import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import WorkflowReportFilter from '../WorkflowReportFilter/WorkflowReportFilter';
import WorkflowCandidateContext from '../WorkflowCandidateContext/WorkflowCandidateContext';
import styles from './WorkflowDetailsContainer.module.scss';
import message from '../Connect/ConnectMessages';
import placeholder from '../../Components/Placeholders/PlaceholdersMessages';

const FormItem = Form.Item;

function WorfklowDetailsHeader(props) {
  const {
    validationStatus,
    validationMessage,
    handleInputChange,
    workflowName,
    workflowId,
    displayMode,
    isWorkflowLive,
    isWorkflowEditAllowedInPreview,
    workflowReportFilterData,
    setWorkflowReportEndNodesData,
    setWorkflowReportData,
    isCandidateCRMFeatureEnabled,
    isDraft,
    location,
    version,
    onEdit,
    candidateContextId,
    showReportsStats,
    isDisclaimerMessageVisible,
    isEditOrUseItButtonVisible,
    workflowHistory,
    intl,
  } = props;
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (!workflowId) {
      inputRef.current.focus();
    }
  }, []);

  const { pathname } = location;

  let disclaimerMessage = isWorkflowLive ? <FormattedMessage {...message.canOnlyPerformSaveAsNoteLabel} /> : null;

  if (displayMode === 'create') {
    disclaimerMessage = <FormattedMessage {...message.workflowWillStopOnceCandidateRespondsLabel} />;
  } else if (displayMode === 'preview' && showReportsStats) {
    disclaimerMessage = <FormattedMessage {...message.theMetricsAreDeterminedByThePreviousEventsLabel} />;
  }

  const getOnClickEditOrUseItRedirectionPath = () => {
    if (candidateContextId)
      return `?tab=workflows&workflowId=${workflowId}&initiateWorkflowFlag=true&candidateContextId=${candidateContextId}`;
    if (isWorkflowEditAllowedInPreview) return `?tab=workflows&workflowId=${workflowId}`;
    return '?tab=workflows&type=create';
  };

  return (
    <div className={classNames(styles.workflowDetailsHeader, { [styles.workflowDetailsHeaderAts]: version === 'ats' })}>
      <div className={styles.workflowDetailsHeaderContent}>
        <div className={styles.workflowDetailsHeaderLeftContent}>
          <div className={styles.workflowDetailsHeaderInput}>
            <Link
              to={{
                pathname,
                search: '?tab=workflows',
              }}
            >
              <div className={styles.backToListWrapper}>
                <Icon type="left" />
                <span className={styles.backToListText}>
                  <FormattedMessage {...message.backLabel} />
                </span>
              </div>
            </Link>
            <FormItem validateStatus={validationStatus} help={validationMessage}>
              <Input
                placeholder={intl.formatMessage({ ...placeholder.enterWorkflowName })}
                ref={inputRef}
                style={{ width: '400px' }}
                defaultValue={workflowName}
                value={workflowName}
                onChange={handleInputChange}
                disabled={displayMode === 'preview'}
              />
            </FormItem>
          </div>
          {isEditOrUseItButtonVisible ? (
            <Link
              to={{
                pathname,
                search: getOnClickEditOrUseItRedirectionPath(),
              }}
            >
              <Button type="primary" shape="round" onClick={onEdit}>
                {isWorkflowEditAllowedInPreview ? (
                  <FormattedMessage {...message.editLabel} />
                ) : (
                  <FormattedMessage {...message.useItLabel} />
                )}
              </Button>
            </Link>
          ) : null}
        </div>
      </div>
      <div className={styles.closeWorkflowIcon}>
        <Link
          to={{
            pathname,
            search: '?tab=workflows',
          }}
        >
          <Icon type="close" style={{ fontSize: '16px', color: '#83878C' }} />
        </Link>
      </div>
      {isDisclaimerMessageVisible && disclaimerMessage ? (
        <div className={styles.workflowReportNote}>{disclaimerMessage}</div>
      ) : null}

      {showReportsStats && !isDraft && (
        <WorkflowReportFilter
          setWorkflowReportData={setWorkflowReportData}
          workflowReportFilterData={workflowReportFilterData}
          setWorkflowReportEndNodesData={setWorkflowReportEndNodesData}
          workflowId={workflowId}
          isCandidateCRMFeatureEnabled={isCandidateCRMFeatureEnabled}
          version={version}
        />
      )}
      {candidateContextId ? (
        <WorkflowCandidateContext candidateContextId={candidateContextId} workflowHistory={workflowHistory} />
      ) : null}
    </div>
  );
}

export default injectIntl(WorfklowDetailsHeader);
export { WorfklowDetailsHeader as WorfklowDetailsHeaderWithoutInjectIntl };
