import _ from 'lodash';
import * as textTemplatesApi from '../Repository/TextTemplates';
import { fetchConnectUsers } from './UserActions';
import { setNotification } from './ActionCreators/ConnectActions';

// function getTextTemplates(from, size) {
//   return dispatch => {
//     textTemplatesApi.getTextTemplates(from, size).then(response => {
//       dispatch({
//         type: 'SET_TEXT_TEMPLATES',
//         payload: response.data.Templates,
//       });
//       dispatch({
//         type: 'SET_TEXT_TEMPLATES_COUNT',
//         payload: response.data.Total,
//       });
//     });
//   };
// }

function searchTextTemplates(filter, isFetchingTemplatesForAutomation = false) {
  return dispatch => {
    textTemplatesApi.searchTextTemplates(filter).then(response => {
      if (!isFetchingTemplatesForAutomation || filter.From === 0) {
        dispatch({
          type: 'SET_TEXT_TEMPLATES',
          payload: response.data.Templates,
        });
      } else {
        dispatch({
          type: 'APPEND_TEXT_TEMPLATES',
          payload: {
            templates: response.data.Templates,
          },
        });
      }
      dispatch(
        fetchConnectUsers({
          userIds: _.uniq(response.data.Templates.map(template => template.CreatedBy)),
        })
      );
      dispatch({
        type: 'SET_TEXT_TEMPLATES_COUNT',
        payload: response.data.Total,
      });
    });
  };
}

function postTextTemplate(template, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId :'creatingTextTemplate',
      })
    );
    textTemplatesApi.postTextTemplate(template).then(() => {
      dispatch(searchTextTemplates(filter));
      dispatch(
        setNotification('LOADING', {
          messageId :'textTemplateCreated',
        })
      );
    });
  };
}

function getTextTemplate(templateId) {
  return dispatch => {
    textTemplatesApi.getTextTemplate(templateId).then(response => {
      dispatch({
        type: 'SET_TEXT_TEMPLATE_DETAILS',
        payload: {
          template: response.data,
        },
      });
    });
  };
}

function editTextTemplate(templateId, template, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId :'updatingTextTemplate',
      })
    );
    textTemplatesApi.putTextTemplate(templateId, template).then(() => {
      dispatch(searchTextTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId :'textTemplateUpdated',
        })
      );
    });
  };
}

function deleteTextTemplate(templateId, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId :'deletingTextTemplate',
      })
    );
    textTemplatesApi.deleteTextTemplate(templateId).then(() => {
      dispatch(searchTextTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId :'textTemplateDeleted',
        })
      );
    });
  };
}

function duplicateTextTemplate(templateId, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId :'duplicatingTextTemplate',
      })
    );
    textTemplatesApi.duplicateTextTemplate(templateId).then(() => {
      dispatch(searchTextTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId :'textTemplateDuplicated',
        })
      );
    });
  };
}

function shareTextTemplate(templateId, shareOptions) {
  let successMessage = 'templateSharedSuccessfully';
  let loadingMessage = 'sharingTemplate';
  if (shareOptions.isShareWithAll) {
    loadingMessage = 'sharingTemplateWithAll';
  }
  if (shareOptions.isUnshareWithAll) {
    successMessage = 'templateUnSharedSuccessfully';
    loadingMessage = 'unSharingTemplateWithAll';
  }
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId :loadingMessage,
      })
    );
    textTemplatesApi
      .shareTextTemplate(templateId, shareOptions)
      .then(() => {
        dispatch({
          type: 'SET_TEXT_TEMPLATE_SHARED_WITH',
          payload: shareOptions,
          templateId,
        });
        dispatch(
          setNotification('SUCCESS', {
            messageId :successMessage,
          })
        );
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SHARE_TEMPLATE_POST_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(
          setNotification('ERROR', {
            messageId :'templateSharingFailed',
          })
        );
      });
  };
}

function testTextTemplate(Body, isConsent) {
  return dispatch => {
    dispatch({
      type: 'SET_TEST_TEXT_TEMPLATE_API_STATUS',
      payload: 'INPROGRESS',
    });
    textTemplatesApi
      .testTextTemplate(Body, isConsent)
      .then(() => {
        dispatch({
          type: 'SET_TEST_TEXT_TEMPLATE_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'SET_TEST_TEXT_TEMPLATE_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

function clearTestTextTemplateState() {
  return dispatch => {
    dispatch({
      type: 'SET_TEST_TEXT_TEMPLATE_API_STATUS',
      payload: null,
    });
  };
}

export {
  // getTextTemplates,
  getTextTemplate,
  searchTextTemplates,
  shareTextTemplate,
  postTextTemplate,
  editTextTemplate,
  deleteTextTemplate,
  duplicateTextTemplate,
  testTextTemplate,
  clearTestTextTemplateState,
};
