import React from 'react';
import queryString from 'query-string';
import { Handle, Position } from 'react-flow-renderer';
import { withRouter } from 'react-router-dom';
import { BaseNode, EmptyBaseNode } from './Base';
import TooltipBaseNode from './Tooltip';
import EndNodeTooltip from './EndNodeTooltip';
import './Style.scss';

const handleNodeClick = props => {
  const { data, id, type } = props;
  data.onNodeClickCallback({ id, type });
};

const onCloseIconClick = (event, props) => {
  event.stopPropagation();
  const { data, id, type } = props;
  data.onDeleteNodeCallback({ id, type });
};

export const _Action = props => {
  const { data, location, id } = props;
  const { hideIncomingHandle, isCloseIconHidden, type, renderTooltipFlag, isHighlighted } = data;
  const queryParams = queryString.parse(location.search);
  const { workflowId } = queryParams;
  const isPreview = type === 'preview';
  const isCloseIconHiddenFlag = isCloseIconHidden || isPreview;
  const ActionBaseNode = () => {
    if (isPreview)
      return (
        <TooltipBaseNode
          {...props}
          renderTooltipFlag={renderTooltipFlag}
          isHighlighted={isHighlighted}
          id={id}
          isCloseIconHidden={isCloseIconHiddenFlag}
          onNodeClick={() => handleNodeClick(props)}
          workflowId={workflowId}
          onCloseIconClick={event => onCloseIconClick(event, props)}
        />
      );
    return (
      <BaseNode
        {...props}
        isCloseIconHidden={isCloseIconHiddenFlag}
        onNodeClick={() => handleNodeClick(props)}
        onCloseIconClick={event => onCloseIconClick(event, props)}
      />
    );
  };
  return (
    <div className="NodeWrapper">
      {hideIncomingHandle ? null : <Handle type="target" position={Position.Top} className="NodePort" />}
      <ActionBaseNode />
      <Handle type="source" position={Position.Bottom} className="NodePort" />
    </div>
  );
};

export const Start = props => {
  const { data } = props;
  const { isHighlighted } = data;
  return (
    <div className="NodeWrapper">
      <BaseNode {...props} isHighlighted={isHighlighted} isCloseIconHidden customClassName="startNode" />
      <Handle type="source" position={Position.Bottom} className="NodePort" />
    </div>
  );
};

export const _Condition = props => {
  const { data } = props;
  const { type, isHighlighted } = data;
  const isCloseIconHidden = type === 'preview';
  return (
    <div className="NodeWrapper">
      <Handle type="target" position={Position.Top} className="NodePort" />
      <BaseNode
        {...props}
        isHighlighted={isHighlighted}
        isCloseIconHidden={isCloseIconHidden}
        onNodeClick={() => handleNodeClick(props)}
        onCloseIconClick={event => onCloseIconClick(event, props)}
      />
      <Handle id="condition_0" type="source" position={Position.Bottom} className="NodePort" />
      <Handle id="condition_1" type="source" position={Position.Bottom} className="NodePort" />
    </div>
  );
};

export const _End = props => {
  const { data } = props;
  const { isLastNode, type, renderTooltipFlag, isHighlighted } = data;
  const isPreview = type === 'preview';
  const isCloseIconHiddenFlag = isLastNode || isPreview;
  return (
    <div className="NodeWrapper">
      <Handle type="target" position={Position.Top} className="NodePort" />
      <EndNodeTooltip
        {...props}
        isHighlighted={isHighlighted}
        isCloseIconHidden={isCloseIconHiddenFlag}
        onCloseIconClick={event => onCloseIconClick(event, props)}
        customClassName="endNode"
        isPreview={isPreview}
        renderTooltipFlag={renderTooltipFlag}
      />

      <Handle type="source" hidden position={Position.Bottom} className="NodePort" />
    </div>
  );
};

export const Empty = props => (
  <div className="NodeWrapper">
    <Handle type="target" position={Position.Top} className="NodePort" />
    <EmptyBaseNode {...props} disabled />
    <Handle type="source" position={Position.Bottom} className="NodePort" style={{ opacity: 0 }} />
  </div>
);
const Action = withRouter(_Action);
const Condition = withRouter(_Condition);
const End = withRouter(_End);
export { Action, Condition, End };
