import * as React from 'react';
import { Icon } from 'antd';

const BackTopSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none">
    <mask
      id="a"
      width={32}
      height={32}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <path fill="#D9D9D9" d="M0 0h32v32H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#1F2730"
        d="m16 14.626 3.41 3.41c.183.185.41.277.684.277.274 0 .51-.092.708-.277a.931.931 0 0 0 .304-.713.996.996 0 0 0-.304-.713l-3.959-3.959A1.156 1.156 0 0 0 16 12.29c-.322 0-.603.12-.844.361l-3.98 3.98a.947.947 0 0 0-.293.703c.003.27.108.504.314.702.199.185.433.278.703.28a.947.947 0 0 0 .702-.293L16 14.626Zm.002 14.04c-1.752 0-3.398-.332-4.94-.997a12.795 12.795 0 0 1-4.023-2.707 12.79 12.79 0 0 1-2.708-4.02c-.665-1.541-.998-3.188-.998-4.94 0-1.751.333-3.398.998-4.94A12.795 12.795 0 0 1 7.038 7.04a12.789 12.789 0 0 1 4.02-2.709c1.541-.665 3.188-.998 4.94-.998 1.752 0 3.398.333 4.94.998a12.796 12.796 0 0 1 4.023 2.707 12.79 12.79 0 0 1 2.708 4.02c.665 1.542.997 3.188.997 4.94 0 1.752-.332 3.399-.997 4.94a12.795 12.795 0 0 1-2.707 4.023 12.789 12.789 0 0 1-4.02 2.708c-1.542.665-3.188.998-4.94.998Zm-.002-2c2.978 0 5.5-1.032 7.567-3.1 2.066-2.066 3.1-4.588 3.1-7.566 0-2.978-1.034-5.5-3.1-7.567-2.067-2.066-4.59-3.1-7.567-3.1-2.978 0-5.5 1.034-7.567 3.1-2.066 2.067-3.1 4.59-3.1 7.567 0 2.978 1.034 5.5 3.1 7.567 2.067 2.067 4.59 3.1 7.567 3.1Z"
      />
    </g>
  </svg>
);
const BackTopIcon = props => <Icon component={BackTopSvg} {...props} />;
export default BackTopIcon;
