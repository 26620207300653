import { message } from 'antd';
import * as atsRepository from '../Repository/AtsRepository';
import { setNotification } from './ActionCreators/ConnectActions';

export function setPushCandidateAtsApiStatus(status) {
  return {
    type: 'SET_PUSH_CANDIDATE_ATS_API_STATUS',
    payload: status,
  };
}

export function pullJobs(jobIds, atsName) {
  return dispatch => {
    atsRepository
      .pullJobs(jobIds, atsName)
      .then(() => {
        dispatch(setNotification('SUCCESS', { messageId: 'jobPullRequestIsBeingProcessed' }));
      })
      .catch(err => {
        dispatch(setNotification('ERROR', { messageId: 'unableToPullJobs' }));
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: err,
            timestamp: new Date(),
          },
        });
      });
  };
}

export function pushCandidate(jobId, candidateId, portal, atsName) {
  return dispatch => {
    dispatch(setPushCandidateAtsApiStatus('INPROGRESS'));
    atsRepository
      .pushCandidate(jobId, candidateId, portal, atsName)
      .then(() => {
        dispatch(setNotification('SUCCESS', { messageId: 'yourRequestIsBeingProcessed' }));
        dispatch(setPushCandidateAtsApiStatus('COMPLETED'));
      })
      .catch(err => {
        dispatch(setNotification('ERROR', { messageId: 'unableToPushCandidate' }));
        dispatch(setPushCandidateAtsApiStatus('FAILED'));
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: err,
            timestamp: new Date(),
          },
        });
      });
  };
}

export function fetchAtsConfig(atsName) {
  return dispatch => {
    atsRepository
      .fetchAtsConfig(atsName)
      .then(response => {
        dispatch({
          type: 'SET_ATS_CONFIG',
          payload: response.data,
        });
      })
      .catch(err => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: err,
            timestamp: new Date(),
          },
        });
      });
  };
}
