import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Button, Modal, Spin, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './SubSegmentStyles.module.scss';
import SubSegmentSelectBox from './SubSegmentSelectBox';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import message from '../ManualSearchV2/ManualSearchMessages';

export const totalCandidateCount = array => {
  return array.reduce((acc, cur) => acc + cur.AssociatedCandidateCount, 0);
};

export const updateSubSegmentData = (subsegmentData, newSubsegmentData) => {
  const finalData = [...subsegmentData];
  newSubsegmentData.forEach(item => {
    const { Id, AssociatedCandidateCount } = item;
    const duplicateItem = finalData.filter(filterItem => filterItem.Id === Id);
    if (duplicateItem.length === 0) finalData.push(item);
    else {
      const index = finalData.findIndex(object => object.Id === Id);
      finalData[index].AssociatedCandidateCount = AssociatedCandidateCount;
    }
  });
  return finalData;
};

const mapStateToProps = state => ({
  featureToggleList: getFeatureToggleList(state),
});

const AddCandidateToSegmentModal = props => {
  const [subSegmentData, setSubsegmentData] = React.useState([]);
  const [subSegmentOption, setSubSegmentOption] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(2);
  const [subSegmentCheckbox, setSubsegmentCheckbox] = React.useState(false);
  const {
    visibleStatus,
    setVisible,
    fetchSubSegments,
    segmentId,
    subsegmentList,
    fetchSubSegmentApiStatus,
    setSubsegmentModalStatus,
    handleOnclick,
    includeAlreadyAssociatedCandidates,
    eventSegmentModalCallbacks,
    showMatchingCandidateChecbox,
    setCreatedSubSegmentId,
    lastCreatedCandidateId,
    featureToggleList,
  } = props;
  const isSubSegmentCreationEnabled = featureToggleList?.SubSegmentCreation?.IsEnabled;
  const { subSegments, total } = subsegmentList;
  const { visible } = visibleStatus;
  const subsegmentCandidateCount = totalCandidateCount(subSegmentData);
  React.useEffect(() => {
    if (fetchSubSegments) fetchSubSegments(segmentId, null, 1, 10);
    return () => {
      setVisible(!visible, visibleStatus);
    };
  }, [fetchSubSegments, segmentId]);

  React.useEffect(() => {
    if (Array.isArray(subSegments)) {
      if (subSegmentData.length === 0 || subSegments.length === 0) setSubsegmentData(subSegments);
      else {
        const newSubsegmentCandidateCount = totalCandidateCount(subSegments);
        if (newSubsegmentCandidateCount !== subsegmentCandidateCount || subSegmentData.length !== total) {
          const newSubsegmentData = updateSubSegmentData(subSegmentData, subSegments);
          setSubsegmentData(newSubsegmentData);
        }
      }
    }
  }, [subSegments]);

  React.useEffect(() => {
    if (lastCreatedCandidateId) {
      setSubsegmentCheckbox(true);
      setSubSegmentOption(lastCreatedCandidateId);
    }
  }, [lastCreatedCandidateId]);

  const handleVisibleChange = () => {
    setVisible(!visible, visibleStatus);
    setCreatedSubSegmentId(null);
  };
  const onChangeCheckbox = e => setSubsegmentCheckbox(e.target.checked);

  const handleSubSegmentOption = value => setSubSegmentOption(value);

  const handleAddCandidate = async e => {
    await handleOnclick(e, subSegmentOption);
    handleVisibleChange();
    setCreatedSubSegmentId(null);
  };

  const selectStyle = {
    width: '91%',
    marginLeft: '22px',
    marginTop: '10px',
  };

  const onPopupScroll = e => {
    const { target } = e;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 5 && total > subSegmentData.length) {
      fetchSubSegments(segmentId, null, pageNumber, 10);
      setPageNumber(pageNumber + 1);
    }
  };

  const onButtonClick = e => {
    e.stopPropagation();
    setSubsegmentModalStatus(true);
    setVisible(false, visibleStatus);
  };
  const dropDownRender = menu => (
    <div>
      <Tooltip
        getPopupContainer={node => node.parentNode}
        title={
          !isSubSegmentCreationEnabled
            ? 'You do not have permission to Create a Sub-Segment. Please contact your admin'
            : ''
        }
      >
        <Button
          disabled={!isSubSegmentCreationEnabled}
          onMouseDown={onButtonClick}
          className={styles.addNewSubsegment}
          type="link"
        >
          + <FormattedMessage {...message.createANewSubSegment} />
        </Button>
      </Tooltip>
      {menu}
      {fetchSubSegmentApiStatus === 'INPROGRESS' && (
        <div>
          <Spin className="spin-loader" size="small" />
        </div>
      )}
    </div>
  );
  const extraWidth = `${subsegmentCandidateCount}`.length < 3 ? 20 : 10;
  const content = () => (
    <div className={styles.addContainer}>
      {showMatchingCandidateChecbox && (
        <div className={styles.matchingCandidateCheckboxContainer}>
          <Checkbox checked={includeAlreadyAssociatedCandidates} onChange={eventSegmentModalCallbacks.onCheckboxChange}>
          <FormattedMessage {...message.alsoAddMatchingRejectedCandidates} />
          </Checkbox>
        </div>
      )}
      <div className={styles.includedCheckbox}>
        <Checkbox disabled checked></Checkbox>
        <span style={{ marginLeft: 10 }}> <FormattedMessage {...message.includedCandidatesLabel} /></span>
      </div>
      <div>
        <Checkbox onChange={onChangeCheckbox} style={{ color: '#1f2730', marginLeft: 16 }} checked={subSegmentCheckbox}>
        <FormattedMessage {...message.subSegmentOptionalLabel} />
        </Checkbox>
        <div className={styles.subsegmentSelectContainer}>
          {subSegmentCheckbox && (
            <SubSegmentSelectBox
              selectStyle={selectStyle}
              handleSubSegmentOption={handleSubSegmentOption}
              onPopupScroll={onPopupScroll}
              dropDownRender={dropDownRender}
              subSegmentList={subSegmentData}
              subsegmentCandidateCount={subsegmentCandidateCount}
              extraWidth={extraWidth}
              selectedSubsegmentId={subSegmentOption}
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        title={<FormattedMessage {...message.addCandidateToLabel} />}
        visible={visible}
        centered
        zIndex={3000}
        onCancel={handleVisibleChange}
        onOk={handleVisibleChange}
        footer={[
          <Button shape="round" key="back" onClick={handleVisibleChange}>
            <FormattedMessage {...message.cancelLabel} />
          </Button>,
          <Button shape="round" key="submit" type="primary" onClick={handleAddCandidate}>
            <FormattedMessage {...message.AddLabel} />
          </Button>,
        ]}
      >
        {content()}
        <small className={styles.noteStyle}>
          <b><FormattedMessage {...message.noteLabel} />:</b>
          <FormattedMessage {...message.addingCandidatesAlertLabel} />
        </small>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, null)(AddCandidateToSegmentModal);
export { AddCandidateToSegmentModal as AddCandidateToSegmentModalWithoutStore };
