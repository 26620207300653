import React from 'react';
import _ from 'lodash';
import { Badge, Tooltip } from 'antd';
import moment from 'moment';
import '../JobShareNotification/JobShareNotification.scss';
import SegmentShareNotificationIcon from '../../../Icons/SegmentShareNotificationIcon';

function getIcon(notificationType) {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (notificationType) {
    case 'CandidateAddRequest-Processed':
      return (
        <div className="jobshared-image-wrapper">
          <div className="jobshared-image">
            <SegmentShareNotificationIcon style={{ fontSize: '40px' }} className="segmentShare" />
          </div>
        </div>
      );
    default:
      return null;
  }
}

function SegmentCandidateAddNotification(props) {
  const { notification, onClick, userByGuId } = props;
  if (typeof notification.Payload === 'string') {
    notification.Payload = JSON.parse(notification.Payload);
  }
  const { Type, Payload, UpdatedTime, UnreadCount, SenderId } = notification;
  const { JobTitle, JobId, RequestCount } = Payload;
  return (
    <div className="notification" role="presentation" onClick={() => onClick(notification)}>
      <div className="icon">{getIcon(Type)}</div>
      <div className="content">
        <div className="notification-title">
          {/* <div className="message">
            Segment: <span className="bold">{JobTitle}</span>, {JobId}
          </div> */}
          Candidate addition completed for segment: <span className="bold">{JobTitle}</span>, {JobId} by{' '}
          <Tooltip
            overlayClassName="notification-user-name"
            title={`${_.get(userByGuId, [SenderId, 'FirstName'])} ${_.get(userByGuId, [SenderId, 'LastName'])}`}
          >
            <span className="bold">{_.get(userByGuId, [SenderId, 'FirstName'])}</span>
          </Tooltip>{' '}
          with count: <span className="bold">{RequestCount}</span>
        </div>
        {/* <div className="message">
          Segment:{' '}
          <span className="bold">
            {JobTitle}, {JobId}
          </span>
        </div> */}
        <div className="actions" />
        <div className="since">{moment.utc(UpdatedTime).local().fromNow()}</div>
      </div>
      <div className="is-viewed">
        <Badge dot={UnreadCount > 0} />
      </div>
    </div>
  );
}

export default SegmentCandidateAddNotification;
