import { message } from 'antd';
import * as actionCreators from './ActionCreators/WorkflowReportActionCreator';
import * as workflowReportRepository from '../Repository/WorkflowReportRepository';
import { setNotification } from './ActionCreators/ConnectActions';

function setWorkflowReportModalVisibility(visible) {
  return dispatch => {
    dispatch(actionCreators.setWorkflowReportModalVisibilityAction(visible));
  };
}

function setWorkflowReport(data) {
  return dispatch => {
    dispatch(actionCreators.setWorkflowReportDataAction(data));
  };
}

function setWorkflowReportFilter(data) {
  return dispatch => {
    dispatch(actionCreators.setWorkflowReportFilterData(data));
  };
}

function setWorkflowReportEndNodesData(data) {
  return actionCreators.setWorkflowReportEndNodesData(data);
}

function setWorkflowPublishedStatus(status) {
  return actionCreators.setWorkflowPublishedStatus(status);
}

function setWorkflowReportFilterData(workflowId) {
  return async dispatch => {
    try {
      const response = await workflowReportRepository.fetchWorkflowReportFilter(workflowId);
      dispatch(setWorkflowReportFilter(response.data));
    } catch (e) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'errorOccurredWhileFetchingTheFilterData',
        })
      );
    }
  };
}

function setWorkflowReportData(data) {
  return async dispatch => {
    try {
      dispatch(actionCreators.setWorkflowReportApiStatus(true));
      const response = await workflowReportRepository.fetchWorkflowReport(data);
      dispatch(setWorkflowReport(response.data));
      dispatch(actionCreators.setWorkflowReportApiStatus(false));
    } catch (e) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'errorOccurredWhileFetchingTheReportData',
        })
      );
    }
  };
}

function setWorkflowReportApiStatus({ status }) {
  return dispatch => {
    dispatch(actionCreators.setWorkflowReportApiStatus(status));
  };
}

export {
  setWorkflowReportModalVisibility,
  setWorkflowReport,
  setWorkflowReportFilterData,
  setWorkflowReportData,
  setWorkflowReportEndNodesData,
  setWorkflowReportApiStatus,
  setWorkflowPublishedStatus,
};
