import React from 'react';
import { Form, Input, Button, Modal, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { phoneData } from './CountryCodeMappingToPhoneLength.js';

import styles from './AddPhoneNumber.module.scss';
import message from '../../../Containers/CandidateDrawer/messages';

const { Option } = Select;

class AddPhoneNumber extends React.Component {
  state = {
    countryCode: '+1',
  };

  handleSubmit = e => {
    const { form, changeState, addPhone, onChangePhone, phones } = this.props;
    const { validateFields, getFieldValue } = form;
    const { countryCode } = this.state;
    const currentPhone = `${countryCode}${getFieldValue('phone')}`;
    const trimmedCurrentPhone = currentPhone?.trim();
    const isPhoneNumberExist = !!phones.find(entry => entry.Number === trimmedCurrentPhone);

    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values.phone);
        if (changeState) changeState();
        if (!isPhoneNumberExist) addPhone(`${countryCode}${values.phone}`);
        if (onChangePhone) onChangePhone(`${countryCode}${values.phone}`);
      }
    });
  };

  validatePhone = (rule, value, callback) => {
    const { form, phones } = this.props;
    const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const countryCode = form.getFieldValue('prefix');
    const phone = countryCode + form.getFieldValue('phone');
    const trimmedPhone = phone?.trim();
    const isPhoneNumberExist = !!phones.find(entry => entry.Number === trimmedPhone);
    if (isPhoneNumberExist) {
      callback('Phone number already exists.');
    }
    if (!phone.trim().length || regex.test(phone)) {
      callback();
      // Valid international phone number
    }
    callback(<FormattedMessage {...message.invalidPhoneNumber} />);
  };

  render() {
    const { form, skEventName, isAddPhoneFormVisible, setAddPhoneFormVisibility } = this.props;
    const { getFieldDecorator } = form;
    const phoneCodes = Object.keys(phoneData);
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '+1',
      rules: [
        {
          required: true,
          validator: this.validatePhone,
        },
      ],
    })(
      <Select
        showSearch
        style={{ width: 70 }}
        getPopupContainer={node => node.parentNode}
        onChange={value => this.setState({ countryCode: value })}
      >
        {phoneCodes.map(code => (
          <Option key={code} value={code}>
            {code}
          </Option>
        ))}
      </Select>
    );

    const phonePlaceHolder = `Ex: 9999999999`;

    return (
      <Modal
        title={<FormattedMessage {...message.addPhoneLabel} />}
        centered
        visible={isAddPhoneFormVisible}
        onCancel={() => setAddPhoneFormVisibility(false)}
        footer={[
          <Button shape="round" key="back" onClick={() => setAddPhoneFormVisibility(false)}>
            <FormattedMessage {...message.cancelButton} />
          </Button>,
          <Button shape="round" key="submit" onClick={this.handleSubmit} type="primary" sk-event-name={skEventName}>
            <FormattedMessage {...message.saveButton} />
          </Button>,
        ]}
        zIndex={2223}
      >
        <Form>
          <div className={styles.modalContent}>
            <Form.Item>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    validator: this.validatePhone,
                  },
                ],
              })(<Input addonBefore={prefixSelector} placeholder={phonePlaceHolder} className={styles.phoneInput} />)}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}

export { AddPhoneNumber as AddPhoneNumberWithoutForm };

export default Form.create({ name: 'coordinated' })(AddPhoneNumber);
