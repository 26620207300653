/* eslint-disable no-console */
import { Auth } from 'aws-amplify';
import io from 'socket.io-client';
import * as signalR from '@microsoft/signalr';

import Config from '../Config/Config';
import EventHandlers from './SocketEventHandler';
import { fetchUsers } from '../Repository/UserRepository';
import _store from '../store';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { getCurrentOrgGuid } from '../Reducers/UserReducer';

/**
 * @author Mouli Kalakota
 * @email mouli.kalakota@leoforce.com
 * @create date 2019-04-30 03:10:52
 * @modify date 2019-04-30 03:10:52
 * @desc Connection manager for websockets - Chat, SMS, Notifications
 */

// eslint-disable-next-line import/no-mutable-exports
let chatConnection = null;
let chatConnectionV2 = null;
// eslint-disable-next-line import/no-mutable-exports
let notificationConnection = null;

function setupNotificationConnection(dispatch, token) {
  const socket = io(Config.urls.aryaNotifySocketEndpoint, {
    query: {
      token,
      AppId: Config.appId,
    },
    transports: ['websocket'],
  });
  socket.connect();
  socket.on('announcement', newAnnouncement => {
    EventHandlers.handleAnnouncementEvent(dispatch, newAnnouncement);
  });
  socket.on('notification', newNotification => {
    EventHandlers.handleNotificationEvent(socket, dispatch, 'notification', newNotification);
  });
  socket.on('GetNotificationsResponse', newNotification => {
    EventHandlers.handleNotificationEvent(socket, dispatch, 'GetNotificationsResponse', newNotification);
  });
  socket.on('NotificationRead', newNotification => {
    EventHandlers.handleNotificationEvent(socket, dispatch, 'NotificationRead', newNotification);
  });
  socket.on('NotificationsRead', newNotification => {
    EventHandlers.handleNotificationEvent(socket, dispatch, 'NotificationsRead', newNotification);
  });
  socket.on('error', error => {
    console.error('Socket connection failed', error);
  });
  socket.on('reconnect', attempt => {
    console.error('Successfully reconnected', attempt);
  });
  socket.on('reconnect_error', error => {
    console.error('Error occured while trying to reconnect', error);
  });
  socket.on('reconnect_failed', error => {
    console.error('Failed to reconnect', error);
  });
  return socket;
}

function createV2HubConnection(token, orgGuid) {
  return new signalR.HubConnectionBuilder()
    .withUrl(`${Config.urls.aryaConnectChatV2}?tenantId=${orgGuid}&access_token=${token}`, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();
}

function registerV2ConnectionHandlers(connection, dispatch, token, orgGuid, retryCount) {
  connection.on('ReceiveMessage', payload => EventHandlers.handleV2ChatEvent(connection, dispatch, payload));
  connection.onclose(res => {
    const { message } = res;
    chatConnectionV2 = null;
    if (message === 'WebSocket closed with status code: 1006 ().') {
      console.log('Chathub v2 disconnected due to ', res, '. Reconnecting... immediately');
      retryV2ChatConnection(dispatch, token, orgGuid, 0); // Retry immediately with reset retryCount
    } else {
      const retryDelay = calculateRetryDelay(retryCount);
      console.log(`Chathub v2 disconnected due to ${res}. Reconnecting in ${retryDelay / 1000} seconds...`);
      setTimeout(() => retryV2ChatConnection(dispatch, token, orgGuid, retryCount + 1), retryDelay);
    }
  });
}

function calculateRetryDelay(retryCount) {
  return 2 ** retryCount * 1000 + Math.floor(Math.random() * 2000);
}

function handleV2ConnectionFailure(err, dispatch, token, orgGuid, retryCount) {
  console.error('ChatHub v2 connection failed due to ', err);
  const retryDelay = calculateRetryDelay(retryCount);
  console.log(`Retrying in ${retryDelay / 1000} seconds...`);
  chatConnectionV2 = null;
  setTimeout(() => retryV2ChatConnection(dispatch, token, orgGuid, retryCount + 1), retryDelay);
}

async function retryV2ChatConnection(dispatch, token, orgGuid, retryCount) {
  chatConnectionV2 = await setupV2ChatConnection(dispatch, token, orgGuid, retryCount);
}

async function setupV2ChatConnection(dispatch, token, orgGuid, retryCount = 0) {
  try {
    console.log('Setting up v2 connect');
    const connection = createV2HubConnection(token, orgGuid);
    // Handle message reception and connection close
    registerV2ConnectionHandlers(connection, dispatch, token, orgGuid, retryCount);
    await connection.start();
    console.log('V2 ChatHub Connected ');
    return connection;
  } catch (err) {
    handleV2ConnectionFailure(err, dispatch, token, orgGuid, retryCount);
    return null;
  }
}

function setupChatConnection(dispatch, token) {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${Config.urls.aryaConnectChat}?access_token=${token}`, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .build();
  connection.on('ReceiveChatMessage', payload => EventHandlers.handleChatEvent(connection, dispatch, 'chat', payload));
  connection.on('ReceiveSMSMessage', payload => EventHandlers.handleChatEvent(connection, dispatch, 'sms', payload));
  // connection.on('ReceiveEmail', payload => EventHandlers.handleEmailEventFromConnect(connection, dispatch, payload));
  connection
    .start()
    .then(() => {
      console.log('ChatHub Connected');
    })
    .catch(err => {
      console.error('ChatHub Connection failed due to', err, '. Retrying... in 5sec');
      chatConnection = null;
      // eslint-disable-next-line no-use-before-define
      setTimeout(() => dispatch(setupSocketConnections()), 5000);
    });
  connection.onclose(res => {
    const { message } = res;
    chatConnection = null;
    if (message === 'WebSocket closed with status code: 1006 ().') {
      console.log('Chathub disconnected due to ', res, '. Reconnecting... immediately');
      dispatch(setupSocketConnections());
    } else {
      console.log('Chathub disconnected due to ', res, '. Reconnecting... in 5sec');
      // eslint-disable-next-line no-use-before-define
      setTimeout(() => dispatch(setupSocketConnections()), 5000);
    }
  });
  return connection;
}

function setupSocketConnections() {
  return dispatch => {
    if (chatConnection && notificationConnection) {
      return;
    }
    Auth.currentSession().then(async session => {
      const { jwtToken: token } = session.getIdToken();
      if (!token) {
        return;
      }
      if (!chatConnection) {
        chatConnection = setupChatConnection(dispatch, token);
      }
      if (!notificationConnection) {
        notificationConnection = setupNotificationConnection(dispatch, token);
      }
    });
  };
}

function setupV2SocketConnections() {
  return dispatch => {
    Auth.currentSession().then(async session => {
      const { jwtToken: token } = session.getIdToken();
      const {
        payload: { sub: userGuid },
      } = session.getIdToken();
      if (!token) {
        return;
      }
      if (!chatConnectionV2) {
        await initializeChatConnectionV2(dispatch, token, userGuid);
      }
    });
  };
}

async function initializeChatConnectionV2(dispatch, token, userGuid) {
  const state = _store.getState();
  const featureToggleList = getFeatureToggleList(state);
  if (featureToggleList.JobLevelSourcingSupportAssistant.IsEnabled) {
    try {
      const orgGuid = await getOrgGuid(state, userGuid);
      if (orgGuid) {
        chatConnectionV2 = await setupV2ChatConnection(dispatch, token, orgGuid.toLowerCase());
      }
    } catch (err) {
      console.log('Fetching org Guid for the user failed');
    }
  }
}

async function getOrgGuid(state, userGuid) {
  let orgGuid = getCurrentOrgGuid(state, userGuid);
  if (!orgGuid) {
    const responseData = await fetchUsers({ userGuids: [userGuid] });
    orgGuid = responseData.data.Users[0]?.OrgGuid;
  }
  if (!orgGuid) {
    const impersonatedUserDetails = localStorage.getItem('ImpersonatedUser');
    const impersonate = JSON.parse(impersonatedUserDetails);
    orgGuid = impersonate.OrgGuid;
  }
  return orgGuid;
}

function getChatConnection(dispatch) {
  if (chatConnection) {
    return Promise.resolve(chatConnection);
  }
  return Auth.currentSession().then(session => {
    const { jwtToken: token } = session.getIdToken();
    if (!token) {
      return Promise.reject();
    }
    chatConnection = setupChatConnection(dispatch, token);
    return Promise.resolve(chatConnection);
  });
}

async function getChatConnectionV2(dispatch) {
  try {
    if (chatConnectionV2 && chatConnectionV2.state === signalR.HubConnectionState.Connected) {
      return chatConnectionV2;
    }
    const session = await Auth.currentSession();
    const { jwtToken: token } = session.getIdToken();
    const {
      payload: { sub: userGuid },
    } = session.getIdToken();
    if (!token) {
      return Promise.reject();
    }
    const response = await fetchUsers({ userGuids: [userGuid] });
    let orgGuid = response.data.Users[0]?.OrgGuid;
    if (!orgGuid) {
      const impersonatedUserDetails = localStorage.getItem('ImpersonatedUser');
      const impersonate = JSON.parse(impersonatedUserDetails);
      orgGuid = impersonate.OrgGuid;
    }
    chatConnectionV2 = await setupV2ChatConnection(dispatch, token, orgGuid.toLowerCase());
    return chatConnectionV2;
  } catch (err) {
    console.error('Error occured while fetching the v2 connection', err);
    return null;
  }
}

function closeSocketConnection() {
  if (chatConnection) {
    chatConnection.stop();
    chatConnection = null;
  }
  if (notificationConnection) {
    notificationConnection.close();
    notificationConnection = null;
  }
  if (chatConnectionV2) {
    chatConnectionV2.stop();
    chatConnectionV2 = null;
  }
}

export {
  setupSocketConnections,
  setupV2SocketConnections,
  chatConnection,
  notificationConnection,
  getChatConnection,
  closeSocketConnection,
  getChatConnectionV2,
};
