import React from 'react';
import classNames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Table, Modal, message, Menu, Dropdown, Icon, Form } from 'antd';
import styles from './EmailConfigurationList.module.scss';
import { getProviderDisplayName } from '../../../Utils/SmtpUtils';
import InfoIconWithTooltip from '../../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';

const Manual = 'Manual';
const OAuth2 = 'OAuth2';
const Verified = 'Verified';
const Pending = 'Pending';
const Failed = 'Failed';
const TestedSuccessfully = <FormattedMessage {...messages.testedSuccessfullyLabel} />;
const TestingFailed = <FormattedMessage {...messages.testingFailedLabel} />;

 function EmailConfigurationList(props) {
  const {
    currentUser,
    userEmails,
    orgDefaultEmail,
    addUserEmail,
    connectEmailToProviderAndTest,
    isAdmin,
    emailProviders,
    deleteUserEmail,
    onEditConfiguration,
    deleteEmailConfig,
    intl,
  } = props;

  const getCurrentUserName = () => {
    return `${currentUser.given_name} ${currentUser.family_name ? currentUser.family_name : ''}`;
  };

  const getUserEmailsMergedWithProviders = () => {
    return userEmails.map(userEmail => {
      let providerConfiguration = emailProviders.find(x => x.Id === userEmail.ConfiguredProviderId);
      if (!providerConfiguration)
        providerConfiguration = emailProviders.find(
          emailProvider => emailProvider.ProviderName?.toLowerCase() === Manual.toLowerCase()
        );
      const { SenderName: senderName } = userEmail;
      const emailConfiguration = {
        ...userEmail,
        SenderName: senderName || getCurrentUserName(),
        ProviderConfiguration: providerConfiguration,
      };
      const linkedEmailConfiguration = providerConfiguration?.Emails?.find(x => x.Email.EmailId === userEmail.EmailId);
      if (linkedEmailConfiguration && !linkedEmailConfiguration.Message?.includes('Skipped test email'))
        emailConfiguration.TestingStatus = linkedEmailConfiguration.IsTestEmailSuccessfull
          ? TestedSuccessfully
          : TestingFailed;
      return emailConfiguration;
    });
  };

  const onClickEditIcon = email => {
    onEditConfiguration(email);
  };

  const onTestEmail = email => {
    const emailId=email.EmailId;
    if (email.ProviderConfiguration?.Id)
      connectEmailToProviderAndTest({
        emailId: email.EmailId,
        providerId: email.ProviderConfiguration.Id,
        isAdmin: email.ProviderConfiguration.AuthScheme === Manual,
        showTestEmailNotification: true,
      });
    else message.error(intl.formatMessage({ ...messages.notLinkedToAnySMTPConfigurationLabel } ,{emailId}));
  };

  const onDeleteConfirmButtonClick = email => {
    deleteUserEmail(email.Id);
    if (email.ProviderConfiguration?.AuthScheme === OAuth2)
      deleteEmailConfig(email.ProviderConfiguration.Id, { isAdmin });
  };

  const onDeleteButtonClick = email => {
    const emailId=email.EmailId;
    const modalOptions = {
      title: intl.formatMessage({ ...messages.areYouSureYouWantToDeleteTheEmailAddressLabel }),
      content: intl.formatMessage({ ...messages.youWillNotBeAbleToSendEmailsLabel }, {emailId}),
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      okText: intl.formatMessage({ ...messages.deleteLabel }),
      onOk: () => onDeleteConfirmButtonClick(email),
    };
    Modal.confirm(modalOptions);
  };

  const onVerifyEmail = email => {
    addUserEmail(email, true);
  };

  const getActionsColumn = email => {
    const { VerificationStatus: verificationStatus } = email;
    const actionsMenu = (
      <Menu>
        <Menu.Item onClick={() => onClickEditIcon(email)}>
          <FormattedMessage {...messages.editLabel} />
        </Menu.Item>
        {verificationStatus !== 'Verified' ? (
          <Menu.Item onClick={() => onVerifyEmail(email.EmailId)}>
            {' '}
            <FormattedMessage {...messages.verifyLabel} />
          </Menu.Item>
        ) : null}
        {orgDefaultEmail.EmailId !== email.EmailId && verificationStatus === 'Verified' ? (
          <Menu.Item onClick={() => onTestEmail(email)}>
            <FormattedMessage {...messages.testLabel} />
          </Menu.Item>
        ) : null}
        {orgDefaultEmail.EmailId !== email.EmailId && currentUser.email !== email.EmailId ? (
          <Menu.Item onClick={() => onDeleteButtonClick(email)}>
            <span className={styles.deleteButton}>
              <FormattedMessage {...messages.deleteLabel} />
            </span>
          </Menu.Item>
        ) : null}
      </Menu>
    );
    return (
      <Dropdown overlay={actionsMenu}>
        <Icon type="ellipsis" style={{ transform: 'rotate(90deg)', cursor: 'pointer' }} />
      </Dropdown>
    );
  };

  const getClassnameForStatus = email =>
    classNames(
      styles.status,
      { [styles.success]: email.VerificationStatus === Verified || email.TestingStatus === TestedSuccessfully },
      { [styles.failed]: email.VerificationStatus === Failed || email.TestingStatus === TestingFailed },
      { [styles.pending]: email.VerificationStatus === Pending }
    );

  const userEmailsMergedWithProviders = getUserEmailsMergedWithProviders();
  const dataSource = userEmailsMergedWithProviders.map(email => {
    const providerDisplayName =
      email.EmailId === orgDefaultEmail.EmailId ? 'Default' : getProviderDisplayName(email.ProviderConfiguration);
    return {
      key: email.EmailId,
      senderName: email.SenderName,
      email: email.EmailId,
      provider: providerDisplayName ?? 'None',
      status: (
        <div style={{ display: 'flex', gap: '5px' }}>
          <div className={getClassnameForStatus(email)} />
          {email.VerificationStatus === Pending ? (
            <span className={styles.pendingStatus}>
              <span><FormattedMessage {...messages.verificationPendingLabel} /></span>
              <InfoIconWithTooltip
                tooltipTitle={<div><FormattedMessage {...messages.verificationIsPendingLabel} /></div>}
              />
            </span>
          ) : (
            email.TestingStatus ?? email.VerificationStatus
          )}
        </div>
      ),
      action: getActionsColumn(email),
    };
  });

  const columns = [
    {
      title: <span className={styles.smtpTableColumnTitle}><FormattedMessage {...messages.NameLabel} /></span>,
      dataIndex: 'senderName',
      key: 'senderName',
      className: styles.smtpTableColumn,
      width: 170,
      ellipsis: true,
    },
    {
      title: <span className={styles.smtpTableColumnTitle}><FormattedMessage {...messages.fromAddressLabel} /></span>,
      dataIndex: 'email',
      key: 'email',
      className: styles.smtpTableColumn,
      width: 250,
      ellipsis: true,
    },
    {
      title: <span className={styles.smtpTableColumnTitle}><FormattedMessage {...messages.emailProviderLabel} /></span>,
      dataIndex: 'provider',
      key: 'provider',
      className: styles.smtpTableColumn,
      width: 160,
      ellipsis: true,
    },
    {
      title: <span className={styles.smtpTableColumnTitle}><FormattedMessage {...messages.StatusLabel} /></span>,
      dataIndex: 'status',
      key: 'status',
      className: styles.smtpTableColumn,
      width: 200,
    },
    {
      title: <span className={styles.smtpTableColumnTitle}><FormattedMessage {...messages.actionLabel} /></span>,
      dataIndex: 'action',
      key: 'action',
      className: styles.smtpTableColumn,
      align: 'center',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      className={styles.emailTable}
      rowClassName={styles.smtpTableRow}
    />
  );
}

export default (injectIntl(Form.create()(EmailConfigurationList)));
export { EmailConfigurationList as EmailConfigurationListWithoutStore };
