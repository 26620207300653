import React from 'react';
import styles from './WorkflowComponents.module.scss';
import { WorkflowDelayActionSvgIcon, WorkflowRuleActionSvgIcon } from '../../../Icons/AryaIcons';

export const Rule = ({ firstRuleText, secondRuleText }) => (
  <div className={styles.rulesContainer}>
    <span></span>
    {!secondRuleText ? (
      <p>
        <WorkflowRuleActionSvgIcon /> {firstRuleText}
      </p>
    ) : (
      <p>
        <WorkflowRuleActionSvgIcon /> {firstRuleText}
        <span className={styles.space}>&</span>
        <WorkflowRuleActionSvgIcon /> {secondRuleText}
      </p>
    )}

    <span></span>
  </div>
);

export const NestedRule = ({ rule1, rule2 }) => (
  <div className={styles.nestedRuleContainer}>
    <span></span>
    <p>{rule1}</p>
    <span></span>
    <p>{rule2}</p>
  </div>
);

export const Delay = ({ text }) => (
  <div className={styles.delayContainer}>
    <span></span>
    <p>
      <WorkflowDelayActionSvgIcon /> {text}
    </p>
    <span></span>
  </div>
);
