import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { Radio, Input, Checkbox, Form, Button, InputNumber, Skeleton, message } from 'antd';
import { getProviderConfig, getAllowedSmtpOptions, PROVIDER_TYPE } from '../../Utils/SmtpUtils';
import './EmailSettings.scss';
import styles from './EmailSettings.module.scss';
import DefaultSmtpComponent from './DefaultSmtpComponent';
import InfoIconWithTooltip from '../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import emailSetting from './EmailSettingsMessage';
import placeholder from '../Placeholders/PlaceholdersMessages';

const initialState = {
  OutgoingServerHost: '',
  OutgoingServerPort: null,
  Username: '',
  Password: '',
  EnableSsl: false,
  activeRadio: PROVIDER_TYPE.manual,
  validateStatus: {
    OutgoingServerHost: 'success',
    OutgoingServerPort: 'success',
    Username: 'success',
    Password: 'success',
  },
  emailSmtpConfigState: {},
  buttonToggleState: 'none',
};

class EmailSettings extends React.Component {
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSSLChange = this.handleSSLChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.clearErrorMsg = this.clearErrorMsg.bind(this);
    this.state = initialState;
    this.isInitialValuesSet = false;
  }

  async componentDidMount() {
    const { fetchUserEmails } = this.props;
    await fetchUserEmails();
  }

  componentDidUpdate(prevProps) {
    const { emailConfigurationApiStatus: prevEmailConfigurationApiStatus } = prevProps;
    const { emailConfigurationApiStatus } = this.props;
    if (
      !this.isInitialValuesSet &&
      prevEmailConfigurationApiStatus === 'INPROGRESS' &&
      emailConfigurationApiStatus === 'COMPLETED'
    ) {
      this.setInitialState();
      this.isInitialValuesSet = true;
    }
  }

  setInitialState = () => {
    const { emailSmtpConfiguration, emailProviders } = this.props;
    const manualProviderConfig = getProviderConfig(emailProviders, PROVIDER_TYPE.manual);
    const { Configuration = {} } = manualProviderConfig || {};
    const newState = {
      OutgoingServerHost: Configuration.Host ?? '',
      OutgoingServerPort: Configuration.Port ?? null,
      Username: Configuration.Username ?? '',
      Password: Configuration.Password ?? '',
      EnableSsl: !!Configuration.EnableSsl,
      providerId: manualProviderConfig?.Id ?? '',
      isMultipleSenderAddressConfigurationAllowed: emailSmtpConfiguration.IsMultipleSenderAddressConfigurationAllowed,
      isThirdPartyEmailConfigurationAllowed: emailSmtpConfiguration.IsThirdPartyEmailConfigurationAllowed,
      activeRadio: PROVIDER_TYPE.manual,
    };
    this.setState({ ...newState });
  };

  validateForm() {
    const { OutgoingServerHost, OutgoingServerPort } = this.state;
    const outServerHost = typeof OutgoingServerHost === 'string' ? OutgoingServerHost.trim() : null;
    const outServerPort = typeof OutgoingServerPort === 'number' ? OutgoingServerPort : null;

    if (!outServerHost && outServerPort === null) {
      return { validated: true, cleared: true };
    }
    const validateStatus = {
      OutgoingServerHost: outServerHost ? 'success' : 'error',
      OutgoingServerPort: outServerPort !== null ? 'success' : 'error',
    };
    this.setState({ validateStatus });
    if (!outServerHost || outServerPort === null) {
      return { validated: false };
    }
    return { validated: true };
  }

  onSave = () => {
    const { updateEmailSmtpConfig, deleteEmailConfig, isAdmin, updateEmailSmtpSettings, fetchEmailConfig,SetNotification } = this.props;
    const {
      OutgoingServerHost,
      OutgoingServerPort,
      Username,
      Password,
      EnableSsl,
      providerId,
      isThirdPartyEmailConfigurationAllowed,
      isMultipleSenderAddressConfigurationAllowed,
    } = this.state;
    const { validated, cleared } = this.validateForm();
    if (!validated) {
      return;
    }
    const updateConfigActions = [];
    if (cleared) {
      if (providerId) {
        updateConfigActions.push(
          deleteEmailConfig(providerId, { isAdmin }, () => {
            this.setState({ providerId: null, EnableSsl: false });
            fetchEmailConfig({ isAdmin });
          })
        );
      }
    }
    const emailUpdate = {
      Configuration: {
        EnableSsl: !!EnableSsl,
        Host: OutgoingServerHost.trim(),
        Port: OutgoingServerPort,
        Username: Username.trim(),
        Password: Password.trim(),
      },
      AuthScheme: PROVIDER_TYPE.manual,
    };
    if (this.isEmailSmtpConfigurationChanged())
      updateConfigActions.push(
        updateEmailSmtpSettings({
          IsMultipleSenderAddressConfigurationAllowed: isMultipleSenderAddressConfigurationAllowed,
          IsThirdPartyEmailConfigurationAllowed: isThirdPartyEmailConfigurationAllowed,
        })
      );
    if (this.isEmailConfigurationChanged() && !cleared) {
      updateConfigActions.push(
        updateEmailSmtpConfig(emailUpdate, { isAdmin, providerId }, ({ updatedProviderId }) => {
          this.setState({ providerId: updatedProviderId });
        })
      );
    }
    Promise.all(updateConfigActions)
      .then(() => SetNotification('SUCCESS', {
        messageId: 'savedSuccessfully',
      }))
      .catch(() => SetNotification('ERROR', {
        messageId: 'oopsSomethingJustWentWrong',
      }));
  };

  clearErrorMsg(field) {
    const { validateStatus } = this.state;
    const newValidateStatus = { ...validateStatus };
    newValidateStatus[field] = 'success';
    this.setState({
      validateStatus: newValidateStatus,
    });
  }

  isEmailSmtpConfigurationChanged() {
    const { emailSmtpConfiguration } = this.props;
    const { isMultipleSenderAddressConfigurationAllowed, isThirdPartyEmailConfigurationAllowed } = this.state;

    return (
      isMultipleSenderAddressConfigurationAllowed !==
      emailSmtpConfiguration.IsMultipleSenderAddressConfigurationAllowed ||
      isThirdPartyEmailConfigurationAllowed !== emailSmtpConfiguration.IsThirdPartyEmailConfigurationAllowed
    );
  }

  isEmailConfigurationChanged() {
    const { emailProviders } = this.props;
    let manualProviderConfig = getProviderConfig(emailProviders, PROVIDER_TYPE.manual);
    if (!manualProviderConfig) {
      manualProviderConfig = {
        Configuration: {},
      };
    }
    const { Configuration } = manualProviderConfig;
    const { OutgoingServerHost, OutgoingServerPort, Username, Password, EnableSsl } = this.state;
    return !(
      _.get(Configuration, 'Host', '') === OutgoingServerHost?.trim() &&
      _.get(Configuration, 'Port', null) === OutgoingServerPort &&
      _.get(Configuration, 'Username', '') === Username?.trim() &&
      _.get(Configuration, 'EnableSsl', false) === EnableSsl &&
      (Password === Configuration.Password || !Password)
    );
  }

  handleChange(key, e) {
    if (key === 'OutgoingServerPort') {
      this.setState({
        [key]: e,
      });
      this.clearErrorMsg('OutgoingServerHost');
      return;
    }
    this.setState({
      [key]: e.target.value,
    });
    this.clearErrorMsg('OutgoingServerPort');
  }

  handleSSLChange(e) {
    this.setState({
      EnableSsl: e.target.checked,
    });
  }

  handleRadioChange = e => {
    this.setState({
      activeRadio: e.target.value,
    });
  };

  getEmailSmtpConfigurationButtons = () => {
    const { activeRadio } = this.state;
    const allowedSmtpOptions = getAllowedSmtpOptions({
      manualValue: PROVIDER_TYPE.manual,
    });

    return (
      <div className="email-settings-row">
        <Radio.Group value={activeRadio} onChange={this.handleRadioChange}>
          {allowedSmtpOptions.map(smtpOption => (
            <Radio
              value={smtpOption.value}
              key={smtpOption.value}
              style={{ color: 'rgba(7, 16, 26, 0.9)', fontWeight: 500 }}
            >
              {smtpOption.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    );
  };

  onMultipleSenderAddressConfigurationChange = e => {
    this.setState({
      isMultipleSenderAddressConfigurationAllowed: e.target.checked,
    });
  };

  onThirdPartyConfigurationChange = e => {
    this.setState({
      isThirdPartyEmailConfigurationAllowed: e.target.checked,
    });
  };

  getIsSaveButtonDisabled = () => {
    return !this.isEmailSmtpConfigurationChanged() && !this.isEmailConfigurationChanged();
  };

  isSslDisabled = () => {
    const { OutgoingServerHost, OutgoingServerPort } = this.state;
    return !OutgoingServerHost || !OutgoingServerPort;
  };

  render() {
    const {
      version,
      userEmails,
      currentUser,
      orgDefaultEmail,
      emailSettingsApiStatus,
      emailConfigurationApiStatus,
      intl
    } = this.props;
    const {
      OutgoingServerHost,
      OutgoingServerPort,
      Username,
      Password,
      EnableSsl,
      validateStatus,
      activeRadio,
      showLoadingScreen,
      isThirdPartyEmailConfigurationAllowed,
      isMultipleSenderAddressConfigurationAllowed,
    } = this.state;
    const title = version === 'ats' ? 'Admin ' : ''
    const emailConfigurationTitle = <FormattedMessage {...emailSetting.EmailConfigurationLabel} values={{title}}/>;
    return (
      <div>
        <div className="email-configuration-header">
          <div className="email-configuration-title">{emailConfigurationTitle}</div>
          <div className={styles.defaultEmailConfig}>
            <div className={styles.infoIconWithContent}>
              <div className={styles.defaultEmailTitle}><FormattedMessage {...emailSetting.defaultEmailConfigurationLabel} />&nbsp;</div>
              <InfoIconWithTooltip tooltipTitle={<FormattedMessage {...emailSetting.infoIconTooltipLabel} />} />
            </div>
            <DefaultSmtpComponent userEmails={userEmails} orgDefaultEmail={orgDefaultEmail} currentUser={currentUser} />
          </div>
        </div>
        <div className="email-settings-container">
          <div className="email-settings-admin">
            <div className={styles.infoIconWithContent}>
              <div className={styles.defaultEmailTitle}><FormattedMessage {...emailSetting.customEmailConfigurationLabel} />&nbsp;</div>
              <InfoIconWithTooltip tooltipTitle={<FormattedMessage {...emailSetting.tooltipForCustomMailLabel} />} />
            </div>
            <Button
              className="app-save"
              onClick={this.onSave}
              loading={emailSettingsApiStatus === 'INPROGRESS' || emailConfigurationApiStatus === 'INPROGRESS'}
              disabled={this.getIsSaveButtonDisabled()}
            >
              <FormattedMessage {...emailSetting.saveChangesLabel} />
            </Button>
          </div>
          <Skeleton loading={!this.isInitialValuesSet || showLoadingScreen} active paragraph={{ rows: 5 }}>
            {this.getEmailSmtpConfigurationButtons()}
            {activeRadio === PROVIDER_TYPE.manual ? (
              <div>
                <div className={styles.emaiSettingBox}>
                  <div className="email-settings-row">
                    <Form.Item
                      label={<FormattedMessage {...emailSetting.sMTPServerLabel} />}
                      validateStatus={validateStatus.OutgoingServerHost}
                      help={validateStatus.OutgoingServerHost === <FormattedMessage {...emailSetting.errorLabel} /> ? <FormattedMessage {...emailSetting.enterSmtpServerLabel} /> : null}
                      colon={false}
                      required
                    >
                      <Input
                        id="smtp-server-input"
                        value={OutgoingServerHost}
                        onChange={e => this.handleChange('OutgoingServerHost', e)}
                        onFocus={() => this.clearErrorMsg('OutgoingServerHost')}
                        placeholder={intl.formatMessage({ ...placeholder.SmtpServerLabel })}
                        className=""
                      />
                    </Form.Item>
                    <Form.Item
                      label={<FormattedMessage {...emailSetting.sMTPPortLabel} />}
                      validateStatus={validateStatus.OutgoingServerPort}
                      help={validateStatus.OutgoingServerPort === <FormattedMessage {...emailSetting.errorLabel} /> ? <FormattedMessage {...emailSetting.enterSmtpPortLabel} /> : null}
                      colon={false}
                      required
                    >
                      <InputNumber
                        className="smtp-port-input"
                        value={OutgoingServerPort}
                        onChange={e => this.handleChange('OutgoingServerPort', e)}
                        onFocus={() => this.clearErrorMsg('OutgoingServerPort')}
                        placeholder={intl.formatMessage({ ...placeholder.smtpPortLabel })}
                      />
                    </Form.Item>
                  </div>
                  <div className={styles.emailSettingsLastRow}>
                    <Form.Item label={<FormattedMessage {...emailSetting.userNameLabel} />} colon={false}>
                      <Input
                        id="username-input"
                        value={Username}
                        autoComplete="false"
                        onChange={e => this.handleChange('Username', e)}
                        onFocus={() => this.clearErrorMsg('Username')}
                        placeholder={intl.formatMessage({ ...placeholder.userNameLabel })}
                      />
                      <span className={styles.formInputHelperText}>
                        <FormattedMessage {...emailSetting.optionalIfSMTPServerLabel} />
                      </span>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage {...emailSetting.passwordLabel} />} colon={false}>
                      <Input
                        id="password-input"
                        value={Password}
                        type="password"
                        autoComplete="new-password"
                        onChange={e => this.handleChange('Password', e)}
                        onFocus={() => this.clearErrorMsg('Password')}
                        placeholder={intl.formatMessage({ ...placeholder.passwordLabel })}
                      />
                      <span className={styles.formInputHelperText}>
                        <FormattedMessage {...emailSetting.optionalIfSMTPServerLabel} />
                      </span>
                    </Form.Item>
                  </div>
                  <Checkbox checked={EnableSsl} onChange={this.handleSSLChange} disabled={this.isSslDisabled()}>
                    <FormattedMessage {...emailSetting.enableSSlLable} />
                  </Checkbox>
                </div>
                <div className={styles.smtpSettings}>
                  <div className={styles.additionalSettings}><FormattedMessage {...emailSetting.additionalSettingsLabel} /></div>
                  <Checkbox
                    checked={isThirdPartyEmailConfigurationAllowed}
                    onChange={this.onThirdPartyConfigurationChange}
                  >
                    <FormattedMessage {...emailSetting.enableThirdpartyEmailConfigurationLabel} />
                  </Checkbox>
                  <Checkbox
                    checked={isMultipleSenderAddressConfigurationAllowed}
                    onChange={this.onMultipleSenderAddressConfigurationChange}
                  >
                    <FormattedMessage {...emailSetting.enableEndUsersToConfigureLable} />
                  </Checkbox>
                </div>
              </div>
            ) : null}
          </Skeleton>
        </div>
      </div>
    );
  }
}
EmailSettings.defaultProps = {
  isAdmin: false,
  emailProviders: [],
};

export default injectIntl(EmailSettings);
