import React from 'react';
import _ from 'lodash';
import { FormRadioGroupComponent, FormCheckboxGroupComponent } from '../../../Utils/FormComponentUtils';
import FormPanelComponent from '../../FormPanelComponent/FormPanelComponent';
import CareerBuilderEdgeOptions from '../../../Data/AdvancedOptions/CareerBuilderEdge/CareerBuilderEdgeOptions.json';
import CareerBuilderEdgeInputs from '../../../Data/AdvancedOptions/CareerBuilderEdge/CareerBuilderEdgeInputs.json';
import { formValuesMapper, generateValueCountFromInputs } from '../../../Utils/MapperUtils';
import {
  getSourceResultCount,
  aggregationKeysToDisplayNameMapper,
  getCurrentSearchResult,
} from '../../../Utils/AggregationFilterUtils';

import './CareerBuilderEdgeFilters.scss';
import FilterAryaSearch from '../../FilterAryaSearch/FilterAryaSearch';

export default function CareerBuilderEdgeFilters(props) {
  const { CurrentlyEmployedOptions } = CareerBuilderEdgeOptions;
  const { ContactInfoParams, CurrentlyEmployedParams, SearchResultsParams } = CareerBuilderEdgeInputs;
  const {
    form,
    defaultSettings,
    candidateAggregations,
    sourceName,
    candidateCollapsedFilters,
    isAdvancedSearchV2Enabled,
    candidateContext,
    maxCandidateCountInAdvanceSearch,
  } = props;
  const { ContactInfo, CurrentlyEmployed } = defaultSettings;
  const {
    Source,
    Skill,
    AcademicDiscipline,
    DegreeLevel,
    City,
    Country,
    State,
    Industry,
    CaroteneV3Title,
    CurrentEmployer,
    PreviousEmployer,
  } = candidateAggregations;

  const groupedFilterKeys = {
    'Candidate Information': _.pickBy({ Source, Skill }, value => value),
    Education: _.pickBy({ AcademicDiscipline, DegreeLevel }, value => value),
    Location: _.pickBy({ City, Country, State }, value => value),
    Employement: _.pickBy({ Industry, CaroteneV3Title, CurrentEmployer, PreviousEmployer }, value => value),
  };

  const resumeCount = getSourceResultCount(candidateAggregations.HasResume);
  const socialSourceCount = getSourceResultCount(candidateAggregations.HasSocialSource);

  const searchResultsOptions = [
    { Value: 'All Results', Count: resumeCount.hasSourceTrueCount + resumeCount.hasSourceFalseCount },
    { Value: 'Resume Results', Count: resumeCount.hasSourceTrueCount },
    { Value: 'Social Results', Count: socialSourceCount.hasSourceTrueCount },
  ];

  const maxCount = maxCandidateCountInAdvanceSearch ?? 1000;

  return (
    <div className="Career-builder-edge-filter-and-collapsed-filter">
      <div className="Career-builder-edge-filter">
        <div className="career-builder-edge-primary-filter">
          <FormRadioGroupComponent
            {...SearchResultsParams}
            form={form}
            initialValue={getCurrentSearchResult(defaultSettings)}
            inputValues={generateValueCountFromInputs({
              inputValues: searchResultsOptions,
              maxCount,
            })}
          />
          <FormRadioGroupComponent
            {...CurrentlyEmployedParams}
            form={form}
            initialValue={CurrentlyEmployed}
            inputValues={formValuesMapper(CurrentlyEmployedOptions)}
          />
          <FormCheckboxGroupComponent
            {...ContactInfoParams}
            form={form}
            initialValue={ContactInfo}
            inputValues={generateValueCountFromInputs({
              inputValues: candidateAggregations?.ContactInfo,
              maxCount,
            })}
          />
        </div>
        <FormPanelComponent
          form={form}
          groupedFilterKeys={groupedFilterKeys}
          fieldDecoratorKey="cbedgefilter"
          values={candidateAggregations}
          labelDisplayNames={aggregationKeysToDisplayNameMapper[sourceName?.toLowerCase()]}
          defaultSettings={defaultSettings}
        />
      </div>
      {isAdvancedSearchV2Enabled ? (
        <div className="career-builder-edge-collapsed-filter">
          <FilterAryaSearch
            form={form}
            candidateCollapsedFilters={candidateCollapsedFilters}
            candidateContext={candidateContext}
          />
        </div>
      ) : null}
    </div>
  );
}
