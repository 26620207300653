import { payV1Client } from './BaseRepository';

export function fetchAvailableCredits(productVariantId) {
  let url = '/Credits';
  if (productVariantId) url += `?productVariantId=${productVariantId}`;
  return payV1Client.get(url);
}

export function createPayment(paymentData) {
  return payV1Client.post('/Payments', paymentData);
}

export function fetchPaymentProfile() {
  return payV1Client.get('/PaymentProfile');
}

export function placeOrder(orderDetails) {
  return payV1Client.post('/orders', orderDetails);
}

export function savePaymentProfile(cardDetails) {
  return payV1Client.post('/PaymentProfile', cardDetails);
}

export function deletePaymentProfile(paymentProfileId) {
  return payV1Client.delete(`/PaymentProfile/${paymentProfileId}`);
}

export function updateBillingInfo(paymentProfileId, Address) {
  return payV1Client.put(`/PaymentProfile/${paymentProfileId}`, Address);
}

export function getOrders(query) {
  return payV1Client.get('/orders', { params: query });
}

export function getOrderDetails(orderId) {
  return payV1Client.get(`/orders/${orderId}`);
}

export function cancelOrder(orderId, orderDetails) {
  return payV1Client.post(`orders/${orderId}/_cancel`, orderDetails);
}

export function requestCredits(filter) {
  return payV1Client.post('/credits', filter);
}
