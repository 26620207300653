import React from 'react';
import styles from './EmailTemplateIdentifier.module.scss';

const getEmailTemplateIdentifier = (isDrip, isSystemTemplate, isDefault, isWorkflow) => {
  let emailTemplateIdentifier;
  if (isDrip) {
    emailTemplateIdentifier = <span className={styles.templateIdentifer}>Drip</span>;
  }
  if (isWorkflow) {
    emailTemplateIdentifier = <span className={styles.templateIdentifer}>Workflow</span>;
  }
  if (isSystemTemplate) {
    emailTemplateIdentifier = <span className={styles.templateIdentifer}>Recommended</span>;
  }
  if (isDefault) {
    emailTemplateIdentifier = <span className={styles.templateIdentifer}>Default</span>;
  }
  if (isSystemTemplate && isDefault) {
    emailTemplateIdentifier = (
      <>
        <span className={styles.templateIdentifer}>Default</span>&#160;
        <span className={styles.templateIdentifer}>Recommended</span>
      </>
    );
  }
  return emailTemplateIdentifier;
};

export { getEmailTemplateIdentifier };
