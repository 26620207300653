import React from 'react';
import _ from 'lodash';
import { Divider,Form } from 'antd';
import { injectIntl } from 'react-intl';
import CompanyInsightView from './CompanyInsightView';
import IndustryInsightView from './IndustryInsightView';
import RoleInsightView from './RoleInsightView';
import SkillsInsightView from './SkillsInsightView';
import LocationsInsightView from './LocationsInsightView';
import ExperienceInsightView from './ExperienceInsightView';
import EducationInsightView from './EducationInsightView';
import CertificationsInsightView from './CertificationsInsightView';
import { getMapingStatsList, getExperienceLabelName } from '../../../Utils/DashBoardUtils';
import { menuItemsList } from '../../../Utils/GlobalInsightSupplyUtil';

import styles from './Insight.module.scss';

 function GlobalInsightList(props) {
  const {
    globalInsightSupplyList,
    supplyPayload,
    loading,
    hiddenMenuList = [],
    readonlyStats,
    emptyInsightDescription,
    intl,
  } = props;
  const {
    CompaniesStats,
    IndustriesStats,
    SimilarTitlesStats,
    SkillsStats,
    States,
    Cities,
    EducationsStats,
    ExperienceRangeStats,
    CertificationsStats,
    Total,
    LocationSpecificProfileCount,
  } = globalInsightSupplyList;

  const listOfCompaniesStats = getMapingStatsList(CompaniesStats, 'CompanyName', Total);
  const listOfIndustriesStats = getMapingStatsList(IndustriesStats, 'IndustryName', Total);
  const listOfSimilarTitlesStats = getMapingStatsList(SimilarTitlesStats, 'SimilarTitle', Total);
  const listOfSkillsStats = getMapingStatsList(SkillsStats, 'SkillName', Total);
  const listOfCertifications = getMapingStatsList(CertificationsStats, 'Certification', Total);
  const experienceRanges = getMapingStatsList(ExperienceRangeStats, 'Range', Total);
  const listOfExperienceRangeStats = experienceRanges
    .filter(obj => obj.Count > 0)
    .map(obj => ({
      ...obj,
      Name: getExperienceLabelName(obj.Name, '<',intl),
      DisplayName: getExperienceLabelName(obj.Name, 'less than',intl),
    }));
  const listOfEducationStats = getMapingStatsList(EducationsStats, 'Degree', Total);
  const listOfMapStates = [];
  const listOfMapCities = [];
  if (!_.isEmpty(supplyPayload.State)) {
    const citiesList = getMapingStatsList(Cities, 'CityName', Total);
    listOfMapCities.push(...citiesList);
    const totalCount = _.sumBy(listOfMapCities, 'Count');
    const stateName = _.trim(supplyPayload.State);
    const statesList = {
      StateName: stateName,
      Name: stateName,
      title: stateName,
      DisplayName: stateName,
      Count: totalCount,
    };
    listOfMapStates.push(statesList);
  } else {
    const statesList = getMapingStatsList(States, 'StateName', Total);
    listOfMapStates.push(...statesList);
  }
  if (!_.isEmpty(supplyPayload.City) && !supplyPayload.Distance) {
    const cityName = _.trim(supplyPayload.City);
    const locationSpecificCount = LocationSpecificProfileCount ?? Total;
    const citiesList = [
      {
        CityName: cityName,
        Count: locationSpecificCount,
      },
    ];
    listOfMapCities.push(getMapingStatsList(citiesList, 'CityName', locationSpecificCount)[0]);
  }
  const getEmptyInsightDescription = sectionName => {
    if (!emptyInsightDescription) {
      return null;
    }
    return emptyInsightDescription(sectionName);
  };

  return (
    <div>
      <div id="globalInsight">
        {!hiddenMenuList.includes(menuItemsList.industries.id) && (
          <>
            <IndustryInsightView
              listOfIndustriesStats={listOfIndustriesStats}
              loading={loading}
              readonlyStats={readonlyStats}
              emptyInsightDescription={getEmptyInsightDescription(menuItemsList.industries.sectionName)}
            />
            <Divider className={styles.supplyContentDivider} />
          </>
        )}
        {!hiddenMenuList.includes(menuItemsList.similarRoles.id) && (
          <>
            <RoleInsightView
              listOfSimilarTitlesStats={listOfSimilarTitlesStats}
              loading={loading}
              readonlyStats={readonlyStats}
              emptyInsightDescription={getEmptyInsightDescription(menuItemsList.similarRoles.sectionName)}
              displayCount={15}
            />
            <Divider className={styles.supplyContentDivider} />
          </>
        )}
        {!hiddenMenuList.includes(menuItemsList.companies.id) && (
          <>
            <CompanyInsightView
              listOfCompaniesStats={listOfCompaniesStats}
              loading={loading}
              totalCount={Total}
              readonlyStats={readonlyStats}
              emptyInsightDescription={getEmptyInsightDescription(menuItemsList.companies.sectionName)}
            />
            <Divider className={styles.supplyContentDivider} />
          </>
        )}
        {!hiddenMenuList.includes(menuItemsList.marketSkills.id) && (
          <>
            <SkillsInsightView
              listOfSkillsStats={listOfSkillsStats}
              totalCount={Total}
              loading={loading}
              readonlyStats={readonlyStats}
              emptyInsightDescription={getEmptyInsightDescription(menuItemsList.marketSkills.sectionName)}
            />
            <Divider className={styles.supplyContentDivider} />
          </>
        )}
        {!hiddenMenuList.includes(menuItemsList.experience.id) && (
          <>
            <ExperienceInsightView
              listOfExperienceRangeStats={listOfExperienceRangeStats}
              loading={loading}
              readonlyStats={readonlyStats}
              emptyInsightDescription={getEmptyInsightDescription(menuItemsList.experience.sectionName)}
            />
            <Divider className={styles.supplyContentDivider} />
          </>
        )}
        {!hiddenMenuList.includes(menuItemsList.location.id) && (
          <>
            <LocationsInsightView
              listOfMapStates={listOfMapStates}
              listOfMapCities={listOfMapCities}
              supplyPayload={supplyPayload}
              loading={loading}
              readonlyStats={readonlyStats}
              emptyInsightDescription={getEmptyInsightDescription(menuItemsList.location.sectionName)}
            />
            <Divider className={styles.supplyContentDivider} />
          </>
        )}
        {!hiddenMenuList.includes(menuItemsList.education.id) && (
          <>
            <EducationInsightView
              listOfEducationStats={listOfEducationStats}
              loading={loading}
              readonlyStats={readonlyStats}
              emptyInsightDescription={getEmptyInsightDescription(menuItemsList.education.sectionName)}
            />
            <Divider className={styles.supplyContentDivider} />
          </>
        )}
        {!hiddenMenuList.includes(menuItemsList.certifications.id) && (
          <>
            <CertificationsInsightView
              listOfCertifications={listOfCertifications}
              totalCount={Total}
              loading={loading}
              readonlyStats={readonlyStats}
              emptyInsightDescription={getEmptyInsightDescription(menuItemsList.certifications.sectionName)}
            />
            <Divider className={styles.supplyContentDivider} />
          </>
        )}
      </div>
    </div>
  );
}


export default (injectIntl(Form.create()(GlobalInsightList)));
export {GlobalInsightList as GlobalInsightListWithoutInjectIntl}
