import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import { NetworkErrorComponent } from '../../Components/ErrorComponent/NetworkErrorComponent';
import { getKnowledgeBaseAuthorizationToken } from '../../Actions/UserSessionActions';
import { getKnowledgeBaseAuthorizationTokenApiStatus } from '../../Reducers/UserSessionReducer';
import Config from '../../Config/Config';

const mapStateToProps = state => ({
  knowledgeBaseAuthorizationTokenStatus: getKnowledgeBaseAuthorizationTokenApiStatus(state),
});

const mapDispatchToProps = { knowledgeBaseAuthToken: getKnowledgeBaseAuthorizationToken };

function Help({ knowledgeBaseAuthToken, knowledgeBaseAuthorizationTokenStatus, location }) {
  const iframeContainer = React.useRef(null);
  const iFrameRef = React.useRef(null);
  const [iframeSrc, setIframeSrc] = React.useState('');
  const [iframeLoaded, setIframeLoaded] = React.useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  React.useEffect(() => {
    const fetchKnowledgeBaseAuthToken = async () => {
      const authToken = await knowledgeBaseAuthToken();
      const handler = event => {
        if (iFrameRef.current && iFrameRef.current.contentWindow) {
          const msg = {
            cognitotoken: authToken.cognitoToken,
            usertoken: authToken.userToken,
            username: authToken.userName,
            roles: authToken.roles,
          };
          iFrameRef.current.contentWindow.postMessage(JSON.stringify(msg), Config.urls.knowledgeBase);
        }
      };

      window.addEventListener('message', handler);
      const queryParams = queryString.parse(location.search);
      const { articleId } = queryParams;
      const knowledgeBaseDefaultUrl = Config.urls.knowledgeBase;
      const knowledgeBaseUrl = articleId
        ? `${knowledgeBaseDefaultUrl}/viewArticles?articleId=${articleId}`
        : knowledgeBaseDefaultUrl;
      setIframeSrc(knowledgeBaseUrl);

      return () => {
        window.removeEventListener('message', handler);
      };
    };
    fetchKnowledgeBaseAuthToken();
  }, []);

  return (
    <>
      {(!iframeLoaded || knowledgeBaseAuthorizationTokenStatus?.status === 'INPROGRESS') &&
      knowledgeBaseAuthorizationTokenStatus?.status !== 'FAILED' ? (
        <Skeleton active loading paragraph={{ rows: 16 }} data-testid="skeleton-element"></Skeleton>
      ) : null}
      <div style={{ margin: '0' }}>
        {knowledgeBaseAuthorizationTokenStatus?.status === 'FAILED' ? (
          <NetworkErrorComponent />
        ) : (
          <div ref={iframeContainer}>
            <iframe
              data-testid="knowledge-base-iframe"
              style={{
                border: 'none',
                height: 'calc(100vh - var(--navbar-height) - var(--footer-height))',
                width: '100vw',
              }}
              title="knowledge-base"
              src={iframeSrc}
              ref={iFrameRef}
              onLoad={handleIframeLoad}
              allow="clipboard-write"
            ></iframe>
          </div>
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Help);
export { Help as HelpWithoutStore };
