import React from 'react';
import ConnectContainer from './Connect';
import WorkflowDetailsContainer from '../WorkflowDetailsContainer/WorkflowDetailsContainer';

export function ConnectUrlMapper(props) {
  const { setUrl, type, tab, location, workflowId, createWorkflowId, atsProps } = props;
  const { version } = atsProps;

  if (tab === 'workflows' && (type || workflowId)) {
    const containerProps = {
      location,
      setUrl,
      workflowId: workflowId ?? createWorkflowId,
      version,
      type,
    };
    return <WorkflowDetailsContainer {...containerProps} />;
  }

  return tab ? (
    <ConnectContainer location={location} setUrl={setUrl} defaultActiveTab={tab} {...{ ...atsProps }} />
  ) : (
    <ConnectContainer location={location} setUrl={setUrl} {...{ ...atsProps }} />
  );
}

export default ConnectUrlMapper;
