import { apiV1Client } from './BaseRepository';

export function fetchSubSegments(segmentId, searchTerm, pageNumber, size) {
  let url = `Segments/${segmentId}/SubSegments`;
  let queryParamString = '?';
  let queryParamsAdded = 0;
  if (searchTerm) {
    queryParamString = queryParamString.concat(`SearchTerm=${searchTerm}`);
    queryParamsAdded += 1;
  }
  if (pageNumber) {
    const from = pageNumber >= 1 ? (pageNumber - 1) * size : 0;
    if (queryParamsAdded > 0) queryParamString = queryParamString.concat('&');
    queryParamString = queryParamString.concat(`from=${from}`);
    queryParamsAdded += 1;
  }
  if (size) {
    if (queryParamsAdded > 0) queryParamString = queryParamString.concat('&');
    queryParamString = queryParamString.concat(`size=${size}`);
    queryParamsAdded += 1;
  }
  if (queryParamString.length > 1) url = url.concat(queryParamString);
  return apiV1Client.get(url);
}

export function createSubSegment(segmentId, payload) {
  return apiV1Client.post(`Segments/${segmentId}/SubSegments`, payload);
}

export function updateSubSegment(segmentId, subSegmentId, payload) {
  return apiV1Client.put(`Segments/${segmentId}/SubSegments/${subSegmentId}`, payload);
}
