import React from 'react';
import { Select, InputNumber, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './ResumeLastUpdated.module.scss';
import TextWithInfoIcon from '../../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';
import message from '../../../ManualSearchMessages';

const { Option } = Select;
const { Item } = Form;

function ResumeLastUpdated(props) {
  const { form, resumeUpdateDurationType, resumeUpdateDurationQuantity } = props;
  const durationTypeSelect = form.getFieldDecorator('ResumeUpdateDurationType', {
    initialValue: resumeUpdateDurationType,
  })(
    <Select className={styles.durationTypeSelect} placeholder="Select duration">
      <Option value="Days">
        <FormattedMessage {...message.daysLabel} />
      </Option>
      <Option value="Months">
        <FormattedMessage {...message.monthsLabel} />
      </Option>
      <Option value="Years">
        <FormattedMessage {...message.years} />
      </Option>
    </Select>
  );
  return (
    <>
      <TextWithInfoIcon
        text={
          <div className={styles.resumeLastUpdatedLabelText}>
            <FormattedMessage {...message.resumeLastUpdated} />
          </div>
        }
        tooltipTitle={<FormattedMessage {...message.resumeLastUpdatedInfoMessage} />}
      />
      <div className={styles.resumeWrapper}>
        <div className={styles.durationLabel}>
          <FormattedMessage {...message.withinLabel} />
        </div>
        <Item colon={false}>
          {form.getFieldDecorator('ResumeUpdateDurationQuantity', { initialValue: resumeUpdateDurationQuantity })(
            <InputNumber min={1} className={styles.durationTypeQuantity} />
          )}
          {durationTypeSelect}
        </Item>
      </div>
    </>
  );
}

export default ResumeLastUpdated;
