import { connectV1Client } from './BaseRepository';

export function fetchFrequentlyAnsweredQuestions() {
  return connectV1Client.get('/BotFrequentlyAnsweredQuestions');
}

export function getContext(contextId) {
  return connectV1Client.get(`/BotContexts/${contextId}`);
}

export function createContext(type, contextEntityId, generateQuestions, answers) {
  return connectV1Client.post('/BotContexts', {
    Type: type,
    ContextEntityId: contextEntityId,
    Answers: answers,
    GenerateQuestions: generateQuestions ?? false,
  });
}

export function getQuestions(contextId) {
  return connectV1Client.get(`/BotContexts/${contextId}/questions`);
}

export function postQuestions(contextId, questions) {
  return connectV1Client.post(`/BotContexts/${contextId}/questions`, questions);
}

export function deleteQuestion(contextId, questionId) {
  return connectV1Client.delete(`/BotContexts/${contextId}/questions/${questionId}`);
}

export function getAnswers(contextId) {
  return connectV1Client.get(`/BotContexts/${contextId}/answers`);
}

export function postAnswers(contextId, answers) {
  return connectV1Client.post(`/BotContexts/${contextId}/answers`, answers);
}

export function putAnswers(contextId, answers) {
  return connectV1Client.put(`/BotContexts/${contextId}/answers`, answers);
}

export function fetchGeneratedQuestions(contextId, jobDescription) {
  return connectV1Client.post(`/BotContexts/${contextId}/_generateQuestions`, { JobDescription: jobDescription });
}
