import React from 'react';
import { Icon } from 'antd';
import {  FormattedMessage } from 'react-intl';
import styles from './AddEmailConfiguration.module.scss';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';

export default function AddEmailConfiguration(props) {
  const { onAddNewEmailConfiguration, emailSmtpConfiguration } = props;
  const { IsMultipleSenderAddressConfigurationAllowed } = emailSmtpConfiguration;
  return (
    <div>
      {IsMultipleSenderAddressConfigurationAllowed ? (
        <div
          role="button"
          tabIndex={0}
          className={styles.addEmailAddressWrapper}
          onClick={onAddNewEmailConfiguration}
          onKeyPress={onAddNewEmailConfiguration}
        >
          <Icon type="plus" className={styles.addEmailAddressIcon} />
          <FormattedMessage {...messages.addNewEmailAddressLabel} />
        </div>
      ) : null}
    </div>
  );
}

export { AddEmailConfiguration as AddEmailConfigurationWithoutStore };
