import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Progress } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import './SourcingProgressIndicator.scss';
import messages from './messages';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';

function isSourceLimitReached(totalCandidateCount, SourceLimit) {
  return totalCandidateCount >= SourceLimit;
}

function SourcingProgressIndicator(props) {
  let timeDiff;
  let progressBarPercentage = 0;
  let progressBarColor;
  let message;
  let progresStatus;
  let progressBarRemainingClass;
  let totalCandidateCount = 0;
  const { sourcingStatus, recentSourcedTime, lastChangeTime, jobSourcingStats, featureToggleList } = props;
  if (jobSourcingStats) {
    totalCandidateCount = jobSourcingStats.Sourced + jobSourcingStats.Shortlisted + jobSourcingStats.Connected;
    progressBarPercentage = (totalCandidateCount * 100) / jobSourcingStats.SourceLimit;
    // progressBarPercentage = progressBarPercentage === 0 ? 5 : progressBarPercentage;
    const recentSourced = moment.utc(recentSourcedTime).local();
    const lastChange = moment.utc(lastChangeTime).local();
    let lastSourcedTime = lastChange;
    if (recentSourcedTime) {
      lastSourcedTime = moment.max(recentSourced, lastChange);
    }
    timeDiff = moment().diff(lastSourcedTime, 'days');
  }
  if (!sourcingStatus) {
    message = <FormattedMessage {...messages.off} />;
    progressBarColor = '#B4B7BA';
    progressBarRemainingClass = 'off';
    progresStatus = 'normal';
  } else {
    const isAdvanceSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
    progresStatus = 'active';
    if (timeDiff < 2 || totalCandidateCount > 0) {
      message = isAdvanceSearchV2Enabled ? (
        <FormattedMessage {...messages.v2EnabledSourcing} />
      ) : (
        <FormattedMessage {...messages.sourcing} />
      );
      if (isSourceLimitReached(totalCandidateCount, jobSourcingStats.SourceLimit) || timeDiff > 2) {
        message = isAdvanceSearchV2Enabled ? (
          <FormattedMessage {...messages.v2EnabledSourced} />
        ) : (
          <FormattedMessage {...messages.sourced} />
        );
        progresStatus = 'normal';
      }
      progressBarColor = '#13C26B';
      progressBarRemainingClass = 'sourcing';
    } else {
      message = isAdvanceSearchV2Enabled ? (
        <FormattedMessage {...messages.v2EnabledReview} />
      ) : (
        <FormattedMessage {...messages.review} />
      );
      progressBarColor = '#FAAF40';
      progressBarRemainingClass = 'review';
    }
  }

  return (
    <div className={`arya-status ${progressBarRemainingClass}`}>
      <div className="sourcing-status-text">{message}</div>
      <Progress
        percent={progressBarPercentage}
        strokeColor={progressBarColor}
        showInfo={false}
        size="small"
        status={progresStatus}
      />
    </div>
  );
}

SourcingProgressIndicator.propTypes = {
  jobSourcingStats: PropTypes.shape({}),
  recentSourcedTime: PropTypes.string,
  lastChangeTime: PropTypes.string,
  sourcingStatus: PropTypes.bool,
};

SourcingProgressIndicator.defaultProps = {
  jobSourcingStats: undefined,
  recentSourcedTime: undefined,
  lastChangeTime: undefined,
  sourcingStatus: false,
};

const mapStateToProps = state => {
  return {
    featureToggleList: getFeatureToggleList(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SourcingProgressIndicator);
export { SourcingProgressIndicator as SourcingProgressIndicatorWithoutStore };
