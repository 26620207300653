import { apiV3Client, apiV1Client } from './BaseRepository';

export function monitorUserEmailActivity(payload) {
  return apiV3Client.post(`UserActivity/_emailclick`, payload);
}

export function saveAdvanceSearchActivity(payload) {
  const { jobId, orgId, Pagename, booleanUsage, email, Producttype, isPrefetch, criteriaId } = payload;
  return apiV1Client.post(`/candidates/searchCriteria/_advancedsearchactivities`, {
    JobId: jobId,
    OrgId: orgId,
    PageName: Pagename,
    IsBoolean: booleanUsage,
    UserEmailId: email,
    ProductType: Producttype,
    IsPrefetch: isPrefetch,
    CriteriaId: criteriaId,
  });
}
