import _ from 'lodash';
import * as actionCreators from './ActionCreators/WorkflowActionCreator';
import * as workflowDripTemplatesRepo from '../Repository/WorkflowRepository';
import { fetchConnectUsers } from './UserActions';
import { setNotification } from './ActionCreators/ConnectActions';

function searchWorkflowTemplates(filter, isFetchingTemplatesForAutomation = false, dispatchApiStatus = true) {
  return async dispatch => {
    if (dispatchApiStatus) {
      dispatch(actionCreators.setWorkflowTemplatesFetchApiStatus('InProgress'));
    }
    try {
      const response = await workflowDripTemplatesRepo.fetchWorkflowTemplates({ ...filter, LanguageCode: 'en-US' });
      const { Items, TotalCount } = response.data;
      if (!isFetchingTemplatesForAutomation || filter.From === 0) {
        dispatch(actionCreators.setWorkflowDripTemplates(Items));
      } else {
        dispatch(actionCreators.appendWorkflowDripTemplates(Items));
      }
      // !! TODO
      dispatch(
        fetchConnectUsers({
          userIds: _.uniq(Items.map(workflow => workflow?.CreatedBy)),
        })
      );
      dispatch(actionCreators.setWorkflowTemplatesCount(TotalCount));
      if (dispatchApiStatus) {
        dispatch(actionCreators.setWorkflowTemplatesFetchApiStatus('Completed'));
      }
    } catch (errorResponse) {
      const isRequestCancelled = errorResponse.message === 'Request Cancelled';
      if (!isRequestCancelled) {
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      }
    }
  };
}

function editWorkflowDripTemplate(workflowId, workflow, filter) {
  return dispatch => {
    dispatch(actionCreators.setLoadingNotificationMessage('updatingWorkflow'));
    workflowDripTemplatesRepo.putWorkflowDripTemplate(workflowId, workflow).then(() => {
      dispatch(searchWorkflowTemplates(filter, false, false));
      dispatch(actionCreators.setSuccessNoificationMessage('workflowUpdated'));
    });
  };
}

function saveWorkflowTemplate(workflow) {
  return async dispatch => {
    try {
      dispatch(actionCreators.setLoadingNotificationMessage('creatingWorkflow'));
      await workflowDripTemplatesRepo.saveWorkflowTemplate(workflow);
      dispatch(actionCreators.setSuccessNoificationMessage('workflowCreated'));
    } catch (error) {
      if (error.response.status === 409) {
        dispatch(
          setNotification('ERROR', {
            messageId: 'workflowWithNameAlreadyExists',
            mergeTags: { workflow: workflow.Name },
          })
        );
      }
      throw error;
    }
  };
}
function fetchWorkflowTemplateById(workflowId) {
  return async dispatch => {
    try {
      dispatch(actionCreators.setWorkflowTemplateFetchApiStatus(workflowId, 'InProgress'));
      const response = await workflowDripTemplatesRepo.fetchWorkflowTemplateById(workflowId);
      dispatch(actionCreators.setWorkflowTemplateDetails(response.data));
      dispatch(actionCreators.setWorkflowTemplateFetchApiStatus(workflowId, 'Completed'));
    } catch {
      dispatch(actionCreators.setWorkflowTemplateFetchApiStatus(workflowId, 'Failed'));
    }
  };
}

function setWorkflowTemplateFetchApiStatus({ workflowId, status }) {
  return dispatch => {
    dispatch(actionCreators.setWorkflowTemplateFetchApiStatus(workflowId, status));
  };
}

function deleteWorkflowDripTemplate(workflowId, filter) {
  return dispatch => {
    dispatch(actionCreators.setLoadingNotificationMessage('deletingWorkflow'));
    workflowDripTemplatesRepo.deleteWorkflowDripTemplate(workflowId).then(() => {
      dispatch(searchWorkflowTemplates(filter, false, false));
      dispatch(actionCreators.setSuccessNoificationMessage('workflowDeleted'));
    });
  };
}

function duplicateWorkflowTemplate(workflowId, filter) {
  return async dispatch => {
    try {
      dispatch(actionCreators.setLoadingNotificationMessage('duplicatingWorkflow'));
      await workflowDripTemplatesRepo.duplicateWorkflowTemplate(workflowId);
      dispatch(actionCreators.setSuccessNoificationMessage('workflowDuplicated'));
      dispatch(searchWorkflowTemplates(filter, false, false));
    } catch (error) {
      throw error;
    }
  };
}

function shareWorkflowDripTemplate(workflowId, shareOptions) {
  let successMessage = 'workflowSharedSuccessfully';
  let loadingMessage = 'sharingWorkflow';
  if (shareOptions.isShareWithAll) {
    loadingMessage = 'sharingWorkflowWithAll';
  }
  if (shareOptions.isUnshareWithAll) {
    successMessage = 'workflowUnSharedSuccessfully';
    loadingMessage = 'unSharingWorkflowWithAll';
  }
  return dispatch => {
    dispatch(actionCreators.setLoadingNotificationMessage(loadingMessage));
    workflowDripTemplatesRepo
      .shareWorkflowDripTemplate(workflowId, shareOptions)
      .then(() => {
        dispatch(actionCreators.setWorkflowDripTemplateSharedWith(workflowId, shareOptions));
        dispatch(actionCreators.setSuccessNoificationMessage(successMessage));
      })
      .catch(() => {
        dispatch(actionCreators.setErrorForShareTemplatePostFailed());
        dispatch(actionCreators.setTemplateSharingFailureNotification());
      });
  };
}

function setWorkflowNodeConnections(data) {
  return actionCreators.setWorkflowNodeConnections(data);
}

export {
  searchWorkflowTemplates,
  saveWorkflowTemplate,
  fetchWorkflowTemplateById,
  editWorkflowDripTemplate,
  deleteWorkflowDripTemplate,
  duplicateWorkflowTemplate,
  shareWorkflowDripTemplate,
  setWorkflowTemplateFetchApiStatus,
  setWorkflowNodeConnections,
};
