import React from 'react';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Icon, Drawer, Button } from 'antd';
import './CandidateDrawer.scss';
import {
  fetchBulkCandidateAllNotes as _fetchBulkCandidateAllNotes,
  updateCandidateViewStatus as _updateCandidateViewStatus,
  fetchCandidateJobs as _fetchCandidateJobs,
  fetchCandidateSegmentsAndCampaigns as _fetchCandidateSegmentsAndCampaigns,
  resetCandidateJobs as _resetCandidateJobs,
} from '../../Actions/CandidateActions';
import {
  fetchCallNotes as _fetchCallNotes,
  fetchCandidateNotes as _fetchCandidateNotes,
} from '../../Actions/CandidateConnectActions';
import { getPersonality as _getPersonality } from '../../Actions/ConnectActions';
import messages from './messages';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getCandidateDetails, getIsDownloadedStatus, getMspIcons } from '../../Reducers/CandidateReducer';
import { CANDIDATE_VIEW } from '../../PubSub/EventTypes';
import { FREE_TRIAL } from '../../Utils/PlanSelectionUtils';
import { getCurrentCandidateBucket } from '../../Utils/CandidateListUtils';
import { getCandidatesViewedStats, setCandidatesViewedStats } from '../../Utils/PubSubUtils';
import { getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import {
  getCandidateProfileActionEventName,
  getEventNameByCandidateStatus,
  getCandidate360ProfileActionEventName,
} from '../../Analytics/Candidate/CandidateEventUtils';
import eventTypes, { modules } from '../../Analytics/EventTypes';
import { logSmartKarrotEvent } from '../../Analytics/EventUtils';
import Candidate360ViewContainer from './Candidate360ViewContainer';
import Candidate360MinimizedCandidateHeaderWrapper from '../../Components/CandidateDrawer/Candidate360MinimizedCandidateHeaderWrapper';
import { getMaskingConfig, getVaults } from '../../Reducers/ConfigReducer';
import { getCandidateSource, getAtsSource } from '../../Utils/SourceUtils';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as AtsActions from '../../Actions/AtsActions';
import { setSubsegmentAddModalStatus } from '../../Actions/SubSegmentActions';
import { getAtsConfig } from '../../Reducers/AtsReducer';
import { getActiveSourceName, getActiveTab } from '../../Reducers/JobCandidatesTabReducer';
import { getCandidateDrawerRedirectedTab, getCandidateStatus } from '../../Utils/CandidateDrawerUtils';
import { getIsDeDuplicationAllowed } from '../../Utils/DeDuplicationUtils';
import { getAdminFeatures } from '../../Reducers/UserReducer';
import { getCandidatePublishStatus } from '../../Utils/CandidatePublishUtils';
import { getSelectedSubsegmentId } from '../../Reducers/SubsegmentReducer';

const mapsDispatchToProps = {
  fetchCandidateNotes: _fetchCandidateNotes,
  fetchCallNotes: _fetchCallNotes,
  getPersonality: _getPersonality,
  fetchBulkCandidateAllNotes: _fetchBulkCandidateAllNotes,
  updateCandidateViewStatus: _updateCandidateViewStatus,
  resetCandidateJobs: _resetCandidateJobs,
  pushCandidateToAts: AtsActions.pushCandidate,
  fetchCandidateJobs: _fetchCandidateJobs,
  fetchCandidateSegmentsAndCampaigns: _fetchCandidateSegmentsAndCampaigns,
  setAddSubsegmentModalStatus: setSubsegmentAddModalStatus,
};

const mapStateToProps = (state, props) => {
  const mappedProps = {
    featureToggleList: getFeatureToggleList(state),
    currentUserDetails: getCurrentUserDetails(state),
    maskingConfig: getMaskingConfig(state),
    pushCandToAtsStatus: getApiStatus(state, 'pushCandToAtsStatus'),
    IsDownloadedStatus: getIsDownloadedStatus(state),
    vaults: getVaults(state),
    atsConfig: getAtsConfig(state),
    activeTab: getActiveTab(state),
    candidateStatusUpdate: getApiStatus(state, 'sourceCandidateStatusUpdate'),
    activeSourceName: getActiveSourceName(state),
    adminFeatures: getAdminFeatures(state),
    mspIcons: getMspIcons(state, props.candidateId),
    selectedSubSegmentId: getSelectedSubsegmentId(state),
  };
  if (!props.candidate && props.candidateId) {
    mappedProps.candidate = getCandidateDetails(state, props.candidateId);
  }
  return mappedProps;
};

class CandidateDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabName: 'profile',
      currentNotes: null,
      isCandidateViewHeaderVisible: true,
      isCandidateMatchingJobModalVisible: false,
    };
    this.tabRef = React.createRef();
    this.onTabChange = this.onTabChange.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.onNotesChange = this.onNotesChange.bind(this);
  }

  static getDerivedStateFromProps(props, prevState) {
    const { visible, subTabKey } = props;
    const { propsDrawerVisibility } = prevState;
    if (propsDrawerVisibility !== visible) {
      return {
        propsDrawerVisibility: visible,
        tabName: subTabKey,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const {
      candidate: nextCandidate,
      fetchBulkCandidateAllNotes,
      fetchCallNotes,
      jobId,
      featureToggleList,
      visible: nextVisible,
      version,
      isTryNow,
      fetchCandidateJobs,
      fetchCandidateSegmentsAndCampaigns,
      openInNewTab,
      activeSourceName,
      adminFeatures,
      candidateContext,
    } = this.props;
    const { tabName } = this.state;
    const { visible: prevVisible, candidate: prevCandidate, isWorkflowInitiated: prevIsWorkflowIntiated } = prevProps;
    const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup.IsEnabled;
    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList, candidateContext);

    const prevCandidateId = _.get(prevCandidate, 'Id');
    const nextCandidateId = _.get(nextCandidate, 'Id');
    const drawerVisibilityOrCandidateChange =
      (prevVisible === false && nextVisible === true) || (nextCandidateId && prevCandidateId !== nextCandidateId);
    if (drawerVisibilityOrCandidateChange) {
      const { Id } = nextCandidate;
      if (nextVisible) {
        const redirectTabName = getCandidateDrawerRedirectedTab(
          nextCandidate,
          revealActiveChannelSourceName,
          activeSourceName,
          tabName
        );
        if (tabName !== redirectTabName)
          this.setState({
            tabName: redirectTabName,
          });
        fetchCandidateJobs({ candidateId: Id, JobStatuses: [1] });
        fetchCandidateSegmentsAndCampaigns({ candidateId: Id });
      }
    }
    if (openInNewTab && nextCandidate?.Sources && nextCandidate?.Sources?.length !== prevCandidate?.Sources?.length) {
      const { Id } = nextCandidate;
      const redirectTabName = getCandidateDrawerRedirectedTab(
        nextCandidate,
        revealActiveChannelSourceName,
        activeSourceName,
        tabName
      );
      this.setState({
        tabName: redirectTabName,
      });
      fetchCandidateJobs({ candidateId: Id, JobStatuses: [1] });
      fetchCandidateSegmentsAndCampaigns({ candidateId: Id });
    }
    if (version !== 'ats' && drawerVisibilityOrCandidateChange) {
      const isNotesEnabled = featureToggleList.Notes.IsEnabled;
      const isNotesIndicatorEnabled = featureToggleList.NotesIndicator.IsEnabled;
      const { Id, ConversationId } = nextCandidate;

      if (isNotesEnabled && isNotesIndicatorEnabled) {
        if (tabName === 'notes') {
          if (ConversationId) fetchCallNotes(Id, ConversationId, 0, -1);
        } else {
          let bulkCallNotesFilter;
          let nonContactedCandidateIds = [];
          const bulkCandidateNotesFilter = {
            jobId,
            candidateIds: [Id],
            from: 0,
            size: 0,
          };
          if (ConversationId) {
            bulkCallNotesFilter = {
              candidateIdsByConversationId: {
                [ConversationId]: Id,
              },
              conversationIds: [ConversationId],
              from: 0,
              size: 0,
            };
          } else {
            nonContactedCandidateIds = [Id];
          }
          if (!isTryNow && !isCandidatePublishEnabled)
            fetchBulkCandidateAllNotes(bulkCandidateNotesFilter, bulkCallNotesFilter, nonContactedCandidateIds);
        }
      }
      this.publishCandidateViewEvent(nextCandidate?.Id, nextCandidate?.Source?.Group);
    }
  }

  publishCandidateViewEvent = (candidateId, candidateType) => {
    const { currentJobDetails, currentUserDetails } = this.props;
    const viewedCandidateIdList = getCandidatesViewedStats(currentUserDetails?.UserGuid);

    if (currentJobDetails?.OrderInfo?.ProductVariantName === FREE_TRIAL) {
      if (!viewedCandidateIdList.find(cId => cId === candidateId)) {
        viewedCandidateIdList.push(candidateId);
        setCandidatesViewedStats(viewedCandidateIdList, currentUserDetails?.UserGuid);
      }
    }

    PubSub.publish(CANDIDATE_VIEW, {
      candidateId,
      candidateType,
      jobGuid: currentJobDetails?.JobGuid,
      planName: currentJobDetails?.OrderInfo?.ProductVariantName,
      viewCount: viewedCandidateIdList.length,
    });
  };

  setCandidateMatchingJobModalVisiblity = value => {
    this.setState({ isCandidateMatchingJobModalVisible: value });
  };

  onCandidate360TabChange = value => {
    const { onTabClick, candidate, fetchCallNotes } = this.props;
    const { Id, ConversationId } = candidate;
    this.setState({ tabName: value });
    if (value === 'contact') {
      onTabClick('email');
    }
    if (value === 'notes') {
      if (ConversationId) fetchCallNotes(Id, ConversationId, 0, -1);
    }
    logSmartKarrotEvent(getCandidate360ProfileActionEventName(value, candidate), {
      Module: modules.candidate.candidateActions,
    });
  };

  onTabChange(e, candidateDetails) {
    const { getPersonality, fetchCandidateNotes, fetchCallNotes, jobId, isTryNow } = this.props;
    const { value } = e.target;
    const { Id, PersonId, ConversationId } = candidateDetails;
    if (!isTryNow) {
      this.setState({ tabName: value });
      if (value === 'personality') {
        getPersonality(Id, PersonId);
      } else if (value === 'notes') {
        if (Id) fetchCandidateNotes({ jobId, candidateId: Id });
        if (ConversationId) fetchCallNotes(Id, ConversationId, 0, -1);
      }
      logSmartKarrotEvent(getCandidateProfileActionEventName(value, candidateDetails), {
        Module: modules.candidate.candidateActions,
      });
    }
  }

  handleClickNext() {
    const { onClickNext, history, resetCandidateJobs } = this.props;
    const candidateBucket = getCurrentCandidateBucket(history?.location);
    this.setState({ tabName: 'profile', currentNotes: null }, () => {
      if (this.tabRef.current) this.tabRef.current.scrollTop = 0;
      this.setState({
        currentNotes: undefined,
      });
    });
    onClickNext();
    logSmartKarrotEvent(
      getEventNameByCandidateStatus(eventTypes.candidate.candidateView.view, { Status: candidateBucket }),
      {
        Module: modules.candidate.candidateActions,
      }
    );
    this.setState({
      isCandidateViewHeaderVisible: true,
    });
    this.setState({ isCandidateMatchingJobModalVisible: false });
    resetCandidateJobs();
  }

  handleClickPrev() {
    const { onClickPrev, history, resetCandidateJobs } = this.props;
    const candidateBucket = getCurrentCandidateBucket(history?.location);
    this.setState({ tabName: 'profile', currentNotes: null }, () => {
      if (this.tabRef.current) this.tabRef.current.scrollTop = 0;
    });
    onClickPrev();
    logSmartKarrotEvent(
      getEventNameByCandidateStatus(eventTypes.candidate.candidateView.view, { Status: candidateBucket }),
      {
        Module: modules.candidate.candidateActions,
      }
    );
    this.setState({
      isCandidateViewHeaderVisible: true,
    });
    this.setState({ isCandidateMatchingJobModalVisible: false });
    resetCandidateJobs();
  }

  onNotesChange(currentNotes) {
    this.setState({
      currentNotes,
    });
  }

  handleDrawerVisibleChange = isDrawerVisible => {
    const { toggleDrawerRenderFlag } = this.props;
    if (!isDrawerVisible) toggleDrawerRenderFlag();
    this.setState({
      isCandidateViewHeaderVisible: true,
    });
  };

  onPushCandToAts = () => {
    const { jobId, pushCandidateToAts, vaults, candidateId, candidate } = this.props;
    const candidateSource = getCandidateSource(candidate);
    const atsPrimarySource = getAtsSource(candidate.Sources);
    const source = atsPrimarySource || candidateSource;
    pushCandidateToAts(jobId, candidateId, source.Portal, vaults[0]);
  };

  getCandidateView = () => {
    const {
      version,
      onTabClick,
      candidateId,
      candidate,
      downloadResume,
      jobId,
      jobGuid,
      activeKey,
      candidateDetailsStatus,
      onCandidateReject,
      onCandidateStatusChange,
      notesContainer,
      onClickAtsPush,
      keywordsToHighlight,
      mustHavesKeywords,
      unlockCandidateResume,
      showPushCandidateButton,
      showContactInfoButton,
      history,
      aryaVersion,
      getCandidateRejectedStatusUpdate,
      getCandidateShortlistedStatusUpdate,
      candidateType,
      candidateListStatus,
      openInNewTab,
      toggleBotConfigDrawer,
      isJobActionsAllowed,
      openCandidateView,
      showPushError,
      revealActiveChannelSourceName,
      showCandidateDetailTabs,
      ignoreSimilar,
      jobCountryCode,
      currentJobDetails,
      isTryNow = false,
      showPortalIcon = false,
      selectPlan,
      showCandidateSource = true,
      candidateContext = 'job',
      isDiversityAttributesVisible,
      IsDownloadedStatus,
      openSipCallWindowsApp,
      composeEmailType,
      setComposeEmailType,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
      isConnectTab,
      fetchCandidates,
      defaultFilter,
      mspIcons,
      setMspIcons,
    } = this.props;
    const { tabName, currentNotes, isCandidateViewHeaderVisible, isCandidateMatchingJobModalVisible } = this.state;
    const isCandidateDownloaded = _.get(IsDownloadedStatus, [candidateId], false);

    return (
      <Candidate360ViewContainer
        version={version}
        candidate={candidate}
        downloadResume={downloadResume}
        jobId={jobId}
        jobGuid={jobGuid}
        activeKey={activeKey}
        onTabClick={onTabClick}
        candidateDetailsStatus={candidateDetailsStatus}
        onCandidateReject={onCandidateReject}
        onCandidateStatusChange={onCandidateStatusChange}
        keywordsToHighlight={keywordsToHighlight}
        mustHavesKeywords={mustHavesKeywords}
        getCandidateRejectedStatusUpdate={getCandidateRejectedStatusUpdate}
        getCandidateShortlistedStatusUpdate={getCandidateShortlistedStatusUpdate}
        tabName={tabName}
        candidateType={candidateType}
        toggleBotConfigDrawer={toggleBotConfigDrawer}
        ref={this.tabRef} // !! TODO: check
        openCandidateView={openCandidateView}
        jobCountryCode={jobCountryCode}
        currentJobDetails={currentJobDetails}
        isTryNow={isTryNow}
        onCandidate360TabChange={this.onCandidate360TabChange}
        candidateContext={candidateContext}
        isDiversityAttributesVisible={isDiversityAttributesVisible}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        setCandidateViewHeaderVisibility={this.setCandidateViewHeaderVisibility}
        candidateListStatus={candidateListStatus}
        ignoreSimilar={ignoreSimilar}
        showPortalIcon={showPortalIcon}
        showCandidateSource={showCandidateSource}
        aryaVersion={aryaVersion}
        isJobActionsAllowed={isJobActionsAllowed}
        unlockCandidateResume={unlockCandidateResume}
        selectPlan={selectPlan}
        history={history}
        revealActiveChannelSourceName={revealActiveChannelSourceName}
        onClickAtsPush={onClickAtsPush}
        showPushCandidateButton={showPushCandidateButton}
        showContactInfoButton={showContactInfoButton}
        showPushError={showPushError}
        openInNewTab={openInNewTab}
        isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
        setCandidateMatchingJobModalVisiblity={this.setCandidateMatchingJobModalVisiblity}
        candidateId={candidateId}
        onPushCandToAts={this.onPushCandToAts}
        isCandidateDownloaded={isCandidateDownloaded}
        notesContainer={notesContainer}
        onNotesChange={this.onNotesChange}
        currentNotes={currentNotes}
        showCandidateDetailTabs={showCandidateDetailTabs}
        openSipCallWindowsApp={openSipCallWindowsApp}
        composeEmailType={composeEmailType}
        setComposeEmailType={setComposeEmailType}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        isConnectTab={isConnectTab}
        defaultFilter={defaultFilter}
        fetchCandidates={fetchCandidates}
        mspIcons={mspIcons}
        setMspIcons={setMspIcons}
      />
    );
  };

  onCloseDrawer = () => {
    const { onClose, resetCandidateJobs } = this.props;
    onClose();
    this.setState({ tabName: 'profile', currentNotes: null });
    this.setState({ isCandidateMatchingJobModalVisible: false });
    resetCandidateJobs();
  };

  setCandidateViewHeaderVisibility = value => {
    this.setState({
      isCandidateViewHeaderVisible: value,
    });
  };

  onExtend = () => {
    const { isCandidateViewHeaderVisible } = this.state;
    this.setState({
      isCandidateViewHeaderVisible: !isCandidateViewHeaderVisible,
    });
  };

  getCandidate360MinimizedHeader = () => {
    const {
      featureToggleList,
      candidateContext,
      openCandidateView,
      candidate,
      candidateType,
      onCandidateStatusChange,
      onCandidateReject,
      getCandidateShortlistedStatusUpdate,
      getCandidateRejectedStatusUpdate,
      jobId,
      currentJobDetails,
      maskingConfig,
      ignoreSimilar,
      pushCandToAtsStatus,
      onClickAtsPush,
      IsDownloadedStatus,
      candidateId,
      showContactInfoButton,
      showPushCandidateButton,
      showPushError,
      atsConfig,
      version,
      showCandidateDetailTabs,
      activeTab,
      candidateStatusUpdate,
      openInNewTab,
      isConnectTab,
      adminFeatures,
      setAddSubsegmentModalStatus,
      selectedSubSegmentId,
    } = this.props;
    const { isCandidateViewHeaderVisible } = this.state;

    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList, candidateContext);
    const isCandidateDownloaded = _.get(IsDownloadedStatus, [candidateId], false);
    return !isCandidateViewHeaderVisible && !isCandidatePublishEnabled ? (
      <Candidate360MinimizedCandidateHeaderWrapper
        openCandidateView={openCandidateView}
        candidate={candidate}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        candidateType={candidateType}
        onCandidateStatusChange={onCandidateStatusChange}
        onCandidateReject={onCandidateReject}
        candidateContext={candidateContext}
        getCandidateShortlistedStatusUpdate={getCandidateShortlistedStatusUpdate}
        getCandidateRejectedStatusUpdate={getCandidateRejectedStatusUpdate}
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
        featureToggleList={featureToggleList}
        jobId={jobId}
        currentJobDetails={currentJobDetails}
        maskingConfig={maskingConfig}
        getCandidateStatus={getCandidateStatus}
        ignoreSimilar={ignoreSimilar}
        onPushCandToAts={this.onPushCandToAts}
        pushCandToAtsStatus={pushCandToAtsStatus}
        onClickAtsPush={onClickAtsPush}
        isCandidateDownloaded={isCandidateDownloaded}
        showContactInfoButton={showContactInfoButton}
        showPushCandidateButton={showPushCandidateButton}
        showPushError={showPushError}
        atsConfig={atsConfig}
        version={version}
        showCandidateDetailTabs={showCandidateDetailTabs}
        candidateId={candidateId}
        activeTab={activeTab}
        candidateStatusUpdate={candidateStatusUpdate}
        openInNewTab={openInNewTab}
        isConnectTab={isConnectTab}
        setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
        selectedSubSegmentId={selectedSubSegmentId}
      />
    ) : null;
  };

  getCollapseIcon = () => {
    const { isCandidateViewHeaderVisible } = this.state;
    const collapseIconType = isCandidateViewHeaderVisible ? 'up' : 'down';
    return (
      <div role="presentation" onClick={this.onExtend} className="extend-icon">
        <Icon type={collapseIconType} />
      </div>
    );
  };

  getPrevButton = ({ showNextPrevButtons }) => {
    const { disablePrev, candidateDetailsStatus } = this.props;
    if (showNextPrevButtons)
      return (
        <Button
          className="candidate-prev-button"
          onClick={this.handleClickPrev}
          disabled={disablePrev || candidateDetailsStatus === 'INPROGRESS'}
        >
          <div>
            <Icon className="candidate-drawer-prev-icon" type="left" />
            <FormattedMessage {...messages.prev} />
          </div>
        </Button>
      );
    return null;
  };

  getNextButton = ({ showNextPrevButtons }) => {
    const { disableNext, candidateDetailsStatus } = this.props;
    if (showNextPrevButtons)
      return (
        <Button
          className="candidate-next-button"
          onClick={this.handleClickNext}
          disabled={disableNext || candidateDetailsStatus === 'INPROGRESS'}
        >
          <div>
            <FormattedMessage {...messages.next} />
            <Icon className="candidate-drawer-next-icon" type="right" />
          </div>
        </Button>
      );
    return null;
  };

  render() {
    const {
      drawerRenderFlag,
      visible,
      openInNewTab,
      showNextPrevButtons,
      closable,
      candidate,
      candidateDetailsStatus,
      isCandidateSet,
      activeTab,
      activeSourceName,
    } = this.props;
    const isAryaRecommendedOrAllTab =
      activeTab === 'sourced' &&
      (activeSourceName === 'AryaRecommended' || getIsDeDuplicationAllowed(activeSourceName));
    if (
      (_.isEmpty(candidate) && !isAryaRecommendedOrAllTab) ||
      (_.isEmpty(candidate) && candidateDetailsStatus === 'COMPLETED' && isCandidateSet && isAryaRecommendedOrAllTab)
    ) {
      return null;
    }
    const { isCandidateViewHeaderVisible } = this.state;
    const { version } = this.props;

    if (openInNewTab)
      return (
        <div className="candidate-drawer-header">
          <div
            className={`candidate-drawer-header-view-new-tab ${
              !isCandidateViewHeaderVisible && 'candidate-drawer-header-pad'
            }`}
            style={version !== 'ats' ? { paddingTop: '8px' } : {}}
          >
            <div className="minimized-candidate-header-info-new-tab">{this.getCandidate360MinimizedHeader()}</div>
            {!isCandidateViewHeaderVisible && this.getCollapseIcon()}
          </div>
          {this.getCandidateView()}
        </div>
      );

    if (!drawerRenderFlag) return null;
    const title = (
      <div className="candidate-drawer-header">
        <div className="candidate-drawer-header-view">
          <div className="minimized-candidate-header-info">
            <div>
              {this.getPrevButton({ showNextPrevButtons })}
              {this.getNextButton({ showNextPrevButtons })}
            </div>
            {this.getCandidate360MinimizedHeader()}
          </div>
          {this.getCollapseIcon()}
        </div>
      </div>
    );
    return (
      <Drawer
        placement="right"
        onClose={this.onCloseDrawer}
        visible={visible}
        destroyOnClose
        afterVisibleChange={this.handleDrawerVisibleChange}
        className="master-profile-drawer"
        bodyStyle={{ padding: '0px' }}
        title={title}
        closable={closable}
        headerStyle={{ padding: '12px 24px', zIndex: 99 }}
      >
        {this.getCandidateView()}
      </Drawer>
    );
  }
}

CandidateDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  fetchCandidateNotes: PropTypes.func.isRequired,
  showNextPrevButtons: PropTypes.bool,
  closable: PropTypes.bool,
  showCandidateDetailTabs: PropTypes.bool,
  showPortalIcon: PropTypes.bool,
};

CandidateDrawer.defaultProps = {
  visible: false,
  showNextPrevButtons: true,
  closable: true,
  showCandidateDetailTabs: true,
  showPortalIcon: false,
};

export default connect(mapStateToProps, mapsDispatchToProps)(CandidateDrawer);

export { CandidateDrawer as CandidateDrawerWithoutStore };
