import { message } from 'antd';
import * as actionCreators from './ActionCreators/ScoutingAgentActionCreator';
import {
  createScoutingAgent,
  fetchJobScoutingAgents,
  startScoutingAgent,
  stopScoutingAgent,
  deleteScoutingAgent,
  getScoutingAgentByIds,
  fetchScoutingAgent,
  updateScoutingAgent,
  getScoutingAgentEnabledUsers,
  getScoutingAgentConfig,
  saveSourcingAgentUserList as _saveSourcingAgentUserList,
} from '../Repository/ScoutingAgentRepository';
import { setApiStatus } from './ApiStatusActions';
import { setNotification } from './ActionCreators/ConnectActions';

export function setScoutingAgentModalVisibility(visible, mode) {
  return dispatch => {
    dispatch(actionCreators.setScoutingAgentModalVisibilityAction(visible, mode));
  };
}
export function setScoutingAgentWarningModalVisibility(visible) {
  return dispatch => {
    dispatch(actionCreators.setScoutingAgentWarningModalVisibilityAction(visible));
  };
}

export function setScoutingAgentDrawerVisibility(visible) {
  return dispatch => {
    dispatch(actionCreators.setScoutingAgentDrawerAction(visible));
  };
}

export function setScoutingAgentSearchCriteriaModalVisibility(visible) {
  return dispatch => {
    dispatch(actionCreators.setScoutingAgentSearchCriteriaModalAction(visible));
  };
}

export function setLastScoutingAgentDeletedId(Id) {
  return actionCreators.setLastScoutingAgentDeletedId(Id);
}

export function setSelectedScoutingAgentIdAction(agentId) {
  return dispatch => {
    dispatch(actionCreators.setSelectedScoutingAgentId(agentId));
  };
}

export function updateScoutingAgentStats(agentId, jobId){
  return dispatch => {
    dispatch(actionCreators.updateScoutingAgentStats(agentId, jobId));
  }
}

export function createScoutingAgentAction(jobId, payload) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'INPROGRESS' }));
      await createScoutingAgent(jobId, payload);
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'COMPLETED' }));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'scoutingAgentCreatedSuccessfully',
        })
      );
      dispatch(actionCreators.updateActiveAgentCount({ jobId, count: 1 }));
    } catch (e) {
      const errorMessage = e.response.data?.Error?.Message ?? 'oops something went wrong';
      message.error(errorMessage);
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'FAILED' }));
      throw e;
    }
  };
}

export function fetchJobScoutingAgent(jobId) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'INPROGRESS' }));
      const response = await fetchJobScoutingAgents(jobId);
      dispatch(actionCreators.setJobScoutingAgents(jobId, response.data));
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'COMPLETED' }));
    } catch (e) {
      const errorMessage = e.response.data?.Error?.Message ?? 'oops something went wrong';
      message.error(errorMessage);
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'FAILED' }));
    }
  };
}

export function stopScoutingAgentAction(agentId, agentName) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'INPROGRESS' }));
      await stopScoutingAgent(agentId);
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'COMPLETED' }));
      message.info(`${agentName} has been stopped`);
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'FAILED' }));
    }
  };
}

export function startScoutingAgentAction(agentId, agentName) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'INPROGRESS' }));
      await startScoutingAgent(agentId);
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'COMPLETED' }));
      dispatch(
        setNotification('LOADING', {
          messageId: 'agentNameIsActive',
          mergeTags: { agentName },
        })
      );
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'FAILED' }));
    }
  };
}

export function updateScoutingAgentAction(payload) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'INPROGRESS' }));
      await updateScoutingAgent(payload);
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'COMPLETED' }));
      dispatch(
        setNotification('LOADING', {
          messageId: 'payLoadHasBeenUpdated',
          mergeTags: { payload:payload.AgentName || 'Agent' },
        })
      );
    } catch (e) {
      const errorMessage = e.response.data?.Error?.Message ?? 'oops something went wrong';
      message.error(errorMessage);
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'FAILED' }));
      throw e;
    }
  };
}

export function deleteScoutingAgentAction(agentId, queryParams) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'deleteScoutingAgentApiStatus', status: 'INPROGRESS' }));
      await deleteScoutingAgent(agentId, queryParams);
      dispatch(setApiStatus({ apiName: 'deleteScoutingAgentApiStatus', status: 'COMPLETED' }));
      message.info('Agent has been deleted');
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'deleteScoutingAgentApiStatus', status: 'FAILED' }));
    }
  };
}

export function getScoutingAgentConfigAction() {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'INPROGRESS' }));
      const response = await getScoutingAgentConfig();
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'COMPLETED' }));
      dispatch(actionCreators.setScoutingAgentConfig(response.data));
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'createScoutingAgentApi', status: 'FAILED' }));
    }
  };
}

export function fetchScoutingAgentsByJobIds(jobIds = []) {
  return async dispatch => {
    try {
      const refineJobIds = jobIds.filter(id => id !== 'undefined' && id != null);
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'INPROGRESS' }));
      const { data } = await getScoutingAgentByIds(refineJobIds);
      const scoutingAgentEnableJobIds = data.map(({ JobId, Count }) => ({ JobId, Count }));
      dispatch(actionCreators.setScoutingAgentIdforJobs(scoutingAgentEnableJobIds));
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'COMPLETED' }));
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'scoutingAgentApiStatus', status: 'FAILED' }));
    }
  };
}

export function fetchScoutingAgentFromAgentId(agentId) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'scoutingAgentFromAgentIdApiStatus', status: 'INPROGRESS' }));
      const { data } = await fetchScoutingAgent(agentId);
      dispatch(actionCreators.setScoutingAgentData(data));
      dispatch(setApiStatus({ apiName: 'scoutingAgentFromAgentIdApiStatus', status: 'COMPLETED' }));
    } catch (e) {
      dispatch(setApiStatus({ apiName: 'scoutingAgentFromAgentIdApiStatus', status: 'FAILED' }));
    }
  };
}
export const getScoutingAgentEnabledUsersAction = () => {
  return async dispatch => {
    try {
      const response = await getScoutingAgentEnabledUsers();
      dispatch(actionCreators.setScoutingAgentAdminViewStatus(true));
      return response.data;
    } catch (error) {
      dispatch(actionCreators.setScoutingAgentAdminViewStatus(false));
      return {};
    }
  };
};

export const saveSourcingAgentUserList = configs => {
  return async () => {
    await _saveSourcingAgentUserList(configs);
  };
};
