import React from 'react';
import _ from 'lodash';
import { Tag, Tooltip } from 'antd';
import { getGroupedLocations } from '../../Utils/LocationUtils';
import RegionTagWrapper from '../Common/RegionTagWrapper';
import styles from './JobLocationsDisplayComponent.module.scss';
import { EllipsedText } from '../../Utils/TextUtils';

const getLocationDisplayName = (location = {}) => {
  const { CountryCode, Region } = location;
  if (Region?.RegionName) {
    return `${Region.RegionName}, ${CountryCode}`;
  }
  return location.Label;
};

function JobLocationsDisplayComponent(props) {
  const { locations, visibleLocationsCount } = props;
  const groupedLocations = getGroupedLocations(locations);
  const visibleLocations = groupedLocations.slice(0, visibleLocationsCount);
  const hiddenLocations = groupedLocations.slice(visibleLocationsCount);
  return (
    <div className={styles.regionContainer}>
      {visibleLocations.map(x => {
        const displayName = getLocationDisplayName(x);
        const locationList = x.Region?.Locations;
        const locationTag = (
          <Tag key={displayName} className={styles.locationTag}>
            <EllipsedText text={displayName} maxTextLength={30} />
            {x.Distance ? `, ${x.Distance} ${x.DistanceUnit.slice(0, 3)?.toLowerCase()}` : ''}
          </Tag>
        );

        return x.Region ? (
          <RegionTagWrapper locationList={locationList} key={x.regionId}>
            <div>{locationTag}</div>
          </RegionTagWrapper>
        ) : (
          locationTag
        );
      })}
      {hiddenLocations.length ? (
        <Tooltip
          title={hiddenLocations.map(x => {
            const displayName = getLocationDisplayName(x);
            return <div key={displayName}>{displayName}{x.Distance ? `, ${x.Distance} ${x.DistanceUnit.slice(0, 3)?.toLowerCase()}` : ''}</div>;
          })}
        >
          <Tag className={styles.locationTag}>{`+ ${hiddenLocations.length}`}</Tag>
        </Tooltip>
      ) : null}
    </div>
  );
}

const MemoizedJobLocationsDisplayComponent = React.memo(JobLocationsDisplayComponent, _.isEqual);
export default MemoizedJobLocationsDisplayComponent;
