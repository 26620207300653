import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';

export default function BoooleanSelector(props) {
  const { value, onChange } = props;
  const onClick = e => {
    const val = e.target.value;
    onChange(val);
  };

  const getButtonType = key => {
    if (key?.toUpperCase() === value?.toUpperCase()) return 'primary';
    return 'default';
  };
  return (
    <Button.Group>
      <Button key="OR" type={getButtonType('OR')} onClick={onClick} value="OR" size="small">
      <FormattedMessage {...message.orLabel} />
      </Button>
      <Button key="AND" type={getButtonType('AND')} onClick={onClick} value="AND" size="small">
      <FormattedMessage {...message.and} />
      </Button>
    </Button.Group>
  );
}
