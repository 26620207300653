import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Collapse, Skeleton, Timeline, Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getCampaignStats } from '../../Reducers/CandidateAdvanceFiltersReducer';
import * as CandidateAdvanceFilterActions from '../../Actions/CandidateAdvanceFiltersActions';
import styles from '../CandidateFilters/CandidateAggregationFilter/CandidateAdvanceFilters/CandidateAdvanceFilters.module.scss';
import message from '../ManualSearchV2/ManualSearchMessages';

const { Panel } = Collapse;

const customPanelStyle = {
  background: '#FFFFFF',
};

const mapStateToProps = state => ({
  campaignStats: getCampaignStats(state),
});

const mapDispatchToProps = {
  fetchCampaignStatsByCampaignId: CandidateAdvanceFilterActions.fetchCampaignStatsByCampaignId,
};

const CampaignStats = props => {
  const { campaignStats, fetchCampaignStatsByCampaignId, jobId } = props;
  const [globalApiStatus, setGloblaApiStatus] = React.useState();
  const [byCampaignResults, setByCampaignResults] = React.useState([]);

  const handleCampaign = value => {
    const {
      CampaignName,
      CreatedByName,
      CandidatesCount,
      CreatedOn,
      SentCount,
      FailedCount,
      OpenedCount,
      ClickCount,
      SubsegmentName,
      RepliedCount,
    } = value;
    return (
      <div className={styles.campaignStatsHeader}>
        <div className={styles.campaignStats}>
          <div className={styles.campaignAttributes}>{CampaignName ?? 'Bulk email'}</div>
          <div>|</div>
          {SubsegmentName && (
            <>
              <div className={styles.campaignAttributes}>{SubsegmentName}</div>
              <div>|</div>
            </>
          )}
          <div>
          <FormattedMessage {...message.createdByLabel} />: <span className={styles.campaignAttributes}>{CreatedByName}</span>
          </div>
          <div>|</div>
          <div>
          <FormattedMessage {...message.CandidatesLabel} />: <span className={styles.campaignAttributes}>{CandidatesCount}</span>
          </div>
        </div>
        <div className={styles.campaignStats}>
          <div className={styles.campaignType}><FormattedMessage {...message.emailLabel} /></div>
          <div><FormattedMessage {...message.createdOn} />: {moment.utc(CreatedOn).local().format('ll')}</div>
        </div>
        <div className={styles.campaignStats}>
          <div className={styles.campaignAttributes}>&mdash;<FormattedMessage {...message.overallStatsLabel} />: </div>
          <div><FormattedMessage {...message.Sent} />: ({SentCount})</div>
          <div>|</div>
          <div><FormattedMessage {...message.failedLabel} />: ({FailedCount})</div>
          <div>|</div>
          <div><FormattedMessage {...message.openedLabel} />: ({OpenedCount})</div>
          <div>|</div>
          <div><FormattedMessage {...message.clickedLabel} />: ({ClickCount})</div>
          <div>|</div>
          <div><FormattedMessage {...message.repliedLabel} />: ({RepliedCount})</div>
        </div>
      </div>
    );
  };

  const handleCampaignPanelContent = (value, index) => {
    const { CreatedByName, CandidatesCount, CreatedOn, SentCount, FailedCount, OpenedCount, ClickCount, RepliedCount } =
      value;
    return (
      <div className={styles.campaignStatsHeader}>
        <div className={styles.campaignStats}>
          <div>
          <FormattedMessage {...message.addedByLabel} />: <span className={styles.campaignAttributes}>{CreatedByName}</span>
          </div>
          <div>|</div>
          <div><FormattedMessage {...message.dateLabel} />: {moment.utc(CreatedOn).local().format('ll')}</div>
          <div>|</div>
          <div>
          <FormattedMessage {...message.CandidatesLabel} />: <span className={styles.campaignAttributes}>{CandidatesCount}</span>
          </div>
        </div>
        <div className={styles.campaignStats}>
          <div> &mdash;<FormattedMessage {...message.Sent} />: ({SentCount})</div>
          <div>|</div>
          <div><FormattedMessage {...message.failedLabel} />: ({FailedCount})</div>
          <div>|</div>
          <div><FormattedMessage {...message.openedLabel} />: ({OpenedCount})</div>
          <div>|</div>
          <div><FormattedMessage {...message.clickedLabel} />: ({ClickCount})</div>
          <div>|</div>
          <div><FormattedMessage {...message.repliedLabel} />: ({RepliedCount})</div>
        </div>
        {index + 1 !== byCampaignResults?.length ? <div className={styles.timelineItem}></div> : null}
      </div>
    );
  };

  const handleChange = async key => {
    if (key) {
      const campaignValue = campaignStats[key];
      setGloblaApiStatus('INPROGRESS');
      const results = await fetchCampaignStatsByCampaignId(jobId, campaignValue?.CampaignId);
      setByCampaignResults(results);
      setGloblaApiStatus('COMPLETED');
    }
  };

  return (
    <div className={styles.campaignStatsPanel}>
      {campaignStats?.length > 0 ? (
        <Collapse expandIconPosition="right" onChange={handleChange} accordion>
          {campaignStats?.map((campaign, index) => (
            <Panel
              header={handleCampaign(campaign)}
              key={index}
              style={customPanelStyle}
              disabled={campaignStats[index]?.CampaignId === 0}
              showArrow={campaignStats[index]?.CampaignId !== 0}
            >
              <Skeleton active title={false} paragraph={{ rows: 3 }} loading={globalApiStatus === 'INPROGRESS'}>
                <Timeline color="#52C41A">
                  {byCampaignResults?.map((campaignResult, index) => (
                    <Timeline.Item>{handleCampaignPanelContent(campaignResult, index)}</Timeline.Item>
                  ))}
                  <div className={styles.hiddenTimeline}>
                    <Timeline.Item dot={<div />}></Timeline.Item>
                  </div>
                </Timeline>
              </Skeleton>
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 100 }}
          description={<p className={styles.emptyText}><FormattedMessage {...message.pleaseRunACampaignLabel} /></p>}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignStats);
