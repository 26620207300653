import { defineMessages } from 'react-intl';

const messages = defineMessages({
  off: {
    id: 'AryaSourceButton.off',
    defaultMessage: 'Off',
  },
  sourcing: {
    id: 'AryaSourceButton.sourcing',
    defaultMessage: 'Sourcing',
  },
  review: {
    id: 'AryaSourceButton.review',
    defaultMessage: 'Review',
  },
  sourced: {
    id: 'AryaSourceButton.sourced',
    defaultMessage: 'Sourced',
  },
  v2EnabledSourcing: {
    id: 'AryaSourceButton.v2EnabledSourcing',
    defaultMessage: 'In-Progress',
  },
  v2EnabledReview: {
    id: 'AryaSourceButton.v2EnabledReview',
    defaultMessage: 'No Results',
  },
  v2EnabledSourced: {
    id: 'AryaSourceButton.v2EnabledSourced',
    defaultMessage: 'Completed',
  },
});

export default messages;
