import React from 'react';
import { Form, InputNumber } from 'antd';
import { injectIntl } from 'react-intl';
import { validateMinExperience, validateMaxExperience } from '../../Utils/FormValidators';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

const FormItem = Form.Item;
 function MinMaxExperience(props) {
  const {
    initialMinExperience,
    initialMaxExperience,
    form,
    showLabel = true,
    inputStyle,
    inputClassName,
    minExperiencePlaceholder,
    maxExperiencePlaceholder,
    intl
  } = props;

  const minExpPlaceholder = minExperiencePlaceholder || intl.formatMessage({ ...messages.yearsLabel});
  const maxExpPlaceholder = maxExperiencePlaceholder || intl.formatMessage({ ...messages.yearsLabel});

  return (
    <>
      <FormItem label={showLabel ? intl.formatMessage({ ...messages.minOverallExp }) : ''} colon={false}>
        {form.getFieldDecorator('MinExperience', {
          initialValue: initialMinExperience,
          rules: [{ validator: (rule, value, callback) => validateMinExperience(form, value, callback) }],
        })(
          <InputNumber
            placeholder={minExpPlaceholder}
            min={0}
            max={50}
            label="Min Overall Exp"
            style={inputStyle}
            className={inputClassName}
          />
        )}
      </FormItem>
      <FormItem label={showLabel ? intl.formatMessage({ ...messages.maxOverallExp }) : ''} colon={false}>
        {form.getFieldDecorator('MaxExperience', {
          initialValue: initialMaxExperience,
          rules: [{ validator: (rule, value, callback) => validateMaxExperience(form, value, callback) }],
        })(
          <InputNumber
            placeholder={maxExpPlaceholder}
            min={0}
            max={50}
            label="Max Overall Exp"
            style={inputStyle}
            className={inputClassName}
          />
        )}
      </FormItem>
    </>
  );
}

export default (injectIntl(Form.create()(MinMaxExperience)));
export {MinMaxExperience as MinMaxExperienceWithoutInjectIntl}
