// Its a collection of country code (key) and phone length(value)
export const phoneData = {
  '+1': 10,
  '+7': 10,
  '+20': 10,
  '+27': 9,
  '+30': 10,
  '+31': 9,
  '+32': 9,
  '+33': 9,
  '+34': 9,
  '+36': 9,
  '+39': 10,
  '+40': 9,
  '+41': 9,
  '+43': 9,
  '+44': 10,
  '+45': 8,
  '+46': 9,
  '+47': 8,
  '+48': 9,
  '+49': 11,
  '+51': 9,
  '+52': 10,
  '+53': 8,
  '+54': 10,
  '+55': 11,
  '+56': 9,
  '+57': 10,
  '+58': 10,
  '+60': 10,
  '+61': 9,
  '+62': 11,
  '+63': 10,
  '+64': 9,
  '+65': 8,
  '+66': 9,
  '+81': 10,
  '+82': 10,
  '+84': 10,
  '+86': 11,
  '+90': 10,
  '+91': 10,
  '+92': 10,
  '+93': 9,
  '+94': 10,
  '+95': 9,
  '+98': 10,
  '+211': 9,
  '+212': 9,
  '+213': 9,
  '+216': 8,
  '+218': 9,
  '+220': 7,
  '+221': 9,
  '+222': 8,
  '+223': 8,
  '+224': 9,
  '+225': 8,
  '+226': 8,
  '+227': 8,
  '+228': 8,
  '+229': 8,
  '+230': 8,
  '+231': 8,
  '+232': 8,
  '+233': 9,
  '+234': 10,
  '+235': 8,
  '+236': 8,
  '+237': 9,
  '+238': 7,
  '+239': 7,
  '+240': 9,
  '+241': 7,
  '+242': 7,
  '+243': 9,
  '+244': 9,
  '+245': 9,
  '+248': 7,
  '+249': 9,
  '+250': 9,
  '+251': 9,
  '+252': 9,
  '+253': 8,
  '+254': 10,
  '+255': 9,
  '+256': 9,
  '+257': 8,
  '+258': 9,
  '+260': 9,
  '+261': 9,
  '+262': 9,
  '+263': 9,
  '+264': 9,
  '+265': 9,
  '+266': 8,
  '+267': 8,
  '+268': 8,
  '+269': 7,
  '+290': 7,
  '+291': 7,
  '+297': 7,
  '+298': 6,
  '+299': 6,
  '+350': 8,
  '+351': 9,
  '+352': 9,
  '+353': 9,
  '+354': 7,
  '+355': 9,
  '+356': 8,
  '+357': 8,
  '+358': 9,
  '+359': 9,
  '+370': 8,
  '+371': 8,
  '+372': 8,
  '+373': 8,
  '+374': 8,
  '+375': 9,
  '+376': 6,
  '+377': 9,
  '+378': 10,
  '+380': 9,
  '+381': 9,
  '+382': 9,
  '+383': 9,
  '+385': 9,
  '+386': 9,
  '+387': 8,
  '+389': 8,
  '+420': 9,
  '+421': 9,
  '+423': 9,
  '+500': 5,
  '+501': 7,
  '+502': 8,
  '+503': 8,
  '+504': 8,
  '+505': 8,
  '+506': 8,
  '+507': 8,
  '+508': 6,
  '+509': 8,
  '+590': 9,
  '+591': 8,
  '+592': 7,
  '+593': 9,
  '+594': 9,
  '+595': 9,
  '+596': 9,
  '+597': 6,
  '+598': 9,
  '+599': 7,
  '+670': 8,
  '+672': 6,
  '+673': 7,
  '+674': 7,
  '+675': 8,
  '+676': 5,
  '+677': 7,
  '+678': 7,
  '+679': 7,
  '+680': 7,
  '+681': 6,
  '+682': 5,
  '+683': 4,
  '+685': 5,
  '+686': 8,
  '+687': 6,
  '+688': 5,
  '+689': 6,
  '+690': 4,
  '+691': 7,
  '+692': 7,
  '+850': 10,
  '+852': 8,
  '+853': 8,
  '+855': 9,
  '+856': 9,
  '+880': 10,
  '+886': 9,
  '+960': 7,
  '+961': 8,
  '+962': 9,
  '+963': 9,
  '+964': 10,
  '+965': 8,
  '+966': 9,
  '+967': 9,
  '+968': 8,
  '+970': 9,
  '+971': 9,
  '+972': 9,
  '+973': 8,
  '+974': 8,
  '+975': 8,
  '+976': 8,
  '+977': 10,
  '+992': 9,
  '+993': 8,
  '+994': 9,
  '+995': 9,
  '+996': 9,
  '+998': 9,
};
