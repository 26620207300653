import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { fetchCandidateRank } from '../../Actions/CandidateActions';

function RedirectUrlMapper(props) {
  const { redirectTo, history, location, match, operation } = props;
  const queryParams = queryString.parse(location.search);
  const { candidateId, candidateGuid, cStatus, view, isScoutingAgentCandidate, status } = queryParams;
  const { jobId } = match.params;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let url;
    if (redirectTo === 'CandidateList' && operation === 'OpenCandidateDrawer') {
      (async function _fetchCandidateRank() {
        const candidateRankQuery = {
          candidateId,
          candidateGuid,
          connectionStatus: cStatus,
          isScoutingAgentCandidate: isScoutingAgentCandidate?.toLowerCase() === 'true' ? true : null,
        };
        const response = await dispatch(fetchCandidateRank({ jobId, candidateRankQuery }));
        const { Status, Rank, CandidateId: AryaCandidateId } = response;
        if (Rank === -1) {
          if (isScoutingAgentCandidate) url = `/jobs/${jobId}/candidates?status=sourced&activeTab=scoutingAgent`;
          else url = `/jobs/${jobId}/candidates?status=sourced`;
        } else if (isScoutingAgentCandidate)
          url = `/jobs/${jobId}/candidates?status=sourced&activeTab=scoutingAgent&index=${Rank}&id=${AryaCandidateId}`;
        else
          url = `/jobs/${jobId}/candidates?status=${
            status?.toLowerCase() === 'applied' ? 'applied' : Status.toLowerCase()
          }&index=${Rank}&id=${AryaCandidateId}`;
        if (view) url = `${url}&view=${view}`;
        history.replace(url);
      })();
    }
  }, []);

  return <LoadingScreen active loading />;
}

export default withRouter(RedirectUrlMapper);
export { RedirectUrlMapper as RedirectUrlMapperWithoutRouter };
