import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import { Form, Switch, Tooltip } from 'antd';
import styles from './TitlesSection.module.scss';
import CustomCheckableTags from '../../../../Components/Common/CustomCheckableTags/CustomCheckableTags';
import AutocompleteSearch from '../../../../Components/AutocompleteSearch/AutocompleteSearch';
import * as autocompleteActions from '../../../../Actions/AutocompleteActions';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import eventTypes from '../../../../Analytics/EventTypes';
import { getEventNameByFeatureType } from '../../../../Analytics/Job/JobEventUtils';
import { getFeatureToggleList } from '../../../../Reducers/FeatureToggleReducer';

const { Item } = Form;

const mapStateToProps = (state, props) => ({
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  fetchJobTitleAutocomplete: autocompleteActions.fetchJobTitleAutocomplete,
};

const DisplayedTitles = props => {
  const { value, onChange, form, featureToggleList } = props;

  const handleOnChange = updatedValues => {
    onChange(updatedValues);
    const isAnyTitleSelected = updatedValues?.some(title => title.isSelected === true);
    if (!isAnyTitleSelected) {
      form.setFieldsValue({ IsExactTitleMatchRequired: false });
    }
  };

  return (
    <CustomCheckableTags
      tags={value}
      onChange={handleOnChange}
      tagsClassName={isSelected => `${styles.titleTag} ${isSelected ? styles.selected : styles.unselected}`}
      className={styles.titleTagsContainer}
      getSkEventName={isSelected =>
        getEventNameByFeatureType('manualSearch', isSelected ? 'deselectSuggestedTitles' : 'selectSuggestedTitles')
      }
      featureToggleList={featureToggleList}
    />
  );
};

const SwitchButton = props => {
  const { value, onChange, form } = props;
  const currentTitles = form.getFieldValue('Titles');
  const isAnyTitleSelected = currentTitles?.some(title => title.isSelected === true);
  return <Switch checked={value} onChange={onChange} disabled={!isAnyTitleSelected} />;
};

function TitlesSection(props) {
  const {
    form,
    defaultTitles,
    isManualSearchFormMinimized,
    fetchJobTitleAutocomplete,
    defaultIsExactTitleMatchRequired,
    featureToggleList,
  } = props;
  const [titleInput, setTitleInput] = React.useState(undefined);
  const [options, setOptions] = React.useState(undefined);
  const [jobTitleAutocompleteApiStatus, setJobTitleAutocompleteApiStatus] = React.useState();

  const titlesRef = React.useRef();

  const handleSearch = React.useCallback(async value => {
    try {
      setJobTitleAutocompleteApiStatus('INPROGRESS');
      const response = await fetchJobTitleAutocomplete({ from: 0, size: 10, title: value });
      setOptions(response?.titles?.map(title => ({ value: title, text: title })));
      setJobTitleAutocompleteApiStatus('COMPLETED');
    } catch {
      //! handle failure
    }
  }, []);

  const scrollToLatestTitle = () => {
    titlesRef.current.scrollTop = titlesRef.current.scrollHeight;
  };

  const handleSelect = title => {
    const currentTitles = form.getFieldValue('Titles');
    const lowerCasedTitle = title.toLowerCase();
    const updatedTitles = currentTitles
      .filter(x => x.name.toLowerCase() !== lowerCasedTitle)
      .concat({ id: uuid.v4(), isSelected: true, name: title });
    form.setFieldsValue({ Titles: updatedTitles }, scrollToLatestTitle);
    setTitleInput(undefined);
  };

  const getContentNotFound = () => {
    if (titleInput) return jobTitleAutocompleteApiStatus === 'INPROGRESS' ? 'Loading...' : '';
    return 'Type to search';
  };

  const onClear = () => {
    const currentTitles = form.getFieldValue('Titles');
    const updatedTitles = currentTitles.map(title => ({ ...title, isSelected: false }));
    form.setFieldsValue({ Titles: updatedTitles, IsExactTitleMatchRequired: false });
  };

  const notFoundContent = getContentNotFound();

  return (
    <div className={styles.titleSelection} style={{ display: isManualSearchFormMinimized ? 'none' : 'block' }}>
      <div className={styles.titleClearAll}>
        <div className={styles.titleLabel}>Job Title</div>
        <div className={styles.clearAll}>
          <ClearAllButton onClear={onClear} skEventName={eventTypes.job.manualSearch.clearTitlesFilter} />
        </div>
      </div>
      <AutocompleteSearch
        style={{ width: '300px', marginTop: '5px' }}
        placeholder="+ Add Title"
        onSearch={handleSearch}
        value={titleInput}
        onChange={setTitleInput}
        options={options}
        onSearchInput={handleSelect}
        notFoundContent={notFoundContent}
        onSelect={handleSelect}
      />
      <div style={{ marginTop: '10px', maxHeight: '122px', overflow: 'auto' }} ref={titlesRef}>
        <Item colon={false}>
          {form.getFieldDecorator('Titles', { initialValue: defaultTitles })(
            <DisplayedTitles form={form} featureToggleList={featureToggleList} />
          )}
        </Item>
      </div>
      <Tooltip
        title="selection of this option may impact the overall candidates counts from the job boards and other sources"
        placement="right"
      >
        <div className={styles.exactTitleMatchWrapper}>
          <div>
            <Item colon={false}>
              {form.getFieldDecorator('IsExactTitleMatchRequired', { initialValue: defaultIsExactTitleMatchRequired })(
                <SwitchButton form={form} defaultTitles={defaultTitles} />
              )}
            </Item>
          </div>
          <div className={styles.exactTitleMatchContent}>Find candidates with exact matching title</div>
        </div>
      </Tooltip>
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(TitlesSection);
export { TitlesSection as TitlesSectionWithoutStore };
