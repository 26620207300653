import { Avatar, Button } from 'antd';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { trimHtmlContent } from '../../Utils/DomParserUtils';
import SendReplyButtonIcon from '../../Icons/SendReplyButtonIcon';
import styles from './ThreadReplyInputComponent.module.scss';

function ThreadReplyInputComponent(props) {
  const { currentMember, parentMessage, addThreadReplyToTheChat, parentMessageId, chatId, topicId, isChatDisabled } =
    props;
  const { userGuid, avatarConfiguration } = currentMember;
  const [replyInput, setReplyInput] = useState('');
  const quillRef = React.useRef(null);
  const quill = quillRef?.current?.getEditor();

  const onReplyButtonClick = () => {
    const unformattedText = quill.getText().trim();
    const formattedLength = quill.getLength();
    const trimmedHtmlContent = trimHtmlContent(replyInput);
    if (unformattedText.length === 0 || formattedLength <= 1) {
      return;
    }
    addThreadReplyToTheChat({
      chatId,
      topicId,
      userGuid,
      messageContent: trimmedHtmlContent,
      parentMessage,
      parentMessageId,
    });
    setReplyInput('');
  };
  const onTextAreaKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onReplyButtonClick();
    }
  };
  const formats = ['bold', 'italic', 'underline', 'list', 'bullet'];

  return (
    <div className={styles.replyInputComponent}>
      <Avatar style={{ color: '#ffffff', backgroundColor: avatarConfiguration.color }}>
        {avatarConfiguration.text}{' '}
      </Avatar>
      <div className={styles.inputSection}>
        <ReactQuill
          ref={quillRef}
          value={replyInput}
          onChange={setReplyInput}
          onKeyDown={onTextAreaKeyPress}
          placeholder="Enter reply"
          readOnly={isChatDisabled}
          theme="snow"
          className={styles.inputField}
          formats={formats}
          modules={{ toolbar: false }}
        />
        <Button
          type="primary"
          className={styles.sendReplyButton}
          onClick={onReplyButtonClick}
          disabled={isChatDisabled || quill?.getText().trim().length === 0}
        >
          <SendReplyButtonIcon />
        </Button>
      </div>
    </div>
  );
}

export default ThreadReplyInputComponent;
