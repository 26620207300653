import _ from 'lodash';
import moment from 'moment';
import { getLastActive, allowedDeDuplicationSources } from './CandidateListUtils';
import { getPortalName } from './SourceUtils';

const portalPriority = {
  Internal: 1,
  'Arya Vault': 2,
  Active: 3,
  Social: 5, // Increasing social by 2 because all other job boards will be social -1 in priority
};

export function sortDuplicateCandidates(candidates, storedCandidatesInfo, revealActiveChannelSourceName) {
  candidates.sort((c1, c2) => {
    const candidate1 = storedCandidatesInfo.find(candidate => candidate.Id === c1);
    const candidate2 = storedCandidatesInfo.find(candidate => candidate.Id === c2);
    const candidate1LastUpdatedDate = moment.utc(getLastActive(candidate1)).format('YYYY-MM-DD');
    const candidate2LastUpdatedDate = moment.utc(getLastActive(candidate2)).format('YYYY-MM-DD');
    const candidate1Portal = getPortalName(candidate1.Sources, revealActiveChannelSourceName);
    const candidate2Portal = getPortalName(candidate2.Sources, revealActiveChannelSourceName);

    if (candidate1LastUpdatedDate !== candidate2LastUpdatedDate) {
      return new Date(candidate2LastUpdatedDate) - new Date(candidate1LastUpdatedDate);
    }
    const portalPriorityA = portalPriority[candidate1Portal] || portalPriority.Social - 1; // Assigning the priority just above social,for all the portals
    const portalPriorityB = portalPriority[candidate2Portal] || portalPriority.Social - 1; // Assigning the priority just above social,for all the portals
    if (portalPriorityA !== portalPriorityB) {
      return portalPriorityA - portalPriorityB;
    }
    return candidate2.Score - candidate1.Score;
  });
  return candidates;
}

export const getIsCandidateGroupHeadBySource = (candidatesGroupInfo, candidateGroupId, candidateId, sourceName) => {
  if (!candidatesGroupInfo[sourceName]?.[candidateGroupId]) return true;
  return candidatesGroupInfo[sourceName]?.[candidateGroupId]?.GroupHead === candidateId;
};

export const getIsCandidateGroupHead = (candidatesGroupInfo, candidateGroupId, candidateId, sourceName) => {
  if (candidateGroupId) {
    if (allowedDeDuplicationSources.filter(source => source !== 'All').includes(sourceName)) {
      return getIsCandidateGroupHeadBySource(candidatesGroupInfo, candidateGroupId, candidateId, sourceName);
    }
    if (!candidatesGroupInfo[candidateGroupId]) return true;
    return candidatesGroupInfo[candidateGroupId]?.GroupHead === candidateId;
  }
  return true;
};

export const getDuplicateCandidateIdsFromSourceByGroupId = (candidateGroupInfo, candidateGroupId, sourceName) => {
  const sourceCandidateGroupInfo = candidateGroupInfo[sourceName][candidateGroupId];
  if (!sourceCandidateGroupInfo) return [];
  return sourceCandidateGroupInfo.candidateIds.filter(
    candidateId => candidateId !== sourceCandidateGroupInfo.GroupHead
  );
};

export const getDuplicateCandidateIdsFromGroupId = (candidatesGroupInfo, candidateGroupId, sourceName) => {
  if (allowedDeDuplicationSources.filter(source => source !== 'All').includes(sourceName))
    return getDuplicateCandidateIdsFromSourceByGroupId(candidatesGroupInfo, candidateGroupId, sourceName);
  if (!candidatesGroupInfo[candidateGroupId]) return [];
  return candidatesGroupInfo[candidateGroupId].candidateIds.filter(
    candidateId => candidateId !== candidatesGroupInfo[candidateGroupId].GroupHead
  );
};

export const getCandidateGroupHead = (candidatesGroupInfo, candidateGroupId) => {
  return candidatesGroupInfo?.[candidateGroupId]?.GroupHead;
};

export const getGroupHeadByCandidateId = (candidatesGroupInfo = {}, candidateId) => {
  const groupInfo = Object.keys(candidatesGroupInfo)
    .map(key => candidatesGroupInfo[key])
    .find(item => item.candidateIds.includes(candidateId));
  return groupInfo ? groupInfo.GroupHead : candidateId;
};

export const getSortedManualSearchNonDuplicateCandidates = (
  manualSearchCandidates,
  allTabCandidates,
  duplicateCandidateIds,
  sourceName
) => {
  if (allowedDeDuplicationSources.filter(source => source !== 'All').includes(sourceName))
    return Object.values(manualSearchCandidates).filter(candidate => {
      return !duplicateCandidateIds.some(filterItem => filterItem === candidate.Id);
    });
  const sortedManualSearchCandidates = allTabCandidates.map(candidate => manualSearchCandidates[candidate.Id]);
  return sortedManualSearchCandidates.filter(candidate => {
    return !duplicateCandidateIds.some(filterItem => filterItem === candidate.Id);
  });
};

export const getIsDeDuplicationAllowed = activeSourceName => {
  return allowedDeDuplicationSources.includes(activeSourceName);
};
export function findGroupHeadsNotPresentInFiltertedAllTabCandidates(
  filteredAllTabCandidates,
  nonGroupHeadDuplicateCandidateIds,
  allTabCandidatesGroups,
  allTabCandidates
) {
  const filteredNonGroupHead = _.uniqBy(filteredAllTabCandidates, 'Id').filter(obj =>
    nonGroupHeadDuplicateCandidateIds.includes(obj.Id)
  );

  const groupHeadsNotPresentInFilteredCandidates = filteredNonGroupHead.map(can =>
    getGroupHeadByCandidateId(allTabCandidatesGroups, can.Id)
  );

  return allTabCandidates.filter(obj => groupHeadsNotPresentInFilteredCandidates.includes(obj.Id));
}
