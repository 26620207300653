import React from 'react';
import { fetchReportPayload, savedQueryMapper } from '../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import ReportViewContainer from '../../../../Containers/ReportPortalContainer/ReportViewContainer/ReportViewContainer';
import styles from './CandidateReports.module.scss';
import TextWithInfoIcon from '../../../Common/TextWithInfoIcon/TextWithInfoIcon';

export default function CandidateReports(props) {
  const {
    reportStats,
    getReportStats: fetchReportStats,
    fetchReportStatsApiStatus,
    exportReportApiStatus,
    savedQueryConfig,
    onSaveQuerySuccess,
  } = props;

  const getReportStats = (filter, page, pageSize) => {
    const fetchPayloadCriteria = { filter, page, pageSize, reportKey: 'candidates' };
    const candidateReportFetchPayload = fetchReportPayload(fetchPayloadCriteria);

    fetchReportStats(candidateReportFetchPayload, 'candidates');
  };

  React.useEffect(() => {
    if (savedQueryConfig?.runSavedQueryFlag) {
      const savedQuery = JSON.parse(savedQueryConfig?.queryDetails?.ReportQuery);
      const mappedSavedQuery = savedQueryMapper(savedQuery, savedQueryConfig?.queryDetails?.dynamicReportColumns);
      getReportStats(mappedSavedQuery, 1, 10);
    }
  }, [savedQueryConfig?.runSavedQueryFlag]);

  return (
    <div className={styles.candidateReports}>
      <ReportViewContainer
        fetchReportStatsApiStatus={fetchReportStatsApiStatus}
        title="Candidate Report"
        subtitle={
          <TextWithInfoIcon
            text="Report based on Candidate Sourcing during the date(s) given"
            tooltipTitle="Latest results may be delayed 1-2 hours"
          />
        }
        type="candidates"
        filterConfig={{ job: true, client: true, singleColumnList: true }}
        reportStats={reportStats}
        getReportStats={getReportStats}
        exportReportApiStatus={exportReportApiStatus}
        savedReportDetails={savedQueryConfig?.queryDetails}
        onSaveQuerySuccess={onSaveQuerySuccess}
      />
    </div>
  );
}
