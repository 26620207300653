import React from 'react';
import { Select } from 'antd';
import styles from './SubSegmentStyles.module.scss';

const { Option } = Select;

const SubSegmentListDropdown = () => {
  return (
    <Select defaultValue="All" className={styles.subSegmentDropdown}>
      <Option value="All">All Candidates (125)</Option>
      <Option value="sub1">Sub Segment 1 (125)</Option>
      <Option value="sub2">Sub Segment 2 (125)</Option>
      <Option value="sub3">Sub Segment 3 (125)</Option>
    </Select>
  );
};

export default SubSegmentListDropdown;
