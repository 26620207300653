import React from 'react';
import {  FormattedMessage } from 'react-intl';
import messages from '../../ManualSearchV2/ManualSearchMessages';

const TooltipText = ({ completedInstances, percentage }) => (
  <div>
    {completedInstances} {completedInstances > 1 ? <FormattedMessage {...messages.CandidatesLabel} /> : <FormattedMessage {...messages.candidateLabel} />}{' '}
    <span style={{ color: '#13C26B' }}>|</span> {Math.round(percentage)}%
  </div>
);

export default TooltipText;
