/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styles from './ManualSearchForm.module.scss';
import SearchCriteria from '../SearchCriteria/SearchCriteria';
import AdvancedCriteria from '../AdvancedCriteria/AdvancedCriteria';
import ManualSearchFormFooter from '../ManualSearchFormFooter/ManualSearchFormFooter';
import {
  getConfig,
  getPortalsConfig,
  getWhiteLabelInformation,
  getConfiguredSources,
  getMaskingConfig,
} from '../../../Reducers/ConfigReducer';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import { getIndustrySuggestions, getJobDetails, getCountries } from '../../../Reducers/JobReducer';
import ManualSearchFormHeaderOptions from '../HeaderOptions/ManualSearchFormHeaderOptions';
import { getInitialSources } from '../../../Utils/ManualSearchUtils';
import {
  getManualSearchSelectedCriteriaName,
  getBooleanSearchStrings,
  getManualSearchFormValues,
} from '../../../Reducers/ManualSearchReducer';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import ManualSearchContext from '../ManualSearchWrapper/ManualSearchContext';
import ManualSearchMinimizedViewContainer from '../ManualSearchMinimizedViewContainer/ManualSearchMinimizedViewContainer';
import LocationWrapper from '../../../CompoundComponents/LocationWrapper/LocationWrapper';
import message from '../ManualSearchMessages';

const mapStateToProps = (state, props) => ({
  userConfig: getConfig(state),
  portalsConfig: getPortalsConfig(state),
  sources: getConfiguredSources(state),
  industries: getIndustrySuggestions(state),
  criteriaName: getManualSearchSelectedCriteriaName(state, { jobId: props.jobId }),
  currentUserDetails: getCurrentUserDetails(state),
  booleanSearchStrings: getBooleanSearchStrings(state),
  manualSearchFormValues: getManualSearchFormValues(state, { jobId: props.jobId }),
  whiteLabelInfo: getWhiteLabelInformation(state),
  currentJobDetails: getJobDetails(state, props.jobId),
  countries: getCountries(state),
  maskingConfig: getMaskingConfig(state),
});

const mapDispatchToProps = {
  fetchBooleanSearchStrings: manualSearchActions.fetchBooleanSearchStrings,
};

const tabBarStyle = {
  paddingLeft: '20px',
};

const tabPaneStyle = { padding: '0px 20px 40px 20px', overflow: 'hidden' };
const advancedOptionsTabPaneStyle = { padding: '0px 20px 20px 20px', overflow: 'hidden' };

export function getFilteredSources({ candidateContext, isNonInternalPortalForSegmentEnabled, isPrefetchEnabled, sources }) {
  if (candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled) {
    return sources?.filter(x => x.Portal === 'Internal');
  }
  if (isPrefetchEnabled) {
    return sources;
  }
  return sources?.filter(
    x => x.Portal === 'Internal' || x.Portal === 'Social' || x.Group === 'Active' || x.Group === 'Passive'
  );
}

function ManualSearchForm(props) {
  const {
    industries,
    unsavedCriteriaId,
    defaultFormValues,
    userConfig,
    portalsConfig,
    jobId,
    currentJobDetails,
    sources,
    onSearchCandidates,
    isManualSearchFormMinimized,
    handleShowMoreLessClick,
    setDefaultFormValues,
    criteriaName,
    currentUserDetails,
    booleanSearchStrings,
    fetchBooleanSearchStrings,
    whiteLabelInfo,
    candidateContext = 'job',
    history,
    isNonInternalPortalForSegmentEnabled,
    setPreFetchRequired,
    isPreFetchRequied,
    manualSearchCandidatesFetchModalVisibility,
    isAdvanceSearchV2Enabled,
    countries,
    maskingConfig,
    isPrefetchEnabled,
    pageName,
    version
  } = props;

  const { form, minimizeManualSearchForm } = React.useContext(ManualSearchContext);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedCriteriaId, setSelectedCriteriaId] = React.useState();

  const sourcesConfig = userConfig?.Sources;
  const updatedSource =
    candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled
      ? sources.filter(source => source.Portal === 'Internal')
      : sources;

  const { PortalQueries: defaultPortalQueries = {}, Sources: defaultSources } = defaultFormValues;
  form.getFieldDecorator('advancedSettings', {
    initialValue: {},
  });
  form.getFieldDecorator('sourceNamesSelected');
  const isAllPortalsAllowed = candidateContext === 'job' || isNonInternalPortalForSegmentEnabled;
  const advancedSettings = form.getFieldValue('advancedSettings');
  const setAdvancedSettings = _advancedSettings => form.setFieldsValue({ advancedSettings: _advancedSettings });

  const sourceNamesSelected = form.getFieldValue('sourceNamesSelected');
  const setSelectedSourceNames = x => form.setFieldsValue({ sourceNamesSelected: x });

  // TODO: Optimise new object creation always?
  const mergedAdvancedSettings = { ...defaultPortalQueries, ...advancedSettings };

  const _sources = getFilteredSources({
    candidateContext,
    isNonInternalPortalForSegmentEnabled,
    isPrefetchEnabled,
    sources,
  });

  const _sourceNamesSelected = sourceNamesSelected?.length
    ? sourceNamesSelected
    : getInitialSources({
      jobCountryCode: currentJobDetails.CountryCode,
      userConfig,
      sources: defaultSources?.length && isAllPortalsAllowed ? defaultSources : _sources,
      portalsConfig,
      allowActiveChannel: true,
    });

  const currentSources = defaultSources?.length && isAllPortalsAllowed ? defaultSources : _sources;
  const initalSources = getInitialSources({
    jobCountryCode: currentJobDetails.CountryCode,
    userConfig,
    sources: currentSources,
    portalsConfig,
    allowActiveChannel: true,
  });
  const buttonName = isManualSearchFormMinimized ? (
    <FormattedMessage {...message.viewEditCriteriaLabel} />
  ) : (
    <FormattedMessage {...message.seeLessButton} />
  );

  const toggleManualSearchFormExpansion = () => {
    if (isManualSearchFormMinimized) minimizeManualSearchForm(false);
  };
  const manualSearchMinimizedView = (
    <ManualSearchMinimizedViewContainer
      isManualSearchFormMinimized={isManualSearchFormMinimized}
      jobId={jobId}
      booleanSearchStrings={booleanSearchStrings}
      fetchBooleanSearchStrings={fetchBooleanSearchStrings}
      form={form}
      defaultFormValues={defaultFormValues}
      currentUserDetails={currentUserDetails}
      candidateContext={candidateContext}
      handleShowMoreLessClick={handleShowMoreLessClick}
      unsavedCriteriaId={unsavedCriteriaId}
      jobGuid={currentJobDetails.JobGuid}
      advancedSettings={mergedAdvancedSettings}
      sourceNamesSelected={_sourceNamesSelected}
      initalSources={initalSources}
      onSearchCandidates={onSearchCandidates}
      isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
      history={history}
      setPreFetchRequired={setPreFetchRequired}
      isPreFetchRequied={isPreFetchRequied}
      manualSearchCandidatesFetchModalVisibility={manualSearchCandidatesFetchModalVisibility}
      searchContext="default"
      countries={countries}
      pageName={pageName}
      version={version}
    />
  );

  const seeMoreButton = (
    <div
      className={styles.seeMoreLessWrapper}
      role="button"
      tabIndex={0}
      onClick={() => handleShowMoreLessClick(!isManualSearchFormMinimized)}
    >
      <div className={styles.seeLessButton}>{buttonName}</div>
    </div>
  );

  const manualSearchExpandedView = (
    <>
      <div
        className={classNames(styles.manualSearchFormWrapper, {
          [styles.manualSearchFormMinimizedView]: isManualSearchFormMinimized,
        })}
        onKeyPress={toggleManualSearchFormExpansion}
        onClick={toggleManualSearchFormExpansion}
        role="button"
        tabIndex={0}
      >
        <div className={`${styles.manualSearchForm} ${isManualSearchFormMinimized ? styles.minimize : ''}`}>
          <div className={styles.candidateCollapsedFilters}>
            <ManualSearchFormHeaderOptions
              jobId={jobId}
              setDefaultFormValues={setDefaultFormValues}
              form={form}
              jobGuid={currentJobDetails.JobGuid}
              advancedSettings={mergedAdvancedSettings}
              currentUserDetails={currentUserDetails}
              setAdvancedSettings={setAdvancedSettings}
              AppName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
              candidateContext={candidateContext}
            />
            {criteriaName ? (
              <div className={styles.savedCriteria}>
                <FormattedMessage {...message.savedCriteria} />: {criteriaName}
              </div>
            ) : null}
            <Tabs tabBarStyle={tabBarStyle} className={styles.manualSearchTabs}>
              <Tabs.TabPane
                tab={<FormattedMessage {...message.searchCandidatesTab} />}
                key="1"
                forceRender
                style={tabPaneStyle}
              >
                <SearchCriteria
                  jobId={jobId}
                  booleanSearchStrings={booleanSearchStrings}
                  fetchBooleanSearchStrings={fetchBooleanSearchStrings}
                  form={form}
                  defaultFormValues={defaultFormValues}
                  isManualSearchFormMinimized={isManualSearchFormMinimized}
                  currentUserDetails={currentUserDetails}
                  candidateContext={candidateContext}
                  sources={updatedSource}
                  sourcesConfig={sourcesConfig}
                  advancedSettings={advancedSettings}
                  defaultAdvancedSettings={mergedAdvancedSettings}
                  setAdvancedSettings={setAdvancedSettings}
                  sourceNamesSelected={_sourceNamesSelected}
                  setSelectedSourceNames={setSelectedSourceNames}
                  currentJobDetails={currentJobDetails}
                  isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
                  isAdvanceSearchV2Enabled={isAdvanceSearchV2Enabled}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<FormattedMessage {...message.advancedOptionsTab} />}
                key="2"
                forceRender
                style={advancedOptionsTabPaneStyle}
              >
                <AdvancedCriteria
                  form={form}
                  defaultFormValues={defaultFormValues}
                  industries={industries}
                  candidateContext={candidateContext}
                  sources={sources}
                  maskingConfig={maskingConfig}
                  jobId={jobId}
                  advancedSettings={mergedAdvancedSettings}
                  setAdvancedSettings={setAdvancedSettings}
                />
              </Tabs.TabPane>
            </Tabs>
            <ManualSearchFormFooter
              form={form}
              unsavedCriteriaId={unsavedCriteriaId}
              jobId={jobId}
              jobGuid={currentJobDetails.JobGuid}
              advancedSettings={mergedAdvancedSettings}
              sourceNamesSelected={_sourceNamesSelected}
              initalSources={initalSources}
              onSearchCandidates={onSearchCandidates}
              candidateContext={candidateContext}
              isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
              history={history}
              isManualSearchFormMinimized={isManualSearchFormMinimized}
              seeMoreButton={seeMoreButton}
              setPreFetchRequired={setPreFetchRequired}
              isPreFetchRequied={isPreFetchRequied}
              manualSearchCandidatesFetchModalVisibility={manualSearchCandidatesFetchModalVisibility}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              selectedCriteriaId={selectedCriteriaId}
              setSelectedCriteriaId={setSelectedCriteriaId}
              setDefaultFormValues={setDefaultFormValues}
              setAdvancedSettings={setAdvancedSettings}
              pageName={pageName}
              version={version}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <LocationWrapper form={form} countryOptions={countries}>
        {manualSearchMinimizedView}
        {manualSearchExpandedView}
      </LocationWrapper>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualSearchForm);
export { ManualSearchForm as ManualSearchFormWithoutStore };
