import React from 'react';
import { Modal, Form, Popover, Timeline, Tooltip, Icon, Button, Radio } from 'antd';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import './EmailComposer.scss';
import RichTextEditor from '../Editor/RichTextEditor';
import ConnectTemplateSelect from '../JobSettings/ConnectTemplateSelect';
import DripsDisplayContainer from '../../Containers/DripsDisplayContainer/DripsDisplayContainer';
import createRequestBody from '../../Containers/DripsDisplayContainer/DripRequestBodyCreator';
import CreditInfoAlert from '../CreditInfo/CreditInfoAlert';
import DropdownWithLabel from '../DropdownWithLabel/DropdownWithLabel';
import SmtpNotConfiguredWarning from '../SmtpNotConfiguredWarning/SmtpNotConfiguredWarning';
import { getProviderLinkedEmails } from '../../Utils/ContactUtils';
import { getVerifiedUserEmails } from '../../Utils/UserInfo';
import HtmlViewer from '../HtmlViewer/HtmlViewer';
import { UserAlertWithWrapper } from '../UserAlerts/UserAlerts';
import { isPulseUser } from '../../Utils/ConfigUtils';
import { getPreHeaderValidationStatus } from '../../Utils/EmailUtils/EmailUtils';
import { MergeTagIcon, PreHeaderIcon } from '../../Icons/AryaIcons';
import CampaignTemplateSelect from './CampaignTemplateSelect';
import message from './EmailComposerMessage';
import jobMessage from '../JobForm/JobMessages';
import placeholder from '../Placeholders/PlaceholdersMessages';

const RadioGroup = Radio.Group;

export const getPreHeaderButton = (preHeaderVisibility, preHeaderButton, dripTemplate) =>
  !preHeaderVisibility && !dripTemplate ? preHeaderButton : null;

export const getCandidate360Preheader = (preHeaderVisibility, dripTemplate, emailPreHeaderLabelWithTooltip) =>
  preHeaderVisibility && !dripTemplate ? (
    <div className="candidate360-preheader-view">{emailPreHeaderLabelWithTooltip}</div>
  ) : null;

class EmailComposer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSmtpConfiguredForSelectedEmail: true,
    };
    this.myRef = React.createRef();
    this.preHeaderRef = React.createRef();
    this.addTags = this.addTags.bind(this);
    this.getFromEmailsDropDown = this.getFromEmailsDropDown.bind(this);
    this.onChangeFromEmail = this.onChangeFromEmail.bind(this);
    this.checkSelectedEmailHasLinkedSMTP = this.checkSelectedEmailHasLinkedSMTP.bind(this);
  }

  addTags(tag) {
    this.myRef.current.addTags(tag);
  }

  addPreHeaderTags = tag => {
    this.preHeaderRef.current.addTags(tag);
  };

  setDefaultEmailTemplateValues = () => {
    const { emailTemplatesById, eventCallbacks } = this.props;
    const defaultEmailTemplate = Object.values(emailTemplatesById).find(template => template.IsDefault);
    const isPulse = isPulseUser();
    if (defaultEmailTemplate?.Id && !isPulse) {
      eventCallbacks.onTemplateSelect(defaultEmailTemplate?.Id);
    }
  };

  onCampaignSelect = ({ templateId, id }) => {
    const { eventCallbacks, setCampaignId } = this.props;
    eventCallbacks.onTemplateSelect(templateId);
    setCampaignId(id);
  };

  componentDidMount() {
    const { selectedFromEmail, emailTemplateIds } = this.props;
    const isSmtpConfiguredForSelectedEmail = this.checkSelectedEmailHasLinkedSMTP(selectedFromEmail);
    this.setState({
      isSmtpConfiguredForSelectedEmail,
    });
    if (emailTemplateIds.length > 0) this.setDefaultEmailTemplateValues();
  }

  componentDidUpdate(prevProps) {
    const { visible, fetchEmailTemplates } = this.props;
    const isPulse = isPulseUser();
    const prevVisibility = _.get(prevProps, 'visible', false);
    if (prevVisibility === false && visible === true) {
      fetchEmailTemplates({ From: 0, Size: 10, IncludeSystemTemplate: !isPulse });
      this.setDefaultEmailTemplateValues();
    }
  }

  getShowMoreDripsButton = () => {
    const { showAllDrips, dripTemplate } = this.props;
    if (_.get(dripTemplate, ['Drops', 'length'], 0) > 1)
      return (
        <div
          className="connect-mail-window-drips-info"
          role="button"
          tabIndex={0}
          onClick={showAllDrips}
          onKeyPress={showAllDrips}
        >
          <span>
            +{dripTemplate.Drops.length - 1} <FormattedMessage {...jobMessage.moreDripEmailsInfo} />
          </span>
        </div>
      );

    return null;
  };

  getDripDropComponent = () => {
    const { dripTemplate, mergeTags, form, version, jobDetails } = this.props;
    if (dripTemplate && dripTemplate.Drops && dripTemplate.Drops.length > 0)
      return (
        <DripsDisplayContainer
          initialValues={{ Drops: (dripTemplate.Drops || []).slice(1) }}
          mergeTags={mergeTags}
          form={form}
          timeLineClassName="bulk-mail-window-display-drip-template"
          initialTimelineIndex={1}
          version={version}
          jobDetails={jobDetails}
          isBulkEmailComposer
        />
      );
    return null;
  };

  onChangeFromEmail(emailId) {
    const { eventCallbacks } = this.props;
    eventCallbacks.onChangeFromEmail(emailId);
    const isSmtpConfiguredForSelectedEmail = this.checkSelectedEmailHasLinkedSMTP(emailId);
    this.setState({
      isSmtpConfiguredForSelectedEmail,
    });
  }

  getFromEmailsDropDown() {
    const { userEmails } = this.props;
    return getVerifiedUserEmails(userEmails);
  }

  checkSelectedEmailHasLinkedSMTP(emailId) {
    const { connectSettings } = this.props;
    const { UserEmailProviders, AdminEmailProviders, OrgDefaultEmail = {} } = connectSettings;
    const linkedEmails = getProviderLinkedEmails(UserEmailProviders);
    const lowercaseLinkedEmails = linkedEmails.map(email => email?.toLowerCase());
    const updatedEmailId = !_.isEmpty(emailId) && emailId?.toLowerCase();
    return (
      (emailId && OrgDefaultEmail.EmailId?.toLowerCase() === updatedEmailId) ||
      (lowercaseLinkedEmails || []).includes(updatedEmailId) ||
      (AdminEmailProviders || []).length !== 0
    );
  }

  getMergeTags = () => {
    const { mergeTags, candidateContext } = this.props;
    let emailSubjectTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject'));
    let emailBodyTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailBody'));
    let emailPreHeaderMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailPreHeader'));

    if (candidateContext === 'segment') {
      emailSubjectTags = emailSubjectTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
      emailBodyTags = emailBodyTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
      emailPreHeaderMergeTags = emailPreHeaderMergeTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
    }
    return { emailSubjectTags, emailBodyTags, emailPreHeaderMergeTags };
  };

  render() {
    const {
      eventCallbacks,
      currentValues,
      form,
      visible,
      onCancel,
      emailTemplateIds,
      emailTemplatesById,
      emailTemplatesCount,
      fetchEmailTemplates,
      selectedEmailTemplateId,
      currentUser,
      selectedActiveCandidateCount,
      dripTemplate,
      isAllDripsVisible,
      credits,
      onCloseCreditInfoAlert,
      isPaidJobServiceEnabled,
      selectedFromEmail,
      candidateDownloadFilterConfig,
      candidateCommunicationFilterConfig,
      communicationDisabledMessage,
      version,
      preHeaderVisibility,
      addPreHeaderToEmail,
      removePreHeaderFromEmail,
      availableCredits,
      userConfig,
      onClickCreateCampaign,
      isCreateCampaign,
      createCampaignTitle,
      handleCampaignTitle,
      jobDetails,
      candidateContext,
      campaignCreationStatus,
      intl,
    } = this.props;
    const preHeaderDeleteButtonLeftPosition = '530px';
    const subjectTextEditorClass = 'candidate360-subject-editor';
    const { isSmtpConfiguredForSelectedEmail } = this.state;
    const { emailSubjectTags, emailBodyTags, emailPreHeaderMergeTags } = this.getMergeTags();
    const isPulse = isPulseUser();

    const { validateFields } = form;
    const regex = /\n$/gi;
    const regexHtml = /(<([^>]+)>)/gi; // to remove HTML tag
    let isEnabled = false;
    let requestBody;
    const selectedEmailTemplateName = _.get(emailTemplatesById, [selectedEmailTemplateId, 'Name'], null);
    const mailInputIdValue = 'mail-input-box-wrap-360';

    const handleSubmit = event => {
      event.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          if (dripTemplate) requestBody = createRequestBody(values, (dripTemplate.Drops || []).slice(1), 1);
          const subject = this.myRef.current.quillRef.getText().trim().replace(regex, '');
          eventCallbacks.onSend(requestBody, subject, values.email);
        }
      });
    };

    const popoverContent = (
      <div className="tags-popover-content">
        {emailSubjectTags.map(mergeTag => (
          <div className="merge-tag" onClick={() => this.addTags(mergeTag)} role="presentation" key={mergeTag.Key}>
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const preHeaderPopoverContent = (
      <div className="tags-popover-content">
        {emailPreHeaderMergeTags.map(mergeTag => (
          <div
            className="merge-tag"
            onClick={() => this.addPreHeaderTags(mergeTag)}
            role="presentation"
            key={mergeTag.Key}
          >
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const addMergeTags = (
      <Popover content={popoverContent} placement="right" trigger="click">
        <Tooltip zIndex={2223} title={<FormattedMessage {...jobMessage.mergeTags} />}>
          <span className="mail-window-merge-tag-360">
            <MergeTagIcon />
          </span>
        </Tooltip>
      </Popover>
    );

    const getEmailSubjectLabelWithMergeTagsFor360 = ({ subjectMergeTags }) => {
      const preHeaderButton = (
        <Tooltip zIndex={2223} title={<FormattedMessage {...jobMessage.preheaderLabel} />}>
          <Button
            id="candidateView360AddPreHeaderButton"
            className="preheaderClassName"
            type="link"
            onClick={addPreHeaderToEmail}
          >
            <PreHeaderIcon />
          </Button>
        </Tooltip>
      );

      return (
        <div className="preheader-with-subject">
          <div className="candidate-view-email-subject-label">
            <span id="labelMergeTag">{subjectMergeTags}</span>
          </div>
          {getPreHeaderButton(preHeaderVisibility, preHeaderButton, dripTemplate)}
        </div>
      );
    };

    const preHeaderMergeTagClass = 'mail-window-merge-tag-preHeader';
    const addPreHeaderMergeTags = (
      <div className={`${preHeaderMergeTagClass}`}>
        <Popover content={preHeaderPopoverContent} placement="right" trigger="click">
          <Tooltip zIndex={2223} title="Merge Tags">
            <MergeTagIcon />
          </Tooltip>
        </Popover>
      </div>
    );

    if (this.myRef.current) {
      isEnabled =
        this.myRef.current.quillRef.getText().trim().replace(regex, '').length !== 0 &&
        currentValues.emailInputValue &&
        currentValues.emailInputValue.trim().replace(regexHtml, '').length !== 0 &&
        selectedFromEmail;
      if (isCreateCampaign) {
        isEnabled = isEnabled && createCampaignTitle;
      }
    }
    if (this.preHeaderRef.current) {
      isEnabled = !getPreHeaderValidationStatus(this.preHeaderRef);
    }

    const verifiedUserEmails = this.getFromEmailsDropDown();
    if (!selectedFromEmail && verifiedUserEmails?.length) {
      eventCallbacks.onChangeFromEmail(verifiedUserEmails[0]);
    }

    const emailPreHeaderValidationMessage = getPreHeaderValidationStatus(this.preHeaderRef) ? (
      <FormattedMessage {...message.preheaderConditionLabel} />
    ) : (
      ''
    );

    const emailPreHeaderLabelWithTooltip = (
      <>
        <span className="bulk-email-input-label-section">
          <span id="email-input-label-name">
            <FormattedMessage {...message.preheaderLabel} />
          </span>
          <Tooltip title={<FormattedMessage {...message.preheaderSummaryLabel} />}>
            <Icon type="question-circle-o" />
          </Tooltip>
          <span id="mergeTagSelect">{addPreHeaderMergeTags}</span>
        </span>
        <Form.Item
          help={emailPreHeaderValidationMessage}
          colon={false}
          validateStatus={getPreHeaderValidationStatus(this.preHeaderRef)}
        >
          <RichTextEditor
            className="subject-editor"
            editorContent={{ htmlContent: currentValues.mailPreHeader }}
            onChange={eventCallbacks.onUpdateEmailPreHeader}
            mergeTags={emailPreHeaderMergeTags}
            ref={this.preHeaderRef}
            showToolbar={false}
            placeholder={intl.formatMessage({ ...placeholder.addPreheaderLabel })}
          />
        </Form.Item>
        <div
          style={{
            position: 'relative',
            width: '0',
            height: '0',
            left: preHeaderDeleteButtonLeftPosition,
            bottom: '28px',
          }}
        >
          <div style={{ position: 'absolute' }}>
            <Icon id="bulkEmailPreHeaderRemoveIcon" onClick={removePreHeaderFromEmail} type="delete" />
          </div>
        </div>
      </>
    );

    const title = (
      <div className="candidate-360-compose-modal-title">
        <span className="compose-modal-title" style={{ flex: '1' }}>
          <FormattedMessage {...message.sendEmailsLabel} />
        </span>
        <div className="expand-shrink-icons">
          <Icon type="close" onClick={onCancel} />
        </div>
      </div>
    );

    const footerButtons = (
      <div style={{ display: 'flex', gap: '12px', alignItems: 'center', justifyContent: 'flex-end' }}>
        <div className="action-buttons">
          <Button shape="round" key="back" onClick={onCancel}>
          <FormattedMessage {...message.cancelLabel} />
          </Button>
          <Button
            shape="round"
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={candidateContext === 'segment' ? campaignCreationStatus === 'INPROGRESS' : false}
            disabled={!isEnabled || !isSmtpConfiguredForSelectedEmail}
          >
            <FormattedMessage {...message.sendLabel} />
          </Button>
        </div>
      </div>
    );
    const modalClassName = 'bulkEmail360ModalBody';
    const emailComposerWrapper = 'bulk-connect-email-dropdown-wrapper';

    const connectTemplateSelectWrapper = 'candidate360-multi-select-mail-window-flex-items';

    const emailComposerSubjectWrapper = 'candidate360-multi-select-email-subject';

    const candidate360SubjectWrapper = 'connect-email-subject-360';

    return (
      <Modal
        title={title}
        visible={visible}
        closable={false}
        footer={[footerButtons]}
        style={{ width: '26%', minWidth: 600 }}
        className={modalClassName}
        okButtonProps={{ disabled: !isEnabled || !isSmtpConfiguredForSelectedEmail }}
        wrapClassName="bulk-compose-email"
      >
        {communicationDisabledMessage ? (
          <UserAlertWithWrapper
            header={communicationDisabledMessage.header}
            content={communicationDisabledMessage.body}
          />
        ) : (
          <>
            <CreditInfoAlert
              credits={credits}
              onCloseCreditInfoAlert={onCloseCreditInfoAlert}
              selectedActiveCandidateCount={selectedActiveCandidateCount}
              currentUser={currentUser}
              isPaidJobServiceEnabled={isPaidJobServiceEnabled}
              availableCredits={availableCredits}
              userConfig={userConfig}
              showCreditInfoAlert
            />
            {selectedFromEmail && !isSmtpConfiguredForSelectedEmail ? (
              <div className="connect-mail-window-alert">
                <SmtpNotConfiguredWarning selectedEmailFrom={selectedFromEmail} version={version} />
              </div>
            ) : null}
            <Form onSubmit={handleSubmit} className="connect-mail-form">
              <div id="candidate-multi-select-mail-window">
                {candidateContext === 'segment' ? (
                  <div className={connectTemplateSelectWrapper}>
                    <CampaignTemplateSelect
                      cssClassname="candidate-multi-select-mail-template-select"
                      placeholderValue={<FormattedMessage {...message.campaignNameLabel} />}
                      onCampaignSelect={this.onCampaignSelect}
                      onChange={this.onChangeFromEmail}
                      onClickCreateCampaign={onClickCreateCampaign}
                      isCreateCampaign={isCreateCampaign}
                      createCampaignTitle={createCampaignTitle}
                      handleCampaignTitle={handleCampaignTitle}
                      jobDetails={jobDetails}
                    />
                  </div>
                ) : null}

                <div className={emailComposerWrapper}>
                  <DropdownWithLabel
                    label="From:"
                    cssClassname="connect-email-dropdown"
                    optionValues={this.getFromEmailsDropDown()}
                    initialValue={selectedFromEmail}
                    placeholderValue="Select an email address (required)"
                    onChange={this.onChangeFromEmail}
                    disabled={verifiedUserEmails?.length < 2}
                  />
                </div>
                <div className={connectTemplateSelectWrapper}>
                  <ConnectTemplateSelect
                    cssClassname="candidate-multi-select-mail-template-select"
                    templateIds={
                      candidateContext === 'segment'
                        ? emailTemplateIds.filter(emailTemplateId => !emailTemplatesById[emailTemplateId].IsDrip)
                        : emailTemplateIds
                    }
                    templatesById={emailTemplatesById}
                    templatesCount={emailTemplatesCount}
                    fetchTemplates={fetchEmailTemplates}
                    isPulse={isPulse}
                    selectedTemplateId={selectedEmailTemplateId}
                    selectedTemplateName={selectedEmailTemplateName}
                    placeholderValue="Select template"
                    setDefaultEmailTemplateValues={this.setDefaultEmailTemplateValues}
                    emailTemplates={Object.values(emailTemplatesById)}
                    onChange={eventCallbacks.onTemplateSelect}
                    isClearFieldAllowed
                  />
                </div>
                {isCreateCampaign ? (
                  <div>
                    <RadioGroup>
                      <Radio value checked className="radio-select">
                        <FormattedMessage {...placeholder.emailLabel} />
                      </Radio>
                    </RadioGroup>
                  </div>
                ) : null}
                <div
                  className={`connect-email-input-wrapper ${
                    isAllDripsVisible ? 'connect-mail-with-timeline' : 'connect-mail-without-timeline'
                  }`}
                >
                  <Timeline.Item dot={1} className="connect-mail-timeline-wrapper">
                    <div className={emailComposerSubjectWrapper}>
                      {getCandidate360Preheader(
                        preHeaderVisibility,

                        dripTemplate,
                        emailPreHeaderLabelWithTooltip
                      )}

                      <div className={candidate360SubjectWrapper}>
                        {getEmailSubjectLabelWithMergeTagsFor360({
                          subjectMergeTags: addMergeTags,
                        })}
                        <RichTextEditor
                          className={subjectTextEditorClass}
                          editorContent={{ htmlContent: currentValues.mailSubject }}
                          onChange={eventCallbacks.onUpdateEmailSubject}
                          mergeTags={emailSubjectTags}
                          ref={this.myRef}
                          showToolbar={false}
                          placeholder={intl.formatMessage({ ...placeholder.subjectLabel })}
                          showSubjectLabel
                        />
                      </div>
                    </div>

                    <div
                      id={mailInputIdValue}
                      className={classNames('connect-mail-window-flex-items', 'candidate360EmailPopUpHead')}
                    >
                      <Form.Item>
                        {currentValues.editorUsed?.toLowerCase() === 'reactemaileditor' ? (
                          <HtmlViewer htmlContent={currentValues.emailInputValue} className="email-composer-preview" />
                        ) : (
                          <RichTextEditor
                            className="mail-editor-composer360"
                            editorContent={{ htmlContent: currentValues.emailInputValue }}
                            onChange={eventCallbacks.onUpdateMailContent}
                            showAdditionalOptions
                            mergeTags={emailBodyTags}
                            showToolbar
                            disabled={currentValues.isReadonly}
                            isCandidate360MailWindow
                          />
                        )}
                      </Form.Item>
                    </div>
                    <BulkCandidatesDownloadFilterInfo
                      candidateDownloadFilterConfig={candidateDownloadFilterConfig}
                      candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
                      dripTemplate={dripTemplate}
                    />
                  </Timeline.Item>
                </div>
              </div>
              {!isAllDripsVisible ? this.getShowMoreDripsButton() : this.getDripDropComponent()}
            </Form>
          </>
        )}
      </Modal>
    );
  }
}

export default injectIntl(Form.create()(EmailComposer));
export { EmailComposer as EmailComposerWithoutInjectIntl };
