import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import styles from './SocialIcon.module.scss';
import { LinkedinIcon, LinkedInCircularIcon } from '../../../Icons/AryaIcons';
import getMediaObject from '../../../Utils/GetMediaLink';
import getSourceSvg from '../../../Utils/GetSourceSvg';
import { getCandidatePrimarySource, getCandidateSource } from '../../../Utils/SourceUtils';

function SocialIcon(props) {
  const { candidate = {}, isActive, isLinkedinCircularIcon = false } = props;
  const sourceObject = getMediaObject(getCandidatePrimarySource(_.get(candidate, ['Sources'], [])));
  const linkedInUrl = sourceObject.url;
  const isLinkedInVisible = !!linkedInUrl;
  const isLinkedInDisabled =
    candidate.Status === 'Sourced' && candidate.ConnectionStatus === 'NotConnected' && !!isActive;

  const LinkedinSvg = isLinkedinCircularIcon ? (
    <LinkedInCircularIcon className={styles.linkedInIcon} style={{ fontSize: '22px' }} />
  ) : (
    <LinkedinIcon className={styles.linkedInIcon} style={{ fontSize: '18px' }} />
  );

  const socialSourceSvg =
    sourceObject.type === 'linkedin'
      ? LinkedinSvg
      : getSourceSvg(sourceObject.type, {
          className: styles.linkedInIcon,
          style: { fontSize: '21px' },
        });

  const candidateSource = getCandidateSource(candidate);
  const showUrl =
    candidateSource?.Portal?.toLowerCase() === 'social' ||
    candidateSource?.Portal?.toLowerCase() === 'linkedinadvance' ||
    candidateSource?.Portal?.toLowerCase() === 'indeed' ||
    candidateSource?.Group?.toLowerCase() === 'passive';

  return isLinkedInVisible && showUrl ? (
    <div className={styles.linkedInContainer}>
      <div className={classNames(styles.socialIcon, { [styles.disabledLinkedIn]: isLinkedInDisabled })}>
        <a
          href={linkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {socialSourceSvg}
        </a>
      </div>
    </div>
  ) : null;
}

export default SocialIcon;
