import React from 'react';
import { Progress } from 'antd';
import InfoIconWithTooltip from '../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import styles from './BulkAdditionProgressIndicator.module.scss';

function BulkAdditionProgressIndicator(props) {
  const { bulkAdditionProgressStats } = props;
  const count = bulkAdditionProgressStats?.NetTraversedCount;
  const totalCount = bulkAdditionProgressStats?.TotalRequestedCount;
  let message;
  let progressBarColor;
  let progressStatus;
  let progressBarRemainingClass;
  let countContent;
  const informationComponent = (
    <InfoIconWithTooltip tooltipTitle="This might take long time depending on the overall requests." />
  );

  if (!count) {
    message = 'Queued';
    progressBarColor = '#FAAD14';
    progressBarRemainingClass = styles.review;
    countContent = totalCount;
  } else {
    message = 'In Progress';
    progressBarColor = '#13C26B';
    progressStatus = 'active';
    progressBarRemainingClass = styles.progress;
    countContent = `${count}/${totalCount}`;
  }

  return (
    <div className={`${styles.bulkAddition} ${progressBarRemainingClass}`}>
      <div className={styles.bulkIndicatorWrapper}>
        <div className={styles.bulkStatusText}>
          {message}
          <div className={styles.infoIcon}>{informationComponent}</div>
        </div>
        <div>{countContent}</div>
      </div>
      <Progress
        percent={((count * 100) / totalCount).toFixed(0)}
        strokeColor={progressBarColor}
        showInfo={false}
        size="small"
        status={progressStatus}
        style={{ width: '100%', minWidth: '170px' }}
      />
    </div>
  );
}

export default BulkAdditionProgressIndicator;
