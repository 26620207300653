export const SET_SUBSEGMENT_LIST = 'SET_SUBSEGMENT_LIST';
export const SET_CREATE_SUBSEGMENT_MODAL_VISIBLE = 'SET_CREATE_SUBSEGMENT_MODAL_VISIBLE';
export const SET_DELETE_SUBSEGMENT_MODAL_VISIBLE = 'SET_DELETE_SUBSEGMENT_MODAL_VISIBLE';
export const SET_SUBSEGMENT_PAGINATION_DATA = 'SET_SUBSEGMENT_PAGINATION_DATA';
export const SET_SELECTED_SUBSEGMENT = 'SET_SELECTED_SUBSEGMENT';
export const SET_ADD_SUBSEGMENT_MODAL_VISIBLE = 'SET_ADD_SUBSEGMENT_MODAL_VISIBLE';
export const SET_CREATED_SUBSEGMENT_ID = 'SET_CREATED_SUBSEGMENT_ID';
export function setSubsegmentList(segmentId, data) {
  return {
    type: SET_SUBSEGMENT_LIST,
    payload: {
      segmentId,
      data,
    },
  };
}
export function setSelectedSubsegmentData(data) {
  return {
    type: SET_SELECTED_SUBSEGMENT,
    payload: data,
  };
}
export function setSubsegmentPaginationData(pageNumber, size) {
  return {
    type: SET_SUBSEGMENT_PAGINATION_DATA,
    payload: {
      pageNumber,
      size,
    },
  };
}

export function setCreateSubsegmentModalVisble(status) {
  return {
    type: SET_CREATE_SUBSEGMENT_MODAL_VISIBLE,
    payload: {
      visible: status,
    },
  };
}
export function setAddSubsegmentModalVisble(status, additionalInfo) {
  return {
    type: SET_ADD_SUBSEGMENT_MODAL_VISIBLE,
    payload: {
      visible: status,
      additionalInfo,
    },
  };
}
export function setDeleteSubsegmentModalVisble(status) {
  return {
    type: SET_DELETE_SUBSEGMENT_MODAL_VISIBLE,
    payload: {
      visible: status,
    },
  };
}

export function setCreatedSubSegmentId(id){
  return {
    type: SET_CREATED_SUBSEGMENT_ID,
    payload: id
  }
}
