import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import * as ReportPortalActions from '../../../Actions/ReportPortalActions';
import ReportSummaryTabComponent from '../../../Components/ReportPortal/ReportSummaryTab/ReportSummaryTabComponent';
import { getCurrentSelectedOrg, getReportSummary } from '../../../Reducers/ReportPortalReducer';

const mapStateToProps = state => {
  return {
    currentUserDetails: getCurrentUserDetails(state),
    reportSummary: getReportSummary(state),
    fetchReportSummaryApiStatus: getApiStatus(state, 'fetchReportSummaryApiStatus'),
    currentSelectedOrg: getCurrentSelectedOrg(state),
  };
};

const mapDispatchToProps = {
  fetchReportSummary: ReportPortalActions.getReportSummary,
  clearFetchReportSummaryApiStatus: ReportPortalActions.clearFetchReportSummaryApiStatus,
};

export function SummaryTabContainer(props) {
  const {
    reportSummary,
    fetchReportSummary,
    currentUserDetails,
    fetchReportSummaryApiStatus,
    clearFetchReportSummaryApiStatus,
    currentSelectedOrg,
  } = props;

  const currentUserId = _.get(currentUserDetails, 'Id');

  React.useEffect(() => {
    return () => {
      clearFetchReportSummaryApiStatus();
    };
  }, []);

  return (
    <ReportSummaryTabComponent
      reportSummary={reportSummary}
      getReportSummary={fetchReportSummary}
      currentUserId={currentUserId}
      fetchReportSummaryApiStatus={fetchReportSummaryApiStatus}
      currentSelectedOrg={currentSelectedOrg}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTabContainer);
