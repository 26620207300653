import React from 'react';
import EmailConfigurationList from '../EmailConfigurationList/EmailConfigurationList';
import EmailConfigurationModal from '../EmailConfigurationModal/EmailConfigurationModal';
import AddEmailConfiguration from '../AddEmailConfiguration/AddEmailConfiguration';
import styles from './EmailConfiguration.module.scss';

const EmailConfiguration = props => {
  const {
    form,
    addUserEmail,
    currentUser,
    userEmails,
    orgDefaultEmail,
    signatures,
    connectEmailToProviderAndTest,
    isAdmin,
    emailProviders,
    deleteUserEmail,
    submitEmailConfiguration,
    emailSmtpConfiguration,
    deleteSmtpEmail,
    deleteEmailConfig,
    SetNotification,
  } = props;

  const [emailConfigurationModalVisiblity, setEmailConfigurationModalVisiblity] = React.useState(false);
  const [addNewEmailAddressFlag, setAddNewEmailAddressFlag] = React.useState(false);
  const [currentEmailConfiguration, setCurrentEmailConfiguration] = React.useState({});

  const onEditConfiguration = email => {
    const emailId = email.EmailId.toLowerCase().trim();
    const signature = signatures.find(x => x.EmailId.toLowerCase().trim() === emailId)?.Signature;
    const _currentEmailConfiguration = { ...email, Signature: signature };
    setCurrentEmailConfiguration(_currentEmailConfiguration);
    setAddNewEmailAddressFlag(false);
    setEmailConfigurationModalVisiblity(true);
  };

  const onAddNewEmailConfiguration = async () => {
    const manualEmailProvider = emailProviders.find(
      emailProvider => emailProvider.ProviderName?.toLowerCase() === 'manual'
    );
    await setCurrentEmailConfiguration({ ProviderConfiguration: manualEmailProvider });
    setEmailConfigurationModalVisiblity(true);
    setAddNewEmailAddressFlag(true);
  };

  return (
    <div className={styles.emailAdditionComponent}>
      <EmailConfigurationList
        currentUser={currentUser}
        userEmails={userEmails}
        orgDefaultEmail={orgDefaultEmail}
        addUserEmail={addUserEmail}
        connectEmailToProviderAndTest={connectEmailToProviderAndTest}
        isAdmin={isAdmin}
        emailProviders={emailProviders}
        deleteUserEmail={deleteUserEmail}
        onEditConfiguration={onEditConfiguration}
        emailSmtpConfiguration={emailSmtpConfiguration}
        deleteSmtpEmail={deleteSmtpEmail}
        deleteEmailConfig={deleteEmailConfig}
      />
      <EmailConfigurationModal
        addNewEmailAddressFlag={addNewEmailAddressFlag}
        currentEmailConfiguration={currentEmailConfiguration}
        setCurrentEmailConfiguration={setCurrentEmailConfiguration}
        emailProviders={emailProviders}
        userEmails={userEmails}
        orgDefaultEmail={orgDefaultEmail}
        isAdmin={isAdmin}
        emailConfigurationModalVisiblity={emailConfigurationModalVisiblity}
        form={form}
        submitEmailConfiguration={submitEmailConfiguration}
        setEmailConfigurationModalVisiblity={setEmailConfigurationModalVisiblity}
        emailSmtpConfiguration={emailSmtpConfiguration}
        deleteEmailConfig={deleteEmailConfig}
        SetNotification={SetNotification}
      />
      <AddEmailConfiguration
        onAddNewEmailConfiguration={onAddNewEmailConfiguration}
        emailSmtpConfiguration={emailSmtpConfiguration}
      />
    </div>
  );
};

export default EmailConfiguration;
export { EmailConfiguration as EmailConfigurationWithoutStore };
