import React from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import LocationContext from '../LocationContext';
import styles from './LocationRegionSelectDropdown.module.scss';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';

const FormItem = Form.Item;
const { Option } = Select;

const LocationRegionSelectDropdown = props => {
  const { label, initialValue, isCountryWideSearchEnabledCountry, onChange, currentLocation, sourcingLocations } =
    props;
  const { form } = React.useContext(LocationContext);
  const locationContextOptions = ['Location', 'Region'];

  const changeLocation = newLocation => {
    const currentKey = sourcingLocations?.[sourcingLocations.length - 1]?.Id || null;
    const index = sourcingLocations.findIndex(location => location.Id === currentKey);
    const clonedLocations = _.cloneDeep(sourcingLocations);
    clonedLocations[index] = newLocation;
    onChange(clonedLocations);
  };

  const handleOnChange = () => {
    changeLocation({
      ...currentLocation,
      Label: undefined,
      Location: undefined,
      State: undefined,
      Postalcode: undefined,
      isStateWideSearchChecked: false,
      Region: undefined,
    });
  };

  const locationContext = (
    <Select
      key="Context"
      optionFilterProp="children"
      defaultActiveFirstOption
      className={styles.locationContextSelect}
      dropdownClassName={styles.locationDropdown}
      allowClear={false}
      disabled={isCountryWideSearchEnabledCountry}
      onChange={handleOnChange}
    >
      {locationContextOptions?.map(_locationContext => (
        <Option value={_locationContext} key={_locationContext}>
          <FormattedMessage {...message[_locationContext]} />
        </Option>
      ))}
    </Select>
  );
  return (
    <div className="wrapper">
      <FormItem label={label} colon={false}>
        {form.getFieldDecorator('LocationContext', {
          initialValue,
          rules: [{ required: true, message: 'Please select either Location or Region' }],
        })(locationContext)}
      </FormItem>
    </div>
  );
};

export default LocationRegionSelectDropdown;
