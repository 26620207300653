import FileSaver from 'file-saver';
import _ from 'lodash';
import * as clientRepository from '../Repository/ClientRepository';
import * as reportPortalRepository from '../Repository/ReportPortalRepository';
import * as userRepository from '../Repository/UserRepository';
import { mapReportDataByCategory, modifyDisplayName } from '../Utils/ReportsDataUtils';
import { setNotification } from './ActionCreators/ConnectActions';
import {
  appendClientListForDropdowns,
  appendJobListForDropdowns,
  appendRecruiterListForDropdowns,
  patchUpdateSavedReportQuery,
  setClientListForDropdowns,
  setClientsMaxCount,
  setDeleteSavedQueryApiStatus,
  setExportReportApiStatus,
  setFetchClientListApiStatus,
  setFetchJobListApiStatus,
  setFetchRecruiterApiStatus,
  setFetchReportColumnsApiStatus,
  setFetchReportStatsApiStatus,
  setFetchReportSummaryApiStatus,
  setFetchSavedQueriesApiStatus,
  setJobListForDropdowns,
  setJobsMaxCount,
  setRecruiterListForDropdowns,
  setRecruitersMaxCount,
  setReportColumns,
  setReportPortalOrgs,
  setReportStats,
  setReportSummary,
  setSaveReportQueryApiStatus,
  setSavedReportQueries,
  setAvailableReportsForOrganization,
  setAvailableReportsApiStatus,
  clearAllTheReportList,
  setReportSummaryData,
  setCurrentOrgInReportSummaryData,
} from './ActionCreators/ReportPortalActionCreator';
import { fetchUsers } from './UserActions';
import { candidateReportWarningMessage } from '../Utils/ReportPortalUtils/ReportPayloadUtils';

function mapErrorCode(error) {
  const source = _.get(error, ['response', 'data', 'Error', 'Code']) || {};
  let errorData = {
    status: 'Oops, something just went wrong',
    error: 'Please try again later or contact support@leoforce.com if this issue persists',
    displayToaster: true,
  };
  switch (source) {
    case 'CONFLICTING_REPORTGENERATIONREQUEST':
      errorData = {
        status: 'FAILED',
        error: 'CONFLICTING_REPORTGENERATIONREQUEST',
        displayToaster: false,
      };
      return errorData;
    case 'FAILED':
      return errorData;
    default:
      return errorData;
  }
}

function getReportSummary(filter) {
  return dispatch => {
    dispatch(setFetchReportSummaryApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getReportSummary(filter)
      .then(response => {
        const payload = response.data;
        dispatch(setReportSummary(payload));
        dispatch(setFetchReportSummaryApiStatus({ status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: _.get(err, 'response'),
        };
        dispatch(setFetchReportSummaryApiStatus(status));
        dispatch(
          setNotification('ERROR',  {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      });
  };
}

function getReportStats(filter, reportStatsCategory) {
  return (dispatch, getStore) => {
    dispatch(setFetchReportStatsApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getReportStats(filter, reportStatsCategory)
      .then(response => {
        dispatch(setReportStats(mapReportDataByCategory(response.data, reportStatsCategory), reportStatsCategory));
        dispatch(setFetchReportStatsApiStatus({ status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: _.get(err, 'response'),
        };
        dispatch(setFetchReportStatsApiStatus(status));
        dispatch(
          setNotification('ERROR',  {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      });
  };
}

function generateReport(filter, reportStatsCategory) {
  return dispatch => {
    dispatch(setExportReportApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .generateReport(filter, reportStatsCategory)
      .then(() => {
        const { dayDifference, content } = candidateReportWarningMessage(filter?.FromDate, filter?.ToDate);
        if (reportStatsCategory?.toLowerCase() === 'candidates' && dayDifference >= 30) {
          dispatch(
            setExportReportApiStatus({
              status: 'CandidateReportWarning',
              content,
            })
          );
        } else {
          dispatch(setExportReportApiStatus({ status: 'COMPLETED' }));
        }
      })
      .catch(err => {
        const status = mapErrorCode(err);
        dispatch(setExportReportApiStatus(status));
        if (status?.displayToaster) {
          dispatch(
            setNotification('ERROR',  {
              messageId: 'oopsSomethingJustWentWrong',
            })
          );
        }
      });
  };
}

function getReportColumns(reportId, reportStatsCategory) {
  return dispatch => {
    dispatch(setFetchReportColumnsApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getReportColumnHeaders(reportId, reportStatsCategory)
      .then(response => {
        const payload = response.data;
        const modifiedPayload = modifyDisplayName(payload);
        dispatch(setReportColumns(modifiedPayload, reportStatsCategory));
        dispatch(setFetchReportColumnsApiStatus({ status: 'COMPLETED' }));
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'COLUMNS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(setFetchReportColumnsApiStatus({ status: 'FAILED' }));
      });
  };
}

function getOrgReportColumns(filter, reportStatsCategory) {
  return dispatch => {
    dispatch(setFetchReportColumnsApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getOrgReportColumnHeaders(filter)
      .then(response => {
        const payload = response.data;
        const modifiedPayload = modifyDisplayName(payload);
        dispatch(setReportColumns(modifiedPayload, reportStatsCategory));
        dispatch(setFetchReportColumnsApiStatus({ status: 'COMPLETED' }));
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'COLUMNS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(setFetchReportColumnsApiStatus({ status: 'FAILED' }));
      });
  };
}

function getAvailableReportsForOrganization() {
  return async dispatch => {
    dispatch(setAvailableReportsApiStatus({ status: 'INPROGRESS' }));

    try {
      const response = await reportPortalRepository.getAvailableReportsForOrganization();
      const payload = response.data.Rows;
      dispatch(setAvailableReportsForOrganization(payload));
      dispatch(setAvailableReportsApiStatus({ status: 'COMPLETED' }));
    } catch (err) {
      const status = {
        status: 'FAILED',
        error: _.get(err, 'response'),
      };
      dispatch(setAvailableReportsApiStatus(status));
      dispatch(
        setNotification('ERROR',  {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

function clearReportStats(reportStatsCategory) {
  return dispatch => {
    dispatch(setReportStats(null, reportStatsCategory));
  };
}

function clearFetchReportSummaryApiStatus() {
  return setFetchReportSummaryApiStatus(null);
}

function clearExportReportApiStatus() {
  return dispatch => {
    dispatch(setExportReportApiStatus(null));
  };
}

function clearFetchReportStatsApiStatus() {
  return setFetchReportStatsApiStatus(null);
}

function clearJobsForDropdownLists() {
  return setJobListForDropdowns([]);
}

function clearRecruitersForDropdownLists(reportStatsCategory) {
  return setRecruiterListForDropdowns([], reportStatsCategory);
}

function clearClientsForDropdownLists(reportStatsCategory) {
  return setClientListForDropdowns([], reportStatsCategory);
}

function fetchClientsForDropdownLists(filter, reportStatsCategory, fetchType = 'INITIAL') {
  return async dispatch => {
    dispatch(setFetchClientListApiStatus({ status: 'INPROGRESS' }));
    try {
      const response = await clientRepository.fetchClientList(filter, filter?.OrgId);
      const clients = response?.data?.Clients;
      const total = response?.data?.Total;
      if (fetchType === 'MORE') {
        dispatch(appendClientListForDropdowns(clients, reportStatsCategory));
      } else {
        dispatch(setClientListForDropdowns(clients, reportStatsCategory));
      }
      dispatch(setClientsMaxCount(total, reportStatsCategory));
      dispatch(setFetchClientListApiStatus({ status: 'COMPLETED' }));
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'CLIENTS_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setFetchClientListApiStatus({ status: 'FAILED' }));
      throw error;
    }
  };
}

function fetchRecruitersForDropdownLists(filter, reportStatsCategory, fetchType = 'INITIAL') {
  return async dispatch => {
    dispatch(setFetchRecruiterApiStatus({ status: 'INPROGRESS' }));
    try {
      const response = await userRepository.fetchUsers(filter, filter?.orgId);
      if (fetchType === 'MORE') {
        dispatch(appendRecruiterListForDropdowns(response?.data?.Users, reportStatsCategory));
      } else {
        dispatch(setRecruiterListForDropdowns(response?.data?.Users, reportStatsCategory));
      }
      dispatch(setRecruitersMaxCount(response?.data?.Total, reportStatsCategory));
      dispatch(setFetchRecruiterApiStatus({ status: 'COMPLETED' }));
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'USERS_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setFetchRecruiterApiStatus({ status: 'FAILED' }));
      throw error;
    }
  };
}

function fetchJobsForDropdownLists(filter, fetchType = 'INITIAL') {
  return dispatch => {
    dispatch(setFetchJobListApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .getJobs(filter)
      .then(response => {
        if (fetchType === 'MORE') {
          dispatch(appendJobListForDropdowns(response?.data?.Jobs));
        } else {
          dispatch(setJobListForDropdowns(response?.data?.Jobs));
        }
        dispatch(setJobsMaxCount(response?.data?.Total));
        dispatch(setFetchJobListApiStatus({ status: 'COMPLETED' }));
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'USERS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(setFetchJobListApiStatus({ status: 'FAILED' }));
      });
  };
}

function downloadReport(fileName) {
  return dispatch => {
    reportPortalRepository
      .downloadReport(fileName)
      .then(response => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const downloadedFileName = fileName;
        FileSaver.saveAs(blob, downloadedFileName);
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR',  {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      });
  };
}

function saveReportQuery(query) {
  return dispatch => {
    dispatch(setSaveReportQueryApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .saveReportQuery(query)
      .then(() => {
        dispatch(setSaveReportQueryApiStatus({ status: 'COMPLETED' }));
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'reportQuerySavedSuccessfully',
          })
        );
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: err?.response,
        };
        dispatch(setSaveReportQueryApiStatus(status));
      });
  };
}

function fetchSavedReportQueries(filter) {
  return dispatch => {
    dispatch(setFetchSavedQueriesApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .fetchSavedQueries(filter)
      .then(response => {
        const payload = response.data;
        const savedReports = payload.SavedReports || [];
        dispatch(setSavedReportQueries(payload));
        let sharedWith = [];
        let ownerIds = [];
        savedReports.forEach(savedReport => {
          if (savedReport.SharedWith?.length) {
            sharedWith = sharedWith.concat(savedReport.SharedWith);
          }
          if (savedReport.CreatedBy) {
            ownerIds = ownerIds.concat(savedReport.CreatedBy);
          }
        });
        sharedWith = sharedWith.filter(userId => !!userId);
        ownerIds = ownerIds.filter(userId => !!userId);
        if (sharedWith?.length || ownerIds?.length) {
          const totalUserIds = _.uniq([...sharedWith, ...ownerIds]);
          dispatch(
            fetchUsers({
              userIds: totalUserIds,
            })
          );
        }
        dispatch(setFetchSavedQueriesApiStatus({ status: 'COMPLETED' }));
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: err?.response,
        };
        dispatch(setFetchSavedQueriesApiStatus(status));
      });
  };
}

function clearSaveReportQueryApiStatus() {
  return setSaveReportQueryApiStatus(null);
}

function getOrganizations({ page, searchTerm, orgIds }) {
  return async dispatch => {
    const res = await reportPortalRepository.fetchOrganizations({
      From: (page - 1) * 10,
      Size: 10,
      SearchKeyword: searchTerm,
      OrgIds: orgIds,
    });
    const orgData = res?.data;
    dispatch(setReportPortalOrgs(orgData, searchTerm));
  };
}

function deleteSavedQuery(queryId) {
  return dispatch => {
    dispatch(setDeleteSavedQueryApiStatus({ status: 'INPROGRESS' }));
    reportPortalRepository
      .deleteSavedQuery(queryId)
      .then(() => {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'savedQueryDeletedSuccessfully',
          })
        );
        dispatch(setDeleteSavedQueryApiStatus({ status: 'COMPLETED' }));
      })
      .catch(() => {
        dispatch(setDeleteSavedQueryApiStatus({ status: 'FAILED' }));
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      });
  };
}

function shareReportSavedQuery({ shareWith, unshareWith, isShareWithAll, isUnshareWithAll, reportId }) {
  return async dispatch => {
    try {
      await reportPortalRepository.shareSavedQuery({
        shareWith,
        unshareWith,
        isShareWithAll,
        isUnshareWithAll,
        reportId,
      });
      const updatedReportQuery = { SharedWith: shareWith, unSharedWith: unshareWith, SharedWithAll: isShareWithAll };
      dispatch(patchUpdateSavedReportQuery({ reportId, updatedReportQuery }));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'savedQuerySharedSuccessfully',
        })
      );
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

function clearDeleteSavedQueryApiStatus() {
  return setDeleteSavedQueryApiStatus(null);
}
function clearTheReportList() {
  return clearAllTheReportList();
}

function clearReportSummaryData() {
  return setReportSummaryData();
}
function setCurrentReportOrg(value) {
  return setCurrentOrgInReportSummaryData(value);
}

export {
  clearClientsForDropdownLists,
  clearDeleteSavedQueryApiStatus,
  clearExportReportApiStatus,
  clearFetchReportStatsApiStatus,
  clearFetchReportSummaryApiStatus,
  clearJobsForDropdownLists,
  clearRecruitersForDropdownLists,
  clearReportStats,
  clearSaveReportQueryApiStatus,
  deleteSavedQuery,
  downloadReport,
  fetchClientsForDropdownLists,
  fetchJobsForDropdownLists,
  fetchRecruitersForDropdownLists,
  fetchSavedReportQueries,
  generateReport,
  getOrgReportColumns,
  getOrganizations,
  getReportColumns,
  getReportStats,
  getReportSummary,
  saveReportQuery,
  shareReportSavedQuery,
  getAvailableReportsForOrganization,
  clearTheReportList,
  clearReportSummaryData,
  setCurrentReportOrg,
};
