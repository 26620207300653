import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import JobMultiLocationInput from '../../Components/JobForm/JobMultiLocationInput/JobMultiLocationInput';
import * as regionActions from '../../Actions/RegionActions';
import { getRegions } from '../../Reducers/RegionReducer';
import useSetDefaultSourcingLocations from '../../Hooks/useSetDeafultSourcingLocations';
import { getLocationSectionConfig } from '../../Utils/MultiLocationUtils';

const mapStateToProps = state => {
  return {
    regions: getRegions(state),
  };
};

const mapDispatchToProps = {
  fetchRegionsByCountryCode: regionActions.fetchRegionsByCountryCode,
};

function JobMultiLocationInputContainer(props) {
  const { regions, fetchRegionsByCountryCode, value, onChange, defaultSourcingLocations, form, countryOptions } = props;

  React.useEffect(() => {
    form.setFieldsValue({ SourcingLocations: [{ Id: uuid.v4(), CountryCode: 'US', IsPrimaryLocation: true }] });
  }, []);

  useSetDefaultSourcingLocations({
    defaultSourcingLocations,
    fetchRegionsByCountryCode,
    value,
    form,
    regions,
    isPrimaryLocation: true,
  });
  const { isRegionSelectionLimitReached, isLocationSelectionLimitReached, isCountryWideSearchEnabledCountry } =
    getLocationSectionConfig(value, countryOptions);
  const isHidden = form.getFieldValue('LocationType') !== 'MultiLocation';

  return (
    <div>
      <JobMultiLocationInput
        locations={value}
        onChange={onChange}
        regions={regions}
        isLocationSelectionLimitReached={isLocationSelectionLimitReached}
        isRegionSelectionLimitReached={isRegionSelectionLimitReached}
        isHidden={isHidden}
        countryOptions={countryOptions}
        isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
        form={form}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobMultiLocationInputContainer);
export { JobMultiLocationInputContainer as JobMultiLocationInputContainerWithoutStore };
