import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Affix, Empty, Icon } from 'antd';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import styles from './CandidateJobs.module.scss';
import CandidateJobsItem from '../CandidateJobsItem/CandidateJobsItem';
import { EmptyJobsIcon } from '../../Icons/AryaIcons';
import ClearFilters from '../Common/ClearFilters/ClearFilters';
import message from '../../Containers/CandidateDrawer/messages';

function CandidateJobs(props) {
  const {
    jobs,
    candidateId,
    jobsTotalCount,
    appendCandidateJobs,
    searchTerm,
    selectedJobStatusFilterOptions,
    selectedCandidateStageFilterOptions,
    clearAllFilter,
    version,
    isATSViewActive,
    openJobViewInNewTabCallBack,
  } = props;

  const getAppendCandidatesJobsPayload = ({ from, size }) => {
    return {
      From: from,
      Size: size,
      candidateId,
      searchTerm,
      CandidateStages: selectedCandidateStageFilterOptions,
      JobStatuses: selectedJobStatusFilterOptions,
    };
  };

  const fetchCandidateJobs = ({ from, size }) => {
    const payload = getAppendCandidatesJobsPayload({ from, size });
    appendCandidateJobs(payload);
  };

  const isFilterApplied = selectedJobStatusFilterOptions?.length || selectedCandidateStageFilterOptions?.length;
  const clearAllFilters = isFilterApplied ? <ClearFilters onClearFilter={clearAllFilter} /> : null;

  return (
    <div
      className={classNames(styles.jobTable, {
        [styles.atsView]: isATSViewActive,
      })}
    >
      <Affix>
        <Row className={styles.tableHeading} type="flex" justify="space-between">
          <Col xs={24} sm={5} className={styles.jobName}>
            <FormattedMessage {...message.candidateJobTitle} />
          </Col>
          <Col xs={24} sm={3}>
            <FormattedMessage {...message.candidateJobId} />
          </Col>
          <Col xs={24} sm={3}>
            <FormattedMessage {...message.clientJobIdLabel} />
          </Col>
          <Col xs={24} sm={3}>
            <FormattedMessage {...message.candidateStageLabel} />
          </Col>
          <Col xs={24} sm={2}>
            <FormattedMessage {...message.jobStatusLabel} />
          </Col>
          <Col xs={24} sm={3}>
            <FormattedMessage {...message.lastUpdatedLabel} />
          </Col>
        </Row>
      </Affix>
      {jobs?.length ? (
        <div className={styles.infiniteScrollDiv}>
          <InfiniteScroll
            useWindow={false}
            initialLoad={false}
            loadMore={page => fetchCandidateJobs({ from: page * 10, size: 10 })}
            hasMore={jobs.length < jobsTotalCount}
            loader={
              <div key="loader" className={styles.loader}>
                <Icon className={styles.loadingIcon} type="loading" spin />
              </div>
            }
            threshold={100}
          >
            {jobs?.map(item => (
              <CandidateJobsItem
                jobTitle={item.JobTitle}
                jobId={item.JobId}
                clientJobId={item.JobCode}
                stage={item.CandidateStage}
                lastUpdated={item.UpdatedTime}
                status={item.JobStatus}
                version={version}
                openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className={styles.emptyScreen}>
          <Empty
            image={<EmptyJobsIcon className={styles.jobListEmpty} />}
            description={
              <div>
                <div>
                  <span className={styles.jobListNotFound}>
                    <FormattedMessage {...message.noJobsFoundLabel} />
                  </span>
                </div>
                {clearAllFilters}
              </div>
            }
          />
        </div>
      )}
    </div>
  );
}

export default CandidateJobs;
