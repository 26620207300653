export const generateScoutingAgentListPayload = (intialValues, newValues) => {
  const selectedIds = Object.keys(newValues);
  return intialValues
    .map(item => {
      if (!selectedIds.includes(`${item.key}`)) {
        return {
          UserId: item.key,
          SearchEnabled: true,
          AutoDownloadEnabled: item.defaultValue === 'searchAndDownload',
          name: item.user,
        };
      }
      if (item.defaultValue.split('-')[0] !== newValues[item.key]) {
        return {
          UserId: item.key,
          SearchEnabled: true,
          AutoDownloadEnabled: newValues[item.key] === 'searchAndDownload',
          name: item.user,
        };
      }
      return null;
    })
    .filter(Boolean);
};

function getNewAndUpdatedUsers(initialValues, newValues) {
  const newAndUpdatedUsers = [];
  for (const id in newValues) {
    if (id) {
      const foundItems = initialValues.find(item => `${item.key}` === id);
      if (foundItems) {
        if (foundItems.defaultValue.split('-')[0] !== newValues[id]) {
          newAndUpdatedUsers.push({
            UserId: id,
            SearchEnabled: true,
            AutoDownloadEnabled: newValues[id] === 'searchAndDownload',
          });
        }
      } else {
        newAndUpdatedUsers.push({
          UserId: id,
          SearchEnabled: true,
          AutoDownloadEnabled: newValues[id] === 'searchAndDownload',
        });
      }
    }
  }
  return newAndUpdatedUsers;
}

export const getUpdatedUsers = (initialValues, newValues) => {
  const newAndUpdatedUsers = getNewAndUpdatedUsers(initialValues, newValues);
  const intialUsersIds = initialValues.map(user => user.key);
  const newUserIds = Object.keys(newValues);
  const usersWhoseScoutingAgentAccessStriped = intialUsersIds.filter(id => !newUserIds.includes(`${id}`));
  usersWhoseScoutingAgentAccessStriped.forEach(id =>
    newAndUpdatedUsers.push({
      UserId: id,
      SearchEnabled: false,
      AutoDownloadEnabled: false,
    })
  );
  return newAndUpdatedUsers;
};

const getInitialScoutingAgentUserConfig = initialScoutingAgentUserList => {
  return Object.fromEntries(
    initialScoutingAgentUserList.map(item => [item.key, { permission: item.defaultValue, isChecked: true }])
  );
};

// if (!newItem) {
// return {
//   UserId: newId,
//   SearchEnabled: true,
//   AutoDownloadEnabled: newValues[newId] === 'searchAndDownload',
// };
// }
// if (newItem.defaultValue.split('-')[0] !== newValues[newId]) {
//   return {
//     UserId: newId,
//     SearchEnabled: true,
//     AutoDownloadEnabled: newValues[newId] === 'searchAndDownload',
//   };
// }
export const getIsSaveScoutingAgentUsersButtonDisabled = (
  selectedKeys,
  initialScoutingAgentUserList,
  currentScoutingAgentConfig
) => {
  console.log({ selectedKeys, initialScoutingAgentUserList, currentScoutingAgentConfig });
  console.log({ getInitialScoutingAgentUserConfig: getInitialScoutingAgentUserConfig(initialScoutingAgentUserList) });
  return true;
};
