import _ from 'lodash';
import * as aryaNotifyRepository from '../Repository/AryaNotifyRepository';
import * as jobRepository from '../Repository/JobRepository';
import { fetchUsers as fetchUsersFromRepo } from '../Repository/UserRepository';
import { setApiStatus } from './ApiStatusActions';

function fetchConnectNofitications(
  lastNotificationId,
  beforeTime,
  isCurrentUserNotificationsOnly,
  shouldReplaceNotifications
) {
  return dispatch => {
    aryaNotifyRepository
      .fetchNotifications({
        LastNotificationId: lastNotificationId,
        BeforeTime: beforeTime,
        Size: 10,
        Source: 'Connect',
        Types: ['SMS', 'Chat', 'ConsentStatus', 'Email'],
        IsCurrentUserNotificationsOnly: isCurrentUserNotificationsOnly,
      })
      .then(response => {
        let jobGuids = response.data.Notifications.map(
          notification => notification.Payload && notification.Payload.RefId
        ).filter(jobGuid => jobGuid);
        jobGuids = [...new Set(jobGuids)];
        if (shouldReplaceNotifications)
          dispatch(setApiStatus({ apiName: 'connectNotifications', status: 'INPROGRESS' }));
        dispatch({
          type: 'APPEND_CONNECT_NOTIFICATIONS',
          payload: { ...response.data, shouldReplaceNotifications },
        });
        if (shouldReplaceNotifications)
          dispatch(setApiStatus({ apiName: 'connectNotifications', status: 'COMPLETED' }));
        return jobGuids;
      })
      .then(jobGuids => {
        if (!jobGuids || jobGuids.length === 0) {
          return;
        }
        jobRepository
          .fetchJobList({
            jobGuids,
          })
          .then(response => {
            const jobGuidsToJobIdMapping = {};
            response.data.Jobs.forEach(job => {
              jobGuidsToJobIdMapping[job.JobGuid] = job.JobId;
            });
            dispatch({
              type: 'SET_JOB_LIST',
              payload: {
                jobs: response.data.Jobs,
              },
            });
            dispatch({
              type: 'SET_JOB_GUID_TO_ID_MAPPING',
              payload: jobGuidsToJobIdMapping,
            });
          });
      })
      .catch(error => {
        if (shouldReplaceNotifications) dispatch(setApiStatus({ apiName: 'connectNotifications', status: 'FAILED' }));
      });
  };
}

function fetchUsersDetails(senderIds) {
  return dispatch => {
    fetchUsersFromRepo({
      userGuids: senderIds,
    }).then(response => {
      dispatch({
        type: 'SET_NOTIFICATIONS_USERS',
        payload: response.data.Users,
      });
    });
  };
}

function fetchAryaNotifications(lastNotificationId, beforeTime, size = 10, types) {
  return dispatch => {
    aryaNotifyRepository
      .fetchNotifications({
        LastNotificationId: lastNotificationId,
        BeforeTime: beforeTime,
        Size: size,
        Source: 'Arya',
        Types: types,
      })
      .then(response => {
        const jobShareNotifications = response.data.Notifications.filter(
          notification => notification.Type === 'JobShare' || notification.Type === 'SegmentShare'
        );
        const senderIds = _.uniq(jobShareNotifications.map(notification => notification.SenderId));
        dispatch({
          type: 'APPEND_ARYA_NOTIFICATIONS',
          payload: response.data,
        });
        return senderIds;
      })
      .then(senderIds => {
        if (!senderIds || senderIds.length === 0) {
          return;
        }
        dispatch(fetchUsersDetails(senderIds));
      });
  };
}

function markNotificationAsRead(payload) {
  return () => {
    aryaNotifyRepository.markNotificationAsRead(payload);
    // .then(() => {
    //   dispatch({
    //     type: 'MARK_NOTIFICATION_READ',
    //     payload: {
    //       NotificationId: notificationId,
    //     },
    //   });
    // });
  };
}

function markSingleNotificationAsRead(id, payload) {
  return () => {
    aryaNotifyRepository.markSingleNotificationAsRead(id, payload);
  };
}

function markConnectNotificationsAsViewed() {
  return dispatch => {
    dispatch({
      type: 'CLEAR_NOTIFICATIONS_VIEWED_COUNT',
      payload: {
        notificationType: 'Connect',
      },
    });
    aryaNotifyRepository.markNotificationsAsViewed({
      Source: 'Connect',
    });
  };
}

function markAryaNotificationsAsViewed(source, types) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_NOTIFICATIONS_VIEWED_COUNT',
      payload: {
        notificationType: 'Arya',
      },
    });
    aryaNotifyRepository.markNotificationsAsViewed({
      Source: source ?? 'Arya',
      Types: types,
    });
  };
}

function updateNewNotificationStatus(status, notificationType, senderId = null) {
  return dispatch => {
    dispatch({
      type: 'NEW_NOTIFICATION_STATUS',
      payload: {
        senderId,
        showNotification: status,
        notificationType,
      },
    });
  };
}

function saveRecentReadNotificationInfo(notificationType, recentReadNotificationInfo) {
  return dispatch => {
    dispatch({
      type: 'SET_RECENT_READ_NOTIFICATION_INFO',
      payload: {
        notificationType,
        info: {
          recentReadNotificationInfo,
        },
      },
    });
  };
}

function setCurrentMessagePopupInfos(notificationId, data) {
  return dispatch => {
    dispatch({
      type: 'SET_CURRENT_MESSAGE_POPUP_INFOS',
      payload: {
        notificationId,
        data,
      },
    });
  };
}

export {
  fetchConnectNofitications,
  fetchAryaNotifications,
  markNotificationAsRead,
  markConnectNotificationsAsViewed,
  markAryaNotificationsAsViewed,
  updateNewNotificationStatus,
  markSingleNotificationAsRead,
  fetchUsersDetails,
  saveRecentReadNotificationInfo,
  setCurrentMessagePopupInfos,
};
