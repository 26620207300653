import React from 'react';
import { Button, Popover, Icon } from 'antd';
import styles from './ReportInvalidIncorrectButton.module.scss';

const InvalidPhoneButton = props => {
  const invalidPhonePopoverContent = () => {
    const { isCurrentSelectedPhoneNumberInvalid, userByGuId } = props;
    const updatedBy = userByGuId[isCurrentSelectedPhoneNumberInvalid?.ContactMismatchInfo.LastModifiedBy]?.FullName;
    const formatDate = dateString => {
      const date = new Date(dateString);
      const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      };
      return date.toLocaleDateString(undefined, options);
    };
    const updatedTime = formatDate(isCurrentSelectedPhoneNumberInvalid?.ContactMismatchInfo.LastUpdatedTime);
    const remarks = isCurrentSelectedPhoneNumberInvalid?.ContactMismatchInfo.MismatchReason || '';
    return (
      <div className={styles.invalidPhoneButtonPopover}>
        <div>
          <b>Marked Invalid By: </b> {updatedBy}
        </div>
        <div>
          <b>Date: </b>
          {updatedTime}
        </div>
        <div>
          <b>Remarks:</b> {remarks}
        </div>
      </div>
    );
  };
  return (
    <div style={{ marginLeft: '24px' }}>
      <Popover
        mouseEnterDelay={0.2}
        placement="top"
        style={{ margin: '10px' }}
        content={invalidPhonePopoverContent()}
        title="Invalid phone number"
      >
        <div>
          <Button type="link">
            <Icon type="exclamation-circle" style={{ color: 'red' }} />
            <span className={styles.invalidPhoneButton}>Invalid phone number</span>
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default InvalidPhoneButton;

export { InvalidPhoneButton as InvalidPhoneButtonWithoutStore };
