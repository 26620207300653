import React from 'react';
import styles from './SkillsSection.module.scss';
import BooleanSearch from './BooleanSearch/BooleanSearch';
import AryaAssistedSkills from './AryaAssistedSkills/AryaAssistedSkills';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import { setUserSkillSearchPreference } from '../../../../Utils/LocalStorageUtils';
import eventTypes from '../../../../Analytics/EventTypes';
import { mapSkillSuggestions } from '../../../../Utils/ManualSearchUtils';

export const ActiveSearchStringButton = ({ value, onChange, visible, form, currentUserDetails }) => {
  const toggleActiveSearchString = () => {
    const newValue = value === 'Manual' ? 'AI' : 'Manual';
    onChange(newValue);
    setUserSkillSearchPreference(newValue, currentUserDetails?.UserGuid);
  };
  const onClear = () => {
    if (value === 'AI') {
      const currentSkills = form.getFieldValue('PreferredSkills');
      const updatedSkills = currentSkills.map(skill => {
        return {
          ...skill,
          isProminent: false,
          isPreferred: false,
          aliasSkills: mapSkillSuggestions(skill.aliasSkills, false),
        };
      });
      form.setFieldsValue({ PreferredSkills: updatedSkills, ExcludedSkills: [], MustHaves: {} });
    } else {
      form.setFieldsValue({ QueryString: '' });
    }
  };
  return (
    <div className={styles.clearAllButton} style={{ display: visible ? 'flex' : 'none' }}>
      <div
        className={styles.skillsSectionLabel}
        role="button"
        tabIndex={0}
        onClick={toggleActiveSearchString}
        onKeyPress={toggleActiveSearchString}
      >
        {value === 'AI' ? 'Use Boolean Search String' : 'Use Arya’s Assistance'}
      </div>
      <div>|</div>
      <ClearAllButton onClear={onClear} skEventName={eventTypes.job.manualSearch.clearSkillsFilter} />
    </div>
  );
};

export default function SkillsSection(props) {
  const {
    isManualSearchFormMinimized,
    form,
    defaultSkills = {},
    defaultQueryString,
    fetchSkillIntel,
    defaultActiveSearchString,
    currentUserDetails,
    jobId,
    booleanSearchStrings,
    fetchBooleanSearchStrings,
  } = props;

  const [searchStringValues, setSearchStringValues] = React.useState([]);
  const isBooleanSearch = form.getFieldValue('ActiveSearchString') === 'Manual';
  const {
    Prefers: defaultPrefers = [],
    Keywords: keywords = [],
    Musts: defaultMustHaves,
    Excludes: defaultExcludes,
  } = defaultSkills;

  const isAryaAssistanceTabVisible = form.getFieldValue('ActiveSearchString') === 'AI';

  React.useEffect(() => {
    if (booleanSearchStrings) setSearchStringValues(booleanSearchStrings?.map(x => x.Value));
  }, [booleanSearchStrings]);
  return (
    <div className={`${styles.skillSelection} ${isManualSearchFormMinimized ? styles.minimize : ''}`}>
      {form.getFieldDecorator('ActiveSearchString', { initialValue: defaultActiveSearchString })(
        <ActiveSearchStringButton
          visible={!isManualSearchFormMinimized}
          form={form}
          currentUserDetails={currentUserDetails}
        />
      )}
      <div className={styles.skillsLabel} style={{ display: isManualSearchFormMinimized ? 'none' : 'block' }}>
        Skills/Keywords
      </div>
      <div
        className={`${styles.aryaAssistedSkills} ${isManualSearchFormMinimized ? styles.minimize : ''}`}
        style={{ display: isAryaAssistanceTabVisible ? 'block' : 'none' }}
      >
        <AryaAssistedSkills
          form={form}
          defaultPrefers={defaultPrefers}
          defaultMustHaves={defaultMustHaves}
          defaultExcludes={defaultExcludes}
          fetchSkillIntel={fetchSkillIntel}
          isManualSearchFormMinimized={isManualSearchFormMinimized}
        />
      </div>
      <div className={styles.booleanSkills} style={{ display: isAryaAssistanceTabVisible ? 'none' : 'block' }}>
        <BooleanSearch
          jobId={jobId}
          isBooleanSearch={isBooleanSearch}
          searchStringValues={searchStringValues}
          fetchBooleanSearchStrings={fetchBooleanSearchStrings}
          form={form}
          keywords={keywords}
          defaultQueryString={defaultQueryString}
        />
      </div>
    </div>
  );
}
