import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import _ from 'lodash';
import config from '../Config/Config';
import { SET_SIGNUP_API_RESPONSE } from '../Utils/SignupUtils';
import * as userRepository from '../Repository/UserRepository';
import { setNotification } from './ActionCreators/ConnectActions';

const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(config.cognitoidentityserviceprovider);

function setBasicInfo(payload) {
  return {
    type: 'SET_BASIC_INFO',
    payload,
  };
}

function setCompanyInfo(payload) {
  return {
    type: 'SET_COMPANY_INFO',
    payload,
  };
}
function setSignupInfo(payload) {
  return {
    type: 'SET_SIGNUP_INFO',
    payload,
  };
}

function clearSignUpStatus() {
  return dispatch => {
    dispatch({
      type: 'SIGNUP_API_STATUS',
      payload: null,
    });
  };
}

function signUp(payload) {
  return async dispatch => {
    dispatch({ type: 'SIGNUP_API_STATUS', payload: 'PENDING' });
    try {
      const response = await userRepository.signUp(payload);
      dispatch({ type: 'SIGNUP_API_STATUS', payload: 'SUCCESS' });
      dispatch({ type: SET_SIGNUP_API_RESPONSE, payload: response });
      return response;
    } catch (errorResponse) {
      dispatch({ type: 'SIGNUP_API_STATUS', payload: 'FAILED' });
      let errorMessage;
      switch (_.get(errorResponse, ['response', 'data', 'Error', 'Code'], null)) {
        case 'CONFLICTING_USER':
          errorMessage = 'yourEmailIsAlreadyRegisteredWithUs';
          break;
        case 'INVALID_PASSWORD':
          errorMessage =
            'invalidPasswordWarning';
          break;
        case 'INVALID_EMAIL':
          errorMessage = 'requiredValidEmailId';
          break;
        default:
          errorMessage = 'oopsSomethingJustWentWrong';
      }
      dispatch(
        setNotification('ERROR',  {
          messageId: errorMessage,
        })
      );
      throw errorResponse;
    }
  };
}

function createGroup(payload, callback) {
  return dispatch => {
    const params = {
      GroupName: payload,
      UserPoolId: config.auth.cognito.poolId,
    };
    dispatch({ type: 'CREATE_GROUP_API_STATUS', payload: 'PENDING' });
    cognitoidentityserviceprovider.createGroup(params, (err, data) => {
      if (err) {
        callback(err, null);
        dispatch({ type: 'CREATE_GROUP_API_STATUS', payload: 'FAILED' });
        throw err;
      } else {
        dispatch({ type: 'CREATE_GROUP_API_STATUS', payload: 'SUCCESS' });
        callback(null, data);
      }
    });
  };
}

function addUserToGroup(payload) {
  return async dispatch => {
    const params = {
      GroupName: payload.GroupName,
      UserPoolId: config.auth.cognito.poolId,
      Username: payload.Username,
    };
    dispatch({ type: 'ADD_USER_TO_GROUP_API_STATUS', payload: 'PENDING' });
    cognitoidentityserviceprovider.adminAddUserToGroup(params, (err, data) => {
      if (err) {
        dispatch({ type: 'ADD_USER_TO_GROUP_API_STATUS', payload: 'FAILED' });
        throw err;
      } else {
        dispatch({ type: 'ADD_USER_TO_GROUP_API_STATUS', payload: 'SUCCESS' });
        return data;
      }
    });
  };
}

function resendVerificationCode(username) {
  // !! TODO
  return async dispatch => {

    dispatch(
      setNotification('LOADING',  {
        messageId: 'sendingVerificationEmail',
      })
    );
    try {
      await Auth.resendSignUp(username);
      dispatch(
        setNotification('SUCCESS',  {
          messageId: 'verificationEmailSent',
        })
      );
    } catch (error) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          Type: 'ERROR',
          Message: error.message,
        },
      });
    }
  };
}

export {
  setBasicInfo,
  setCompanyInfo,
  signUp,
  createGroup,
  addUserToGroup,
  resendVerificationCode,
  clearSignUpStatus,
  setSignupInfo,
};
