import _ from 'lodash';

import {
  SET_CHAT_ID_FOR_THE_JOB,
  SET_CHATS_FOR_THE_JOB,
  SET_TOPIC_ID_FOR_THE_JOB,
  ADD_THREAD_TO_THE_CHAT,
  ADD_REPLY_TO_THE_THREAD,
  SET_ALL_REPLIES_FOR_THE_THREAD,
  SET_LAST_FETCHED_THREAD_COUNT,
  SET_HAS_MORE_REPLIES,
  SET_UNREAD_COUNT_FOR_CHAT,
  ADD_UNREAD_COUNT_FOR_CHAT,
} from '../Actions/ActionCreators/SourcingSupportChatActionCreator';

const initialState = {
  ByChatId: {},
  JobIdToChatIdMapping: {},
  JobIdToTopicIdMapping: {},
  LastFetchedThreadCountByChatId: {},
  UnreadCountByChatId: {},
  UnreadMessageIdListByChatId: {},
};

function SourcingSupportChatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHAT_ID_FOR_THE_JOB: {
      const { chatId, jobId } = action.payload;
      return {
        ...state,
        JobIdToChatIdMapping: {
          ...state.JobIdToChatIdMapping,
          [jobId]: chatId,
        },
      };
    }

    case SET_CHATS_FOR_THE_JOB: {
      const { chatId, messages } = action.payload;
      const newState = _.cloneDeep(state);

      if (!newState.ByChatId[chatId]) {
        return {
          ...state,
          ByChatId: {
            ...state.ByChatId,
            [chatId]: messages,
          },
        };
      }
      const currentStateMessages = state.ByChatId[chatId];
      const allMessages = [...currentStateMessages, ...messages];
      const updatedMessages = _.uniqBy(allMessages, 'Id');
      return {
        ...state,
        ByChatId: {
          ...state.ByChatId,
          [chatId]: updatedMessages,
        },
      };
    }

    case SET_TOPIC_ID_FOR_THE_JOB: {
      const { jobId, topicId } = action.payload;
      return {
        ...state,
        JobIdToTopicIdMapping: {
          ...state.JobIdToTopicIdMapping,
          [jobId]: topicId,
        },
      };
    }

    case ADD_THREAD_TO_THE_CHAT: {
      const { thread } = action.payload;
      const existingThreads = state.ByChatId[thread.ChatId] || [];
      const threadExists = existingThreads.some(existingThread => existingThread.Id === thread.Id);
      if (threadExists) {
        return state;
      }
      return {
        ...state,
        ByChatId: {
          ...state.ByChatId,
          [thread.ChatId]: [thread, ...existingThreads],
        },
      };
    }

    case ADD_REPLY_TO_THE_THREAD: {
      const { reply } = action.payload;
      const newState = _.cloneDeep(state);
      const chat = newState.ByChatId[reply.ChatId];
      const updatedChat = chat.map(thread => {
        if (thread.Id !== reply.ReplyToMessage.Id) return thread;
        const updatedThreadReplies = thread.ThreadReplies || {
          Messages: [],
        };
        const existingReplyIndex = updatedThreadReplies.Messages.findIndex(message => message.Id === reply.Id);
        if (existingReplyIndex === -1) {
          updatedThreadReplies.Messages = [reply, ...updatedThreadReplies.Messages];
        }
        return {
          ...thread,
          ThreadReplies: updatedThreadReplies,
        };
      });
      newState.ByChatId[reply.ChatId] = updatedChat;
      return newState;
    }

    case SET_ALL_REPLIES_FOR_THE_THREAD: {
      const { chatId, messageId, replyMessages, defaultLoad } = action.payload;
      const newState = _.cloneDeep(state);
      const chat = newState.ByChatId[chatId];
      const updatedChat = chat.map(thread => {
        if (thread.Id !== messageId) return thread;
        const updatedThreadReplies = thread.ThreadReplies || {
          Messages: [],
        };
        if (defaultLoad) updatedThreadReplies.Messages = [...replyMessages];
        else updatedThreadReplies.Messages = [...updatedThreadReplies.Messages, ...replyMessages];
        return {
          ...thread,
          ThreadReplies: updatedThreadReplies,
        };
      });
      newState.ByChatId[chatId] = updatedChat;
      return newState;
    }

    case SET_LAST_FETCHED_THREAD_COUNT: {
      const { chatId, threadCount } = action.payload;
      const newState = _.cloneDeep(state);
      newState.LastFetchedThreadCountByChatId[chatId] = threadCount;
      return newState;
    }

    case SET_HAS_MORE_REPLIES: {
      const { chatId, threadId, hasMoreReplies } = action.payload;
      const newState = _.cloneDeep(state);
      const chat = newState.ByChatId[chatId];
      const updatedChat = chat.map(thread => {
        if (thread.Id !== threadId) return thread;
        let newThread = _.cloneDeep(thread);
        newThread = {
          ...newThread,
          hasMoreReplies,
        };
        return newThread;
      });
      newState.ByChatId[chatId] = updatedChat;
      return newState;
    }

    case SET_UNREAD_COUNT_FOR_CHAT: {
      const { chatId, unreadMessageCount } = action.payload;
      return {
        ...state,
        UnreadCountByChatId: {
          ...state.UnreadCountByChatId,
          [chatId]: unreadMessageCount,
        },
      };
    }

    case ADD_UNREAD_COUNT_FOR_CHAT: {
      const { chatId, messageId, unreadMessageCount } = action.payload;
      const messageExists = (state.UnreadMessageIdListByChatId?.[chatId] || []).some(id => id === messageId);

      if (!messageExists) {
        return {
          ...state,
          UnreadCountByChatId: {
            ...state.UnreadCountByChatId,
            [chatId]: (state.UnreadCountByChatId?.[chatId] || 0) + unreadMessageCount,
          },
          UnreadMessageIdListByChatId: {
            ...state.UnreadMessageIdListByChatId,
            [chatId]: [...(state.UnreadMessageIdListByChatId?.[chatId] || []), messageId],
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
}

function getChatIdOfJob(state, jobId) {
  return state.SourcingSupportChatReducer.JobIdToChatIdMapping?.[jobId];
}

function getAllThreadsForTheJob(state, jobId) {
  return (
    state.SourcingSupportChatReducer.ByChatId[state.SourcingSupportChatReducer.JobIdToChatIdMapping?.[jobId]] || []
  );
}

function getTopicIdOfTheJob(state, jobId) {
  return state.SourcingSupportChatReducer.JobIdToTopicIdMapping?.[jobId];
}

function getLastFetchedThreadCount(state, jobId) {
  return state.SourcingSupportChatReducer.LastFetchedThreadCountByChatId[
    state.SourcingSupportChatReducer.JobIdToChatIdMapping?.[jobId]
  ];
}

function getUnreadMessageCountForTheChat(state, jobId) {
  return state.SourcingSupportChatReducer.UnreadCountByChatId[
    state.SourcingSupportChatReducer.JobIdToChatIdMapping?.[jobId]
  ];
}

function getSingleThread(state, chatId, messageId) {
  const threads = state.SourcingSupportChatReducer.ByChatId?.[chatId];

  if (!threads) {
    return null;
  }

  return threads.find(thread => thread.Id === messageId) || null;
}

export {
  getChatIdOfJob,
  SourcingSupportChatReducer,
  getAllThreadsForTheJob,
  getTopicIdOfTheJob,
  getLastFetchedThreadCount,
  getUnreadMessageCountForTheChat,
  getSingleThread,
};
