/* eslint-disable no-constant-condition */
/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { getIncomers, getOutgoers } from 'react-flow-renderer';
import {
  deleteNodeAndOutgoingEdges,
  getIncomingEdges,
  getOutGoingEdges,
  updateEdge,
  getMergeNodeIdOfRuleNode,
} from './GraphUtils';
import { actionTypes } from '../../../Utils/WorkflowUtils';
import nodeStyles from './WorkflowNode.module.scss';

const position = { x: 0, y: 0 };

const weekDaysWithLabel = [
  {
    label: 'Sun',
    value: 'Sunday',
  },
  {
    label: 'Mon',
    value: 'Monday',
  },
  {
    label: 'Tue',
    value: 'Tuesday',
  },
  {
    label: 'Wed',
    value: 'Wednesday',
  },
  {
    label: 'Thu',
    value: 'Thursday',
  },
  {
    label: 'Fri',
    value: 'Friday',
  },
  {
    label: 'Sat',
    value: 'Saturday',
  },
];

const ActionsMapper = [
  {
    displayAction: 'Decision',
    payloadAction: 'If',
  },
  {
    displayAction: 'SendEmail',
    payloadAction: 'AryaConnectSendEmail',
  },
];

const RuleDisplayMapper = {
  AnyEmailAnyLinkClicked: {
    true: {
      category: 'Email',
      rule: 'Clicked',
    },
    false: {
      category: 'Email',
      rule: 'Not Clicked',
    },
  },
  AnyEmailOpened: {
    true: {
      category: 'Email',
      rule: 'Opened',
    },
    false: {
      category: 'Email',
      rule: 'Not Opened',
    },
  },
  CandidateApplied: {
    true: {
      category: 'Candidate',
      rule: 'Applied',
    },
    false: {
      category: 'Candidate',
      rule: 'Not Applied',
    },
  },
};

const RulePayloadMapper = {
  Email: {
    Clicked: {
      Variable: 'AnyEmailAnyLinkClicked',
      Value: 'true',
    },
    'Not Clicked': {
      Variable: 'AnyEmailAnyLinkClicked',
      Value: 'false',
    },
    Opened: {
      Variable: 'AnyEmailOpened',
      Value: 'true',
    },
    'Not Opened': {
      Variable: 'AnyEmailOpened',
      Value: 'false',
    },
  },
  Candidate: {
    Applied: {
      Variable: 'CandidateApplied',
      Value: 'true',
    },
    'Not Applied': {
      Variable: 'CandidateApplied',
      Value: 'false',
    },
  },
};

function validateWaitTime(rule, value, callback, minValue) {
  if (value && !Number.isInteger(Number(value))) {
    callback('Decimals are not allowed');
  } else if (value < minValue) {
    callback(`Wait time cannot be less than ${minValue}`);
  } else if (value > 24) {
    callback('Wait time cannot be greater than 24');
  }
  callback();
}

const getRuleNodeDescription = selectedConditions => {
  const ruleDescription = [];

  selectedConditions.forEach(andScope => {
    const currentAndBlock = [];
    const currentConditions = _.get({ ...andScope }, '1');

    currentConditions.forEach(condition => {
      currentAndBlock.push(`${condition?.category} ${condition?.rule}`);
    });
    ruleDescription.push(`( ${currentAndBlock.join(' AND ')} )`);
  });
  return ruleDescription.join(' OR ');
};

const getTitleAndDescription = ({ type, properties }) => {
  switch (type) {
    case actionTypes.SendEmail: {
      const { EmailTemplateName } = properties;
      return { title: 'Email', description: EmailTemplateName };
    }
    case actionTypes.SendSMS: {
      const { SmsTemplateName } = properties;
      return { title: 'SMS', description: SmsTemplateName };
    }
    case actionTypes.AddTag: {
      const { AddTagList } = properties;
      const description = (
        <div className={nodeStyles.tagContent}>
          {AddTagList?.map(item => (
            <span className={nodeStyles.tagItem}>&nbsp;{`"${item}"`} |</span>
          ))}
        </div>
      );
      return { title: 'Tag', description };
    }
    case actionTypes.AddCustomField: {
      const { CustomFields } = properties;
      const description = (
        <div className={nodeStyles.customTagContent}>
          {CustomFields?.map(field => (
            <span key={field.Name}>&nbsp;{`"${field.Name} = ${field.Value}" |`}</span>
          ))}
        </div>
      );
      return { title: 'Custom Field', description };
    }
    case actionTypes.Delay: {
      const { WaitTime, WaitUnit, ResumeAt } = properties;
      const resumeTime = ResumeAt?.Time ? `| Resume: ${ResumeAt.Time}` : '';
      const resumeDays = ResumeAt?.Days?.length ? `| Resume Days: ( ${ResumeAt.Days?.join(' , ')} )` : '';
      const description = `Wait: ${WaitTime} ${WaitUnit} ${resumeTime} ${resumeDays}`;
      return { title: 'Delay', description };
    }
    case actionTypes.Decision: {
      const { SelectedConditions, NewRuleName } = properties;
      const newRuleName = !NewRuleName ? '' : `: ${NewRuleName}`;
      const ruleNodeDescription = getRuleNodeDescription(SelectedConditions);
      return { title: `Rule${newRuleName}`, description: ruleNodeDescription };
    }
    case actionTypes.End:
      return { title: 'End' };

    default:
      return null;
  }
};

const showNodesAndEdgesAfterEndNodeDeletion = ({ endNodeId, nodes, edges }) => {
  const endNode = nodes.find(n => n.id === endNodeId);
  const outgoingNodeId = _.get(getOutgoers(endNode, nodes, edges), [0]).id;

  const updatedNodes = nodes.map(n => {
    const cloneNode = _.cloneDeep(n);
    if (cloneNode.id === outgoingNodeId) cloneNode.hidden = false;
    return cloneNode;
  });

  const updatedEdges = edges.map(e => {
    const cloneEdge = _.cloneDeep(e);
    if (cloneEdge.source === outgoingNodeId || cloneEdge.target === outgoingNodeId) cloneEdge.hidden = false;
    return cloneEdge;
  });

  return [updatedNodes, updatedEdges];
};

const hideNodesAndEdgesAfterEndNode = ({ elements }) => {
  const nodes = elements.filter(x => x.position);
  const edges = elements.filter(x => !x.position);
  const endNodes = nodes.filter(node => node.type === 'End');
  const nodeIdsToHide = [];

  endNodes.forEach(currentNode => {
    const outgoingNode = _.get(getOutgoers(currentNode, nodes, edges), [0]);
    if (outgoingNode?.type === 'Empty') nodeIdsToHide.push(outgoingNode.id);
  });

  const updatedNodes = nodes.map(n => {
    const cloneNode = _.cloneDeep(n);
    if (nodeIdsToHide.includes(cloneNode.id)) cloneNode.hidden = true;
    return cloneNode;
  });

  const updatedEdges = edges.map(edge => {
    const cloneEdge = _.cloneDeep(edge);
    const endNodeIds = endNodes.map(node => node.id);
    if (endNodeIds.includes(cloneEdge.source) || nodeIdsToHide.includes(cloneEdge.source)) cloneEdge.hidden = true;
    return cloneEdge;
  });

  return [...updatedNodes, ...updatedEdges];
};

const getUpdatedElementsAfterActionNodeAddition = ({ elements, newNodeId, targetNodeId, onAddNodeCallback }) => {
  const clonedElements = _.cloneDeep(elements);
  const newEdge = {
    id: uuidv4(),
    source: newNodeId,
    target: targetNodeId,
    type: 'default',
    data: { onAddNodeCallback },
  };
  clonedElements.push(newEdge);
  return hideNodesAndEdgesAfterEndNode({ elements: clonedElements });
};

const getUpdatedElementsAfterRuleNodeAdditon = ({ elements, newNodeId, targetNodeId, onAddNodeCallback }) => {
  const clonedElements = _.cloneDeep(elements);
  const emptyNode1Id = uuidv4();
  const emptyNode2Id = uuidv4();
  const mergeNodeId = uuidv4();
  const emptyNode1 = {
    id: emptyNode1Id,
    type: 'Empty',
    data: {},
    position,
    height: 6,
  };
  const emptyNode2 = {
    id: emptyNode2Id,
    type: 'Empty',
    data: {},
    position,
    height: 6,
  };
  const mergeNode = {
    id: mergeNodeId,
    type: 'Merge',
    data: {},
    position,
    height: 6,
  };
  const ruleNodeToEmptyNodeEdge1 = {
    id: uuidv4(),
    source: newNodeId,
    target: emptyNode1Id,
    type: 'condition',
    data: { onAddNodeCallback, pathCondition: true, label: 'Yes' },
  };
  const emptyNode1ToMergeNodeEdge = {
    id: uuidv4(),
    source: emptyNode1Id,
    target: mergeNodeId,
    type: 'default',
    data: { onAddNodeCallback, isAddButtonHidden: true },
  };
  const ruleNodeToEmptyNodeEdge2 = {
    id: uuidv4(),
    source: newNodeId,
    target: emptyNode2Id,
    type: 'condition',
    data: { onAddNodeCallback, pathCondition: false, label: 'No' },
  };
  const emptyNode2ToMergeNodeEdge = {
    id: uuidv4(),
    source: emptyNode2Id,
    target: mergeNodeId,
    type: 'default',
    data: { onAddNodeCallback, isAddButtonHidden: true },
  };
  const mergeNodeEdge = {
    id: uuidv4(),
    source: mergeNodeId,
    target: targetNodeId,
    type: 'default',
    data: { onAddNodeCallback },
    mergeNodeOfParentId: newNodeId,
  };
  clonedElements.push(
    ...[
      emptyNode1,
      emptyNode2,
      ruleNodeToEmptyNodeEdge1,
      emptyNode1ToMergeNodeEdge,
      ruleNodeToEmptyNodeEdge2,
      emptyNode2ToMergeNodeEdge,
      mergeNode,
      mergeNodeEdge,
    ]
  );
  return clonedElements;
};

const getUpdatedElementsAfterNodeAddition = ({
  elements,
  targetEdgeId,
  type,
  properties,
  onDeleteNodeCallback,
  onNodeClickCallback,
  onAddNodeCallback,
}) => {
  const newNodeId = uuidv4();
  const { title, description } = getTitleAndDescription({ type, properties });
  const newNode = {
    id: newNodeId,
    type,
    data: {
      title,
      description,
      properties,
      onNodeClickCallback,
      onDeleteNodeCallback,
    },
    position,
  };
  const targetNodeId = elements.find(x => x.id === targetEdgeId).target;
  const clonedElements = _.cloneDeep(elements);
  updateEdge({
    edges: clonedElements,
    targetEdgeId,
    updatedTargetEdgeId: newNodeId,
  });
  clonedElements.push(newNode);

  switch (type) {
    case actionTypes.Decision:
      return getUpdatedElementsAfterRuleNodeAdditon({
        elements: clonedElements,
        newNodeId,
        targetNodeId,
        onAddNodeCallback,
      });
    default:
      return getUpdatedElementsAfterActionNodeAddition({
        elements: clonedElements,
        newNodeId,
        newNode,
        targetNodeId,
        onAddNodeCallback,
      });
  }
};

const getUpdatedElementsAfterActionNodeDeletion = ({ incomingEdges, elements, targetNodeId, type }) => {
  const clonedElements = _.cloneDeep(elements);
  let nodes = clonedElements.filter(x => x.position);
  let edges = clonedElements.filter(x => !x.position);
  if (type === 'End') {
    [nodes, edges] = showNodesAndEdgesAfterEndNodeDeletion({
      endNodeId: targetNodeId,
      nodes,
      edges,
    });
  }
  if (incomingEdges?.length) {
    const newTargetNodeId = getOutgoers({ id: targetNodeId }, nodes, edges)[0].id;
    deleteNodeAndOutgoingEdges({ nodes, edges, targetNodeId });
    updateEdge({ edges, targetEdgeId: incomingEdges[0].id, updatedTargetEdgeId: newTargetNodeId });
  } else {
    deleteNodeAndOutgoingEdges({ nodes, edges, targetNodeId });
  }
  return [...nodes, ...edges];
};

const getUpdatedElementsAfterRuleNodeDeletion = ({ incomingEdges, elements, targetNodeId }) => {
  const clonedElements = _.cloneDeep(elements);
  const nodes = clonedElements.filter(x => x.position);
  const edges = clonedElements.filter(x => !x.position);
  const mergeNodeId = getMergeNodeIdOfRuleNode({
    targetNodeId,
    nodes,
    edges,
  });
  const sourceEdge = incomingEdges[0];
  const currentNode = elements.find(x => x.id === targetNodeId);
  const outGoingNodes = getOutgoers(currentNode, nodes, edges);
  const nodesToBeTraversed = outGoingNodes;
  deleteNodeAndOutgoingEdges({ nodes, edges, targetNodeId });
  const newTargetNodeId = getOutgoers({ id: mergeNodeId }, nodes, edges)[0].id;
  while (1) {
    const traversedNode = nodesToBeTraversed.pop();
    const incomingNodes = getIncomers(traversedNode, nodes, edges);
    if (incomingNodes.length > 0) continue;
    if (traversedNode.id === mergeNodeId) {
      deleteNodeAndOutgoingEdges({
        nodes,
        edges,
        targetNodeId: traversedNode.id,
      });
      break;
    }
    const outGoingEdges = getOutGoingEdges({
      edges,
      targetNodeId: traversedNode.id,
    });
    outGoingEdges.forEach(x => {
      const { target } = x;
      const targetNode = nodes.find(node => node.id === target);
      nodesToBeTraversed.push(targetNode);
    });
    deleteNodeAndOutgoingEdges({
      nodes,
      edges,
      targetNodeId: traversedNode.id,
    });
  }
  updateEdge({
    edges,
    targetEdgeId: sourceEdge.id,
    updatedTargetEdgeId: newTargetNodeId,
  });

  return [...nodes, ...edges];
};

const getUpdatedElementsAfterNodeDeletion = ({ elements, targetNodeId, type }) => {
  const incomingEdges = getIncomingEdges({ edges: elements, targetNodeId });
  const outgoingEdges = getOutGoingEdges({ edges: elements, targetNodeId });
  switch (type) {
    case actionTypes.Decision:
      return getUpdatedElementsAfterRuleNodeDeletion({
        incomingEdges,
        outgoingEdges,
        elements,
        targetNodeId,
      });
    default:
      return getUpdatedElementsAfterActionNodeDeletion({
        incomingEdges,
        outgoingEdges,
        elements,
        targetNodeId,
        type,
      });
  }
};

const onAddWorkflowNode = (type, form) => {
  let payload = {};
  switch (type) {
    case 'SendEmail':
      form.validateFields((err, values) => {
        if (!err) {
          const { EmailTemplateId, EmailTemplateName } = values;
          payload = {
            type,
            properties: {
              EmailTemplateId,
              EmailTemplateName,
            },
          };
        }
      });
      break;

    case 'SendSMS':
      form.validateFields((err, values) => {
        if (!err) {
          const { SmsTemplateId, SmsTemplateName } = values;
          payload = {
            type,
            properties: {
              SmsTemplateId,
              SmsTemplateName,
            },
          };
        }
      });
      break;

    case 'AddTag':
      form.validateFields((err, values) => {
        if (!err) {
          const { AddTagList } = values;
          payload = {
            type,
            properties: {
              AddTagList,
            },
          };
        }
      });
      break;

    case 'AddCustomField':
      form.validateFields((err, values) => {
        if (!err) {
          const { CustomFields } = values;
          payload = {
            type,
            properties: {
              CustomFields,
            },
          };
        }
      });
      break;

    case 'Delay':
      form.validateFields((err, values) => {
        if (!err) {
          const { WaitTime, WaitUnit, ResumeTime, ResumeDays } = values;
          const resumePayloadValues = {
            Time: ResumeTime,
            Days: ResumeDays,
          };

          if (WaitUnit === 'hours' || WaitUnit === 'minutes') {
            resumePayloadValues.Time = undefined;
            resumePayloadValues.Days = undefined;
          }
          payload = {
            type,
            properties: {
              WaitTime: WaitTime || 0,
              WaitUnit,
              ResumeAt: resumePayloadValues,
            },
          };
        }
      });
      break;

    case 'Decision':
      form.validateFields((err, values) => {
        if (!err) {
          const { SetOfKeys, NewRuleName } = values;
          const listOfAndConditions = Object.entries(values)
            .slice(2)
            .map(entry => entry[1]);

          const payloadListOfAndConditions = [];
          listOfAndConditions.forEach(itemsArray => {
            const newItemsArray = [];
            itemsArray.forEach(item => {
              let newItem = null;
              if (item.category && item.rule) {
                newItem = {
                  Name: 'VariableCondition',
                  Rule: _.get(RulePayloadMapper[item.category], [item.rule]),
                };
              }
              newItemsArray.push(newItem);
            });
            payloadListOfAndConditions.push(_.compact(newItemsArray));
          });

          const nestedAndConditions = [];
          payloadListOfAndConditions.forEach(andItem => nestedAndConditions.push({ $AND: andItem }));

          const finalPayload = {
            $OR: nestedAndConditions,
          };
          payload = {
            type,
            properties: {
              SetOfKeys,
              SelectedConditions: Object.entries(values).slice(2),
              // RuleInput,
              NewRuleName,
              Condition: JSON.stringify(finalPayload),
            },
          };
        }
      });
      break;

    case 'End':
      payload = {
        type,
        properties: {},
      };
      break;

    default:
      break;
  }
  return payload;
};

function getStartActivityId(nodes, edges) {
  const startNode = nodes.find(node => node.type === 'Start');
  return getOutgoers(startNode, nodes, edges)[0]?.id;
}

const getResumeDaysFromLabel = selectedDays => {
  return weekDaysWithLabel
    .filter(day => selectedDays?.includes(day.label))
    .map(day => {
      return day.value;
    });
};

const getResumeDaysFromValue = selectedDays => {
  if (!selectedDays) {
    return [];
  }
  return weekDaysWithLabel
    .filter(day => selectedDays?.includes(day.value))
    .map(day => {
      return day.label;
    });
};

const getPropertiesAsKeyValuePairs = properties => {
  const payloadProperties = [];
  Object.entries(properties).forEach(keyValueArray => {
    payloadProperties.push({
      Key: keyValueArray[0],
      Value: keyValueArray[1],
    });
  });
  return payloadProperties;
};

function getActivitiesForPayload(nodes) {
  const Activities = nodes.map(node => {
    const nodeObject = {
      ActivityId: node?.id,
      Type: node?.type,
      Properties: [],
    };

    ActionsMapper.forEach(action => {
      if (action.displayAction === node?.type) {
        nodeObject.Type = action.payloadAction;
      }
    });

    switch (node?.type) {
      case actionTypes.SendEmail:
        {
          const { title, description } = getTitleAndDescription({
            type: actionTypes.SendEmail,
            properties: _.get(node, 'data.properties'),
          });
          nodeObject.Properties = getPropertiesAsKeyValuePairs(_.get(node, 'data.properties'));
          nodeObject.Name = title;
          nodeObject.DisplayName = title;
          nodeObject.Description = description;
        }
        break;

      case actionTypes.SendSMS:
        {
          const { title, description } = getTitleAndDescription({
            type: actionTypes.SendSMS,
            properties: _.get(node, 'data.properties'),
          });
          nodeObject.Properties = getPropertiesAsKeyValuePairs(_.get(node, 'data.properties'));
          nodeObject.Name = title;
          nodeObject.DisplayName = title;
          nodeObject.Description = description;
        }
        break;

      case actionTypes.Delay:
        {
          const { title, description } = getTitleAndDescription({
            type: actionTypes.Delay,
            properties: _.get(node, 'data.properties'),
          });
          const currentProperties = _.get(node, 'data.properties');
          const payloadProperties = [];

          const { WaitTime, WaitUnit, ResumeAt } = currentProperties;
          const waitTime = WaitTime.toString().padStart(2, '0');
          switch (WaitUnit) {
            case 'days': {
              payloadProperties.push({ Key: 'Delay', Value: `${waitTime}:00:00:00` });
              if (ResumeAt?.Days?.length)
                payloadProperties.push({
                  Key: 'ResumeOnDays',
                  Value: getResumeDaysFromLabel(ResumeAt.Days)?.join(),
                });

              if (ResumeAt?.Time) {
                let resumeHour = ResumeAt?.Time?.split(' ')?.join('');
                if (resumeHour?.includes('am')) {
                  resumeHour = resumeHour.slice(0, -2);
                } else if (resumeHour?.includes('pm')) {
                  if (Number(resumeHour?.slice(0, -2)) !== 12) {
                    resumeHour = Number(resumeHour?.slice(0, -2)) + 12;
                  } else {
                    resumeHour = Number(resumeHour?.slice(0, -2));
                  }
                }
                resumeHour = resumeHour.toString().padStart(2, '0');
                const resumeTime = resumeHour ? `${resumeHour}:00:00` : '';
                if (resumeTime)
                  payloadProperties.push({
                    Key: 'ResumeAt',
                    Value: resumeTime,
                  });
              }
              break;
            }

            case 'hours':
              payloadProperties.push({ Key: 'Delay', Value: `00:${waitTime}:00:00` });
              break;

            case 'minutes':
              payloadProperties.push({ Key: 'Delay', Value: `00:00:${waitTime}:00` });
              break;

            default:
              break;
          }
          nodeObject.Properties = payloadProperties;
          nodeObject.Name = title;
          nodeObject.DisplayName = title;
          nodeObject.Description = description;
        }
        break;

      case actionTypes.Decision:
        {
          const properties = {
            NewRuleName: _.get(node, 'data.properties.NewRuleName'),
            Condition: _.get(node, 'data.properties.Condition'),
          };
          const { title, description } = getTitleAndDescription({
            type: actionTypes.Decision,
            properties: _.get(node, 'data.properties'),
          });
          nodeObject.Properties = getPropertiesAsKeyValuePairs(properties);
          nodeObject.Name = _.get(node, 'data.properties.NewRuleName');
          nodeObject.DisplayName = title;
          nodeObject.Description = description;
        }
        break;

      case actionTypes.End:
        nodeObject.Name = 'End';
        nodeObject.DisplayName = 'End';
        break;

      default:
        return null;
    }
    return nodeObject;
  });

  return _.compact(Activities);
}

function getConnectionsForPayload(edges, startActivityId) {
  return edges
    .map(edge => {
      const edgeObject = {
        SourceActivityId: edge?.source,
        TargetActivityId: edge?.target,
        Outcome: 'Done',
      };

      if (edge?.type === 'condition') {
        const outcome = _.get(edge, 'data.label');
        edgeObject.Outcome = outcome;
      }
      return edgeObject;
    })
    .filter(edge => edge.TargetActivityId !== startActivityId);
}

const getKeysAndConditionsFromBooleanString = booleanExpression => {
  const parsedObject = JSON.parse(booleanExpression);

  const conditionsArray = _.get(parsedObject, '$OR');
  const filterdPayloadConditons = [];

  conditionsArray.forEach(item => {
    return filterdPayloadConditons.push(item?.$AND);
  });

  const SetOfKeys = {};
  const finalSelectedConditions = {};

  filterdPayloadConditons.forEach((conditionArray, index) => {
    const currentAndBlockKeyValuePairs = [];
    let currentKeys = [];

    conditionArray.forEach(itemObject => {
      const fieldName = _.get(itemObject, 'Rule.Variable');
      const value = _.get(itemObject, 'Rule.Value');
      currentAndBlockKeyValuePairs.push(_.get(RuleDisplayMapper[fieldName], [value], {}));
    });

    currentKeys = Object.keys({ ...currentAndBlockKeyValuePairs });
    SetOfKeys[index] = currentKeys.map(Number);
    finalSelectedConditions[`andFilterList${index}`] = currentAndBlockKeyValuePairs;
  });

  return { SetOfKeys, SelectedConditions: Object.entries(finalSelectedConditions) };
};

const getPropertiesForDisplay = (type, payloadProperties) => {
  const updatedProperties = {};
  const delay = payloadProperties?.find(prop => prop?.Key === 'Delay')?.Value?.split(':');
  const resumeDefaultValue = { Time: undefined, Days: undefined };

  if (type === 'Delay') {
    let waitTime;
    let waitUnit;
    const days = Number(delay[0]);
    const hours = Number(delay[1]);
    const minutes = Number(delay[2]);

    if (days !== 0 || (days === 0 && hours === 0 && minutes === 0)) {
      waitTime = days.toString();
      waitUnit = 'days';
      const resumeTime = Number(payloadProperties?.find(prop => prop?.Key === 'ResumeAt')?.Value?.split(':')[0]);

      if (resumeTime) {
        if (resumeTime >= 12 && resumeTime < 24) {
          resumeDefaultValue.Time = `${resumeTime % 12 || 12} pm`;
        } else if (resumeTime < 12 && resumeTime >= 1) {
          resumeDefaultValue.Time = `${resumeTime} am`;
        } else {
          resumeDefaultValue.Time = `${resumeTime % 12} am`;
        }
      }

      resumeDefaultValue.Days = getResumeDaysFromValue(
        payloadProperties.find(prop => prop.Key === 'ResumeOnDays')?.Value?.split(',')
      );
    } else if (hours !== 0) {
      waitTime = hours?.toString();
      waitUnit = 'hours';
    } else if (minutes !== 0) {
      waitTime = minutes?.toString();
      waitUnit = 'minutes';
    }

    return {
      WaitTime: waitTime,
      WaitUnit: waitUnit,
      ResumeAt: resumeDefaultValue,
    };
  }

  payloadProperties.forEach(property => {
    const { Key, Value } = property;
    updatedProperties[Key?.toString()] = Value;
  });
  return updatedProperties;
};

function getNodesFromPaylaod(Activities, HighlightedNodeIds) {
  const nodes = Activities.map(activity => {
    const activityId = activity?.ActivityId;
    const nodeObject = {
      id: activityId,
      type: activity?.Type,
      data: { isHighlighted: HighlightedNodeIds.includes(activityId) },
      position,
    };

    ActionsMapper.forEach(action => {
      if (action?.payloadAction === activity?.Type) {
        nodeObject.type = action?.displayAction;
      }
    });

    switch (nodeObject?.type) {
      case actionTypes.SendEmail:
      case actionTypes.SendSMS:
      case actionTypes.Delay:
        nodeObject.data = {
          ...nodeObject.data,
          title: activity?.DisplayName,
          description: activity?.Description,
          properties: getPropertiesForDisplay(nodeObject?.type, activity?.Properties),
        };
        break;

      case actionTypes.Decision:
        {
          const structuredProperties = getPropertiesForDisplay(nodeObject?.type, activity?.Properties);
          const keysAndSelectedConditons = getKeysAndConditionsFromBooleanString(structuredProperties?.Condition);
          const updatedProperties = _.cloneDeep(structuredProperties);
          const { SetOfKeys, SelectedConditions } = keysAndSelectedConditons;
          updatedProperties.SetOfKeys = SetOfKeys;
          updatedProperties.SelectedConditions = SelectedConditions;
          nodeObject.data = {
            ...nodeObject.data,
            title: activity?.DisplayName,
            description: activity?.Description,
            properties: updatedProperties,
          };
        }
        break;

      case actionTypes.End:
        {
          const isLastNode = activity.ActivityId === '2';
          nodeObject.data = {
            ...nodeObject.data,
            title: 'End',
            isLastNode,
          };
        }
        break;

      case 'Start':
        nodeObject.data = {
          title: 'Start',
          data: {
            isHighlighted: HighlightedNodeIds.length > 0,
          },
        };
        break;

      default:
        return {};
    }
    return nodeObject;
  });

  const startNodeExists = nodes.find(node => node?.type === 'Start');

  if (!startNodeExists) {
    nodes.push({
      id: uuidv4(),
      type: 'Start',
      data: { title: 'Start', isHighlighted: HighlightedNodeIds.length > 0 },
      position,
    });
  }
  return nodes;
}
function getTargetNode(targetId, nodes) {
  const targetNode = nodes.find(node => node.id === targetId);
  if (targetNode) return targetNode.id;
  return false;
}

function getEdgesFromPaylaod(Connections, nodes, startActivityId, highlightedNodeIds) {
  const edges = Connections.map(connection => {
    const sourceType = nodes.filter(node => node.id === connection?.SourceActivityId)[0].type;
    const targetType = nodes.filter(node => node.id === connection?.TargetActivityId)[0].type;
    let targetId = connection?.TargetActivityId;
    let targetNode = getTargetNode(targetId, nodes);
    while (!targetNode) {
      // eslint-disable-next-line no-loop-func
      const nextNode = Connections.find(connectionItem => connectionItem?.SourceActivityId === targetId);
      if (!nextNode) break;
      targetId = nextNode.TargetActivityId;
      targetNode = getTargetNode(targetId, nodes);
    }
    const sourceActivityId = connection?.SourceActivityId;
    const targetActivityId = connection?.TargetActivityId;
    const edgeObject = {
      id: uuidv4(),
      source: sourceActivityId,
      target: targetActivityId,
      type: 'default',
      data: {
        sourceType,
        targetType,
        targetNode,
        isHighlighted: highlightedNodeIds.includes(sourceActivityId) && highlightedNodeIds.includes(targetActivityId),
      },
    };

    if (connection?.Outcome === 'Yes') {
      edgeObject.type = 'condition';
      edgeObject.data = {
        ...edgeObject.data,
        pathCondition: true,
        label: 'Yes',
        targetType,
      };
    } else if (connection?.Outcome === 'No') {
      edgeObject.type = 'condition';
      edgeObject.data = {
        ...edgeObject.data,
        pathCondition: false,
        label: 'No',
        targetType,
      };
    }
    return edgeObject;
  });

  const startNode = nodes.find(node => node?.type === 'Start');
  if (startNode) {
    edges.push({
      id: uuidv4(),
      source: startNode.id,
      target: startActivityId,
      type: 'default',
      data: { isHighlighted: startNode.data.isHighlighted },
    });
  }
  return edges;
}

function updateEndNodesHighlightStatus(nodes, edges) {
  const endNodes = nodes.filter(x => x.type === actionTypes.End);
  endNodes.forEach(node => {
    const incomeEdge = getIncomingEdges({ edges, targetNodeId: node.id })[0];
    if (incomeEdge?.data?.isHighlighted) node.data.isHighlighted = true;
  });
}

function getPayloadElements(apiPayloadElements, highlightedNodeIds = []) {
  const { StartActivityId, Activities, Connections } = apiPayloadElements;
  const nodes = getNodesFromPaylaod(Activities, highlightedNodeIds);
  const edges = getEdgesFromPaylaod(Connections, nodes, StartActivityId, highlightedNodeIds);
  if (highlightedNodeIds.length > 0) updateEndNodesHighlightStatus(nodes, edges);

  return { nodes, edges };
}

function generateWorkflowPayload({
  nodes,
  edges,
  workflowName,
  startActivityId,
  publish,
  isCreate,
  editWorkflowDefinitionId,
}) {
  const payload = {
    StartActivityId: startActivityId,
    Name: workflowName,
    Publish: publish,
    Activities: getActivitiesForPayload(nodes),
    Connections: getConnectionsForPayload(edges, startActivityId),
  };

  if (!isCreate && editWorkflowDefinitionId) {
    payload.WorkflowDefinitionId = editWorkflowDefinitionId;
  }

  return payload;
}

const isCreatedWorkflowValid = elements => {
  const clonedElements = [...elements];
  const nodes = clonedElements?.filter(x => x.position);
  const edges = clonedElements?.filter(x => !x.position);
  let validationMessage = '';

  const ruleNodes = nodes?.filter(node => node.type === 'Decision');
  if (ruleNodes?.length > 0) {
    ruleNodes.forEach(node => {
      const currentNode = elements?.find(x => x.id === node?.id);
      const outGoingNodes = getOutgoers(currentNode, nodes, edges);

      if (outGoingNodes?.length === 2) {
        const emptyNodes = outGoingNodes?.filter(outgoingNode => outgoingNode?.type === 'Empty');
        if (emptyNodes?.length === 2)
          validationMessage =
            'At least one node on either of the YES/NO paths under Rule should be created in the workflow';
      }
    });
  }

  const actionNodeExists = nodes.find(node => node.type === 'SendEmail');

  if (clonedElements?.length <= 3 || !actionNodeExists)
    validationMessage = 'At least one Action node should be created in the workflow';

  return validationMessage;
};

const getSystemEmailTemplatesForDuplication = (nodes, emailTemplatesById, workflowName) => {
  const systemEmailTemplateIds = _.compact(
    Object.values(emailTemplatesById)?.map(template => {
      if (template.IsSystemTemplate) return template.Id;
      return null;
    })
  );
  return _.uniqBy(
    _.compact(
      nodes?.map(node => {
        const currentTempalateDetails = _.get(node, ['data', 'properties'], null);
        if (node?.type === 'SendEmail' && systemEmailTemplateIds.includes(currentTempalateDetails?.EmailTemplateId)) {
          return {
            id: currentTempalateDetails.EmailTemplateId,
            name: `${currentTempalateDetails.EmailTemplateName} - ${workflowName}`,
          };
        }
        return null;
      })
    ),
    'id'
  );
};

const getUpdatedNodesWithDuplicateSystemTemplates = (
  nodes,
  templateIdVsDuplicatedTemplateIdKeyValuePairs,
  workflowName
) => {
  return nodes?.map(node => {
    const cloneNode = { ...node };
    const currentNodeProperties = _.get(node, ['data', 'properties'], null);
    if (
      Object.keys(templateIdVsDuplicatedTemplateIdKeyValuePairs).includes(currentNodeProperties?.EmailTemplateId) &&
      node?.type === 'SendEmail'
    ) {
      cloneNode.data.properties = {
        EmailTemplateId: templateIdVsDuplicatedTemplateIdKeyValuePairs[currentNodeProperties?.EmailTemplateId],
        EmailTemplateName: `${currentNodeProperties?.EmailTemplateName} - ${workflowName}`,
      };
    }
    return cloneNode;
  });
};

export {
  getUpdatedElementsAfterNodeAddition,
  getUpdatedElementsAfterNodeDeletion,
  onAddWorkflowNode,
  getTitleAndDescription,
  getMergeNodeIdOfRuleNode,
  getPayloadElements,
  getStartActivityId,
  generateWorkflowPayload,
  validateWaitTime,
  isCreatedWorkflowValid,
  getSystemEmailTemplatesForDuplication,
  getUpdatedNodesWithDuplicateSystemTemplates,
  hideNodesAndEdgesAfterEndNode,
  showNodesAndEdgesAfterEndNodeDeletion,
};
