import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Tabs } from 'antd';
import qs from 'query-string';
import MessageWindowContainer from '../../Containers/MessageWindow/MessageWindow';
import { getCandidateStatus } from '../../Utils/SourceUtils';
import CallWindowContainer from '../../Containers/CallWindow/CallWindow';
import ChatWindowContainer from '../../Containers/ChatWindow/ChatWindow';
import MailWindowContainer from '../../Containers/MailWindow/MailWindow';
import styles from './CandidateProfileContactsTab.module.scss';
import message from '../../Containers/CandidateDrawer/messages';

const { TabPane } = Tabs;

function CandidateProfileContactsTab(props) {
  const {
    connectInfo,
    candidate,
    jobId,
    currentJobDetails,
    jobGuid,
    featureToggleList,
    jobCountryCode,
    postCallNotes,
    toggleBotConfigDrawer,
    candidateContext,
    activeSubTab,
    onSubTabClick,
    version,
    mailRef,
    smsRef,
    callRef,
    isCandidateViewHeaderVisible,
    isComposeEmailModalVisible,
    setEmailComposeModalVisibility,
    isComposeMessageModalVisible,
    setMessageComposeModalVisibility,
    currentSelectedEmail,
    setCurrentSelectedEmail,
    phoneNumber,
    setPhoneNumber,
    isMailCommunicationAllowed,
    isMessageCommunicationAllowed,
    openSipCallWindowsApp,
    isContactTabRedirection,
    setCandidateViewHeaderVisibility,
    isConnectIconsEnabled,
    isAudioReadVisible,
    isMessageReadVisible,
    isEmailReadVisible,
    composeEmailType,
    setComposeEmailType,
    contactsTabRef,
    unsubscribeCandidateButton,
    isUnsubscribeCandidateEnabled,
    isComposeWorkflowModalVisible,
    setWorkflowComposeModalVisibility,
    workflowSelectedEmails,
    setWorkflowSelectedEmails,
    fetchEmailTemplates
  } = props;
  const connectStatuses = connectInfo.ConnectStatuses || {};
  const connectStatus = candidate ? _.get(connectStatuses, candidate.PersonId, {}) : {};
  const availableProviders = _.get(connectStatus, 'Contact.AvailableProviders', null);
  const candidateStatus = getCandidateStatus(_.get(candidate, ['Sources'], []));
  const mailCount = mailRef.current?.state.allEmails?.length || 0;
  const contextualEmailCount = isMailCommunicationAllowed ? mailCount : 0;
  const textCount = smsRef.current?.state?.messages?.length || 0;
  const contextualTextCount = isMessageCommunicationAllowed ? textCount : 0;
  const phoneCount = callRef.current?.state?.callHistory?.Order?.length || 0;
  const isPhoneCommunicationAllowed = callRef.current?.state?.isCommunicationAllowed;
  const contextualPhoneCount = isPhoneCommunicationAllowed ? phoneCount : 0;
  const { redirectTo } = qs.parse(window.location.search);
  const isMessageTabRedirection = redirectTo?.trim().toLowerCase() === 'text';
  React.useEffect(() => {
    if (isMessageTabRedirection) {
      onSubTabClick('message');
    }
  }, []);
  const isWorkflowWindowEnabled = featureToggleList.CandidateLevelWorkflow?.IsEnabled || null;

  const contextualEmail = (
    <span>
      <FormattedMessage {...message.contextualEmailTab} /> ({contextualEmailCount})
    </span>
  );

  const jobTextTab = (
    <span>
      <FormattedMessage {...message.jobMessageTab} /> ({contextualTextCount})
    </span>
  );

  React.useEffect(() => {
    if (activeSubTab && (activeSubTab === "workflow" || activeSubTab === "email")) {
      const excludeTags = [];
      if (activeSubTab === "workflow") excludeTags.push('Drip');
      else excludeTags.push('workflow');
      fetchEmailTemplates(0, 100, true, 'en-US', excludeTags);
    }
  }, [activeSubTab]);


  return (
    <div className={styles.contactTabsAndButton}>
      <div className={styles.contactTabs}>
        <Tabs
          tabPosition="left"
          className={styles.candidateProfileContactTabs}
          size="small"
          activeKey={activeSubTab}
          onChange={onSubTabClick}
        >
          {isConnectIconsEnabled && isEmailReadVisible ? (
            <TabPane tab={<FormattedMessage {...message.emailLabel} /> } key="email" >
              <div>
                <Tabs className={styles.emailTabs}>
                  <TabPane tab={contextualEmail} key="contextualEmails" forceRender>
                    <MailWindowContainer
                      availableProviders={availableProviders}
                      conversationId={candidate.ConversationId}
                      candidate={candidate}
                      jobId={jobId}
                      connectInfo={connectStatus}
                      candidateStatus={candidateStatus}
                      featureToggleList={featureToggleList}
                      timeLineClassName="mail-window-display-drip-template"
                      currentJobDetails={currentJobDetails}
                      candidateContext={candidateContext}
                      version={version}
                      ref={mailRef}
                      isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                      jobGuid={jobGuid}
                      onSubTabClick={onSubTabClick}
                      isComposeEmailModalVisible={isComposeEmailModalVisible}
                      setEmailComposeModalVisibility={setEmailComposeModalVisibility}
                      currentSelectedEmail={currentSelectedEmail}
                      setCurrentSelectedEmail={setCurrentSelectedEmail}
                      isContactTabRedirection={isContactTabRedirection}
                      composeEmailType={composeEmailType}
                      setComposeEmailType={setComposeEmailType}
                      contactsTabRef={contactsTabRef}
                      setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </TabPane>
          ) : null}
          {isConnectIconsEnabled && isMessageReadVisible ? (
            <TabPane tab={<FormattedMessage {...message.messageLabel} />} key="message">
              <Tabs className={styles.smsTabs}>
                <TabPane tab={jobTextTab} key="contextualText" forceRender>
                  <MessageWindowContainer
                    conversationId={candidate.ConversationId}
                    availableProviders={availableProviders}
                    candidate={candidate}
                    jobId={jobId}
                    candidateStatus={candidateStatus}
                    currentJobDetails={currentJobDetails}
                    candidateContext={candidateContext}
                    version={version}
                    ref={smsRef}
                    isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                    isComposeMessageModalVisible={isComposeMessageModalVisible}
                    setMessageComposeModalVisibility={setMessageComposeModalVisibility}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                  />
                </TabPane>
              </Tabs>
            </TabPane>
          ) : null}
          {isConnectIconsEnabled && isAudioReadVisible ? (
            <TabPane tab={<FormattedMessage {...message.phoneLabel} />} key="call">
              <Tabs className={styles.phoneTabs}>
                <TabPane tab={<FormattedMessage {...message.jobsCallsLabel} values={{contextualPhoneCount}}/>} key="contextualPhone" forceRender>
                  <CallWindowContainer
                    availableProviders={availableProviders}
                    conversationId={candidate.ConversationId}
                    jobId={jobId}
                    jobGuid={jobGuid}
                    candidate={candidate}
                    postCallNotes={postCallNotes}
                    candidateId={candidate?.Id}
                    candidateStatus={candidateStatus}
                    featureToggleList={featureToggleList}
                    jobCountryCode={jobCountryCode}
                    currentJobDetails={currentJobDetails}
                    ref={callRef}
                    openSipCallWindowsApp={openSipCallWindowsApp}
                    unsubscribeCandidateButton={unsubscribeCandidateButton}
                    isUnsubscribeCandidateEnabled={isUnsubscribeCandidateEnabled}
                  />
                </TabPane>
              </Tabs>
            </TabPane>
          ) : null}
          {candidateContext === 'job' && featureToggleList.BotRead.IsEnabled && isConnectIconsEnabled ? (
            <TabPane tab="AryaBot" key="bot">
              <ChatWindowContainer
                conversationId={candidate.ConversationId}
                personId={candidate.PersonId}
                showBotConfigDrawer={toggleBotConfigDrawer}
                featureToggleList={featureToggleList}
                candidate={candidate}
                emptyChatStyle={styles.emptyChat}
                setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
                isCandidate360View
              />
            </TabPane>
          ) : null}
          {isWorkflowWindowEnabled && (
            <TabPane tab="Workflow" key="workflow">
              <MailWindowContainer
                workflowWindowFlag
                availableProviders={availableProviders}
                candidate={candidate}
                jobId={jobId}
                featureToggleList={featureToggleList}
                currentJobDetails={currentJobDetails}
                candidateContext={candidateContext}
                version={version}
                jobGuid={jobGuid}
                currentSelectedEmail={currentSelectedEmail}
                setCurrentSelectedEmail={setCurrentSelectedEmail}
                isComposeWorkflowModalVisible={isComposeWorkflowModalVisible}
                setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
                workflowSelectedEmails={workflowSelectedEmails}
                setWorkflowSelectedEmails={setWorkflowSelectedEmails}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
}

export default CandidateProfileContactsTab;
