import React from 'react';
import { Icon } from 'antd';

const StartIcons = props => {
  const { removeFavouriteCandidateApiAtatus, favouriteCandidateApiStatus, candidate, activeTab = '' } = props;
  const status = candidate?.Status || '';

  const isFavouriteCandidateIconVisible =
    (activeTab === 'sourced' || activeTab.toLowerCase() === 'favourite') &&
    (status === '' || status.toLowerCase() === 'favourite');

  const getFavouriteCandidateApiStatus = candidateId => {
    return favouriteCandidateApiStatus?.[candidateId];
  };

  const favouriteCandidateIcon =
    removeFavouriteCandidateApiAtatus?.[candidate.Id] === 'INPROGRESS' ? (
      <Icon type="loading" style={{ fontSize: '30px' }} />
    ) : (
      <Icon type="star" theme="filled" style={{ fontSize: '30px', color: '#13c26b' }} />
    );
  return isFavouriteCandidateIconVisible ? (
    <div>
      {status.toLowerCase() !== 'favourite' ? (
        <Icon
          type={getFavouriteCandidateApiStatus(candidate.Id) === 'INPROGRESS' ? 'loading' : 'star'}
          style={{ fontSize: '30px' }}
        />
      ) : (
        favouriteCandidateIcon
      )}
    </div>
  ) : (
    <div style={{ fontSize: '20px', fontWeight: '600' }}>{status}</div>
  );
};

export default StartIcons;
