import { Button } from 'antd';
import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { ScoutingAgentOutlinedIcon } from '../../../Icons/AryaIcons';
import styles from './ScoutingAgentNotification.module.scss';

function getNotificationInfo(type, payload, updatedTime) {
  const { LatestFetchedCandidateCount, ScoutingAgentName, EndDate } = payload;
  switch (type) {
    case 'ScoutingAgent.ExpiringSoon': {
      const { isExpired, timeString } = getTimeInfo(updatedTime, EndDate);
      const message = isExpired
        ? `${ScoutingAgentName} has expired ${timeString}.`
        : `${ScoutingAgentName} is expiring ${timeString}.`;

      return <div className="message">{message}</div>;
    }
    case 'ScoutingAgent.CandidatesFound': {
      return (
        <div className="message">
          We have found <b>{LatestFetchedCandidateCount}</b> candidates based on the {ScoutingAgentName}
        </div>
      );
    }
    case 'ScoutingAgent.Deleted': {
      return <div className="message">{ScoutingAgentName} has been deleted</div>;
    }
    case 'ScoutingAgent.Stopped': {
      return <div className="message">{ScoutingAgentName} has been stopped</div>;
    }
    case 'ScoutingAgent.CandidatesFilled': {
      return <div className="message">{ScoutingAgentName} has reached the maximum candidate limit</div>;
    }
    default: {
      return <div></div>;
    }
  }
}

function getTimeInfo(updatedTime, EndDate) {
  const parsedUpdatedTime = moment.utc(updatedTime).format('YYYY-MM-DD HH:mm:ss');
  const parsedEndDate = moment.utc(EndDate).format('YYYY-MM-DD HH:mm:ss');

  const duration = moment.duration(moment(parsedEndDate).diff(moment(parsedUpdatedTime)));

  const days = Math.abs(duration.days());
  const hours = Math.abs(duration.hours());
  const minutes = Math.abs(duration.minutes());
  const isExpired = parsedUpdatedTime > parsedEndDate;
  const timeString = getTimeString(days, hours, minutes, isExpired);

  return { isExpired, timeString };
}

function getTimeString(days, hours, minutes, isExpired) {
  const getPastTimeString = (value, unit) => (value === 1 ? `1 ${unit} ago` : `${value} ${unit}s ago`);
  const getFutureTimeString = (value, unit) => (value === 1 ? `in 1 ${unit}` : `in ${value} ${unit}s`);

  switch (true) {
    case isExpired && days > 0:
      return getPastTimeString(days, 'day');
    case isExpired && hours > 0:
      return getPastTimeString(hours, 'hour');
    case isExpired && minutes > 0:
      return getPastTimeString(minutes, 'minute');
    case isExpired:
      return 'just now';
    case days > 0:
      return getFutureTimeString(days, 'day');
    case hours > 0:
      return getFutureTimeString(hours, 'hour');
    case minutes > 0:
      return getFutureTimeString(minutes, 'minute');
    default:
      return 'in few seconds';
  }
}

const ScoutingAgentNotification = props => {
  const { notification, publishedTime, history, onClick, setJobCandidateActiveTab } = props;
  const { Payload: payload, Type: type, UpdatedTime: updatedTime } = notification;
  const { JobId, JobTitle } = payload;
  const handleViewCandidate = async () => {
    await setJobCandidateActiveTab({ activeTab: 'sourced' });
    history.push(`/jobs/${JobId}/candidates?status=sourced&activeTab=scoutingAgent`);
    onClick(notification);
  };

  return (
    <div className={classNames(styles.scoutingAgentNotificationContainer, 'notification')} role="presentation">
      <div className={styles.scoutingAgentNotificationIconWrapper}>
        <div className={styles.scoutingAgentIcon}>
          <ScoutingAgentOutlinedIcon width="20" height="20" style={{ fontSize: '26px', color: '#B4B7BA' }} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.notificationTitle}>
          <span className="bold">
            {JobTitle}, {JobId}
          </span>
        </div>
        {getNotificationInfo(type, payload, updatedTime)}
        <div className="actions" />
        <div className="since">{publishedTime}</div>
        <div className={styles.actionButton}>
          <Button shape="round" onClick={handleViewCandidate}>
            View Candidates
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ScoutingAgentNotification);
export { ScoutingAgentNotification as ScoutingAgentNotificationWithoutRouter };
