import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import * as ReportPortalActions from '../../../Actions/ReportPortalActions';
import { getConfig } from '../../../Reducers/ConfigReducer';
import { getRecruiterList, getRecruiterMaxCount, getOrganizations } from '../../../Reducers/ReportPortalReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import SummaryFilter from '../../../Components/ReportPortal/ReportSummaryTab/SummaryHeader/SummaryFilter/SummaryFilter';
import { fetchUsers as _fetchUsers } from '../../../Actions/UserActions';

const mapStateToProps = state => {
  return {
    userConfig: getConfig(state),
    currentUserDetails: getCurrentUserDetails(state),
    orgs: getOrganizations(state),
    recruiters: getRecruiterList(state, 'summary'),
    fetchRecruiterApiStatus: getApiStatus(state, 'fetchRecruiterApiStatus'),
    recruiterMaxCount: getRecruiterMaxCount(state, 'summary'),
  };
};

const mapDispatchToProps = {
  fetchOrganizations: ReportPortalActions.getOrganizations,
  fetchUsers: _fetchUsers,
  fetchRecruiters: ReportPortalActions.fetchRecruitersForDropdownLists,
  clearReportSummaryData: ReportPortalActions.clearReportSummaryData,
  setCurrentReportOrg: ReportPortalActions.setCurrentReportOrg,
};

export function ReportSummaryFilterContainer(props) {
  const {
    currentUserDetails,
    orgs,
    fetchOrganizations,
    form,
    recruiters,
    fetchRecruiters,
    fetchUsers,
    recruiterMaxCount,
    fetchRecruiterApiStatus,
    userConfig,
    clearReportSummaryData,
    setCurrentReportOrg,
  } = props;

  const currentUserId = currentUserDetails?.Id;
  const canUserImpersonate = userConfig.CanImpersonate;
  const isAdmin = ['admin', 'superadmin'].includes(userConfig?.Role?.toLowerCase()) || canUserImpersonate;

  const selectedOrgId = form?.getFieldValue('orgId');

  const debouncedFetchRecruiters = debounce(fetchRecruiters, 600);
  const onRecruiterSearch = (value, skip, count) =>
    debouncedFetchRecruiters({ orgId: selectedOrgId, searchTerm: value, from: skip, size: count }, 'summary', 'MORE');
  const onRecruiterScroll = (skip, count) =>
    fetchRecruiters({ orgId: selectedOrgId, from: skip, size: count }, 'summary', 'MORE');

  React.useEffect(() => {
    fetchRecruiters(
      {
        from: 0,
        size: 10,
      },
      'summary'
    );
    if (canUserImpersonate) fetchOrganizations({ page: 1, searchTerm: '' });
  }, []);

  React.useEffect(() => {
    if (selectedOrgId) {
      fetchRecruiters(
        {
          from: 0,
          size: 10,
          orgId: selectedOrgId,
        },
        'summary'
      );
    }
  }, [selectedOrgId]);

  React.useEffect(() => {
    if (currentUserId && !Number.isInteger(currentUserId)) {
      fetchUsers({ userGuids: [currentUserId] });
    }
  }, [currentUserId]);

  return (
    <SummaryFilter
      form={form}
      currentUserId={currentUserId}
      onRecruiterSearch={onRecruiterSearch}
      recruiters={recruiters}
      onRecruiterScroll={onRecruiterScroll}
      recruiterMaxCount={recruiterMaxCount}
      fetchRecruiterApiStatus={fetchRecruiterApiStatus}
      isAdmin={isAdmin}
      canUserImpersonate={canUserImpersonate}
      currentUserDetails={currentUserDetails}
      orgs={orgs}
      orgMaxCount={orgs?.Total}
      fetchOrgs={fetchOrganizations}
      clearReportSummaryData={clearReportSummaryData}
      setCurrentReportOrg={setCurrentReportOrg}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSummaryFilterContainer);
