import { apiV1Client } from './BaseRepository';

export function getWorkflowHistory(jobGuid, candidateId) {
  return apiV1Client.get(`Workflow/_history?JobGuid=${jobGuid}&CandidateId=${candidateId}`);
}

export function startWorkflow(payload) {
  return apiV1Client.post('Workflow/_start', payload);
}

export function stopWorkflow(jobGuid, candidateId) {
  return apiV1Client.post(`Workflow/_stop?jobGuid=${jobGuid}&candidateId=${candidateId}`);
}
