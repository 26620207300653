import React from 'react';
import _ from 'lodash';
import { Badge, Button } from 'antd';
import { getRelativeTimeSpanFromNow } from '../../../Utils/RelativeTimeCalculator';
import styles from './ReportExportNotification.module.scss';
import { ReportStatsErrorIcon, ReportStatsIcon } from '../../../Icons/AryaIcons';

function getIcon(notificationType) {
  switch (notificationType?.toLowerCase()) {
    case 'reportgenerated':
      return (
        <div key="reportExportSuccessIcon" className={styles.reportExportSuccessIconWrapper}>
          <ReportStatsIcon className={styles.reportExportSuccessIcon} />
        </div>
      );
    case 'reportgenerationfailed':
      return (
        <div key="reportExportErrorIcon" className={styles.reportExportErrorIconWrapper}>
          <ReportStatsErrorIcon className={styles.reportExportErrorIcon} />
        </div>
      );
    default:
      return null;
  }
}

function getTitle(notificationType) {
  switch (notificationType?.toLowerCase()) {
    case 'reportgenerated':
      return (
        <div key="reportExportSuccessTitle" className={styles.exportNotificationTitle}>
          <div>
            <span className={styles.exportNotificationTitleBold}>Your Report export</span> is ready now.
          </div>
        </div>
      );
    case 'reportgenerationfailed':
      return (
        <div key="reportExportErrorTitle" className={styles.exportNotificationTitle}>
          <div>
            <span className={styles.exportNotificationTitleBold}>Your Report export</span> has{' '}
            <span className={styles.exportNotificationTitleBold}>failed</span>.
          </div>
        </div>
      );
    default:
      return null;
  }
}

function getMessage(notification) {
  const reportType = _.get(notification, ['Payload', 'ReportType'], '');
  return `${reportType === 'RecruiterByJob' ? 'Recruiter By Job' : reportType} Report`;
}

export default function ReportExportNotification(props) {
  const { notification, onClick, downloadReport } = props;
  const onDownloadClick = () => {
    const fileName = _.get(notification, ['Payload', 'ReportFileName'], null);
    downloadReport(fileName);
  };

  const { Type, UpdatedTime, UnreadCount } = notification;
  return (
    <div className={styles.reportExportNotification} role="presentation" onClick={() => onClick(notification)}>
      <div className={styles.icon}>{getIcon(Type)}</div>
      <div className={styles.content}>
        <div className={styles.title}>{getTitle(Type)}</div>
        <div className={styles.message}>{getMessage(notification)}</div>
        <div className={styles.actions}>
          {notification?.Type?.toLowerCase() === 'reportgenerated' ? (
            <Button size="small" onClick={onDownloadClick}>
              Download
            </Button>
          ) : null}
        </div>
        <div className={styles.since}>
          <span className={styles.sinceTime}>{getRelativeTimeSpanFromNow(UpdatedTime)}</span>
        </div>
      </div>
      <div className={styles.isViewed}>
        <Badge dot={UnreadCount > 0} />
      </div>
    </div>
  );
}

export { getIcon, getMessage, getTitle };
