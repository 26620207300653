import cleanSet from 'clean-set';
import {
  SET_SCOUTING_AGENT_MODAL_VISIBILITY,
  SET_JOB_SCOUTING_AGENTS,
  SET_SELECTED_SCOUTING_AGENT,
  SET_SCOUTING_AGENT_ENABLE_JOB_IDS,
  SET_SCOUTING_AGENT_DRAWER,
  SET_SCOUTING_AGENT,
  SET_SCOUTING_AGENT_WARNING_MODAL_VISIBILITY,
  SET_SCOUTING_AGENT_CONFIG_DATA,
  SET_SCOUTING_AGENT_ADMIN_VIEW_STATUS,
  SET_LAST_SCOUTING_AGENT_DELETED_ID,
  UPDATE_ACTIVE_AGENT_COUNTS,
  SET_SCOUTING_AGENT_SEARCH_CRITERIA_MODAL,
  UPDATE_SCOUTING_AGENT_STATS
} from '../Actions/ActionCreators/ScoutingAgentActionCreator';

const initialState = {
  ModalVisible: {
    show: false,
  },
  JobIds: {},
  Drawer: false,
  scoutingAgentJobIds: {},
  ScoutingAgentAdminViewStatus: true,
  scoutingAgentNotification:{}
};

function ScoutingAgentReducer(state = initialState, action) {
  let newState;
  if (action.type === SET_SCOUTING_AGENT_MODAL_VISIBILITY) {
    newState = cleanSet(state, ['ModalVisible', 'show'], action.payload.visible);
    newState = cleanSet(newState, ['ModalVisible', 'mode'], action.payload.mode);
    return newState;
  }
  if (action.type === SET_SCOUTING_AGENT_WARNING_MODAL_VISIBILITY) {
    newState = cleanSet(state, 'WarningModalVisible', action.payload.visible);
    return newState;
  }

  if (action.type === SET_SCOUTING_AGENT_DRAWER) {
    newState = cleanSet(state, 'Drawer', action.payload.visible);
    return newState;
  }

  if (action.type === SET_SCOUTING_AGENT_SEARCH_CRITERIA_MODAL) {
    newState = cleanSet(state, 'SearchCriteria', action.payload.visible);
    return newState;
  }

  if (action.type === SET_LAST_SCOUTING_AGENT_DELETED_ID) {
    newState = cleanSet(state, 'lastScoutingAgentDeleted', action.payload);
    return newState;
  }

  if (action.type === SET_SCOUTING_AGENT_ADMIN_VIEW_STATUS) {
    return cleanSet(state, 'ScoutingAgentAdminViewStatus', action.payload);
  }

  if (action.type === SET_JOB_SCOUTING_AGENTS) {
    newState = cleanSet(state, ['JobIds', action.payload.jobId], action.payload.data);
    const activeAgents = action.payload.data.filter(agent => agent.IsActive).length;
    newState.scoutingAgentJobIds[action.payload.jobId] = activeAgents;
    return newState;
  }
  if (action.type === UPDATE_ACTIVE_AGENT_COUNTS) {
    const { jobId, count } = action.payload;
    const newActiveAgentCount =
      state.scoutingAgentJobIds[jobId] == null ? count : state.scoutingAgentJobIds[jobId] + count;
    newState = cleanSet(state, ['scoutingAgentJobIds', jobId], newActiveAgentCount);
    return newState;
  }
  if (action.type === SET_SELECTED_SCOUTING_AGENT) {
    newState = cleanSet(state, 'selectedScoutingAgentId', action.payload);
    return newState;
  }
  if (action.type === SET_SCOUTING_AGENT_ENABLE_JOB_IDS) {
    const newJobIds = action.payload;
    const exisitingJobIds = state.scoutingAgentJobIds || {};
    newJobIds.forEach(({ JobId: jobId, Count: count }) => {
      exisitingJobIds[jobId] = count;
    });
    newState = cleanSet(state, 'scoutingAgentJobIds', exisitingJobIds);
    return newState;
  }
  if (action.type === SET_SCOUTING_AGENT) {
    newState = cleanSet(state, 'currentScoutingAgent', action.payload);
    return newState;
  }
  if (action.type === SET_SCOUTING_AGENT_CONFIG_DATA) {
    newState = cleanSet(state, 'configData', action.payload);
    return newState;
  }
  if(action.type === UPDATE_SCOUTING_AGENT_STATS){
    const { jobId, agentId} = action.payload;
    newState = cleanSet(state, ['scoutingAgentNotification', jobId], agentId);
    return newState;
  }

  return state;
}

function getScoutingAgentModalVisiblityStatus(state) {
  return state.ScoutingAgentReducer.ModalVisible;
}
function getScoutingAgentWarningModalVisiblityStatus(state) {
  return state.ScoutingAgentReducer.WarningModalVisible || false;
}
function getScoutingAgentDrawerStatus(state) {
  return state.ScoutingAgentReducer.Drawer;
}

function getScoutingAgentSearchCriteriaModalStatus(state) {
  return state.ScoutingAgentReducer.SearchCriteria || false;
}

function getScoutingAgentofJob(state, jobId) {
  return state.ScoutingAgentReducer.JobIds[jobId] || [];
}

function getAllScoutingAgentofJobs(state) {
  return state.ScoutingAgentReducer.JobIds || {};
}
function getLastScoutingAgentDeletedId(state) {
  return state.ScoutingAgentReducer.lastScoutingAgentDeleted || null;
}

function getSelectedScoutingAgent(state) {
  return state.ScoutingAgentReducer.selectedScoutingAgentId;
}
function getScoutingAgentsofEnabledJobs(state, jobId) {
  return state.ScoutingAgentReducer.scoutingAgentJobIds[jobId];
}
function getCurrentScoutingAgent(state) {
  return state.ScoutingAgentReducer.currentScoutingAgent || {};
}
function getScoutingAgentConfig(state) {
  return state.ScoutingAgentReducer.configData;
}

function getScoutingAgentAdminViewStatus(state) {
  return state.ScoutingAgentReducer.ScoutingAgentAdminViewStatus;
}

function getScoutingAgentUpdateStats(state, jobId){
  return state.ScoutingAgentReducer.scoutingAgentNotification[jobId] || null;
}

export {
  ScoutingAgentReducer,
  getScoutingAgentModalVisiblityStatus,
  getScoutingAgentWarningModalVisiblityStatus,
  getScoutingAgentofJob,
  getAllScoutingAgentofJobs,
  getSelectedScoutingAgent,
  getScoutingAgentsofEnabledJobs,
  getScoutingAgentDrawerStatus,
  getCurrentScoutingAgent,
  getScoutingAgentConfig,
  getScoutingAgentAdminViewStatus,
  getLastScoutingAgentDeletedId,
  getScoutingAgentSearchCriteriaModalStatus,
  getScoutingAgentUpdateStats
};
