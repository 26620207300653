import React from 'react';

export const PREMIUM_UPGRADE = 'PremiumUpgrade';
export const PREMIUM = 'Premium';
export const QUANTITY_INPUT_LABEL = 'Number of Jobs :';
export const benefits = [
  {
    id: 1,
    benefit: 'Pre-qualified applicant list',
    TryNow: true,
    Basic: true,
    Premium: true,
    PremiumUpgrade: true,
  },
  {
    id: 2,
    benefit: 'Candidates from premium job sources',
    TryNow: true,
    Basic: true,
    Premium: true,
    PremiumUpgrade: true,
  },
  {
    id: 3,
    benefit: 'Candidate profiles with contact info',
    TryNow: true,
    Basic: true,
    Premium: true,
    PremiumUpgrade: true,
  },
  {
    id: 4,
    benefit: 'Recruiting experts vet for best candidates',
    TryNow: false,
    Basic: true,
    Premium: true,
    PremiumUpgrade: true,
  },
  {
    id: 5,
    benefit: 'Intelligent job advertising to increase applications',
    TryNow: false,
    Basic: false,
    Premium: true,
    PremiumUpgrade: true,
  },
  {
    id: 6,
    benefit: 'Dedicated talent acquisition team per job',
    TryNow: false,
    Basic: false,
    Premium: true,
    PremiumUpgrade: true,
  },
  {
    id: 7,
    benefit: 'Interview-ready candidate list',
    TryNow: false,
    Basic: false,
    Premium: true,
    PremiumUpgrade: true,
  },
];
const discountText = {
  id: 10,
  benefit: 'Avail maximum discount by opting this plan for multiple jobs.',
  TryNow: false,
  Basic: false,
  Premium: false,
  PremiumUpgrade: false,
};
const totalText = {
  id: 11,
  benefit: 'TOTAL',
  TryNow: false,
  Basic: false,
  Premium: false,
  PremiumUpgrade: false,
};

const planDetails = {
  Description: {
    cardType: 'Description',
    princeInfo: 'Price per job',
    description: benefits,
  },
  // TryNow: {
  //   cardType: 'TryNow',
  //   turnAroundTimeInfo: 'Average turnaround time: seconds',
  //   title: 'Try Now',
  //   princeInfo: 'FREE',
  //   princeInfoDescription: '- Up to 5 searches per day',
  //   caption: 'Sample of 10 real candidates',
  //   description: benefits,
  //   buttonText: 'Get Started',
  //   planName: 'TryNow',
  // },
  Basic: {
    cardType: 'Basic',
    turnAroundTimeInfo: 'Average turnaround time: minutes',
    princeInfo: '$199/job',
    caption: 'Up to 100 qualified candidates',
    description: benefits,
    buttonText: 'Select',
    title: 'Basic',
  },
  Premium: {
    cardType: PREMIUM,
    turnAroundTimeInfo: 'Average turnaround time: a few days',
    title: 'Full Service',
    princeInfo: '$599/job',
    caption: 'Get access to connect with top-interview-ready candidates',
    description: benefits,
    buttonText: 'Select',
    isRecommended: true,
  },
  'Premium Upgrade': {
    cardType: PREMIUM_UPGRADE,
    turnAroundTimeInfo: 'Average turnaround time: a few days',
    title: 'Full Service',
    princeInfo: '$600/job',
    caption: 'Engaged, interview-ready candidates',
    description: benefits,
    buttonText: 'Order Upgrade',
    isRecommended: true,
    isUpgrade: true,
    planType: 'Premium',
  },
  MultipleJobs: {
    cardType: 'MultipleJobs',
    title: 'Multiple-Job Plans',
    princeInfo: 'Discounted pricing',
    caption: 'Plans to save you more time & money ',
    description: [
      'You have lots of job opening',
      'Your company has an HR Department',
      'You need database integrations or additional recruiting services',
    ],
    buttonText: 'Select',
    planType: 'MultipleJobs',
    planName: 'Multiple Jobs',
  },
};

export function getProductContent(productVariant) {
  const productName = productVariant.Name;
  const productDisplayName = productVariant.DisplayName;
  switch (productName) {
    case 'TryNow':
      return {
        pos: 1,
        ...planDetails[productName],
        amount: 'FREE - Up to 5 searches per day',
      };
    case 'Basic':
      return {
        pos: 2,
        ...planDetails[productName],
        id: productVariant.Id,
        princeInfo: `$${productVariant.UnitPrice}/job`,
        planName: productVariant.Name,
        title: productDisplayName,
        amount: productVariant.UnitPrice,
      };
    case 'Premium':
      return {
        pos: 3,
        ...planDetails[productName],
        id: productVariant.Id,
        princeInfo: `$${productVariant.UnitPrice}/job`,
        planName: productVariant.Name,
        title: productDisplayName,
        amount: productVariant.UnitPrice,
      };
    case 'Premium Upgrade':
      return {
        pos: 4,
        ...planDetails[productName],
        id: productVariant.Id,
        princeInfo: `$${productVariant.UnitPrice}/job`,
        planName: productVariant.Name,
        title: productDisplayName,
        amount: productVariant.UnitPrice,
      };
    case 'MultipleJobs':
      return {
        pos: 5,
        ...planDetails[productName],
        amount: 'Discounted pricing',
      };
    default:
      return {};
  }
}
export function PulsePromotionalDiscountOfferText() {
  return (
    <>
      Avail maximum &nbsp;
      <a target="blank" href="https://leoforce.com/offer-terms-conditions">
        discount
      </a>
      &nbsp; by opting this plan for multiple jobs.
    </>
  );
}
export function getProductsData(productVariants, isUpgrade, jobGuid, isFromFree, isPulsePromotionalOffer) {
  if (!productVariants) {
    return [];
  }
  const filteredProductVariants = productVariants.filter(productVariant => productVariant.IsHidden !== true);
  let productsData = filteredProductVariants.map(productVariant => ({
    ...getProductContent(productVariant),
  }));

  // productsData.push(getProductContent({ Name: 'TryNow' }));
  productsData = productsData.sort((a, b) => a.pos - b.pos);
  productsData = [{ ...planDetails.Description }, ...productsData];
  if (isUpgrade) {
    return [
      { ...planDetails.Description, princeInfo: 'Plan Features' },
      ...productsData.filter(productData => productData.isUpgrade).map(productData => ({ ...productData, jobGuid })),
    ];
  }
  if (isFromFree) {
    return productsData.filter(
      productData => productData.cardType !== 'PremiumUpgrade' && productData.cardType !== 'MultipleJobs'
    );
  }
  if (!jobGuid) {
    if (isPulsePromotionalOffer) discountText.benefit = <PulsePromotionalDiscountOfferText />;
    productsData = productsData.map(productData => {
      const newProductData = productData;
      const productDescription = [...productData.description, discountText, totalText];
      newProductData.description = productDescription;
      return newProductData;
    });
  }
  return productsData.filter(productData => !productData.isUpgrade);
}

export function getRedirectOptions(isUpgrade, productVariantId, jobGuid, planName, jobId, isFromFree) {
  if (isUpgrade || isFromFree) {
    return {
      pathname: '/payment',
      search: `?variantId=${productVariantId}&jobGuid=${jobGuid}&isUpgrade=${true}&jobId=${jobId}`,
    };
  }
  if (planName === 'TryNow') {
    return {
      pathname: '/_trynow',
    };
  }
  return {
    pathname: '/payment',
    search: `?variantId=${productVariantId}&jobGuid=${jobGuid}&jobId=${jobId}`,
  };
}

export function getPlanSelectionHeaderContent(isUpgrade) {
  return {
    header: isUpgrade ? 'Upgrade your plan & enjoy the benefits of Premium service' : 'Select a plan',
    headerDescription: 'This plan has price set per job with no hidden or additional fee charges.',
  };
}
export function getPlanSelectionFooterContent() {
  return {
    footer: 'Arya by the numbers',
    footerDescription:
      'The talent market has never been so competitive. We pride ourselves in helping our clients find the right candidates at the right time',
    footerStats: [
      {
        id: 1,
        valueNumber: '100',
        valueText: 'Million+',
        caption: 'Candidates Sourced',
      },
      { id: 2, valueNumber: '90,000', caption: 'Clients & Client Companies' },
      { id: 3, valueNumber: '16,000', caption: 'Recruiters Supported Globally' },
    ],
  };
}

export function getProductVariantsByName(productVariantById) {
  const productVariantByName = {};
  Object.keys(productVariantById).forEach(id => {
    productVariantByName[productVariantById[id].Name] = productVariantById[id];
  });
  return productVariantByName;
}

export const FREE_TRIAL = 'FreeTrial';

export const LOGO_WIDTH = '200px';
export const LOGO_HEIGHT = '100px';
export const ALT_TEXT = 'logo';
export const FOOTER_TEXT = 'COMPANIES TRUSTED & GROWING WITH ARYA';
export const BASIC = 'Basic';
export const INPUT_VALIDATION_ERROR = 'Please enter valid number of jobs between 1 to 100';
export const TOTAL = 'Total';
export const PRICE = 'Price';
export const DISCOUNT = 'Discount';
export const AMOUNT = 'Amount';
export const CONTACTED_INFO_TEXT = "Candidates to whom you've initiated outreach to via email, text or Call";
export const ENGAGED_INFO_TEXT =
  'This list includes only those candidates who have responded to your outreach email, text.';
