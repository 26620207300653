/* eslint-disable no-continue */
import _ from 'lodash';
import { getLocaleDateStringWithTimeStamp } from './RelativeTimeCalculator';

function validateStatus(status) {
  if (status?.toLowerCase() === 'invalid' || status?.toLowerCase() === 'spam') {
    return true;
  }
  return false;
}

function getIsContactMismatch(contactMismatchInfo) {
  if (!contactMismatchInfo) return false;
  return contactMismatchInfo.IsMismatch;
}

function getNonRefundedPhones(phones) {
  return _.filter(phones, phone => !_.get(phone, ['IsRefunded'], false));
}

function getNonRefundedEmails(emails) {
  return _.filter(emails, email => !_.get(email, ['IsRefunded'], false));
}

function getManuallyAddedContactsCount(connectInfo) {
  const manuallyAddedPhones = _.filter(_.get(connectInfo, ['Contact', 'Phones'], []), phone =>
    _.get(phone, ['IsAddedByUser'], false)
  );
  const manuallyAddedEmails = _.filter(_.get(connectInfo, ['Contact', 'Emails'], []), email =>
    _.get(email, ['IsAddedByUser'], false)
  );
  return (manuallyAddedPhones || []).length + (manuallyAddedEmails || []).length;
}

function getNonRefundedContacts(connectInfo) {
  const phones = _.get(connectInfo, ['Contact', 'Phones'], []);
  const nonRefundedPhones = getNonRefundedPhones(phones);
  const emails = _.get(connectInfo, ['Contact', 'Emails'], []);
  const nonRefundedEmails = getNonRefundedEmails(emails);
  return { nonRefundedPhones, nonRefundedEmails };
}

function checkIfAnyNonRefundedPulledContactsExists(connectInfo) {
  const phones = _.get(connectInfo, ['Contact', 'Phones'], []);
  const nonRefundedPhones = _.filter(phones, phone => !_.get(phone, ['IsRefunded'], false));
  const nonRefundedPulledPhones = _.filter(
    nonRefundedPhones,
    phone => !_.get(phone, ['IsAddedByUser'], false) && _.get(phone, ['ProvidedBy'], []).length
  );
  const emails = _.get(connectInfo, ['Contact', 'Emails'], []);
  const nonRefundedEmails = _.filter(emails, email => !_.get(email, ['IsRefunded'], false));
  const nonRefundedPulledEmails = _.filter(
    nonRefundedEmails,
    email => !_.get(email, ['IsAddedByUser'], false) && _.get(email, ['ProvidedBy'], []).length
  );
  return nonRefundedPulledPhones.length || nonRefundedPulledEmails.length;
}

function getLastRefundTime(connectInfo) {
  const phones = connectInfo?.Contact?.Phones;
  const nonAutoRefundPhones = _.filter(phones, phone => phone.IsRefunded && phone.RefundType !== 'Auto');
  const refundedPhoneTimes = nonAutoRefundPhones.map(phone => phone.RefundedTime);
  const emails = connectInfo?.Contact?.Emails;
  const nonAutoRefundEmails = _.filter(emails, email => email.IsRefunded && email.RefundType !== 'Auto');
  const refundedEmailTimes = nonAutoRefundEmails.map(email => email.RefundedTime);
  const refundedContactTimes = refundedPhoneTimes.concat(refundedEmailTimes);
  const sortedRefundedTimes = _.sortBy(refundedContactTimes, time => new Date(time));
  const lastRefundTime = _.last(sortedRefundedTimes);
  if (lastRefundTime) {
    return getLocaleDateStringWithTimeStamp(lastRefundTime, false);
  }
  return null;
}

function getProviderLinkedEmails(userEmailProviders) {
  const linkedEmailsList = [];
  (userEmailProviders || []).forEach(provider => {
    const currentLinkedEmailsList = (provider.Emails || []).map(email => email.Email.EmailId);
    linkedEmailsList.push(...currentLinkedEmailsList);
  });
  return linkedEmailsList;
}
function getIsLinkedEmailsTestSuccesful({ userEmailProviders }) {
  return userEmailProviders?.find(provider => provider?.Emails?.find(email => email?.IsTestEmailSuccessfull === true));
}

function getSuccessfulTestedEmailList({ userEmailProviders }) {
  const successFulTestedEmailList = [];
  (userEmailProviders || []).forEach(provider => {
    (provider.Emails || []).forEach(email => {
      if (email.IsTestEmailSuccessfull) {
        successFulTestedEmailList.push(email.Email.EmailId);
      }
    });
  });
  return successFulTestedEmailList;
}

const getSortedPhoneArray = array => {
  return array.sort((a, b) => a.Number.localeCompare(b.Number));
};
export const getSortedPhonesByTypes = ({ phones }) => {
  if (!phones?.length) {
    return phones;
  }
  const invalidSpamPhones = [];
  const validPhones = [];
  const userAddedPhones = [];
  for (let i = 0; i < phones.length; i += 1) {
    const phone = phones[i];
    const isEmailInvalid = validateStatus(phone.ValidityStatus);
    const isEmailInvalidOrRefunded = isEmailInvalid || phone.IsRefunded;
    if (isEmailInvalidOrRefunded) {
      invalidSpamPhones.push(phone);
      continue;
    }
    if (phone.IsAddedByUser) userAddedPhones.push(phone);
    else validPhones.push(phone);
  }
  let sortedPhones = [];
  if (userAddedPhones.length) sortedPhones = [...getSortedPhoneArray(userAddedPhones)];
  if (validPhones.length) sortedPhones = [...sortedPhones, ...getSortedPhoneArray(validPhones)];

  if (invalidSpamPhones.length) sortedPhones = [...sortedPhones, ...getSortedPhoneArray(invalidSpamPhones)];
  return sortedPhones;
};

export const getEmptyContactsTabScreenStyle = ({ consentInforBanner, isCandidateViewHeaderVisible }) => {
  if (consentInforBanner && isCandidateViewHeaderVisible)
    return {
      iconStyle: { fontSize: '100px' },
      infoMessageStyle: { fontSize: '20px', lineHeight: '20px' },
      contactInfoStyle: { marginTop: '0px' },
    };
  return {};
};

export const getIsUnsubscribedContactExists = contacts => {
  return contacts?.some(contact => contact.IsUnsubscribeByUser);
};

export {
  validateStatus,
  getIsContactMismatch,
  getManuallyAddedContactsCount,
  getNonRefundedContacts,
  checkIfAnyNonRefundedPulledContactsExists,
  getNonRefundedPhones,
  getNonRefundedEmails,
  getLastRefundTime,
  getProviderLinkedEmails,
  getIsLinkedEmailsTestSuccesful,
  getSuccessfulTestedEmailList,
};
