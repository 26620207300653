import JsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';

const PAGE_HEIGHT = 297;
const BASE_LOGO_URL = 'https://arya-pdf-generation.s3.amazonaws.com/arya-logo.png';
const BASE_PLACEHOLDER_URL = 'https://arya-pdf-generation.s3.amazonaws.com/arya-logo-background.png';

async function generateCanvas(elementId, setLoadingStatus, exclusionClassNames) {
  const element = document.getElementById(elementId);
  if (!element || element.innerHTML === '') {
    return null;
  }
  const filter = node => {
    return !exclusionClassNames.some(classname => node.classList && node.classList.contains(classname));
  };
  try {
    await new Promise(resolve => setTimeout(resolve, 500));
    const canvas = await htmlToImage.toCanvas(element, {
      backgroundColor: '#ffffff',
      filter,
    });
    return { elementId, canvas };
  } catch (error) {
    setLoadingStatus(false);
    throw error;
  }
}

async function generatePdf(title, subTitle, elementIds, setLoadingStatus, exclusionClassNames) {
  await new Promise(resolve => setTimeout(resolve, 500));
  try {
    const pdf = new JsPDF('p', 'mm', 'a4', true);
    let upperEdgeCoordinate = 20;
    let pageHeight = PAGE_HEIGHT;

    pdf.addImage(BASE_PLACEHOLDER_URL, 'JPEG', 0, 0, 210, 0, '', 'FAST');
    pdf.addImage(BASE_LOGO_URL, 'JPEG', 5, 1, 30, 0, '', 'FAST');

    const titleSplitArray = pdf.splitTextToSize(title, 180);
    // eslint-disable-next-line no-unsafe-optional-chaining
    const titleOccupiedSpace = titleSplitArray?.length * 6;
    pdf.setFontSize(15);
    pdf.text(title, 10, upperEdgeCoordinate, {
      maxWidth: 180,
    });
    upperEdgeCoordinate += titleOccupiedSpace;
    pdf.setFontSize(10);
    pdf.text(subTitle, 10, upperEdgeCoordinate);
    upperEdgeCoordinate += 10;
    pageHeight -= upperEdgeCoordinate - 10;

    const canvasObjects = await Promise.all(
      elementIds.map(elementId => generateCanvas(elementId, setLoadingStatus, exclusionClassNames))
    );

    for (const elementId of elementIds) {
      const canvasObject = canvasObjects.find(obj => obj?.elementId === elementId) || {};
      const { canvas } = canvasObject;

      if (canvas?.height) {
        const img = canvas.toDataURL('image/jpeg');
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const componentHeight = (imgHeight * 180) / imgWidth;

        if (pageHeight < componentHeight + 10) {
          pdf.addPage();
          pdf.addImage(BASE_PLACEHOLDER_URL, 'JPEG', 0, 0, 210, 0, '', 'FAST');
          pdf.addImage(BASE_LOGO_URL, 'JPEG', 5, 1, 30, 0, '', 'FAST');
          pageHeight = PAGE_HEIGHT;
          upperEdgeCoordinate = 15;
        }

        pdf.addImage(img, 'JPEG', 10, upperEdgeCoordinate, 180, componentHeight, '', 'FAST');
        upperEdgeCoordinate += componentHeight;
        pageHeight -= componentHeight;
      }
    }

    pdf.save(`${title}.pdf`);
  } catch (error) {
    setLoadingStatus(false);
  }
}

export { generatePdf };
