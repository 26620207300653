import { setApiStatus } from '../ApiStatusActions';

export const SET_FETCH_REPORT_SUMMARY_API_STATUS = 'SET_FETCH_REPORT_SUMMARY_API_STATUS';
export const SET_FETCH_REPORT_STATS_API_STATUS = 'SET_FETCH_REPORT_STATS_API_STATUS';
export const SET_FETCH_REPORT_COLUMNS_API_STATUS = 'SET_FETCH_REPORT_COLUMNS_API_STATUS';
export const SET_FETCH_RECRUITER_API_STATUS = 'SET_FETCH_RECRUITER_API_STATUS';
export const SET_FETCH_CLIENT_LIST_API_STATUS = 'SET_FETCH_CLIENT_LIST_API_STATUS';
export const SET_REPORT_PORTAL_ORGANIZATIONS = 'SET_REPORT_PORTAL_ORGANIZATIONS';
export const SET_FETCH_JOB_LIST_API_STATUS = 'SET_FETCH_JOB_LIST_API_STATUS';
export const SET_EXPORT_REPORT_API_STATUS = 'SET_EXPORT_REPORT_API_STATUS';
export const SET_SAVE_REPORT_QUERY_API_STATUS = 'SET_SAVE_REPORT_QUERY_API_STATUS';
export const SET_FETCH_SAVED_QUERIES_API_STATUS = 'SET_FETCH_SAVED_QUERIES_API_STATUS';
export const SET_SAVED_REPORT_QUERIES = 'SET_SAVED_REPORT_QUERIES';
export const PATCH_UPDATE_SAVED_REPORT_QUERY = 'PATCH_UPDATE_SAVED_REPORT_QUERY';
export const SET_SAVED_QUERIES_PAGE_SIZE = 'SET_SAVED_QUERIES_PAGE_SIZE';
export const SET_REPORT_SUMMARY = 'SET_REPORT_SUMMARY';
export const SET_REPORT_STATS = 'SET_REPORT_STATS';

export const SET_CLIENTS_MAX_COUNT = 'SET_CLIENTS_MAX_COUNT';
export const SET_RECRUITERS_MAX_COUNT = 'SET_RECRUITERS_MAX_COUNT';

export const APPEND_CLIENT_LIST_FOR_DROPDOWNS = 'APPEND_CLIENT_LIST_FOR_DROPDOWNS';
export const SET_CLIENT_LIST_FOR_DROPDOWNS = 'SET_CLIENT_LIST_FOR_DROPDOWNS';

export const APPEND_RECRUITER_LIST_FOR_DROPDOWNS = 'APPEND_RECRUITER_LIST_FOR_DROPDOWNS';
export const SET_RECRUITER_LIST_FOR_DROPDOWNS = 'SET_RECRUITER_LIST_FOR_DROPDOWNS';

export const SET_REPORT_LIST_PAGE_SIZE = 'SET_REPORT_LIST_PAGE_SIZE';

export const SET_JOB_LIST_FOR_DROPDOWNS = 'SET_JOB_LIST_FOR_DROPDOWNS';
export const APPEND_JOB_LIST_FOR_DROPDOWNS = 'APPEND_JOB_LIST_FOR_DROPDOWNS';
export const SET_JOBS_MAX_COUNT = 'SET_JOBS_MAX_COUNT';

export const SET_REPORT_COLUMNS = 'SET_REPORT_COLUMNS';

export const SET_AVAILABLE_ORGANISATION_REPORT = 'SET_AVAILABLE_ORGANISATION_REPORT';
export const SET_AVAILABLE_REPORT_API_STATUS = 'SET_AVAILABLE_REPORT_API_STATUS';
export const SET_ALL_REPORT_LIST_TO_NULL = 'SET_ALL_REPORT_LIST_TO_NULL';
export const RESET_REPORT_SUMMARY_DATAIL = 'RESET_REPORT_SUMMARY_DATAIL';
export const SET_ORG_IN_REPORT_SUMMARY = 'SET_ORG_IN_REPORT_SUMMARY';

export const setFetchReportSummaryApiStatus = status => ({
  type: SET_FETCH_REPORT_SUMMARY_API_STATUS,
  payload: {
    status,
  },
});

export const setAvailableReportsApiStatus = status => ({
  type: SET_AVAILABLE_REPORT_API_STATUS,
  payload: {
    status,
  },
});

export const setFetchReportStatsApiStatus = status => ({
  type: SET_FETCH_REPORT_STATS_API_STATUS,
  payload: {
    status,
  },
});

export const setFetchRecruiterApiStatus = status => ({
  type: SET_FETCH_RECRUITER_API_STATUS,
  payload: {
    status,
  },
});

export const setFetchClientListApiStatus = status => ({
  type: SET_FETCH_CLIENT_LIST_API_STATUS,
  payload: {
    status,
  },
});

export const setFetchJobListApiStatus = status => ({
  type: SET_FETCH_JOB_LIST_API_STATUS,
  payload: {
    status,
  },
});

export const setExportReportApiStatus = status => ({
  type: SET_EXPORT_REPORT_API_STATUS,
  payload: {
    status,
  },
});

export const setReportSummary = reportSummary => ({
  type: SET_REPORT_SUMMARY,
  payload: { reportSummary },
});

export const setReportStats = (reportStats, reportStatsCategory) => ({
  type: SET_REPORT_STATS,
  payload: { reportStats, reportStatsCategory },
});

export const setFetchReportColumnsApiStatus = status => ({
  type: SET_FETCH_REPORT_COLUMNS_API_STATUS,
  payload: {
    status,
  },
});

export const setReportColumns = (reportColumns, reportStatsCategory) => ({
  type: SET_REPORT_COLUMNS,
  payload: { reportColumns, reportStatsCategory },
});

export const setClientsMaxCount = (maxCount, reportStatsCategory) => ({
  type: SET_CLIENTS_MAX_COUNT,
  payload: {
    maxCount,
    reportStatsCategory,
  },
});

export const setClientListForDropdowns = (clients, reportStatsCategory) => ({
  type: SET_CLIENT_LIST_FOR_DROPDOWNS,
  payload: {
    clients,
    reportStatsCategory,
  },
});

export const appendClientListForDropdowns = (clients, reportStatsCategory) => ({
  type: APPEND_CLIENT_LIST_FOR_DROPDOWNS,
  payload: {
    clients,
    reportStatsCategory,
  },
});

export const setReportPortalOrgs = (orgData, searchTerm) => ({
  type: SET_REPORT_PORTAL_ORGANIZATIONS,
  payload: {
    orgData,
    searchTerm,
  },
});

export const setRecruitersMaxCount = (maxCount, reportStatsCategory) => ({
  type: SET_RECRUITERS_MAX_COUNT,
  payload: {
    maxCount,
    reportStatsCategory,
  },
});

export const setRecruiterListForDropdowns = (recruiters, reportStatsCategory) => ({
  type: SET_RECRUITER_LIST_FOR_DROPDOWNS,
  payload: {
    recruiters,
    reportStatsCategory,
  },
});

export const appendRecruiterListForDropdowns = (recruiters, reportStatsCategory) => ({
  type: APPEND_RECRUITER_LIST_FOR_DROPDOWNS,
  payload: {
    recruiters,
    reportStatsCategory,
  },
});

export const setReportListPageSize = pageSize => {
  return {
    type: SET_REPORT_LIST_PAGE_SIZE,
    payload: pageSize,
  };
};

export const setSavedQueriesPageSize = pageSize => {
  return {
    type: SET_SAVED_QUERIES_PAGE_SIZE,
    payload: pageSize,
  };
};

export const setJobsMaxCount = maxCount => ({
  type: SET_JOBS_MAX_COUNT,
  payload: maxCount,
});

export const setJobListForDropdowns = jobs => ({
  type: SET_JOB_LIST_FOR_DROPDOWNS,
  payload: {
    jobs,
  },
});

export const appendJobListForDropdowns = jobs => ({
  type: APPEND_JOB_LIST_FOR_DROPDOWNS,
  payload: {
    jobs,
  },
});

export const setSaveReportQueryApiStatus = status => ({
  type: SET_SAVE_REPORT_QUERY_API_STATUS,
  payload: {
    status,
  },
});

export const setSavedReportQueries = savedQueries => ({
  type: SET_SAVED_REPORT_QUERIES,
  payload: {
    savedQueries,
  },
});

export const setFetchSavedQueriesApiStatus = status => ({
  type: SET_FETCH_SAVED_QUERIES_API_STATUS,
  payload: {
    status,
  },
});

export const patchUpdateSavedReportQuery = ({ reportId, updatedReportQuery }) => ({
  type: PATCH_UPDATE_SAVED_REPORT_QUERY,
  payload: {
    reportId,
    updatedReportQuery,
  },
});

export const setAvailableReportsForOrganization = availableReports => ({
  type: SET_AVAILABLE_ORGANISATION_REPORT,
  payload: { availableReports },
});

export const setDeleteSavedQueryApiStatus = status => setApiStatus({ apiName: 'deleteSavedQueryApiStatus', status });
export const clearAllTheReportList = () => ({
  type: SET_ALL_REPORT_LIST_TO_NULL,
});

export const setReportSummaryData = () => ({
  type: RESET_REPORT_SUMMARY_DATAIL,
});
export const setCurrentOrgInReportSummaryData = value => ({
  type: SET_ORG_IN_REPORT_SUMMARY,
  payload: value,
});
