import { Layout, Menu } from 'antd';
import React from 'react';
import CandidateReportsContainer from '../../../Containers/ReportPortalContainer/CandidateReportsContainer/CandidateReportsContainer';
import JobReportsContainer from '../../../Containers/ReportPortalContainer/JobReportsContainer/JobReportsContainer';
import RecruiterByJobReportsContainer from '../../../Containers/ReportPortalContainer/RecruiterByJobReportsContainer/RecruiterByJobReportsContainer';
import RecruiterReportsContainer from '../../../Containers/ReportPortalContainer/RecruiterReportsContainer/RecruiterReportsContainer';
import SavedReportQueriesContainer from '../../../Containers/ReportPortalContainer/SavedReportQueriesContainer/SavedReportQueriesContainer';
import styles from './ReportStatsTabComponent.module.scss';
import EmptyReportList from './ReportView/ReportDetails/ReportListWrapper/EmptyReportList/EmptyReportList';
import DEIReportContainer from '../../../Containers/ReportPortalContainer/DEIReportContainer/DEIReportContainer';
import { getReportType } from '../../../Utils/ReportsDataUtils';

const { Content, Sider } = Layout;

export default function ReportStatsTabComponent(props) {
  const {
    clearFetchReportStatsApiStatus,
    clearRecruitersForDropdownLists,
    clearClientsForDropdownLists,
    clearReportStats,
    availableReports,
    clearTheReportList,
  } = props;
  const [activeReportType, setActiveReportType] = React.useState('2');
  if (!availableReports.length) return <EmptyReportList title="No reports available" subtitle="" />;

  const onReportMenuClick = async ({ key }) => {
    const reportKey = key?.toLowerCase();
    if (reportKey !== 'saved' && reportKey !== activeReportType) {
      clearTheReportList();
      const reportType = getReportType(reportKey);
      await Promise.all([
        clearReportStats(reportType),
        clearRecruitersForDropdownLists(reportType),
        clearClientsForDropdownLists(reportType),
        clearFetchReportStatsApiStatus(),
      ]);
    }
    setActiveReportType(reportKey?.toLowerCase());
  };

  const getReportsContainer = () => {
    switch (activeReportType) {
      case '2':
        return <RecruiterReportsContainer />;
      case '4':
        return <RecruiterByJobReportsContainer />;
      case '3':
        return <CandidateReportsContainer />;
      case '1':
        return <JobReportsContainer />;
      case '0':
        return <SavedReportQueriesContainer />;
      case '5':
        return <DEIReportContainer />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.reportsTabComponent}>
      <Layout className={styles.reportsTabLayout}>
        <Sider width={276} className={styles.reportsTabSider}>
          <Menu
            mode="inline"
            defaultSelectedKeys={[activeReportType]}
            style={{ height: '100%' }}
            onClick={onReportMenuClick}
            className={styles.reportsTabMenu}
          >
            {availableReports?.map(availableReport => (
              <Menu.Item key={availableReport?.ReportType}>{availableReport?.ReportName}</Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content className={styles.reportsTabContent}>{getReportsContainer()}</Content>
      </Layout>
    </div>
  );
}
