import React from 'react';
import {  FormattedMessage } from 'react-intl';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';
import styles from './CandidateCardPersonalInfo.module.scss';

const CandidateNotInterested = props => {
  const { statusReasons, candidateViewedApiStatus, status } = props;
  return (
    <div>
      {statusReasons &&
        candidateViewedApiStatus === 'COMPLETED' &&
        status?.toLowerCase() === 'rejected' &&
        statusReasons.map(
          ({ Name: name }) =>
            name.toLowerCase() === 'candidate_not_interested' && (
              <div className={styles.candidateNotInterestedContent}><FormattedMessage {...messages.CandidateNotInterested} /></div>
            )
        )}
    </div>
  );
};

export default CandidateNotInterested;
