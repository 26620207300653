import React from 'react';
import classNames from 'classnames';
import { Input } from 'antd';
import './InputSearch.scss';

const { Search } = Input;
export default function InputSearch(props) {
  const {
    onSearch,
    placeholder,
    enterButton,
    className,
    value: inputValue = '',
    onChange,
    tokenSeparators = [','],
    style,
    id = '',
    mustHaveInputRef,
    shouldAddClassname,
    additionalSkillInputClassname,
    missingSkillInputValue,
  } = props;

  const handleSearch = value => {
    if (value.trim()) {
      onSearch(value);
    }
  };

  const handleChange = value => {
    if (tokenSeparators?.find(tokenSeparator => value.indexOf(tokenSeparator) > -1)) {
      handleSearch(value.substring(0, value.length - 1));
    } else {
      onChange(value);
    }
  };

  const inputSearchClassname = `${className} input-search-component ${
    !(missingSkillInputValue || inputValue).trim() ? 'disabled' : null
  }`;
  const updatedInputSearchClassname = shouldAddClassname
    ? classNames(inputSearchClassname, additionalSkillInputClassname)
    : inputSearchClassname;

  return (
    <Search
      style={style}
      ref={mustHaveInputRef}
      className={updatedInputSearchClassname}
      placeholder={placeholder}
      enterButton={enterButton}
      value={missingSkillInputValue || inputValue}
      onSearch={value => handleSearch(value)}
      id={id}
      onChange={event => handleChange(event.target.value)}
    />
  );
}
