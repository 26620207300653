import React from 'react';
import { Checkbox } from 'antd';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import FilterSectionWithCheckbox from './FilterSectionWithCheckbox';
import LinkButton from '../../../Components/Common/LinkButton/LinkButton';
import InfoIconWithTooltip from '../../../Components/Common/InfoIconWithTooltip/InfoIconWithTooltip';

const LastActivityFilter = ({
  sortedLastActivity,
  candidatesLastActivityField,
  selectedLastActivity,
  filterSectionCommonStyles,
  onClickClear,
  form,
}) => {
  return (
    <div className={styles.filterSection}>
      <div className={styles.filterSectionHeader}>
        <span className={styles.subsectionTitle}>
          Resume Last Updated{' '}
          <InfoIconWithTooltip
            tooltipTitle='On Dice this field will be applied for "Last Active on Dice"'
            style={{ zIndex: 3000, maxWidth: '300px' }}
          />
        </span>
        <LinkButton
          buttonName="Clear all"
          onClickButton={() => {
            onClickClear(candidatesLastActivityField);
          }}
          style={{ color: 'rgba(7, 16, 26, 0.5)' }}
        />
      </div>
      {form.getFieldDecorator(candidatesLastActivityField, {
        initialValue: selectedLastActivity,
      })(
        <Checkbox.Group style={{ width: '100%' }}>
          <FilterSectionWithCheckbox
            filterSectionKeys={Object.keys(sortedLastActivity || {})}
            sortedAllTabFilterSection={sortedLastActivity || {}}
            style={{ ...filterSectionCommonStyles, maxHeight: 150 }}
            maxTextLength={35}
          />
        </Checkbox.Group>
      )}
    </div>
  );
};

export default LastActivityFilter;
