import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Input, Button, Modal } from 'antd';
import { emailValidator } from '../../../Utils/Validators';
import styles from './AddEmail.module.scss';
import message from '../../../Containers/CandidateDrawer/messages';

class AddEmail extends React.Component {
  handleSubmit = e => {
    const { form, addEmail, changeState, onChangeMail, emails = [] } = this.props;
    const { validateFields, getFieldValue } = form;
    const currentEmail = getFieldValue('email');
    const trimmedCurrentEmail = currentEmail?.trim()?.toLowerCase();
    const isEmailExist = !!emails.find(email => email.EmailAddress?.toLowerCase() === trimmedCurrentEmail);

    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values.email);
        if (!isEmailExist) addEmail(values.email);
        if (changeState) changeState();
        if (onChangeMail) onChangeMail(values.email);
      }
    });
  };

  validateEmail = (rule, value, callback) => {
    const { form, emails = [] } = this.props;
    const { getFieldValue } = form;
    const currentEmail = getFieldValue('email');
    const trimmedCurrentEmail = currentEmail?.trim()?.toLowerCase();
    const isEmailExist = !!emails.find(email => email.EmailAddress?.toLowerCase() === trimmedCurrentEmail);
    if (isEmailExist) callback(<FormattedMessage {...message.emailAlreadyExistsLabel} />);
    if (!value || !value.trim().length || emailValidator(value)) {
      callback();
    } else {
      callback(<FormattedMessage {...message.pleaseInputValidEmailLabel} />);
    }
  };

  render() {
    const { form, skEventName, isAddEmailFormVisible, setAddEmailFormVisibility,intl } = this.props;
    const { getFieldDecorator } = form;
    const emailPlaceHolder = intl.formatMessage({ ...message.enterEmailAddressLabel });
    return (
      <Modal
        title={<FormattedMessage {...message.addEmailLabel} />}
        centered
        visible={isAddEmailFormVisible}
        onCancel={() => setAddEmailFormVisibility(false)}
        footer={[
          <Button shape="round" key="back" onClick={() => setAddEmailFormVisibility(false)}>
            <FormattedMessage {...message.cancelButton} />
          </Button>,
          <Button shape="round" key="submit" onClick={this.handleSubmit} type="primary" sk-event-name={skEventName}>
            <FormattedMessage {...message.saveButton} />
          </Button>,
        ]}
        zIndex={2223}
      >
        <Form>
          <div className={styles.modalContent}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [{ required: true, validator: this.validateEmail }],
              })(<Input placeholder={emailPlaceHolder} className={styles.emailInput} />)}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}
export { AddEmail as AddEmailWithoutForm };

export default (injectIntl(Form.create({ name: 'coordinated' })(AddEmail)));
