import React from 'react';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import { BaseNode } from './Base';
import TooltipReportData from '../../WorkflowReport/WorkflowReportUtils/TooltipReportData';
import * as WorkflowReportReducer from '../../../Reducers/WorkflowReportReducer';
import * as WorkflowReducer from '../../../Reducers/WorkflowReducer';

const mapStateToProps = (state, props) => {
  const { workflowId } = props;
  const workflowTemplatesById = WorkflowReducer.getWorkflowDripTemplatesById(state, workflowId);
  return {
    workflowTemplatesById,
    getWorkflowReportData: WorkflowReportReducer.getWorkflowReportData(state),
    workflowNodeConnections: WorkflowReducer.getWorkflowNodeConnections(state),
    workflowEndNodeData: WorkflowReportReducer.getWorkflowReportEndNodesData(state),
    isWorkflowPublished: WorkflowReportReducer.getWorkflowPublishedStatus(state),
    workflowReportFetchApiStatus: WorkflowReportReducer.getWorkflowReportApiStatus(state),
  };
};

const TooltipBaseNode = props => {
  const {
    id,
    getWorkflowReportData,
    type,
    workflowNodeConnections,
    isWorkflowPublished,
    workflowTemplatesById,
    workflowEndNodeData,
    workflowReportFetchApiStatus,
    renderTooltipFlag,
  } = props;
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const [tooltipText, setTooltipText] = React.useState('');
  const [targetNodeLabel, setTargetNodeLabel] = React.useState('Done');
  const [sourceNodeId, setSourceNodeId] = React.useState([]);
  const { isRendered } = workflowEndNodeData || false;

  const tooltipChildElement = React.useRef(null);
  const workflowReportData = getWorkflowReportData;
  const { ActivitiesStats: activityStats } = workflowReportData;
  React.useEffect(() => {
    if (Array.isArray(activityStats) && sourceNodeId.length > 0) {
      const tooltipTextNode = TooltipReportData({
        activityStats,
        elementId: id,
        outComeLabel: 'Done',
        elementType: 'node',
        nodeName: type,
        targetNodeLabel,
        sourceNode: sourceNodeId,
        isRendered,
      });
      if (tooltipTextNode && isWorkflowPublished) {
        setTooltipText(tooltipTextNode);
        setTooltipVisible(true);
      }
    }
  }, [workflowReportData, sourceNodeId]);

  React.useEffect(() => {
    if (workflowReportFetchApiStatus) {
      setTooltipVisible(false);
    }
  }, [workflowReportFetchApiStatus]);

  function getSourceNode() {
    const { Activities: activities } = workflowTemplatesById;
    const endNodes = activities.filter(x => x.Type === 'End').map(x => x.ActivityId);
    const sourceNodes = workflowNodeConnections.filter(nodes => nodes.TargetActivityId === id);
    const filterEndNodes = sourceNodes.filter(({ SourceActivityId }) => !endNodes.includes(SourceActivityId));
    if (filterEndNodes.length === 0) {
      return [
        {
          SourceActivityId: 'start node',
        },
      ];
    }
    return filterEndNodes;
  }

  React.useEffect(() => {
    const sourceNodes = getSourceNode();
    const conditionalTargetNode = workflowNodeConnections.find(targetNode => {
      if (targetNode[id]) {
        return targetNode;
      }
      return false;
    });
    if (conditionalTargetNode) setTargetNodeLabel(conditionalTargetNode[id]);
    const sourceNodesIds = sourceNodes.map(sourceNode => sourceNode.SourceActivityId);
    setSourceNodeId(sourceNodesIds);
  }, [workflowNodeConnections]);

  let tooltipPlacement = 'top';
  if (tooltipChildElement.current) {
    const tooltipNode = tooltipChildElement.current.parentNode;
    const nodeWrapper = tooltipNode.querySelector('.NodeInnerWrapper');
    const detailContainer = document.querySelector('.react-flow-container');
    const tooltipHeight = 45;
    const containerHeightFromTop = detailContainer.getBoundingClientRect().top;
    const nodeHeightFromTop = nodeWrapper.getBoundingClientRect().top;
    if (nodeHeightFromTop > 0 && nodeHeightFromTop < containerHeightFromTop + tooltipHeight) tooltipPlacement = 'right';
  }

  return (
    <Tooltip
      placement={tooltipPlacement}
      title={tooltipText}
      overlayClassName={`ActionNodeTooltip-${id} TooltipNode`}
      visible={renderTooltipFlag && tooltipVisible}
      getTooltipContainer={node => node.parentNode}
    >
      <div ref={tooltipChildElement}></div>
      <BaseNode {...props} />
    </Tooltip>
  );
};

export default connect(mapStateToProps, null)(TooltipBaseNode);
export { TooltipBaseNode as TooltipBaseNodeWithoutStore };
