import React from 'react';
import { connect } from 'react-redux';
import ReportPortalComponent from '../../Components/ReportPortal/ReportPortalComponent';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import * as ReportPortalActions from '../../Actions/ReportPortalActions';
import styles from './ReportPortalContainer.module.scss';

const mapStateToProps = state => {
  return {
    featureToggleList: getFeatureToggleList(state),
  };
};
const mapDispatchToProps = {
  getAvailableReportsForOrganization: ReportPortalActions.getAvailableReportsForOrganization,
};

export function ReportPortalContainer(props) {
  const { featureToggleList, getAvailableReportsForOrganization } = props;
  React.useEffect(() => {
    getAvailableReportsForOrganization();
  }, []);
  return (
    <div className={styles.reportingPortalContainer}>
      <ReportPortalComponent featureToggleList={featureToggleList} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPortalContainer);
