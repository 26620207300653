import React, { useEffect } from 'react';
import { Form, DatePicker, Select, Tooltip } from 'antd';
import TimeRangeSelect, { getDateRange } from './TimeRangeSelect/TimeRangeSelect';
import DateFormatSelect from './DateFormatSelect/DateFormatSelect';
import TreeSelectDropdown from '../../../../TreeSelectDropdown/TreeSelectDropdown';
import {
  getFormattedClientOptions,
  getFormattedColumnOptionValues,
  getFormattedRecruiterOptions,
  getOrgsWithCurrentUserOrg,
} from '../../../../../Utils/ReportPortalUtils/OptionUtils';
import {
  getJobListForDropdown,
  allSelectionFilter,
  getDate,
  savedQueryMapper,
  getReportType,
  disabledDate,
  validateDateRange,
} from '../../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import { getLocalDateFormat } from '../../../../../Utils/RelativeTimeCalculator';
import styles from './ReportViewFilter.module.scss';
import { CalendarIcon } from '../../../../../Icons/AryaIcons';
import SelectDropdownWithPagination from '../../../../Common/SelectDropdownWithPagination/SelectDropdownWithPagination';
import { getCurrentUserOrg } from '../../../../../Utils/CurrentUserUtils';
import ColumnFilter from './ColumnFilter/ColumnFilter';
import TextWithInfoIcon from '../../../../Common/TextWithInfoIcon/TextWithInfoIcon';

const { Option } = Select;
const { RangePicker } = DatePicker;

export function ReportViewFilter(props) {
  const {
    form,
    filterConfig,
    onJobSearch,
    onClientSearch,
    clients,
    onRecruiterSearch,
    recruiters,
    isAdmin,
    currentUserId,
    jobs,
    onJobScroll,
    jobsMaxCount,
    clearJobsForDropdownLists,
    savedReportDetails,
    clearRecruitersForDropdownLists,
    clearClientsForDropdownLists,
    orgs,
    orgMaxCount,
    currentUserDetails,
    fetchOrgs,
    canUserImpersonate,
    type,
    reportColumns,
    fetchReportColumnsApiStatus,
    getReportColumns,
    clearTheReportList,
    fetchReportStatsApiStatus,
  } = props;

  const [currentFromDate, setCurrentFromDate] = React.useState(null);
  const [currentToDate, setCurrentToDate] = React.useState(null);
  const savedQueryJSON = savedReportDetails?.ReportQuery || '{}';
  const savedQuery = JSON.parse(savedQueryJSON);
  const mappedSavedQuery = savedQueryMapper(savedQuery, savedReportDetails?.dynamicReportColumns);

  const allJobs = getJobListForDropdown(jobs);
  let jobsCount = 0;

  const formFields = form.getFieldsValue();
  const { recruiters: selectedRecruiters, clients: selectedClients } = formFields;
  const { fromDate, toDate } = getDate(formFields);
  const recruiterIds = allSelectionFilter(selectedRecruiters);
  const clientIds = allSelectionFilter(selectedClients);
  const localDateFormat = getLocalDateFormat();
  const selectedOrgId = form?.getFieldValue('orgId');

  const jobsFilter = {
    from: 0,
    size: 10,
    fromDate,
    toDate,
    clientIds,
    recruiterIds,
    orgId: selectedOrgId,
  };

  const changeDateRange = dateRange => {
    form.setFieldsValue({
      dateRange,
    });
  };

  const handleJobSearch = value => {
    if (onJobSearch) {
      const jobsearchFilter = { ...jobsFilter };
      jobsearchFilter.searchKeyword = value;
      onJobSearch(jobsearchFilter);
    }
  };

  const fetchOrgDropdownOptions = filter => {
    const { searchTerm, from = 0, size = 10 } = filter;
    const pageToFetch = from / size + 1;
    fetchOrgs({ page: pageToFetch, searchTerm });
  };

  const resetRecruitersFormItem = () => {
    form.setFieldsValue({ recruiters: ['ALL'] });
  };

  const resetClientsFormItem = () => {
    form.setFieldsValue({ clients: ['ALL'] });
  };

  const resetColumnsFormItem = () => {
    const columns = getFormattedColumnOptionValues(reportColumns);
    form.setFieldsValue({ columns });
  };

  const handleClientSearch = value => {
    if (onClientSearch) {
      onClientSearch(value);
    }
  };

  const handleRecruiterSearch = value => {
    if (onRecruiterSearch) {
      onRecruiterSearch(value);
    }
  };

  const handleOnJobScroll = e => {
    e.persist();
    if (e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 20 && jobsCount <= jobsMaxCount) {
      jobsCount += 10;

      const jobScrollFilter = { ...jobsFilter };
      jobScrollFilter.from = jobsCount;
      jobScrollFilter.size = 10;
      onJobScroll(jobScrollFilter, 'MORE');
    }
  };

  const handleOnJobFocus = () => {
    form.validateFields().then(() => {
      onJobScroll(jobsFilter);
    });
  };

  const getRecruitersInitialValue = () => {
    if (mappedSavedQuery?.recruiters) return mappedSavedQuery?.recruiters;
    if (isAdmin) {
      return ['ALL'];
    }
    return (currentUserId && [currentUserId]) || [];
  };

  const getClientsInitialValue = () => {
    if (mappedSavedQuery?.clients) return mappedSavedQuery?.clients;
    return ['ALL'];
  };

  const getRecruiterOptionValues = () => {
    return isAdmin
      ? [
          {
            Name: 'All Recruiters',
            Id: 'ALL',
            children: getFormattedRecruiterOptions(recruiters),
          },
        ]
      : getFormattedRecruiterOptions(recruiters);
  };

  useEffect(() => {
    if (currentUserId && !isAdmin) form.setFieldsValue({ recruiters: [currentUserId] });
  }, [currentUserId]);

  React.useEffect(() => {
    if (reportColumns?.length) {
      const columns = getFormattedColumnOptionValues(reportColumns);
      form.setFieldsValue({ columns });
    }
  }, [JSON.stringify(reportColumns)]);

  React.useEffect(() => {
    if (savedReportDetails?.SavedReportId && savedReportDetails?.ReportQuery) {
      form.setFieldsValue(mappedSavedQuery);
    }
  }, [savedReportDetails?.SavedReportId, savedReportDetails?.ReportQuery]);

  const resetJobFormItem = () => {
    if (filterConfig?.job) {
      form.setFieldsValue({ jobIds: [] });
      clearJobsForDropdownLists();
    }
  };

  const onOrgChange = async orgId => {
    const filter = {
      ReportType: getReportType(type),
      OrgId: orgId,
      FromDate: fromDate,
      ToDate: toDate,
    };
    resetJobFormItem();
    resetRecruitersFormItem();
    resetClientsFormItem();
    clearRecruitersForDropdownLists(type);
    clearClientsForDropdownLists(type);
    await getReportColumns(filter, type);
    resetColumnsFormItem();
    clearTheReportList();
  };

  const onDateFormatChange = dateFormat => {
    form.setFieldsValue({ dateFormat });
  };

  const onTimeRangeChange = async dateRange => {
    if (!dateRange || dateRange.length === 0) {
      setCurrentFromDate(null);
      setCurrentToDate(null);
      return;
    }
    const { fromDate: FromDate, toDate: ToDate } = getDate({ dateRange });
    resetJobFormItem();
    const filter = {
      ReportType: getReportType(type),
      OrgId: selectedOrgId,
      FromDate,
      ToDate,
    };
    await getReportColumns(filter, type);
    resetColumnsFormItem();
  };

  const onTimeRangeKeyChange = async key => {
    const dateRangeKey = key?.toLowerCase();
    if (dateRangeKey !== 'custom') {
      const dateRange = getDateRange(dateRangeKey);
      const { fromDate: FromDate, toDate: ToDate } = getDate({ dateRange });
      const filter = {
        ReportType: getReportType(type),
        OrgId: selectedOrgId,
        FromDate,
        ToDate,
      };
      await getReportColumns(filter, type);
      resetJobFormItem();
      resetColumnsFormItem();
    }
  };

  const onCalendarChange = dates => {
    if (dates?.length > 0) {
      setCurrentFromDate(dates[0]);
      if (dates?.length > 1) {
        setCurrentToDate(dates[1]);
      } else {
        setCurrentToDate(null);
      }
    }
  };

  const currentUserOrg = getCurrentUserOrg(currentUserDetails);
  const orgsWithCurrentUserOrg = getOrgsWithCurrentUserOrg(orgs?.Organizations, currentUserOrg);
  const isReportStatsApiInProgress = fetchReportStatsApiStatus?.status === 'INPROGRESS';

  return (
    <div className={styles.reportViewFilter}>
      <Form form={form} layout="vertical" className={isReportStatsApiInProgress ? styles.reportViewFilterDisabled : ''}>
        <div className={styles.reportViewFilterForm}>
          {canUserImpersonate ? (
            <Form.Item label="Select Organization" colon={false} className={styles.formItemRecruiters}>
              {form.getFieldDecorator(`orgId`, {
                initialValue: currentUserDetails?.OrgId,
                rules: [{ required: true, message: 'Please select organization' }],
              })(
                <SelectDropdownWithPagination
                  placeholder="Select organization"
                  showSearch
                  className={styles.jobSelect}
                  fetchDropdownOptions={fetchOrgDropdownOptions}
                  totalDropdownOptionsCount={orgMaxCount}
                  currentDropdownOptionsCount={orgs?.Organizations?.length}
                  onChange={onOrgChange}
                  defaultActiveFirstOption={false}
                  filterOption
                  optionFilterProp="label"
                >
                  {orgsWithCurrentUserOrg?.map(org => (
                    <Option key={org.Id} label={org.Name} value={org.Id}>
                      <Tooltip title={org.Id}>
                        {org.Name} ({org.Id})
                      </Tooltip>
                    </Option>
                  ))}
                </SelectDropdownWithPagination>
              )}
            </Form.Item>
          ) : null}
          <div className={styles.reportViewFilterFormItems}>
            <Form.Item label="Select Recruiters" colon={false} className={styles.formItemRecruiters}>
              {form.getFieldDecorator(`recruiters`, {
                initialValue: getRecruitersInitialValue(),
                rules: [{ required: true, message: 'Please select recruiter(s)' }],
              })(
                <TreeSelectDropdown
                  placeholder="Select recruiters"
                  onSearch={handleRecruiterSearch}
                  className={styles.recruiterSelect}
                  optionValues={getRecruiterOptionValues()}
                  disabled={!isAdmin}
                  onChange={resetJobFormItem}
                />
              )}
            </Form.Item>
            {filterConfig?.client ? (
              <Form.Item label="Select Clients" colon={false} className={styles.formItemClients}>
                {form.getFieldDecorator(`clients`, {
                  initialValue: getClientsInitialValue(),
                  rules: [{ required: true, message: 'Please select client(s)' }],
                })(
                  <TreeSelectDropdown
                    placeholder="Select clients"
                    onSearch={handleClientSearch}
                    className={styles.clientSelect}
                    optionValues={[
                      {
                        Name: 'All Clients',
                        Id: 'ALL',
                        children: getFormattedClientOptions(clients),
                      },
                    ]}
                    onChange={resetJobFormItem}
                  />
                )}
              </Form.Item>
            ) : null}
          </div>
          <div className={styles.reportViewFilterFormItems}>
            <TimeRangeSelect
              form={form}
              initialValue="month"
              fieldDecoratorValue="dateRangeKey"
              label={
                <TextWithInfoIcon
                  text="Select Time Range"
                  tooltipTitle="Please choose a date range with a maximum difference of one year."
                />
              }
              className={styles.formTimeRangeSelect}
              changeDateRange={changeDateRange}
              onChange={onTimeRangeKeyChange}
            />
            <Form.Item colon={false} className={styles.timeRangeDatePicker}>
              <div className="time-range-date-picker">
                {form.getFieldDecorator(`dateRange`, {
                  initialValue: getDateRange('month'),
                  rules: [
                    { required: true, message: 'Please select the date range' },
                    { validator: (rule, value, callback) => validateDateRange(rule, value, callback, form) },
                  ],
                })(
                  <RangePicker
                    allowClear
                    disabled={form.getFieldValue('dateRangeKey')?.toLowerCase() !== 'custom'}
                    disabledDate={current => disabledDate(current, currentFromDate, currentToDate, form)}
                    onCalendarChange={onCalendarChange}
                    format={localDateFormat}
                    className={styles.dateRangePicker}
                    suffixIcon={<CalendarIcon />}
                    onChange={onTimeRangeChange}
                  />
                )}
              </div>
            </Form.Item>
            <Form.Item label="Select Columns" colon={false}>
              {form.getFieldDecorator(`columns`, {
                rules: [{ required: true, message: 'Please select column(s)' }],
                initialValue: ['ALL'],
              })(
                <ColumnFilter
                  columns={reportColumns}
                  fetchReportColumnsApiStatus={fetchReportColumnsApiStatus}
                  isSingleColumnList={filterConfig?.singleColumnList}
                />
              )}
            </Form.Item>
            <DateFormatSelect
              form={form}
              initialValue="MM-DD-YYYY"
              fieldDecoratorValue="dateFormat"
              label={<TextWithInfoIcon text="Date & Time Format" tooltipTitle="Customize the date format on report" />}
              className={styles.formDateFormatSelect}
              dropdownClassName={styles.formDateFormatOption}
              onChange={onDateFormatChange}
            />
          </div>
          {filterConfig?.job ? (
            <Form.Item label="Select Jobs" colon={false} className={styles.formItemJobs}>
              {form.getFieldDecorator(`jobIds`)(
                <Select
                  placeholder="Select jobs"
                  mode="multiple"
                  showSearch
                  filterOption
                  clearIcon
                  onSearch={handleJobSearch}
                  optionFilterProp="label"
                  optionLabelProp="label"
                  className={styles.jobSelect}
                  onPopupScroll={handleOnJobScroll}
                  onFocus={handleOnJobFocus}
                >
                  {allJobs?.map(job => (
                    <Option key={job.value} label={job.label} value={job.value}>
                      {job.label}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          ) : null}
        </div>
      </Form>
    </div>
  );
}

export default ReportViewFilter;
