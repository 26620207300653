import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { getBezierPath, getEdgeCenter, getMarkerEnd } from 'react-flow-renderer';
import ConditionalEdgeTooltip from './ConditionalEdgeTooltip';
import TooltipEdge from './Tooltip';
import EdgeAddButton from '../Buttons/EdgeAddButton/EdgeAddButton';
import './Style.scss';

const Path = props => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    arrowHeadType,
    markerEndId,
    isHighlighted,
  } = props;
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  return (
    <path
      id={id}
      d={edgePath}
      markerEnd={markerEnd}
      className={`react-flow__edge-path ${isHighlighted ? 'highlight' : null}`}
    />
  );
};

const getLabelDisplayName = value => {
  switch (value) {
    case 'Yes':
      return 'If YES';
    case 'No':
      return 'If NO';
    default:
      return null;
  }
};

export const _ConditionEdge = props => {
  const { sourceX, sourceY, targetX, targetY, data, target, source, id } = props;
  const { label, targetType, type, renderTooltipFlag, isHighlighted } = data;

  const isPreview = type === 'preview';
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const [buttonWidth, buttonHeight] = [Math.abs(sourceX - targetX), Math.abs(sourceY - targetY)];
  return (
    <>
      <Path {...props} isHighlighted={isHighlighted} />
      {/* <text>
        <textPath href={`#${id}`} style={{ fontSize: '14px', fontWeight: 500 }} startOffset="20%" textAnchor="middle">
          {label}
        </textPath>
      </text> */}
      <foreignObject
        width={buttonWidth}
        height={buttonHeight}
        style={{ overflow: 'visible' }}
        x={edgeCenterX - buttonWidth / 2}
        y={edgeCenterY - buttonHeight / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <EdgeAddButton
          {...props}
          label={
            isPreview ? (
              <ConditionalEdgeTooltip
                id={id}
                targetType={targetType}
                source={source}
                sourceX={sourceX}
                targetX={targetX}
                target={target}
                label={label}
                renderTooltipFlag={renderTooltipFlag}
              />
            ) : (
              <span
                className={classNames(
                  'condition-edge-text',
                  { rightTop: sourceX > targetX },
                  { leftTop: sourceX < targetX }
                )}
                style={{ fontSize: '12px' }}
              >
                {getLabelDisplayName(label)}
              </span>
            )
          }
          style={{ width: buttonWidth, height: buttonHeight }}
        />
      </foreignObject>
    </>
  );
};

export const _DefaultEdge = props => {
  const [buttonWidth, buttonHeight] = [100, 40];
  const { sourceX, sourceY, targetX, targetY, data } = props;
  const { sourceType, type, renderTooltipFlag } = data;
  const isPreview = type === 'preview';
  const { isAddButtonHidden, isHighlighted } = data;
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const xAxis = isPreview ? edgeCenterX - buttonWidth : edgeCenterX - buttonWidth / 2;
  const EdgeAndButtonComponent = edgeAndButtonProps => {
    if (isPreview)
      return (
        <TooltipEdge
          {...edgeAndButtonProps}
          renderTooltipFlag={renderTooltipFlag}
          style={{ width: buttonWidth, height: buttonHeight }}
        />
      );
    return <EdgeAddButton {...edgeAndButtonProps} style={{ width: buttonWidth, height: buttonHeight }} />;
  };
  const { targetType, targetNode } = data;
  return (
    <>
      <Path {...props} isHighlighted={isHighlighted} />
      {isAddButtonHidden ? null : (
        <foreignObject
          width={buttonWidth}
          height={buttonHeight}
          style={{ overflow: 'visible' }}
          x={xAxis}
          y={edgeCenterY - buttonHeight / 2}
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <div className="edge-button-parent-div">
            <EdgeAndButtonComponent
              {...props}
              targetType={targetType}
              targetNode={targetNode}
              sourceType={sourceType}
            />
          </div>
        </foreignObject>
      )}
    </>
  );
};

const DefaultEdge = withRouter(_DefaultEdge);
const ConditionEdge = withRouter(_ConditionEdge);
export { DefaultEdge, ConditionEdge };
