import * as AODRepository from '../Repository/AoDRepository';
import {
  setRequestQuoteApiStatus,
  setQuoteRequestedByUserApiStatus,
  setIsQuoteRequestedByUser,
} from './ActionCreators/AoDActionCreator';
import { setNotification } from './ActionCreators/ConnectActions';

export function requestQuote(jobId) {
  return async dispatch => {
    dispatch(setRequestQuoteApiStatus('INPROGRESS'));
    try {
      await AODRepository.requestQuote(jobId);
      dispatch(setIsQuoteRequestedByUser(true));
      dispatch(setRequestQuoteApiStatus('COMPLETED'));
      return true;
    } catch (error) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setRequestQuoteApiStatus('FAILED'));
      return false;
    }
  };
}

export function getIsQuoteRequestedByUser() {
  return async dispatch => {
    dispatch(setQuoteRequestedByUserApiStatus('INPROGRESS'));
    try {
      const response = await AODRepository.getIsQuoteRequestedByUser();
      dispatch(setIsQuoteRequestedByUser(response.data.IsRequested));
      dispatch(setQuoteRequestedByUserApiStatus('COMPLETED'));
    } catch (error) {
      dispatch(setQuoteRequestedByUserApiStatus('FAILED'));
    }
  };
}
