import React from 'react';
import moment from 'moment';
import {  FormattedMessage } from 'react-intl';
import { Switch, Pagination, Tag, Tooltip } from 'antd';
import UserAndClientListFilters from '../UserAndClientListFilters/UserAndClientListFilters';
import './ClientList.scss';
import ClientFormContainer from '../../Containers/AddClient/ClientFormContainer';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';


class ClientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      page: 1,
      pageSize: 10,
      searchTerm: '',
      clientId: undefined,
    };
    this.handleFormVisibility = this.handleFormVisibility.bind(this);
    this.openClient = this.openClient.bind(this);
    this.onAddNewClient = this.onAddNewClient.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    const { fetchClientList } = this.props;
    const { page, pageSize } = this.state;
    fetchClientList({
      From: (page - 1) * pageSize,
      Size: pageSize,
      SortBy: 'ModifiedDate',
      SortOrder: 'Descending',
    });
  }

  onAddNewClient() {
    this.setState({
      clientId: undefined,
    });
    this.handleFormVisibility();
  }

  onPageChange(page, pageSize) {
    const { searchTerm } = this.state;
    const { fetchClientList } = this.props;
    this.setState({ page, pageSize });
    fetchClientList({
      From: (page - 1) * pageSize,
      Size: pageSize,
      SearchTerm: searchTerm || undefined,
      SortBy: 'ModifiedDate',
      SortOrder: 'Descending',
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  onSearch(currentSearchTerm) {
    const { searchTerm, pageSize } = this.state;
    const { fetchClientList } = this.props;
    if (searchTerm !== currentSearchTerm) {
      this.setState({ searchTerm: currentSearchTerm, page: 1 });
      fetchClientList({
        From: 0,
        Size: pageSize,
        SearchTerm: currentSearchTerm,
        SortBy: 'ModifiedDate',
        SortOrder: 'Descending',
      });
    }
  }

  onSearchChange(event) {
    const { pageSize, page } = this.state;
    const { fetchClientList } = this.props;
    if (!event.target.value) {
      this.setState({ searchTerm: event.target.value, page: 1 });
      fetchClientList({
        From: (page - 1) * pageSize,
        Size: pageSize,
        SortBy: 'ModifiedDate',
        SortOrder: 'Descending',
      });
    }
  }

  onShowSizeChange(current, size) {
    const { fetchClientList } = this.props;
    const { page, pageSize, searchTerm } = this.state;
    this.setState({ page: current, pageSize: size });
    fetchClientList({
      From: (page - 1) * pageSize,
      Size: size,
      searchTerm,
      SortBy: 'ModifiedDate',
      SortOrder: 'Descending',
    });
  }

  openClient(id) {
    const { fetchClientDetails, toggleAddClientDrawer } = this.props;
    this.setState({
      clientId: id,
    });
    fetchClientDetails(id);
    toggleAddClientDrawer();
    this.handleFormVisibility();
  }

  handleFormVisibility() {
    const { visible } = this.state;
    this.setState({
      visible: !visible,
    });
  }

  onCloseDrawer = () => {
    const { toggleAddClientDrawer } = this.props;
    this.handleFormVisibility();
    toggleAddClientDrawer();
    this.setState({
      clientId: undefined,
    });
  };

  onCloseClientForm = () => {
    this.handleFormVisibility();
    this.setState({
      clientId: undefined,
    });
  };

  render() {
    const { clientId, page, pageSize } = this.state;
    const {
      clientsById,
      clientIds,
      clientTotal,
      changeClientStatus,
      activeClientTotal,
      toggleAddClientDrawer,
      visible,
    } = this.props;
    const clients = clientIds.map(id => clientsById[id]);
    const ClientTotal =clientTotal || 0;
    const ActiveClientTotal= activeClientTotal || 0;
    return (
      <div className="client-list">
        <div className="client-list-header">
          <div className="client-list-title">
          <FormattedMessage {...message.clientAccountsLabel} /> <Tag className="clients-count"><FormattedMessage {...message.totalClientsLabel} values={{ClientTotal}}/></Tag>{' '}
            <span className="active-clients-count"><FormattedMessage {...message.activeClientsLabel} values={{ActiveClientTotal}}/></span>
          </div>
          <UserAndClientListFilters
            onAddNew={this.onAddNewClient}
            onSearchChange={this.onSearchChange}
            onSearch={this.onSearch}
            toggleAddClientDrawer={toggleAddClientDrawer}
          />
        </div>
        <div>
          <div className="client-list-column-headers">
            <div className="client-column-header"><FormattedMessage {...message.clientLabel} /></div>
            <div className="contact-column-header"><FormattedMessage {...message.contactLabel} /></div>
            <div className="updated-column-header"><FormattedMessage {...message.updatedOnLabel} /></div>
            <div className="source-count-column-header"><FormattedMessage {...message.sourceCountLabel} /></div>
            <div className="status-column-header"><FormattedMessage {...message.StatusLabel} /></div>
          </div>
          {clients.map(client => (
            <div className="client-list-row" key={client.Id}>
              <div className="client-column-item" role="presentation" onClick={() => this.openClient(client.Id)}>
                <div className="client-name">
                  <Tooltip title={client.Name}>{client.Name}</Tooltip>
                </div>
                <div className="client-id">{client.Id}</div>
              </div>
              <div className="contact-column-item">
                <div className="client-email">{client.Email}</div>
                <div className="contact-number">{client.Phone}</div>
              </div>
              <div className="updated-column-item">
                <Tooltip
                  title={moment
                    .utc(client.ModifiedDate)
                    .local()
                    .format('DD/MM/YYYY hh:mm:ss A')}
                >
                  <div className="updated-time">
                    {moment
                      .utc(client.ModifiedDate)
                      .local()
                      .fromNow()}
                  </div>
                </Tooltip>
              </div>
              <div className="source-count-column-item">
                <div className="count">{client.SourceCount}</div>
              </div>
              <div className="status-column-item">
                <span className="status-text">{client.IsActive ? <FormattedMessage {...message.activeLabel} /> : <FormattedMessage {...message.inActiveLabel} />} </span>
                <Switch
                  checkedChildren={<FormattedMessage {...message.onLabel} />}
                  unCheckedChildren={<FormattedMessage {...message.offLabel} />}
                  checked={client.IsActive}
                  loading={client.changeClientStatusApiStatus === 'IN_PROGRESS'}
                  onChange={checked =>
                    changeClientStatus(client.Id, [{ op: 'replace', path: 'IsActive', value: checked }])
                  }
                />
              </div>
            </div>
          ))}
        </div>

        <ClientFormContainer
          clientId={clientId}
          visible={visible}
          onCloseClientForm={this.onCloseClientForm}
          onCloseDrawer={this.onCloseDrawer}
        />

        <div className="client-page-pagination">
          <Pagination
            current={page}
            pageSize={pageSize}
            showSizeChanger
            size="small"
            total={clientTotal || 0}
            onChange={this.onPageChange}
            onShowSizeChange={this.onShowSizeChange}
            hideOnSinglePage={clientTotal <= 10}
          />
        </div>
      </div>
    );
  }
}

export default ClientList;
