import React from 'react';
import moment from 'moment';
import { Badge } from 'antd';
import { TextIcon } from '../../../Icons/AryaIcons';
import styles from './JobLevelSourcingAssistantChatNotification.module.scss';

function getIcon() {
  return (
    <TextIcon
      style={{ fontSize: 20, padding: 10, color: '#13C26B', backgroundColor: '#DCF6E9', borderRadius: '50%' }}
    />
  );
}

function JobLevelSourcingAssistantChatNotification(props) {
  const { notification, onClick } = props;
  const onNotificationClick = () => onClick(notification);
  const { ChatMessage, JobTitle, JobId } = notification.Payload;
  const message = ChatMessage?.Body?.Content;
  const messagePreview = message.slice(0, 50);

  return (
    <div className="notification" role="presentation" onClick={onNotificationClick}>
      <div className="icon">{getIcon()}</div>
      <div className="content">
        <div className={styles.senderName}>{ChatMessage?.From?.User?.DisplayName}</div>
        <div>
          {JobTitle}, {JobId}
        </div>
        <div>
          {messagePreview}
          {message.length > 50 ? '...' : ''}
        </div>
        <div className={styles.since}>{moment.utc(ChatMessage.CreatedTime).local().fromNow()}</div>
      </div>
      <div className="is-viewed">
        <Badge dot={notification.UnreadCount > 0} />
      </div>
    </div>
  );
}

export default JobLevelSourcingAssistantChatNotification;
