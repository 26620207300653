import React, { useEffect, useState } from 'react';
import { Icon, Dropdown, Menu, Form, Skeleton } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import uuid from 'uuid';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getManualSearchFormValues, getManualSearchCriteria } from '../../Reducers/ManualSearchReducer';
import { fetchSuggestions } from '../../Actions/SuggestionsActions';
import InputSearch from '../InputSearch/InputSearch';
import BooleanDisplay from '../BooleanDisplay/BooleanDisplay';
import './SkillMissingContent.scss';
import NotificationMessageIcon from '../../Icons/NotificationMessageIcon';
import { changeCursorPosition } from '../../Utils/ManualSearchUtils';
import {
  getFormattedMissingSkills,
  filterUniqueSkills,
  updatedCandidateMissingSkill,
  missingSkillsAfterSelectOrDeSelect,
  checkIfDuplicateIsPresent,
} from '../../Utils/MissingSkillsUtils';
import { generateSkillsObject, getMergedRejectReasonValues } from '../../Utils/CandidateRejectReasonsUtils';
import { generateArrayFromBooleanString, sanitizeKeyword } from '../../Utils/BooleanStringUtility';
import message from '../Placeholders/PlaceholdersMessages';

const { Item } = Form;

const defaultMissingSkills = {};
 function SkillMissingContent(props) {
  const {
    value: currentMissingSkills = defaultMissingSkills,
    jobId,
    candidateId,
    onChange,
    rejectReasonKey,
    form,
    suggestions,
    suggestionsFetchApiStatus,
    openInNewTab,
    featureToggleList,
    intl,
  } = props;
  const dispatch = useDispatch();
  const [defaultMustHaves, setDefaultMustHaves] = useState({});

  const isAdvancedSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
  const unsavedCriteria = useSelector(state =>
    getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' })
  );

  const combinedMustHavesAndCurrentMissingSkills = filterUniqueSkills({ ...currentMissingSkills, ...defaultMustHaves });

  useEffect(() => {
    const mustHaves = unsavedCriteria.Skills?.Musts;
    if (isAdvancedSearchV2Enabled && mustHaves) {
      const mustHavesArray = generateArrayFromBooleanString(mustHaves);
      const formattedSkillsArray = [];
      mustHavesArray?.forEach(skillArray => {
        const formattedSkillArray = skillArray.map(skill => sanitizeKeyword(skill));
        formattedSkillsArray.push(formattedSkillArray);
      });
      const newMustHaves = generateSkillsObject(formattedSkillsArray);
      setDefaultMustHaves(newMustHaves);
    }
  }, []);

  const [inputVisibilityKey, setInputVisibilityKey] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [missingSkillInputValue, setMissingSkillInputValue] = React.useState('');
  const [isDoubleQuotesAllowed, setIsDoubleQuotesAllowed] = React.useState(true);
  const missingSkillInputRef = React.useRef(null);

  useEffect(() => {
    const updatedMissingSkills = updatedCandidateMissingSkill(currentMissingSkills);
    const clonedCurrentMissingSkills = _.cloneDeep(updatedMissingSkills);
    const mergedSkills = { ...clonedCurrentMissingSkills, ...suggestions };
    onChange(mergedSkills);
  }, [suggestions]);

  useEffect(() => {
    return () => {
      setMissingSkillInputValue('');
    };
  }, []);

  React.useEffect(() => {
    const missingSkillInput = missingSkillInputRef.current?.input?.input;
    changeCursorPosition(missingSkillInput, missingSkillInputValue, isDoubleQuotesAllowed);
  }, [missingSkillInputValue, isDoubleQuotesAllowed]);

  const onMissingSkillsChange = value => {
    const formattedMissingSkill = getFormattedMissingSkills(
      value,
      missingSkillInputValue,
      isDoubleQuotesAllowed,
      setIsDoubleQuotesAllowed
    );
    setMissingSkillInputValue(formattedMissingSkill);
  };
  const addMissingSkills = currentSkills => {
    const skills = {};
    currentSkills.split(',').forEach(currentSkill => {
      const currentIndex = uuid.v4();
      skills[currentIndex] = currentSkill;
    });

    dispatch(
      fetchSuggestions({
        jobId,
        candidateId,
        skills,
        suggestionsType: rejectReasonKey,
      })
    );
    onChange(getMergedRejectReasonValues(rejectReasonKey, currentMissingSkills, skills));

    form.setFieldsValue({ [`${rejectReasonKey}-Input`]: '' });
    setMissingSkillInputValue('');
  };

  const updateCurrentSkills = ({
    clonedCurrentMissingSkills,
    currentIndex,
    updatedCurrentKeySelectedSkills,
    updatedCurrentKeySuggestedSkills,
  }) => {
    _.setWith(clonedCurrentMissingSkills, [currentIndex, 'selectedSkills'], updatedCurrentKeySelectedSkills, Object);
    _.setWith(clonedCurrentMissingSkills, [currentIndex, 'suggestedSkills'], updatedCurrentKeySuggestedSkills, Object);
    onChange(clonedCurrentMissingSkills);
  };

  const deSelectSkill = (reason, currentIndex, shouldHighlight) => {
    const updatedMissingSkills = updatedCandidateMissingSkill(currentMissingSkills);
    let clonedCurrentMissingSkills;
    if (shouldHighlight) {
      clonedCurrentMissingSkills = updatedMissingSkills[currentIndex]
        ? _.cloneDeep(updatedMissingSkills)
        : missingSkillsAfterSelectOrDeSelect(currentIndex, defaultMustHaves, updatedMissingSkills, openInNewTab);
    } else {
      clonedCurrentMissingSkills = _.cloneDeep(
        missingSkillsAfterSelectOrDeSelect(currentIndex, defaultMustHaves, updatedMissingSkills, openInNewTab)
      );
    }

    const currentIndexMissingSkills = clonedCurrentMissingSkills[currentIndex];
    const updatedCurrentKeySelectedSkills = currentIndexMissingSkills?.selectedSkills.filter(
      currentSkill => currentSkill !== reason
    );
    const updatedCurrentKeySuggestedSkills = (currentIndexMissingSkills?.suggestedSkills ?? []).concat(reason);
    updateCurrentSkills({
      clonedCurrentMissingSkills,
      currentIndex,
      updatedCurrentKeySelectedSkills,
      updatedCurrentKeySuggestedSkills,
    });
  };

  const selectSkill = (reason, currentIndex, shouldHighlight) => {
    const updatedMissingSkills = updatedCandidateMissingSkill(currentMissingSkills);
    let clonedCurrentMissingSkills;
    const isDuplicatePresent = checkIfDuplicateIsPresent(reason, defaultMustHaves);

    if (!isDuplicatePresent) {
      if (shouldHighlight) {
        clonedCurrentMissingSkills = updatedMissingSkills[currentIndex]
          ? _.cloneDeep(updatedMissingSkills)
          : missingSkillsAfterSelectOrDeSelect(currentIndex, defaultMustHaves, updatedMissingSkills, openInNewTab);
      } else {
        clonedCurrentMissingSkills = _.cloneDeep(
          missingSkillsAfterSelectOrDeSelect(currentIndex, defaultMustHaves, updatedMissingSkills, openInNewTab)
        );
      }

      const currentIndexMissingSkills = clonedCurrentMissingSkills[currentIndex];
      if (
        !currentIndexMissingSkills?.selectedSkills?.find(
          selectedSkill => selectedSkill.toLowerCase() === reason.toLowerCase()
        )
      ) {
        const updatedCurrentKeySelectedSkills = currentIndexMissingSkills?.selectedSkills.concat(reason);
        const updatedCurrentKeySuggestedSkills = currentIndexMissingSkills?.suggestedSkills?.filter(
          currentSkill => currentSkill !== reason
        );

        updateCurrentSkills({
          clonedCurrentMissingSkills,
          currentIndex,
          updatedCurrentKeySelectedSkills,
          updatedCurrentKeySuggestedSkills,
        });
      }
    }
  };
  const suggestedSkillsDropdown = (skills, currentIndex) => {
    const { allSkills, suggestedSkills, selectedSkills } = skills;
    const filteredSkills = allSkills?.filter(
      skill =>
        !(suggestedSkills?.includes(skill) || selectedSkills?.includes(skill)) &&
        skill?.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (!filteredSkills?.length) return <div />;
    return (
      <Menu className="reject-reasons-skill-suggestions-menu">
        {filteredSkills.map(filteredSkill => (
          <Menu.Item
            key={filteredSkill}
            onClick={() => {
              selectSkill(filteredSkill, currentIndex);
            }}
          >
            {filteredSkill}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const updatedMissingSkills = updatedCandidateMissingSkill(currentMissingSkills);
  const iscombinedMustHavesAndCurrentMissingSkillsEmpty = Object.keys(defaultMustHaves).length === 0;
  return (
    <div className="skill-missing-content-wrapper">
      <div className="skill-missing-title-input-wrapper">
        <div className="skill-missing-content-title"><FormattedMessage {...message.addMissingMustHaveSkillsLabels}/></div>
        <Item>
          {form.getFieldDecorator(`${rejectReasonKey}-Input`)(
            <InputSearch
              value={missingSkillInputValue}
              className="add-missing-skill-input"
              placeholder={intl.formatMessage({ ...message.addSkillsXML })}
              enterButton={<Icon type="plus" />}
              onSearch={addMissingSkills}
              onChange={onMissingSkillsChange}
              mustHaveInputRef={missingSkillInputRef}
              missingSkillInputValue={missingSkillInputValue}
            />
          )}
        </Item>
        {!iscombinedMustHavesAndCurrentMissingSkillsEmpty && isAdvancedSearchV2Enabled ? (
          <div className="existing-mandatory-skills-section"><FormattedMessage {...message.existingMustHaveSkillsLabel}/></div>
        ) : null}
      </div>
      <div className="selected-skills-wrapper">
        {Object.keys(combinedMustHavesAndCurrentMissingSkills).map(skillKey => {
          let shouldHighlight = false;
          const combinedSelectedSkills = combinedMustHavesAndCurrentMissingSkills[skillKey]?.selectedSkills
            ?.join('-')
            .toLowerCase();
          if (updatedMissingSkills) {
            const currentSelectedSkills = updatedMissingSkills[skillKey]?.selectedSkills?.join('-').toLowerCase();
            if (currentSelectedSkills === combinedSelectedSkills) {
              shouldHighlight = true;
            }
          }
          return (
            <Dropdown
              overlay={suggestedSkillsDropdown(combinedMustHavesAndCurrentMissingSkills[skillKey], skillKey)}
              visible={skillKey === inputVisibilityKey}
              overlayClassName="reject-reasons-skill-suggestions-dropdown"
              key={skillKey}
            >
              <BooleanDisplay
                key={skillKey}
                selectedTags={combinedMustHavesAndCurrentMissingSkills[skillKey].selectedSkills}
                suggestedTags={combinedMustHavesAndCurrentMissingSkills[skillKey]?.suggestedSkills}
                currentIndex={skillKey}
                deSelectSkill={deSelectSkill}
                selectSkill={selectSkill}
                selectedTagClassName="boolean-display-selected-tag"
                suggestedTagClassName="boolean-display-suggested-tag"
                inputVisibility={inputVisibilityKey === skillKey}
                setInputVisibilityKey={setInputVisibilityKey}
                inputValue={inputValue}
                setInputValue={setInputValue}
                currentMissingSkills={currentMissingSkills}
                shouldHighlight={shouldHighlight}
              />
            </Dropdown>
          );
        })}
        {suggestionsFetchApiStatus === 'INPROGRESS' ? <Skeleton active paragraph={{ rows: 1 }} /> : null}
      </div>
      {!iscombinedMustHavesAndCurrentMissingSkillsEmpty && isAdvancedSearchV2Enabled ? (
        <div className="mandatory-skill-notification">
          <NotificationMessageIcon />
          <span className="notification-message">Must Have Skill added, click &apos;Search&apos; for new results.</span>
        </div>
      ) : null}
    </div>
  );
}

export default (injectIntl(Form.create()(SkillMissingContent)));
export {SkillMissingContent as SkillMissingContentWithoutInjectIntl};
