import React from 'react';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Switch, Button, Select, InputNumber } from 'antd';
import { isUnsupportedOperation } from '../../Utils/CreateClientIdApiErrorResponseMapper';
import { emailValidator } from '../../Utils/Validators';
import { validatePhone, validateEmail } from '../../Utils/FormValidators';
import validateUrl from '../../Utils/UrlValidator';
import InfoIconWithTooltip from '../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import './ClientForm.scss';
import styles from './ClientForm.module.scss';
import LinkButton from '../Common/LinkButton/LinkButton';
import jobMessage from '../JobForm/JobMessages';
import placeholder from '../Placeholders/PlaceholdersMessages';

const { Option } = Select;

export function validateClientName(rule, value, callback) {
  if (!value) {
    callback('Please enter the Client Name');
  } else if (value.trim().length) {
    if (value.trim().length > 500) {
      callback('Client Name must be less than 500 chars');
    } else {
      callback();
    }
  } else {
    callback('Enter valid Client Name');
  }
}

function validateClientUrl(rule, value, callback) {
  if (!value) {
    callback();
  } else if (validateUrl(value)) {
    callback();
  } else {
    callback('Input valid url');
  }
}

function getHttpParsedUrl(url) {
  if (url && url.slice(0, 7) === 'http://') {
    return { httpType: 'http://', url: url.slice(7, url.length) };
  }
  if (url && url.slice(0, 8) === 'https://') {
    return { httpType: 'https://', url: url.slice(8, url.length) };
  }
  return { httpType: 'https://', url };
}

class ClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryEmailVisible: false,
      isSecondaryContactVisible: false,
      validateSecondaryPhoneStatus: '',
      validateSecondaryEmailStatus: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.validateClientId = this.validateClientId.bind(this);
    this.clientFormRef = React.createRef();
    this.onClientIdChange = this.onClientIdChange.bind(this);
  }

  onClientIdChange() {
    const { setClientFormApiStatus } = this.props;
    setClientFormApiStatus(null);
  }

  validateClientId(rule, value, callback) {
    const { createClientApiStatus } = this.props;
    const showUnsupportedOperationMessage = isUnsupportedOperation(createClientApiStatus?.error);
    const regex = /^[\d]*$/;

    if (showUnsupportedOperationMessage && createClientApiStatus?.error) {
      callback('Client ATS id already exists, Please leave blank or provide valid id');
    } else if (regex.test(value) || !value) {
      callback();
    } else {
      callback('Please input valid Client Id');
    }
  }

  validatePhone = (rule, value, callback) => {
    const { form } = this.props;
    validatePhone(rule, value, callback);
    form.validateFields(['WorkPhone'], { force: true });
  };

  validateEmail = (rule, value, callback) => {
    const { form } = this.props;
    validateEmail(rule, value, callback);
    form.validateFields(['PrimaryContact'], { force: true });
  };

  validateSecondaryPhone = (rule, value, callback) => {
    const { form } = this.props;
    const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    if (form.getFieldValue('Phone') === form.getFieldValue('WorkPhone') && value) {
      this.setState({
        validateSecondaryPhoneStatus: 'warning',
      });
      callback('Secondary contact number is same as primary');
    } else if (!value || !value.trim().length || regex.test(value)) {
      this.setState({
        validateSecondaryPhoneStatus: '',
      });
      callback();
    } else {
      this.setState({
        validateSecondaryPhoneStatus: 'error',
      });
      callback('Invalid number. Please start with country code.');
    }
  };

  validateSecondaryEmail = (rule, value, callback) => {
    const { form } = this.props;

    if (form.getFieldValue('Email') === form.getFieldValue('PrimaryContact') && value) {
      this.setState({
        validateSecondaryEmailStatus: 'warning',
      });
      callback('Secondary email is same as primary');
    } else if (!value || !value.trim().length || emailValidator(value)) {
      this.setState({
        validateSecondaryEmailStatus: '',
      });
      callback();
    } else {
      this.setState({
        validateSecondaryEmailStatus: 'error',
      });
      callback('Please input valid Email');
    }
  };

  componentDidMount() {
    const { fetchIndustries, utilities } = this.props;
    const industries = _.get(utilities, ['industries'], []);
    if (!industries.length)
      fetchIndustries({
        SearchTerm: '',
        Size: 200,
      });
  }

  componentDidUpdate(prevProps) {
    const { createClientApiStatus: prevCreateClientIdApiStatus } = prevProps;
    const { createClientApiStatus: currentCreateClientIdApiStatus, onClose, toggleAddClientDrawer, form } = this.props;

    if (
      prevCreateClientIdApiStatus?.status !== currentCreateClientIdApiStatus?.status &&
      currentCreateClientIdApiStatus?.status === 'COMPLETED'
    ) {
      onClose(form);
      toggleAddClientDrawer();
    }
    if (prevCreateClientIdApiStatus?.status === 'INPROGRESS' && currentCreateClientIdApiStatus?.status === 'FAILED') {
      form.validateFields(['VaultClientId'], { force: true });
    }
  }

  handleSubmit() {
    const { onSubmit, form, clientDetails } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const newValues = values;
        if (newValues.Url) {
          newValues.Url = newValues.HttpType + newValues.Url;
        } else {
          newValues.Url = null;
        }
        newValues.HttpType = undefined;
        if (!clientDetails.Id) {
          onSubmit(values);
        } else {
          const patchClientUpdate = [];
          Object.keys(values).forEach(key => {
            if (key === 'Industries' || key === 'CompetitorCompanies') {
              if (
                !_.isEqual(
                  clientDetails[key].map(value => value.Name),
                  values[key]
                )
              ) {
                patchClientUpdate.push({
                  path: key,
                  op: 'replace',
                  value: values[key].map(value => ({
                    Name: value,
                  })),
                });
              }
            } else if (key === 'SubsidiaryCompanies') {
              if (!_.isEqual(clientDetails[key], values[key])) {
                patchClientUpdate.push({
                  path: key,
                  op: 'replace',
                  value: values[key],
                });
              }
            } else if (values[key] !== clientDetails[key]) {
              patchClientUpdate.push({
                path: key,
                op: 'replace',
                value: values[key],
              });
            }
          });
          if (patchClientUpdate.length) {
            onSubmit(clientDetails.Id, patchClientUpdate);
          }
        }
      }
    });
  }

  handleUrlChange(e) {
    const { form } = this.props;
    const url = e.target.value;
    if (url) {
      if (url.slice(0, 7) === 'http://') {
        form.setFieldsValue({ HttpType: 'http://' });
        return url.slice(7, url.length);
      }
      if (url.slice(0, 8) === 'https://') {
        form.setFieldsValue({ HttpType: 'https://' });
        return url.slice(8, url.length);
      }
    }
    return url;
  }

  toggleSecondaryEmailVisibilty = () => {
    this.setState({
      isSecondaryEmailVisible: true,
    });
  };

  toggleSecondaryContactVisibilty = () => {
    this.setState({
      isSecondaryContactVisible: true,
    });
  };

  render() {
    let industryMenuList = [];
    const {
      form,
      onClose,
      clientDetails,
      toggleAddClientDrawer,
      utilities,
      createClientApiStatus,
      intl,
      featureToggleList = {},
    } = this.props;
    const {
      isSecondaryEmailVisible,
      isSecondaryContactVisible,
      validateSecondaryPhoneStatus,
      validateSecondaryEmailStatus,
    } = this.state;

    const { IsEnabled: isAdvancedSearchV2Enabled } = featureToggleList.AdvanceSearchV2 || {};
    const sourcingParametersLabel = isAdvancedSearchV2Enabled ? (
      <FormattedMessage {...jobMessage.scoringAndRankingParameterLabel} />
    ) : (
      <FormattedMessage {...jobMessage.sourcingParametersLabel} />
    );

    const parsedUrl = getHttpParsedUrl(clientDetails.Url);
    const selectBefore = form.getFieldDecorator('HttpType', { initialValue: parsedUrl.httpType })(
      <Select className="http-select" dropdownClassName="drawer-select-dropdown">
        <Select.Option value="http://">
          <FormattedMessage {...jobMessage.httpUrlLabel} />
        </Select.Option>
        <Select.Option value="https://">
          <FormattedMessage {...jobMessage.httpsUrlLabel} />
        </Select.Option>
      </Select>
    );
    if (utilities.industries) {
      industryMenuList = utilities.industries.map(industryOption => (
        <Option value={industryOption} key={industryOption}>
          {industryOption}
        </Option>
      ));
    }

    const industrySelect = (
      <Select
        mode="multiple"
        key="Industry"
        placeholder={intl.formatMessage({ ...placeholder.addIndustryLabel })}
        style={{ width: '375px' }}
        getPopupContainer={() => this.clientFormRef.current}
      >
        {industryMenuList}
      </Select>
    );
    return (
      <div>
        <div className="client-form-body" ref={this.clientFormRef}>
          <Form key={clientDetails.Id}>
            <div className="client-name-form">
              <Form.Item>
                {form.getFieldDecorator('Name', {
                  initialValue: clientDetails.Name,
                  rules: [{ validator: validateClientName }],
                })(
                  <Input
                    placeholder={intl.formatMessage({ ...placeholder.clientNameLabel })}
                    className="client-name-input"
                  />
                )}
              </Form.Item>
              <div>
                <span className="client-status-label">
                  <FormattedMessage {...jobMessage.activityStatusLabel} />
                </span>
                {form.getFieldDecorator('IsActive', {
                  initialValue: clientDetails.IsActive ? clientDetails.IsActive : clientDetails.IsActive === undefined,
                  valuePropName: 'checked',
                })(
                  <Switch
                    checkedChildren={<FormattedMessage {...jobMessage.switchOnLabel} />}
                    unCheckedChildren={<FormattedMessage {...jobMessage.switchOffLabel} />}
                  />
                )}
              </div>
            </div>
            <div className={styles.ClientFormContent}>
              <div className="clientid ">
                <Form.Item
                  label={<FormattedMessage {...jobMessage.atsClientIdLabel} />}
                  className="source-count-item"
                  colon={false}
                >
                  {form.getFieldDecorator('VaultClientId', {
                    initialValue: clientDetails.VaultClientId,
                    rules: [{ validator: this.validateClientId }],
                  })(
                    <InputNumber
                      placeholder={intl.formatMessage({ ...placeholder.enterClientIdLabel })}
                      className="ats-id-input"
                      onChange={this.onClientIdChange}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="emails">
                <Form.Item
                  label={<FormattedMessage {...jobMessage.emailLabel} />}
                  colon={false}
                  style={{ height: '58.5px' }}
                >
                  {form.getFieldDecorator('Email', {
                    initialValue: clientDetails.Email,
                    rules: [{ validator: this.validateEmail }],
                  })(
                    <Input
                      placeholder={intl.formatMessage({ ...placeholder.enterEmailLabel })}
                      className="company-email"
                    />
                  )}
                </Form.Item>
                <div className="plus-circle">
                  {isSecondaryEmailVisible || clientDetails.PrimaryContact ? (
                    <Form.Item
                      label={<FormattedMessage {...jobMessage.secondaryEmailLabel} />}
                      validateStatus={validateSecondaryEmailStatus}
                      colon={false}
                      style={{ height: '58.5px' }}
                    >
                      {form.getFieldDecorator('PrimaryContact', {
                        initialValue: clientDetails.PrimaryContact,
                        rules: [{ validator: this.validateSecondaryEmail }],
                      })(
                        <Input
                          placeholder={intl.formatMessage({ ...placeholder.enterEmailLabel })}
                          className="company-email"
                        />
                      )}
                    </Form.Item>
                  ) : (
                    <LinkButton
                      buttonName={<FormattedMessage {...jobMessage.secondaryEmailLabel} />}
                      onClickButton={this.toggleSecondaryEmailVisibilty}
                    />
                  )}
                </div>
              </div>
              <div className="contact-numbers">
                <Form.Item
                  label={<FormattedMessage {...jobMessage.phone} />}
                  colon={false}
                  style={{ height: '58.5px' }}
                >
                  {form.getFieldDecorator('Phone', {
                    initialValue: clientDetails.Phone,
                    rules: [{ validator: this.validatePhone }],
                  })(
                    <Input
                      placeholder={intl.formatMessage({ ...placeholder.enterPhoneNumberLabel })}
                      className="phone-number-input"
                    />
                  )}
                </Form.Item>

                <div className="plus-circle">
                  {isSecondaryContactVisible || clientDetails.WorkPhone ? (
                    <Form.Item
                      label={<FormattedMessage {...jobMessage.sceondaryPhoneNumberLabel} />}
                      validateStatus={validateSecondaryPhoneStatus}
                      colon={false}
                      style={{ height: '58.5px' }}
                    >
                      {form.getFieldDecorator('WorkPhone', {
                        initialValue: clientDetails.WorkPhone,
                        rules: [{ validator: this.validateSecondaryPhone }],
                      })(
                        <Input
                          placeholder={intl.formatMessage({ ...placeholder.enterPhoneNumberLabel })}
                          className="phone-number-input"
                        />
                      )}
                    </Form.Item>
                  ) : (
                    <LinkButton
                      buttonName={<FormattedMessage {...jobMessage.addSecondaryPhoneNumberLabel} />}
                      onClickButton={this.toggleSecondaryContactVisibilty}
                    />
                  )}
                </div>
              </div>
              <Form.Item label={<FormattedMessage {...jobMessage.clientURLLabel} />} colon={false}>
                {form.getFieldDecorator('Url', {
                  initialValue: parsedUrl.url,
                  getValueFromEvent: this.handleUrlChange,
                  rules: [{ validator: validateClientUrl }],
                })(
                  <Input
                    placeholder={intl.formatMessage({ ...placeholder.sample_comLabel })}
                    className="company-url"
                    addonBefore={selectBefore}
                    onChange={this.handleUrlChange}
                  />
                )}
              </Form.Item>
              <Form.Item label={<FormattedMessage {...jobMessage.addressLabel} />} colon={false}>
                {form.getFieldDecorator('Address', { initialValue: clientDetails.Address })(
                  <Input
                    placeholder={intl.formatMessage({ ...placeholder.enterAddressLabel })}
                    className={styles.addressSelectWidth}
                  />
                )}
              </Form.Item>
              <div>
                <div className={styles.sourcingParameterHeader}>{sourcingParametersLabel}</div>
                <div className={styles.sourcingParameterDescription}>
                  <FormattedMessage {...jobMessage.sourcingParameterDescription} />
                </div>
              </div>
              <Form.Item
                label={
                  <span className={styles.subsidaryCandidatesInfo}>
                    <span>
                      <FormattedMessage {...jobMessage.subsidiaryCompanies} />
                    </span>
                    <InfoIconWithTooltip
                      tooltipTitle={
                        <span>
                          <FormattedMessage {...jobMessage.subsidiaryCompaniesInfoMessage} />
                        </span>
                      }
                      style={{ zIndex: 3000, maxWidth: '300px' }}
                    />
                  </span>
                }
                colon={false}
              >
                {form.getFieldDecorator('SubsidiaryCompanies', {
                  initialValue: clientDetails.SubsidiaryCompanies,
                })(
                  <Select
                    mode="tags"
                    style={{ width: '375px' }}
                    placeholder={intl.formatMessage({ ...placeholder.enterSubsidiaryCompaniesLabel })}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={
                  <span className={styles.subsidaryCandidatesInfo}>
                    <span>
                      <FormattedMessage {...jobMessage.competitorCompanies} />
                    </span>
                    <InfoIconWithTooltip
                      tooltipTitle={
                        <span>
                          <FormattedMessage {...jobMessage.competitorCompaniesInfoMessage} />
                        </span>
                      }
                      style={{ zIndex: 3000, maxWidth: '300px' }}
                    />
                  </span>
                }
                colon={false}
              >
                {form.getFieldDecorator('CompetitorCompanies', {
                  initialValue: clientDetails.CompetitorCompanies
                    ? clientDetails.CompetitorCompanies.map(company => company.Name)
                    : undefined,
                })(
                  <Select
                    mode="tags"
                    style={{ width: '375px' }}
                    placeholder={intl.formatMessage({ ...placeholder.enterCompetitorCompaniesLabel })}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={
                  <span className={styles.subsidaryCandidatesInfo}>
                    <span>
                      <FormattedMessage {...jobMessage.industryLabel} />
                    </span>
                    <InfoIconWithTooltip
                      tooltipTitle={
                        <span>
                          <FormattedMessage {...jobMessage.industryLabelInfoMessage} />
                        </span>
                      }
                      style={{ zIndex: 3000, maxWidth: '300px' }}
                    />
                  </span>
                }
                colon={false}
              >
                {form.getFieldDecorator('Industries', {
                  initialValue: clientDetails.Industries ? clientDetails.Industries.map(industry => industry.Name) : [],
                })(industrySelect)}
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="client-form-footer">
          <div className="buttons">
            <Button
              className="cancel"
              onClick={() => {
                onClose(form);
                toggleAddClientDrawer();
              }}
            >
              <FormattedMessage {...jobMessage.cancelButton} />
            </Button>
            <Button
              className="create-client"
              onClick={this.handleSubmit}
              loading={createClientApiStatus?.status === 'INPROGRESS'}
            >
              {!clientDetails.Id ? (
                <FormattedMessage {...jobMessage.createClientButton} />
              ) : (
                <FormattedMessage {...jobMessage.updateClientButton} />
              )}
            </Button>
          </div>
        </div>
        <div />
      </div>
    );
  }
}

export default injectIntl(Form.create()(ClientForm));
export { ClientForm as ClientFormWithoutInjectIntl };
