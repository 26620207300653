import React from 'react';
import { getFilteredRecommendations } from '../../Utils/AdvanceRefineIntelUtils';
import EmploymentContext from './EmploymentContext';

export default function EmploymentWrapper({
  children,
  form,
  recommendedIndusties,
  recommendedCompanies,
  recommendedOccupations,
  industries = [],
  occupations = [],
}) {
  const {
    PreferredCompany: existingCompanies,
    PreferredIndustry: existingIndustries,
    PreferredOccupation: existingOccupations,
  } = form.getFieldsValue();
  const industryRecommendations = getFilteredRecommendations(recommendedIndusties, existingIndustries);
  const companyRecommendations = getFilteredRecommendations(recommendedCompanies, existingCompanies);
  const occupationRecommendations = getFilteredRecommendations(recommendedOccupations, existingOccupations);
  const isDisable = !form.getFieldValue('PreferredCompany') || form.getFieldValue('PreferredCompany')?.length === 0;

  return (
    <EmploymentContext.Provider
      value={{
        form,
        industries,
        occupations,
        industryRecommendations,
        companyRecommendations,
        occupationRecommendations,
        isDisable,
      }}
    >
      {children}
    </EmploymentContext.Provider>
  );
}
