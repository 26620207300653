import _ from 'lodash';
import { SET_NOTIFICATION_FETCH_ENABLED_FLAG } from '../Actions/ActionCreators/AryaNotifyActionCreator';

const emptyList = [];
const emptyObject = {};
const initialState = {
  Connect: {
    Notifications: [],
    UnViewedCount: 0,
  },
  Arya: {
    Notifications: [],
    UnViewedCount: 0,
  },
  newNotificationStatus: {},
  isNotificationFetchEnabled: false,
};

function AryaNotifyReducer(state = initialState, action) {
  let newState;
  let connectNotifications;
  let aryaNotifications;
  let notifications;
  switch (action.type) {
    case 'REPLACE_CONNECT_NOTIFICATIONS':
      newState = _.cloneDeep(state);
      _.set(newState, 'Connect', action.payload);
      return newState;

    case 'REPLACE_ARYA_NOTIFICATIONS':
      newState = _.cloneDeep(state);
      _.set(newState, 'Arya', action.payload);
      return newState;
    case 'APPEND_CONNECT_NOTIFICATIONS':
      newState = _.cloneDeep(state);
      if (action.payload.shouldReplaceNotifications)
        return {
          ...state,
          Connect: { ...action.payload, currentPageConnectNotificationCount: action.payload.Notifications.length },
        };
      connectNotifications = _.get(newState, 'Connect.Notifications', null);
      if (!connectNotifications) {
        _.set(newState, 'Connect', {
          ...action.payload,
          currentPageConnectNotificationCount: action.payload.Notifications.length,
        });
      } else {
        newState.Connect.Notifications.push(...action.payload.Notifications);
        newState.Connect.Total = action.payload.Total;
        newState.Connect.UnViewedCount = action.payload.UnViewedCount;
        newState.Connect.currentPageConnectNotificationCount = action.payload.Notifications.length;
      }
      return newState;
    case 'APPEND_ARYA_NOTIFICATIONS':
      newState = _.cloneDeep(state);
      aryaNotifications = _.get(newState, 'Arya.Notifications', null);
      if (!aryaNotifications) {
        _.set(newState, 'Arya', {
          ...action.payload,
          currentPageNotificationCount: action.payload.Notifications.length,
        });
      } else {
        newState.Arya.Notifications.push(...action.payload.Notifications);
        newState.Arya.Total = action.payload.Total;
        newState.Arya.UnViewedCount = action.payload.UnViewedCount;
        newState.Arya.currentPageNotificationCount = action.payload.Notifications.length;
      }
      return newState;
    case 'CLEAR_NOTIFICATIONS_VIEWED_COUNT':
      newState = _.cloneDeep(state);
      _.set(newState, [action.payload.notificationType, 'UnViewedCount'], 0);
      return newState;
    case 'MARK_NOTIFICATION_READ':
      newState = _.cloneDeep(state);
      notifications = _.get(newState, [action.payload.notificationType, 'Notifications'], []).map(n => {
        if (n.Id !== action.payload.NotificationId) return n;
        return {
          ...n,
          UnreadCount: 0,
        };
      });
      _.set(newState, [action.payload.notificationType, 'Notifications'], notifications);
      return newState;

    case 'NEW_NOTIFICATION_STATUS':
      newState = _.cloneDeep(state);
      newState.newNotificationStatus[action.payload.notificationType] = {
        senderId: action.payload.senderId,
        showNotification: action.payload.showNotification,
      };
      return newState;

    case 'SET_RECENT_READ_NOTIFICATION_INFO':
      newState = _.cloneDeep(state);
      if (!_.isEmpty(action.payload.info.recentReadNotificationInfo)) {
        _.merge(_.get(newState, [action.payload.notificationType]), action.payload.info);
      } else {
        _.set(newState, [action.payload.notificationType, 'recentReadNotificationInfo'], {});
      }
      return newState;
    case SET_NOTIFICATION_FETCH_ENABLED_FLAG:
      return {
        ...state,
        isNotificationFetchEnabled: action.payload.isNotificationFetchEnabled,
      };

    case 'SET_CURRENT_MESSAGE_POPUP_INFOS': {
      newState = _.cloneDeep(state);
      _.set(newState, [['CurrentMessageNotification'], action.payload.notificationId], action.payload.data);

      return newState;
    }

    default:
      return state;
  }
}

function getIsNotifcationFetchEnabled(state) {
  return state.AryaNotifyReducer?.isNotificationFetchEnabled;
}

function getNotifications(state, notificationType) {
  return _.get(state, ['AryaNotifyReducer', notificationType, 'Notifications'], emptyList);
}

function getNotificationsTotalCount(state, notificationType) {
  return _.get(state.AryaNotifyReducer, [notificationType, 'Total'], 2);
}

function getUnviewedNotificationsCount(state, notificationType) {
  return _.get(state.AryaNotifyReducer, [notificationType, 'UnViewedCount'], 0);
}

function getNewNotificationStatus(state, notificationType) {
  return state.AryaNotifyReducer.newNotificationStatus[notificationType];
}

function getRecentReadNotificationInfo(state, notificationType) {
  return _.get(state.AryaNotifyReducer, [notificationType, 'recentReadNotificationInfo'], emptyObject);
}

function getCurrentPageNotificationCount(state) {
  return state.AryaNotifyReducer.Arya?.currentPageNotificationCount;
}

function getCurrentPageConnectNotificationCount(state) {
  return state.AryaNotifyReducer.Connect?.currentPageConnectNotificationCount;
}
function getMessagePopupNotificationPayload(state, personId) {
  return state.AryaNotifyReducer.CurrentMessageNotification?.[personId];
}

export {
  AryaNotifyReducer,
  getNotifications,
  getNotificationsTotalCount,
  getUnviewedNotificationsCount,
  getNewNotificationStatus,
  getRecentReadNotificationInfo,
  getIsNotifcationFetchEnabled,
  getCurrentPageNotificationCount,
  getCurrentPageConnectNotificationCount,
  getMessagePopupNotificationPayload,
};
