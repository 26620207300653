import React from 'react';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import TooltipReportData from '../../WorkflowReport/WorkflowReportUtils/TooltipReportData';
import EdgeAddButton from '../Buttons/EdgeAddButton/EdgeAddButton';
import * as WorkflowReportReducer from '../../../Reducers/WorkflowReportReducer';
import * as WorkflowReportAction from '../../../Actions/WorkflowReportActions';

const mapStateToProps = state => {
  return {
    workflowReportData: WorkflowReportReducer.getWorkflowReportData(state),
    isWorkflowPublished: WorkflowReportReducer.getWorkflowPublishedStatus(state),
    workflowEndNodeData: WorkflowReportReducer.getWorkflowReportEndNodesData(state),
    workflowReportFetchApiStatus: WorkflowReportReducer.getWorkflowReportApiStatus(state),
  };
};

const mapDispatchToProps = {
  setWorkflowReportEndNodesData: WorkflowReportAction.setWorkflowReportEndNodesData,
};

const TooltipEdge = props => {
  const {
    id,
    source,
    workflowReportData,
    sourceType,
    targetType,
    targetNode,
    setWorkflowReportEndNodesData,
    isWorkflowPublished,
    workflowEndNodeData,
    workflowReportFetchApiStatus,
    renderTooltipFlag,
  } = props;
  const [tooltipText, setTooltipText] = React.useState('');
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const { ActivitiesStats: activityStats } = workflowReportData;
  const { isRendered } = workflowEndNodeData || false;

  React.useEffect(() => {
    if (Array.isArray(activityStats)) {
      const tooltipTextNode = TooltipReportData({
        activityStats,
        elementId: source,
        outComeLabel: 'Done',
        elementType: 'edge',
        targetType,
        target: targetNode,
        sourceNode: sourceType,
        setWorkflowReportEndNodesData,
        isRendered,
      });
      if (tooltipTextNode && isWorkflowPublished) {
        setTooltipText(tooltipTextNode);
        setTooltipVisible(true);
      }
    }
  }, [workflowReportData]);

  React.useEffect(() => {
    if (workflowReportFetchApiStatus) {
      setTooltipVisible(false);
    }
  }, [workflowReportFetchApiStatus]);

  return (
    <Tooltip
      placement="right"
      title={tooltipText}
      overlayClassName={`EdgeTooltip-${id} TooltipEdge`}
      visible={renderTooltipFlag && tooltipVisible}
      overlayStyle={{ left: '-73px' }}
      getTooltipContainer={node => node.parentNode}
    >
      <div></div>
      <EdgeAddButton {...props} />
    </Tooltip>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TooltipEdge);
export { TooltipEdge as TooltipEdgeWithoutStore };
