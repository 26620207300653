import React from 'react';
import { Icon } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import {
  CandidateMoveIcon,
  JobIcon,
  NoteCreateIcon,
  NoteModifiedIcon,
  NoteDeletedIcon,
  TagCreatedIcon,
  TagUpdatedIcon,
  TagDeletedIcon,
  SmsRepliedIcon,
  SmsSentIcon,
  AudioCallIcon,
  EmailRepliedIcon,
  EmailViewedIcon,
  EmailSentIcon,
  CandidateSegmentAddIcon,
  CandidateSegmentRemoveIcon,
  EmailClickIcon,
  AudioCallCanceledIcon,
  AudioCallUnansweredIcon,
  AudioCallFailedIcon,
} from '../../Icons/AryaIcons';
import styles from './ActivityLogUtil.module.scss';
import PushIconV2 from '../../Icons/PushIconV2';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const getLocalTime = dateTime => moment.utc(dateTime).local().format('hh:mm A');

const openSegmentPage = ({ id, version }) => {
  if (version === 'ats') {
    return `/Leo/Segments/Info?segmentId=${id}`;
  }
  return `/segments/${id}/`;
};

const openJobPage = ({ id, code, version }) => {
  if (version === 'ats') {
    if (code) {
      return `/Leo/Job/JobViewNew?id=${code}`;
    }
    return '/Leo/Job/JobIndex';
  }
  return `/jobs/${id}/`;
};

export const openPage = ({ id, code, entityType, version }) => {
  switch (entityType?.toLowerCase()) {
    case 'job':
      return openJobPage({ id, code, version });
    case 'segment':
      return openSegmentPage({ id, version });
    default:
      return openJobPage({ id, code, version });
  }
};

export const openPageUsingId = ({ id, code, entityTypeId, version }) => {
  switch (entityTypeId) {
    case 1:
      return openJobPage({ id, code, version });
    case 2:
      return openSegmentPage({ id, version });
    default:
      return openJobPage({ id, code, version });
  }
};

export const eventNameActionNameMapping = {
  'job:candidate:sourced': null,
  'job:candidate:viewed': 'viewed',
  'job:candidate:shortlisted': 'shortlisted',
  'job:candidate:rejected': 'rejected',
  'job:candidate:applied': null,
  'candidate:downloaded': 'downloaded',
  'candidate:copied:to:job': 'copied',
  'candidate:moved:to:job': 'moved',
  'candidate:copied:to:segment': 'copied',
  'candidate:moved:to:segment': 'moved',
  'segment:candidate:shortlisted': 'added',
  'segment:candidate:rejected': 'removed',
  'job:candidate:deleted': 'deleted',
  'candidate:bookmark:added': 'added',
  'candidate:bookmark:removed': 'removed',
  'candidate:note:created': 'created',
  'candidate:note:deleted': 'deleted',
  'candidate:note:updated': 'updated',
  'candidate:tag:created': 'added',
  'candidate:tag:deleted': 'removed',
  'candidate:email:sent': 'sent',
  'candidate:email:viewed': null,
  'candidate:email:replied': null,
  'candidate:email:clicked': null,
  'candidate:sms:sent': 'sent',
  'candidate:sms:replied': null,
  'candidate:audiocall:completed': null,
};

const eventNameToActivityNameMapping = {
  'candidate:tag:created': 'tag',
  'candidate:tag:deleted': 'tag',
};

const getActivityActionName = eventName => {
  if (eventName == null) {
    return null;
  }
  const actionName = eventNameActionNameMapping[eventName];
  return _.capitalize(actionName);
};

const getActivityName = eventName => {
  const activityName = eventNameToActivityNameMapping[eventName];
  return _.capitalize(activityName);
};
export const getJobIdentifier = ({ id, code, version }) => {
  if (version === 'ats') return code;
  return id;
};

export const getCallDuration = ({ startedTime, endedTime }) => {
  const startedTimeInMoment = moment(startedTime);
  const endedTimeInMoment = moment(endedTime);
  const diff = endedTimeInMoment.diff(startedTimeInMoment, 'seconds');
  const callDurationMinutes = Math.floor(diff / 60);
  const callDurationSeconds = diff % 60;
  return `${callDurationMinutes === 0 ? '' : callDurationMinutes} ${callDurationMinutes === 0 ? '' : 'min'} ${
    callDurationSeconds === 0 ? '' : callDurationSeconds
  } ${callDurationSeconds === 0 ? '' : 'sec'}`;
};

export const getUpdatedAudioConversationStatus = audioConversationStatus => {
  if (audioConversationStatus === 7) {
    return 'Completed';
  }
  return audioConversationStatus;
};

export const getSecondaryContentForAudioCalls = event => {
  switch (getUpdatedAudioConversationStatus(event.EventData?.AudioConversationStatus)) {
    case 'Completed':
      return (
        <>
          Connected:{' '}
          {getCallDuration({
            startedTime: event.EventData?.AnsweredTime,
            endedTime: event.EventData?.FinishedTime,
          })}
        </>
      );
    case 'Canceled':
      return <>Canceled </>;
    case 'Unanswered':
      return <>Unanswered </>;
    case 'Failed':
      return <>Failed </>;
    default:
      return null;
  }
};

export const getAudioEventIcon = ({ audioConversationStatus }) => {
  switch (getUpdatedAudioConversationStatus(audioConversationStatus)) {
    case 'Completed':
      return <AudioCallIcon />;
    case 'Canceled':
      return <AudioCallCanceledIcon />;
    case 'Unanswered':
      return <AudioCallUnansweredIcon />;
    case 'Failed':
      return <AudioCallFailedIcon />;
    default:
      return <AudioCallIcon />;
  }
};

export const getEventIcon = ({ activityLog }) => {
  switch (activityLog?.EventName?.toLowerCase()) {
    case 'job:candidate:sourced':
      return <JobIcon />;
    case 'job:candidate:viewed':
      return <Icon type="eye" />;
    case 'job:candidate:shortlisted':
      return <Icon type="like" />;
    case 'job:candidate:rejected':
      return <Icon type="like" rotate={180} />;
    case 'job:candidate:applied':
      return <Icon type="check-circle" />;
    case 'candidate:downloaded':
      return <Icon type="download" />;
    case 'candidate:copied:to:job':
    case 'candidate:moved:to:job':
    case 'candidate:copied:to:segment':
    case 'candidate:moved:to:segment':
      return <CandidateMoveIcon />;
    case 'segment:candidate:shortlisted':
      return <CandidateSegmentAddIcon />;
    case 'segment:candidate:rejected':
    case 'job:candidate:deleted':
      return <CandidateSegmentRemoveIcon />;
    case 'candidate:bookmark:added':
    case 'candidate:bookmark:removed':
      return <Icon type="star" />;
    case 'candidate:note:created':
      return <NoteCreateIcon />;
    case 'candidate:note:deleted':
      return <NoteDeletedIcon />;
    case 'candidate:note:updated':
      return <NoteModifiedIcon />;
    case 'candidate:tag:created':
    case 'candidate:tagged:extension':
      return <TagCreatedIcon />;
    case 'candidate:tag:updated':
      return <TagUpdatedIcon />;
    case 'candidate:tag:deleted':
      return <TagDeletedIcon />;
    case 'candidate:email:sent':
      return <EmailSentIcon />;
    case 'candidate:email:viewed':
      return <EmailViewedIcon />;
    case 'candidate:email:replied':
      return <EmailRepliedIcon />;
    case 'candidate:email:clicked':
      return <EmailClickIcon />;
    case 'candidate:sms:sent':
      return <SmsSentIcon />;
    case 'candidate:sms:replied':
      return <SmsRepliedIcon />;
    case 'candidate:audiocall:completed':
      return getAudioEventIcon({ audioConversationStatus: activityLog?.EventData?.AudioConversationStatus });
    case 'candidate:pushed':
      return (
        <PushIconV2
          style={{ fontSize: '14px' }}
          fillColor="
          #51575E"
        />
      );
    default:
      return <AudioCallIcon />;
  }
};

export function ActivityByUser({ event }) {
  const actionName = getActivityActionName(event?.EventName?.toLowerCase());
  if (!actionName) {
    return null;
  }
  return (
    <>
      &nbsp;|&nbsp; <FormattedMessage {...message[actionName]} />&nbsp;
      <FormattedMessage {...message.byLabel} />
      :&nbsp;
      <span>{event?.CreatedByUser}</span>
    </>
  );
}

export const ActivityName = ({ event }) => {
  const activityName = getActivityName(event?.EventName?.toLowerCase());
  if (!activityName) return null;
  switch (event?.EventName?.toLowerCase()) {
    case 'candidate:tag:deleted':
    case 'candidate:tag:created':
      return (
        <>
          &nbsp;|&nbsp;{activityName}:&nbsp;{event?.EventData?.TagName}
        </>
      );
    default:
      return null;
  }
};

export const getEventDescription = ({ event, contentClass, subContentClass, version }) => {
  switch (event?.EventName?.toLowerCase()) {
    case 'job:candidate:sourced':
      return (
        <div>
          <div className={contentClass}>
            {event?.EventData?.SourcedCount ? (
              <>
                <span>
                  <FormattedMessage {...message.CandidatesLabel} />:
                </span>
                &nbsp;
                <span>{event?.EventData?.SourcedCount}</span>&nbsp;|&nbsp;
              </>
            ) : null}
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openJobPage({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
            <ActivityByUser event={event} />
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'job:candidate:viewed':
    case 'job:candidate:shortlisted':
    case 'job:candidate:rejected':
    case 'job:candidate:deleted':
    case 'job:candidate:applied':
      return (
        <div>
          <div className={contentClass}>
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openJobPage({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
            <ActivityByUser event={event} />
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'segment:candidate:rejected':
    case 'segment:candidate:shortlisted':
      return (
        <div>
          <div className={contentClass}>
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openSegmentPage({ id: event?.ContextRefOriginalId, version })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
            <ActivityByUser event={event} />
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'candidate:bookmark:added':
    case 'candidate:bookmark:removed':
    case 'candidate:sms:sent':
    case 'candidate:sms:replied':
      return (
        <div>
          <div className={contentClass}>
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openPage({
                id: event?.ContextRefOriginalId,
                code: event?.ContextRefOriginalCode,
                entityType: event?.ContextRefType,
                version,
              })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
            <ActivityByUser event={event} />
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'candidate:downloaded':
    case 'candidate:tagged:extension':
      return (
        <div>
          {event?.ContextRefOriginalId ? (
            <div className={contentClass}>
              <span>{event?.EventName?.toLowerCase() === 'candidate:downloaded' ?<FormattedMessage {...message.downloadedByLabel} /> : <FormattedMessage {...message.taggedByLabel} />}</span>
              &nbsp;
              <span>{event?.CreatedByUser}</span>&nbsp;|&nbsp;
              <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
              <a
                href={openPage({
                  id: event?.ContextRefOriginalId,
                  code: event?.ContextRefOriginalCode,
                  entityType: event?.ContextRefType,
                  version,
                })}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
              </a>
            </div>
          ) : (
            <div className={contentClass}>{`${getActivityActionName(event?.EventName)} by: ${
              event?.CreatedByUser
            }`}</div>
          )}
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'candidate:copied:to:job':
      return (
        <div>
          <div className={contentClass}>
            <span><FormattedMessage {...message.copiedFromLabel} /></span>&nbsp;
            {event?.EventData?.SourceJobName ? <span>{event?.EventData?.SourceJobName}&nbsp;|&nbsp;</span> : null}
            <a
              href={openPageUsingId({
                id: event?.EventData?.SourceJobId,
                code: event?.ContextRefOriginalCode,
                entityTypeId: event?.EventData?.SourceJobEntityTypeId,
                version,
              })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {event?.EventData?.SourceJobId}
            </a>
            &nbsp;to&nbsp;
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openJobPage({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'candidate:copied:to:segment':
      return (
        <div>
          <div className={contentClass}>
            <span><FormattedMessage {...message.copiedFromLabel} /></span>&nbsp;
            {event?.EventData?.SourceJobName ? <span>{event?.EventData?.SourceJobName}&nbsp;|&nbsp;</span> : null}
            <a
              href={openPageUsingId({
                id: event?.EventData?.SourceJobId,
                code: event?.ContextRefOriginalCode,
                entityTypeId: event?.EventData?.SourceJobEntityTypeId,
                version,
              })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {event?.EventData?.SourceJobId}
            </a>
            &nbsp;to&nbsp;
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openSegmentPage({ id: event?.ContextRefOriginalId, version })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'candidate:moved:to:job':
      return (
        <div>
          <div className={contentClass}>
            <span><FormattedMessage {...message.movedFromLabel} /></span>&nbsp;
            {event?.EventData?.SourceJobName ? <span>{event?.EventData?.SourceJobName}&nbsp;|&nbsp;</span> : null}
            <a
              href={openPageUsingId({
                id: event?.EventData?.SourceJobId,
                code: event?.ContextRefOriginalCode,
                entityTypeId: event?.EventData?.SourceJobEntityTypeId,
                version,
              })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {event?.EventData?.SourceJobId}
            </a>
            &nbsp;to&nbsp;
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openJobPage({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'candidate:moved:to:segment':
      return (
        <div>
          <div className={contentClass}>
            <span><FormattedMessage {...message.movedFromLabel} /></span>&nbsp;
            {event?.EventData?.SourceJobName ? <span>{event?.EventData?.SourceJobName}&nbsp;|&nbsp;</span> : null}
            <a
              href={openPageUsingId({
                id: event?.EventData?.SourceJobId,
                code: event?.ContextRefOriginalCode,
                entityTypeId: event?.EventData?.SourceJobEntityTypeId,
                version,
              })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {event?.EventData?.SourceJobId}
            </a>
            &nbsp;to&nbsp;
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openSegmentPage({ id: event?.ContextRefOriginalId, version })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'candidate:note:created':
    case 'candidate:note:deleted':
    case 'candidate:note:updated':
    case 'candidate:tag:deleted':
    case 'candidate:tag:created':
      return (
        <div>
          {event?.ContextRefOriginalId ? (
            <div className={contentClass}>
              <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
              <a
                href={openPage({
                  id: event?.ContextRefOriginalId,
                  code: event?.ContextRefOriginalCode,
                  entityType: event?.ContextRefType,
                  version,
                })}
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
              </a>
              <ActivityByUser event={event} />
              <ActivityName event={event} />
            </div>
          ) : (
            <div className={contentClass}>
              {`${getActivityActionName(event?.EventName)} by: ${event?.CreatedByUser}`}
              <ActivityName event={event} />
            </div>
          )}
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    case 'candidate:email:sent':
    case 'candidate:email:viewed':
    case 'candidate:email:replied':
    case 'candidate:email:clicked':
      return (
        <div>
          <div className={contentClass}>
          <FormattedMessage {...message.sentByLabel} /> {event?.CreatedByUser}- &lsquo;{event?.EventData?.Subject}&rsquo;
          </div>
          <div className={subContentClass}>
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openPage({
                id: event?.ContextRefOriginalId,
                code: event?.ContextRefOriginalCode,
                entityType: event?.ContextRefType,
                version,
              })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
            &nbsp;|&nbsp;
            <span>{getLocalTime(event?.CreatedTime)}</span>
          </div>
        </div>
      );
    case 'candidate:audiocall:completed':
      return (
        <div>
          <div className={contentClass}><FormattedMessage {...message.callerLabel} /> {event?.CreatedByUser}</div>
          <div className={subContentClass}>
            <span>{getSecondaryContentForAudioCalls(event)}</span>
            &nbsp;|&nbsp;
            <span>{getLocalTime(event?.CreatedTime)}</span>
          </div>
        </div>
      );
    case 'candidate:pushed':
      return (
        <div>
          <div className={contentClass}>
            <span><FormattedMessage {...message.pushedByLabel} /></span>&nbsp;
            <span>{event?.CreatedByUser}</span>&nbsp;|&nbsp;
            <span>{event?.ContextRefTitle}</span>&nbsp;|&nbsp;
            <a
              href={openJobPage({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              {getJobIdentifier({ id: event?.ContextRefOriginalId, code: event?.ContextRefOriginalCode, version })}
            </a>
          </div>
          <div className={subContentClass}>{getLocalTime(event?.CreatedTime)}</div>
        </div>
      );
    default:
      return (
        <div>
          <div className={contentClass}>Sent by: Peter Hudson- &lsquo;Please tell us your experience&rsquo;</div>
          <div className={subContentClass}>{`Java Developer | 121219 | ${getLocalTime(event?.CreatedTime)}`} </div>
        </div>
      );
  }
};
export const getEventDisplayText = ({ eventText }) => {
  switch (eventText) {
    case 'Sms Sent':
      return 'Text Message Sent';
    case 'Sms Replied':
      return 'Text Message Replied';
    default:
      return eventText;
  }
};
