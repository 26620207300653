import * as React from 'react';

const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={14} fill="none" {...props}>
    <path
      fill="#FFC53D"
      fillRule="evenodd"
      d="m2.728 11.377.025-.174c.19-1.107.38-2.214.575-3.321.022-.129.001-.216-.097-.31-.816-.787-1.627-1.579-2.438-2.37-.249-.243-.3-.504-.154-.772.103-.189.272-.272.48-.302 1.136-.162 2.272-.33 3.409-.49.115-.016.18-.06.23-.166C5.264 2.44 5.773 1.41 6.28.382c.138-.28.356-.409.624-.377.239.028.389.17.491.378.454.924.93 1.838 1.357 2.775.16.353.364.495.746.54 1.032.118 2.06.285 3.088.437.402.06.63.426.486.794a.899.899 0 0 1-.215.293c-.795.78-1.592 1.558-2.394 2.331-.107.103-.142.197-.115.346.199 1.116.39 2.233.58 3.35.067.394-.14.712-.508.74a.86.86 0 0 1-.434-.105A291.633 291.633 0 0 1 6.978 10.3c-.103-.055-.18-.054-.283 0-1.005.535-2.012 1.065-3.02 1.595-.296.157-.534.137-.752-.053-.137-.12-.184-.275-.195-.465Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
