import React from 'react';
import { Checkbox, Input } from 'antd';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import FilterSectionWithCheckbox from './FilterSectionWithCheckbox';
import LinkButton from '../../../Components/Common/LinkButton/LinkButton';

const { Search } = Input;

const LocationFilter = ({
  sortedLocation,
  candidatesLocationField,
  selectedLocation,
  filterSectionCommonStyles,
  onClickClear,
  form,
  filterRef
}) => {
  const [locationSearchTerm, setLocationSearchTerm] = React.useState('');

  const handleLocationSearchChange = e => {
    setLocationSearchTerm(e.target.value);
  };

  const filteredLocations = Object.keys(sortedLocation || {}).filter(location =>
    location.toLowerCase().includes(locationSearchTerm.toLowerCase())
  );

  return (
    <div className={styles.filterSection}>
      <div className={styles.filterSectionHeader}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <span className={styles.subsectionTitle}>Location </span>
          <Search
            className={styles.searchBox}
            placeholder="Search"
            allowClear
            value={locationSearchTerm}
            onChange={handleLocationSearchChange}
          />
        </div>
        <LinkButton
          buttonName="Clear all"
          onClickButton={() => {
            onClickClear(candidatesLocationField);
          }}
          style={{ color: 'rgba(7, 16, 26, 0.5)' }}
        />
      </div>
      {form.getFieldDecorator(candidatesLocationField, {
        initialValue: selectedLocation,
      })(
        <Checkbox.Group style={{ width: '100%' }}>
          <FilterSectionWithCheckbox
            filterSectionKeys={filteredLocations}
            sortedAllTabFilterSection={sortedLocation || {}}
            style={filterSectionCommonStyles}
            maxTextLength={30}
            filterRef={filterRef}
            shouldPreserveCasing
          />
        </Checkbox.Group>
      )}
    </div>
  );
};

export default LocationFilter;
