import React, { useState } from 'react';
import { Form, Select, DatePicker, Avatar, Tooltip } from 'antd';
import TextWithInfoIcon from '../../../../Common/TextWithInfoIcon/TextWithInfoIcon';
import TimeRangeSelect, {
  getDateRange,
} from '../../../ReportStatsTab/ReportView/ReportViewFilter/TimeRangeSelect/TimeRangeSelect';
import { getLocalDateFormat } from '../../../../../Utils/RelativeTimeCalculator';
import { disabledDate, validateDateRange } from '../../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import styles from './SummaryFilter.module.scss';
import { CalendarIcon } from '../../../../../Icons/AryaIcons';
import SelectDropdownWithPagination from '../../../../Common/SelectDropdownWithPagination/SelectDropdownWithPagination';
import { getCurrentUserOrg } from '../../../../../Utils/CurrentUserUtils';
import { getOrgsWithCurrentUserOrg } from '../../../../../Utils/ReportPortalUtils/OptionUtils';

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
export default function SummaryFilter(props) {
  const {
    form,
    onRecruiterSearch,
    recruiters,
    onRecruiterScroll,
    recruiterMaxCount,
    currentUserId,
    isAdmin,
    fetchRecruiterApiStatus,
    orgs,
    orgMaxCount,
    fetchOrgs,
    canUserImpersonate,
    currentUserDetails,
    clearReportSummaryData,
    setCurrentReportOrg,
  } = props;
  const [recruiterCount, setRecruiterCount] = useState(10);
  const [currentFromDate, setCurrentFromDate] = React.useState(null);
  const [currentToDate, setCurrentToDate] = React.useState(null);
  const recruiterLoadingStatus = fetchRecruiterApiStatus?.status?.toUpperCase() === 'INPROGRESS';
  const localDateFormat = getLocalDateFormat();
  const changeDateRange = dateRange => {
    form.setFieldsValue({
      dateRange,
    });
  };

  const handleOnSearch = value => {
    onRecruiterSearch(value, 0, 10);
  };

  const handleRecruiterScroll = e => {
    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 20 &&
      recruiterCount <= recruiterMaxCount
    ) {
      onRecruiterScroll(recruiterCount, 10);
      setRecruiterCount(recruiterCount + 10);
    }
  };

  const fetchOrgDropdownOptions = filter => {
    const { searchTerm, from = 0, size = 10 } = filter;
    const pageToFetch = from / size + 1;
    fetchOrgs({ page: pageToFetch, searchTerm });
  };

  const currentUserOrg = getCurrentUserOrg(currentUserDetails);
  const orgsWithCurrentUserOrg = getOrgsWithCurrentUserOrg(orgs?.Organizations, currentUserOrg);

  const selectedOrgId = form?.getFieldValue('orgId');

  const onOrgChange = value => {
    clearReportSummaryData();
    setCurrentReportOrg(value);
    form.setFieldsValue({ recruiterId: 'ALL' });
  };

  const getMyReportOption = () => {
    const myReportOption = (
      <Option key="My Report" label="My Report" value={currentUserId}>
        <Avatar size="small">MR</Avatar> My Report
      </Option>
    );
    if (!canUserImpersonate) {
      return myReportOption;
    }
    if (canUserImpersonate && selectedOrgId === currentUserDetails?.OrgId) {
      return myReportOption;
    }
    return null;
  };
  const onCalendarChange = dates => {
    if (dates?.length > 0) {
      setCurrentFromDate(dates[0]);
      if (dates?.length > 1) {
        setCurrentToDate(dates[1]);
      } else {
        setCurrentToDate(null);
      }
    }
  };
  const onTimeRangeChange = dateRange => {
    if (!dateRange || dateRange.length === 0) {
      setCurrentFromDate(null);
      setCurrentToDate(null);
    }
  };

  return (
    <Form form={form}>
      <div className={styles.summaryFilterFormItems}>
        {canUserImpersonate ? (
          <Form.Item>
            {form.getFieldDecorator(`orgId`, {
              initialValue: currentUserDetails?.OrgId,
              rules: [{ required: true, message: 'Please select organization' }],
            })(
              <SelectDropdownWithPagination
                placeholder="Select organization"
                showSearch
                className={styles.reportUserSelect}
                fetchDropdownOptions={fetchOrgDropdownOptions}
                totalDropdownOptionsCount={orgMaxCount}
                currentDropdownOptionsCount={orgs?.Organizations?.length}
                onChange={onOrgChange}
                defaultActiveFirstOption={false}
                filterOption
                optionFilterProp="label"
              >
                {orgsWithCurrentUserOrg?.map(org => (
                  <Option key={org.Id} label={org.Name} value={org.Id}>
                    <Tooltip title={org.Id}>
                      {org.Name} ({org.Id})
                    </Tooltip>
                  </Option>
                ))}
              </SelectDropdownWithPagination>
            )}
          </Form.Item>
        ) : null}
        <Form.Item>
          <div className="select-recruiters">
            {form.getFieldDecorator(`recruiterId`, {
              initialValue: currentUserId,
            })(
              <Select
                placeholder="Select User"
                showSearch
                filterOption
                loading={recruiterLoadingStatus}
                notFoundContent={recruiterLoadingStatus ? 'Loading' : null}
                onSearch={handleOnSearch}
                optionFilterProp="label"
                optionLabelProp="label"
                className={styles.reportUserSelect}
                onPopupScroll={handleRecruiterScroll}
                disabled={!isAdmin}
              >
                {getMyReportOption()}
                <Option key="All Reports" label="All Reports" value="ALL">
                  <Avatar size="small">AR</Avatar> All Reports
                </Option>

                <OptGroup key="Shared Reports" label="Shared Reports">
                  {recruiters
                    ?.filter(recruiter => recruiter?.Id !== currentUserId)
                    ?.map(recruiter => (
                      <Option key={recruiter.Id} label={recruiter.FullName} value={recruiter.Id}>
                        <Tooltip title={recruiter?.Email}>
                          <Avatar size="small">{recruiter.FullName.substring(0, 2).toUpperCase()}</Avatar>{' '}
                          {recruiter.FullName}
                        </Tooltip>
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            )}
          </div>
        </Form.Item>
        <TimeRangeSelect
          fieldDecoratorValue="dateRangeKey"
          form={form}
          className={styles.formTimeRangeSelect}
          changeDateRange={changeDateRange}
          initialValue="month"
        />
        <div className={styles.timeRangeTextInfo}>
          <div>
            {' '}
            <Form.Item>
              <div className="time-range-date-picker">
                {form.getFieldDecorator(`dateRange`, {
                  initialValue: getDateRange('month'),
                  rules: [
                    { required: true, message: 'Please select the date range' },
                    { validator: (rule, value, callback) => validateDateRange(rule, value, callback, form) },
                  ],
                })(
                  <RangePicker
                    allowClear
                    disabled={form.getFieldValue('dateRangeKey')?.toLowerCase() !== 'custom'}
                    disabledDate={current => disabledDate(current, currentFromDate, currentToDate, form)}
                    className={styles.dateRangePicker}
                    format={localDateFormat}
                    suffixIcon={<CalendarIcon />}
                    onCalendarChange={onCalendarChange}
                    onChange={onTimeRangeChange}
                  />
                )}
              </div>
            </Form.Item>
          </div>
          <div className={styles.infoButton}>
            {' '}
            <TextWithInfoIcon tooltipTitle="Please choose a date range with a maximum difference of one year." />
          </div>
        </div>
      </div>
    </Form>
  );
}
