import React from 'react';
import { FormattedMessage } from 'react-intl';
import MinMaxExperience from '../MinMaxExperience/MinMaxExperience';
import { FormRadioGroupComponent } from '../../Utils/FormComponentUtils';
import { formValuesMapper } from '../../Utils/MapperUtils';
import './ExperienceMismatchContent.scss';
import message from '../Placeholders/PlaceholdersMessages';

const experienceMismatchOptions = { 'Less Experienced': 'lessExp', 'Over Experienced': 'moreExp' };

const formValueMapper = options => {
  return Object.keys(options).map(key => {
    return { label: <FormattedMessage {...message[key.replace(/[\s\-\/]+/g, '')]}  />, value: options[key] };
  });
};
export default function ExperienceMismatchContent(props) {
  const { initalExperienceMismatchReason, initialMinExperience, initialMaxExperience, form, experienceRange } = props;
  const { MinExperience, MaxExperience } = experienceRange;
  return (
    <div className="experience-mismatch-wrapper">
      <div className="experience-mismatch-reason">
        <div className="experience-mismatch-header"><FormattedMessage {...message.selectExperienceMismatchReasonsLabel}/></div>
        <FormRadioGroupComponent
          className="experience-mismatch-radio-buttons"
          fieldDecoratorValue="EXPERIENCE_MISMATCH"
          form={form}
          initialValue={initalExperienceMismatchReason}
          inputValues={formValueMapper(experienceMismatchOptions)}
        />
      </div>
      <div className="experience-mismatch-divider" />
      {!(MinExperience || MaxExperience) ? (
        <div className="years-of-experience-range">
          <div className="years-of-experience-range-header">
          <FormattedMessage {...message.requiredYearsOfExperienceRangeLabel}/>
          </div>
          <div className="min-max-experience-wrapper">
            <MinMaxExperience
              form={form}
              initialMinExperience={initialMinExperience}
              initialMaxExperience={initialMaxExperience}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
