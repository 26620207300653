import * as React from 'react';
import { Icon } from 'antd';

export const ResumeSvg = props => (
  <svg width="190" height="171.5" viewBox="0 0 150 137" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M67.797 136.228C105.24 136.228 135.594 130.556 135.594 123.56C135.594 116.563 105.24 110.892 67.797 110.892C30.3537 110.892 0 116.563 0 123.56C0 130.556 30.3537 136.228 67.797 136.228Z"
      fill="#F5F5F7"
      fill-opacity="0.8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.034 86.3438L98.109 56.8988C96.961 55.5128 95.283 54.6738 93.516 54.6738H42.076C40.31 54.6738 38.632 55.5128 37.484 56.8988L13.56 86.3438V101.727H122.035V86.3438H122.034Z"
      fill="#AEB8C2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.83 16.6699H101.763C102.824 16.6699 103.841 17.0913 104.591 17.8415C105.342 18.5916 105.763 19.6091 105.763 20.6699V114.014C105.763 115.075 105.342 116.092 104.591 116.842C103.841 117.592 102.824 118.014 101.763 118.014H33.83C32.7692 118.014 31.7517 117.592 31.0016 116.842C30.2514 116.092 29.83 115.075 29.83 114.014V20.6699C29.83 19.6091 30.2514 18.5916 31.0016 17.8415C31.7517 17.0913 32.7692 16.6699 33.83 16.6699V16.6699Z"
      fill="#F5F5F7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.678 26.623H92.915C93.4455 26.623 93.9542 26.8338 94.3292 27.2088C94.7043 27.5839 94.915 28.0926 94.915 28.623V53.58C94.915 54.1105 94.7043 54.6192 94.3292 54.9943C93.9542 55.3693 93.4455 55.58 92.915 55.58H42.678C42.1476 55.58 41.6389 55.3693 41.2638 54.9943C40.8887 54.6192 40.678 54.1105 40.678 53.58V28.623C40.678 28.0926 40.8887 27.5839 41.2638 27.2088C41.6389 26.8338 42.1476 26.623 42.678 26.623ZM42.94 66.437H92.653C93.2529 66.437 93.8283 66.6754 94.2525 67.0996C94.6767 67.5238 94.915 68.0991 94.915 68.699C94.915 69.299 94.6767 69.8743 94.2525 70.2985C93.8283 70.7227 93.2529 70.961 92.653 70.961H42.94C42.3401 70.961 41.7648 70.7227 41.3405 70.2985C40.9163 69.8743 40.678 69.299 40.678 68.699C40.678 68.0991 40.9163 67.5238 41.3405 67.0996C41.7648 66.6754 42.3401 66.437 42.94 66.437ZM42.94 78.2H92.653C93.2531 78.2 93.8286 78.4384 94.2529 78.8627C94.6772 79.287 94.9155 79.8625 94.9155 80.4625C94.9155 81.0626 94.6772 81.6381 94.2529 82.0624C93.8286 82.4867 93.2531 82.725 92.653 82.725H42.94C42.34 82.725 41.7645 82.4867 41.3402 82.0624C40.9159 81.6381 40.6775 81.0626 40.6775 80.4625C40.6775 79.8625 40.9159 79.287 41.3402 78.8627C41.7645 78.4384 42.34 78.2 42.94 78.2ZM121.813 121.702C121.038 124.773 118.316 127.062 115.078 127.062H20.515C17.277 127.062 14.555 124.772 13.781 121.702C13.6334 121.117 13.5588 120.516 13.559 119.912V86.3451H39.877C42.784 86.3451 45.127 88.793 45.127 91.765V91.805C45.127 94.776 47.497 97.175 50.404 97.175H85.189C88.096 97.175 90.466 94.754 90.466 91.782V91.77C90.466 88.798 92.809 86.3441 95.716 86.3441H122.034V119.913C122.034 120.53 121.957 121.129 121.813 121.702Z"
      fill="#DCE0E6"
    />
    <path
      d="M146.304 6.36535C147.877 6.36535 149.153 5.10503 149.153 3.55035C149.153 1.99567 147.877 0.735352 146.304 0.735352C144.731 0.735352 143.455 1.99567 143.455 3.55035C143.455 5.10503 144.731 6.36535 146.304 6.36535Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.348 6.01291H125.65L128.548 1.08691L131.348 6.01291ZM134.909 1.08691H139.894V6.01291H134.909V1.08691Z"
      fill="white"
    />
  </svg>
);

const ResumeIcon = props => <Icon component={ResumeSvg} {...props} />;

export default ResumeIcon;
