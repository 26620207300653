import React from 'react';
import { Empty } from 'antd';
import classNames from 'classnames';
import {  FormattedMessage } from 'react-intl';
import { EmptyCandidatesIcon } from '../../Icons/AryaIcons';
import styles from './SubSegmentStyles.module.scss';
import messages from '../ManualSearchV2/ManualSearchMessages';

const EmptyScreen = () => {
  return (
    <div className={styles.emptyScreen}>
      <Empty
        image={<EmptyCandidatesIcon className={classNames(styles.emptySubsegment, 'candidate-empty-list')} />}
        description={
          <div className={styles.emptySubsegmentText}>
            <h2><FormattedMessage {...messages.noSubSegmentsFound} /></h2>
            <p><FormattedMessage {...messages.useCreateNewButtonLabel} /></p>
          </div>
        }
      />
    </div>
  );
};

export default EmptyScreen;
