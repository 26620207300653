import React from 'react';
import { Pagination } from 'antd';
import { withRouter } from 'react-router-dom';
import styles from './SampleCandidateList.module.scss';
import CandidateListItem from '../CandidateListItem/CandidateListItem';
import selectPlanTooltip from '../../Utils/TooltipUtils';

import { BenefitCard } from '../BenefitCard/BenefitCard';

const rejectTooltipTitle = 'to reject candidates so Arya can exclude similar candidates for you while sourcing';
const shortlistTooltipTitle = 'to shortlist candidates so Arya can source similar candidates for you';
const cardTitle = 'Select a paid plan to unlock the full potential of Arya Pulse!';
const cardDescription =
  "View, shortlist or engage with candidates personally or let Arya's recruiters line up interested candidates for you.";

function getCandidateList(
  history,
  featureToggleList,
  showCandidateDetail,
  totalCandidates,
  selectPlan,
  showSampleCandidates,
  candidatesById = {},
  userConfig,
  getCandidate360Notes,
  fetchCandidate360Notes,
  setUndoApplyVisibility,
  selectedSubSegmentId
) {
  const candidates = Object.values(candidatesById);
  const candidateList = candidates.map((candidate, index) => {
    return (
      <>
        {index === 0 ? (
          <div className={styles.candidateListItemWrapper}>
            <BenefitCard
              history={history}
              key="ad-card"
              redirectPath={selectPlan}
              cardTitle={cardTitle}
              cardDescription={cardDescription}
            />
          </div>
        ) : null}
        <div className={styles.candidateListItemWrapper}>
          <CandidateListItem
            key={candidate.Id}
            candidate={{ ...candidate, IsViewed: true }}
            showCandidateSource={false}
            showPortalIcon={false}
            featureToggleList={{
              ...featureToggleList,
              ShortlistReject: { Name: 'ShortlistReject', IsEnabled: true, IsAllowed: false },
              NotesIndicator: { Name: 'NotesIndicator', IsEnabled: false, IsAllowed: false },
            }}
            candidateMetadataDetails={{}}
            wrapperStyles={{
              border: '1px solid rgba(7, 16, 26, 0.15)',
            }}
            showCandidateDetail={showCandidateDetail}
            rejectTooltipTitle={selectPlanTooltip(selectPlan, rejectTooltipTitle)}
            shortlistTooltipTitle={selectPlanTooltip(selectPlan, shortlistTooltipTitle)}
            showSampleCandidates={showSampleCandidates}
            userConfig={userConfig}
            getCandidate360Notes={getCandidate360Notes}
            fetchCandidate360Notes={fetchCandidate360Notes}
            setUndoApplyVisibility={setUndoApplyVisibility}
            selectedSubSegmentId={selectedSubSegmentId}
          />
        </div>
      </>
    );
  });
  return (
    <>
      {candidateList}
      <div className={styles.sampleCandidatePagenation}>
        <Pagination
          size="small"
          total={totalCandidates}
          showSizeChanger
          hideOnSinglePage={totalCandidates <= 10}
          pageSizeOptions={['10', '20']}
          disabled
        />
      </div>
    </>
  );
}

function SampleCandidateList(props) {
  const {
    history,
    candidatesById,
    totalCandidates,
    featureToggleList,
    className,
    showCandidateDetail,
    selectPlan,
    showSampleCandidates,
    userConfig,
    getCandidate360Notes,
    fetchCandidate360Notes,
    setUndoApplyVisibility,
    selectedSubSegmentId,
  } = props;
  return (
    <div id={styles.sampleCandidatesContainer} className={className}>
      <div className={styles.sampleListContent}>
        <div className={styles.candidateListWrapper}>
          {getCandidateList(
            history,
            featureToggleList,
            showCandidateDetail,
            totalCandidates,
            selectPlan,
            showSampleCandidates,
            candidatesById,
            userConfig,
            getCandidate360Notes,
            fetchCandidate360Notes,
            setUndoApplyVisibility,
            selectedSubSegmentId
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(SampleCandidateList);
