import React from 'react';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { Form, Skeleton, Button } from 'antd';
import HtmlViewer from '../../HtmlViewer/HtmlViewer';
import { generateKeywordsFromBooleanString } from '../../../Utils/BooleanStringUtility';
import { getUserGuids, getJobDetailsForAnalytics, SAVE } from '../../../Utils/JobUtils';
import { ADVANCED_JOB_EDIT_INSIGHTS, ADVANCED_JOB_EDIT_TITLE } from '../../../PubSub/EventTypes';
import styles from './AdvancedJobDetailsReview.module.scss';
import JobCreationFooter from '../JobCreationFooter/JobCreationFooter';
import WarningPopupModal from '../WarningPopupModal/WarningPopupModal';

export function JobDetailReviewItem({ title, subTitles, children, musts, prefers, securityClearance }) {
  return (
    <div className={styles.item}>
      <span className={styles.itemTitle}>{`${title}:`}</span>
      {musts && <span className={styles.itemSubTitle}>Must have - {musts.join(', ')}</span>}
      {prefers && <span className={styles.itemSubTitle}>Preferred - {prefers.join(', ')}</span>}
      {subTitles && <span className={styles.itemSubTitle}>{subTitles.join(', ')}</span>}
      {securityClearance && <span className={styles.itemSubTitle}>{securityClearance}</span>}
      {children}
    </div>
  );
}

class AdvancedJobDetailsReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warningMessage: '',
      isSubmitButtonClicked: false,
    };
  }

  isJobDescriptionEmpty = jobDetails => {
    const regexHtml = /(<([^>]+)>)/gi;
    if (!jobDetails || !jobDetails?.Description) return true;
    return jobDetails.Description.trim().replace(regexHtml, '').length === 0;
  };

  buildWarningMessage = jobDetails => {
    const isSkillsRequired = _.isEmpty(jobDetails?.MustHaves) && _.isEmpty(jobDetails?.NiceToHaves);
    const isJobDescriptionRequired = this.isJobDescriptionEmpty(jobDetails);
    return `Please fill the ${isSkillsRequired ? 'Market Skills' : ''}
    ${isSkillsRequired && isJobDescriptionRequired ? ' and ' : ''}
    ${isJobDescriptionRequired ? 'Job Description' : ''} field${
      isSkillsRequired && isJobDescriptionRequired ? 's' : ''
    } under the insights section to proceed further. To fill ${isSkillsRequired ? 'Market Skills' : ''}
    ${isSkillsRequired && isJobDescriptionRequired ? ' and ' : ''}
    ${isJobDescriptionRequired ? 'Job Description' : ''} field${
      isSkillsRequired && isJobDescriptionRequired ? 's' : ''
    } click on edit insights.`;
  };

  handleSubmit = submitAction => {
    const { handleSubmit, jobDetails, usersById, form } = this.props;
    if (
      (this.isJobDescriptionEmpty(jobDetails) ||
        (_.isEmpty(jobDetails?.MustHaves) && _.isEmpty(jobDetails?.NiceToHaves))) &&
      submitAction === SAVE
    ) {
      this.setWarningPopupModalMessage(this.buildWarningMessage(jobDetails));
      return;
    }
    form.validateFieldsAndScroll(err => {
      if (submitAction === SAVE && !err) {
        this.setState({ isSubmitButtonClicked: true });
      }
      if (!err || submitAction !== SAVE) {
        this.setWarningPopupModalMessage('');
        const newJobDetails = _.cloneDeep(jobDetails);
        newJobDetails.AssignedTo = getUserGuids(newJobDetails?.AssignedTo || [], usersById);
        handleSubmit(newJobDetails, null, submitAction);
      }
    });
  };

  handleJobFormEdit = () => {
    const { jobDetails, setAdvancedJobFormData, history } = this.props;
    if (!_.isEmpty(jobDetails)) {
      const { Client, JobTitle } = jobDetails;
      const selectedClient = { selectedClient: Client };
      setAdvancedJobFormData({
        jobTitleData: {
          title: JobTitle,
          titleSynonyms: [],
        },
        ...jobDetails,
        ...selectedClient,
      });
    }
    const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);
    PubSub.publish(ADVANCED_JOB_EDIT_TITLE, {
      jobId: jobDetails?.JobId,
      jobTitle: jobDetails?.JobTitle,
      isAdvanced: jobDetails?.IsAdvancedJob,
      jobDetails: analyticsJobDetails,
    });
    history.push('/jobs-v2/create');
  };

  getExperienceText = () => {
    const { jobDetails } = this.props;
    const minExp = jobDetails?.MinExperience;
    const maxExp = jobDetails?.MaxExperience;

    if (minExp && maxExp) {
      return `${minExp} to ${maxExp} years`;
    }
    if (minExp && !maxExp) {
      return `Min Experience - ${minExp} years`;
    }
    if (!minExp && maxExp) {
      return `Max Experience - ${maxExp} years`;
    }
    return '';
  };

  editInsights = () => {
    const { jobDetails, history } = this.props;
    const analyticsJobDetails = getJobDetailsForAnalytics(jobDetails);
    PubSub.publish(ADVANCED_JOB_EDIT_INSIGHTS, {
      jobId: jobDetails?.JobId,
      jobTitle: jobDetails?.JobTitle,
      isAdvanced: jobDetails?.IsAdvancedJob,
      jobDetails: analyticsJobDetails,
    });
    history.push(`/jobs-v2/${jobDetails?.JobId}/edit`);
  };

  getSecurityClearanceText = () => {
    const { jobDetails } = this.props;
    const jobAdvanceInfo = jobDetails?.JobAdvanceInfo;
    const securityClearance = jobAdvanceInfo?.SecurityClearance ?? null;
    if (securityClearance !== null) {
      return securityClearance ? 'Yes' : 'No';
    }
    return "Doesn't matter";
  };

  setWarningPopupModalMessage = warningMessage => {
    this.setState({ warningMessage });
  };

  render() {
    const { warningMessage, isSubmitButtonClicked } = this.state;
    const { jobDetails, editJobStatus, jobDetailsStatus, featureToggleList } = this.props;
    const jobAdvanceInfo = jobDetails?.JobAdvanceInfo;
    const isLoading = editJobStatus === 'INPROGRESS';
    const specialNotes = jobAdvanceInfo?.SpecialNotes;
    const workAuthorization = jobAdvanceInfo?.WorkAuthorization;
    const employmentTypes = jobAdvanceInfo?.EmploymentTypes;

    const description = jobDetails?.Description;
    const summary = jobDetails?.Summary;

    const mustHaves = generateKeywordsFromBooleanString(jobDetails?.MustHaves || '');
    const niceToHaves = generateKeywordsFromBooleanString(jobDetails?.NiceToHaves || '');

    const compensationType = jobAdvanceInfo?.Salary?.CompensationType || '';
    const minSalary = jobAdvanceInfo?.Salary?.MinSalary || 0;
    const maxSalary = jobAdvanceInfo?.Salary?.MaxSalary;

    return (
      <Skeleton active paragraph={{ rows: 10 }} loading={jobDetailsStatus === 'INPROGRESS'}>
        <div className={styles.wrapper}>
          <WarningPopupModal
            warningMessage={warningMessage}
            setWarningPopupModalMessage={this.setWarningPopupModalMessage}
          />
          <div className={styles.jobSummary}>Almost done!</div>
          <div className={styles.container}>
            <div className={styles.editTitle}>
              <span className={styles.title}>{jobDetails?.JobTitle || ''}</span>
              <Button type="secondary" className={styles.editTitleBtn} onClick={this.handleJobFormEdit}>
                Click to edit Job Title, Location
              </Button>
            </div>
            <span className={styles.subTitle}>{jobDetails?.Location || ''}</span>
            <div className={styles.editInsights}>
              <JobDetailReviewItem title="Skills/Keywords" musts={mustHaves} prefers={niceToHaves} />
              <Button type="secondary" className={styles.editInsightsBtn} onClick={this.editInsights}>
                Click to edit insights
              </Button>
            </div>
            <JobDetailReviewItem title="Roles" subTitles={jobDetails?.Synonyms} />
            <JobDetailReviewItem title="Industries" subTitles={jobDetails?.Industries} />
            <JobDetailReviewItem title="Experience" subTitles={[this.getExperienceText()]} />
            <JobDetailReviewItem title="Education" subTitles={jobAdvanceInfo?.Degree} />
            <JobDetailReviewItem
              title="Salary"
              subTitles={[
                `${minSalary ? `$${minSalary}` : ''} ${maxSalary ? `to $${maxSalary}` : ''} ${
                  minSalary && maxSalary ? compensationType : ''
                }`,
              ]}
            />
            <JobDetailReviewItem title="Employment Type" subTitles={employmentTypes} />
            <JobDetailReviewItem title="Work Authorization Section" subTitles={workAuthorization} />
            <JobDetailReviewItem title="Security Clearance" securityClearance={this.getSecurityClearanceText()} />
            {featureToggleList.JobSummary.IsEnabled ? (
              <JobDetailReviewItem title="Job Summary">
                <HtmlViewer htmlContent={summary} />
              </JobDetailReviewItem>
            ) : null}
            <JobDetailReviewItem title="Job Description">
              <HtmlViewer htmlContent={description} />
            </JobDetailReviewItem>
            <JobDetailReviewItem title="Additional Notes">
              <HtmlViewer htmlContent={specialNotes} />
            </JobDetailReviewItem>
          </div>
        </div>
        <JobCreationFooter
          isSubmitButtonClicked={isSubmitButtonClicked}
          content="By clicking Submit Job, I agree that Leoforce may publish and/or distribute my job advertisement on its site and through its distribution partners."
          handleSubmit={this.handleSubmit}
          isLoading={isLoading}
          primaryButtonText="Submit Job"
        />
      </Skeleton>
    );
  }
}

export { AdvancedJobDetailsReview as AdvancedJobDetailsReviewWithoutForm };
export default Form.create()(AdvancedJobDetailsReview);
