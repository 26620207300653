import React from 'react';
import { Checkbox, Input } from 'antd';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import FilterSectionWithCheckbox from './FilterSectionWithCheckbox';
import LinkButton from '../../../Components/Common/LinkButton/LinkButton';

const { Search } = Input;
const TitlesFilter = ({
  sortedTitles,
  candidatesTitlesField,
  selectedTitles,
  filterSectionCommonStyles,
  onClickClear,
  form,
  filterRef,
}) => {
  const [titleSearchTerm, setTitleSearchTerm] = React.useState('');

  const handleTitleSearchChange = e => {
    setTitleSearchTerm(e.target.value);
  };

  const filteredTitles = Object.keys(sortedTitles || {}).filter(title =>
    title.toLowerCase().includes(titleSearchTerm.toLowerCase())
  );

  return (
    <div className={styles.filterSection}>
      <div className={styles.filterSectionHeader}>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <span className={styles.subsectionTitle}>Job Titles</span>
          <Search
            className={styles.searchBox}
            placeholder="Search"
            allowClear
            value={titleSearchTerm}
            onChange={handleTitleSearchChange}
          />
        </div>
        <LinkButton
          buttonName="Clear all"
          onClickButton={() => {
            onClickClear(candidatesTitlesField);
          }}
          style={{ color: 'rgba(7, 16, 26, 0.5)' }}
        />
      </div>
      {form.getFieldDecorator(candidatesTitlesField, { initialValue: selectedTitles })(
        <Checkbox.Group style={{ width: '100%' }}>
          <FilterSectionWithCheckbox
            filterSectionKeys={filteredTitles}
            sortedAllTabFilterSection={sortedTitles || {}}
            filterName="title"
            style={{
              ...filterSectionCommonStyles,
              gridRowGap: '10px',
              gridColumnGap: '12px',
            }}
            maxTextLength={30}
            filterRef={filterRef}
          />
        </Checkbox.Group>
      )}
    </div>
  );
};

export default TitlesFilter;
