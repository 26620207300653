import React from 'react';
import { connect } from 'react-redux';
import { Card, Button, Icon, Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import styles from './WorkflowTemplateSelectionContainer.module.scss';
import * as workflowReducer from '../../Reducers/WorkflowReducer';
import * as workflowActions from '../../Actions/WorkflowActions';
import { EllipsedText } from '../../Utils/TextUtils';
import config from '../../Config/Config';
import jobMessage from '../../Components/JobForm/JobMessages';

const { Meta } = Card;

const mapStateToProps = state => ({
  workflowTemplatesFetchApiStatus: workflowReducer.getWorkflowTemplatesFetchApiStatus(
    state,
    'getWorkflowsFetchApiStatus'
  ),
  currentWorkflowDripTemplateIds: workflowReducer.getCurrentWorkflowDripTemplateIds(state),
  workflowDripTemplatesById: workflowReducer.getWorkflowDripTemplates(state),
});

const mapDispatchToProps = {
  searchWorkflowTemplates: workflowActions.searchWorkflowTemplates,
  fetchWorkflowTemplateById: workflowActions.fetchWorkflowTemplateById,
};

const WorkflowTemplateCreationContainer = props => {
  const {
    location,
    searchWorkflowTemplates,
    currentWorkflowDripTemplateIds,
    workflowDripTemplatesById,
    workflowTemplatesFetchApiStatus,
  } = props;

  React.useEffect(() => {
    searchWorkflowTemplates({
      page: 0,
      pageSize: 10,
      searchTerm: '',
      isSystemTemplate: true,
    });
  }, []);

  const scratchTemplateId = [uuidv4()];
  const updatedWorkflowTemplateIds = [...scratchTemplateId, ...currentWorkflowDripTemplateIds];
  const updatedWorkflowTemplates = {
    ...{
      [scratchTemplateId[0]]: {
        DefinitionId: scratchTemplateId[0],
        Description: <FormattedMessage {...jobMessage.buildANewWorkflowLabel}/>,
        Name: <FormattedMessage {...jobMessage.createFromScratchLabel}/>,
        ImageURL: config.Workflow.ScratchTemplate,
        IsScratchTemplate: true,
      },
    },
    ...workflowDripTemplatesById,
  };

  const { pathname } = location;

  return (
    <div className={styles.displayContainer}>
      <Skeleton loading={workflowTemplatesFetchApiStatus !== 'Completed'}>
        <div className={styles.templateSelection}>
          <div className={styles.headingSection}>
            <h1>
              <FormattedMessage {...jobMessage.chooseATemplateLabel} />
            </h1>
            <Link to={{ pathname, search: '?tab=workflows' }}>
              <Icon type="close" style={{ fontSize: '16px', color: '#83878C' }} />
            </Link>
          </div>
          <div className={styles.displayCards}>
            {updatedWorkflowTemplateIds?.map(templateId => {
              const workflow = updatedWorkflowTemplates[templateId];
              const defaultTemplateId = workflow?.IsScratchTemplate ? null : workflow.DefinitionId;
              const title = <div className={styles.cardTitle}>{workflow.Name}</div>;
              const ellipsedText = workflow?.Description;
              const description = (
                <div className={styles.cardDescription}>
                  {ellipsedText ? <EllipsedText text={ellipsedText} maxTextLength={135} /> : null}
                </div>
              );
              const imageURL = workflow?.ImageURL || config.Workflow.DefaultTemplate;
              const previewButton =
                scratchTemplateId[0] !== templateId ? (
                  <Link to={{ pathname, search: `?tab=workflows&type=preview&workflowId=${templateId || ''}` }}>
                    <Button shape="round">
                      <FormattedMessage {...jobMessage.previewLabel} />
                    </Button>
                  </Link>
                ) : null;

              return (
                <div key={workflow.DefinitionId}>
                  <Card
                    className={styles.displayCard}
                    cover={<img alt="workflow display" src={imageURL} width="200px" height="150px" />}
                  >
                    <div className={styles.cardContent}>
                      <Meta title={title} description={description} />
                      <div>
                        {previewButton}
                        &nbsp;&nbsp;
                        <Link
                          to={{
                            pathname,
                            search: '?tab=workflows&type=create',
                            state: { workflowId: defaultTemplateId },
                          }}
                        >
                          <Button type="primary" shape="round">
                            <FormattedMessage {...jobMessage.useItLabel} />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowTemplateCreationContainer);
export { WorkflowTemplateCreationContainer as WorkflowTemplateCreationContainerWithoutStore };
