import React from 'react';
import { Form } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormSelectComponent, FormRadioGroupComponent, FormInputComponent } from '../../../../Utils/FormComponentUtils';
import { formValuesMapper } from '../../../../Utils/MapperUtils';
import { getColonSplittedArray } from '../../QuickSearchFormUtils';
import CareerBuilderEdgeOptions from '../../../../Data/AdvancedOptions/CareerBuilderEdge/CareerBuilderEdgeOptions.json';
import CareerBuilderEdgeInputs from '../../../../Data/AdvancedOptions/CareerBuilderEdge/CareerBuilderEdgeInputs.json';
import '../AdvancedOptions.scss';
import messages from '../../../../Containers/ManualSearchV2/ManualSearchMessages';

function CareerBuilderEdgeAdvancedOptions(props) {
  const {
    DesiredJobTypeOptions,
    CompanySizeCodeOptions,
    WorkStatusOptions,
    WillingToRelocateOptions,
    SecurityClearanceOptions,
  } = CareerBuilderEdgeOptions;

  const {
    DesiredJobTypeParams,
    CompanySizeParams,
    WorkStatusParams,
    SecurityClearanceParams,
    WillingToRelocateParams,
    CandidateNameParams,
    EmployerNameParams,
    EmailParams,
  } = CareerBuilderEdgeInputs;

  const formValueMapper = options => {
    return Object.keys(options).map(key => {
      return { label: <FormattedMessage {...messages[key.replace(/[\s\-\/]+/g, '')]}  />, value: options[key] };
    });
  };

  const { form, defaultSettings, maskingConfig, diversityForNameText, intl } = props;
  const { Names, Employer, Email, CompanySizeCode, DesiredJobType, WorkStatus, WillingToRelocate, SecurityClearance } =
    defaultSettings;

  const { IsNameMasked: isNameMasked } = maskingConfig;
  const tooltipText = isNameMasked ? diversityForNameText('name', 'Name') : '';
  return (
    <div className="quicksearch-advanced-options careerbuilder-edge-advanced-options">
      <FormInputComponent
        {...CandidateNameParams}
        label={<FormattedMessage {...messages[CandidateNameParams.label.replace(/[\s/\\-]+/g, '')]} />}
        placeholder={intl.formatMessage({ ...messages[CandidateNameParams.placeholder.replace(/[\s/\\-]+/g, '')] })}
        form={form}
        initialValue={Names}
        tooltipText={tooltipText}
        isNameMasked={isNameMasked}
      />
      <FormSelectComponent
        {...EmailParams}
        label={<FormattedMessage {...messages[EmailParams.label.replace(/[\s/\\-]+/g, '')]} />}
        placeholder={intl.formatMessage({ ...messages[EmailParams.placeholder.replace(/[\s/\\-]+/g, '')] })}
        form={form}
        initialValue={getColonSplittedArray(Email)}
      />
      <FormInputComponent
        {...EmployerNameParams}
        label={<FormattedMessage {...messages[EmployerNameParams.label.replace(/[\s/\\-]+/g, '')]} />}
        placeholder={intl.formatMessage({ ...messages[EmployerNameParams.placeholder.replace(/[\s/\\-]+/g, '')] })}
        form={form}
        initialValue={Employer}
      />

      <FormSelectComponent
        {...CompanySizeParams}
        label={<FormattedMessage {...messages[CompanySizeParams.label.replace(/[\s/\\-]+/g, '')]} />}
        placeholder={intl.formatMessage({ ...messages[CompanySizeParams.placeholder.replace(/[\s/\\-]+/g, '')] })}
        form={form}
        initialValue={getColonSplittedArray(CompanySizeCode)}
        inputValues={formValueMapper(CompanySizeCodeOptions)}
      />
      <FormSelectComponent
        {...DesiredJobTypeParams}
        label={<FormattedMessage {...messages[DesiredJobTypeParams.label.replace(/[\s/\\-]+/g, '')]} />}
        placeholder={intl.formatMessage({ ...messages[DesiredJobTypeParams.placeholder.replace(/[\s/\\-]+/g, '')] })}
        form={form}
        initialValue={getColonSplittedArray(DesiredJobType)}
        inputValues={formValueMapper(DesiredJobTypeOptions)}
      />
      <FormSelectComponent
        {...WorkStatusParams}
        label={<FormattedMessage {...messages[WorkStatusParams.label.replace(/[\s/\\-]+/g, '')]}  />}
        placeholder={intl.formatMessage({ ...messages[WorkStatusParams.placeholder.replace(/[\s/\\-]+/g, '')] })}
        form={form}
        initialValue={WorkStatus}
        inputValues={formValueMapper(WorkStatusOptions)}
      />
      <FormRadioGroupComponent
        {...SecurityClearanceParams}
        label={<FormattedMessage {...messages[SecurityClearanceParams.label.replace(/[\s/\\-]+/g, '')]}  />}
        form={form}
        initialValue={SecurityClearance || 'not_selected'}
        inputValues={formValueMapper(SecurityClearanceOptions)}
      />
      <FormRadioGroupComponent
        {...WillingToRelocateParams}
        label={<FormattedMessage {...messages[WillingToRelocateParams.label.replace(/[\s/\\-]+/g, '')]}  />}
        form={form}
        initialValue={WillingToRelocate || 'not_selected'}
        inputValues={formValueMapper(WillingToRelocateOptions)}
      />
    </div>
  );
}

export default injectIntl(Form.create()(CareerBuilderEdgeAdvancedOptions));
export {CareerBuilderEdgeAdvancedOptions as CareerBuilderEdgeAdvancedOptionsWithoutInjectIntl};
