export const getWorkflowData = activities => {
  const newActivity = [];
  let minCounter = 0;
  let index = 0;
  for (const activity of activities) {
    const { Type: type, adjacentRule } = activity;
    if (type === 'AryaConnectSendEmail') {
      if (!minCounter) {
        activity.State = 'max';
        minCounter += 1;
      } else activity.State = 'min';
    }
    if (type === 'If' && !adjacentRule) {
      const nextActivity = activities[index + 1];
      const { Type: nextActivityType } = nextActivity;
      if (nextActivityType === 'If') {
        activity.nextRule = {
          text: `${nextActivity.DisplayName}: ${nextActivity.Description}`,
        };
        nextActivity.adjacentRule = true;
      }
    }
    if (type !== 'End' && !adjacentRule) newActivity.push(activity);
    index += 1;
  }
  return newActivity;
};

export const addEmailTemplateWorkflowData = (workflowData, emailTemplatesById) => {
  if (workflowData.length > 0) {
    return workflowData.map(item => {
      const obj = { ...item };
      if (obj.Type === 'AryaConnectSendEmail') {
        const templateId = obj.Properties[0].Value;
        const emailTemplateData = emailTemplatesById[templateId] ?? {};
        obj.Body = emailTemplateData.Body;
        obj.Subject = emailTemplateData.Subject;
      }
      return obj;
    });
  }
  return [];
};

export const createWorkflow = (activities, connections, startingId) => {
  let targetId = startingId;
  const workflowFlatIds = [startingId];
  const workflowAscendedOrder = [];
  while (targetId != null) {
    targetId = getTargetId(targetId, connections);
    workflowFlatIds.push(targetId);
  }
  workflowFlatIds.forEach(id => {
    const item = activities.find(({ ActivityId }) => ActivityId === id);
    if (item) workflowAscendedOrder.push(item);
  });
  return workflowAscendedOrder;
};

const getTargetId = (sourceId, connections) => {
  let targetId = null;
  connections.forEach(connection => {
    const { SourceActivityId, TargetActivityId } = connection;
    if (SourceActivityId === sourceId) targetId = TargetActivityId;
  });
  return targetId;
};

export const getTotalEmails = Activities => {
  let totalEmailCounter = 0;
  Activities.forEach(activity => {
    if (activity.Type === 'AryaConnectSendEmail') totalEmailCounter += 1;
  });
  return totalEmailCounter;
};

export const createWorkflowData = workflowData => {
  const { Activities, Connections, StartActivityId } = workflowData;
  const finalArray = [];
  const nodeStack = [];
  let emailCounter = 0;
  const firstNode = Activities.find(activity => activity.ActivityId === StartActivityId);
  nodeStack.push(firstNode);
  if (firstNode.Type === 'AryaConnectSendEmail') emailCounter += 1;

  while (nodeStack.length > 0 && emailCounter !== 4) {
    const element = nodeStack.pop();
    if (element.Type === 'AryaConnectSendEmail') emailCounter += 1;
    finalArray.push(element);
    const targetID = getTargetId(element.ActivityId, Connections);
    const nextElement = Activities.find(activity => activity.ActivityId === targetID);
    if (nextElement) {
      nodeStack.push(nextElement);
    }
  }
  return finalArray;
};
