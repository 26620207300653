import _ from 'lodash';
import * as dripTemplatesApi from '../Repository/DripTemplates';
import { fetchConnectUsers } from './UserActions';
import { setNotification } from './ActionCreators/ConnectActions';
import { setDripTemplateDetails, setDripTemplateFetchApiStatus } from './ActionCreators/DripTemplatesActionCreator';

function getDripTemplates(from, size) {
  return dispatch => {
    dripTemplatesApi.getDripTemplates(from, size).then(response => {
      dispatch({
        type: 'SET_DRIP_TEMPLATES',
        payload: response.data.Templates,
      });
      dispatch({
        type: 'SET_DRIP_TEMPLATES_COUNT',
        payload: response.data.Total,
      });
    });
  };
}

function clearDripTemplates() {
  return dispatch => {
    dispatch({ type: 'CLEAR_DRIP_TEMPLATES' });
  };
}

function searchDripTemplates(filter) {
  return dispatch => {
    dripTemplatesApi.searchDripTemplates(filter).then(response => {
      dispatch({
        type: 'SET_DRIP_TEMPLATES',
        payload: response.data.Templates,
      });
      dispatch(
        fetchConnectUsers({
          userIds: _.uniq(response.data.Templates.map(template => template.CreatedBy)),
        })
      );
      dispatch({
        type: 'SET_DRIP_TEMPLATES_COUNT',
        payload: response.data.Total,
      });
    });
  };
}

function deleteDripTemplate(templateId, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'deletingDripTemplate',
      })
    );
    dripTemplatesApi.deleteDripTemplate(templateId).then(() => {
      dispatch(searchDripTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'dripTemplateDeleted',
        })
      );
    });
  };
}

function editDripTemplate(templateId, template, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'updatingDripTemplate',
      })
    );
    dripTemplatesApi.putDripTemplate(templateId, template).then(() => {
      dispatch(searchDripTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'dripTemplateUpdated',
        })
      );
    });
  };
}

function duplicateDripTemplate(templateId, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'duplicatingDripTemplate',
      })
    );
    dripTemplatesApi.duplicateDripTemplate(templateId).then(() => {
      dispatch(searchDripTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'dripTemplateDuplicated',
        })
      );
    });
  };
}

function postDripTemplate(template, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'creatingDripTemplate',
      })
    );
    dripTemplatesApi.postDripTemplate(template).then(() => {
      dispatch(searchDripTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'createdDripTemplate',
        })
      );
    });
  };
}

function shareDripTemplate(templateId, shareOptions) {
  let successMessageId = 'shareAryaBotSuccessMessage';
  let loadingMessageId = 'shareAryaLoadingMessage';
  if (shareOptions.isShareWithAll) {
    loadingMessageId = 'shareAryaLoadingMessageWithAll';
  }
  if (shareOptions.isUnshareWithAll) {
    successMessageId = 'shareAryaBotSuccessMessageUnshareAll';
    loadingMessageId = 'shareAryaLoadingMessageUnshareWithAll';
  }
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: loadingMessageId,
      })
    );
    dripTemplatesApi
      .shareDripTemplate(templateId, shareOptions)
      .then(() => {
        dispatch({
          type: 'SET_DRIP_TEMPLATE_SHARED_WITH',
          payload: shareOptions,
          templateId,
        });
        dispatch(
          setNotification('SUCCESS', {
            messageId: successMessageId,
          })
        );
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SHARE_TEMPLATE_POST_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(
          setNotification('ERROR', {
            messageId: 'templateSharingFailed',
          })
        );
      });
  };
}

function getDripTemplate(templateId) {
  return async dispatch => {
    dispatch(setDripTemplateFetchApiStatus('INPROGRESS'));
    try {
      const response = await dripTemplatesApi.getDripTemplate(templateId);
      dispatch(setDripTemplateDetails({ templateId, template: response.data }));
      dispatch(setDripTemplateFetchApiStatus('COMPLETED'));
    } catch {
      dispatch(setDripTemplateFetchApiStatus('FAILED'));
    }
  };
}

export {
  getDripTemplates,
  searchDripTemplates,
  postDripTemplate,
  editDripTemplate,
  deleteDripTemplate,
  duplicateDripTemplate,
  getDripTemplate,
  shareDripTemplate,
  clearDripTemplates,
};
