import React from 'react';
import { fetchReportPayload, savedQueryMapper } from '../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import ReportViewContainer from '../../../../Containers/ReportPortalContainer/ReportViewContainer/ReportViewContainer';
import styles from './DEIReports.module.scss';
import TextWithInfoIcon from '../../../Common/TextWithInfoIcon/TextWithInfoIcon';

export default function DEIReports(props) {
  const {
    reportStats,
    getReportStats: fetchReportStats,
    fetchReportStatsApiStatus,
    exportReportApiStatus,
    savedQueryConfig,
    onSaveQuerySuccess,
    setFetchReportStatsApiStatus,
  } = props;

  const getReportStats = (filter, page, pageSize) => {
    const fetchPayloadCriteria = { filter, page, pageSize, reportKey: 'dei' };
    const recruiterReportFetchPayload = fetchReportPayload(fetchPayloadCriteria);

    fetchReportStats(recruiterReportFetchPayload, 'dei');
  };

  React.useEffect(() => {
    try {
      if (savedQueryConfig?.runSavedQueryFlag) {
        const savedQuery = JSON.parse(savedQueryConfig?.queryDetails?.ReportQuery);
        const mappedSavedQuery = savedQueryMapper(savedQuery, savedQueryConfig?.queryDetails?.dynamicReportColumns);
        getReportStats(mappedSavedQuery, 1, 10);
      }
    } catch (err) {
      const status = {
        status: 'FAILED',
      };
      setFetchReportStatsApiStatus(status);
    }
  }, [savedQueryConfig?.runSavedQueryFlag]);

  return (
    <div className={styles.DEIReports}>
      <ReportViewContainer
        fetchReportStatsApiStatus={fetchReportStatsApiStatus}
        title="DEI Report"
        subtitle={
          <TextWithInfoIcon
            text="Report based on DEI Activity during the date(s) given"
            tooltipTitle="Latest results may be delayed 1-2 hours"
          />
        }
        type="dei"
        filterConfig={{ job: false, client: false }}
        reportStats={reportStats}
        getReportStats={getReportStats}
        exportReportApiStatus={exportReportApiStatus}
        savedReportDetails={savedQueryConfig?.queryDetails}
        onSaveQuerySuccess={onSaveQuerySuccess}
      />
    </div>
  );
}
