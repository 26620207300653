import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover, Slider, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SourceLimitPopover.module.scss';
import { updateJobConfig } from '../../Actions/JobActions';
import { getJobsById } from '../../Reducers/JobReducer';
import { getConfig } from '../../Reducers/ConfigReducer';
import InfoIconWithTooltip from '../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import jobMessage from '../JobForm/JobMessages';

export default function SourceLimitPopover({ jobId }) {
  const [sourceLimit, setSourceLimit] = React.useState(0);
  const [isPopoverVisible, setPopoverVisibility] = React.useState(false);
  const dispatch = useDispatch();

  const jobsById = useSelector(state => getJobsById(state));
  const userConfig = useSelector(state => getConfig(state));
  const currentJobDetails = jobsById[jobId] || {};
  const {
    AryaRejectionConfiguration,
    AutomationConfiguration,
    SourcingConfiguration = {},
    updateJobConfigApiStatus,
  } = currentJobDetails;
  const configuredSourceLimit = SourcingConfiguration?.SourceLimit;

  React.useEffect(() => {
    setSourceLimit(configuredSourceLimit);
  }, [configuredSourceLimit]);

  React.useEffect(() => {
    if (updateJobConfigApiStatus === 'COMPLETED') {
      setPopoverVisibility(false);
    }
  }, [updateJobConfigApiStatus]);

  const saveConfig = useCallback(() => {
    const updatedConfig = { AryaRejectionConfiguration, AutomationConfiguration, SourcingConfiguration };
    updatedConfig.SourcingConfiguration.SourceLimit = sourceLimit;
    dispatch(updateJobConfig(jobId, updatedConfig));
  });

  const onChangeSlider = value => {
    if (value >= configuredSourceLimit) {
      setSourceLimit(value);
    }
  };

  const minSliderValue = 1;
  const maxSliderValue = userConfig.MaxSourceLimit ?? 3 * userConfig.DefaultSourceLimit;

  const sliderMarks = {
    [minSliderValue]: minSliderValue,
    [maxSliderValue]: maxSliderValue,
  };

  const text = (
    <span>
      <FormattedMessage {...jobMessage.updateSourcingLimit} />
    </span>
  );
  const content = (
    <div className={styles.popoverContent}>
      <p>
        <FormattedMessage {...jobMessage.sourcingConfigUpdateLabel} />
      </p>
      <h4>
        <FormattedMessage {...jobMessage.singleListCandidateSourceLimit} />{' '}
        <InfoIconWithTooltip tooltipTitle={<FormattedMessage {...jobMessage.sourceLimitDescription} />} />
      </h4>
      <Slider
        value={sourceLimit}
        onChange={onChangeSlider}
        className={styles.sourcingLimitSlider}
        min={minSliderValue}
        max={maxSliderValue}
        marks={sliderMarks}
        tooltipVisible
        getTooltipPopupContainer={trigger => trigger.parentNode}
      />
      <div className={styles.popoverContentFooter}>
        <Button
          key="CancelButton"
          className={styles.cancelButton}
          shape="round"
          onClick={() => setPopoverVisibility(false)}
        >
          <FormattedMessage {...jobMessage.cancelButton} />
        </Button>
        <Button
          key="SaveButton"
          shape="round"
          type="primary"
          onClick={saveConfig}
          loading={updateJobConfigApiStatus === 'INPROGRESS'}
          disabled={configuredSourceLimit === sourceLimit}
        >
          <FormattedMessage {...jobMessage.saveButtonText} />
        </Button>
      </div>
    </div>
  );
  return (
    <Popover
      overlayClassName={styles.sourceLimitPopover}
      placement="bottom"
      title={text}
      content={content}
      trigger="click"
      visible={isPopoverVisible}
      onVisibleChange={visibility => setPopoverVisibility(visibility)}
    >
      <div className={`${styles.sourceLimitWrapper} ${isPopoverVisible ? styles.boldText : ''}`}>
        <FormattedMessage {...jobMessage.sourceLimitLabel} />
      </div>
    </Popover>
  );
}
