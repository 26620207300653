import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover, Button } from 'antd';
import { UserBulbIcon, InfoIcon } from '../../Icons/AryaIcons';
import './CandidateRejectReasonsPopover.scss';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

const CandidateRejectReasonsPopover = props => {
  const {
    content,
    children,
    title,
    showWarningMessage = false,
    onApplyRejectReason,
    onClearRejectReason,
    onResetRejectReason,
    rejectReasonKey,
    size,
    okButtonText,
    cancelButtonText,
    isCancelButtonDisabled,
  } = props;
  const [popoverVisibility, setPopoverVisibility] = React.useState(false);
  const getCandidateRejectReasonWarningMessage = () => {
    const optionsText = rejectReasonKey === 'MORE_REASONS' ? <FormattedMessage {...messages.OtherReasonsLabel}/> : <FormattedMessage {...messages.manualAddedValuesLabel}/>;
    return <FormattedMessage {...messages.rejectReasonsWarningLabel} values={{optionsText}}/>;
  };
  return (
    <Popover
      overlayClassName={`candidate-reject-reasons-popover-overlay ${size}`}
      overlayStyle={{ zIndex: 2223 }}
      visible={popoverVisibility}
      autoAdjustOverflow={false}
      getPopupContainer={trigger => trigger.parentNode}
      placement="bottom"
      title={
        <div className="candidate-reject-reasons-popover-title-wrapper">
          <div className="candidate-reject-reasons-popover-title-intel-icon">
            <UserBulbIcon />
          </div>
          <div className="candidate-reject-reasons-popover-title-text"><FormattedMessage {...messages[title.replace(/\s+/g, '')]}/></div>
        </div>
      }
      onVisibleChange={visibility => {
        if (!visibility) onResetRejectReason(rejectReasonKey);
        setPopoverVisibility(visibility);
      }}
      content={
        <div className="candidate-reject-reason-content-wrapper">
          {content}
          {showWarningMessage ? (
            <div className="reject-reason-warning-message">
              <div className="reject-reasons-popover-info-icon">
                <InfoIcon />
              </div>
              {getCandidateRejectReasonWarningMessage(rejectReasonKey)}
            </div>
          ) : null}
          <div className="candidate-reject-reasons-popover-footer">
            <div className="candidate-reject-reasons-footer-buttons">
              <Button
                size="small"
                onClick={() => {
                  onClearRejectReason(rejectReasonKey);
                  setPopoverVisibility(false);
                }}
                disabled={isCancelButtonDisabled}
              >
                {cancelButtonText}
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  onApplyRejectReason(rejectReasonKey);
                  setPopoverVisibility(false);
                }}
              >
                {okButtonText}
              </Button>
            </div>
          </div>
        </div>
      }
      trigger="click"
      autoAdjustOverflow
      placement="bottom"
    >
      {children}
    </Popover>
  );
};

export default CandidateRejectReasonsPopover;
