import React from 'react';
import { Button, Tooltip } from 'antd';
import ListItemMoreMenuButton from './ListItemMoreMenuButton/ListItemMoreMenuButton';
import styles from './ReportQueriesListItem.module.scss';
import { getEventNameByReportType } from '../../../../../../../Analytics/Report/ReportEventUtils';
import { getReportViewType } from '../../../../../../../Utils/ReportPortalUtils/ReportPayloadUtils';

export default function ReportQueriesListItem(props) {
  const { queryDetails, onRunSavedQuery, onEditSavedQuery, onDeleteSavedQuery, onShareSavedQuery } = props;

  const onRunQuery = () => {
    onRunSavedQuery(queryDetails);
  };

  const onEditQuery = () => {
    onEditSavedQuery(queryDetails);
  };

  const onDeleteQuery = () => {
    onDeleteSavedQuery(queryDetails);
  };

  const onShareQuery = () => {
    onShareSavedQuery(queryDetails);
  };

  const reportType = getReportViewType(queryDetails?.ReportType);

  return (
    <div className={styles.reportQueriesListItem}>
      <Tooltip title={queryDetails?.ReportName} placement="topLeft">
        <div className={styles.queryName}>{queryDetails?.ReportName}</div>
      </Tooltip>
      <div className={styles.queryType}>{`${
        queryDetails?.ReportType === 'RecruiterByJob' ? 'Recruiter By Job' : queryDetails?.ReportType
      } Report`}</div>
      <div className={styles.listItemActions}>
        <Button
          type="primary"
          shape="round"
          size="small"
          onClick={onRunQuery}
          sk-event-name={getEventNameByReportType(reportType, 'view')}
        >
          Run
        </Button>
        <ListItemMoreMenuButton
          onEdit={onEditQuery}
          onDelete={onDeleteQuery}
          onShare={onShareQuery}
          type={reportType}
        />
      </div>
    </div>
  );
}
