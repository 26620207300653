import React, { Component } from 'react';
import { Icon, Tooltip } from 'antd';
import _ from 'lodash';
import { AddIcon, AutoRejectIcon, DeleteIcon } from '../../Icons/AryaIcons';
import styles from './CandidateShortlistReject.module.scss';
import eventTypes from '../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import DeleteCandidateModal from '../DeleteCandidateModal/DeleteCandidateModal';
import { isCandidateStateChangeAllowed, getCandidateCardCursorStyle } from '../../Utils/CandidateListUtils';
import CandidateTransfer from '../CandidateTransfer/CandidateTransfer';

export default class CandidateShortListReject extends Component {
  static getDerivedStateFromProps(props) {
    const { candidateShortlistedStatusUpdate, candidateRejectedStatusUpdate } = props;
    const isCandidateStatusChangeAllowed =
      candidateShortlistedStatusUpdate === 'INPROGRESS' || candidateRejectedStatusUpdate === 'INPROGRESS';
    return { isCandidateStatusChangeAllowed };
  }

  changeCandidateShortlistedStatus = () => {
    const { isCandidateStatusChangeAllowed } = this.state;
    const {
      onCandidateStatusChange,
      candidate,
      isPaidJobServiceEnabled,
      candidateContext = 'job',
      activeTab,
    } = this.props;
    if (isCandidateStateChangeAllowed(candidate)) {
      const isShortListRjectAllowed = this.getIsShortListRjectAllowed();
      let status = isPaidJobServiceEnabled ? candidate.PublishStatus : candidate.Status;
      if (candidateContext === 'segment' && status === 'Shortlisted') {
        status = activeTab === 'rejected' ? 'Rejected' : status;
      }
      const modifiedStatus = status === 'Shortlisted' ? 'Sourced' : 'Shortlisted';
      if (!isCandidateStatusChangeAllowed && isShortListRjectAllowed)
        onCandidateStatusChange({
          status: modifiedStatus,
          candidate,
        });
    }
  };

  getIsShortListRjectAllowed = () => {
    const { featureToggleList } = this.props;
    return _.get(featureToggleList, ['ShortlistReject', 'IsAllowed'], true);
  };

  handleCandidateRejection = newCandidateStatus => {
    const { isFeedbackRequired, candidate, size, onCandidateReject, onCandidateStatusChange } = this.props;
    if (isFeedbackRequired) onCandidateReject(candidate, size);
    else
      onCandidateStatusChange(
        {
          status: newCandidateStatus,
          candidate,
        },
        size
      );
  };

  changeCandidateRejectedStatus = () => {
    const { isCandidateStatusChangeAllowed } = this.state;
    const { candidate, isPaidJobServiceEnabled, candidateContext = 'job' } = this.props;

    if (isCandidateStateChangeAllowed(candidate)) {
      const isShortListRjectAllowed = this.getIsShortListRjectAllowed();
      const newCandidateStatus = (() => {
        let currentStatus;
        if (isPaidJobServiceEnabled) {
          currentStatus = candidate.PublishStatus;
        } else {
          currentStatus = candidate.Status;
        }
        return currentStatus === 'Rejected' ? 'Sourced' : 'Rejected';
      })();

      if (!isCandidateStatusChangeAllowed && isShortListRjectAllowed) {
        if (candidateContext === 'segment' && candidate.Status?.toLowerCase() === 'shortlisted')
          this.setState({ isDeleteModalVisible: true });
        else this.handleCandidateRejection(newCandidateStatus);
      }
    }
  };

  getCandidateStatus = () => {
    const { candidate, isPaidJobServiceEnabled } = this.props;
    if (isPaidJobServiceEnabled) {
      return {
        isShortlisted: candidate.PublishStatus === 'Shortlisted',
        isRejected: candidate.PublishStatus === 'Rejected',
      };
    }
    return {
      isShortlisted: candidate.Status === 'Shortlisted',
      isRejected: candidate.Status === 'Rejected',
      isAutoRejected:
        candidate?.Status?.toLowerCase() === 'rejected' && candidate?.RecommendedMode?.toLowerCase() === 'auto',
    };
  };

  getCandidateStatusIconData = () => {
    const { candidateRejectedStatusUpdate, candidateShortlistedStatusUpdate } = this.props;

    const likeIconProps = {
      theme: 'outlined',
      className: 'candidate-like-icon',
    };
    const dislikeIconProps = {
      theme: 'outlined',
      className: 'candidate-dislike-icon',
    };
    const { isShortlisted, isRejected } = this.getCandidateStatus();

    if (isShortlisted) {
      likeIconProps.theme = 'filled';
    } else if (isRejected) {
      dislikeIconProps.theme = 'filled';
    }

    if (candidateRejectedStatusUpdate === 'FAILED') {
      dislikeIconProps.theme = 'outlined';
    }

    if (candidateShortlistedStatusUpdate === 'FAILED') {
      likeIconProps.theme = 'outlined';
    }

    return {
      likeIconProps,
      dislikeIconProps,
    };
  };

  getRejectIcon = () => {
    const {
      candidateRejectedStatusUpdate,
      rejectTooltipTitle,
      tooltipPlacement = 'top',
      candidateContext = 'job',
      isCandidateViewHeaderVisible = true,
    } = this.props;
    const { dislikeIconProps } = this.getCandidateStatusIconData();
    const { isAutoRejected } = this.getCandidateStatus();
    const isShortListRjectAllowed = this.getIsShortListRjectAllowed();
    const rejectIconFontSize = isCandidateViewHeaderVisible ? '24px' : '17px';
    const rejectIconStyle = { fontSize: rejectIconFontSize };
    if (!isShortListRjectAllowed) {
      rejectIconStyle.color = 'rgba(7,16,26,0.2)';
      rejectIconStyle.cursor = 'not-allowed';
    }

    if (candidateRejectedStatusUpdate?.toUpperCase() === 'INPROGRESS')
      return <Icon style={rejectIconStyle} type="loading" {...dislikeIconProps} />;
    if (candidateRejectedStatusUpdate?.toUpperCase() !== 'INPROGRESS' && isAutoRejected && candidateContext === 'job')
      return (
        <Tooltip title="Auto rejected">
          <AutoRejectIcon
            className={isCandidateViewHeaderVisible ? styles.autoRejectIcon : styles.minimizedRejectIcon}
            styles={{ fontSize: '20px' }}
          />
        </Tooltip>
      );
    if (candidateRejectedStatusUpdate?.toUpperCase() !== 'INPROGRESS' && candidateContext === 'segment')
      return (
        <Tooltip
          placement={tooltipPlacement}
          title={rejectTooltipTitle}
          overlayClassName={styles.candidateShortListRejectTooltip}
        >
          <DeleteIcon style={{ fontSize: '24px' }} {...dislikeIconProps} />
        </Tooltip>
      );
    return (
      <Tooltip
        placement={tooltipPlacement}
        title={rejectTooltipTitle}
        overlayClassName={styles.candidateShortListRejectTooltip}
      >
        <Icon style={{ fontSize: '24px' }} type="dislike" {...dislikeIconProps} />
      </Tooltip>
    );
  };

  onDeleteModalOk = () => {
    this.setState({ isDeleteModalVisible: false });
    this.handleCandidateRejection('Rejected');
  };

  onDeleteModalcancel = () => {
    this.setState({ isDeleteModalVisible: false });
  };

  render() {
    const {
      candidateShortlistedStatusUpdate,
      showShortlistIcon = true,
      showRejectIcon = true,
      shortlistTooltipTitle,
      tooltipPlacement = 'top',
      candidate,
      candidateContext = 'job',
      showCandidateTransfer,
      isCandidateTransferModalVisible,
      jobId,
      setCandidateTransferModalVisibility,
      onClickTransferIcon,
      candidateIds,
      isCandidateViewHeaderVisible = true,
      selectedSubSegmentId,
      version,
    } = this.props;
    const { isDeleteModalVisible } = this.state;
    const { likeIconProps } = this.getCandidateStatusIconData();
    const isShortListRjectAllowed = this.getIsShortListRjectAllowed();
    const { isShortlisted, isRejected } = this.getCandidateStatus();
    const shortlistIconStyle = { fontSize: '24px' };
    if (!isShortListRjectAllowed) {
      shortlistIconStyle.color = 'rgba(7,16,26,0.2)';
      shortlistIconStyle.cursor = 'not-allowed';
    }
    const candidateSelectIconDivStyle = () => {
      let height = '100%';
      if (showCandidateTransfer && showRejectIcon) height = '33%';
      else if (showCandidateTransfer || showRejectIcon) height = '50%';
      return { height };
    };
    const candidateRejectIconDivStyle = () => {
      let height = '100%';
      if (showCandidateTransfer && showShortlistIcon) height = '33%';
      else if (showCandidateTransfer || showShortlistIcon) height = '50%';
      const candidateRejectIconDiv = { height };
      Object.assign(candidateRejectIconDiv, getCandidateCardCursorStyle(candidate.Status, candidate.PublishStatus));
      return candidateRejectIconDiv;
    };
    const candidateTransferIconDivStyle = () => {
      return { height: showShortlistIcon ? '33%' : '50%' };
    };
    const shortlistIconFontSize = isCandidateViewHeaderVisible ? '24px' : '17px';
    const shortlistIconClassName = isCandidateViewHeaderVisible
      ? 'candidate-card-select-candidate'
      : 'minimized-candidate-card-select-candidate';
    const rejectIconClassName = isCandidateViewHeaderVisible
      ? 'candidate-card-reject-candidate'
      : 'minimized-candidate-card-reject-candidate';
    const candidateTransferClassName = isCandidateViewHeaderVisible
      ? 'candidate-card-transfer-candidate'
      : 'minimized-candidate-card-transfer-candidate';
    const isSegmentCandidateShortlistingInprogress =
      candidateContext === `segment` && candidateShortlistedStatusUpdate === 'INPROGRESS';
    const isSegmentCandidateShortlisted =
      candidateContext === `segment` && candidateShortlistedStatusUpdate !== 'INPROGRESS';
    return (
      <div
        className={
          isCandidateViewHeaderVisible
            ? 'candidate-card-candidate-reject-select'
            : 'minimized-candidate-card-candidate-reject-select'
        }
      >
        {showShortlistIcon ? (
          <div
            className={shortlistIconClassName}
            role="button"
            tabIndex={0}
            onClick={this.changeCandidateShortlistedStatus}
            onKeyPress={this.changeCandidateShortlistedStatus}
            style={candidateSelectIconDivStyle()}
            sk-event-name={
              isShortlisted
                ? getEventNameByCandidateStatus(eventTypes.candidate.candidateShortlist.unshortlistCandidate, candidate)
                : getEventNameByCandidateStatus(eventTypes.candidate.candidateShortlist.shortlistCandidate, candidate)
            }
          >
            <Tooltip
              placement={tooltipPlacement}
              title={shortlistTooltipTitle}
              overlayClassName={styles.candidateShortListRejectTooltip}
            >
              {candidateContext === `job` ? (
                <Icon
                  style={{ fontSize: '24px' }}
                  type={candidateShortlistedStatusUpdate === 'INPROGRESS' ? 'loading' : 'like'}
                  {...likeIconProps}
                />
              ) : null}
              {isSegmentCandidateShortlistingInprogress ? (
                <Icon style={{ fontSize: shortlistIconFontSize }} type="loading" {...likeIconProps} />
              ) : null}
              {isSegmentCandidateShortlisted ? (
                <AddIcon style={{ fontSize: shortlistIconFontSize }} {...likeIconProps} />
              ) : null}
            </Tooltip>
          </div>
        ) : null}
        {showRejectIcon ? (
          <div
            className={rejectIconClassName}
            role="button"
            tabIndex={0}
            onKeyPress={this.changeCandidateRejectedStatus}
            onClick={this.changeCandidateRejectedStatus}
            style={candidateRejectIconDivStyle()}
            sk-event-name={
              isRejected
                ? getEventNameByCandidateStatus(eventTypes.candidate.candidateReject.unrejectCandidate, candidate)
                : getEventNameByCandidateStatus(eventTypes.candidate.candidateReject.rejectCandidate, candidate)
            }
          >
            {this.getRejectIcon()}
          </div>
        ) : null}
        {showCandidateTransfer ? (
          <div className={candidateTransferClassName} style={candidateTransferIconDivStyle()}>
            <CandidateTransfer
              isCandidateTransferModalVisible={isCandidateTransferModalVisible}
              candidateTransferIconClassName="candidate-transfer-icon"
              jobId={jobId}
              setCandidateTransferModalVisibility={setCandidateTransferModalVisibility}
              onClickTransferIcon={onClickTransferIcon}
              candidateIds={candidateIds}
              invalidCandidateCallbackMessage="Please select a candidate"
              transferType="single"
              version={version}
              candidateContext={candidateContext}
              selectedSubSegmentId={selectedSubSegmentId}
            />
          </div>
        ) : null}

        <DeleteCandidateModal
          isVisible={isDeleteModalVisible}
          onCancel={this.onDeleteModalcancel}
          onOk={this.onDeleteModalOk}
        />
      </div>
    );
  }
}
