import * as React from 'react';
import { Icon } from 'antd';

const TrophyAwardSvg = props => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="trophy-award">
      <path id="Vector" d="M13.9333 9.80834L15.2167 14.6667L11 11.1833L6.78334 14.6667L8.06667 9.90001L4.21667 6.69168L9.16667 6.41668L11 1.83334L12.8333 6.41668L17.7833 6.69168L13.9333 9.80834ZM12.8333 17.4167H11.9167V14.6667L11 13.75L10.0833 14.6667V17.4167H9.16667C8.15834 17.4167 7.33334 18.2417 7.33334 19.25V20.1667H14.6667V19.25C14.6667 18.2417 13.8508 17.4167 12.8333 17.4167Z" fill="#007BFF" />
    </g>
  </svg>

);
const TrophyAwardIcon = props => (
  <Icon component={customProps => <TrophyAwardSvg {...props} {...customProps} />} {...props} />
);
export default TrophyAwardIcon;
