import { Icon } from 'antd';
import * as React from 'react';

const SendReplyButtonSvg = props => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.000152588" width="32" height="32" rx="6" fill="#13C26B" />
      <path
        d="M22.2943 7.71343L9.27796 11.5945C8.29499 11.8874 8.22625 13.2527 9.17437 13.6429L13.9774 15.6094C14.166 15.6866 14.3819 15.6414 14.5242 15.4955L17.4849 12.4597C17.8754 12.0692 18.5086 12.0692 18.8991 12.4597C19.2896 12.8502 19.2896 13.4834 18.8991 13.8739L15.863 16.8347C15.7171 16.977 15.6722 17.1936 15.7494 17.3822L17.7173 22.186C18.1071 23.1321 19.4706 23.0635 19.763 22.0825L23.6462 9.06493C23.8932 8.23781 23.1223 7.46714 22.2943 7.71343Z"
        fill="white"
      />
      <path
        d="M7.81271 17.6017C7.91012 17.6992 8.03757 17.7479 8.16489 17.7479C8.29284 17.7479 8.42029 17.6992 8.5172 17.6017L10.1022 16.0172C10.2971 15.8228 10.2971 15.5072 10.1022 15.3129C9.90773 15.118 9.59264 15.118 9.3977 15.3129L7.81271 16.8974C7.61777 17.0918 7.61777 17.4075 7.81271 17.6017Z"
        fill="white"
      />
      <path
        d="M9.44813 21.8226C9.3208 21.8226 9.19335 21.7739 9.09594 21.6764C8.901 21.4821 8.901 21.1665 9.09594 20.9721L12.0775 17.9914C12.2725 17.7965 12.5876 17.7965 12.782 17.9914C12.977 18.1857 12.977 18.5013 12.782 18.6957L9.80031 21.677C9.70278 21.7744 9.57545 21.8226 9.44813 21.8226Z"
        fill="white"
      />
      <path
        d="M13.1717 22.9593C13.2691 23.0568 13.3965 23.1055 13.5238 23.1055C13.6518 23.1055 13.7792 23.0568 13.8762 22.9593L15.4611 21.3748C15.6561 21.1804 15.6561 20.8647 15.4611 20.6705C15.2667 20.4756 14.9516 20.4756 14.7567 20.6705L13.1717 22.255C12.9767 22.4494 12.9767 22.765 13.1717 22.9593Z"
        fill="white"
      />
    </svg>
  );
};

const SendReplyButtonIcon = props => <Icon component={() => SendReplyButtonSvg(props)} {...props} />;
export default SendReplyButtonIcon;
export { SendReplyButtonIcon as CreateNewThreadButtonIcon };
