import React from 'react';
import { Popover, Icon, Tooltip } from 'antd';
import StarIcon from '../../../../../Icons/StarIcon';
import AdditionalSkillsContent from './AdditionalSkillsContent';
import styles from '../SkillsSection.module.scss';
import eventTypes from '../../../../../Analytics/EventTypes';
import CheckboxGreenTick from '../../../../../Icons/CheckboxGreenTick';
import CheckboxWithoutTick from '../../../../../Icons/CheckboxWithoutTick';
import StarIconWithColor from '../../../../../Icons/StarIconWithColor';
import StarIconWithoutColor from '../../../../../Icons/StarIconWithoutColor';

const SkillTag = ({
  skill,
  handleProminentSkillClick,
  handleSkillClick,
  handleTilesIconHover,
  addNewSkill,
  handleAliasSkillSelection,
  handleAliasSkillSectionCheckboxClick,
  skillIntelApiStatus,
  isTitlesExists,
}) => {
  const { id, isProminent, name, isPreferred } = skill;

  const handleVisibleChange = visible => {
    if (visible && !(skill.aliasSkills?.length || skill.alternateSkills?.length) && isTitlesExists)
      handleTilesIconHover(id, name);
  };

  return (
    <div
      className={`${styles.skillTag} ${isPreferred ? styles.selected : styles.unselected} ${
        isProminent ? styles.prominent : ''
      }`}
    >
      <div
        className={styles.skillTagItem}
        style={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '8px' }}
        role="button"
        tabIndex={0}
        onClick={() => handleSkillClick(id, !isPreferred)}
        onKeyPress={() => handleSkillClick(id, !isPreferred)}
        sk-event-name={
          isPreferred
            ? eventTypes.job.manualSearch.deselectSuggestedSkills
            : eventTypes.job.manualSearch.selectSuggestedSkills
        }
      >
        {isPreferred ? <CheckboxGreenTick /> : <CheckboxWithoutTick />}
        <Tooltip title={name} placement="topLeft">
          <span className={styles.jobTitleTag}> {name} </span>
        </Tooltip>
      </div>
      <div
        className={styles.skillTagItem}
        role="button"
        tabIndex={0}
        onClick={() => handleProminentSkillClick(id, !isProminent)}
        onKeyPress={() => handleProminentSkillClick(id, !isProminent)}
        sk-event-name={
          isProminent ? eventTypes.job.manualSearch.unmarkProminent : eventTypes.job.manualSearch.markProminent
        }
      >
        {isProminent ? (
          <StarIconWithColor sk-event-name={eventTypes.job.manualSearch.unmarkProminent} />
        ) : (
          <StarIconWithoutColor sk-event-name={eventTypes.job.manualSearch.markProminent} />
        )}
      </div>
      <Popover
        content={
          <AdditionalSkillsContent
            aliasSkills={skill.aliasSkills}
            alternateSkills={skill.alternateSkills}
            skillIntelApiStatus={skillIntelApiStatus}
            addNewSkill={addNewSkill}
            handleAliasSkillSelection={handleAliasSkillSelection}
            handleAliasSkillSectionCheckboxClick={handleAliasSkillSectionCheckboxClick}
            isTitlesExists={isTitlesExists}
            id={id}
          />
        }
        placement="bottomLeft"
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0.5}
        onVisibleChange={handleVisibleChange}
        trigger={['hover', 'click']}
        destroyTooltipOnHide
      >
        <div className={styles.skillTagItem}>
          <Icon type="more" />
        </div>
      </Popover>
    </div>
  );
};

export default React.memo(SkillTag);
