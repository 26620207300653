import React from 'react';
import AvailableCredit from '../../Components/ContactSettings/AvailableCredit';
import { getAvailableCredits } from '../../Utils/CreditInfoUtils';

const AvailableCreditsContainer = props => {
  const {
    creditTypes,
    fetchAvailableCreditsApiStatus,
    getShouldHideAddCreditsForm,
    handleSendRequest,
    requestForCreditsApiStatus,
    isSendRequestButtonDisabled,
    validateCredits,
    availableCredits,
    creditsRef,
  } = props;

  return (
    <div className="availableCreditsInfo" id="credits-info" ref={creditsRef}>
      {creditTypes.map(creditType => (
        <div className="credits-settings" key={creditType.Id}>
          <AvailableCredit
            fetchAvailableCreditsApiStatus={fetchAvailableCreditsApiStatus}
            displayAmount={getAvailableCredits(availableCredits, creditType.Id)}
            getShouldHideAddCreditsForm={getShouldHideAddCreditsForm}
            handleSendRequest={handleSendRequest}
            requestForCreditsApiStatus={requestForCreditsApiStatus}
            isSendRequestButtonDisabled={isSendRequestButtonDisabled}
            validateCredits={validateCredits}
            creditName={creditType.Name}
            creditType={creditType.Id}
          />
        </div>
      ))}
    </div>
  );
};

export default AvailableCreditsContainer;
