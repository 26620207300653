import React from 'react';
import _, { debounce } from 'lodash';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import NotesWrapper from '../../CompoundComponents/NotesWrapper/NotesWrapper';
import NotesSubjectField from '../../CompoundComponents/NotesWrapper/NotesSubjectField';
import NotesEditorField from '../../CompoundComponents/NotesWrapper/NotesEditorField';
import NotesSaveButton from '../../CompoundComponents/NotesWrapper/NotesSaveButton';
import NotesCard from '../NotesCard/NotesCard';
import styles from './JobNotes.module.scss';
import NotesCancelButton from '../../CompoundComponents/NotesWrapper/NotesCancelButton';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function JobNotes(props) {
  const {
    notesData,
    getAllJobNotes,
    jobId,
    createJobNote,
    deleteJobNote,
    updateJobNote,
    form,
    jobNoteFetchApiStatus,
    jobNoteDeleteApiStatuses,
    jobNoteAddButtonApiStatus,
    fetchTagsForCandidate,
    candidateSuggestedTags,
    candidateSuggestedTagsCount,
    addTagForJobNote,
    tagCloseApiStatus,
    deleteTagFromJobNote,
    setNotification,
    suggestedTagsApiStatus,
  } = props;
  const [isSave, setSave] = React.useState(true);
  const [content, setContent] = React.useState('');
  const [editNote, setEditNote] = React.useState({});
  const [allNotes, setAllNotes] = React.useState([]);
  const [invalidDescriptionErrorMessage, setInvalidDescriptionErrorMessage] = React.useState('');
  const [isDescriptionWarningSuppressed, setDescriptionWarningSuppressed] = React.useState(false);
  const createNoteFormRef = React.useRef(null);

  React.useEffect(() => {
    getAllJobNotes({ jobId });
  }, []);

  React.useEffect(() => {
    if (notesData) {
      const tempNotes = _.sortBy(notesData, note => -1 * new Date(note.CreatedDate));
      setAllNotes(tempNotes);
    }
  }, [notesData]);

  const handleScroll = ref => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const clearForm = () => {
    setDescriptionWarningSuppressed(true);
    setSave(true);
    form.resetFields();
    setEditNote({});
    setInvalidDescriptionErrorMessage('');
    setContent(undefined);
    setTimeout(() => setDescriptionWarningSuppressed(false), 0);
  };

  const onEditorContentChange = ({ htmlContent, plainTextContent }) => {
    setContent(htmlContent);
    if (plainTextContent?.trim() || isDescriptionWarningSuppressed) setInvalidDescriptionErrorMessage('');
    else setInvalidDescriptionErrorMessage(<FormattedMessage {...message.pleaseEnterDescription} />);
  };

  const onSave = async values => {
    const payload = {
      Description: values?.NotesEditor?.htmlContent,
      SubjectLine: values?.NotesSubject,
    };
    const response = await createJobNote({ jobId, note: payload });
    if (response.isSuccess) {
      clearForm();
    }
  };

  const onUpdate = async values => {
    const payload = {
      Description: values?.NotesEditor?.htmlContent,
      SubjectLine: values?.NotesSubject,
      Context: editNote.Context,
    };
    const response = await updateJobNote({ jobId, noteId: editNote.Id, note: payload });
    if (response.isSuccess) {
      clearForm();
    }
  };

  const onSubmit = values => {
    form.validateFields(['NotesSubject'], { force: true });
    if (!values?.NotesEditor?.plainTextContent?.trim()) {
      setInvalidDescriptionErrorMessage(<FormattedMessage {...message.pleaseEnterDescription} />);
      return;
    }
    form.validateFields(['NotesSubject'], { force: true }).then(() => {
      if (isSave) {
        onSave(values);
      } else {
        onUpdate(values);
      }
    });
  };

  const onDelete = noteId => {
    deleteJobNote({ jobId, noteId });
  };

  const onEdit = note => {
    setSave(false);
    const cloneAllNotes = allNotes.filter(noteCard => noteCard?.Id !== note?.Id);
    setAllNotes(cloneAllNotes);
    setEditNote(note);
    form.setFieldsValue({ NotesSubject: note.SubjectLine });
    setContent(note.Description);
    handleScroll(createNoteFormRef);
  };
  const debounceFetchSuggestedTags = React.useCallback(
    debounce(nextValue => fetchTagsForCandidate(nextValue), 600),
    []
  );
  const onTagChange = value => {
    debounceFetchSuggestedTags({
      searchTerm: value,
      from: 0,
      size: 20,
      type: 'notes',
    });
  };
  const onLoadMore = payload => {
    fetchTagsForCandidate(payload);
  };
  const onTagAdd = ({ noteId, tag }) => {
    addTagForJobNote({ jobId, noteId, tag });
  };

  const onTagClose = ({ tag, noteId }) => {
    deleteTagFromJobNote({ jobId, tag, noteId });
  };

  const onCancelNote = async () => {
    const tempNotes = _.sortBy(notesData, note => -1 * new Date(note.CreatedDate));
    setAllNotes(tempNotes);
    clearForm();
  };

  return (
    <div>
      <div className={styles.segmentNote} ref={createNoteFormRef}>
        <Form>
          <NotesWrapper
            editorBody={content}
            onEditorContentChange={onEditorContentChange}
            onSubmit={onSubmit}
            form={form}
            addButtonApiStatus={jobNoteAddButtonApiStatus}
            invalidDescriptionErrorMessage={invalidDescriptionErrorMessage}
            onCancel={onCancelNote}
          >
            <div className={styles.noteSubject}>
              <NotesSubjectField />
            </div>
            <div className={styles.noteEditor}>
              <NotesEditorField />
            </div>
            <div className={styles.noteSave}>
              <NotesCancelButton />
              <NotesSaveButton buttonName={<FormattedMessage {...message.AddLabel} />} />
            </div>
          </NotesWrapper>
        </Form>
      </div>
      <div className={styles.noteView}>
        <NotesCard
          allNotes={allNotes}
          getAllJobNotes={getAllJobNotes}
          jobId={jobId}
          onDelete={onDelete}
          onEdit={onEdit}
          noteFetchApiStatus={jobNoteFetchApiStatus}
          noteDeleteApiStatuses={jobNoteDeleteApiStatuses}
          suggestedTags={candidateSuggestedTags}
          totalSuggestedTagsCount={candidateSuggestedTagsCount}
          onTagChange={onTagChange}
          onLoadMore={onLoadMore}
          onTagAdd={onTagAdd}
          onTagClose={onTagClose}
          tagCloseApiStatus={tagCloseApiStatus}
          setNotification={setNotification}
          suggestedTagsApiStatus={suggestedTagsApiStatus}
        />
      </div>
    </div>
  );
}

export default Form.create()(JobNotes);
export { JobNotes as JobNotesWithForm };
