import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Menu, Row, Col,Form } from 'antd';
import PropTypes from 'prop-types';
import TemplateRow from '../TemplateRow';
import ShareTemplate from '../../ShareTemplate';
import Collaborators from '../../../Collaborators/Collaborators';
import eventTypes from '../../../../Analytics/EventTypes';
import { connectTemplateNameByType } from '../../../../Utils/ConnectUtils';
import connectTemplateMessage from '../../../../Containers/BaseTemplates/ConnectTemplatesMessages';

 class UserEmailTemplateRow extends TemplateRow {
  constructor(props) {
    super(props);
    this.state = {
      showUsersPopup: false,
    };
    this.editTemplate = this.editTemplate.bind(this);
    this.duplicateTemplate = this.duplicateTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.sharePopUp = this.sharePopUp.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onShare = this.onShare.bind(this);
  }

  onShare(shareOptions) {
    const { shareEmailTemplate, templateId } = this.props;
    shareEmailTemplate(templateId, shareOptions);
    this.setState({
      showUsersPopup: false,
    });
  }

  render() {
    const {
      title,
      subject,
      updatedTime,
      usersByGuId,
      maxCount,
      fetchUsersForShare,
      userListForShareTemplate,
      CurrentUser,
      sharedUsers,
      isPublic,
      intl
    } = this.props;
    const currentUserEmailId = CurrentUser.email;
    const { showUsersPopup } = this.state;
    const actionsMenu = (
      <Menu>
        <Menu.Item onClick={this.duplicateTemplate} sk-event-name={eventTypes.connect.emailTemplates.duplicate}>
          <span sk-event-name={eventTypes.connect.emailTemplates.duplicate}>
            <FormattedMessage {...connectTemplateMessage.duplicateLabel} />
          </span>
        </Menu.Item>

        <Menu.Item onClick={this.sharePopUp} sk-event-name={eventTypes.connect.emailTemplates.share}>
          <span sk-event-name={eventTypes.connect.emailTemplates.share}>
            <FormattedMessage {...connectTemplateMessage.shareLabel} />
          </span>
        </Menu.Item>

        <Menu.Item onClick={this.deleteTemplate} sk-event-name={eventTypes.connect.emailTemplates.delete}>
          <span className="delete-option-text" sk-event-name={eventTypes.connect.emailTemplates.delete}>
            <FormattedMessage {...connectTemplateMessage.deleteLabel} />
          </span>
        </Menu.Item>
      </Menu>
    );
    const collaborators = isPublic ? userListForShareTemplate : sharedUsers;
    const collaboratorslist = collaborators
      .filter(id => usersByGuId[id])
      .map(id => {
        const FirstName = _.get(usersByGuId, [id, 'FirstName'], '');
        const LastName = _.get(usersByGuId, [id, 'LastName'], '');
        const Name = FirstName.concat(' ', LastName);
        const { Email } = usersByGuId[id];
        return {
          name: Name,
          Id: id,
          Email,
        };
      });

    return (
      <div>
        <Row className="template">
          <Col span={8} onClick={this.editTemplate} className="template-column">
            {this.addSubject(this.getTitleColumn(title), this.getSubject(intl.formatMessage({ ...connectTemplateMessage.subjectLabel }), subject))}
          </Col>
          <Col span={6}>
            <Collaborators
              collaborators={collaboratorslist}
              onAddNew={this.sharePopUp}
              shareSize="Small"
              currentUserEmailId={currentUserEmailId}
              skEventName={eventTypes.connect.emailTemplates.share}
            />
          </Col>
          <Col span={6}>{this.getUpdatedOnColumn(updatedTime)}</Col>

          {/* <Col span={3}>{this.getIsBotIncludedColumn(includesBot)}</Col> */}
          {/* <Col span={4}>{this.getSharedCountColumn(sharedCount)}</Col> */}
          <Col span={3}>{this.getActionsColumn(actionsMenu)}</Col>
        </Row>

        <ShareTemplate
          isPublic={isPublic}
          userGuIds={userListForShareTemplate}
          isVisible={showUsersPopup}
          fetchUsersForShare={fetchUsersForShare}
          onClose={this.onClosePopup}
          sharedUsers={sharedUsers}
          onShare={this.onShare}
          maxCount={maxCount}
          usersByGuId={usersByGuId}
          currentUserEmailId={currentUserEmailId}
          type={connectTemplateNameByType.email}
        />
      </div>
    );
  }
}

UserEmailTemplateRow.propTypes = {
  templateId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  includesBot: PropTypes.bool.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  duplicateTemplate: PropTypes.func.isRequired,
  editTemplate: PropTypes.func.isRequired,
};

export default (injectIntl(Form.create()(UserEmailTemplateRow)));
