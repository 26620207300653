import React from 'react';
import { Icon, Form } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import InputSearch from '../InputSearch/InputSearch';
import './ManualRejectReasons.scss';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

const { Item } = Form;
 function ManualRejectReasons(props) {
  const { selectedReasons, addRejectReason, mismatchTitleText, rejectReasonKey, form, moreReasonsTitleText,intl } = props;
  return (
    <div className="manual-add-reject-reasons-wrapper">
      <div className="manual-add-reject-reason-text"><FormattedMessage {...messages.addMissing} values={{moreReasonsTitleText,mismatchTitle:mismatchTitleText}}/></div>
      <Item>
        {form.getFieldDecorator(`${rejectReasonKey}-Input`)(
          <InputSearch
            className="manual-add-reject-reason-input"
            placeholder={intl.formatMessage({ ...messages.addMismatchTitleText },{mismatchTitleText})}
            enterButton={<Icon type="plus" />}
            onSearch={addRejectReason}
          />
        )}
      </Item>
      <div className="manual-added-reject-reasons"> {selectedReasons}</div>
    </div>
  );
}

export default (injectIntl(Form.create()(ManualRejectReasons)));
export {ManualRejectReasons as ManualRejectReasonsWithoutInjectIntl}
