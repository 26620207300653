import _ from 'lodash';

function mapErrorCodes(error) {
  let errorMessage = `oopsSomethingJustWentWrong`;
  const appCode = _.get(error, ['response', 'data', 'Error', 'Code'], '');
  const statusCode = _.get(error, ['response', 'status']);
  if (statusCode === 404 && appCode.includes('JOB_NOT_FOUND')) {
    errorMessage = 'invalidJobCode';
  }
  return errorMessage;
}

export { mapErrorCodes };
