import React from 'react';
import * as d3 from 'd3';
import { Tooltip,Form } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

function classes(root) {
  const classArray = root.map(node => ({
    className: node.Name,
    value: node.Count,
  }));
  return { children: classArray };
}

function prepareBubbleChatList(list, chartConfig) {
  const { width, height, padding } = chartConfig;
  const bubble = d3
    .pack()
    .size([width, height])
    .padding(padding);
  const root = d3
    .hierarchy(classes(list))
    .sum(d => d.value)
    .sort((a, b) => b.value - a.value);
  bubble(root);
  return root;
}

 function BubbleChat(props) {
  const { chartConfig, list,intl } = props;
  const bubbleChatData = prepareBubbleChatList(list, chartConfig);
  const { width, height, backgroundColor, stroke, strokeWidth, bubbleChartDataValueClass } = chartConfig;
  return (
    <div id="bubble-chart-info">
      {list.length > 0 ? (
        <svg className="bubble" width={width} height={height}>
          {bubbleChatData.children.map((dataPoint, index) => (
            <Tooltip
              key={`key${index.toString()}`}
              title={`${dataPoint.data.className} (${dataPoint.data.value} ${intl.formatMessage({ ...messages.profilesLabel })})`}
            >
              <g className="node" transform={`translate(${dataPoint.x},${dataPoint.y})`}>
                <circle r={dataPoint.r.toString()} fill={backgroundColor} stroke={stroke} strokeWidth={strokeWidth} />
                <text dy="-1em" textAnchor="middle" className={bubbleChartDataValueClass}>
                  {dataPoint.data.value > 10000 ? '10000+' : dataPoint.data.value}
                </text>
                <text dy=".3em" textAnchor="middle">
                  {dataPoint.data.className.substr(0, Math.round(dataPoint.r / 4))}
                </text>
              </g>
            </Tooltip>
          ))}
        </svg>
      ) : null}
    </div>
  );
}

export default (injectIntl(Form.create()(BubbleChat)));
export {BubbleChat as BubbleChatWithoutInjectIntl};
