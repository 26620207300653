import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import deepEqual from 'fast-deep-equal';
import _ from 'lodash';
import { Form } from 'antd';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import ScoutingAgentModal from '../ScoutingAgent/ScoutingAgentModal';
import {
  getIsReactivationAllowed,
  getManualSearchFormValues,
  getManualSearchCriteria,
  geLastPerformedManualSearchTime,
} from '../../../Reducers/ManualSearchReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { getScoutingAgentConfig } from '../../../Reducers/ScoutingAgentReducer';
import { getManualSearchFormMinimizedStatus } from '../../../Reducers/JobReducer';
import ManualSearchContext from './ManualSearchContext';
import { generateManualSearchPayload } from '../../../Utils/ManualSearchUtils';
import * as JobActions from '../../../Actions/JobActions';

const mapDispatchToProps = {
  setIsReactivationAllowedFlag: manualSearchActions.setIsReactivationAllowedFlag,
  updateMinimizeManualSearchStatus: JobActions.setMinimizeManualSearchStatus,
};

const mapStateToProps = (state, props) => ({
  isReactivationAllowed: getIsReactivationAllowed(state, { jobId: props.jobId }),
  manualSearchFormValues: getManualSearchFormValues(state, { jobId: props.jobId }),
  unsavedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' }),
  manualSearchLastPerformedTime: geLastPerformedManualSearchTime(state, { jobId: props.jobId }),
  featureToggleList: getFeatureToggleList(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  isManualSearchFormMinimized: getManualSearchFormMinimizedStatus(state, { jobId: props.jobId }),
});

const handleChangedExperenice = (newState, prevState) => {
  if (!prevState) {
    return false;
  }
  if (!prevState?.MaxExperience && !prevState?.MinExperience) {
    return !newState?.MaxExperience && !newState?.MinExperience;
  }
  return newState?.MaxExperience === prevState?.MaxExperience && newState?.MinExperience === prevState?.MinExperience;
};

const handleChangedState = (newState, prevState) => {
  if (!prevState && newState?.length === 0) {
    return true;
  }
  return JSON.stringify(newState) === JSON.stringify(prevState);
};

const handleChangedSourcingLocations = (newState, prevState) => {
  const newStateWithoutId = newState?.map(obj => {
    const { Id, ...rest } = obj;
    return rest;
  });

  const prevStateWithoutId = prevState?.map(obj => {
    const { Id, ...rest } = obj;
    return rest;
  });
  return JSON.stringify(newStateWithoutId) === JSON.stringify(prevStateWithoutId);
};

const handleChangedMustHaves = (newState, prevState) => {
  if (!prevState && _.isEmpty(newState)) {
    return true;
  }
  return JSON.stringify(newState) === JSON.stringify(prevState);
};

const handleChangedTitlesAndExcludeSkills = (newState, prevState) => {
  if (!prevState) {
    return false;
  }
  if (newState.length !== prevState.length) {
    const newStateSelectedArray = [];
    const prevStateSelectedArray = [];

    newState.forEach(ele => {
      if (ele?.isSelected) {
        newStateSelectedArray.push(ele);
      }
    });
    prevState.forEach(ele => {
      if (ele?.isSelected) {
        prevStateSelectedArray.push(ele);
      }
    });
    return JSON.stringify(newStateSelectedArray) === JSON.stringify(prevStateSelectedArray);
  }
  return JSON.stringify(newState) === JSON.stringify(prevState);
};

const handleChangedKeywords = (newState, prevState) => {
  if (!prevState) {
    return false;
  }
  if (newState.length !== prevState.length) {
    const newStatePrefferedArray = [];
    const prevStatePrefferedArray = [];
    prevState.forEach(ele => {
      if (ele?.isPreferred) {
        prevStatePrefferedArray.push(ele);
      }
    });
    newState.forEach(ele => {
      if (ele?.isPreferred) {
        newStatePrefferedArray.push(ele);
      }
    });
    return JSON.stringify(newStatePrefferedArray) === JSON.stringify(prevStatePrefferedArray);
  }
  return JSON.stringify(newState) === JSON.stringify(prevState);
};

const handlePortalAdvancedSettings = ({ newState, prevState }) => {
  return deepEqual(newState, prevState);
};

export const onValuesChange = (props, changedValues, allValues) => {
  const {
    setIsReactivationAllowedFlag,
    jobId,
    manualSearchFormValues,
    unsavedCriteria,
    manualSearchLastPerformedTime,
  } = props;
  const {
    Titles: newTitles,
    ExcludedSkills: newExcludedSkills,
    QueryString: newQueryString,
    ExcludedCompanies: newExcludedCompanies,
    ExcludedIndustries: newExcludedIndustries,
    PreferredCompany: newPreferredCompany,
    PreferredIndustry: newPreferredIndustry,
    Degree: newDegree,
    School: newSchool,
    Certification: newCertification,
    Specialization: newSpecialization,
    Name: newName,
    Email: newEmail,
    Mobile: newMobile,
    Location: newLocation,
    CountryCode: newCountryCode,
    Zipcode: newZipcode,
    Range: newRange,
    ResumeUpdateDurationQuantity: newResumeUpdateDurationQuantity,
    ResumeUpdateDurationType: newResumeUpdateDurationType,
    VisaStatuses: newVisaStatuses,
    LocationType: newLocationType,
    SourcingLocations: newSourcingLocations,
    PreferredSkills: newPreferredSkills,
    MustHaves: newMustHaves,
    IsRemoteJob: newIsRemoteJob,
    advancedSettings: newAdvancedSettings,
    ActiveSearchString: newActiveSearchString,
    IsExactTitleMatchRequired: newIsExactTitleMatchRequired,
    IsCurrentCompany: newIsCurrentCompany,
    IsPastCompany: newIsPastCompany,
  } = allValues;
  const {
    Titles: prevTitles,
    ExcludedSkills: prevExcludedSkills,
    QueryString: prevQueryString,
    ExcludedCompanies: prevExcludedCompanies,
    ExcludedIndustries: prevExcludedIndustries,
    PreferredCompany: prevPreferredCompany,
    PreferredIndustry: prevPreferredIndustry,
    Degree: prevDegree,
    School: prevSchool,
    Certification: prevCertification,
    Specialization: prevSpecialization,
    Name: prevName,
    Email: prevEmail,
    Mobile: prevMobile,
    Location: prevLocation,
    CountryCode: prevCountryCode,
    Zipcode: prevZipcode,
    Range: prevRange,
    ResumeUpdateDurationQuantity: prevResumeUpdateDurationQuantity,
    ResumeUpdateDurationType: prevResumeUpdateDurationType,
    VisaStatuses: prevVisaStatuses,
    LocationType: prevLocationType,
    SourcingLocations: prevSourcingLocations,
    PreferredSkills: prevPreferredSkills,
    MustHaves: prevMustHaves,
    IsRemoteJob: prevIsRemoteJob,
    advancedSettings: prevAdvancedSettings,
    ActiveSearchString: prevActiveSearchString,
    IsExactTitleMatchRequired: prevIsExactTitleMatchRequired,
    IsCurrentCompany: prevIsCurrentCompany,
    IsPastCompany: prevIsPastCompany,
  } = manualSearchFormValues;
  const isUnsavedCriteriaExisting = !_.isEmpty(unsavedCriteria);
  setIsReactivationAllowedFlag({
    jobId,
    isReactivationAllowedFlag:
      !(isUnsavedCriteriaExisting && !manualSearchLastPerformedTime) &&
      handleChangedExperenice(allValues, manualSearchFormValues) &&
      handleChangedTitlesAndExcludeSkills(newTitles, prevTitles) &&
      handleChangedTitlesAndExcludeSkills(newExcludedSkills, prevExcludedSkills) &&
      handleChangedState(newQueryString, prevQueryString) &&
      handleChangedState(newActiveSearchString, prevActiveSearchString) &&
      handleChangedState(newExcludedIndustries, prevExcludedIndustries) &&
      handleChangedState(newExcludedCompanies, prevExcludedCompanies) &&
      handleChangedState(newPreferredCompany, prevPreferredCompany) &&
      handleChangedState(newPreferredIndustry, prevPreferredIndustry) &&
      handleChangedState(newDegree, prevDegree) &&
      handleChangedState(newSchool, prevSchool) &&
      handleChangedState(newCertification, prevCertification) &&
      handleChangedState(newSpecialization, prevSpecialization) &&
      handleChangedState(newName, prevName) &&
      handleChangedState(newEmail, prevEmail) &&
      handleChangedState(newMobile, prevMobile) &&
      handleChangedState(newLocation, prevLocation) &&
      handleChangedState(newCountryCode, prevCountryCode) &&
      handleChangedState(newZipcode, prevZipcode) &&
      handleChangedState(newRange, prevRange) &&
      handleChangedState(newResumeUpdateDurationQuantity, prevResumeUpdateDurationQuantity) &&
      handleChangedState(newResumeUpdateDurationType, prevResumeUpdateDurationType) &&
      handleChangedState(newVisaStatuses, prevVisaStatuses) &&
      handleChangedState(newLocationType, prevLocationType) &&
      handleChangedSourcingLocations(newSourcingLocations, prevSourcingLocations) &&
      handleChangedKeywords(newPreferredSkills, prevPreferredSkills) &&
      handleChangedMustHaves(newMustHaves, prevMustHaves) &&
      handleChangedState(newIsRemoteJob, prevIsRemoteJob) &&
      handlePortalAdvancedSettings({
        newState: newAdvancedSettings,
        prevState: prevAdvancedSettings,
      }) &&
      handleChangedState(newIsExactTitleMatchRequired, prevIsExactTitleMatchRequired) &&
      handleChangedState(newIsCurrentCompany, prevIsCurrentCompany) &&
      handleChangedState(newIsPastCompany, prevIsPastCompany),
  });
};

function ManualSearchWrapper(props) {
  const {
    form,
    children,
    jobId,
    history: {
      location: { pathname },
    },
    featureToggleList,
    scoutingAgentConfig,
    updateMinimizeManualSearchStatus,
    isManualSearchFormMinimized,
  } = props;

  const setMinimizeManualSearchForm = status => {
    updateMinimizeManualSearchStatus(jobId, status);
  };

  const [defaultFormValues, setDefaultFormValues] = React.useState();
  const getCriteria = () => {
    const formValues = form.getFieldsValue();
    const payload = generateManualSearchPayload({
      formValues,
      locationType: form.getFieldValue('LocationType'),
      activeSearchString: form.getFieldValue('ActiveSearchString'),
    });
    if (formValues.LocationType === 'Location' && payload.Locations && payload.Locations.length > 0) {
      payload.Locations[0].Postalcode = formValues.Zipcode;
    }
    return payload;
  };
  const candidateContext = pathname?.toLowerCase().includes('segments') ? 'segment' : 'job';
  return (
    <ManualSearchContext.Provider
      value={{
        form,
        defaultFormValues,
        setDefaultFormValues,
        isManualSearchFormMinimized,
        minimizeManualSearchForm: setMinimizeManualSearchForm,
      }}
    >
      {featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig && (
        <ScoutingAgentModal jobId={jobId} getCriteria={getCriteria} candidateContext={candidateContext} />
      )}

      {children}
    </ManualSearchContext.Provider>
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form.create({ onValuesChange })(ManualSearchWrapper))
);
export { ManualSearchWrapper as ManualSearchWrapperWithoutStore };
