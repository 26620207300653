import moment from 'moment';

export function getLocalDateFormat() {
  const date = new Date(2013, 11, 31);
  const localTimeString = date.toLocaleDateString();
  return localTimeString.replace('31', 'DD').replace('12', 'MM').replace('2013', 'YYYY');
}

export function getLocaleDateString(date) {
  return new Date(date).toLocaleDateString(navigator.language);
}

export function getChatTextHistoryRelativeTime(date) {
  const momentDate = moment.utc(date).local();
  return momentDate.calendar(null, {
    lastDay: 'hh:mm A',
    sameDay: 'hh:mm A',
    lastWeek: 'hh:mm A',
    sameElse: `hh:mm A`,
  });
}

export function getLocaleDateStringWithTimeStamp(date, showRelativeDate = true) {
  const momentDate = moment.utc(date).local();
  const localDateFormat = getLocalDateFormat();
  if (showRelativeDate)
    return momentDate.calendar(null, {
      sameDay: `${localDateFormat}, hh:mm A`,
      lastDay: `${localDateFormat}, hh:mm A`,
      lastWeek: `${localDateFormat}, hh:mm A`,
      sameElse: `${localDateFormat}, hh:mm A`,
    });
  return moment(moment.utc(date).local()).format(`hh:mma, ${localDateFormat}`);
}

export function getRelativeTime(date) {
  const momentDate = moment.utc(date).local();
  const localDateFormat = getLocalDateFormat();
  return momentDate.calendar(null, {
    sameDay: '[Today], hh:mm A',
    lastDay: '[Yesterday], hh:mm A',
    lastWeek: 'dddd, hh:mm A',
    sameElse: `${localDateFormat}, hh:mm A`,
  });
}

export function getRelativeDay(date) {
  const momentDate = moment.utc(date).local();
  const localDateFormat = getLocalDateFormat();
  return momentDate.calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    lastWeek: 'dddd',
    sameElse: `${localDateFormat}`,
  });
}

// this function it get relative time for chat
export function getChatRelativeTime(date) {
  const momentDate = moment.utc(date).local();
  const localDateFormat = getLocalDateFormat();
  return momentDate.calendar(null, {
    lastDay: '[Yesterday], hh:mm A',
    sameDay: 'hh:mm A',
    lastWeek: 'dddd, hh:mm A',
    sameElse: `${localDateFormat}, hh:mm A`,
  });
}

export function getRelativeTimeSpanFromNow(date) {
  return moment.utc(date).local().fromNow();
}

export function isExpiredDateTime(dateTime) {
  const isMinDate = moment(dateTime).isSame(moment('0001-01-01T00:00:00'));
  return !isMinDate && dateTime && moment().isAfter(dateTime);
}

export function getRelativeCallingDate(date) {
  return moment.utc(date).local().format('DD MMM YYYY');
}

export function getRelativeCallingTime(date) {
  return moment.utc(date).local().format('hh:mm A');
}
