import * as React from 'react';

const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <rect width={15} height={15} x={0.5} y={0.5} fill="#13C26B" stroke="#13C26B" rx={1.5} />
    <path
      fill="#fff"
      stroke="#fff"
      d="M3.913 7.035a.525.525 0 0 1 .412.2l-.412-.2Zm0 0h-.82a.243.243 0 0 0-.19.394l3.21 4.067c.21.266.613.265.824 0l5.723-7.252a.243.243 0 0 0-.191-.394h-.82a.523.523 0 0 0-.411.2l-4.714 5.972m-2.61-2.987 2.61 2.987m0 0L4.325 7.235l2.199 2.787Z"
    />
  </svg>
);
export default SvgComponent;
