import React from 'react';
import _ from 'lodash';
import {  FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { prepareColourChartData } from '../../Utils/DashBoardUtils';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

export default function ColourChart(props) {
  const { list, totalCount } = props;
  const listOfData = prepareColourChartData(list, totalCount);
  const totalCountWidth = !_.isEmpty(listOfData) ? listOfData.reduce((a, b) => ({ Count: a.Count + b.Count })) : 0;
  return (
    <div style={{ display: 'flex' }}>
      {listOfData.map((item, index) => (
        <Tooltip key={`key${index.toString()}`} title={<FormattedMessage {...messages.ProfileLabel} values={{itemDisplayName:item.DisplayName,itemValue:item.Value}}/>}>
          <div
            style={{
              width: `${((item.Count / totalCountWidth.Count) * 100).toFixed(2)}%`,
              backgroundColor: item.colorCode,
            }}
          >
            &nbsp;
          </div>
        </Tooltip>
      ))}
    </div>
  );
}
