import _ from 'lodash';
import uuid from 'uuid';

import { generateBooleanString, generateArrayFromBooleanString } from './BooleanStringUtility';

const titleMismatch = 'TITLE_MISMATCH';
export const missingMandatorySkills = 'MISSING_MANDATORY_SKILLS';
const industryMismatch = 'INDUSTRY_MISMATCH';
const jobFunctionMismatch = 'JOB_FUNCTION_MISMATCH';
const companyMismatch = 'COMPANY_MISMATCH';
const locationMismatch = 'LOCATION_MISMATCH';
export const locationMismatchChecked = 'LOCATION_MISMATCH_CHECKED';
const educationMismatch = 'EDUCATION_MISMATCH';
export const educationMismatchChecked = 'EDUCATION_MISMATCH_CHECKED';
const certificationMismatch = 'CERTIFICATION_MISMATCH';
export const certificationMismatchChecked = 'CERTIFICATION_MISMATCH_CHECKED';
const irrelevantCertification = 'IRRELEVANT_CERTIFICATION';
const missingRequiredCertifications = 'MISSING_REQUIRED_CERTIFICATION';
const experienceMismatch = 'EXPERIENCE_MISMATCH';
const moreReasonsMismatch = 'MORE_REASONS';
const minExperience = 'MinExperience';
const maxExperience = 'MaxExperience';
const others = 'OTHERS';
const otherReason = 'OTHER_REASON';
export const otherReasonChecked = 'OTHER_REASON_CHECKED';

const v1RejectReasonKeysToDisplayNameMapper = {
  TITLE_MISMATCH: 'Title',
  LOCATION_MISMATCH: 'Location',
  MISSING_MANDATORY_SKILLS: 'Missing Must Have Skills',
  EXPERIENCE_MISMATCH: 'Experience Mismatch',
  INDUSTRY_MISMATCH: 'Industry Mismatch',
  JOB_FUNCTION_MISMATCH: 'Job Occupation Mismatch',
  COMPANY_MISMATCH: 'Company Mismatch',
};

const v2RejectReasonKeysToDisplayNameMapper = {
  TITLE_MISMATCH: 'Title Mismatch',
  MISSING_MANDATORY_SKILLS: 'Skill Missing',
  EXPERIENCE_MISMATCH: 'Years of Experience Mismatch',
  INDUSTRY_MISMATCH: 'Industry Mismatch',
  JOB_FUNCTION_MISMATCH: 'Occupation Mismatch',
  COMPANY_MISMATCH: 'Company Mismatch',
  OPT_OUT: 'Opted out',
  CANDIDATE_NOT_INTERESTED: 'Candidate Not Interested',
  MORE_REASONS: 'More',
};

const v2RejectReasonKeysToFormFieldsMapper = {
  TITLE_MISMATCH: ['TITLE_MISMATCH'],
  MISSING_MANDATORY_SKILLS: ['MISSING_MANDATORY_SKILLS'],
  EXPERIENCE_MISMATCH: ['EXPERIENCE_MISMATCH', 'MinExperience', 'MaxExperience'],
  INDUSTRY_MISMATCH: ['INDUSTRY_MISMATCH'],
  JOB_FUNCTION_MISMATCH: ['JOB_FUNCTION_MISMATCH'],
  COMPANY_MISMATCH: ['COMPANY_MISMATCH'],
  OPT_OUT: ['OPT_OUT'],
  CANDIDATE_NOT_INTERESTED: ['CANDIDATE_NOT_INTERESTED'],
  MORE_REASONS: [
    'LOCATION_MISMATCH_CHECKED',
    'LOCATION_MISMATCH',
    'EDUCATION_MISMATCH_CHECKED',
    'EDUCATION_MISMATCH',
    'CERTIFICATION_MISMATCH_CHECKED',
    'IRRELEVANT_CERTIFICATION',
    'MISSING_REQUIRED_CERTIFICATION',
    'OTHER_REASON_CHECKED',
    'OTHER_REASON',
  ],
};

const rejectReasonKeysToTitleMapper = {
  TITLE_MISMATCH: 'Improve Title Intel',
  MISSING_MANDATORY_SKILLS: 'Improve Skill Intel',
  EXPERIENCE_MISMATCH: 'Improve Years of Experience Intel',
  INDUSTRY_MISMATCH: 'Improve Industry Intel',
  JOB_FUNCTION_MISMATCH: 'Improve Occupation Intel',
  COMPANY_MISMATCH: 'Improve Company Intel',
  OPT_OUT: 'Candidate Opt-out',
  CANDIDATE_NOT_INTERESTED: 'Candidate Not Interested',
  MORE_REASONS: 'More',
};

const rejectReasonKeysToMoreSuitableReasonsTextMapper = {
  TITLE_MISMATCH: 'titles',
  INDUSTRY_MISMATCH: 'industries',
  JOB_FUNCTION_MISMATCH: 'occupations',
  COMPANY_MISMATCH: 'companies',
};

const rejectReasonkeysToInitialValueMapper = {
  TITLE_MISMATCH: 'RejectedTitles',
  INDUSTRY_MISMATCH: 'RejectedIndustries',
  JOB_FUNCTION_MISMATCH: 'RejectedOccupations',
  COMPANY_MISMATCH: 'RejectedCompanies',
  MISSING_MANDATORY_SKILLS: 'MissingSkills',
  LOCATION_MISMATCH: 'RejectedLocations',
};

const generateSkillsObject = skillsArray => {
  if (!skillsArray?.length) return {};
  const skillsObject = {};
  skillsArray.forEach(skillArray => {
    const skillKey = uuid.v4();
    _.setWith(skillsObject, [skillKey, 'selectedSkills'], skillArray, Object);
  });
  return skillsObject;
};

const getDefaultCandidateStatusReasons = selectedCandidateStatusReasons => {
  if (!selectedCandidateStatusReasons?.length) return {};
  const defaultCandidateStatusReasons = {};
  selectedCandidateStatusReasons.forEach(statusReason => {
    const statusReasonName = statusReason.Name?.toUpperCase();
    const additionalDetails = statusReason.AdditionalDetails;
    if (statusReasonName === 'CANDIDATE_NOT_INTERESTED') {
      defaultCandidateStatusReasons.CANDIDATE_NOT_INTERESTED = true;
    }
    if (additionalDetails) {
      if (statusReasonName === educationMismatch) {
        defaultCandidateStatusReasons[educationMismatch] =
          additionalDetails.IsLessQualified === true ? 'lessQ' : 'moreQ';
        defaultCandidateStatusReasons[educationMismatchChecked] = true;
      } else if (statusReasonName === certificationMismatch) {
        defaultCandidateStatusReasons[certificationMismatchChecked] = true;
        defaultCandidateStatusReasons[irrelevantCertification] = additionalDetails.IrrelevantCertifications;
        defaultCandidateStatusReasons[missingRequiredCertifications] = additionalDetails.MissingCertifications;
      } else if (statusReasonName === experienceMismatch) {
        const { ExperienceRange } = additionalDetails;
        const { IsLessExperienced, IsOverExperienced } = additionalDetails;
        if (IsLessExperienced || IsOverExperienced)
          defaultCandidateStatusReasons[experienceMismatch] =
            additionalDetails.IsLessExperienced === true ? 'lessExp' : 'moreExp';
        if (ExperienceRange) {
          defaultCandidateStatusReasons[minExperience] = ExperienceRange.MinExp;
          defaultCandidateStatusReasons[maxExperience] = ExperienceRange.MaxExp;
        }
      } else if (statusReasonName === others) {
        defaultCandidateStatusReasons[otherReason] = additionalDetails.OtherReason;
        defaultCandidateStatusReasons[otherReasonChecked] = true;
      } else if (statusReasonName === 'MISSING_MANDATORY_SKILLS') {
        const skillsArray = generateArrayFromBooleanString(
          additionalDetails[rejectReasonkeysToInitialValueMapper[statusReasonName]]
        );
        defaultCandidateStatusReasons.MISSING_MANDATORY_SKILLS = generateSkillsObject(skillsArray);
      } else if (statusReasonName === 'OPT_OUT') {
        defaultCandidateStatusReasons.OPT_OUT = additionalDetails;
      } else {
        defaultCandidateStatusReasons[statusReasonName] =
          additionalDetails[rejectReasonkeysToInitialValueMapper[statusReasonName]];
      }
    }
  });
  if (defaultCandidateStatusReasons[locationMismatch]) defaultCandidateStatusReasons[locationMismatchChecked] = true;

  return defaultCandidateStatusReasons;
};

const getUpdatedCandidateStatusReasons = (rejectReasonKey, candidateStatusReasons) => {
  return _.pick(
    candidateStatusReasons,
    Object.keys(candidateStatusReasons).filter(
      statusReasons => !v2RejectReasonKeysToFormFieldsMapper[rejectReasonKey].includes(statusReasons)
    )
  );
};

const clearRejectReasonValues = (rejectReasonKey, form) => {
  v2RejectReasonKeysToFormFieldsMapper[rejectReasonKey].forEach(rejectReasonSubKey => {
    if (rejectReasonSubKey === 'OPT_OUT') {
      form.setFieldsValue({ [rejectReasonSubKey]: { CandidateOptOutType: 'Job' } });
    } else form.setFieldsValue({ [rejectReasonSubKey]: undefined });
  });
};

const getCurrentRejectReasonFormValues = (rejectReasonKey, formValues) => {
  const currentRejectReasonFormValues = _.pick(formValues, v2RejectReasonKeysToFormFieldsMapper[rejectReasonKey]);
  if (rejectReasonKey === missingMandatorySkills) {
    const selectedMissingMandatorySkills = {};
    if (currentRejectReasonFormValues[missingMandatorySkills])
      Object.entries(currentRejectReasonFormValues[missingMandatorySkills])?.forEach(skill => {
        const [skillKey, skillValues] = skill;
        if (skillValues.selectedSkills?.length) selectedMissingMandatorySkills[skillKey] = skillValues;
      });
    return { [missingMandatorySkills]: selectedMissingMandatorySkills };
  }
  if (rejectReasonKey === 'OPT_OUT') {
    if (currentRejectReasonFormValues.OPT_OUT?.CandidateOptOutType) {
      return currentRejectReasonFormValues;
    }
    return null;
  }
  return currentRejectReasonFormValues;
};

const getRejectReasonInitialValue = (defaultCandidateStatusReasons, rejectReasonKey) => {
  switch (rejectReasonKey) {
    case 'TITLE_MISMATCH':
    case 'INDUSTRY_MISMATCH':
    case 'JOB_FUNCTION_MISMATCH':
    case 'COMPANY_MISMATCH':
    case 'MISSING_MANDATORY_SKILLS':
      return defaultCandidateStatusReasons[rejectReasonKey];
    case 'OPT_OUT':
      return defaultCandidateStatusReasons[rejectReasonKey] || { CandidateOptOutType: 'Job' };
    case 'CANDIDATE_NOT_INTERESTED':
      return defaultCandidateStatusReasons[rejectReasonKey];

    default:
      return null;
  }
};

const getCurrentSelectedRejectReason = (rejectReasonKey, form, initialValue) => {
  const formValues = form.getFieldsValue();
  return rejectReasonKey in formValues ? formValues[rejectReasonKey] : initialValue;
};

const getIsMandatorySkillsSelected = mandatorySkills => {
  return !!Object.values(mandatorySkills).find(mandatorySkill => mandatorySkill.selectedSkills?.length > 0);
};

const getIsCurrentRejectReasonSelected = (rejectReasonKey, candidateStatusReasons) => {
  switch (rejectReasonKey) {
    case 'TITLE_MISMATCH':
    case 'INDUSTRY_MISMATCH':
    case 'JOB_FUNCTION_MISMATCH':
    case 'COMPANY_MISMATCH': {
      return candidateStatusReasons[rejectReasonKey]?.length > 0;
    }
    case 'OPT_OUT': {
      return !!candidateStatusReasons[rejectReasonKey]?.CandidateOptOutType;
    }
    case 'MISSING_MANDATORY_SKILLS': {
      return (
        candidateStatusReasons[rejectReasonKey] && getIsMandatorySkillsSelected(candidateStatusReasons[rejectReasonKey])
      );
    }
    case 'EXPERIENCE_MISMATCH': {
      return !!(
        candidateStatusReasons[rejectReasonKey] ||
        candidateStatusReasons.MinExperience ||
        candidateStatusReasons.MaxExperience
      );
    }
    case 'MORE_REASONS': {
      const {
        LOCATION_MISMATCH_CHECKED,
        LOCATION_MISMATCH,
        EDUCATION_MISMATCH_CHECKED,
        EDUCATION_MISMATCH,
        CERTIFICATION_MISMATCH_CHECKED,
        IRRELEVANT_CERTIFICATION,
        MISSING_REQUIRED_CERTIFICATION,
        OTHER_REASON_CHECKED,
        OTHER_REASON,
      } = candidateStatusReasons;
      return !!(
        (LOCATION_MISMATCH_CHECKED && LOCATION_MISMATCH) ||
        (EDUCATION_MISMATCH_CHECKED && EDUCATION_MISMATCH?.trim()) ||
        (CERTIFICATION_MISMATCH_CHECKED && IRRELEVANT_CERTIFICATION?.trim()) ||
        (CERTIFICATION_MISMATCH_CHECKED && MISSING_REQUIRED_CERTIFICATION?.trim()) ||
        (OTHER_REASON_CHECKED && OTHER_REASON?.trim())
      );
    }
    case 'CANDIDATE_NOT_INTERESTED': {
      return candidateStatusReasons.CANDIDATE_NOT_INTERESTED;
    }
    default:
      return false;
  }
};
const checkIfCandidateNotInterested = candidateStatusReasons => {
  return candidateStatusReasons.CANDIDATE_NOT_INTERESTED;
};

const getIsRejectSimilarCandidatesAllowed = candidateStatusReasons => {
  return Object.keys(v2RejectReasonKeysToDisplayNameMapper)
    .filter(rejectReasonKey => rejectReasonKey !== 'OPT_OUT' && rejectReasonKey !== 'CANDIDATE_NOT_INTERESTED')
    .some(rejectReasonKey => getIsCurrentRejectReasonSelected(rejectReasonKey, candidateStatusReasons));
};

const generateMandatorySkillsArray = mandatorySkills => {
  if (!mandatorySkills) return [];
  const mandatorySkillsArray = [];
  Object.values(mandatorySkills).forEach(mandatorySkill => {
    if (mandatorySkill?.selectedSkills) {
      mandatorySkillsArray.push(mandatorySkill.selectedSkills.filter(selectedSkill => selectedSkill));
    }
  });
  return mandatorySkillsArray;
};

const generateStatusReasonsPayload = candidateStatusReasons => {
  const {
    TITLE_MISMATCH,
    MISSING_MANDATORY_SKILLS,
    INDUSTRY_MISMATCH,
    JOB_FUNCTION_MISMATCH,
    COMPANY_MISMATCH,
    EXPERIENCE_MISMATCH,
    MinExperience,
    MaxExperience,
    LOCATION_MISMATCH_CHECKED,
    LOCATION_MISMATCH,
    EDUCATION_MISMATCH_CHECKED,
    EDUCATION_MISMATCH,
    CERTIFICATION_MISMATCH_CHECKED,
    IRRELEVANT_CERTIFICATION,
    MISSING_REQUIRED_CERTIFICATION,
    OTHER_REASON_CHECKED,
    OTHER_REASON,
    OPT_OUT,
    CANDIDATE_NOT_INTERESTED,
  } = candidateStatusReasons;
  const statusReasons = [];
  if (TITLE_MISMATCH?.length) {
    statusReasons.push({
      Name: 'TITLE_MISMATCH',
      AdditionalDetails: {
        RejectedTitles: TITLE_MISMATCH,
      },
    });
  }

  if (OPT_OUT && OPT_OUT.CandidateOptOutType) {
    statusReasons.push({
      Name: 'OPT_OUT',
      AdditionalDetails: {
        CandidateOptOutType: OPT_OUT.CandidateOptOutType,
        CandidateOptOutReason: OPT_OUT.CandidateOptOutReason,
      },
    });
  }

  if (INDUSTRY_MISMATCH?.length) {
    statusReasons.push({
      Name: 'INDUSTRY_MISMATCH',
      AdditionalDetails: {
        RejectedIndustries: INDUSTRY_MISMATCH,
      },
    });
  }

  if (JOB_FUNCTION_MISMATCH?.length) {
    statusReasons.push({
      Name: 'JOB_FUNCTION_MISMATCH',
      AdditionalDetails: {
        RejectedOccupations: JOB_FUNCTION_MISMATCH,
      },
    });
  }

  if (COMPANY_MISMATCH?.length) {
    statusReasons.push({
      Name: 'COMPANY_MISMATCH',
      AdditionalDetails: {
        RejectedCompanies: COMPANY_MISMATCH,
      },
    });
  }

  if (MISSING_MANDATORY_SKILLS && getIsMandatorySkillsSelected(MISSING_MANDATORY_SKILLS)) {
    statusReasons.push({
      Name: 'MISSING_MANDATORY_SKILLS',
      AdditionalDetails: {
        MissingSkills: generateBooleanString(generateMandatorySkillsArray(MISSING_MANDATORY_SKILLS)),
      },
    });
  }

  if (EXPERIENCE_MISMATCH || MinExperience || MaxExperience) {
    const statusReason = { Name: 'EXPERIENCE_MISMATCH' };
    const additionalDetails = {};
    if (EXPERIENCE_MISMATCH === 'lessExp') additionalDetails.IsLessExperienced = true;
    else if (EXPERIENCE_MISMATCH === 'moreExp') additionalDetails.IsOverExperienced = true;
    if (MinExperience) _.set(additionalDetails, ['ExperienceRange', 'MinExp'], MinExperience);
    if (MaxExperience) _.set(additionalDetails, ['ExperienceRange', 'MaxExp'], MaxExperience);

    _.set(statusReason, ['AdditionalDetails'], additionalDetails);
    statusReasons.push(statusReason);
  }

  if (LOCATION_MISMATCH_CHECKED && LOCATION_MISMATCH) {
    statusReasons.push({
      Name: 'LOCATION_MISMATCH',
      AdditionalDetails: {
        RejectedLocations: [LOCATION_MISMATCH],
      },
    });
  }

  if (EDUCATION_MISMATCH_CHECKED && EDUCATION_MISMATCH) {
    statusReasons.push({
      Name: 'EDUCATION_MISMATCH',
      AdditionalDetails: {
        IsLessQualified: EDUCATION_MISMATCH === 'lessQ',
        IsOverQualified: EDUCATION_MISMATCH === 'overQ',
      },
    });
  }

  if (CERTIFICATION_MISMATCH_CHECKED) {
    const statusReason = { Name: 'CERTIFICATION_MISMATCH', AdditionalDetails: {} };
    if (IRRELEVANT_CERTIFICATION?.trim())
      statusReason.AdditionalDetails.IrrelevantCertifications = IRRELEVANT_CERTIFICATION.trim();
    if (MISSING_REQUIRED_CERTIFICATION?.trim())
      statusReason.AdditionalDetails.MissingCertifications = MISSING_REQUIRED_CERTIFICATION.trim();
    if (!_.isEmpty(statusReason.AdditionalDetails)) statusReasons.push(statusReason);
  }

  if (OTHER_REASON_CHECKED && OTHER_REASON?.trim()) {
    statusReasons.push({
      Name: 'OTHERS',
      AdditionalDetails: {
        OtherReason: OTHER_REASON,
      },
    });
  }

  if (CANDIDATE_NOT_INTERESTED) {
    statusReasons.push({
      Name: 'CANDIDATE_NOT_INTERESTED',
    });
  }
  return statusReasons;
};

const getIsWarningMessageVisible = (rejectReasonKey, negativeValues) => {
  return (
    rejectReasonKey === 'MORE_REASONS' ||
    ([titleMismatch, industryMismatch, companyMismatch, jobFunctionMismatch].includes(rejectReasonKey) &&
      !negativeValues?.[rejectReasonKey])
  );
};

const getCandidateRejectReasonWarningMessage = rejectReasonKey => {
  const optionsText = rejectReasonKey === 'MORE_REASONS' ? '"Other Reasons"' : 'manual added values';
  return `Note: We may or may not take ${optionsText} to refine candidates`;
};

const getIsRejectReasonValuesChanged = (formValues, candidateStatusReasons, rejectReasonKey) => {
  switch (rejectReasonKey) {
    case titleMismatch:
    case jobFunctionMismatch:
    case companyMismatch:
    case industryMismatch: {
      return !!(
        formValues[`${rejectReasonKey}-Input`]?.trim() ||
        ((formValues[rejectReasonKey] || !candidateStatusReasons[rejectReasonKey]) &&
          !_.isEqual(formValues[rejectReasonKey] || [], candidateStatusReasons[rejectReasonKey] || []))
      );
    }

    case missingMandatorySkills: {
      const formSkillValues = formValues[rejectReasonKey] ?? {};
      const selectedSkillValues = candidateStatusReasons[rejectReasonKey] ?? {};
      return !!(
        formValues[`${rejectReasonKey}-Input`]?.trim() ||
        ((!_.isEmpty(formSkillValues) || _.isEmpty(candidateStatusReasons[rejectReasonKey])) &&
          Object.keys(formSkillValues).some(
            key => !_.isEqual(formSkillValues[key].selectedSkills, selectedSkillValues[key]?.selectedSkills ?? [])
          ))
      );
    }

    case experienceMismatch: {
      const {
        [experienceMismatch]: selectedExperienceMismatch,
        [minExperience]: selectedMinExperience = 0,
        [maxExperience]: selectedMaxExperience = 0,
      } = candidateStatusReasons;

      const {
        [experienceMismatch]: currentExperienceMismatch = selectedExperienceMismatch,
        [minExperience]: currentMinExperience = selectedMinExperience,
        [maxExperience]: currentMaxExperience = selectedMaxExperience,
      } = formValues;

      return (
        currentExperienceMismatch !== selectedExperienceMismatch ||
        currentMinExperience !== selectedMinExperience ||
        currentMaxExperience !== selectedMaxExperience
      );
    }
    case moreReasonsMismatch: {
      const {
        [locationMismatchChecked]: selectedLocationMismatchChecked = false,
        [locationMismatch]: selectedLocationMismatch = '',
        [educationMismatchChecked]: selectedEducationMismatchChecked = false,
        [educationMismatch]: selectedEducationMismatch,
        [certificationMismatchChecked]: selectedCertificationMismatchChecked = false,
        [irrelevantCertification]: selectedIrrelevantCertification = '',
        [missingRequiredCertifications]: selectedMissingRequiredCertifications = '',
        [otherReasonChecked]: selectedOtherReasonChecked = false,
        [otherReason]: selectedOtherReason = '',
      } = candidateStatusReasons;
      const {
        [locationMismatchChecked]: currentLocationMismatchChecked = false,
        [locationMismatch]: currentLocationMismatch = selectedLocationMismatch,
        [educationMismatchChecked]: currentEducationMismatchChecked = false,
        [educationMismatch]: currentEducationMismatch = selectedEducationMismatch,
        [certificationMismatchChecked]: currentCertificationMismatchChecked = false,
        [irrelevantCertification]: currentIrrelevantCertification = selectedIrrelevantCertification,
        [missingRequiredCertifications]: currentMissingRequiredCertifications = selectedMissingRequiredCertifications,
        [otherReasonChecked]: currentOtherReasonChecked = false,
        [otherReason]: currentOtherReason = selectedOtherReason,
      } = formValues;

      return !!(
        (currentLocationMismatchChecked !== selectedLocationMismatchChecked &&
          ((!currentLocationMismatchChecked && selectedLocationMismatch) || currentLocationMismatch)) ||
        currentLocationMismatch !== selectedLocationMismatch ||
        (currentEducationMismatchChecked !== selectedEducationMismatchChecked &&
          ((!currentEducationMismatchChecked && selectedEducationMismatch) || currentEducationMismatch)) ||
        currentEducationMismatch !== selectedEducationMismatch ||
        (currentCertificationMismatchChecked !== selectedCertificationMismatchChecked &&
          ((!currentCertificationMismatchChecked &&
            (currentIrrelevantCertification.trim() || selectedIrrelevantCertification.trim())) ||
            currentIrrelevantCertification.trim() ||
            selectedIrrelevantCertification.trim())) ||
        currentIrrelevantCertification.trim() !== selectedIrrelevantCertification.trim() ||
        currentMissingRequiredCertifications.trim() !== selectedMissingRequiredCertifications.trim() ||
        (currentOtherReasonChecked !== selectedOtherReasonChecked &&
          ((!currentOtherReasonChecked && selectedOtherReason.trim()) || currentOtherReason.trim())) ||
        currentOtherReason.trim() !== selectedOtherReason.trim()
      );
    }
    case 'OPT_OUT':
      return true;
    default:
      return false;
  }
};

const moreRejectReasonKeyToSubKeysMapper = {
  [locationMismatchChecked]: [locationMismatch],
  [educationMismatchChecked]: [educationMismatch],
  [certificationMismatchChecked]: [irrelevantCertification, missingRequiredCertifications],
  [otherReasonChecked]: [otherReason],
};

const clearMoreRejectReasonFormValues = (form, moreRejectReasonKey) => {
  moreRejectReasonKeyToSubKeysMapper[moreRejectReasonKey].forEach(moreRejectReasonSubKey => {
    if (form.getFieldValue(moreRejectReasonSubKey)) form.setFieldsValue({ [moreRejectReasonSubKey]: undefined });
  });
};

const getRejectReasonsInputValues = (rejectReasonKey, rejectReasonInputValues) => {
  if (rejectReasonKey === missingMandatorySkills) {
    const skillsInput = {};
    rejectReasonInputValues.split(',').forEach(skill => {
      const key = uuid.v4();
      skillsInput[key] = skill;
    });

    return skillsInput;
  }
  return rejectReasonInputValues.split(',');
};

const getMergedRejectReasonValues = (rejectReasonKey, existingRejectReasonValues, rejectReasonInputValues) => {
  if (rejectReasonKey === missingMandatorySkills) {
    const currentSkills = {};
    Object.entries(rejectReasonInputValues).forEach(rejectReasonInputValue => {
      const [currentSkillKey] = rejectReasonInputValue;
      currentSkills[currentSkillKey] = { selectedSkills: [rejectReasonInputValue[1]] };
    });

    return { ...existingRejectReasonValues, ...currentSkills };
  }
  return (existingRejectReasonValues ?? []).concat(rejectReasonInputValues);
};

const getCandidateOptOutReason = candidate => {
  const { Status, StatusReasons } = candidate;
  return Status === 'Rejected' ? StatusReasons?.find(reason => reason.Name?.toLowerCase() === 'opt_out') : null;
};

const handleTagClick = (form, onApplyRejectReason, rejectReasonKey) => {
  const formValues = form.getFieldsValue();
  if (rejectReasonKey === 'CANDIDATE_NOT_INTERESTED') {
    const currentFieldsValue = formValues.CANDIDATE_NOT_INTERESTED;
    form.setFieldsValue({
      CANDIDATE_NOT_INTERESTED: !currentFieldsValue,
    });
    onApplyRejectReason(rejectReasonKey);
  }
};

export {
  v1RejectReasonKeysToDisplayNameMapper,
  v2RejectReasonKeysToDisplayNameMapper,
  rejectReasonKeysToTitleMapper,
  rejectReasonKeysToMoreSuitableReasonsTextMapper,
  v2RejectReasonKeysToFormFieldsMapper,
  getDefaultCandidateStatusReasons,
  getCurrentSelectedRejectReason,
  generateStatusReasonsPayload,
  getRejectReasonInitialValue,
  getUpdatedCandidateStatusReasons,
  clearRejectReasonValues,
  getCurrentRejectReasonFormValues,
  getIsCurrentRejectReasonSelected,
  getCandidateRejectReasonWarningMessage,
  getIsWarningMessageVisible,
  getIsRejectSimilarCandidatesAllowed,
  getIsRejectReasonValuesChanged,
  clearMoreRejectReasonFormValues,
  getMergedRejectReasonValues,
  getRejectReasonsInputValues,
  generateSkillsObject,
  generateMandatorySkillsArray,
  getCandidateOptOutReason,
  checkIfCandidateNotInterested,
  handleTagClick,
};
