import React from 'react';
import moment from 'moment';
import { Badge } from 'antd';
import { TextIcon } from '../../../Icons/AryaIcons';
import './ChatNotification.scss';
import { handleSmsAndChatNotificationClick } from '../../../Utils/NotificationUtils';

function getIcon() {
  return (
    <TextIcon
      style={{ fontSize: 20, padding: 10, color: '#13C26B', backgroundColor: '#DCF6E9', borderRadius: '50%' }}
    />
  );
}

function getSenderName(senderName, currentUserId, senderId, IsByBot) {
  let name;

  if (IsByBot) {
    name = 'AryaBot';
  } else if (currentUserId === senderId) {
    name = 'You';
  } else {
    const firstName = senderName && senderName.split(' ')[0];
    name = firstName;
  }

  return name;
}

function ChatNotification(props) {
  const { notification, onClick, currentUser, jobDetails, setCurrentMessagePopupInfos } = props;
  const { UnreadCount } = notification;
  const { CreatedTime, SenderName, Message, PersonName, SenderId, IsByBot, IsByPerson } = notification.Payload;
  return (
    <div
      className="connect-notification"
      onClick={() => {
        handleSmsAndChatNotificationClick({ notification, setCurrentMessagePopupInfos, onClick });
      }}
      role="presentation"
    >
      <div className="icon">{getIcon()}</div>
      <div className="content">
        <div className="sender-name">{PersonName}</div>
        <div>
          {jobDetails.JobTitle} {jobDetails.JobId}
        </div>
        <Badge dot={UnreadCount > 0}>
          <div className="message">
            <span>{!IsByPerson ? `${getSenderName(SenderName, currentUser.sub, SenderId, IsByBot)}: ` : ''}</span>
            <span>{Message}</span>
          </div>
        </Badge>
        <div className="since">{moment.utc(CreatedTime).local().fromNow()}</div>
      </div>
    </div>
  );
}

export default ChatNotification;
