import { defineMessages } from 'react-intl';

const messages = defineMessages({
  sourced: {
    id: 'Pipeline.Sourced',
    defaultMessage: 'Sourced',
  },
  shortlisted: {
    id: 'Pipeline.Shortlisted',
    defaultMessage: 'Shortlisted',
  },
  connected: {
    id: 'Pipeline.Connected',
    defaultMessage: 'Connected',
  },
  applied: {
    id: 'Pipeline.Applied',
    defaultMessage: 'Applied',
  },
  engaged: {
    id: 'Pipeline.Engaged',
    defaultMessage: 'Engaged',
  },
  aryaSearch: {
    id: 'Pipeline.AryaSearch',
    defaultMessage: 'Arya Search',
  },
  aryaRanked: {
    id: 'Pipeline.AryaRanked',
    defaultMessage: 'Arya Ranked',
  },
  aryaFavourite: {
    id: 'Pipeline.AryaFavourite',
    defaultMessage: 'Arya Favourite',
  },
});

export default messages;
