import React from 'react';
import _ from 'lodash';
import {  FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import MapData from '../../Data/UsStatesMap.json';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

function getHeatmapOpacity(candidatesCount) {
  let fillOpacity = 0.4;
  if (candidatesCount > 800) {
    fillOpacity = 1;
  } else if (candidatesCount > 600) {
    fillOpacity = 0.85;
  } else if (candidatesCount > 400) {
    fillOpacity = 0.7;
  } else if (candidatesCount > 200) {
    fillOpacity = 0.55;
  }
  return fillOpacity;
}

export default function HeatMap(props) {
  const { width, height, list, fillColor } = props;
  return (
    <div id="heatmap-chart-info">
      <svg width={width} height={height} preserveAspectRatio="xMinYMin">
        <g transform="matrix(0.45,0,0,0.45,0,0)">
          {Object.keys(MapData).map(stateName => {
            const candidatesCount = _.get(_.find(list, { DisplayName: stateName }), ['Count'], 0);
            const fillOpacity = getHeatmapOpacity(candidatesCount);
            return (
              <Tooltip title={ <FormattedMessage {...messages.stateNameCandidatesCountProfilesLabel} values={{stateName,candidatesCount}}/>}>
                <path d={MapData[stateName]} fill={fillColor} fillOpacity={fillOpacity} />
              </Tooltip>
            );
          })}
        </g>
      </svg>
    </div>
  );
}
