import React from 'react';
import styles from './WorkflowTab.module.scss';
import StackedBar from '../../StackedBar/StackedBar';

const WorkflowTab = props => {
  const {
    workflowHistory,
    setWorkflowComposeModalVisibility,
    stopCandidateWorkflow,
    stopWorkflowApiStatus,
    candidateId,
    jobGuid,
    jobId,
    jobTitle,
    candidateName,
    setCandidateWorkflowHistoryAction,
  } = props;
  if (workflowHistory?.length > 0) {
    return workflowHistory.map(data => (
      <div key={data.InstanceId} className={styles.stackedBarContainer}>
        <StackedBar
          stopCandidateWorkflow={stopCandidateWorkflow}
          stopWorkflowApiStatus={stopWorkflowApiStatus}
          setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
          setCandidateWorkflowHistoryAction={setCandidateWorkflowHistoryAction}
          {...data}
          candidateId={candidateId}
          candidateName={candidateName}
          jobGuid={jobGuid}
          jobId={jobId}
          jobTitle={jobTitle}
        />
      </div>
    ));
  }
  return <div></div>;
};

export default WorkflowTab;
