import React from 'react';
import { Icon, Badge } from 'antd';
import moment from 'moment';
import styles from './AppliedScoringNotification.module.scss';

export default function AppliedScoringNotification(props) {
  const { notification, onClick } = props;
  const { Payload, UpdatedTime, UnreadCount } = notification;
  const { JobId, JobTitle } = Payload;
  return (
    <div className={styles.notification} role="presentation" onClick={() => onClick(notification)}>
      <div className="icon">
        <Icon style={{ fontSize: 40, color: '#13C26B' }} type="clock-circle" />
      </div>
      <div className={styles.content}>
        <div className={styles.notificationTitle}>
          <span className={styles.bold}>{JobTitle}</span>, {JobId}
        </div>
        <div className={styles.message}>
          <span className={styles.bold}> New intel </span>
          applied on candidates under the Applied bucket.
        </div>
        <div className={styles.action} />
        <div className={styles.since}>{moment.utc(UpdatedTime).local().fromNow()}</div>
      </div>
      <div className={styles.viewSymbol}>
        <Badge dot={UnreadCount > 0} />
      </div>
    </div>
  );
}
