import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Form, Select, Icon, Button } from 'antd';
import styles from './LanguageFilter.module.scss';
import message from '../../../../../Containers/ManualSearchV2/ManualSearchMessages';

const { Item } = Form;
const { Option } = Select;

const range = N => Array.from({ length: N }, (v, k) => k);

export default function LanguageFilter(props) {
  const {
    form,
    languageSkills,
    supportedLanguages,
    languageProficiencyLevels,
    jobBoardFormDecorator,
    defaultProficiencyLevel,
  } = props;

  const keysFieldDecorator = `${jobBoardFormDecorator}-language-keys`;
  form.getFieldDecorator(keysFieldDecorator, {
    initialValue: languageSkills ? range(JSON.parse(languageSkills).length) : [0],
  });
  const keys = form.getFieldValue(keysFieldDecorator);
  const languagesSkillProficiency = JSON.parse(languageSkills ?? '[]');
  const languages = (form.getFieldsValue()?.[`${jobBoardFormDecorator}-languages`] ?? []).map(language => ({
    value: language.Language,
  }));
  const remainingLanguages = _.differenceBy(supportedLanguages, languages, 'value');

  const [languageCount, setLanguageCount] = React.useState(keys?.length);

  const addMoreLanguages = () => {
    const _keys = form.getFieldValue(keysFieldDecorator);
    const nextKeys = _keys?.concat(languageCount);
    setLanguageCount(languageCount + 1);
    form.setFieldsValue({
      [keysFieldDecorator]: nextKeys,
    });
  };

  const removeLanguage = languageKey => {
    const _keys = form.getFieldValue(keysFieldDecorator);
    const _languages = form.getFieldValue(`${jobBoardFormDecorator}-languages`);
    const newLanguages = _languages.map((language, index) => {
      if (index === languageKey) {
        return {
          Language: undefined,
          Proficiency: undefined,
        };
      }
      return language;
    });
    if (_keys?.length === 1) {
      return;
    }
    form.setFieldsValue({
      [keysFieldDecorator]: _keys?.filter(key => key !== languageKey),
      [`${jobBoardFormDecorator}-languages`]: newLanguages,
    });
  };

  const isAddLanguageButtonDisabled = () => {
    const _keys = form.getFieldValue(keysFieldDecorator);
    const _languages = form.getFieldValue(`${jobBoardFormDecorator}-languages`);
    return (
      _keys?.length === supportedLanguages?.length ||
      !(
        _.get(_languages, [_keys[_keys.length - 1], 'Language'], undefined) &&
        _.get(_languages, [_keys[_keys.length - 1], 'Proficiency'], undefined)
      )
    );
  };

  const isProficiencyDisabled = key => {
    const _languages = form.getFieldValue(`${jobBoardFormDecorator}-languages`);
    return !_languages?.[key]?.Language;
  };

  const setLanguageProficiency = (key, value) => {
    const _languages = form.getFieldValue(`${jobBoardFormDecorator}-languages`);
    const newLanguages = _languages?.map((language, index) => {
      if (index === key) {
        return {
          Language: language.Language,
          Proficiency: value,
        };
      }
      return language;
    });

    form.setFieldsValue({
      [`${jobBoardFormDecorator}-languages`]: newLanguages,
    });
  };

  const onLanguageChange = (value, key) => {
    if (!value) {
      setLanguageProficiency(key);
    } else {
      const _languages = form.getFieldValue(`${jobBoardFormDecorator}-languages`);
      const currentValue = _languages?.find((language, index) => index === key);
      if (!currentValue?.Language) {
        setLanguageProficiency(key, defaultProficiencyLevel);
      }
    }
  };

  const languageSkillsFormItems = keys?.map(key => (
    <Item key={key}>
      {form.getFieldDecorator(`${jobBoardFormDecorator}-languages[${key}].Language`, {
        initialValue: languagesSkillProficiency?.[key]?.Language,
      })(
        <Select
          placeholder={<FormattedMessage {...message.languageLabel} />}
          key={`${jobBoardFormDecorator}-languages[${key}].Language`}
          className={styles.languageSelect}
          dropdownClassName={styles.languageSelectPopover}
          getPopupContainer={trigger => trigger.parentNode}
          allowClear
          onChange={value => onLanguageChange(value, key)}
          optionFilterProp="title"
          showSearch
          defaultActiveFirstOption={false}
        >
          {remainingLanguages.map(language => (
            <Option key={language.value} value={language.value} title={language.displayText}>
              <FormattedMessage {...message[language.displayText.replace(/[()\-\/\s]/g, '')]} />
            </Option>
          ))}
        </Select>
      )}
      {form.getFieldDecorator(`${jobBoardFormDecorator}-languages[${key}].Proficiency`, {
        initialValue: languagesSkillProficiency?.[key]?.Proficiency,
      })(
        <Select
          placeholder={<FormattedMessage {...message.proficiencyLabel} />}
          key={`${jobBoardFormDecorator}-languages[${key}].Proficiency`}
          className={styles.proficiencySelect}
          dropdownClassName={styles.proficiencySelectPopover}
          getPopupContainer={trigger => trigger.parentNode}
          disabled={isProficiencyDisabled(key)}
        >
          {languageProficiencyLevels?.map(proficiency => (
            <Option key={proficiency.value} value={proficiency.value} title={proficiency.displayText}>
              <FormattedMessage {...message[proficiency.displayText.replace(/[()\-\/\s]/g, '')]} />
            </Option>
          ))}
        </Select>
      )}
      {keys.length > 1 ? (
        <Icon className={styles.languageDeleteButton} type="minus-circle-o" onClick={() => removeLanguage(key)} />
      ) : null}
    </Item>
  ));

  return (
    <div>
      <div className={styles.formItemLabelText}>
        <FormattedMessage {...message.languageProficiencyLabel} />
      </div>
      {languageSkillsFormItems}
      <Item>
        <Button
          key="add-more-languages"
          shape="round"
          onClick={addMoreLanguages}
          disabled={isAddLanguageButtonDisabled()}
        >
          <Icon type="plus" /> <FormattedMessage {...message.addMoreLanguagesLabel} />
        </Button>
      </Item>
    </div>
  );
}
