import React from 'react';
import classNames from 'classnames';
import styles from './LinkButton.module.scss';

export default function LinkButton(props) {
  const { buttonName, onClickButton, type, style } = props;
  return (
    <div
      role="button"
      className={classNames(styles.linkButton, { [styles.danger]: type === 'danger' })}
      tabIndex={0}
      onClick={onClickButton}
      onKeyPress={onClickButton}
      style={style}
    >
      {buttonName}
    </div>
  );
}
