import React from 'react';
import ClassicAdvancedOptions from './Classic/ClassicAdvancedOptions';
import PowerAdvancedOptions from './Power/PowerAdvancedOptions';

export default function MonsterAdvancedOptions(props) {
  const {
    form,
    defaultSettings,
    searchType,
    currentCompanies,
    monsterClassicCompanyCatagories,
    maskingConfig,
    diversityForNameText,
  } = props;
  const { IsNameMasked: isNameMasked } = maskingConfig;
  const tooltipText = isNameMasked ? diversityForNameText('name', 'Name') : '';

  if (searchType && searchType.toLowerCase() === 'power') {
    return (
      <PowerAdvancedOptions
        form={form}
        defaultSettings={defaultSettings}
        currentCompanies={currentCompanies}
        isNameMasked={isNameMasked}
        tooltipText={tooltipText}
      />
    );
  }
  if (searchType && searchType.toLowerCase() === 'classic') {
    return (
      <ClassicAdvancedOptions
        form={form}
        defaultSettings={defaultSettings}
        currentCompanies={currentCompanies}
        monsterClassicCompanyCatagories={monsterClassicCompanyCatagories}
      />
    );
  }
  return null;
}
