import React from 'react';
import { message, Form } from 'antd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getNotification } from '../../Reducers/AppNotificationReducer';
import markNotificationCompletionAction from '../../Actions/AppNotificationActions';
import messages from '../ManualSearchV2/ToasterMessages';

const mapStateToProps = state => ({
  notification: getNotification(state),
});

const mapDispatchToProps = {
  markNotificationCompletion: markNotificationCompletionAction,
};

class AppToastNotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    const { notification, markNotificationCompletion, intl } = props;
    function replaceMergeTags(Message, mergeTags) {
      if (!mergeTags) return Message;
      return Message.replace(/\$\{(\w+)\}/g, (match, tag) => {
        return tag in mergeTags ? mergeTags[tag] : match;
      });
    }
    if (notification) {
      switch (notification.Type) {
        case 'LOADING': {
          const { MessageData, Message } = notification;
          const { messageId, mergeTags, mergeTagId } = MessageData;
          if (!messageId && Message) {
            message.loading(Message, notification.Duration || 3);
            markNotificationCompletion();
            break;
          }
          const messageToDisplay = intl.formatMessage({ ...messages[messageId] });
          let localizedMessages;
          if (mergeTagId) {
            localizedMessages = mergeTags
              ? Object.keys(mergeTags).reduce((acc, key) => {
                  const actionMessageId = mergeTags[key];
                  acc[key] = intl.formatMessage(messages[actionMessageId]);
                  return acc;
                }, {})
              : {};
          } else localizedMessages = mergeTags || {};
          const resultMessage = replaceMergeTags(messageToDisplay, localizedMessages);
          message.loading(resultMessage, notification.Duration || 3);
          markNotificationCompletion();
          break;
        }

        case 'SUCCESS': {
          const { MessageData, Message } = notification;
          const { messageId, mergeTags, mergeTagId } = MessageData;
          if (!messageId && Message) {
            message.success(Message, notification.Duration || 3);
            markNotificationCompletion();
            break;
          }
          const messageToDisplay = intl.formatMessage({ ...messages[messageId] });
          let localizedMessages;
          if (mergeTagId) {
            localizedMessages = mergeTags
              ? Object.keys(mergeTags).reduce((acc, key) => {
                  const actionMessageId = mergeTags[key];
                  acc[key] = intl.formatMessage(messages[actionMessageId]);
                  return acc;
                }, {})
              : {};
          } else localizedMessages = mergeTags || {};
          const resultMessage = replaceMergeTags(messageToDisplay, localizedMessages);
          message.success(resultMessage, notification.Duration || 3);
          markNotificationCompletion();
          break;
        }
        case 'ERROR': {
          const { MessageData, Message } = notification;
          const { messageId, mergeTags, mergeTagId } = MessageData;
          if (!messageId && Message) {
            message.error(Message, notification.Duration || 3);
            markNotificationCompletion();
            break;
          }
          const messageToDisplay = intl.formatMessage({ ...messages[messageId] });
          let localizedMessages;
          if (mergeTagId) {
            localizedMessages = mergeTags
              ? Object.keys(mergeTags).reduce((acc, key) => {
                  const actionMessageId = mergeTags[key];
                  acc[key] = intl.formatMessage(messages[actionMessageId]);
                  return acc;
                }, {})
              : {};
          } else localizedMessages = mergeTags || {};
          const resultMessage = replaceMergeTags(messageToDisplay, localizedMessages);
          message.error(resultMessage, notification.Duration || 3);
          markNotificationCompletion();
          break;
        }

        default:
          break;
      }
    }
    return state;
  }

  render() {
    return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(AppToastNotificationContainer)));
