import React from 'react';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Select, Button } from 'antd';
import messages from '../ApplicationSettings/messages';

const { Option } = Select;

function DistanceSettings(props) {
  const { form, refsArray, userConfig, roleType, updateConfigApiStatus, AppName } = props;

  const onSave = () => {
    const { patchUpdateConfig } = props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const configUpdate = {};
        if (values.Distance !== userConfig.Distance.Distance || values.Unit !== userConfig.Distance.Unit) {
          configUpdate.Distance = {
            Distance: values.Distance,
            Unit: values.Unit,
          };
        }
        if (Object.keys(configUpdate).length) {
          patchUpdateConfig(configUpdate);
        }
      }
    });
  };

  const isSaveDisabled = () => {
    const formValues = form.getFieldsValue();
    if (
      formValues.Unit !== _.get(userConfig, ['Distance', 'Unit'], undefined) ||
      formValues.Distance !== _.get(userConfig, ['Distance', 'Distance'], undefined)
    ) {
      return false;
    }
    return true;
  };

  const distances = [5, 10, 20, 30, 40, 50, 75, 100, 999];
  return (
    <div className="distance-settings">
      <div>
        <div
          className="distance-title"
          ref={input => {
            refsArray[2] = input;
          }}
        >
          <FormattedMessage {...messages.defaultDistance} />
        </div>
        <div className="distance-message">
          <FormattedMessage {...messages.defaultDistanceMessage} values={{ AppName }} />
        </div>
        {form.getFieldDecorator('Unit', {
          initialValue: _.get(userConfig, 'Distance', false) ? userConfig.Distance.Unit : 'Kilometers',
        })(
          <Select className="distance-select" disabled={roleType === 'Recruiter'}>
            <Option value="Miles"><FormattedMessage {...messages.milesLabel}  /></Option>
          </Select>
        )}
        {form.getFieldDecorator('Distance', {
          initialValue: _.get(userConfig, 'Distance', false) ? Math.min(userConfig.Distance.Distance, 999) : 50,
        })(
          <Select className="distance-input-number" disabled={roleType === 'Recruiter'}>
            {distances.map(distance => (
              <Option key={distance} value={distance}>
                {distance}
              </Option>
            ))}
          </Select>
        )}
      </div>
      <Button
        loading={updateConfigApiStatus === 'InProgress'}
        disabled={isSaveDisabled()}
        className="app-save"
        onClick={onSave}
      >
        <FormattedMessage {...messages.submitButtonText} />
      </Button>
    </div>
  );
}

export default Form.create()(injectIntl(DistanceSettings));
export { DistanceSettings as DistanceSettingsWithoutForm };
