import React from 'react';
import { Select, Tooltip, Form } from 'antd';
import MultiLocationContext from '../MultiLocationContext';

const LocationSelect = props => {
  const { placeholder, isRemoteJobChecked } = props;
  const {
    locationTooltipContent,
    Location,
    onChangeLocation,
    onSearchLocation,
    isLocationDisabled,
    filteredLocations,
    locationDropDownStatus,
    State,
    Label,
  } = React.useContext(MultiLocationContext);

  const valueObject = { value: Location || State, label: Label || Location };
  const isValueObjectEmpty = !valueObject.value && !valueObject.label;

  return (
    <Tooltip title={locationTooltipContent}>
      <Form.Item colon={false}>
        <Select
          value={isValueObjectEmpty ? undefined : valueObject}
          style={{ width: '100%' }}
          placeholder={placeholder}
          notFoundContent={locationDropDownStatus}
          onChange={onChangeLocation}
          onSearch={onSearchLocation}
          disabled={isLocationDisabled || isRemoteJobChecked}
          showArrow={false}
          showSearch
          allowClear
          filterOption={false}
          labelInValue
        >
          {filteredLocations?.map(optionValue => (
            <Select.Option key={optionValue.value ?? optionValue} value={optionValue.value ?? optionValue}>
              {optionValue.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  );
};

export default LocationSelect;
