const candidateList = {
  goToSourcedBucket: 'GO_TO_SOURCED_BUCKET',
  goToShortlistedBucket: 'GO_TO_SHORTLISTED_BUCKET',
  goToConnectedBucket: 'GO_TO_CONNECTED_BUCKET',
  goToAppliedBucket: 'GO_TO_APPLIED_BUCKET',
  goToFavouriteBucket: 'GO_TO_FAVOURITE_BUCKET',
};

const candidateView = {
  view: 'CANDIDATE_VIEW',
  viewInNewTab: 'CANDIDATE_VIEW_NEW_TAB',
  viewCandidateCustom: 'CANDIDATE_VIEW_CUSTOM',
  viewProfile: 'CANDIDATE_VIEW_PROFILE',
};

const bulkActions = {
  shortlist: 'BULK_SHORTLIST',
  mail: 'BULK_MAIL',
  sms: 'BULK_SMS',
  contactPull: 'BULK_CONTACT_PULL',
};

const candidateContactPull = {
  pullMoreContact: 'MORE_CONTACT_PULL',
  pullContact: 'CONTACT_PULL',
  requestRefund: 'REQUEST_REFUND_CONTACT_PULL',
  confirmRefundRequest: 'CONFIRM_REQUEST_REFUND_CONTACT_PULL',
  cancelRefundRequest: 'CANCEL_REQUEST_REFUND_CONTACT_PULL',
  addEmail: 'CONTACT_PULL_ADD_EMAIL',
  addPhone: 'CONTACT_PULL_ADD_PHONE',
};
const candidateShortlist = {
  shortlistCandidate: 'CANDIDATE_SHORTLIST',
  unshortlistCandidate: 'CANDIDATE_UNSHORTLIST',
};
const candidateReject = {
  rejectCandidate: 'CANDIDATE_REJECT',
  unrejectCandidate: 'CANDIDATE_UNREJECT',
  confirmRejectCandidate: 'CONFIRM_CANDIDATE_REJECT',
};
const candidateProfile = {
  goToCandidateProfile: 'GO_TO_CANDIDATE_PROFILE',
  goToCandidatePersonality: 'GO_TO_CANDIDATE_PERSONALITY',
  goToCandidateIntel: 'GO_TO_CANDIDATE_INTEL',
  goToCandidateNotes: 'GO_TO_CANDIDATE_NOTES',
  addCandidateNotes: 'ADD_CANDIDATE_NOTES',
};

const candidate360Profile = {
  goToCandidateResume: 'GO_TO_CANDIDATE_360_RESUME',
  goToCandidateScoreDetails: 'GO_TO_CANDIDATE_360_SCORE_DETAILS',
  goToCandidateWorkHistory: 'GO_TO_CANDIDATE_360_WORK_HISTORY',
  goToCandidateJobs: 'GO_TO_CANDIDATE_360_JOBS',
  goToCandidateSegmentsAndCampaigns: 'GO_TO_CANDIDATE_360_SEGMENTS_AND_CAMPAIGNS',
  goToCandidateActivityLogs: 'GO_TO_CANDIDATE_360_ACTIVITY_LOGS',
  goToCandidateContact: 'GO_TO_CANDIDATE_360_CONTACT',
  goToCandidateNotes: 'GO_TO_CANDIDATE_360_NOTES',
  addCandidateNotes: 'ADD_CANDIDATE_360_NOTES',
  findMatchingJobs: 'FIND_CANDIDATE_MATCHING_JOBS',
  updateCandidateBookmark: 'UPDATE_CANDIDATE_360_BOOKMARK',
  goToCandidateSkills: 'GO_TO_CANDIDATE_360_SKILLS',
  goToCandidateEducation: 'GO_TO_CANDIDATE_360_EDUCATION',
  goToCandidateMetadataTags: 'GO_TO_CANDIDATE_360_METADATA_TAGS',
  goToCandidateTags: 'GO_TO_CANDIDATE_360_TAGS',
  addCandidateTags: 'ADD_CANDIDATE_360_TAGS',
};

const candidateConnect = {
  goToMailWindow: 'GO_TO_CANDIDATE_EMAIL',
  goToSmsWindow: 'GO_TO_CANDIDATE_SMS',
  sendSms: 'SEND_CANDIDATE_SMS',
  sendMail: 'SEND_CANDIDATE_MAIL',
  composeSms: 'COMPOSE_CANDIDATE_SMS',
  composeMail: 'COMPOSE_CANDIDATE_MAIL',
  useChatGptPromptForEmail: 'CHAT_GPT_PROMPT_FOR_EMAIL',
  stopChatGptPromptForEmailGenerate: 'STOP_CHAT_GPT_PROMPT_FOR_EMAIL',
  regenerateEmailUsingChatGpt: 'REGENERATE_EMAIL_USING_CHAT_GPT',
  disconnectCall: 'DISCONNECT_CALL_CANDIDATE',
  goToCallWindow: 'GO_TO_CANDIDATE_CALL',
  callCandidate: 'CALL_CANDIDATE',
  callCandidateRedial: 'CALL_CANDIDATE_REDIAL',
  goToCallNotes: 'GO_TO_CALL_NOTES',
  addCallNotes: 'ADD_CALL_NOTES',
  goToDialPad: 'GO_TO_DIAL_PAD',
  cancelDripEmail: 'CANCEL_DRIP_MAIL',
  confirmDripEmailCancellation: 'CONFIRM_DRIP_MAIL_CANCELLATION',
  cancelDripEmailCancellation: 'CANCEL_DRIP_MAIL_CANCELLATION',
};

const candidateConnectBot = {
  botConnectCandidate: 'CANDIDATE_CONNECT_BOT',
  configureBot: 'CANDIDATE_CONNECT_CONFIGURE_BOT',
  sendBotMessage: 'CANDIDATE_CONNECT_SEND_BOT_MESSAGE',
  interruptBotMessage: 'CANDIDATE_CONNECT_INTERRUPT_BOT_MESSAGE',
};

const candidateLinks = {
  linkedIn: 'GO_TO_CANDIDATE_LINKEDIN',
  source: 'GO_TO_CANDIDATE_SOURCELINK',
};
const candidateResume = {
  download: 'DOWNLOAD_CANDIDATE_RESUME',
  unlock: 'UNLOCK_CANDIDATE_RESUME',
};

const candidateEventTypes = {
  bulkActions,
  candidateConnectBot,
  candidateConnect,
  candidateContactPull,
  candidateList,
  candidateProfile,
  candidate360Profile,
  candidateReject,
  candidateResume,
  candidateShortlist,
  candidateView,
  candidateLinks,
};

export const candidateModules = {
  candidateActions: 'Candidate Actions',
};

export default candidateEventTypes;
