import React from 'react';
import { Form } from 'antd';
import RichTextEditor from '../../Editor/RichTextEditor';
import styles from './WorkflowComponents.module.scss';

const WorkflowEmail = ({ renderState, subject, description, body, onClick, id, status, onChangeEmailContent }) => {
  const editorRef = React.useRef(null);

  const onMailClick = () => {
    onClick(id);
  };
  const onSubjectChange = content => {
    const { htmlContent: htmlContentData } = content;
    onChangeEmailContent('Subject', htmlContentData, id);
  };

  const onBodyChange = content => {
    const { htmlContent: htmlContentData } = content;
    onChangeEmailContent('Body', htmlContentData, id);
  };
  if (renderState === 'min')
    return (
      <div onClick={onMailClick} role="presentation" className={styles.minimizedContainer}>
        <div>{description}</div>
        <div className={styles.mailStatus}>{status}</div>
      </div>
    );
  return (
    <div className={styles.maxmizedContainer}>
      <div className={styles.subjectContainer}>
        <RichTextEditor
          className="mail-subject-editor"
          editorContent={{ htmlContent: subject }}
          onChange={onSubjectChange}
          showToolbar={false}
          placeholder="Subject Line"
          disabled
        />
      </div>
      <div className={styles.EmailEditor}>
        <RichTextEditor
          className={styles.workflowEmailEditor}
          editorContent={{ htmlContent: body }}
          type="body"
          ref={editorRef}
          isSubmitButtonEnabled
          onChange={onBodyChange}
          mergeTags={[]}
          showToolbar
          disabled
          placeholder="Enter your text here"
          isChatGptContentGenerated={false}
          textEditorContentVisibility
          footerButtons={[]}
          isCandidate360MailWindow
        />
      </div>
    </div>
  );
};

export { WorkflowEmail as WorkflowEmailWithoutForm };
export default Form.create()(WorkflowEmail);
