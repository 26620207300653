import React from 'react';
import { Modal, Alert, Radio,Form } from 'antd';
import { FormattedMessage,injectIntl } from 'react-intl';
import styles from './DeleteCandidateModal.module.scss';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

function DeleteCandidateModal(props) {
  const { isVisible, onCancel, onOk, selctedOption, setSelectedOption, activeTab,intl } = props;
  const message = intl.formatMessage({ ...messages.deletingCandidateWillRemoveFromTheOnGoingDripCampaignLabel });
  const includedTabMessage = intl.formatMessage({ ...messages.deletingThisCandidateWillMoveThemToRemovedBucket });

  const onChange = e => {
    setSelectedOption(e.target.value);
  };
  const showSubsegmentOptions = activeTab === 'subsegment';
  return (
    <div>
      <Modal
        visible={isVisible}
        title={<FormattedMessage {...messages.deleteCandidateLabel} />}
        onCancel={onCancel}
        onOk={onOk}
        okButtonProps={{ type: 'primary', size: 'medium', shape: 'round' }}
        cancelButtonProps={{ size: 'medium', shape: 'round' }}
        okText={intl.formatMessage({ ...messages.yesLabel })}
        zIndex={2002}
      >
        <div className={styles.alertMessage}>
          <Alert message={activeTab === 'subsegment' ? message : includedTabMessage} type="warning" showIcon />
          {showSubsegmentOptions && (
            <Radio.Group onChange={onChange} style={{ marginLeft: 28 }} value={selctedOption}>
              <Radio value={1}><FormattedMessage {...messages.fromSubSegmentLabel} /></Radio>
              <Radio value={2}><FormattedMessage {...messages.bothSegmentAndSubSegmentLabel} /></Radio>
            </Radio.Group>
          )}
        </div>
      </Modal>
    </div>
  );
}


export default (injectIntl(Form.create()(DeleteCandidateModal)));
