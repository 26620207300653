import React from 'react';
import { Input } from 'antd';
import { Grid } from 'react-virtualized';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import CheckboxItem from './CheckboxItem';
import BoooleanSelector from '../../../Components/Common/BooleanSelector/BooleanSelector';
import LinkButton from '../../../Components/Common/LinkButton/LinkButton';

const { Search } = Input;

const Cell = React.memo(({ columnIndex, rowIndex, style, data }) => {
  const skill = data.skills[rowIndex * data.columnCount + columnIndex];
  if (!skill) return null;
  const isSkillHighlighted = data.sortedAllTabAggregationFilter.Skills[skill].IsHighlighted;
  const { onChange, value: formValue } = data;

  const onChangeSkills = e => {
    const { checked, value } = e.target;
    if (checked) onChange([...formValue, value]);
    else onChange(formValue.filter(item => item !== value));
  };

  return (
    <div style={{ ...style, width: '100%' }}>
      <CheckboxItem
        value={skill}
        text={skill}
        count={data.sortedAllTabAggregationFilter.Skills[skill].CandidateIds?.length || 0}
        isHighlighted={isSkillHighlighted}
        maxTextLength={15}
        onChange={onChangeSkills}
        checked={formValue.includes(skill)}
      />
    </div>
  );
});

const SkillsFilter = React.memo(
  ({
    sortedAllTabAggregationFilter,
    form,
    candidatesSkillsOperationField,
    allTabKeywordsOperation,
    candidatesSkillsField,
    allTabSelectedAggregationFilter,
    filterRef,
  }) => {
    const [keywordSearchTerm, setKeywordSearchTerm] = React.useState('');

    const handleKeywordSearchChange = e => {
      setKeywordSearchTerm(e.target.value);
    };

    const columnCount = 4;
    const filteredSkills = React.useMemo(
      () =>
        Object.keys(sortedAllTabAggregationFilter.Skills || {}).filter(skill =>
          skill.toLowerCase().includes(keywordSearchTerm.toLowerCase())
        ),
      [keywordSearchTerm, sortedAllTabAggregationFilter.Skills]
    );

    const skills = filteredSkills;
    const rowCount = Math.ceil(skills.length / columnCount);

    const columnWidth = 210;
    const rowHeight = 36;
    const gridHeight = Math.min(rowHeight * rowCount, 200);

    const cellRenderer = ({ columnIndex, rowIndex, style }) => (
      <Cell
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        style={style}
        data={{
          skills,
          sortedAllTabAggregationFilter,
          columnCount,
          onChange: form.getFieldProps(candidatesSkillsField).onChange,
          value: form.getFieldValue(candidatesSkillsField) || [],
        }}
      />
    );

    return (
      <div className={styles.filterSection}>
        <div className={styles.filterSectionHeader}>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <span className={styles.subsectionTitle}>Skills/Keywords</span>
            <Search
              className={styles.searchBox}
              placeholder="Search"
              allowClear
              value={keywordSearchTerm}
              onChange={handleKeywordSearchChange}
            />
          </div>
          {form.getFieldDecorator(candidatesSkillsOperationField, { initialValue: allTabKeywordsOperation })(
            <BoooleanSelector />
          )}
          <LinkButton
            buttonName="Clear all"
            onClickButton={() => {
              form.getFieldProps(candidatesSkillsField).onChange([]);
            }}
            style={{ color: 'rgba(7, 16, 26, 0.5)' }}
          />
        </div>
        {form.getFieldDecorator(candidatesSkillsField, {
          initialValue: allTabSelectedAggregationFilter.Skills,
        })(
          <Grid
            columnCount={columnCount}
            columnWidth={columnWidth}
            height={gridHeight}
            rowCount={rowCount}
            rowHeight={rowHeight}
            width={840}
            cellRenderer={cellRenderer}
            style={{ overflowX: 'hidden', maxHeight: '200px' }}
            ref={filterRef}
          />
        )}
      </div>
    );
  }
);

export default SkillsFilter;
