import React from 'react';
import {  FormattedMessage } from 'react-intl';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';
import styles from './PhoneNumberItem.module.scss';

function PhoneNumberItem(props) {
  const { phoneNumber, deletePhoneNumber, index } = props;
  return (
    <div className="phone-number-item">
      <div className="phone-number-column">{phoneNumber.CallerId}</div>
      <div className="region-column">
        <div className="region">{phoneNumber.CountryCode}</div>
        <div className="phone-number-action-buttons">
          {/* <Button className="edit-phone-number" onClick={() => this.changeEditMode(true)}>
                  edit
                </Button> */}
          <div
            role="button"
            className={styles.deletePhoneNumber}
            tabIndex={0}
            onClick={() => deletePhoneNumber(index)}
            onKeyPress={() => deletePhoneNumber(index)}
          >
            <FormattedMessage {...messages.deleteLabel} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneNumberItem;
