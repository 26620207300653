import React from 'react';
import { Modal, Button, Checkbox, Icon } from 'antd';

class CreditInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
    };
    this.handleOk = this.handleOk.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
  }

  handleCheckboxClick() {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  }

  handleOk() {
    const { creditInfoShowAgainStatusUpdate } = this.props;
    const { checked } = this.state;
    creditInfoShowAgainStatusUpdate(checked);
  }

  render() {
    const { credits, visible, onCreditInfoModalCancel, availableCredits, userConfig } = this.props;

    const activeTabDisplayName = userConfig?.ActiveChannelAlias || 'active';
    const availableCreditValue = availableCredits?.['Arya'].AvailableCredits;
    const { checked } = this.state;
    let showAvailableCredit = true;
    if (typeof availableCreditValue === 'string' && availableCreditValue?.toLowerCase() === 'unlimited') {
      showAvailableCredit = false;
    }
    return (
      <div>
        {showAvailableCredit ? (
          <Modal
            title={null}
            visible={visible}
            footer={null}
            closable={false}
            centered
            zIndex={2500}
            maskStyle={{ zIndex: 2400 }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'inline', marginRight: '16px', paddingTop: '4px' }}>
                <Icon type="exclamation-circle" theme="filled" style={{ fontSize: '22px', color: '	#ffcc00' }} />
              </div>

              <div>
                <h3 id="credit-deduction-header">Credit Deduction</h3>
                <p style={{ marginBottom: '24px', marginTop: '8px' }}>
                  You will be charged {credits} credit for each unique {activeTabDisplayName} profile view or contact
                  pull per job.
                </p>
                <Checkbox checked={checked} onChange={this.handleCheckboxClick}>
                  Dont show again
                </Checkbox>
                <div style={{ float: 'right' }}>
                  <Button
                    type="default"
                    shape="round"
                    style={{ marginRight: '8px' }}
                    key="cancel-button"
                    onClick={onCreditInfoModalCancel}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" shape="round" key="ok-button" onClick={this.handleOk}>
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default CreditInfo;
