import _ from 'lodash';
import * as emailTemplatesApi from '../Repository/EmailTemplates';
import { setNotification } from './ActionCreators/ConnectActions';
import { fetchConnectUsers } from './UserActions';

// function getEmailTemplates(from, size) {
//   return dispatch => {
//     emailTemplatesApi.getEmailTemplates(from, size).then(response => {
//       dispatch({
//         type: 'SET_EMAIL_TEMPLATES',
//         payload: response.data.Templates,
//       });
//       dispatch({
//         type: 'SET_EMAIL_TEMPLATES_COUNT',
//         payload: response.data.Total,
//       });
//     });
//   };
// }

function searchEmailTemplates(filter, isFetchingTemplatesForAutomation = false, dispatchApiStatus = true) {
  return async dispatch => {
    if (dispatchApiStatus) {
      dispatch({
        type: 'SET_EMAIL_TEMPLATES_API_STATUS',
        payload: 'INPROGRESS',
      });
    }
    const { ExcludeTags = ['workflow'] } = filter;
    const response = await emailTemplatesApi.searchEmailTemplates({
      ...filter,
      LanguageCode: 'en-US',
      ExcludeTags,
    });
    if (!isFetchingTemplatesForAutomation || filter.From === 0) {
      dispatch({
        type: 'SET_EMAIL_TEMPLATES',
        payload: response.data.Templates,
      });
    } else {
      dispatch({
        type: 'APPEND_EMAIL_TEMPLATES',
        payload: {
          templates: response.data.Templates,
        },
      });
    }
    dispatch(
      fetchConnectUsers({
        userIds: _.uniq(response.data.Templates.map(template => template.CreatedBy)),
      })
    );
    dispatch({
      type: 'SET_EMAIL_TEMPLATES_COUNT',
      payload: response.data.Total,
    });
    if (dispatchApiStatus) {
      dispatch({
        type: 'SET_EMAIL_TEMPLATES_API_STATUS',
        payload: 'SUCCESS',
      });
    }
  };
}

function searchSystemEmailTemplates(filter, dispatchApiStatus = true) {
  return dispatch => {
    if (dispatchApiStatus) {
      dispatch({
        type: 'SET_EMAIL_TEMPLATES_API_STATUS',
        payload: 'INPROGRESS',
      });
    }
    emailTemplatesApi.searchSystemEmailTemplates(filter).then(response => {
      const { Templates, Total } = response.data;
      dispatch({
        type: 'SET_EMAIL_TEMPLATES',
        payload: Templates,
      });
      dispatch(
        fetchConnectUsers({
          userIds: _.uniq(Templates.map(template => template.UpdatedBy)),
        })
      );
      dispatch({
        type: 'SET_EMAIL_TEMPLATES_COUNT',
        payload: Total,
      });
      if (dispatchApiStatus) {
        dispatch({
          type: 'SET_EMAIL_TEMPLATES_API_STATUS',
          payload: 'SUCCESS',
        });
      }
    });
  };
}

function editSystemEmailTemplate(templateId, template, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'updatingSystemEmailTemplate',
      })
    );
    emailTemplatesApi.putSystemEmailTemplate(templateId, template).then(() => {
      dispatch(searchSystemEmailTemplates(filter, false));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'systemEmailTemplateUpdated',
        })
      );
    });
  };
}

function postEmailTemplate(template, filter, fetchTemplate = true) {
  return async dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'creatingEmailTemplate',
      })
    );
    try {
      const response = await emailTemplatesApi.postEmailTemplate(template);
      if (fetchTemplate) {
        await dispatch(searchEmailTemplates(filter, false, false));
      }
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'emailTemplateCreated',
        })
      );
      return response.data.id;
    } catch (e) {
      return null;
    }
  };
}

function getEmailTemplate(templateId) {
  return async dispatch => {
    try {
      const response = await emailTemplatesApi.getEmailTemplate(templateId);
      const templateDetails = response.data;
      dispatch({
        type: 'SET_EMAIL_TEMPLATE_DETAILS',
        payload: {
          template: templateDetails,
        },
      });
      return templateDetails;
    } catch (error) {
      if (error.response.status !== 403)
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      return {};
    }
  };
}

function editEmailTemplate(templateId, template, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'updatingEmailTemplate',
      })
    );
    emailTemplatesApi.putEmailTemplate(templateId, template).then(() => {
      dispatch(searchEmailTemplates(filter, false, false));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'emailTemplateUpdated',
        })
      );
    });
  };
}

function deleteEmailTemplate(templateId, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'deletingEmailTemplate',
      })
    );
    emailTemplatesApi.deleteEmailTemplate(templateId).then(() => {
      dispatch(searchEmailTemplates(filter, false, false));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'emailTemplateDeleted',
        })
      );
    });
  };
}

function duplicateBulkEmailTemplates(templatesKeyValuePairs) {
  return async () => {
    try {
      const response = await emailTemplatesApi.duplicateBulkEmailTemplates(templatesKeyValuePairs);
      return response.data;
    } catch (e) {
      return {};
    }
  };
}

function duplicateEmailTemplate(templateId, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'duplicatingEmailTemplate',
      })
    );
    emailTemplatesApi.duplicateEmailTemplate(templateId).then(() => {
      dispatch(searchEmailTemplates(filter, false, false));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'emailTemplateDuplicated',
        })
      );
    });
  };
}

function shareEmailTemplate(templateId, shareOptions) {
  let successMessage = 'templateSharedSuccessfully';
  let loadingMessage = 'sharingTemplate';
  if (shareOptions.isShareWithAll) {
    loadingMessage = 'sharingTemplateWithAll';
  }
  if (shareOptions.isUnshareWithAll) {
    successMessage = 'templateUnSharedSuccessfully';
    loadingMessage = 'unSharingTemplateWithAll';
  }
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: loadingMessage,
      })
    );
    emailTemplatesApi
      .shareEmailTemplate(templateId, shareOptions)
      .then(() => {
        dispatch({
          type: 'SET_EMAIL_TEMPLATE_SHARED_WITH',
          payload: shareOptions,
          templateId,
        });
        dispatch(
          setNotification('SUCCESS', {
            messageId: successMessage,
          })
        );
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SHARE_TEMPLATE_POST_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(
          setNotification('ERROR', {
            messageId: 'templateSharingFailed',
          })
        );
      });
  };
}

function testEmailTemplate(Subject, Body) {
  return dispatch => {
    dispatch({
      type: 'SET_TEST_TEMPLATE_API_STATUS',
      payload: 'INPROGRESS',
    });
    emailTemplatesApi
      .testEmailTemplate(Subject, Body)
      .then(() => {
        dispatch({
          type: 'SET_TEST_TEMPLATE_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'SET_TEST_TEMPLATE_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

function clearTestEmailTemplateState() {
  return dispatch => {
    dispatch({
      type: 'SET_TEST_TEMPLATE_API_STATUS',
      payload: null,
    });
  };
}

export {
  // getEmailTemplates,
  shareEmailTemplate,
  searchEmailTemplates,
  postEmailTemplate,
  getEmailTemplate,
  editEmailTemplate,
  deleteEmailTemplate,
  duplicateBulkEmailTemplates,
  duplicateEmailTemplate,
  testEmailTemplate,
  clearTestEmailTemplateState,
  searchSystemEmailTemplates,
  editSystemEmailTemplate,
};
