export const SET_CHAT_ID_FOR_THE_JOB = 'SET_CHAT_ID_FOR_THE_JOB';
export const SET_CHATS_FOR_THE_JOB = 'SET_CHATS_FOR_THE_JOB';
export const SET_TOPIC_ID_FOR_THE_JOB = 'SET_TOPIC_ID_FOR_THE_JOB';
export const ADD_THREAD_TO_THE_CHAT = 'ADD_THREAD_TO_THE_CHAT';
export const ADD_REPLY_TO_THE_THREAD = 'ADD_REPLY_TO_THE_THREAD';
export const SET_ALL_REPLIES_FOR_THE_THREAD = 'SET_ALL_REPLIES_FOR_THE_THREAD';
export const SET_LAST_FETCHED_THREAD_COUNT = 'SET_LAST_FETCHED_THREAD_COUNT';
export const SET_HAS_MORE_REPLIES = 'SET_HAS_MORE_REPLIES';
export const SET_UNREAD_COUNT_FOR_CHAT = 'SET_UNREAD_COUNT_FOR_CHAT';
export const ADD_UNREAD_COUNT_FOR_CHAT = 'ADD_UNREAD_COUNT_FOR_CHAT';

export function setUnreadCountForChat({ chatId, unreadMessageCount }) {
  return {
    type: SET_UNREAD_COUNT_FOR_CHAT,
    payload: { chatId, unreadMessageCount },
  };
}

export function addUnreadCountForChat({ chatId, messageId, unreadMessageCount }) {
  return {
    type: ADD_UNREAD_COUNT_FOR_CHAT,
    payload: { chatId, messageId, unreadMessageCount },
  };
}
export function setAllRepliesForTheThread({ chatId, messageId, replyMessages, defaultLoad }) {
  return {
    type: SET_ALL_REPLIES_FOR_THE_THREAD,
    payload: { chatId, messageId, replyMessages, defaultLoad },
  };
}
export function setChatIdForTheJob(jobId, chatId) {
  return {
    type: SET_CHAT_ID_FOR_THE_JOB,
    payload: { jobId, chatId },
  };
}

export function setTopicIdForTheJob({ topicId, jobId }) {
  return {
    type: SET_TOPIC_ID_FOR_THE_JOB,
    payload: { jobId, topicId },
  };
}

export function addReplyToTheThread({ reply }) {
  return {
    type: ADD_REPLY_TO_THE_THREAD,
    payload: { reply },
  };
}

export function addThreadToTheChat({ thread }) {
  return {
    type: ADD_THREAD_TO_THE_CHAT,
    payload: { thread },
  };
}

export function setChatsForTheJob(chatId, messageItems) {
  return {
    type: SET_CHATS_FOR_THE_JOB,
    payload: {
      chatId,
      messages: messageItems,
    },
  };
}

export function setLastFetchedThreadCountForChatId(chatId, threadCount) {
  return {
    type: SET_LAST_FETCHED_THREAD_COUNT,
    payload: {
      chatId,
      threadCount,
    },
  };
}

export function setHasMoreRepliesForTheThread(chatId, threadId, hasMoreReplies) {
  return {
    type: SET_HAS_MORE_REPLIES,
    payload: {
      chatId,
      threadId,
      hasMoreReplies,
    },
  };
}
