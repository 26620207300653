import _ from 'lodash';
import { SET_CREATE_ORDER_API_ERROR_MESSAGE } from '../Actions/ActionCreators/AryaPayOrderActionCreator';

function AryaPayReducer(state = {}, action) {
  let newState;
  let paymentProfiles;
  let id;
  let paymentProfilesById;
  switch (action.type) {
    case 'SET_AVAILABLE_CREDITS':
      _.set(
        state,
        ['CreditsInformation', [action.payload.type], 'AvailableCredits'],
        action.payload.data.PlanType === 'Unlimited' ? 'Unlimited' : action.payload.data.AvailableCredits
      );
      return {
        ...state,
      };
    case 'SET_CREATE_ORDER_API_STATUS':
      return {
        ...state,
        createOrderApiStatus: action.payload,
      };
    case SET_CREATE_ORDER_API_ERROR_MESSAGE:
      return {
        ...state,
        createOrderApiErrorMessage: action.payload,
      };
    case 'RESET_ARYAPAY_API_STATUS':
      return { ...state, paymentApiStatus: null, createOrderApiStatus: null, saveCardDetailsApiStatus: null };
    case 'SET_CREATE_PAYMENT_API_STATUS':
      return {
        ...state,
        paymentApiStatus: action.payload,
      };
    case 'SET_PAYMENT_PROFILES':
      newState = _.cloneDeep(state);
      paymentProfiles = _.get(action, ['payload'], []);
      newState.PaymentProfile = paymentProfiles;
      paymentProfiles.forEach(paymentProfile => {
        id = paymentProfile.ProfileId;
        _.setWith(newState, ['PaymentProfilesById', id], paymentProfile, Object);
      });
      return newState;

    case 'DELETE_PAYMENT_PROFILE':
      newState = _.cloneDeep(state);
      id = _.get(action, ['payload', 'paymentProfileId'], null);
      paymentProfiles = _.get(newState, 'PaymentProfile', []);
      paymentProfiles = paymentProfiles.filter(paymentProfile => paymentProfile.ProfileId !== id);
      newState.PaymentProfile = paymentProfiles;
      paymentProfilesById = newState.PaymentProfilesById;
      delete paymentProfilesById[id];
      newState.PaymentProfilesById = paymentProfilesById;
      return newState;

    case 'SET_SAVE_CARD_DETAILS_API_STATUS':
      return {
        ...state,
        saveCardDetailsApiStatus: action.payload,
      };

    case 'RESET_SAVE_CARD_DETAILS_API_STATUS':
      return {
        ...state,
        saveCardDetailsApiStatus: null,
      };

    case 'SET_ORDER_NUMBER':
      return {
        ...state,
        invoiceNumber: action.payload.InvoiceNumber,
      };
    case 'SET_UPDATE_BILLING_INFO_API_STATUS':
      return {
        ...state,
        updateBillingInfoApiStatus: action.payload,
      };
    default:
      return state;
  }
}

function getUpdateBillingInfoApiStatus(state) {
  return _.get(state, ['AryaPayReducer', 'updateBillingInfoApiStatus'], null);
}
function getAvailableCredits(state) {
  return state?.AryaPayReducer?.CreditsInformation;
}

function getCreateOrderApiStatus(state) {
  return _.get(state, ['AryaPayReducer', 'createOrderApiStatus'], null);
}
function getCreateOrderApiErrorMessage(state) {
  return state?.AryaPayReducer?.createOrderApiErrorMessage;
}

function getPaymentApiStatus(state) {
  return _.get(state, ['AryaPayReducer', 'paymentApiStatus'], null);
}
function getPaymentProfile(state) {
  return _.get(state, ['AryaPayReducer', 'PaymentProfile'], []);
}

function getAddPaymentProfileApiStatus(state) {
  return _.get(state, ['AryaPayReducer', 'saveCardDetailsApiStatus'], null);
}

function getOrderInvoiceNumber(state) {
  return _.get(state, ['AryaPayReducer', 'invoiceNumber'], null);
}

function getPaymentProfilesById(state) {
  return _.get(state, ['AryaPayReducer', 'PaymentProfilesById'], {});
}

export {
  AryaPayReducer,
  getAvailableCredits,
  getCreateOrderApiStatus,
  getCreateOrderApiErrorMessage,
  getPaymentApiStatus,
  getPaymentProfile,
  getPaymentProfilesById,
  getAddPaymentProfileApiStatus,
  getOrderInvoiceNumber,
  getUpdateBillingInfoApiStatus,
};
