import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PubSub from 'pubsub-js';
import { Skeleton, message, Spin, Form } from 'antd';
import { injectIntl } from 'react-intl';
import WorkflowCreationContainer from '../WorkflowCreationContainer/WorkflowLayoutContainer/WorkflowLayoutContainer';
import * as WorkflowActions from '../../Actions/WorkflowActions';
import * as WorkflowWindowActions from '../../Actions/WorkflowWindowAction';
import { initialElements } from '../WorkflowCreationContainer/Data/InitialElements';
import * as WorkflowReducer from '../../Reducers/WorkflowReducer';
import * as WorkflowWindowReducer from '../../Reducers/WorkflowWindowReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as EmailTemplateActions from '../../Actions/EmailTemplates';
import * as SmsActions from '../../Actions/TextTemplates';
import { isPulseUser } from '../../Utils/ConfigUtils';
import WorfklowDetailsHeader from './WorfklowDetailsHeader';
import {
  generateWorkflowPayload,
  getStartActivityId,
  isCreatedWorkflowValid,
} from '../WorkflowCreationContainer/WorkflowUtils/WorkflowElementUtils';
import { generatePayload } from '../WorkflowCreationContainer/WorkflowUtils/PayloadGenerationUtility';
import WorkflowDetailsFooter from './WorkflowDetailsFooter';
import { BROADCAST_CHANNEL_POST_MESSAGE } from '../../PubSub/EventTypes';
import styles from './WorkflowDetailsContainer.module.scss';
import * as EmailTemplatesReducer from '../../Reducers/EmailTemplates';
import * as MergeTagsReducer from '../../Reducers/MergeTagsReducer';
import * as WorkflowReportActions from '../../Actions/WorkflowReportActions';
import * as WorkflowReprotReducer from '../../Reducers/WorkflowReportReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getFilteredMergeTags } from '../../Utils/MergeTagsUtils';
import connectmessage from '../Connect/ConnectMessages';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = (state, props) => {
  const { workflowId, type, location } = props;
  const templateDetails =
    (workflowId && !type) || (workflowId && type === 'preview') || (type === 'create' && workflowId)
      ? WorkflowReducer.getWorkflowDripTemplatesById(state, workflowId)
      : null;
  const urlSearchParams = new URLSearchParams(location?.search || null);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { instanceId, candidateId } = params;

  return {
    initialValues: templateDetails || initialElements,
    workflowInstanceActivity: WorkflowWindowReducer.getCandidateWorkflowHistoryByInstanceId(state, {
      instanceId,
      candidateId,
    }),
    workflowApiStatus: WorkflowReducer.getWorkflowApiStatusById(state, workflowId),
    candidateWorkflowHistoryApiStatus: getApiStatus(state, 'candidateWorkflowHistoryApiStatus'),
    emailTemplatesById: EmailTemplatesReducer.getEmailTemplatesById(state),
    mergeTags: MergeTagsReducer.getMergeTags(state),
    featureToggleList: getFeatureToggleList(state),
    workflowReportFilterData: WorkflowReprotReducer.getWorkflowReportFilterData(state),
    workflowReportApiStatus: WorkflowReprotReducer.getWorkflowReportApiStatus(state),
    isWorkflowReportModalVisible: WorkflowReprotReducer.getWorkflowReportModalVisibility(state),
    startWorkflowWindowApiStatus: getApiStatus(state, 'startWorkflowWindowApiStatus'),
    candidateWorkflowHistory: WorkflowWindowReducer.getCandidateWorkflowHistory(state, candidateId),
  };
};

const mapDispatchToProps = {
  setWorkflowTemplateFetchApiStatus: WorkflowActions.setWorkflowTemplateFetchApiStatus,
  searchEmailTemplates: EmailTemplateActions.searchEmailTemplates,
  duplicateBulkEmailTemplates: EmailTemplateActions.duplicateBulkEmailTemplates,
  fetchSmsTemplates: SmsActions.searchTextTemplates,
  saveWorkflowTemplate: WorkflowActions.saveWorkflowTemplate,
  setWorkflowReportData: WorkflowReportActions.setWorkflowReportData,
  setWorkflowReportFilterData: WorkflowReportActions.setWorkflowReportFilterData,
  setWorkflowReportModalVisibility: WorkflowReportActions.setWorkflowReportModalVisibility,
  fetchWorkflowTemplateById: WorkflowActions.fetchWorkflowTemplateById,
  setWorkflowReportEndNodesData: WorkflowReportActions.setWorkflowReportEndNodesData,
  setWorkflowPublishedStatus: WorkflowReportActions.setWorkflowPublishedStatus,
  setWorkflowNodeConnections: WorkflowActions.setWorkflowNodeConnections,
  startCandidateWorkflow: WorkflowWindowActions.startCandidateWorkflow,
  setCandidateWorkflowHistory: WorkflowWindowActions.setCandidateWorkflowHistory,
  SetNotification: setNotification,
};

const WorkflowDetailsContainer = props => {
  const [workflowName, setWorkflowName] = React.useState();
  const [validationMessage, setValidationMessage] = React.useState();
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  const [elements, setElements] = React.useState([]);

  const {
    type,
    workflowId,
    setUrl,
    initialValues,
    saveWorkflowTemplate,
    fetchWorkflowTemplateById,
    setWorkflowReportData,
    workflowApiStatus,
    setWorkflowTemplateFetchApiStatus,
    workflowReportFilterData,
    isWorkflowReportModalVisible,
    setWorkflowReportFilterData,
    setWorkflowReportModalVisibility,
    setWorkflowNodeConnections,
    setWorkflowReportEndNodesData,
    setWorkflowPublishedStatus,
    workflowReportApiStatus,
    searchEmailTemplates,
    fetchSmsTemplates,
    mergeTags,
    featureToggleList,
    version,
    jobDetails,
    startCandidateWorkflow,
    location,
    startWorkflowWindowApiStatus,
    workflowInstanceActivity,
    candidateWorkflowHistoryApiStatus,
    setCandidateWorkflowHistory,
    candidateWorkflowHistory,
    intl,
    SetNotification,
  } = props;

  const isWorkflowApiStatusCompleted = workflowApiStatus === 'Completed';
  const isDraft = _.get(location, ['state', 'isDraft'], false);
  const isDraftOrNotPublished = isDraft || !initialValues?.IsPublished;
  const urlSearchParams = new URLSearchParams(location?.search || null);
  const params = Object.fromEntries(urlSearchParams.entries());
  const {
    initiateWorkflowFlag,
    showReportsStats,
    workflowInitiated,
    candidateContextId,
    isWorkflowEditAllowedInPreview,
    isReadOnly,
    instanceId,
    jobGuid,
    candidateId,
  } = params;

  const filteredMergeTags = getFilteredMergeTags(
    mergeTags,
    {
      CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
      JobSummary: featureToggleList.JobSummary,
      CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
    },
    version,
    jobDetails
  );

  const isWorkflowLive = workflowReportFilterData.Jobs?.length;
  const onClose = () => {
    setWorkflowTemplateFetchApiStatus({ workflowId, status: null });
  };

  React.useEffect(() => {
    if (workflowId) {
      fetchWorkflowTemplateById(workflowId);
    }
    if (instanceId) {
      setCandidateWorkflowHistory(jobGuid, candidateId);
    }
    //! !TODO : Once support for instance id is added, make the changes here
    searchEmailTemplates({ From: 0, Size: 10, IncludeSystemTemplate: !isPulseUser(), ExcludeTags: [] });
    fetchSmsTemplates({ From: 0, Size: 10 });
    return () => {
      onClose();
    };
  }, []);

  React.useEffect(() => {
    if (type === 'preview' && !isDraft && initialValues?.IsPublished) {
      if (showReportsStats) {
        const data = {
          id: workflowId,
          aggregationIds: [workflowId],
        };
        setWorkflowReportData(data);
      }
      setWorkflowReportFilterData(workflowId);
      setWorkflowPublishedStatus(initialValues?.IsPublished);
    } else setWorkflowPublishedStatus(false);
    return () => {
      setWorkflowPublishedStatus(false);
    };
  }, [workflowId, type, initialValues?.IsPublished]);

  React.useEffect(() => {
    if (type === 'preview' && !isDraft && initialValues?.Connections?.length > 0) {
      setWorkflowNodeConnections({ connections: initialValues.Connections });
    }
    return () => {
      setWorkflowNodeConnections({ reinitiate: true });
    };
  }, [initialValues?.Connections]);

  React.useEffect(() => {
    if (isWorkflowApiStatusCompleted && type !== 'create') setWorkflowName(initialValues?.Name);
  }, [isWorkflowApiStatusCompleted]);

  React.useEffect(() => {
    if (initialValues?.IsPublished && showReportsStats) setWorkflowReportFilterData(workflowId);
  }, [initialValues?.IsPublished]);

  const workflowDetailsFetchInProgress = workflowApiStatus?.toLowerCase() === 'inprogress' || !workflowApiStatus;
  const workflowStatsFetchInProgress =
    candidateWorkflowHistoryApiStatus?.toLowerCase() === 'inprogress' || !candidateWorkflowHistoryApiStatus;
  const showLoading = (workflowId && workflowDetailsFetchInProgress) || (instanceId && workflowStatsFetchInProgress);

  const handleInputChange = event => {
    const name = event.target.value;
    setWorkflowName(name);
    if (name) setValidationMessage('');
    else if (!name?.trim())
      setValidationMessage(intl.formatMessage({ ...connectmessage.emptyWorkflowNameErrorMessage }));
    else if (name?.length > 100)
      setValidationMessage(intl.formatMessage({ ...connectmessage.maxCharLimitExceededErrorMessage }));
  };

  const validationStatus = validationMessage ? 'error' : '';

  const scrollToInput = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onSaveClickCallback = () => {
    if (!workflowName) {
      scrollToInput();
      setValidationMessage(intl.formatMessage({ ...connectmessage.emptyWorkflowNameErrorMessage }));
    } else if (isWorkflowLive) {
      if (initialValues?.Name === workflowName)
        setValidationMessage(intl.formatMessage({ ...connectmessage.pleaseEnterDifferentName }));
    }
  };

  const onEdit = () => {
    const _elements = elements.map(x => ({
      ...x,
      data: { ...x.data, type: undefined },
    }));
    setElements(_elements);
  };

  const getWorkflowPayload = ({ createNew, isPublish }) => {
    const isNewWorkFlow = type === 'create' || isWorkflowLive || createNew;
    const paylaod = generatePayload({ elements });
    const { nodes, edges } = paylaod;
    const startActivityId = getStartActivityId(nodes, edges);
    return generateWorkflowPayload({
      nodes,
      edges,
      workflowName,
      startActivityId,
      publish: isPublish,
      isCreate: isNewWorkFlow,
      editWorkflowDefinitionId: workflowId,
    });
  };

  const saveWorkflowDetails = async ({ isPublish, createNew, redirect }) => {
    onSaveClickCallback();
    if (!workflowName) {
      SetNotification('ERROR', {
        messageId: 'emptyWorkflowNameErrorMessage',
      });
      return;
    }
    if (isWorkflowLive || createNew) {
      if (initialValues?.Name?.trim() === workflowName?.trim()) {
        SetNotification('ERROR', {
          messageId: 'pleaseEnterDifferentName',
        });
        return;
      }
    }
    const { pathname } = location;
    const workflowPaylaod = getWorkflowPayload({ createNew, isPublish });

    try {
      setLoadingFlag(true);
      await saveWorkflowTemplate(workflowPaylaod);
      setLoadingFlag(false);
      if (redirect) setUrl(`${pathname}?tab=workflows`);
    } catch {
      setLoadingFlag(false);
    }
  };

  const onSaveWorkflow = ({ createNew, redirect }) => {
    saveWorkflowDetails({ isPublish: true, createNew, redirect });
  };

  const onSaveDraftWorkflow = () => {
    saveWorkflowDetails({ isPublish: false });
    localStorage.setItem('DraftSaved', true);
  };

  const initiateWorkflow = async ({ actionType }) => {
    const isStartExistingWorkflow = actionType === 'StartExisting';
    const isCloneAndStartWorkflow = actionType === 'CloneAndStart';
    const isSaveCopyAndStartWorkflow = actionType === 'SaveCopyAndStart';
    if (isSaveCopyAndStartWorkflow && initialValues?.Name?.trim() === workflowName?.trim()) {
      setNotification('ERROR', {
        messageId: 'workflowAlreadyExistingError',
        mergeTags: { workflowName },
      });
      return;
    }
    const workflowPaylaod = isStartExistingWorkflow
      ? null
      : {
          ...getWorkflowPayload({
            createNew: isCloneAndStartWorkflow || isSaveCopyAndStartWorkflow,
            isPublish: !isCloneAndStartWorkflow,
          }),
          IsTemporaryWorkflow: isCloneAndStartWorkflow,
        };
    const workflowCandidateContext = JSON.parse(localStorage.getItem('WorkflowCandidateContext'));
    const currentCandidateContext = workflowCandidateContext[candidateContextId];
    if (!currentCandidateContext) {
      SetNotification('ERROR', {
        messageId: 'initiationError',
      });
      return;
    }
    const startWorkflowData = {
      CandidateId: currentCandidateContext.candidateId,
      DefinitionId: initialValues.DefinitionId,
      DefinitionName: initialValues.Name,
      JobGuid: currentCandidateContext.jobGuid,
      JobId: currentCandidateContext.jobId,
      EmailActivityFromAddress: currentCandidateContext.selectedFromEmail,
      ToEmails: currentCandidateContext.selectedMultipleEmail,
    };
    await startCandidateWorkflow(startWorkflowData, workflowPaylaod);
    const eventData = {
      CandidateId: currentCandidateContext.candidateId,
      JobId: currentCandidateContext.jobId,
    };
    const broadcastNotification = {
      eventType: 'WorkflowInitiated',
      eventData,
    };
    PubSub.publish(BROADCAST_CHANNEL_POST_MESSAGE, {
      broadcastNotification,
    });
  };

  const saveButtonTooltipMessage = isCreatedWorkflowValid(elements);
  const isSaveDisabled = saveButtonTooltipMessage || !!validationStatus;
  const isSaveDraftDisabled = elements?.length <= 3 || !!validationStatus;

  const isFooterDisabled = type === 'preview' && !initiateWorkflowFlag;

  const displayWorkflowFooter = isFooterDisabled ? null : (
    <WorkflowDetailsFooter
      onSaveWorkflow={onSaveWorkflow}
      isCreate={type === 'create'}
      isPreview={type === 'preview'}
      isDraft={isDraftOrNotPublished}
      isWorkflowLive={isWorkflowLive}
      loadingFlag={loadingFlag}
      isSaveDisabled={isSaveDisabled}
      saveButtonTooltipMessage={saveButtonTooltipMessage}
      onSaveDraftWorkflow={onSaveDraftWorkflow}
      initiateWorkflow={initiateWorkflow}
      isSaveDraftDisabled={isSaveDraftDisabled}
      onCancelWorkflow={onClose}
      version={version}
      initiateWorkflowFlag={initiateWorkflowFlag}
      workflowInitiated={workflowInitiated}
      startWorkflowWindowApiStatus={startWorkflowWindowApiStatus}
    />
  );

  return (
    <div className={styles.workflowDetailsContainer}>
      <Spin spinning={workflowReportApiStatus}>
        <Skeleton loading={showLoading}>
          <WorfklowDetailsHeader
            setUrl={setUrl}
            isWorkflowEditAllowedInPreview={isWorkflowEditAllowedInPreview}
            handleInputChange={handleInputChange}
            validationStatus={validationStatus}
            displayMode={type}
            validationMessage={validationMessage}
            workflowName={workflowName}
            workflowId={workflowId}
            setWorkflowReportData={setWorkflowReportData}
            workflowReportFilterData={workflowReportFilterData}
            setWorkflowReportEndNodesData={setWorkflowReportEndNodesData}
            onCancelWorkflow={onClose}
            isDraft={isDraftOrNotPublished}
            isWorkflowLive={isWorkflowLive}
            isCandidateCRMFeatureEnabled={featureToggleList.CandidateCRM.IsEnabled}
            location={location}
            version={version}
            onEdit={onEdit}
            candidateContextId={candidateContextId}
            showReportsStats={showReportsStats}
            isDisclaimerMessageVisible={!initiateWorkflowFlag && !isReadOnly}
            isEditOrUseItButtonVisible={type === 'preview' && !isReadOnly}
            workflowHistory={candidateWorkflowHistory}
          />
          {initialValues?.Connections && (
            <>
              <WorkflowCreationContainer
                workflowName={workflowName}
                setWorkflowName={setWorkflowName}
                initialElements={initialValues}
                workflowInstanceActivity={workflowInstanceActivity}
                saveWorkflowTemplate={saveWorkflowTemplate}
                editWorkflowDefinitionId={workflowId}
                setUrl={setUrl}
                onCancelWorkflow={onClose}
                validationStatus={validationStatus}
                onSaveClickCallback={onSaveClickCallback}
                elements={elements}
                displayMode={type}
                isWorkflowReportModalVisible={isWorkflowReportModalVisible}
                setWorkflowReportModalVisibility={setWorkflowReportModalVisibility}
                setElements={setElements}
                mergeTags={filteredMergeTags}
                isDisabled={type === 'preview'}
                version={version}
                workflowType={type}
                showReportsStats={showReportsStats}
                workflowReportApiStatus={workflowReportApiStatus}
              />
              {displayWorkflowFooter}
            </>
          )}
        </Skeleton>
      </Spin>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(WorkflowDetailsContainer)));
export { WorkflowDetailsContainer as WorkflowContainerWithoutStore };
