import React from 'react';
import CheckboxItem from './CheckboxItem';

const FilterSectionWithCheckbox = ({
  filterSectionKeys,
  sortedAllTabFilterSection,
  style,
  maxTextLength = 35,
  filterRef,
  shouldPreserveCasing
}) => {
  return (
    <div style={style} ref={filterRef}>
      {filterSectionKeys.map(x => {
        const count = sortedAllTabFilterSection[x]?.CandidateIds?.length;
        return (
          <CheckboxItem
            key={x}
            value={x}
            text={x}
            count={count}
            maxTextLength={maxTextLength}
            style={{ display: 'flex', alignItems: 'center' }}
            shouldPreserveCasing={shouldPreserveCasing}
          />
        );
      })}
    </div>
  );
};

export default FilterSectionWithCheckbox;
