import { defineMessages } from 'react-intl';
import toasterMessagesJsonEn from '../../Data/En_Us/ToasterMessagesEn_Us.json';
import toasterMessagesJsonEs from '../../Data/Es_Es/ToasterMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'Toaster';
const transformedMessage = formatMessagesWithComponentPrefix(toasterMessagesJsonEn, component);

const toasterMessages = defineMessages(transformedMessage);

export const toasterMessagesEn = formatJsonWithComponentPrefix(toasterMessagesJsonEn, component);
export const toasterMessagesEs = formatJsonWithComponentPrefix(toasterMessagesJsonEs, component);
export default toasterMessages;
