import { setApiStatus } from '../ApiStatusActions';

const SET_JOB_ACTIVITY = 'SET_JOB_ACTIVITY';

const fetchJobActivityStatus = status => setApiStatus({ apiName: 'fetchJobActivityStatus', status });

const setJobActivity = ({ jobId, activity, totalCount, isFromInfiniteScroll }) => ({
  type: SET_JOB_ACTIVITY,
  payload: { jobId, activity, totalCount, isFromInfiniteScroll },
});

export { setJobActivity, fetchJobActivityStatus, SET_JOB_ACTIVITY };
