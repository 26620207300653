import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EmailSettings from '../../Components/EmailSettings/EmailSettings';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import * as ConnectActions from '../../Actions/ConnectActions';
import {
  getConnectSettings,
  getEmailSmtpConfiguration,
  getOrgDefaultEmail,
} from '../../Reducers/ConnectSettingsReducer';
import { getUserEmails } from '../../Reducers/UserReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = state => {
  return {
    connectConfig: getConnectSettings(state),
    userConfig: getConfig(state),
    emailSmtpConfiguration: getEmailSmtpConfiguration(state),
    emailSettingsApiStatus: getApiStatus(state, 'emailSettingsApiStatus'),
    emailConfigurationApiStatus: getApiStatus(state, 'emailConfigurationApiStatus'),
    userEmails: getUserEmails(state),
    currentUser: getCurrentUser(state),
    orgDefaultEmail: getOrgDefaultEmail(state),
    whiteLabelInfo: getWhiteLabelInformation(state),
  };
};

const mapDispatchToProps = {
  updateEmailSmtpConfig: ConnectSettingsActions.updateEmailSmtpConfig,
  deleteEmailConfig: ConnectSettingsActions.deleteEmailConfig,
  updateEmailSmtpSettings: ConnectSettingsActions.updateEmailSmtpSettings,
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,
  fetchUserEmails: ConnectActions.fetchUserEmails,
  SetNotification:setNotification,
};

const AdminSMTPSettingsContainer = props => {
  const {
    updateEmailSmtpConfig,
    deleteEmailConfig,
    connectConfig,
    userConfig,
    version,
    updateEmailSmtpSettings,
    emailConfigurationApiStatus,
    emailSettingsApiStatus,
    emailSmtpConfiguration,
    fetchEmailConfig,
    fetchUserEmails,
    userEmails,
    currentUser,
    orgDefaultEmail,
    whiteLabelInfo,
    SetNotification,
  } = props;

  const isAdmin = _.get(userConfig, 'Role', '') !== 'Recruiter';
  const AppName = _.get(whiteLabelInfo, ['AppName'], 'Leoforce');
  return isAdmin ? (
    <div>
      <EmailSettings
        emailProviders={connectConfig.AdminEmailProviders}
        updateEmailSmtpConfig={updateEmailSmtpConfig}
        deleteEmailConfig={deleteEmailConfig}
        isAdmin
        version={version}
        updateEmailSmtpSettings={updateEmailSmtpSettings}
        fetchEmailConfig={fetchEmailConfig}
        emailConfigurationApiStatus={emailConfigurationApiStatus}
        emailSettingsApiStatus={emailSettingsApiStatus}
        emailSmtpConfiguration={emailSmtpConfiguration}
        userEmails={userEmails}
        fetchUserEmails={fetchUserEmails}
        currentUser={currentUser}
        orgDefaultEmail={orgDefaultEmail}
        AppName={AppName}
        SetNotification={SetNotification}
      />
    </div>
  ) : null;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSMTPSettingsContainer));
export { AdminSMTPSettingsContainer as AdminSMTPSettingsContainerWithoutStore };
