import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import _ from 'lodash';
import StarIcon from '../../../../../Icons/StarIcon';
import TilesIcon from '../../../../../Icons/TilesIcon';
import MoreSkillsSection from '../MoreSkillsSection/MoreSkillsSection';
import styles from '../SkillsSection.module.scss';
import { fetchSkillIntel as _fetchSkillIntel } from '../../../../../Actions/ManualSearchActions';
import PreferredSkills from './PreferredSkills';
import { getApiStatus } from '../../../../../Reducers/SalesDashBoardReducer';
import AutocompleteSearch from '../../../../../Components/AutocompleteSearch/AutocompleteSearch';
import * as autocompleteActions from '../../../../../Actions/AutocompleteActions';
import { mapSkillSuggestions, mapSkillSuggestionsWithMaxSelectionLimit } from '../../../../../Utils/ManualSearchUtils';

const mapStateToProps = state => ({
  fetchSkillIntelApiStatus: getApiStatus(state, 'fetchSkillIntelApiStatus'),
});

const mapDispatchToProps = {
  fetchSkillIntel: _fetchSkillIntel,
  fetchSkillSuggestions: autocompleteActions.fetchSkillSuggestions,
};

function AryaAssistedSkills(props) {
  const {
    form,
    defaultPrefers,
    defaultMustHaves,
    defaultExcludes,
    fetchSkillIntel,
    isManualSearchFormMinimized,
    fetchSkillSuggestions,
  } = props;
  const [skillInput, setSkillInput] = React.useState();
  const [skillIntelApiStatus, setSkillIntelApiStatus] = React.useState({});
  const [skillAutocompleteApiStatus, setSkillAutocompleteApiStatus] = React.useState();
  const [skillAutocompleteOptions, setSkillAutocompleteOptions] = React.useState([]);

  const skillSetRef = React.useRef();

  const getSkillsIntel = async skills => {
    const titles = form
      .getFieldValue('Titles')
      ?.filter(x => x.isSelected)
      .map(x => x.name);
    const skillsIntel = await fetchSkillIntel({ skills, titles });
    const _skillsIntel = {};
    Object.entries(skillsIntel).forEach(x => {
      const [key, value] = x;
      _skillsIntel[key.toLowerCase()] = value;
    });
    return _skillsIntel;
  };

  const isTitlesExists = form.getFieldValue('Titles')?.filter(x => x.isSelected).length > 0;

  const scrollToLatestSkill = () => {
    skillSetRef.current.scrollTop = skillSetRef.current.scrollHeight;
  };

  const updateSkills = (newlyAddedSkill, popoverSkillId) => {
    const currentSkills = form.getFieldValue('PreferredSkills');
    const newSkillName = newlyAddedSkill.name.toLowerCase();
    const existingSkillIndex = currentSkills.findIndex(x => x.name.toLowerCase() === newSkillName);
    const clonedSkill = { ...newlyAddedSkill, isProminent: currentSkills[existingSkillIndex]?.isProminent };
    let updatedSkills;
    if (popoverSkillId) {
      const popoverSkillIdIndex = currentSkills.findIndex(x => x.id === popoverSkillId);
      if (existingSkillIndex < 0) {
        currentSkills.splice(popoverSkillIdIndex + 1, 0, newlyAddedSkill);
      } else {
        currentSkills.splice(existingSkillIndex, 1);
        currentSkills.splice(popoverSkillIdIndex + 1, 0, clonedSkill);
      }
      updatedSkills = currentSkills;
    } else if (existingSkillIndex >= 0) {
      currentSkills.splice(existingSkillIndex, 1);
      updatedSkills = currentSkills.concat(clonedSkill);
    } else updatedSkills = currentSkills.concat(newlyAddedSkill);
    form.setFieldsValue({ PreferredSkills: updatedSkills });
  };

  const updateSkillIntel = (skillId, skillIntel) => {
    const skills = form.getFieldValue('PreferredSkills');
    const skill = skills.find(x => x.id === skillId);
    if (skill && !_.isEmpty(skillIntel)) {
      const { AliasSkills, AlternateSkills, MaxRecommendedAliasSkillCount } = skillIntel;
      skill.aliasSkills = mapSkillSuggestionsWithMaxSelectionLimit(
        AliasSkills,
        true,
        false,
        MaxRecommendedAliasSkillCount
      );
      skill.alternateSkills = mapSkillSuggestions(AlternateSkills, false);
      skill.MaxRecommendedAliasSkillCount = MaxRecommendedAliasSkillCount;

      form.setFieldsValue({ PreferredSkills: skills });
    }
  };

  const setSkillIntel = useCallback(
    async (skillId, skill) => {
      if (skill) {
        setSkillIntelApiStatus({ ...skillIntelApiStatus, [skillId]: 'INPROGRESS' });
        const skillIntel = await getSkillsIntel([skill]);
        const skillIntelValue = skillIntel[skill.toLowerCase()];
        updateSkillIntel(skillId, skillIntelValue);
        setSkillIntelApiStatus({ ...skillIntelApiStatus, [skillId]: 'COMPLETED' });
      }
    },
    [skillIntelApiStatus]
  );

  const addNewSkill = useCallback(
    (skill, id) => {
      const skillId = uuid.v4();
      const newlyAddedSkill = {
        id: skillId,
        name: skill,
        isPreferred: true,
        isProminent: false,
      };
      updateSkills(newlyAddedSkill, id);
      if (isTitlesExists) setSkillIntel(skillId, skill);
    },
    [isTitlesExists]
  );

  const handleAliasSkillSelection = useCallback((aliasSkill, skillId) => {
    const skills = form.getFieldValue('PreferredSkills');
    const newSkills = skills.map(skill => {
      if (skill.id === skillId) {
        const selectedAliasSkills = skill.aliasSkills.map(alias => {
          if (alias.name === aliasSkill) {
            return { ...alias, isSelected: !alias.isSelected };
          }
          return alias;
        });
        const isAtleastOneAliasSkillExists = selectedAliasSkills.some(x => x.isSelected);
        return {
          ...skill,
          aliasSkills: selectedAliasSkills,
          isPreferred: !skill.isPreferred ? isAtleastOneAliasSkillExists : true,
        };
      }
      return skill;
    });
    form.setFieldsValue({ PreferredSkills: newSkills });
  }, []);

  const handleAliasSkillSectionCheckboxClick = useCallback((skillId, isSelected) => {
    const skills = form.getFieldValue('PreferredSkills');
    const newSkills = skills.map(skill => {
      if (skill.id === skillId) {
        const selectedAliasSkills = skill.aliasSkills.map(alias => ({ ...alias, isSelected }));
        return {
          ...skill,
          aliasSkills: selectedAliasSkills,
          isPreferred: !skill.isPreferred ? isSelected : true,
        };
      }
      return skill;
    });
    form.setFieldsValue({ PreferredSkills: newSkills });
  }, []);

  const handleSearch = React.useCallback(async value => {
    setSkillAutocompleteApiStatus('INPROGRESS');
    const response = await fetchSkillSuggestions({ searchTerm: value });
    setSkillAutocompleteOptions(
      _.uniqBy(
        (response?.Skills ?? []).map(skillData => ({ value: skillData.Skill, text: skillData.Skill })),
        'value'
      )
    );
    setSkillAutocompleteApiStatus('COMPLETED');
  }, []);

  const getNotFoundContent = () => {
    if (skillInput) return skillAutocompleteApiStatus === 'INPROGRESS' ? 'Loading...' : '';
    return 'Type to search';
  };

  const handleOnSelect = skill => {
    addNewSkill(skill);
    scrollToLatestSkill();
    setSkillInput(undefined);
  };

  const notFoundContent = getNotFoundContent();
  return (
    <>
      <AutocompleteSearch
        style={{ width: '300px', display: isManualSearchFormMinimized ? 'none' : 'block' }}
        placeholder="+ Add Skills/Keywords"
        onSearch={handleSearch}
        value={skillInput}
        onChange={setSkillInput}
        options={skillAutocompleteOptions}
        onSearchInput={handleOnSelect}
        notFoundContent={notFoundContent}
        onSelect={handleOnSelect}
      />

      <div className={styles.skills}>
        <div style={{ fontSize: '13px', fontWeight: 500, marginTop: isManualSearchFormMinimized ? '0px' : '10px' }}>
          Choose keywords from the below list. Click <StarIcon fillColor="#13c26b" /> to make it priority. Click{' '}
          <TilesIcon fillColor="#13c26b" /> for additional options.
        </div>
        <div style={{ marginTop: '10px', maxHeight: '225px', overflow: 'auto' }} ref={skillSetRef}>
          {form.getFieldDecorator('PreferredSkills', { initialValue: defaultPrefers })(
            <PreferredSkills
              skillIntelApiStatus={skillIntelApiStatus}
              handleTilesIconHover={setSkillIntel}
              addNewSkill={addNewSkill}
              handleAliasSkillSelection={handleAliasSkillSelection}
              handleAliasSkillSectionCheckboxClick={handleAliasSkillSectionCheckboxClick}
              isTitlesExists={isTitlesExists}
            />
          )}
        </div>
      </div>
      <MoreSkillsSection form={form} defaultMustHaves={defaultMustHaves} defaultExcludes={defaultExcludes} />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AryaAssistedSkills);
export { AryaAssistedSkills as AryaAssistedSkillsWithoutStore };
