import { Avatar, Tag } from 'antd';
import React from 'react';
import styles from './ThreadMessage.module.scss';
import TimeAgo from './TimeAgoComponent';
import { getConfiguration } from '../Collaborators/Collaborators';
import HtmlViewer from '../HtmlViewer/HtmlViewer';

function ThreadMessage(props) {
  const { threadDetails } = props;
  const { userName, postedAt, content, isAryaRecruiter } = threadDetails;
  const avatarConfiguration = getConfiguration(userName);
  return (
    <div className={styles.threadMessage}>
      <div className={styles.userDetails}>
        <Avatar style={{ color: '#ffffff', backgroundColor: avatarConfiguration.color }}>
          {avatarConfiguration.text}{' '}
        </Avatar>
        <div className={styles.userInfo}>
          <div className={styles.displayName}>{userName}</div>
          <TimeAgo createdTime={postedAt} />
          {isAryaRecruiter && <Tag className={styles.aryaRecruiterTag}>Arya Recruiter</Tag>}
        </div>
      </div>
      <div className={styles.content}>
        <HtmlViewer forbidTags={['style']} htmlContent={content && content.replace(/\n/gi, '<br />')} />
      </div>
    </div>
  );
}

export default ThreadMessage;
