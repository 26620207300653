import React from 'react';
import ReportViewContainer from '../../../../Containers/ReportPortalContainer/ReportViewContainer/ReportViewContainer';
import {
  fetchReportPayload,
  getExportReportPayload,
  savedQueryMapper,
} from '../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import TextWithInfoIcon from '../../../Common/TextWithInfoIcon/TextWithInfoIcon';
import styles from './JobReports.module.scss';

export default function JobReports(props) {
  const {
    reportStats,
    getReportStats: fetchReportStats,
    fetchReportStatsApiStatus,
    exportReportApiStatus,
    savedQueryConfig,
    onSaveQuerySuccess,
  } = props;

  const getReportStats = (filter, page, pageSize) => {
    const fetchPayloadCriteria = { filter, page, pageSize, reportKey: 'jobs' };
    const jobReportFetchPayload = fetchReportPayload(fetchPayloadCriteria);

    fetchReportStats(jobReportFetchPayload, 'jobs');
  };

  React.useEffect(() => {
    if (savedQueryConfig?.runSavedQueryFlag) {
      const savedQuery = JSON.parse(savedQueryConfig?.queryDetails?.ReportQuery);
      const mappedSavedQuery = savedQueryMapper(savedQuery, savedQueryConfig?.queryDetails?.dynamicReportColumns);
      getReportStats(mappedSavedQuery, 1, 10);
    }
  }, [savedQueryConfig?.runSavedQueryFlag]);

  return (
    <div className={styles.jobReports}>
      <ReportViewContainer
        fetchReportStatsApiStatus={fetchReportStatsApiStatus}
        title="Job Report"
        subtitle={
          <TextWithInfoIcon
            text="Report based on Job Creation by Recruiter during the date(s) given"
            tooltipTitle="Latest results may be delayed 1-2 hours"
          />
        }
        type="jobs"
        filterConfig={{ job: false, client: true }}
        reportStats={reportStats}
        getReportStats={getReportStats}
        exportReportApiStatus={exportReportApiStatus}
        savedReportDetails={savedQueryConfig?.queryDetails}
        onSaveQuerySuccess={onSaveQuerySuccess}
      />
    </div>
  );
}
