import React from 'react';
import { Radio } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getGroupedLocations, getIsCountryWideSearchEnabled } from '../../../../Utils/LocationUtils';
import { getCountries, getCurrentJobDetails } from '../../../../Reducers/JobReducer';
import { getFeatureToggleList } from '../../../../Reducers/FeatureToggleReducer.ts';
import ManualSearchLocationInput from './ManualSearchLocationInput/ManualSearchLocationInput';
import ManualSearchMiles from './ManualSearchMiles/ManualSearchMiles';
import ManualSearchMultiLocation from './ManualSearchMultiLocation/ManualSearchMultiLocation';
import { getConfig, getWhiteLabelInformation } from '../../../../Reducers/ConfigReducer';
import styles from './LocationSection.module.scss';

const mapStateToProps = state => ({
  userConfig: getConfig(state) ?? {},
  countries: getCountries(state),
  featureToggleList: getFeatureToggleList(state),
  currentJobDetails: getCurrentJobDetails(state) ?? {},
  whiteLabelInfo: getWhiteLabelInformation(state),
});

export const previousRange = {
  location: undefined,
  multiLocation: undefined,
};

function LocationSection(props) {
  const {
    form,
    featureToggleList,
    currentJobDetails,
    countries,
    defaultFormValues,
    userConfig,
    whiteLabelInfo,
    candidateContext,
  } = props;
  const [isLocationChecked, setLocationChecked] = React.useState(true);
  React.useEffect(() => {
    previousRange.location = undefined;
    previousRange.multiLocation = undefined;
  }, []);

  const getDefaultRange = () => {
    if (previousRange.multiLocation) return previousRange.multiLocation;
    return featureToggleList.PaidJobService.IsEnabled ? 100 : userConfig?.Distance?.Distance ?? 50;
  };

  const onLocationTabChange = key => {
    if (key.target.value === 'MultiLocation') {
      previousRange.location = form.getFieldValue('Range');
      form.setFieldsValue({ Range: previousRange.multiLocation ?? getDefaultRange() });
      setLocationChecked(false);
    } else {
      previousRange.multiLocation = form.getFieldValue('Range');
      setLocationChecked(true);
      if (previousRange.location) form.setFieldsValue({ Range: previousRange.location });
    }
  };

  const getIsMultiLocationEnabled = () => {
    return featureToggleList.MultiLocationSourcing.IsEnabled || currentJobDetails.IsMultiLocationJob;
  };

  const isMultiLocationEnabled = getIsMultiLocationEnabled();
  const isCountryWideSearchEnabled = getIsCountryWideSearchEnabled(countries, currentJobDetails.CountryCode);
  const locationType = form.getFieldValue('LocationType');
  const isMultiLocationSectionVisible = locationType === 'MultiLocation';
  const multiLocationInitialValue = React.useMemo(
    () => getGroupedLocations(defaultFormValues.SourcingLocations),
    [defaultFormValues.SourcingLocations]
  );

  return (
    <div className={styles.locationSection}>
      {form.getFieldDecorator('LocationType', { initialValue: defaultFormValues.LocationType })(
        <Radio.Group onChange={onLocationTabChange}>
          <Radio value="Location" key="Location" className={styles.locationLabel}>
            <span className={isLocationChecked ? styles.selectedLocation : null}>Location</span>
          </Radio>
          {defaultFormValues.LocationType === 'MultiLocation' || featureToggleList.MultiLocationSourcing.IsEnabled ? (
            <Radio value="MultiLocation" key="MultiLocation" className={styles.locationLabel}>
              <span className={!isLocationChecked ? styles.selectedLocation : null}>Multilocation</span>
            </Radio>
          ) : null}
        </Radio.Group>
      )}
      <div className={styles.locationTabs}>
        <div style={{ display: !isMultiLocationSectionVisible ? 'block' : 'none' }}>
          <ManualSearchLocationInput
            form={form}
            defaultLocationObject={defaultFormValues.Location}
            defaultIsStateWideSearchChecked={defaultFormValues.IsStateWideSearch}
            defaultIsRemoteLocation={defaultFormValues.IsRemoteLocation}
            isCountryWideSearchEnabled={isCountryWideSearchEnabled}
            isPaidJobServiceEnabled={featureToggleList.PaidJobService.IsEnabled}
            isStateWideSearchEnabled={featureToggleList.StateWideSearch.IsEnabled}
            countryOptions={countries}
            AppName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
            candidateContext={candidateContext}
          />
        </div>
        {isMultiLocationEnabled ? (
          <div style={{ display: isMultiLocationSectionVisible ? 'block' : 'none' }}>
            {form.getFieldDecorator('SourcingLocations', { initialValue: multiLocationInitialValue })(
              <ManualSearchMultiLocation countryOptions={countries} candidateContext={candidateContext} form={form} />
            )}
          </div>
        ) : null}
      </div>
      <ManualSearchMiles
        form={form}
        locationType={locationType}
        isCountryWideSearchEnabled={isCountryWideSearchEnabled}
        defaultRange={defaultFormValues.Range}
        candidateContext={candidateContext}
      />
    </div>
  );
}

export default connect(mapStateToProps)(LocationSection);
export { LocationSection as LocationSectionWithoutStore };
