import { aryaConnectApiV2Client } from './BaseRepository';

export function createSourcingSupportChat({ jobGuid, jobTitle, jobId, usersInTheChat }) {
  const payload = {
    name: `${jobTitle}|${jobId}`,
    description: '',
    users: usersInTheChat,
    chatType: 'Group',
    topic: {
      correlationId: jobGuid,
      name: jobTitle,
    },
  };
  return aryaConnectApiV2Client.post('chats', payload);
}

export function createTopic({ jobGuid, jobTitle, jobId }) {
  const payload = {
    correlationId: jobGuid,
    name: `${jobTitle}|${jobId}`,
  };
  return aryaConnectApiV2Client.post('topics', payload);
}

export function getAllChats({ topicId }) {
  return aryaConnectApiV2Client.get(`topics/${topicId}/chats?chatTypes=group`);
}

export function getAllMainThreadsForTheJob(chatId, isThread, before) {
  let url = `/chats/${chatId}/messages?isThread=${isThread}`;
  if (before) url += `&before=${before}`;
  return aryaConnectApiV2Client.get(url);
}

export function getMoreRepliesForTheMessage({ chatId, messageId, before, limit }) {
  let url = `/chats/${chatId}/messages/${messageId}/replies?`;
  if (before) url += `before=${before}`;
  if (limit) url += `limit=${limit}`;
  return aryaConnectApiV2Client.get(url);
}

export function addMembersToTheChat({ chatId, users }) {
  const url = `chats/${chatId}/members`;
  return aryaConnectApiV2Client.post(url, users);
}

export function markChatAsRead({ chatId }) {
  const url = `chats/${chatId}/_read`;
  return aryaConnectApiV2Client.post(url);
}

export function fetchSingleThread({ chatId, messageId }) {
  const url = `chats/${chatId}/messages/${messageId}`;
  return aryaConnectApiV2Client.get(url);
}
