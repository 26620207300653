import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { message, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from '../../Components/ApplicationSettings/messages';
import {
  getConnectSettings,
  getEmailSmtpConfiguration,
  getOrgDefaultEmail,
} from '../../Reducers/ConnectSettingsReducer';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getSignatures, getUserEmails } from '../../Reducers/UserReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as UserActions from '../../Actions/UserActions';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import EmailConfiguration from '../../Components/EmailSmtp/EmailConfiguration/EmailConfiguration';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';

const oAuth2 = 'OAuth2';

const mapStateToProps = state => {
  const emailSmtpConfiguration = getEmailSmtpConfiguration(state);
  return {
    currentUser: getCurrentUser(state),
    connectConfig: getConnectSettings(state),
    testSmtpApiStatus: getApiStatus(state, 'testSmtpApiStatus'),
    userEmails: getUserEmails(state),
    orgDefaultEmail: getOrgDefaultEmail(state),
    featureToggleList: getFeatureToggleList(state),
    signatures: getSignatures(state),
    emailSmtpConfiguration,
  };
};

const mapDispatchToProps = {
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,
  updateEmailSmtpConfig: ConnectSettingsActions.updateEmailSmtpConfig,
  deleteEmailConfig: ConnectSettingsActions.deleteEmailConfig,
  connectEmailToProviderAndTest: ConnectSettingsActions.connectEmailToProviderAndTest,
  fetchUserEmails: ConnectActions.fetchUserEmails,
  deleteSmtpEmail: ConnectSettingsActions.deleteSmtpEmail,
  addUserEmail: ConnectActions.addUserEmail,
  deleteUserEmail: ConnectActions.deleteUserEmail,
  getEmailSignatures: ConnectActions.getEmailSignatures,
  postEmailSignature: ConnectActions.postEmailSignature,
  postEmailSignatures: ConnectActions.postEmailSignatures,
  fetchUsers: UserActions.fetchUsers,
  updateEmailSmtpSettings: ConnectSettingsActions.updateEmailSmtpSettings,
  updateUserEmail: ConnectActions.updateUserEmail,
  SetNotification:setNotification,
};

message.config({
  top: 100,
  duration: 2,
  maxCount: 1,
});
class EmailConfigurationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchEmailConfig, fetchUserEmails, getEmailSignatures } = this.props;
    fetchEmailConfig({ isAdmin: false });
    fetchEmailConfig({ isAdmin: true });
    fetchUserEmails();
    getEmailSignatures();
  }

  getEmailProviders = () => {
    const { connectConfig } = this.props;
    let adminEmailProviders = _.get(connectConfig, ['AdminEmailProviders'], []);
    adminEmailProviders = adminEmailProviders.map(x => {
      return { ...x, isAdminConfiguredEmailProvider: true };
    });
    let userEmailProviders = _.get(connectConfig, ['UserEmailProviders'], []);
    userEmailProviders = userEmailProviders.map(x => {
      return { ...x, isAdminConfiguredEmailProvider: false };
    });
    return adminEmailProviders.concat(userEmailProviders);
  };

  getFormValues = () => {
    const { form } = this.props;
    const { email, senderName, emailSignature, providerId } = form.getFieldsValue();
    return { email: email.trim(), senderName: senderName?.trim(), emailSignature: emailSignature?.trim(), providerId };
  };

  addNewEmailConfiguration = async () => {
    const { addUserEmail, postEmailSignature, connectEmailToProviderAndTest } = this.props;
    const { email: emailId, senderName, emailSignature, providerId } = this.getFormValues();
    const emailProviders = this.getEmailProviders();
    const currentProvider = emailProviders.find(emailProvider => emailProvider.Id === providerId);
    if (currentProvider.AuthScheme === oAuth2) {
      await connectEmailToProviderAndTest({ emailId, providerId, senderName });
    } else {
      await addUserEmail(emailId, true, senderName, providerId);
    }
    postEmailSignature(emailId, emailSignature);
  };

  updateEmailConfiguration = async (previousProvider, isDefaultEmailConfiguration) => {
    const { deleteEmailConfig, connectEmailToProviderAndTest, postEmailSignature, updateUserEmail } = this.props;
    const { email: emailId, emailSignature, providerId, senderName } = this.getFormValues();
    const {
      ProviderConfiguration: prevProviderConfiguration,
      Signature: prevSignature,
      SenderName: prevSenderName,
    } = previousProvider;

    const { Id: prevProviderId, AuthScheme: prevProviderAuthScheme } = prevProviderConfiguration || {};
    const emailProviders = this.getEmailProviders();
    const currentProvider = emailProviders.find(emailProvider => emailProvider.Id === providerId);
    const updateConfigActions = [];
    if (prevProviderAuthScheme === oAuth2 && prevProviderId !== providerId) {
      await deleteEmailConfig(prevProviderId, { isAdmin: false });
    }

    if ((!isDefaultEmailConfiguration || prevProviderId) &&
    prevProviderId !== providerId)
    {
      const authScheme = currentProvider.AuthScheme;
      const isAdmin = authScheme === "Manual";
      updateConfigActions.push(connectEmailToProviderAndTest({ emailId, providerId, isAdmin }));
    }

    if (prevSignature !== emailSignature) {
      updateConfigActions.push(postEmailSignature(emailId, emailSignature));
    }

    if (senderName !== prevSenderName) {
      updateConfigActions.push(updateUserEmail(previousProvider.Id, { SenderName: senderName }));
    }

    await Promise.all(updateConfigActions);
  };

  submitEmailConfiguration = async (addNewEmailAddressFlag, previousProvider, isDefaultEmailConfiguration) => {
    const { fetchUserEmails,SetNotification } = this.props;
    try {
      if (addNewEmailAddressFlag) {
        await this.addNewEmailConfiguration();
      } else {
        await this.updateEmailConfiguration(previousProvider, isDefaultEmailConfiguration);
      }
      fetchUserEmails();
      SetNotification('SUCCESS', {
          messageId: 'savedSuccessfully',
        })
    } catch {
      SetNotification('SUCCESS', {
        messageId: 'oopsSomethingJustWentWrong',
      })
    }
  };

  render() {
    const {
      deleteEmailConfig,
      connectEmailToProviderAndTest,
      form,
      userEmails,
      orgDefaultEmail,
      addUserEmail,
      signatures,
      currentUser,
      fetchUserEmails,
      postEmailSignature,
      deleteUserEmail,
      updateEmailSmtpSettings,
      emailSmtpConfiguration,
      deleteSmtpEmail,
      SetNotification,
    } = this.props;
    const emailProviders = this.getEmailProviders();
    const combinedEmails = [orgDefaultEmail, ...userEmails];
    const uniqueCombinedEmails = _.uniqBy(combinedEmails, email => email.EmailId?.toLowerCase());
    return (
      <div>
        <div className="application-settings-email-configuration-title">
          <FormattedMessage {...messages.emailConfiguration} />
        </div>
        <div className="application-settings-email-configuration-message">
          <FormattedMessage {...messages.emailConfigurationMessage} />
        </div>
        <EmailConfiguration
          form={form}
          submitEmailConfiguration={this.submitEmailConfiguration}
          isAdmin={false}
          fetchUserEmails={fetchUserEmails}
          addUserEmail={addUserEmail}
          postEmailSignature={postEmailSignature}
          userEmails={uniqueCombinedEmails}
          orgDefaultEmail={orgDefaultEmail}
          currentUser={currentUser}
          signatures={signatures}
          connectEmailToProviderAndTest={connectEmailToProviderAndTest}
          deleteSmtpEmail={deleteSmtpEmail}
          emailProviders={emailProviders}
          deleteEmailConfig={deleteEmailConfig}
          deleteUserEmail={deleteUserEmail}
          updateEmailSmtpSettings={updateEmailSmtpSettings}
          emailSmtpConfiguration={emailSmtpConfiguration}
          SetNotification={SetNotification}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EmailConfigurationContainer));
export { EmailConfigurationContainer as EmailConfigurationContainerWithoutStore };
