import React from 'react';
import { Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import ThreadItem from './ThreadItem';

function SourcingSupportCard(props) {
  const {
    threads,
    chatId,
    topicId,
    fetchMoreRepliesForTheThread,
    initialChatsFetchApiStatus,
    currentMember,
    addThreadReplyToTheChat,
    fetchAllThreads,
    lastFetchedThreadCount,
    initialThreadsFetchApiStatus,
    isChatDisabled
  } = props;
  let currentPaginatedLastThreadCreatedTime = null;
  const newThreads = _.cloneDeep(threads);
  const sortedThreads = newThreads.sort((firstThread, secondThread) => {
    return new Date(secondThread.CreatedTime).getTime() - new Date(firstThread.CreatedTime).getTime();
  });
  if (threads?.length > 0) {
    const lastThread = threads[threads.length - 1];
    const lastCreatedTimeString = lastThread?.CreatedTime;
    currentPaginatedLastThreadCreatedTime = new Date(lastCreatedTimeString).getTime();
  }
  const loadingStatus = initialChatsFetchApiStatus === 'IN_PROGRESS' || initialThreadsFetchApiStatus === 'IN_PROGRESS';
  return (
    <InfiniteScroll
      loadMore={() => {
        fetchAllThreads({ chatId, before: currentPaginatedLastThreadCreatedTime });
      }}
      hasMore={lastFetchedThreadCount >= 10}
      initialLoad={false}
    >
      <Skeleton loading={loadingStatus} avatar paragraph={{ rows: 4 }}>
        {sortedThreads.map(thread => (
          <ThreadItem
            key={thread.Id}
            thread={thread}
            currentMember={currentMember}
            chatId={chatId}
            topicId={topicId}
            addThreadReplyToTheChat={addThreadReplyToTheChat}
            fetchMoreRepliesForTheThread={fetchMoreRepliesForTheThread}
            isChatDisabled={isChatDisabled}
          />
        ))}
      </Skeleton>
    </InfiniteScroll>
  );
}

export default SourcingSupportCard;
