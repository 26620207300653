import { getConfig } from '../Reducers/ConfigReducer';
import store from '../store';

export const mapReportStatsSourceName = (reportsData = {}) => {
  const state = store.getState();
  const userConfig = getConfig(state);
  const rows = (reportsData.Rows || []).map(item => {
    if (item.Source === 'Active' && userConfig?.ActiveChannelAlias) {
      const modifiedSource = userConfig?.ActiveChannelAlias;
      return { ...item, Source: modifiedSource };
    }
    return item;
  });
  return { ...reportsData, Rows: rows };
};

export const mapReportStatsByRecruiterName = (reportsData = {}) => {
  const colsToIgnoreForTotals = [
    'UserName',
    'LoginName',
    'UserType',
    'AryaJobId',
    'ClientJobId',
    'AryaJobCreatedDate',
    'ATSJobCreatedDate',
    'ClientJobStatus',
    'AryaJobStatus',
    'UserStatus',
    'AryaJobTitle',
  ];
  const colsToIgnoreInRecruiterData = [
    'AryaJobId',
    'ClientJobId',
    'AryaJobCreatedDate',
    'ATSJobCreatedDate',
    'ClientJobStatus',
    'AryaJobStatus',
    'AryaJobTitle',
  ];
  const groupableCols = ['UserName', 'LoginName'];

  let rows = [];

  if (reportsData.Rows && reportsData.Rows.length > 0) {
    const colToGroupBy = Object.keys(reportsData.Rows[0]).find(o => groupableCols.includes(o));
    if (colToGroupBy) {
      reportsData.Rows.forEach(element => {
        let userData = rows.find(o => o[colToGroupBy] === element[colToGroupBy]);
        if (!userData) {
          userData = { ...element };
          colsToIgnoreInRecruiterData.forEach(col => delete userData[col]);
          userData.children = [];
          rows.push(userData);
        }

        Object.entries(element).forEach(([colName, value]) => {
          if (!colsToIgnoreForTotals.includes(colName)) {
            userData[colName] += value;
          }
        });

        userData.children.push(element);
      });
    } else rows = reportsData.Rows;
  }

  return { ...reportsData, Rows: rows };
};

export const modifyDisplayName = (data = []) => {
  const state = store.getState();
  const userConfig = getConfig(state);
  return data.map(item => {
    if (item.DisplayName.includes('Active') && userConfig?.ActiveChannelAlias) {
      const modifiedName = userConfig?.ActiveChannelAlias;
      const modifiedDisplayName = item.DisplayName.replace('Active', modifiedName);
      return { ...item, DisplayName: modifiedDisplayName };
    }
    return item;
  });
};

export const mapReportDataByCategory = (data, category = '') => {
  const categoryInLowerCase = category.toLowerCase();
  switch (categoryInLowerCase) {
    case 'candidates':
      return mapReportStatsSourceName(data);
    case 'recruitersbyjob':
      return mapReportStatsByRecruiterName(data);
    default:
      return data;
  }
};

export const getReportType = type => {
  switch (type) {
    case '1':
      return 'jobs';
    case '2':
      return 'recruiters';
    case '3':
      return 'candidates';
    case '4':
      return 'recruitersbyjob';
    case '5':
      return 'dei';
    default:
      return 'unknown';
  }
};
