import * as SalesDashBoardRepository from '../Repository/SalesDashBoardRepository';
import {
  resetGlobalInsight,
  setGlobalSupplyLocationApiStatus,
  setGlobalSupplyApiStatus,
  setGlobalSupplyLocationData,
  setGlobalSupplyList,
} from './ActionCreators/SalesDashBoardActions';
import { setNotification } from './ActionCreators/ConnectActions';

function fetchGlobalInsightSupplyList(filter, allowPageLoading = true, updateLocationData = false) {
  return async dispatch => {
    if (allowPageLoading) {
      dispatch(resetGlobalInsight);
      dispatch(setGlobalSupplyApiStatus('INPROGRESS'));
    }
    if (updateLocationData) {
      dispatch(setGlobalSupplyLocationApiStatus('INPROGRESS'));
    }
    try {
      const response = await SalesDashBoardRepository.fetchGlobalInsightSupplyList(filter);
      if (updateLocationData) {
        dispatch(setGlobalSupplyLocationData(response.data));
      } else {
        dispatch(setGlobalSupplyList(response.data));
      }
      if (allowPageLoading) {
        dispatch(setGlobalSupplyApiStatus('COMPLETED'));
      }
      if (updateLocationData) {
        dispatch(setGlobalSupplyLocationApiStatus('COMPLETED'));
      }
    } catch (err) {
      dispatch(
        setNotification('ERROR',  {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'FETCH_GLOBAL_SUPPLY_API_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setGlobalSupplyApiStatus('FAILED'));
      dispatch(setGlobalSupplyLocationApiStatus('FAILED'));
    }
  };
}

function clearGlobalInsightList() {
  return dispatch => {
    dispatch({
      type: resetGlobalInsight.type,
    });
  };
}

function fetchMarketIntelligence(filter) {
  return async dispatch => {
    await dispatch(fetchGlobalInsightSupplyList({ ...filter, State: undefined, City: undefined, Distance: undefined }));
    if (filter.City && filter.State) {
      await dispatch(fetchGlobalInsightSupplyList(filter, false, true));
    }
  };
}

export { clearGlobalInsightList, fetchGlobalInsightSupplyList, resetGlobalInsight, fetchMarketIntelligence };
