import React from 'react';
import { Icon } from 'antd';

export const AryaDeactivatedSvg = props => (
  <svg width="72" height="70" viewBox="0 0 72 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3463_34824)">
      <path
        d="M30.1218 31.6659L22.5831 39.1882C22.007 39.763 22.007 40.6992 22.5831 41.2741C22.8794 41.5697 23.258 41.7011 23.6365 41.7011C24.0151 41.7011 24.3937 41.5533 24.69 41.2741L32.2286 33.7518L39.9483 41.4547C40.2446 41.7504 40.6232 41.8818 41.0018 41.8818C41.3804 41.8818 41.7589 41.7339 42.0552 41.4547C42.6313 40.8799 42.6313 39.9437 42.0552 39.3688L34.3355 31.6659L41.8741 24.1436C42.4502 23.5688 42.4502 22.6326 41.8741 22.0577C41.2981 21.4829 40.3598 21.4829 39.7837 22.0577L32.2122 29.58L24.4925 21.8771C23.9164 21.3022 22.9781 21.3022 22.402 21.8771C21.8259 22.4519 21.8259 23.3881 22.402 23.9629L30.1218 31.6659Z"
        fill="#1F2730"
      />
      <path
        d="M32.2121 63.3482C40.3268 63.3482 47.9807 60.4247 53.9392 55.0704L68.819 69.573C69.1153 69.8522 69.4774 70 69.856 70C70.2346 70 70.6296 69.8522 70.9094 69.5565C71.4855 68.9653 71.4691 68.0291 70.893 67.4707L56.0625 53.0009C61.4943 47.1539 64.4571 39.6316 64.4571 31.6659C64.4571 23.191 61.0993 15.2417 55.0091 9.26326C48.9189 3.28484 40.8371 0 32.2286 0C23.62 0 15.5382 3.28484 9.448 9.26326C3.35783 15.2417 0 23.2074 0 31.6659C0 40.1408 3.35783 48.0901 9.448 54.0685C15.5217 60.0634 23.6036 63.3482 32.2121 63.3482ZM11.5055 11.3656C17.2171 5.7649 24.7064 2.95636 32.2121 2.95636C39.7178 2.95636 47.2071 5.7649 52.9187 11.3656C58.4492 16.7855 61.4779 23.9958 61.4779 31.6659C61.4779 39.336 58.4328 46.5462 52.9187 51.9662C41.4955 63.1839 22.9287 63.1839 11.5055 51.9662C5.97496 46.5462 2.94633 39.336 2.94633 31.6659C2.94633 24.0122 5.97496 16.802 11.5055 11.3656Z"
        fill="#1F2730"
      />
    </g>
    <defs>
      <clipPath id="clip0_3463_34824">
        <rect width="71.3333" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const AryaDeactivated = props => <Icon component={() => AryaDeactivatedSvg(props)} {...props} />;
