import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../ManualSearchV2/ManualSearchMessages';

function SelectedCandidatesText(props) {
  const { candidatesCount, candidateContext } = props;
  let showText;
  if (candidateContext === 'segment') {
    if (candidatesCount > 0) {
      showText = <FormattedMessage {...messages.candidatesFoundAfterApplying} values={{ candidatesCount }} />;
    } else {
      showText = null;
    }
  } else if (candidatesCount > 1) {
    showText = <FormattedMessage {...messages.noOfCandidatesCountLabel} values={{ candidatesCount }} />;
  } else {
    showText = <FormattedMessage {...messages.noOfCandidateCountLabel} values={{ candidatesCount }} />;
  }
  return <span style={{ color: '#83878c', fontSize: '14px' }}>{showText}</span>;
}

export default SelectedCandidatesText;
