const SET_JOB_CANDIDATE_TAB_FILTER = 'SET_JOB_CANDIDATE_TAB_FILTER';
const SET_JOB_CANDIDATE_TAB_PAGINATION = 'SET_JOB_CANDIDATE_TAB_PAGINATION';
const SET_JOB_CANDIDATE_TAB_ACTIVE_TAB = 'SET_JOB_CANDIDATE_TAB_ACTIVE_TAB';
const SET_JOB_CANDIDATE_TAB_ACTIVE_SOURCE_NAME = 'SET_JOB_CANDIDATE_TAB_ACTIVE_SOURCE_NAME';
const SET_MANUAL_SEARCH_ACTIVE_SOURCE = 'SET_MANUAL_SEARCH_ACTIVE_SOURCE';
const SET_JOB_CANDIDATE_TAB_SELECTED_CANDIDATES = 'SET_JOB_CANDIDATE_TAB_SELECTED_CANDIDATES';
const SET_JOB_CANDIDATE_TAB_SELECTED_ACTIVE_CANDIDATES_COUNT = 'SET_JOB_CANDIDATE_TAB_SELECTED_ACTIVE_CANDIDATES_COUNT';
const SET_JOB_CANDIDATE_TAB_SELECTED_BULK_ACTIVITY = 'SET_JOB_CANDIDATE_TAB_SELECTED_BULK_ACTIVITY';
const RESET_JOB_CANDIDATE_TAB_STATE = 'RESET_JOB_CANDIDATE_TAB_STATE';
const RESET_JOB_CANDIDATE_TAB_BULK_SELECTION = 'RESET_JOB_CANDIDATE_TAB_BULK_SELECTION';
const SET_JOB_CANDIDATE_TAB_FILTER_QUERIES = 'SET_JOB_CANDIDATE_TAB_FILTER_QUERIES';
const SET_CANDIDATE_COLLAPSED_FILTERS = 'SET_CANDIDATE_COLLAPSED_FILTERS';
const SET_CANDIDATE_COLLAPSED_FILTERS_STATUS = 'SET_CANDIDATE_COLLAPSED_FILTERS_STATUS';
const SET_JOB_CANDIDATE_TAB_SELECTED_CUSTOM_BULK_ACTIVITY = 'SET_JOB_CANDIDATE_TAB_SELECTED_CUSTOM_BULK_ACTIVITY';
const SET_JOB_CANDIDATE_TAB_CUSTOM_SELECTED_CANDIDATES = 'SET_JOB_CANDIDATE_TAB_CUSTOM_SELECTED_CANDIDATES';
const SET_CANDIDATES_RECOMMENDATION_STATUS = 'SET_CANDIDATES_RECOMMENDATION_STATUS';
const SET_SEGMENT_BULK_RECOMMEND_ERROR_MESSAGE = 'SET_SEGMENT_BULK_RECOMMEND_ERROR_MESSAGE';
const SET_JOB_CANDIDATE_TAB_FROM = 'SET_JOB_CANDIDATE_TAB_FROM';

function setJobCandidateTabFilter(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_FILTER,
    payload,
  };
}

function setJobCandidateTabPagination(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_PAGINATION,
    payload,
  };
}
function setJobCandidateTabFrom(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_FROM,
    payload,
  };
}

function setJobCandidateTabActiveTab(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_ACTIVE_TAB,
    payload,
  };
}

function setJobCandidateTabActiveSourceName(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_ACTIVE_SOURCE_NAME,
    payload,
  };
}

function setManualSearchActiveSource(payload) {
  return {
    type: SET_MANUAL_SEARCH_ACTIVE_SOURCE,
    payload,
  };
}

function setSelectedCandidates(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_SELECTED_CANDIDATES,
    payload,
  };
}

function setSelectedActiveCandidateCount(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_SELECTED_ACTIVE_CANDIDATES_COUNT,
    payload,
  };
}

function setSelectedBulkActivity(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_SELECTED_BULK_ACTIVITY,
    payload,
  };
}

function resetJobCandidatesTabState() {
  return {
    type: RESET_JOB_CANDIDATE_TAB_STATE,
  };
}

function resetJobCandidatesTabBulkSelection() {
  return {
    type: RESET_JOB_CANDIDATE_TAB_BULK_SELECTION,
  };
}

function setFilterQueries({ filterQueries, sourceName }) {
  return {
    type: SET_JOB_CANDIDATE_TAB_FILTER_QUERIES,
    payload: { filterQueries, sourceName },
  };
}

const seCandidateCollapsedFilters = ({ jobId, filters }) => {
  return {
    type: SET_CANDIDATE_COLLAPSED_FILTERS,
    payload: { jobId, filters },
  };
};

const setCandidateCollapsedFilterStatus = status => {
  return {
    type: SET_CANDIDATE_COLLAPSED_FILTERS_STATUS,
    payload: { status },
  };
};

function setCustomSelectedCandidates(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_CUSTOM_SELECTED_CANDIDATES,
    payload,
  };
}

function setCustomSelectedBulkActivity(payload) {
  return {
    type: SET_JOB_CANDIDATE_TAB_SELECTED_CUSTOM_BULK_ACTIVITY,
    payload,
  };
}

function setCandidatesRecommendationStatus({ jobId, data }) {
  return {
    type: SET_CANDIDATES_RECOMMENDATION_STATUS,
    payload: { jobId, data },
  };
}

function setSegmentBulkRecommendErrorMessage({ jobId, errorMessage }) {
  return {
    type: SET_SEGMENT_BULK_RECOMMEND_ERROR_MESSAGE,
    payload: { jobId, errorMessage },
  };
}

export {
  SET_JOB_CANDIDATE_TAB_FILTER,
  SET_JOB_CANDIDATE_TAB_PAGINATION,
  SET_JOB_CANDIDATE_TAB_ACTIVE_TAB,
  SET_JOB_CANDIDATE_TAB_ACTIVE_SOURCE_NAME,
  SET_MANUAL_SEARCH_ACTIVE_SOURCE,
  SET_JOB_CANDIDATE_TAB_SELECTED_CANDIDATES,
  SET_JOB_CANDIDATE_TAB_SELECTED_ACTIVE_CANDIDATES_COUNT,
  SET_JOB_CANDIDATE_TAB_SELECTED_BULK_ACTIVITY,
  RESET_JOB_CANDIDATE_TAB_STATE,
  RESET_JOB_CANDIDATE_TAB_BULK_SELECTION,
  SET_JOB_CANDIDATE_TAB_FILTER_QUERIES,
  SET_CANDIDATE_COLLAPSED_FILTERS,
  SET_CANDIDATE_COLLAPSED_FILTERS_STATUS,
  SET_JOB_CANDIDATE_TAB_SELECTED_CUSTOM_BULK_ACTIVITY,
  SET_JOB_CANDIDATE_TAB_CUSTOM_SELECTED_CANDIDATES,
  SET_CANDIDATES_RECOMMENDATION_STATUS,
  SET_SEGMENT_BULK_RECOMMEND_ERROR_MESSAGE,
  SET_JOB_CANDIDATE_TAB_FROM,
  setJobCandidateTabFilter,
  setJobCandidateTabPagination,
  setJobCandidateTabActiveTab,
  setJobCandidateTabActiveSourceName,
  setManualSearchActiveSource,
  setSelectedCandidates,
  setSelectedActiveCandidateCount,
  setSelectedBulkActivity,
  resetJobCandidatesTabState,
  resetJobCandidatesTabBulkSelection,
  setFilterQueries,
  seCandidateCollapsedFilters,
  setCandidateCollapsedFilterStatus,
  setCustomSelectedCandidates,
  setCustomSelectedBulkActivity,
  setCandidatesRecommendationStatus,
  setSegmentBulkRecommendErrorMessage,
  setJobCandidateTabFrom,
};
