import cleanSet from 'clean-set';
import {
  SET_CANDIDATE_WORKFLOW_HISTORY,
  WORKFLOW_INITIATED,
} from '../Actions/ActionCreators/WorkflowWindowActionCreator';

const initialState = {
  History: {
    ById: {},
  },
};

function WorkflowWindowReducer(state = initialState, action) {
  let newState;
  if (action.type === SET_CANDIDATE_WORKFLOW_HISTORY) {
    newState = cleanSet(state, ['History', 'ById', action.payload.Id], action.payload.data);
    return newState;
  }
  if (action.type === WORKFLOW_INITIATED) {
    newState = cleanSet(state, [action.payload.jobId, action.payload.candidateId], true);
    return newState;
  }
  return state;
}

function getCandidateWorkflowHistory(state, candidateId) {
  return state.WorkflowWindowReducer.History.ById[candidateId];
}

function getCandidateWorkflowHistoryByInstanceId(state, { candidateId, instanceId }) {
  if (!instanceId) return null;
  const instances = state.WorkflowWindowReducer.History.ById[candidateId] ?? [];
  return instances.find(x => x.InstanceId === instanceId);
}

function getIsWorkflowInitiated(state, candidateId, jobId) {
  if (state.WorkflowWindowReducer[jobId]) {
    return state.WorkflowWindowReducer[jobId][candidateId] ?? null;
  }
  return null;
}

export {
  WorkflowWindowReducer,
  getCandidateWorkflowHistory,
  getIsWorkflowInitiated,
  getCandidateWorkflowHistoryByInstanceId,
};
