const emailStatsTitles = {
  Sent: 'Email sent',
  Opened: 'Email opened',
  NotOpened: 'Email not opened',
  Clicked: 'Email clicked',
  Replied: 'Email replied',
  NotSent: 'Email not sent',
  Pending: 'Email pending',
  Failed: 'Email Failed',
};
const messageStatsTitles = {
  Sent: 'Message sent',
  Failed: 'Message failed',
  NotSent: 'Message not sent',
  Skipped: 'Message skipped',
  Pending: 'Message pending',
};

const unsubscribedStatsTitles = {
  EmailUnsubscribed: 'Email unsubscribed',
  MessageConsentDenied: 'Message consent denied',
  Skipped: 'Message skipped',
  Pending: 'Message pending',
  MessageConsentApproved: 'Message consent approved',
};

const emailValueMapping = {
  Sent: 'Email_Sent',
  Opened: 'Email_Opened',
  NotOpened: 'Email_NotOpened',
  Clicked: 'Email_Clicked',
  Replied: 'Email_Replied',
  NotSent: 'Email_NotSent',
  Pending: 'Email_Pending',
  Failed: 'Email_Failed',
};

const messageValueMapping = {
  Sent: 'Message_Sent',
  Failed: 'Message_Failed',
  NotSent: 'Message_NotSent',
  Skipped: 'Message_Skipped',
  Pending: 'Message_Pending',
};

const subscriptionStatsValueMapping = {
  EmailUnsubscribed: 'SubscriptionStats_EmailUnsubscribed',
  MessageConsentDenied: 'SubscriptionStats_MessageConsentDenied',
  MessageConsentApproved: 'SubscriptionStats_MessageConsentApproved',
};

const emailStatsTitleForSegments = {
  Sent: 'Sent',
  Opened: 'Opened',
  Clicked: 'Clicked',
  NotContacted: 'Not Sent',
  Success: 'Not Opened',
  Skipped: 'Skipped',
  Failed: 'Failed',
};

const emailStatusValueForSegmemnts = {
  Sent: 'Sent',
  Opened: 'Opened',
  Success: 'NotViewed',
  Clicked: 'Clicked',
  NotContacted: 'NotContacted',
  Skipped: 'Skipped',
  Failed: 'Failed',
};

const emailStatsTooltipTexts = {
  Clicked: 'The total no. of candidates engaged with one or more actionable links within an email',
};

const getNotSentStatusCount = filteredStats => {
  return filteredStats?.find(messageStatus => messageStatus?.Status === 'NotSent')?.ConversationCount;
};

const getFilteredStatusesArray = (communicationData, filterType) => {
  const filteredArray = [];
  if (communicationData === undefined || communicationData === null) return filteredArray;
  communicationData.forEach(item => {
    const splitedArray = item.split('_');
    if (splitedArray[0] === filterType) {
      filteredArray.push(splitedArray[1]);
    }
  });
  return filteredArray;
};

const getAggregatedPersonIds = (subscriptionStats, subscriptionStatuses) => {
  const { EmailUnsubscribedPersonIds, MessageConsentApprovedPersonIds, MessageConsentDeniedPersonIds } =
    subscriptionStats;
  let personIds = [];
  if (subscriptionStatuses?.includes('EmailUnsubscribed') && EmailUnsubscribedPersonIds?.length) {
    personIds = [...personIds, ...EmailUnsubscribedPersonIds];
  }
  if (subscriptionStatuses?.includes('MessageConsentDenied') && MessageConsentDeniedPersonIds?.length) {
    personIds = [...personIds, ...MessageConsentDeniedPersonIds];
  }
  if (subscriptionStatuses?.includes('MessageConsentApproved') && MessageConsentApprovedPersonIds?.length) {
    personIds = [...personIds, ...MessageConsentApprovedPersonIds];
  }
  return personIds;
};

const getDefaultStatsFilter = isMessageFilterEnabled => {
  if (isMessageFilterEnabled) return ['Email', 'Message'];
  return ['Email'];
};

const getSubscriptionStats = (
  emailUnsubscribedPersonIds,
  messageConsentDeniedPersonIds,
  messageConsentApprovedPersonIds
) => {
  const temparray = [];
  temparray.push(
    { Status: 'EmailUnsubscribed', Count: emailUnsubscribedPersonIds?.length },
    { Status: 'MessageConsentDenied', Count: messageConsentDeniedPersonIds?.length },
    { Status: 'MessageConsentApproved', Count: messageConsentApprovedPersonIds?.length }
  );
  return temparray;
};

export {
  emailStatsTitles,
  emailStatsTooltipTexts,
  emailStatsTitleForSegments,
  emailStatusValueForSegmemnts,
  messageStatsTitles,
  messageValueMapping,
  emailValueMapping,
  subscriptionStatsValueMapping,
  unsubscribedStatsTitles,
  getNotSentStatusCount,
  getFilteredStatusesArray,
  getDefaultStatsFilter,
  getSubscriptionStats,
  getAggregatedPersonIds,
};
