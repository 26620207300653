import _ from 'lodash';
import cleanSet from 'clean-set';
import { createSelector } from 'reselect';
import shallowEqual from 'shallowequal';
import {
  SET_MANUAL_SEARCH_PAYLOAD,
  SET_MANUAL_SEARCH_FORM_VALUES,
  SET_MANUAL_SEARCH_CRITERIA,
  SET_MANUAL_SEARCH_CRITERIA_BY_ID,
  SET_MANUAL_SEARCH_CRITERIA_IDENTIFIERS,
  SET_RETRIEVED_JOBACTIVITY_ID,
  RESET_MANUAL_SEARCH_JOBACTIVITY,
  SET_SELECTED_CRITERIA_NAME,
  SET_SELECTED_CRITERIA_TYPE,
  SET_SELECTED_CRITERIA_ID,
  SET_LAST_PERFORMED_MANUAL_SEARCH_TIME,
  SET_MANUAL_SEARCH_CRITERIA_RECORD_BY_ID,
  CLEAR_MANUAL_SEARCH_CRITERIA,
  SET_MANUAL_SEARCH_SOURCES,
  SET_IS_REACTIVATION_ALLOWED_FLAG,
  SET_BOOLEAN_SEARCH_STRINGS,
  SET_IS_DEFAULT_CRITERIA_FETCHED_FLAG,
  SET_IS_PREFETCH_REQUIRED_FETCHED_FLAG,
  SET_MANUAL_SEARCH_SELECTED_CRITERIA_INFO,
  SET_MANUAL_SEARCH_MUST_HAVES,
  SET_MANUAL_SEARCH_CRITERIA_TYPE,
  SET_MANUAL_SEARCH_CURRENT_CRITERIA_TYPE_AND_RECORD_ID,
  SET_IS_ALL_TAB_CANDIDATE_AGGREGATION_FILTER_APPLIED_FLAG,
} from '../Actions/ActionCreators/ManualSearchActionCreators';

import { getManualSearchKeywordsToHighlight as _getManualSearchKeywordsToHighlight } from '../Utils/ManualSearchUtils';

const getCriteriaType = ({ IsAryaSuggested, IsActivated, IsDraft }) => {
  if (IsAryaSuggested && !IsDraft) return 'AryaGenerated';
  if (IsActivated && !IsDraft) return 'Reactivated';
  if (IsDraft) return 'Unsaved';
  return null;
};

const emptyObject = {};

function ManualSearchReducer(state = {}, action = {}) {
  let newState;
  let payload;
  switch (action.type) {
    case SET_BOOLEAN_SEARCH_STRINGS: {
      ({ payload } = action);
      newState = cleanSet(state, 'SearchStrings', payload.SearchStrings);
      return newState;
    }
    case SET_MANUAL_SEARCH_PAYLOAD: {
      ({ payload } = action);
      const { manualSearchPayload, jobId, isAdvancedSearchV2Enabled } = payload;
      const {
        Titles: titles,
        Skills: skills,
        VisaStatuses: visaStatuses,
        QueryString: queryString,
        ActiveSearchString: activeSearchString,
      } = manualSearchPayload;
      const { allKeywordsToHighlight, mustHavesKeywords } = _getManualSearchKeywordsToHighlight({
        titles,
        skills,
        visaStatuses,
        queryString,
        activeSearchString,
        isAdvancedSearchV2Enabled,
      });
      const job = _.get(state, ['ByJobId', jobId], {});
      const newJob = {
        ...job,
        ManualSearchPayload: manualSearchPayload,
        ManualSearchKeywordsToHighlight: {
          allKeywordsToHighlight,
          mustHavesKeywords,
        },
      };
      newState = cleanSet(state, ['ByJobId', jobId], newJob);
      return newState;
    }

    case SET_MANUAL_SEARCH_SOURCES: {
      ({ payload } = action);
      const { jobId, sources } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'ManualSearchPayload', 'Sources'], sources);
      return newState;
    }

    case SET_MANUAL_SEARCH_CRITERIA: {
      ({ payload } = action);
      const { criterias, jobId } = payload;
      let existingCriterias = state?.ByJobId?.[jobId]?.Criterias ?? {};
      criterias.forEach(criteria => {
        const criteriaType = getCriteriaType(criteria);
        if (criteriaType) {
          const { Id, Criteria } = criteria;
          existingCriterias = cleanSet(existingCriterias, [criteriaType], { Id, ...Criteria });
        }
      });
      newState = cleanSet(state, ['ByJobId', jobId, 'Criterias'], existingCriterias);
      return newState;
    }

    case SET_MANUAL_SEARCH_CRITERIA_IDENTIFIERS: {
      ({ payload } = action);
      const { criteriaIdentifiers, agentIdentifiers, count, jobId, criteriaType, searchTerm } = payload;
      const existingCriteriaIdentifiers = _.cloneDeep(state?.ByJobId?.[jobId]?.CriteriaIdentifiers ?? {});
      const criteriaIdentifiersToBeUpdated = existingCriteriaIdentifiers[criteriaType] ?? {};
      const combineIdentifiers = criteriaIdentifiers.concat(agentIdentifiers || []);
      if (criteriaIdentifiersToBeUpdated.SearchTerm === searchTerm) {
        criteriaIdentifiersToBeUpdated.Suggestions = (criteriaIdentifiersToBeUpdated.Suggestions ?? []).concat(
          combineIdentifiers
        );
      } else {
        criteriaIdentifiersToBeUpdated.Suggestions = combineIdentifiers;
        criteriaIdentifiersToBeUpdated.SearchTerm = searchTerm;
      }
      criteriaIdentifiersToBeUpdated.Count = count;
      existingCriteriaIdentifiers[criteriaType] = criteriaIdentifiersToBeUpdated;
      newState = cleanSet(state, ['ByJobId', jobId, 'CriteriaIdentifiers'], existingCriteriaIdentifiers);
      return newState;
    }

    case SET_MANUAL_SEARCH_CRITERIA_BY_ID: {
      ({ payload } = action);
      const { criteria, id } = payload;
      newState = cleanSet(state, ['CriteriasById', id], criteria);
      return newState;
    }

    case SET_MANUAL_SEARCH_CRITERIA_RECORD_BY_ID: {
      ({ payload } = action);
      const { criteria, id, jobId } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'CriteriaRecordsById', id], criteria);
      return newState;
    }

    case SET_RETRIEVED_JOBACTIVITY_ID: {
      ({ payload } = action);
      const { jobActivityId, jobId } = payload;
      const jobActivity = { LastRetrievedTime: Date.now(), Id: jobActivityId };
      newState = cleanSet(state, ['ByJobId', jobId, 'JobActivity'], jobActivity);
      return newState;
    }

    case RESET_MANUAL_SEARCH_JOBACTIVITY: {
      ({ payload } = action);
      const { jobId } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'JobActivity'], {});
      return newState;
    }

    case SET_SELECTED_CRITERIA_NAME: {
      ({ payload } = action);
      const { jobId, criteriaName } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'SelectedCriteriaName'], criteriaName);
      return newState;
    }
    case SET_SELECTED_CRITERIA_TYPE: {
      ({ payload } = action);
      const { jobId, selectedCriteriaType } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'SelectedCriteriaType'], selectedCriteriaType);
      return newState;
    }
    case SET_SELECTED_CRITERIA_ID: {
      ({ payload } = action);
      const { jobId, criteriaId } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'SelectedCriteriaId'], criteriaId);
      return newState;
    }

    case SET_LAST_PERFORMED_MANUAL_SEARCH_TIME: {
      ({ payload } = action);
      const { jobId } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'LastPerformedManualSearchTime'], Date.now());
      return newState;
    }

    case SET_IS_REACTIVATION_ALLOWED_FLAG: {
      ({ payload } = action);
      const { jobId, isReactivationAllowedFlag } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'ReactivationAllowed'], isReactivationAllowedFlag);
      return newState;
    }

    case SET_IS_DEFAULT_CRITERIA_FETCHED_FLAG: {
      ({ payload } = action);
      const { jobId, isDefaultCriteriaFetchedFlag } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'DefaultCriteriaFetchedFlag'], isDefaultCriteriaFetchedFlag);
      return newState;
    }

    case SET_MANUAL_SEARCH_FORM_VALUES: {
      ({ payload } = action);
      const { jobId, manualSearchFormValues } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'ManualSearchFormValues'], manualSearchFormValues);
      return newState;
    }

    case SET_MANUAL_SEARCH_MUST_HAVES: {
      ({ payload } = action);
      const { jobId, mustHaves } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'ManualSearchFormValues', 'MustHaves'], mustHaves);
      return newState;
    }

    case CLEAR_MANUAL_SEARCH_CRITERIA: {
      ({ payload } = action);
      const { jobId } = payload;
      newState = cleanSet(state, ['ByJobId', jobId], {});
      return newState;
    }
    case SET_IS_PREFETCH_REQUIRED_FETCHED_FLAG: {
      ({ payload } = action);
      const { jobId, isPrefetchRequiredFlag } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'PreFetchedFlag'], isPrefetchRequiredFlag);
      return newState;
    }
    case SET_MANUAL_SEARCH_SELECTED_CRITERIA_INFO: {
      ({ payload } = action);
      const { jobId, criteriaInfo } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'ManualSearchSelectedCriteriaInfo'], criteriaInfo);
      return newState;
    }
    case SET_MANUAL_SEARCH_CRITERIA_TYPE: {
      ({ payload } = action);
      const { type } = payload;
      newState = cleanSet(state, 'ManualSearchFooterButtonType', type);
      return newState;
    }
    case SET_MANUAL_SEARCH_CURRENT_CRITERIA_TYPE_AND_RECORD_ID: {
      ({ payload } = action);
      const { recordId, jobId, criteriaType } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'ManualSearchCurrentRecordId'], recordId);
      newState = cleanSet(newState, ['ByJobId', jobId, 'ManualSearchCurrentCriteriaType'], criteriaType);
      return newState;
    }
    case SET_IS_ALL_TAB_CANDIDATE_AGGREGATION_FILTER_APPLIED_FLAG: {
      ({ payload } = action);
      const { jobId, isFilterAppliedFlag } = payload;
      newState = cleanSet(state, ['ByJobId', jobId, 'AllTabFilterAppliedFlag'], isFilterAppliedFlag);
      return newState;
    }
    default:
      break;
  }

  return state;
}

function getManualSearchPayload(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.ManualSearchPayload ?? emptyObject;
}

function getManualSearchKeywordsToHighlight(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.ManualSearchKeywordsToHighlight;
}

function getRetrievedJobActivity(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.JobActivity ?? emptyObject;
}

function getManualSearchCriteria(state, { jobId, criteriaType }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.Criterias?.[criteriaType] ?? emptyObject;
}

function getManualSearchCriteriaDetailsById(state, { id }) {
  return state.ManualSearchReducer?.CriteriasById?.[id] ?? emptyObject;
}

function getManualSearchCriteriaIdentifiers(state, { jobId, criteriaType }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.CriteriaIdentifiers?.[criteriaType] ?? emptyObject;
}

function getManualSearchSelectedCriteriaName(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.SelectedCriteriaName;
}
function getManualSearchSelectedCriteriaType(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.SelectedCriteriaType;
}
function getManualSearchSelectedCriteriaId(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.SelectedCriteriaId;
}

function geLastPerformedManualSearchTime(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.LastPerformedManualSearchTime;
}

function getManualSearchCriteriaRecordById(state, { jobId, jobActivityId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.CriteriaRecordsById?.[jobActivityId];
}

function getIsReactivationAllowed(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.ReactivationAllowed;
}

function getIsDefaultCriteriaFetchedFlag(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.DefaultCriteriaFetchedFlag;
}

function getBooleanSearchStrings(state) {
  return state.ManualSearchReducer.SearchStrings;
}

function getManualSearchFormValues(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.ManualSearchFormValues;
}

export function getManualSearchPreFetchedFlag(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.PreFetchedFlag;
}

export function getManualSearchCriteriaInfo(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId]?.ManualSearchSelectedCriteriaInfo;
}

export function getManualSearchCriteriaType(state) {
  return state.ManualSearchReducer.ManualSearchFooterButtonType || 'SaveCriteria';
}

const getJobActivityWithPayload = createSelector(
  [state => state.ManualSearchReducer.ByJobId, (state, { jobId }) => jobId],
  (jobs, jobId) => {
    const jobActivity = jobs?.[jobId] ?? emptyObject;
    const { Id: jobActivityId, LastRetrievedTime: lastJobActivityRetrievedTime } =
      jobActivity.JobActivity || emptyObject;

    return {
      jobActivityId,
      lastJobActivityRetrievedTime,
      jobActivityPayload: jobs?.[jobId]?.CriteriaRecordsById?.[jobActivityId],
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);
export function getIsAllTabFiltersApplied(state, { jobId }) {
  return state.ManualSearchReducer.ByJobId?.[jobId].AllTabFilterAppliedFlag;
}
export function getManualSearchCriteriaTypeAndRecordId(state, { jobId }) {
  const { ManualSearchCurrentCriteriaType: criteriaType, ManualSearchCurrentRecordId: recordId } =
    state.ManualSearchReducer?.ByJobId?.[jobId] || {};
  return { criteriaType, recordId };
}
export {
  getBooleanSearchStrings,
  ManualSearchReducer,
  getManualSearchPayload,
  getManualSearchKeywordsToHighlight,
  getRetrievedJobActivity,
  getJobActivityWithPayload,
  getManualSearchCriteria,
  getManualSearchCriteriaDetailsById,
  getManualSearchCriteriaIdentifiers,
  getManualSearchSelectedCriteriaName,
  getManualSearchSelectedCriteriaType,
  getManualSearchSelectedCriteriaId,
  geLastPerformedManualSearchTime,
  getManualSearchCriteriaRecordById,
  getIsDefaultCriteriaFetchedFlag,
  getIsReactivationAllowed,
  getManualSearchFormValues,
};
