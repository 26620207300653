import React from 'react';
import _ from 'lodash';
import { Radio } from 'antd';
import { getProviderDisplayName } from '../../../Utils/SmtpUtils';
import styles from './EmailConfigurationModal.module.scss';
import LinkButton from '../../Common/LinkButton/LinkButton';
import { EllipsedText } from '../../../Utils/TextUtils';

export default function ExistingEmailProviders(props) {
  const { emailProviders, isorgDefaultEmail, currentEmailProvider, value, onChange, handleDeleteEmailProvider } = props;
  const availableProviders = emailProviders.filter(
    x => !x.Emails?.length || (x.AuthScheme === 'Manual')
  );
  const mergedEmailProviders = !_.isEmpty(currentEmailProvider)
    ? availableProviders.concat(currentEmailProvider)
    : availableProviders;
  const uniqEmailProviders = _.uniqBy(mergedEmailProviders, 'Id');

  const handleRadioChange = event => {
    onChange(event.target.value);
  };

  const getRadioGroup = () => (
    <Radio.Group value={value} onChange={e => handleRadioChange(e)} className={styles.emailProvidersGroup}>
      {uniqEmailProviders.map(provider => {
        const providerDisplayName = getProviderDisplayName(provider);
        const configHost = provider.Configuration.Email ?? provider.Configuration.Host;
        return (
          <Radio value={provider.Id} key={provider.Id}>
            <span className={styles.providerWrapper}>
              <span className={styles.providerName}>
                <EllipsedText text={configHost} maxTextLength={25} />
                {` `}({providerDisplayName})
              </span>
              {provider.isAdminConfiguredEmailProvider ? null : (
                <LinkButton
                  type="danger"
                  buttonName="Delete"
                  onClickButton={event => handleDeleteEmailProvider(provider.Id, event)}
                />
              )}
            </span>
          </Radio>
        );
      })}
    </Radio.Group>
  );

  return (
    <div className={styles.existingEmailProviders}>
      {!isorgDefaultEmail ? (
        <div>
          {' '}
          <div className={styles.header}>Email Provider</div>
          {uniqEmailProviders?.length ? getRadioGroup() : 'No email providers available'}
        </div>
      ) : null}
    </div>
  );
}
