import _ from 'lodash';
import * as clientRepository from '../Repository/ClientRepository';
import {
  setCreateClientApiStatus,
  setCurrentClientCreationInfo,
  clearCurrentClientCreationInfo as _clearCurrentClientCreationInfo,
} from './ActionCreators/CreateClientActionCreator';
import { mapErrorCodes } from '../Utils/CreateClientIdApiErrorResponseMapper';
import { setNotification } from './ActionCreators/ConnectActions';

function fetchClientList(filter) {
  return dispatch => {
    clientRepository
      .fetchClientList(filter)
      .then(response => {
        dispatch({
          type: 'SET_CLIENT_LIST',
          payload: response.data.Clients,
        });
        dispatch({
          type: 'SET_CLIENT_COUNT',
          payload: response.data.Total,
        });
        dispatch({
          type: 'SET_ACTIVE_CLIENT_COUNT',
          payload: response.data.Active,
        });
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR', {
            messageId: 'failedToLoadClientList',
          })
        );
      });
  };
}

function addClient(clientDetails) {
  const createClientPayload = {
    ...clientDetails,
    Industries: (clientDetails.Industries || []).map(i => {
      return {
        Name: i,
      };
    }),
    CompetitorCompanies: _.get(clientDetails, 'CompetitorCompanies', false)
      ? clientDetails.CompetitorCompanies.map(c => {
          return {
            Name: c,
          };
        })
      : null,
  };
  return dispatch => {
    dispatch(setCreateClientApiStatus({ status: 'INPROGRESS' }));
    clientRepository
      .addClient(createClientPayload)
      .then(response => {
        dispatch({
          type: 'ADD_CLIENT',
          payload: createClientPayload,
        });
        dispatch(
          fetchClientList({
            From: 0,
            Size: 10,
            SortBy: 'ModifiedDate',
            SortOrder: 'Descending',
          })
        );
        dispatch(setCreateClientApiStatus({ status: 'COMPLETED' }));
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'clientAddedSuccessfully',
          })
        );
        dispatch(setCurrentClientCreationInfo({ Id: response.data.Id, Name: createClientPayload.Name }));
      })
      .catch(err => {
        if (err) {
          const { isVisible, errorMessage } = mapErrorCodes(err);
          dispatch(setCreateClientApiStatus({ status: 'FAILED', error: err }));

          if (isVisible) {
            dispatch(
              setNotification('ERROR', {
                messageId: 'failedToAddClient',
              })
            );
          }
        }
      });
  };
}

function clearCurrentClientCreationInfo() {
  return _clearCurrentClientCreationInfo();
}

function patchUpdateClient(id, clientUpdate) {
  return dispatch => {
    dispatch(setCreateClientApiStatus({ status: 'INPROGRESS' }));
    clientRepository
      .patchUpdateClient(id, clientUpdate)
      .then(() => {
        const clientUpdatedStatus = clientUpdate.filter(detail => detail.path === 'IsActive');
        dispatch({
          type: 'UPDATE_CLIENT_DETAILS',
          payload: {
            id,
            clientUpdate,
          },
        });
        dispatch(setCreateClientApiStatus({ status: 'COMPLETED' }));
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'clientUpdatedSuccessfully',
          })
        );

        if (clientUpdatedStatus.length > 0) {
          dispatch({
            type: 'CHANGE_ACTIVE_CLIENT_COUNT',
            payload: {
              isClientActivated: clientUpdatedStatus[0].value,
            },
          });
        }
      })
      .catch(err => {
        if (err) {
          const { isVisible, errorMessage } = mapErrorCodes(err);
          dispatch(setCreateClientApiStatus({ status: 'FAILED', error: err }));

          if (isVisible) {
            dispatch(
              setNotification('ERROR', {
                messageId: 'failedToAddClient',
              })
            );
          }
        }
      });
  };
}

function changeClientStatus(clientId, status) {
  return dispatch => {
    dispatch({
      type: 'SET_CLIENT_STATUS_API_STATUS',
      payload: {
        id: clientId,
        changeClientStatusApiStatus: 'IN_PROGRESS',
      },
    });
    clientRepository
      .patchUpdateClient(clientId, status)
      .then(() => {
        dispatch({
          type: 'UPDATE_CLIENT_DETAILS',
          payload: {
            id: clientId,
            clientUpdate: status,
          },
        });
        dispatch({
          type: 'CHANGE_ACTIVE_CLIENT_COUNT',
          payload: {
            isClientActivated: status[0].value,
          },
        });
        dispatch({
          type: 'SET_CLIENT_STATUS_API_STATUS',
          payload: {
            id: clientId,
            changeClientStatusApiStatus: 'COMPLETED',
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_CLIENT_STATUS_API_STATUS',
          payload: {
            id: clientId,
            changeClientStatusApiStatus: 'COMPLETED',
          },
        });
      });
  };
}

function fetchClientDetails(id) {
  return dispatch => {
    clientRepository
      .fetchClientDetails(id)
      .then(response => {
        dispatch({
          type: 'SET_CLIENT_DETAILS',
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR', {
            messageId: 'failedToLoadClientDetails',
          })
        );
      });
  };
}

function toggleAddClientDrawer() {
  return dispatch => {
    dispatch({
      type: 'TOGGLE_ADD_CLIENT_DRAWER',
    });
  };
}

export {
  addClient,
  patchUpdateClient,
  changeClientStatus,
  fetchClientList,
  fetchClientDetails,
  toggleAddClientDrawer,
  clearCurrentClientCreationInfo,
};
