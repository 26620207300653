import _ from 'lodash';
import * as AryabotTemplatesApi from '../Repository/AryabotTemplates';
import { fetchConnectUsers } from './UserActions';
import { setNotification } from './ActionCreators/ConnectActions';

export function searchAryabotTemplates(filter) {
  return dispatch => {
    AryabotTemplatesApi.searchAryabotTemplates(filter).then(response => {
      dispatch({
        type: 'SET_ARYABOT_TEMPLATES',
        payload: response.data.Templates,
      });
      dispatch(
        fetchConnectUsers({
          userIds: _.uniq(response.data.Templates.map(template => template.CreatedBy)),
        })
      );
      dispatch({
        type: 'SET_ARYABOT_TEMPLATES_COUNT',
        payload: response.data.Total,
      });
    });
  };
}

export function getAryabotTemplate(templateId) {
  return dispatch => {
    AryabotTemplatesApi.getAryabotTemplate(templateId).then(response => {
      dispatch({
        type: 'SET_ARYABOT_TEMPLATE_DETAILS',
        payload: {
          templateId,
          template: response.data,
        },
      });
    });
  };
}

export function createAryabotTemplate(templateName, isPublic, questions, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'creatingAryaBotTemplate',
      })
    );
    AryabotTemplatesApi.createAryabotTemplate(templateName, isPublic, questions).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'aryaBotTemplateCreated',
        })
      );
    });
  };
}

export function editAryabotTemplate(templateId, templateName, isPublic, questions, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'updatingAryaBotTemplate',
      })
    );
    AryabotTemplatesApi.editAryabotTemplate(templateId, templateName, isPublic, questions).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'aryaBotTemplateUpdated',
        })
      );
    });
  };
}

export function shareAryabotTemplate(templateId, shareOptions) {
  let successMessageId = 'shareAryaBotSuccessMessage';
  let loadingMessageId = 'shareAryaLoadingMessage';
  if (shareOptions.isShareWithAll) {
    loadingMessageId = 'shareAryaLoadingMessageWithAll';
  }
  if (shareOptions.isUnshareWithAll) {
    successMessageId = 'shareAryaBotSuccessMessageUnshareAll';
    loadingMessageId = 'shareAryaLoadingMessageUnshareWithAll';
  }
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: loadingMessageId,
      })
    );
    AryabotTemplatesApi.shareAryabotTemplate(templateId, shareOptions)
      .then(() => {
        dispatch({
          type: 'SET_ARYABOT_TEMPLATE_SHARED_WITH',
          payload: shareOptions,
          templateId,
        });
        dispatch(
          setNotification('SUCCESS', {
            messageId: successMessageId,
          })
        );
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SHARE_TEMPLATE_POST_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(
          setNotification('ERROR', {
            messageId: 'templateSharingFailed',
          })
        );
      });
  };
}

export function duplicateAryabotTemplate(templateId, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'duplicateAryaBotMessage',
      })
    );
    AryabotTemplatesApi.duplicateAryabotTemplate(templateId).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'aryaBotTemplateDuplicated',
        })
      );
    });
  };
}

export function getQuestions(templateId, callback) {
  return dispatch => {
    AryabotTemplatesApi.getQuestions(templateId).then(response => {
      dispatch({
        type: 'SET_QUESTIONS',
        payload: {
          templateId,
          questions: response.data,
        },
      });
      if (callback) {
        callback(response.data);
      }
    });
  };
}

export function postQuestions(templateId, questions, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'updatingAryaBotTemplate',
      })
    );
    AryabotTemplatesApi.postQuestions(templateId, questions).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'aryaBotTemplateUpdated',
        })
      );
    });
  };
}

export function getAnswers(templateId) {
  return dispatch => {
    AryabotTemplatesApi.getAnswers(templateId).then(response => {
      dispatch({
        type: 'SET_ANSWERS',
        payload: response.data,
      });
    });
  };
}

export function postAnswers(templateId, answers) {
  return () => {
    AryabotTemplatesApi.postAnswers(templateId, answers);
  };
}

export function putAnswers(templateId, answers) {
  return () => {
    AryabotTemplatesApi.putAnswers(templateId, answers);
  };
}

export function deleteAryabotTemplate(templateId, filter) {
  return dispatch => {
    dispatch(
      setNotification('LOADING', {
        messageId: 'deletingAryaBotTemplate',
      })
    );
    AryabotTemplatesApi.deleteTemplate(templateId).then(() => {
      dispatch(searchAryabotTemplates(filter));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'aryaBotTemplateDeleted',
        })
      );
    });
  };
}
