import React from 'react';
import { Tooltip } from 'antd';
import styles from './Candidate360MinimizedCandidateInformationCard.module.scss';
import { isInternalIconVisible, getCandidatePrimarySourceImage } from '../../Utils/DownloadedCandidatesSourceUtils';
import { getCandidateStatusFromSources, getCandidateStatus } from '../../Utils/CandidateDrawerUtils';
import SourceImageIcon from '../CandidateListItem/SourceImageIcon/SourceImageIcon';
import { EllipsedText } from '../../Utils/TextUtils';
import TrophyAwardIcon from '../../Icons/TrophyAwardIcon';

function Candidate360MinimizedCandidateInformationCard(props) {
  const { candidate, featureToggleList, candidateSourceNameToDisplay, userConfig, atsConfig, jobGuid } = props;

  const { ShowVaultName: shouldShowVaultName } = userConfig;
  const showVaultName = shouldShowVaultName ?? false;

  const {
    WhiteGloveServiceProgress: { IsEnabled: isSRPulseUser },
    RevealPortalsUnderGroup: { IsEnabled: revealActiveChannelSourceName },
  } = featureToggleList;

  const showInternalIcon = isInternalIconVisible(candidate, isSRPulseUser);

  const getCandidateInternalImageIcon = () => {
    return candidate?.Id ? (
      <SourceImageIcon
        tooltipVisibility
        placement="top"
        sourceName="Internal"
        sourcePortal="InternalForDownloadedCandidates"
        iconStyle={{ fontSize: 20, display: 'flex' }}
        showVaultName={showVaultName}
      />
    ) : null;
  };

  const candidatePrimarySourceImage = (
    <span className={styles.sourceImage}>
      {getCandidatePrimarySourceImage(
        candidate,
        20,
        revealActiveChannelSourceName,
        showVaultName,
        candidateSourceNameToDisplay
      )}
    </span>
  );

  const candidateDrawerPortalIcon = showInternalIcon ? (
    <div>
      {candidatePrimarySourceImage}
      <span className={styles.overlappingSourceImage}>{getCandidateInternalImageIcon()}</span>
    </div>
  ) : (
    candidatePrimarySourceImage
  );

  const {
    ShowAllClientCandidateStatus: { IsEnabled: isShowAllClientCandidateStatus },
  } = featureToggleList;
  const candidateStatus = getCandidateStatusFromSources(candidate);
  const { statusTag } = getCandidateStatus(candidate, featureToggleList, jobGuid, atsConfig);

  return (
    <div className={styles.minimizedCandidateInformation}>
      <div>
        <span className={styles.portalIcon}>{candidateDrawerPortalIcon}</span>
      </div>
      <div>
        <div className={styles.candidateNameAndLocation}>
          <Tooltip title={candidate?.Name} placement="bottom">
            <span className={styles.candidateName}>{candidate?.Name}&nbsp;</span>
          </Tooltip>
          <span>
            {isShowAllClientCandidateStatus && (candidateStatus || statusTag) ? (
              <div className={styles.candidateStatusWithIcon}>
                <TrophyAwardIcon />
                <div className={styles.candidateStatus}>
                  <EllipsedText text={candidateStatus || statusTag} maxTextLength={15} tooltipPlacement="bottom" />
                </div>
              </div>
            ) : (
              candidateStatus
            )}
          </span>
        </div>
        <div className={styles.candidateLocation}>{candidate?.Location}</div>
      </div>
    </div>
  );
}

export default Candidate360MinimizedCandidateInformationCard;
