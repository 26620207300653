import * as React from 'react';
import { Icon } from 'antd';

const CandidateAggregationSvg = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.46154 12H17.5385M4 7H20M10.1538 17H13.8462"
      stroke="#83878C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const CandidateAggregationIcon = props => (
  <Icon component={customProps => <CandidateAggregationSvg {...props} {...customProps} />} {...props} />
);
export default CandidateAggregationIcon;
