import React, { useRef } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useKeyboardListNavigation } from 'use-keyboard-list-navigation';
import { Dropdown, Menu, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getPlainTextFromHTML } from '../../Utils/TextUtils';
import RichTextEditor from '../Editor/RichTextEditor';
import { VectorSendIcon } from '../../Icons/AryaIcons';
import ChatGPTInputSendEnabledIcon from '../../Icons/ChatGPTInputSendEnabledIcon';
import styles from './DynamicTagInput.module.scss';
import messages from '../../Containers/CandidateDrawer/messages';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';

let rawSearchedValue = '';

const mapDispatchToProps = {
  SetNotification: setNotification,
};
export const handleMergeTagFilter = (htmlContent, mergeTags) => {
  if (!htmlContent.includes('#')) return mergeTags;
  const hashTagTextRegex = /#(\w+)/;
  const matches = htmlContent.match(hashTagTextRegex);
  const searchValue = matches?.[1];
  rawSearchedValue = matches?.[0];
  if (!rawSearchedValue) rawSearchedValue = /#/g;
  if (searchValue) {
    return mergeTags.filter(tag => tag.Key.toLowerCase().includes(searchValue.toLowerCase()));
  }
  return mergeTags;
};

export const changeCursorPosition = (inputField, inputValue) => {
  const indexValue = inputField?.getSelection();
  const inputLength = inputValue?.Value?.length;
  if (indexValue) {
    const { index } = indexValue;
    const cursorPosition = index + inputLength;
    inputField?.setSelection(cursorPosition);
  }
};

const DynamicTagInput = ({
  visible,
  handleRegenerateOnClick,
  mergeTags,
  displayRegenerateButton,
  inputChanged,
  setIsInputChanged,
  SetNotification,
}) => {
  const textRef = React.useRef(null);
  const richTextEditorRef = React.useRef(null);
  const dropdownTriggerTarget = useRef(null);
  const dropdownRef = useRef(null);
  const [richTextEditorContnet, setRichTextEditorContent] = React.useState('');
  const [filteredMergeTags, setFilteredMergeTags] = React.useState(mergeTags);
  const [selectedOption, setSelectedOption] = React.useState(null);

  const handleChange = e => {
    const selectedMergeTags = e.element ? [e.element] : filteredMergeTags?.filter(t => t?.Key === e.key);
    if (
      selectedMergeTags?.length === 0 ||
      selectedMergeTags[0]?.Value === null ||
      selectedMergeTags[0]?.Value?.trim()?.length === 0
    ) {
      const replacedText = richTextEditorContnet.replace(rawSearchedValue, '');
      setRichTextEditorContent(replacedText);
      SetNotification('ERROR', {
        messageId: 'selectedMergeTagValueNotAvailableLabel',
      })
      keyBoardData.reset();
      return;
    }
    const mergeTagValue = getPlainTextFromHTML(selectedMergeTags[0]?.Value);
    const replacementText = mergeTagValue ? `<span style="color: #40A9FF">'${mergeTagValue}'</span>` : '';
    const replacedText = richTextEditorContnet.replace(rawSearchedValue, replacementText);
    setRichTextEditorContent(replacedText);
    if (e?.element?.Value) {
      changeCursorPosition(richTextEditorRef?.current?.reactQuillRef?.getEditor(), e.element);
    }

    keyBoardData.reset();
  };

  const keyBoardData = useKeyboardListNavigation({
    list: [{ Id: -1 }, ...(filteredMergeTags ?? [])],
    onEnter: handleChange,
  });
  const { selected } = keyBoardData;

  React.useEffect(() => {
    setSelectedOption(selected);
    if (document.getElementById(`id-${selected?.Key}`))
      document.getElementById(`id-${selected?.Key}`).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [selected.Key]);

  const handleInputChange = ({ htmlContent }) => {
    const regex = /(<([^>]+)>)/gi;
    setRichTextEditorContent(htmlContent);
    const currentMergeTags = handleMergeTagFilter(htmlContent, mergeTags);
    setFilteredMergeTags(currentMergeTags);
    const content = htmlContent?.replace(regex, '');
    if (content?.trim() !== '') {
      if (displayRegenerateButton) {
        setIsInputChanged(!!content);
      } else {
        setIsInputChanged(false);
      }
    } else {
      setIsInputChanged(false);
    }
  };

  const handleTagsSelectionConfirm = () => {
    setRichTextEditorContent('');
    const plainTextFromHtml = getPlainTextFromHTML(richTextEditorContnet);
    handleRegenerateOnClick(plainTextFromHtml);
    setIsInputChanged(false);
  };

  const handleMenuItemMouseOver = (isMouseOver, option) => {
    if (isMouseOver) {
      setSelectedOption(option);
      const index = filteredMergeTags.findIndex(x => x.Key === option.Key);
      keyBoardData.set({ cursor: index + 1 });
    }
  };

  function getDropdownHeader() {
    const dropdownText = filteredMergeTags?.length ? (
      <FormattedMessage {...messages.WillAutomaticallyBeReplacedByTheSpecificJobLabel} />
    ) : (
      <FormattedMessage {...messages.noMergeTagsAvailableLabel} />
    );
    return (
      <div
        style={{
          display: mergeTags?.length > 1 ? 'flex' : 'none',
        }}
        className={styles.suggestionsDropdownHeader}
      >
        <div>
          <span>{dropdownText}</span>
        </div>
      </div>
    );
  }
  const menu = (
    <Menu onClick={handleChange}>
      {getDropdownHeader()}
      {filteredMergeTags?.map(tag => {
        return (
          <Menu.Item
            className={classNames(styles.suggestionDropdownItem, {
              [styles.liSelected]: selectedOption ? selectedOption.Key === tag.Key : false,
            })}
            onMouseEnter={() => handleMenuItemMouseOver(true, tag)}
            onMouseLeave={() => handleMenuItemMouseOver(false, tag)}
            key={tag.Key}
            id={`id-${tag.Key}`}
          >
            #{tag.Key}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div>
      {visible ? (
        <div className={styles.inputWrapperChatGpt} ref={textRef}>
          <RichTextEditor
            placeholder={`Enter your instructions for ChatGPT. Use # to include pre-set parameters.\n\n E.g. : Write an introductory email as a recruiter to encourage #Candidate.Name to apply for #Job.Title. Use less than 200 words and an executive-level tone.`}
            onChange={handleInputChange}
            className={styles.chatGptInputButton}
            editorContent={{
              htmlContent: richTextEditorContnet,
            }}
            restrictedReactQuillFormats={['image', 'video']}
            ref={richTextEditorRef}
          />
          <div ref={dropdownRef}>
            <Dropdown
              overlay={menu}
              visible={richTextEditorContnet.includes('#')}
              overlayClassName={styles.suggestionsDropdown}
              getPopupContainer={node => node.parentNode}
            >
              <a
                ref={dropdownTriggerTarget}
                className="ant-dropdown-link"
                onClick={e => e.preventDefault()}
                style={{
                  position: 'absolute',
                  top: `${textRef?.current?.offsetTop}px`,

                  visibility: 'hidden',
                }}
              >
                <FormattedMessage {...messages.dontHoverMeLabel} />
              </a>
            </Dropdown>
          </div>
          {inputChanged ? (
            <ChatGPTInputSendEnabledIcon
              className={styles.regenerateButton}
              onClick={e => {
                e.stopPropagation();
                handleTagsSelectionConfirm();
              }}
            />
          ) : (
            <VectorSendIcon className={styles.regenerateDisableButton} />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(DynamicTagInput);
export {DynamicTagInput as DynamicTagInputwithoutStore}
