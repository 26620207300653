import React from 'react';
import { Form, Checkbox } from 'antd';

const { Item } = Form;

const FormCheckboxComponent = props => {
  const {
    form,
    fieldDecoratorValue,
    displayText,
    initialValue,
    formItemClassName,
    checkboxClassName,
    disabled,
    onChange,
  } = props;

  React.useEffect(() => {
    if (disabled) {
      form.setFieldsValue({ [fieldDecoratorValue]: false });
    }
  }, [disabled]);
  return (
    <Item className={formItemClassName}>
      {form.getFieldDecorator(fieldDecoratorValue, {
        initialValue,
        valuePropName: 'checked',
      })(
        <Checkbox
          className={checkboxClassName}
          disabled={disabled}
          onChange={event => {
            if (onChange) onChange(event);
          }}
        >
          {displayText}
        </Checkbox>
      )}
    </Item>
  );
};

export default FormCheckboxComponent;
