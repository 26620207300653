import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Icon, Tooltip, message,Form } from 'antd';
import styles from './PushCandidateComponent.module.scss';
import PushIconV2 from '../../Icons/PushIconV2';
import messages from '../CandidateCard/CandidateCardMessages';

const appliedBucketPushCandidateErrorText = 'youCanOnlyPushCandidatesThatAreInAppliedBucket';

function PushCandidateComponent(props) {
  const {
    atsSource,
    onPushCandToAts,
    loading = false,
    isPushAllowed = false,
    showContactInfoButton,
    showPushCandidateButton,
    isRecreateCandidateAllowed,
    jobStatus,
    aryaJobStatus,
    isPushCandidateNotAllowedForAryaClosedJobs,
    isManualCandidateCreationAllowedForApplied,
    appliedtime,
    intl,
  } = props;

  if (loading) {
    return (
      <div className={styles.loadinIcon}>
        <Icon type="loading" />
      </div>
    );
  }
  const getIsCandidatePushAllowedForCurrentJobStatus = () =>
    aryaJobStatus.toLowerCase() !== 'closed' || !isPushCandidateNotAllowedForAryaClosedJobs;

  const closedJobStatusErrorText = `youCannotPushCandidateForJobsThatAreInTheJobStatusState`;

  const handlePushIconOnClick = e => {
    if (isManualCandidateCreationAllowedForApplied && !appliedtime) {
      e.stopPropagation();
      showAlertForPushOnCandidates(appliedBucketPushCandidateErrorText, null);
      return;
    }
    if (isPushAllowed && isRecreateCandidateAllowed) {
      if (getIsCandidatePushAllowedForCurrentJobStatus()) onPushCandToAts();
      else showAlertForPushOnCandidates(closedJobStatusErrorText, jobStatus);
      return;
    }
    e.stopPropagation();
  };

  const handleInfoButtonOnClick = e => {
    if (isManualCandidateCreationAllowedForApplied && !appliedtime) {
      e.stopPropagation();
      showAlertForPushOnCandidates(appliedBucketPushCandidateErrorText, null);
      return;
    }
    if (getIsCandidatePushAllowedForCurrentJobStatus()) onPushCandToAts();
    else showAlertForPushOnCandidates(closedJobStatusErrorText, jobStatus);
    e.stopPropagation();
  };

  const showAlertForPushOnCandidates = (errorText, JobStatus) => {
    message.error(intl.formatMessage({ ...messages[errorText] },{JobStatus}))
  };

  if (atsSource) {
    return (
      <Tooltip
        title={
          <div>
            <FormattedMessage {...messages.clientCandidateId} />: {atsSource.CandidateId}
          </div>
        }
        overlayClassName="drawer-tooltip"
      >
        <div className={styles.candidateDetailPushedCandidate}>
          <PushIconV2
            className={styles.pushStatusIcon}
            onClick={handlePushIconOnClick}
            style={{ fontSize: '18px' }}
            fillColor="#07101a"
          />
        </div>
      </Tooltip>
    );
  }

  const shouldShowInfoButton = showContactInfoButton && isPushAllowed && showPushCandidateButton;

  if (shouldShowInfoButton) {
    return (
      <div className={styles.candidateDetailPushCandidate} role="presentation" onClick={handleInfoButtonOnClick}>
        <PushIconV2 style={{ fontSize: '18px' }} fillColor="#07101a" />
      </div>
    );
  }
  return null;
}

export default (injectIntl(Form.create()(PushCandidateComponent)));
