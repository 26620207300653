import React from 'react';
import { Icon } from 'antd';

function TickSVG(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.67115 8.66374C4.69246 8.65932 4.70295 8.66469 4.7033 8.66489L5.0705 8.01093L4.7033 8.66489C5.07051 8.87107 5.34197 9.05854 5.62388 9.31334C6.0799 9.7255 6.83527 9.68552 7.21449 9.13171C8.44337 7.33705 9.8604 5.45169 11.4707 4.08924C11.4769 4.09389 11.4883 4.10534 11.4973 4.12628C11.5068 4.14819 11.509 4.16967 11.507 4.18551C11.5055 4.19784 11.5013 4.21058 11.4872 4.22681C9.67495 6.30774 8.12782 8.58808 6.67495 10.9132C6.57924 11.0664 6.3344 11.0897 6.21037 10.9114C5.72699 10.2164 5.23985 9.5923 4.64497 8.99709L4.1145 9.52728L4.64497 8.99709C4.59574 8.94783 4.56767 8.86152 4.59026 8.7679C4.60059 8.72508 4.61822 8.69828 4.63078 8.68503C4.64023 8.67507 4.6503 8.66805 4.67115 8.66374Z" fill="black" stroke="#1751D0" stroke-width="1.5"/>
</svg>
  );
}

const TickIcon = props => <Icon component={TickSVG} {...props} />;

export default TickIcon;
