import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from './TimeAgoComponent.module.scss';

const TimeAgo = ({ createdTime }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const updateTime = () => {
      setTimeAgo(moment(createdTime).fromNow());
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000);
    return () => clearInterval(intervalId);
  }, [createdTime]);

  return <span className={styles.createdTime} >{timeAgo}</span>;
};

export default TimeAgo;
