import React from 'react';
import { Alert } from 'antd';

function MessageWindowTemplateConsentNote() {
  return (
    <Alert message="Note: Below text will be appended to the end of every consent text message." type="info" showIcon />
  );
}

export default MessageWindowTemplateConsentNote;
