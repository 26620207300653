import React from 'react';
import { Button, Input, Icon, Popover, Modal } from 'antd';
import { connect } from 'react-redux';
import styles from './ReportInvalidIncorrectButton.module.scss';
import * as connectActions from '../../../Actions/ConnectActions';

const { TextArea } = Input;

const ReportInvalidIncorrectButton = props => {
  const [popoverVisibility, setPopoverVisibility] = React.useState(false);
  const [phoneValidityStatusRemark, setPhoneValidityStatusRemark] = React.useState('');
  const isSubmitButtonDisabled = !phoneValidityStatusRemark.trim().length;
  const { candidate, selectedPhone } = props;

  const togglePopoverVisibility = () => {
    setPopoverVisibility(!popoverVisibility);
  };

  const onClickInvalidButton = () => {
    togglePopoverVisibility();
  };

  const handleInvalidPhoneReason = e => {
    setPhoneValidityStatusRemark(e.target.value);
  };

  const handleSubmitButtonClick = ({ type }) => {
    const modalOptions = {
      title: `Are you sure you want to report this ${type} as invalid?`,
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      okText: 'Report Invalid',
      onOk: () => handlePhoneStatusUpdate(),
      zIndex: 2200,
      centered: true,
      width: 400,
    };
    Modal.confirm(modalOptions);
  };

  const handlePhoneStatusUpdate = () => {
    const { handlePhoneValidityStatusUpdate } = props;
    if (phoneValidityStatusRemark.trim() !== '') {
      handlePhoneValidityStatusUpdate(candidate?.PersonId, selectedPhone, 'Invalid', phoneValidityStatusRemark);
      togglePopoverVisibility();
      setPhoneValidityStatusRemark('');
    }
  };

  const invalidButtonPopoverContent = () => {
    return (
      <div className={styles.remarkForInvalidPhone}>
        <div className={styles.remarkMandate}>
          <span>*</span>
          Remarks
        </div>
        <div className={styles.textAreaInInvalidPhoneReason}>
          <TextArea
            onChange={handleInvalidPhoneReason}
            rows={4}
            placeholder={`Enter Remarks\n(max 70 characters)`}
            data-testid="email-invalid-reason"
            maxLength={70}
            value={phoneValidityStatusRemark}
            className={styles.textarea}
          />
        </div>
        <div className={styles.remarkSubmitButton}>
          <Button
            size="small"
            type="primary"
            shape="round"
            onClick={() => handleSubmitButtonClick({ type: 'number' })}
            disabled={isSubmitButtonDisabled}
            className={styles.buttonMargin}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.popoverContent}>
      <Popover
        mouseEnterDelay={0.2}
        placement="top"
        content={invalidButtonPopoverContent()}
        trigger="click"
        title="Report Invalid/Incorrect"
        visible={popoverVisibility}
        onVisibleChange={visible => {
          if (!visible) setPhoneValidityStatusRemark('');
        }}
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Button type="link" onClick={onClickInvalidButton}>
          <div className={styles.invalidPhone}>
            <Icon type="exclamation-circle" style={{ color: 'grey' }} />
            <span className={styles.reportInvalidtext}>Report Invalid/Incorrect</span>
          </div>
        </Button>
      </Popover>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  handlePhoneValidityStatusUpdate: connectActions.updatePhoneMismatchStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportInvalidIncorrectButton);
export { ReportInvalidIncorrectButton as ReportInvalidIncorrectButtonWithoutStore };
