import _ from 'lodash';
import { getUsersById } from '../Reducers/UserReducer';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import * as jobActivityRepository from '../Repository/JobActivityRepository';
import { fetchJobActivityStatus, setJobActivity } from './ActionCreators/JobActivityActionCreator';
import { fetchUsers } from './UserActions';

const INPROGRESS = 'INPROGRESS';
const COMPLETED = 'COMPLETED';
const FAILED = 'FAILED';

function fetchJobActivity({ from, size, jobId, isFromInfiniteScroll }) {
  return async (dispatch, getState) => {
    try {
      const eventTypes = [
        'CandidateSearchCriteria.Created',
        'CandidateSearchCriteria.Updated',
        'CandidateSearchCriteria.Activated',
        'AryaSuggestedCandidateSearchCriteria.Created',
        'AryaSuggestedCandidateSearchCriteria.Updated',
        'CandidateSearchCriteria.AdvanceSearchV2Activated',
      ];
      dispatch(fetchJobActivityStatus(INPROGRESS));
      const response = await jobActivityRepository.fetchJobActivity({ from, size, jobId, eventTypes });
      const store = getState();
      const featureToggleList = getFeatureToggleList(store);
      const jobActivity = _.cloneDeep(response.data.Activity);
      const newActivityIfAdvanceSearchV2IsEnable = jobActivity.map(activity => {
        const updatedActivity = activity;
        if (
          featureToggleList?.AdvanceSearchV2?.IsEnabled &&
          updatedActivity.EventName === 'CandidateSearchCriteria.Activated'
        )
          updatedActivity.EventName = 'CandidateSearchCriteria.AdvanceSearchV2Activated';
        return updatedActivity;
      });
      dispatch(
        setJobActivity({
          jobId,
          activity: newActivityIfAdvanceSearchV2IsEnable,
          totalCount: response.data.TotalCount,
          isFromInfiniteScroll,
        })
      );
      const userIds = response.data.Activity?.map(criteria => criteria.CreatedBy).filter(x => x);
      if (userIds?.length) {
        const existingUsersInStore = getUsersById(getState());
        const unAvailableUserIdsInStore = userIds.filter(id => !existingUsersInStore[id]);
        const uniqueUserIds = [...new Set(unAvailableUserIdsInStore)];
        if (uniqueUserIds.length)
          await dispatch(
            fetchUsers({
              userGuids: uniqueUserIds,
            })
          );
      }
      dispatch(fetchJobActivityStatus(COMPLETED));
    } catch {
      dispatch(fetchJobActivityStatus(FAILED));
    }
  };
}

export { fetchJobActivity };
