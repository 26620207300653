import React from 'react';
import ReportViewContainer from '../../../../Containers/ReportPortalContainer/ReportViewContainer/ReportViewContainer';
import {
  fetchReportPayload,
  getExportReportPayload,
  savedQueryMapper,
} from '../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import TextWithInfoIcon from '../../../Common/TextWithInfoIcon/TextWithInfoIcon';
import styles from './RecruiterByJobReports.module.scss';

const componentKey = 'recruitersbyjob';

export default function RecruiterByJobReports(props) {
  const {
    reportStats,
    getReportStats: fetchReportStats,
    fetchReportStatsApiStatus,
    exportReportApiStatus,
    savedQueryConfig,
    onSaveQuerySuccess,
  } = props;

  const getReportStats = (filter, page, pageSize) => {
    const fetchPayloadCriteria = { filter, page, pageSize, reportKey: componentKey };
    const recruiterReportFetchPayload = fetchReportPayload(fetchPayloadCriteria);

    fetchReportStats(recruiterReportFetchPayload, componentKey);
  };

  React.useEffect(() => {
    if (savedQueryConfig?.runSavedQueryFlag) {
      const savedQuery = JSON.parse(savedQueryConfig?.queryDetails?.ReportQuery);
      const mappedSavedQuery = savedQueryMapper(savedQuery, savedQueryConfig?.queryDetails?.dynamicReportColumns);
      getReportStats(mappedSavedQuery, 1, 10);
    }
  }, [savedQueryConfig?.runSavedQueryFlag]);

  return (
    <div className={styles.recruiterByJobReports}>
      <ReportViewContainer
        fetchReportStatsApiStatus={fetchReportStatsApiStatus}
        title="Recruiter By Job Report"
        subtitle={
          <TextWithInfoIcon
            text="Report based on Recruiter Activity during the date(s) given"
            tooltipTitle="Latest results may be delayed 1-2 hours"
          />
        }
        type={componentKey}
        filterConfig={{ job: false, client: false }}
        reportStats={reportStats}
        getReportStats={getReportStats}
        exportReportApiStatus={exportReportApiStatus}
        savedReportDetails={savedQueryConfig?.queryDetails}
        onSaveQuerySuccess={onSaveQuerySuccess}
        hidePagination
      />
    </div>
  );
}
