import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as JobActions from '../../Actions/JobActions';
import { getJobsById } from '../../Reducers/JobReducer';
import DistributeJobComponent from '../../Components/DistributeJob/DistributeJobComponent';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';

const allPortals = ['indeed', 'appcast'];

const mapStateToProps = state => ({
  jobsById: getJobsById(state),
});

const mapDispatchToProps = {
  distributeJob: JobActions.distributeJob,
  clearDistributeJobApiStatus: JobActions.clearDistributeJobApiStatus,
  fetchJobDistributionStatus: JobActions.fetchJobDistributionStatus,
  SetNotification:setNotification,
};

class DistributeJobContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPortals: [],
    };
    this.onClose = this.onClose.bind(this);
    this.handleDistribute = this.handleDistribute.bind(this);
    this.handlePortalSelect = this.handlePortalSelect.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { jobsById, jobId, fetchJobDistributionStatus } = this.props;
    const prevJobDetails = _.get(prevProps, ['jobsById', jobId]);
    const nextJobDetails = _.get(jobsById, [jobId]);
    const prevDistributeJobApiStatus = _.get(prevJobDetails, ['distributeJobApiStatus', 'status'], null);
    const nextDistributeJobApiStatus = _.get(nextJobDetails, ['distributeJobApiStatus', 'status'], null);
    if (prevDistributeJobApiStatus !== 'COMPLETED' && nextDistributeJobApiStatus === 'COMPLETED') {
      fetchJobDistributionStatus(jobId);
      this.onClose();
    }
  }

  onClose() {
    const { handleClose, clearDistributeJobApiStatus, jobId, jobsById } = this.props;
    const jobDetails = _.get(jobsById, [jobId]);
    const distributeJobApiStatus = _.get(jobDetails, ['distributeJobApiStatus'], null);
    this.setState({
      selectedPortals: [],
    });
    handleClose();
    if (_.get(distributeJobApiStatus, ['status']) === 'FAILED') {
      clearDistributeJobApiStatus(jobId);
    }
  }

  handleDistribute(payload) {
    const { distributeJob, jobId } = this.props;
    distributeJob(jobId, payload);
  }

  getUsedPortalsForJobDistribution = () => {
    const { jobId, jobsById } = this.props;
    const jobDetails = _.get(jobsById, [jobId]);
    const jobDistributionStatuses = _.get(jobDetails, ['JobDistributionStatuses'], []);
    return jobDistributionStatuses.map(distributionStatus => {
      return _.get(distributionStatus, 'Portal', '').toLowerCase();
    });
  };

  handlePortalSelect = portal => {
    const { selectedPortals } = this.state;
    let currentSelectedPortals = _.cloneDeep(selectedPortals);
    if (!selectedPortals.includes(portal)) {
      currentSelectedPortals.push(portal);
    } else {
      currentSelectedPortals = currentSelectedPortals.filter(
        selectedPortal => selectedPortal.toLowerCase() !== portal.toLowerCase()
      );
    }
    this.setState({
      selectedPortals: currentSelectedPortals,
    });
  };

  getDistributeDisableFlag = () => {
    const { selectedPortals } = this.state;
    return !selectedPortals.length;
  };

  getJobDistributionStatusesByPortal = () => {
    const { jobId, jobsById } = this.props;
    const jobDetails = _.get(jobsById, [jobId]);
    const jobDistributionStatuses = _.get(jobDetails, ['JobDistributionStatuses'], []);
    const jobDistributionStatusesByPortal = {};
    jobDistributionStatuses.forEach(distributionStatus => {
      const portal = _.get(distributionStatus, 'Portal', '').toLowerCase();
      jobDistributionStatusesByPortal[portal] = distributionStatus;
    });
    return jobDistributionStatusesByPortal;
  };

  render() {
    const { distributeJobModalVisibility, jobId, jobsById,SetNotification } = this.props;
    const { selectedPortals } = this.state;
    const jobDetails = _.get(jobsById, [jobId]);
    const distributeJobApiStatus = _.get(jobDetails, ['distributeJobApiStatus'], null);
    const jobDistributionStatusesByPortal = this.getJobDistributionStatusesByPortal();
    const usedPortalsForJobDistribution = this.getUsedPortalsForJobDistribution();
    const distributeDisableFlag = this.getDistributeDisableFlag();
    return (
      <DistributeJobComponent
        isVisible={distributeJobModalVisibility}
        distributeDisableFlag={distributeDisableFlag}
        usedPortalsForJobDistribution={usedPortalsForJobDistribution}
        jobDistributionStatusesByPortal={jobDistributionStatusesByPortal}
        handleClose={this.onClose}
        selectedPortals={selectedPortals}
        allPortals={allPortals}
        handleDistribute={this.handleDistribute}
        handlePortalSelect={this.handlePortalSelect}
        distributeJobApiStatus={distributeJobApiStatus}
        SetNotification={SetNotification}
      />
    );
  }
}

export { DistributeJobContainer as DistributeJobContainerWithoutStore };

export default connect(mapStateToProps, mapDispatchToProps)(DistributeJobContainer);
