import { defineMessages } from 'react-intl';

const messages = defineMessages({
  emailTemplates: {
    id: 'Templates.Sidebar.emailTemplates',
    defaultMessage: 'Email Templates',
  },
  textTemplates: {
    id: 'Templates.Sidebar.textTemplates',
    defaultMessage: 'Text Templates',
  },
  drips: {
    id: 'Templates.Sidebar.drips',
    defaultMessage: 'Drips',
  },
  workflowsDrip: {
    id: 'Workflows.Sidebar.drip',
    defaultMessage: 'Drip',
  },
  aryabotTemplates: {
    id: 'Templates.Sidebar.ChatbotTemplates',
    defaultMessage: 'AryaBot Templates',
    values: {
      AppName: 'Arya',
    },
  },
});

export default messages;
