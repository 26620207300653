import React from 'react';
import {  FormattedMessage } from 'react-intl';
import styles from './EmailSettings.module.scss';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

export default function DefaultSmtpComponent(props) {
  const { currentUser, orgDefaultEmail } = props;

  return (
    <div className={styles.defaultEmailSmtpComponent}>
      <div>
        <span className={styles.label}><FormattedMessage {...messages.defaultLabel} /> &ldquo;<FormattedMessage {...messages.Fromlabel} />&rdquo; <FormattedMessage {...messages.name} />:</span> {currentUser.given_name}{' '}
        {currentUser.family_name}{' '}
      </div>
      <div>
        <span className={styles.label}><FormattedMessage {...messages.defaultLabel} /> &ldquo;<FormattedMessage {...messages.Fromlabel} />&rdquo;  <FormattedMessage {...messages.emailLabel} />:</span> {orgDefaultEmail.EmailId}
      </div>
    </div>
  );
}
