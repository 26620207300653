import React from 'react';
import { Checkbox } from 'antd';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import InfoIconWithTooltip from '../../../Components/Common/InfoIconWithTooltip/InfoIconWithTooltip';
import FilterSectionWithCheckbox from './FilterSectionWithCheckbox';
import LinkButton from '../../../Components/Common/LinkButton/LinkButton';

const SourcesFilter = ({
  sortedSources,
  candidatesSourceField,
  selectedSources,
  filterSectionCommonStyles,
  onClickClear,
  form,
}) => {
  return (
    <div className={styles.filterSection}>
      <div className={styles.filterSectionHeader}>
        <span className={styles.subsectionTitle}>
          Sources{' '}
          <InfoIconWithTooltip
            tooltipTitle="By default, a maximum of 200 candidates from each portal is aggregated"
            style={{ zIndex: 3000, maxWidth: '300px' }}
          />
        </span>
        <LinkButton
          buttonName="Clear all"
          onClickButton={() => {
            onClickClear(candidatesSourceField);
          }}
          style={{ color: 'rgba(7, 16, 26, 0.5)' }}
        />
      </div>
      {form.getFieldDecorator(candidatesSourceField, {
        initialValue: selectedSources,
      })(
        <Checkbox.Group style={{ width: '100%' }}>
          <FilterSectionWithCheckbox
            filterSectionKeys={Object.keys(sortedSources || {})}
            sortedAllTabFilterSection={sortedSources || {}}
            style={filterSectionCommonStyles}
            maxTextLength={35}
          />
        </Checkbox.Group>
      )}
    </div>
  );
};

export default SourcesFilter;
