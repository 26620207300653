import { apiV3Client } from './BaseRepository';

export function deleteNote({ noteId, vaultName }) {
  let url = `notes/${noteId}`;
  if (vaultName) {
    url += `?vaultName=${vaultName}`;
  }
  return apiV3Client.delete(url);
}

export function updateNote({ noteId, payload, vaultName }) {
  let url = `notes/${noteId}`;
  if (vaultName) {
    url += `?vaultName=${vaultName}`;
  }
  return apiV3Client.put(url, {
    Context: payload.Context,
    Description: payload.Description,
    SubjectLine: payload.SubjectLine,
  });
}

export const fetchBulkNotes = ({ jobId, filter }) => {
  return apiV3Client.post(`jobs/${jobId}/notes/_bulksearch`, {
    From: filter.from,
    Size: filter.size,
    CandidateIds: filter.candidateIds,
  });
};

export const getNotes = ({ jobId, candidateId }) => {
  let url = '';
  if (jobId && candidateId) url = `notes?jobId=${jobId}&candidateId=${candidateId}`;
  else if (jobId) url = `notes?jobId=${jobId}`;
  else url = `notes?candidateId=${candidateId}`;
  return apiV3Client.get(url);
};

export const createNote = ({ jobId, candidateId, vaultName, payload }) => {
  const filter = {
    Context: payload.Context,
    Description: payload.Description,
    SubjectLine: payload.SubjectLine,
  };
  let url = '/notes?';
  if (jobId && candidateId) {
    url += `jobId=${jobId}&candidateId=${candidateId}`;
    if (vaultName) {
      url += `&vaultName=${vaultName}`;
    }
  } else {
    url += jobId ? `jobId=${jobId}` : `candidateId=${candidateId}`;
  }
  return apiV3Client.post(url, filter);
};
