import React from 'react';
import StarIcon from '../Components/StarIconComponent/StartIcons';
import { handleFavouriteCandidateIconClick } from './CandidateListUtils';

const FavouriteCandidateStarIcon = props => {
  const {
    candidate,
    recommendFavouriteCandidate,
    userConfig,
    activeTab,
    jobId,
    favouriteCandidateApiStatus,
    removeFavouriteCandidate,
    removeFavouriteCandidateApiAtatus,
    defaultFilter,
    fetchCandidates,
    fetchJobSourcingStats,
  } = props;
  return (
    <div
      onClick={e => {
        e.stopPropagation();
        handleFavouriteCandidateIconClick({
          candidate,
          recommendFavouriteCandidate,
          removeFavouriteCandidate,
          fetchCandidates,
          fetchJobSourcingStats,
          defaultFilter,
          userConfig,
          jobId,
        });
      }}
    >
      <StarIcon
        activeTab={activeTab}
        candidate={candidate}
        favouriteCandidateApiStatus={favouriteCandidateApiStatus}
        removeFavouriteCandidateApiAtatus={removeFavouriteCandidateApiAtatus}
      />
    </div>
  );
};

export default FavouriteCandidateStarIcon;
