import React from 'react';
import { Divider, Form, Skeleton } from 'antd';
import SummaryDetails from './SummaryDetails/SummaryDetails';
import SummaryHeader from './SummaryHeader/SummaryHeader';
import { fetchReportStatsPayload, getRecruiterId } from '../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import styles from './ReportSummaryTabComponent.module.scss';
import EmptyReportList from '../ReportStatsTab/ReportView/ReportDetails/ReportListWrapper/EmptyReportList/EmptyReportList';

export function ReportSummaryTabComponent(props) {
  const {
    form,
    reportSummary,
    getReportSummary,
    currentUserId,
    fetchReportSummaryApiStatus,
    recruiters,
    currentSelectedOrg,
  } = props;

  const getFormDetails = () => {
    const values = form.getFieldsValue();
    return {
      dateRange: values?.dateRange,
      reportUser: values?.recruiterId,
      dateRangeKey: values?.dateRangeKey,
    };
  };

  React.useEffect(() => {
    if (getReportSummary && Number.isInteger(currentUserId)) {
      const filter = form.getFieldsValue();
      const payloadFilter = getRecruiterId(filter, currentUserId);
      const payload = fetchReportStatsPayload(payloadFilter);
      getReportSummary(payload);
    }
  }, [currentUserId, getReportSummary]);

  return (
    <div className={styles.summaryTabComponent}>
      <SummaryHeader
        form={form}
        getReportSummary={getReportSummary}
        currentUserId={currentUserId}
        fetchReportSummaryApiStatus={fetchReportSummaryApiStatus}
      />
      <Divider className={styles.divider} />
      <Skeleton
        active
        paragraph={{ rows: 16 }}
        loading={!['COMPLETED', 'FAILED'].includes(fetchReportSummaryApiStatus?.status?.toUpperCase())}
      >
        {fetchReportSummaryApiStatus?.status?.toUpperCase() === 'FAILED' ? (
          <EmptyReportList title="Oops, something just went wrong!" subtitle="Please try again." />
        ) : (
          <SummaryDetails
            reportSummary={reportSummary}
            formDetails={getFormDetails()}
            currentUserId={currentUserId}
            recruiters={recruiters}
            currentSelectedOrg={currentSelectedOrg}
          />
        )}
      </Skeleton>
    </div>
  );
}

export default Form.create()(ReportSummaryTabComponent);
