import React, { useEffect } from 'react';
import { Button, Skeleton } from 'antd';
import ThreadItem from '../SourcingSupportCard/ThreadItem';

function SingleThreadWindow(props) {
  const {
    chatId,
    messageId,
    topicId,
    addThreadReplyToTheChat,
    fetchMoreRepliesForTheThread,
    isChatDisabled,
    currentMember,
    thread,
    fetchSingleThread,
    fetchSingleThreadApiStatus,
    history,
    jobId,
  } = props;
  useEffect(() => {
    fetchSingleThread({ chatId, messageId });
  }, [messageId, chatId]);
  const onBackToAllThreads = () => {
    const searchParams = new URLSearchParams(window.location.href);

    if (searchParams.has('chatId')) {
      searchParams.delete('chatId');
    }
    if (searchParams.has('messageId')) {
      searchParams.delete('messageId');
    }
    history.push(`/jobs/${jobId}/sourcingsupport`);
  };
  return (
    <Skeleton loading={fetchSingleThreadApiStatus === 'IN_PROGRESS'} avatar paragraph={{ rows: 4 }}>
      <Button onClick={onBackToAllThreads}>Back To All Threads</Button>
      {thread ? (
        <ThreadItem
          key={thread.Id}
          thread={thread}
          currentMember={currentMember}
          chatId={chatId}
          topicId={topicId}
          addThreadReplyToTheChat={addThreadReplyToTheChat}
          fetchMoreRepliesForTheThread={fetchMoreRepliesForTheThread}
          isChatDisabled={isChatDisabled}
          isSingleThreadWindow
        />
      ) : null}
    </Skeleton>
  );
}

export default SingleThreadWindow;
