import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Form, Input, Modal, Popover, Skeleton, Tooltip } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import styles from './EmailNodeContent.module.scss';
import ConnectTemplateSelect from '../../JobSettings/ConnectTemplateSelect';
import { isPulseUser } from '../../../Utils/ConfigUtils';
import EmptyTemplate from '../../ReactEmailEditor/EmptyTemplate.json';
import * as EmailTemplatesReducer from '../../../Reducers/EmailTemplates';
import * as EmailTemplatesActions from '../../../Actions/EmailTemplates';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import EmailSubjectAndBodyWrapper from '../../Connect/BaseTemplateForm/CreateEmailTemplate/EmailSubjectAndBodyWrapper';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getPreHeaderValidationStatus } from '../../../Utils/EmailUtils/EmailUtils';
import { validateTemplate } from '../../../Utils/Validators';
import * as ConnectActions from '../../../Actions/ConnectActions';
import { MergeTagIcon } from '../../../Icons/AryaIcons';
import message from '../../../Containers/Connect/ConnectMessages';

const FormItem = Form.Item;
const emptyRegex = /\n$/gi;
const emptyHtmlRegex = /(<p><br><\/p>)+$/gi; // to remove HTML tag

const mapStateToProps = state => ({
  emailTemplateIds: EmailTemplatesReducer.getEmailTemplateIds(state),
  emailTemplatesById: EmailTemplatesReducer.getEmailTemplatesById(state),
  emailTemplatesCount: EmailTemplatesReducer.getEmailTemplatesCount(state),
  featureToggleList: getFeatureToggleList(state),
  getEmailTemplateStatus: getApiStatus(state, 'getEmailTemplateStatus'),
});

const mapDispatchToProps = {
  searchEmailTemplates: EmailTemplatesActions.searchEmailTemplates,
  postEmailTemplate: EmailTemplatesActions.postEmailTemplate,
  getEmailTemplate: EmailTemplatesActions.getEmailTemplate,
  setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
};

function EmailNodeContent(props) {
  const {
    form,
    initialValue,
    emailTemplateIds,
    emailTemplatesById,
    searchEmailTemplates,
    emailTemplatesCount,
    drawerFooter,
    onCancel,
    onSave,
    postEmailTemplate,
    mergeTags,
    isDisabled,
    featureToggleList,
    getEmailTemplate,
    getEmailTemplateStatus,
    setInvalidTemplateNotification,
    intl,
  } = props;
  const initialEmailDetailsRef = React.useRef();
  const [templateEditFlag, setTemplateEditFlag] = React.useState(false);
  const [preHeaderVisibility, setPreHeaderVisisbility] = React.useState(false);
  const [loadingFlag, setLoadingFlag] = React.useState(false);
  const [modalVisibility, setModalVisibility] = React.useState(false);
  const [emailTemplateDetails, setEmailTemplateDetails] = React.useState({
    isAdvancedTemplate: false,
    htmlToolActiveStatus: false,
    plainText: '',
    body: null,
    design: null,
    subject: null,
    preHeader: null,
  });
  const [emailTemplateLoadingFlag, setEmailTemplateLoadingFlag] = React.useState(false);
  const subjectRef = React.useRef();
  const editorRef = React.useRef();
  const preHeaderRef = React.useRef();
  const emailSubjectMergeTags = mergeTags?.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject'));
  const emailPreHeaderMergeTags = mergeTags?.filter(mergeTag => mergeTag.Scopes.includes('EmailPreHeader'));

  const { EmailTemplateId: selectedEmailTemplateId } = form.getFieldsValue();
  form.getFieldDecorator('EmailTemplateName', { initialValue: _.get(initialValue, 'EmailTemplateName') });

  const updateAdvancedHtmlContent = ({ html, design }) => {
    editorRef.current.editor.exportPlainText(data => {
      const { text } = data;
      setEmailTemplateDetails({ ...emailTemplateDetails, body: html, design, plainText: text });
      const { bodyInitialValue } = initialEmailDetailsRef.current;
      if (!bodyInitialValue) {
        initialEmailDetailsRef.current = { ...initialEmailDetailsRef.current, bodyInitialValue: html };
      } else {
        setTemplateEditFlag(true);
      }
    });
  };

  const onEmailTemplateChange = templateId => {
    form.setFieldsValue({
      EmailTemplateId: templateId,
      EmailTemplateName: _.get(emailTemplatesById, [templateId, 'Name'], null),
    });
    const currentTemplate = emailTemplatesById?.[templateId] || {};
    const {
      Body: currentBody,
      Subject: currentSubject,
      Design: currentDesign,
      EditorUsed: currentEditorUsed,
      PreHeader: currentPreHeader,
    } = currentTemplate;
    if (currentPreHeader) {
      setPreHeaderVisisbility(true);
    } else {
      setPreHeaderVisisbility(false);
    }
    const isCurrentAdvancedTemplate = currentEditorUsed?.toLowerCase() === 'reactemaileditor';
    setEmailTemplateDetails({
      ...emailTemplateDetails,
      subject: currentSubject,
      body: currentBody,
      preHeader: currentPreHeader,
      design: currentDesign ? JSON.parse(currentDesign) : null,
      isAdvancedTemplate: isCurrentAdvancedTemplate,
    });
    setTemplateEditFlag(false);
    if (isCurrentAdvancedTemplate) {
      initialEmailDetailsRef.current = { bodyInitialValue: currentBody };
    } else {
      initialEmailDetailsRef.current = {};
    }
  };

  const selectedEmailTemplateName = _.get(emailTemplatesById, [selectedEmailTemplateId, 'Name'], null);

  const setDefaultEmailTemplateValues = async () => {
    const { EmailTemplateId: emailTemplateId } = initialValue || {};
    let currentTemplateDetails = emailTemplatesById?.[emailTemplateId];
    if (emailTemplateId && !currentTemplateDetails) {
      setEmailTemplateLoadingFlag(true);
      currentTemplateDetails = await getEmailTemplate(emailTemplateId);
    }
    const {
      Subject: currentSubject,
      Body: currentBody,
      Design: currentDesign,
      EditorUsed: currentEditorUsed,
      PreHeader: currentPreHeader,
    } = currentTemplateDetails || {};
    initialEmailDetailsRef.current = {
      subjectInitialValue: currentSubject,
      bodyInitialValue: currentBody,
      preHeaderInitialValue: currentPreHeader,
    };
    form.setFieldsValue({ EmailTemplateId: emailTemplateId });
    if (currentPreHeader) setPreHeaderVisisbility(true);
    setEmailTemplateDetails({
      ...emailTemplateDetails,
      subject: currentSubject,
      preHeader: currentPreHeader,
      body: currentBody,
      design: currentDesign ? JSON.parse(currentDesign) : null,
      isAdvancedTemplate: currentEditorUsed?.toLowerCase() === 'reactemaileditor',
    });
    setEmailTemplateLoadingFlag(false);
  };

  React.useEffect(() => {
    setDefaultEmailTemplateValues();
  }, []);

  const toogleAdvancedEditor = () => {
    setEmailTemplateDetails({
      ...emailTemplateDetails,
      isAdvancedTemplate: !emailTemplateDetails.isAdvancedTemplate,
      body: null,
      design: null,
    });
  };

  const onClearAdvancedHtmlContent = () => {
    editorRef.current.loadDesign(EmptyTemplate.design);
    setEmailTemplateDetails({ ...emailTemplateDetails, body: null, design: EmptyTemplate.design });
  };

  const updateCKeditorBodyContent = content => {
    setEmailTemplateDetails({ ...emailTemplateDetails, body: content });
  };

  const addTags = tag => {
    subjectRef.current.addTags(tag);
  };

  const addPreHeaderTags = tag => {
    preHeaderRef.current.addTags(tag);
  };

  const emailSubjectPopoverContent = (
    <div className="tags-popover-content">
      {emailSubjectMergeTags?.map(mergeTag => (
        <div
          className="merge-tag"
          onClick={() => {
            addTags(mergeTag);
          }}
          role="presentation"
        >
          + {mergeTag.DisplayName}
        </div>
      ))}
    </div>
  );

  const emailPreHeaderPopoverContent = (
    <div className="tags-popover-content">
      {emailPreHeaderMergeTags?.map(mergeTag => (
        <div
          className="merge-tag"
          onClick={() => {
            addPreHeaderTags(mergeTag);
          }}
          role="presentation"
        >
          + {mergeTag.DisplayName}
        </div>
      ))}
    </div>
  );

  const addEmailSubjectMergeTags = (
    <Popover content={emailSubjectPopoverContent} placement="left" trigger="click">
      <Tooltip zIndex={2223} title="Merge Tags">
        <span className="create-template-merge-tag-button">
          <MergeTagIcon />
        </span>
      </Tooltip>
    </Popover>
  );

  const addEmailPreHeaderMergeTags = (
    <Popover content={emailPreHeaderPopoverContent} placement="left" trigger="click">
      <Tooltip zIndex={2223} title="Merge Tags">
        <span className="create-template-merge-tag-button">
          <MergeTagIcon />
        </span>
      </Tooltip>
    </Popover>
  );

  const updateSubjectContent = ({ htmlContent }) => {
    setEmailTemplateDetails({ ...emailTemplateDetails, subject: htmlContent });
    const { subjectInitialValue, bodyInitialValue } = initialEmailDetailsRef.current;
    if (!subjectInitialValue) {
      initialEmailDetailsRef.current = { subjectInitialValue: htmlContent, bodyInitialValue };
    } else {
      setTemplateEditFlag(true);
    }
  };

  const updateBodyContent = ({ htmlContent }) => {
    setEmailTemplateDetails({ ...emailTemplateDetails, body: htmlContent });
    const { subjectInitialValue, bodyInitialValue } = initialEmailDetailsRef.current;
    if (!bodyInitialValue) {
      initialEmailDetailsRef.current = { subjectInitialValue, bodyInitialValue: htmlContent };
    } else {
      setTemplateEditFlag(true);
    }
  };

  const updatePreHeaderContent = ({ htmlContent }) => {
    setEmailTemplateDetails({ ...emailTemplateDetails, preHeader: htmlContent });
    const { subjectInitialValue, bodyInitialValue, preHeaderInitialValue } = initialEmailDetailsRef.current;
    if (!preHeaderInitialValue) {
      initialEmailDetailsRef.current = { subjectInitialValue, bodyInitialValue, preHeaderInitialValue: htmlContent };
    } else {
      setTemplateEditFlag(true);
    }
  };

  const setHtmlToolActiveStatus = status => {
    setEmailTemplateDetails({ ...emailTemplateDetails, htmlToolActiveStatus: status });
  };

  const getEditorUsed = () => {
    const { isAdvancedTemplate } = emailTemplateDetails;
    return isAdvancedTemplate ? 'ReactEmailEditor' : 'ReactQuill';
  };

  const onEmailNameChange = e => {
    form.setFieldsValue({ EmailTemplateName: e.target.value });
  };

  const onSaveAndAddTemplate = async () => {
    const { EmailTemplateName } = form.getFieldsValue();
    const filter = {
      From: 0,
      Size: 100,
      SearchTerm: '',
    };
    const { body: payloadBody, design: payloadDesign, preHeader: payloadPreHeader } = emailTemplateDetails;
    const subject = subjectRef.current.quillRef.getText().replace(emptyRegex, '');
    const updatedTemplateDetails = {
      Name: EmailTemplateName,
      Subject: subject,
      Body: payloadBody,
      PreHeader: payloadPreHeader,
      EditorUsed: getEditorUsed(),
      Design: payloadDesign,
      IsPublic: false,
    };
    setLoadingFlag(true);
    const templateId = await postEmailTemplate(updatedTemplateDetails, filter);
    form.setFieldsValue({
      EmailTemplateId: templateId,
    });
    onSave();
    setLoadingFlag(false);
  };

  const EmailNameModal = () => {
    return modalVisibility ? (
      <Modal
        destroyOnClose
        visible
        zIndex={5000}
        onCancel={onCancel}
        width={456}
        title={<FormattedMessage {...message.saveTemplateAndAddToTheMainWorkflow} />}
        footer={[
          <Button key="back" onClick={onCancel} shape="round">
            <FormattedMessage {...message.cancelLabel} />
          </Button>,
          <Button key="submit" type="primary" loading={loadingFlag} onClick={onSaveAndAddTemplate} shape="round">
            Save &amp; Add
          </Button>,
        ]}
      >
        <Input width={401} placeholder="Enter a name" onChange={e => onEmailNameChange(e)} />
      </Modal>
    ) : null;
  };

  const validateTemplateAndSave = () => {
    const { body: updatedBody, subject: updatedSubject, preHeader: updatedPreHeader } = emailTemplateDetails;
    const isEmailTemplateValid = validateTemplate({
      subject: updatedSubject,
      preHeader: updatedPreHeader,
      body: updatedBody,
      subjectMergeTags: emailSubjectMergeTags,
      preHeaderMergeTags: emailPreHeaderMergeTags,
      bodyMergeTags: mergeTags?.filter(mergeTag => mergeTag.Scopes.includes('EmailBody')),
    });
    if (isEmailTemplateValid) {
      setModalVisibility(true);
    } else {
      setInvalidTemplateNotification();
    }
  };

  const getEditTemplateFooter = () => {
    const subject = subjectRef.current.quillRef.getText().replace(emptyRegex, '');
    const { body } = emailTemplateDetails;
    const { EmailTemplateId } = form.getFieldsValue();
    const isSaveEnabled =
      subject &&
      subject.trim().replace(emptyRegex, '').length !== 0 &&
      body &&
      !getPreHeaderValidationStatus(preHeaderRef) &&
      body.trim().replace(emptyHtmlRegex, '').length !== 0 &&
      EmailTemplateId;
    return (
      <div className={styles.editTemplateFooter}>
        <Button key="back" onClick={onCancel} shape="round">
          <FormattedMessage {...message.cancelLabel} />
        </Button>
        <Button key="submit" type="primary" disabled={!isSaveEnabled} onClick={validateTemplateAndSave} shape="round">
          Save As &amp; Add
        </Button>
      </div>
    );
  };

  const onClearEmailSelection = () => {
    form.setFieldsValue({
      EmailTemplateId: undefined,
    });
  };

  const addPreHeaderToEmail = () => {
    setPreHeaderVisisbility(true);
    setTemplateEditFlag(true);
  };

  const removePreHeaderFromEmail = () => {
    setPreHeaderVisisbility(false);
    setTemplateEditFlag(true);
    setEmailTemplateDetails({ ...emailTemplateDetails, preHeader: undefined });
  };

  const { body, design, subject, preHeader, isAdvancedTemplate } = emailTemplateDetails;

  return (
    <div className={styles.workflowEmailDrawerContainer} style={{ width: isAdvancedTemplate ? 1078 : 650 }}>
      <Skeleton loading={emailTemplateLoadingFlag}>
        <div className={styles.emailDropdown}>
          <FormItem>
            {form.getFieldDecorator('EmailTemplateId', {
              initialValue: _.get(initialValue, 'EmailTemplateId'),
              rules: [{ required: true, message: 'Please select a Template' }],
            })(
              <ConnectTemplateSelect
                cssClassname="job-settings-template-selector"
                templateIds={emailTemplateIds}
                templatesById={emailTemplatesById}
                templatesCount={emailTemplatesCount}
                fetchTemplates={searchEmailTemplates}
                isPusle={isPulseUser()}
                selectedTemplateId={selectedEmailTemplateId}
                selectedTemplateName={selectedEmailTemplateName}
                emailTemplates={Object.values(emailTemplatesById)}
                placeholderValue="Choose a template"
                onChange={onEmailTemplateChange}
                isDisabled={isDisabled}
                excludeTags={[]}
                templateFetchApiStatus={getEmailTemplateStatus}
                inlineSelectDropdownStyles={{ width: '600px' }}
              />
            )}
          </FormItem>
          <Button type="link" onClick={onClearEmailSelection} disabled={isDisabled}>
            <span className={styles.clearEmailText}>
              <FormattedMessage {...message.clearLabel} />
            </span>
          </Button>
        </div>
        {selectedEmailTemplateId ? (
          <div>
            <EmailSubjectAndBodyWrapper
              viewOnly={isDisabled}
              isEditTemplateDrawer
              emailSubjectMergeTags={emailSubjectMergeTags}
              emailPreHeaderMergeTags={emailPreHeaderMergeTags}
              addEmailSubjectMergeTags={addEmailSubjectMergeTags}
              addEmailPreHeaderMergeTags={addEmailPreHeaderMergeTags}
              preHeader={preHeader}
              subject={subject}
              updateSubjectContent={updateSubjectContent}
              ref={{ myRef: subjectRef, editorRef, preHeaderRef }}
              isAdvancedTemplate={isAdvancedTemplate}
              isReactEmailEditorEnabled={featureToggleList.ReactEmailEditor.IsEnabled}
              toogleAdvancedEditor={toogleAdvancedEditor}
              onClearAdvancedHtmlContent={onClearAdvancedHtmlContent}
              systemEmailTemplate={false}
              mergeTags={mergeTags}
              getEditorUsed={getEditorUsed}
              body={body}
              design={design}
              updateAdvancedHtmlContent={updateAdvancedHtmlContent}
              updateCKeditorBodyContent={updateCKeditorBodyContent}
              updateBodyContent={updateBodyContent}
              updatePreHeaderContent={updatePreHeaderContent}
              setHtmlToolActiveStatus={setHtmlToolActiveStatus}
              preHeaderVisibility={preHeaderVisibility}
              addPreHeaderToEmail={addPreHeaderToEmail}
              removePreHeaderFromEmail={removePreHeaderFromEmail}
              isWorkflow
              intl={intl}
            />
          </div>
        ) : null}
        {EmailNameModal()}
        <div className={styles.emailFooterPlacement}>{templateEditFlag ? getEditTemplateFooter() : drawerFooter}</div>
      </Skeleton>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(EmailNodeContent)));
export { EmailNodeContent as EmailTemplatesContainerWithoutStore };
