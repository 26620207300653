import { message } from 'antd';
import _ from 'lodash';
import config from '../Config/Config';
import { getCurrentUserDetails, getCurrentUserId } from '../Reducers/UserSessionReducer';
import * as aryaPayRepository from '../Repository/AryaPayRepository';
import * as connectRepository from '../Repository/ConnectRepository';
import { setNotification } from './ActionCreators/ConnectActions';
import { mapAryaPayApiErrorCode } from '../Utils/AryaPayApiErrorResponseMapper';
import {
  setCreateOrderApiErrorMessage,
  setFetchAvailableCreditsApiStatus,
} from './ActionCreators/AryaPayOrderActionCreator';
import { setCurrentUserTryNowFlag } from './UserActions';
import { getCurrentOrgGuid } from '../Reducers/UserReducer';

message.config({
  top: 100,
  duration: 5,
  maxCount: 1,
});

export function fetchAvailableCredits(productVariantId = null, type) {
  return async dispatch => {
    try {
      dispatch(setFetchAvailableCreditsApiStatus({ status: 'INPROGRESS', type }));
      const response = await aryaPayRepository.fetchAvailableCredits(productVariantId);
      const { data } = response;
      dispatch({
        type: 'SET_AVAILABLE_CREDITS',
        payload: { data, type },
      });
      dispatch(setFetchAvailableCreditsApiStatus({ status: 'COMPLETED', type }));
    } catch (error) {
      dispatch(setFetchAvailableCreditsApiStatus({ status: 'FAILED', type }));
      dispatch({
        type: 'SET_AVAILABLE_CREDITS',
        payload: 0,
      });
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
    }
  };
}

export function requestForCredits(amount, AppName, creditType, requestedCreditMessage) {
  return (dispatch, getState) => {
    const state = getState();
    const currentUserDetails = getCurrentUserDetails(state);
    const { OrgName: orgName, Email: userName, OrgId: orgId } = currentUserDetails;
    const currentUserId = getCurrentUserId(state);
    const orgGuid = getCurrentOrgGuid(state, currentUserId);
    const filter = {
      Credits: amount,
      CreditType: creditType === 'SMS' ? 'Text' : creditType,
      OrgGuid: orgGuid,
    };

    const emailContent = config.getRequestCreditsEmailContent({
      amount,
      orgName,
      userName,
      requestedCreditMessage,
      orgId,
    });
    dispatch({
      type: 'SET_REQUEST_CREDITS_API_STATUS',
      payload: { status: 'INPROGRESS', type: creditType },
    });

    aryaPayRepository
      .requestCredits(filter)
      .then(response => {
        connectRepository
          .sendTestEmail(emailContent)
          .then(() => {
            dispatch({
              type: 'SET_REQUEST_CREDITS_API_STATUS',
              payload: { status: 'COMPLETED', type: creditType },
            });
            dispatch(setNotification('SUCCESS', { messageId: 'emailRequestHasBeenSentToOurSupportTeam' }));
          })
          .catch(() => {
            dispatch({
              type: 'SET_REQUEST_CREDITS_API_STATUS',
              payload: { status: 'FAILED', type: creditType },
            });
          });
      })
      .catch(() => {
        dispatch({
          type: 'SET_REQUEST_CREDITS_API_STATUS',
          payload: { status: 'FAILED', type: creditType },
        });
      });
  };
}
export function resetSavePaymentDetailApiStatus() {
  return dispatch => dispatch({ type: 'RESET_SAVE_CARD_DETAILS_API_STATUS' });
}
export function savePaymentDetails(CardDetail, Address, showNotification = false) {
  return async dispatch => {
    dispatch({ type: 'SET_SAVE_CARD_DETAILS_API_STATUS', payload: 'PENDING' });
    try {
      await aryaPayRepository.savePaymentProfile({ CardDetail, Address });
      dispatch({ type: 'SET_SAVE_CARD_DETAILS_API_STATUS', payload: 'COMPLETED' });
      if (showNotification) {
        dispatch(setNotification('SUCCESS', { messageId: 'paymentMethodAddedSuccessfully' }));
      }
    } catch (error) {
      let statusType = 'FAILED';
      switch (_.get(error, ['response', 'status'], null)) {
        case 409:
          if (_.get(error, ['response', 'data', 'ErrorCode'], null) === 'CONFLICTING_CARDDETAIL')
            statusType = 'CONFLICTING_CARDDETAIL';
          break;
        default:
          break;
      }
      dispatch({ type: 'SET_SAVE_CARD_DETAILS_API_STATUS', payload: statusType });
      throw error;
    }
  };
}

export function orderStatusAction(payload) {
  return {
    type: 'SET_CREATE_ORDER_API_STATUS',
    payload,
  };
}
export function setCreateOrderApiStatus(status) {
  return dispatch => dispatch(orderStatusAction(status));
}

export function orderNumberAction(payload) {
  return {
    type: 'SET_ORDER_NUMBER',
    payload,
  };
}

export function placeOrderAsync(orderDetails, recordStatus = true, callback = null) {
  return async dispatch => {
    if (recordStatus) dispatch(orderStatusAction('PENDING'));
    try {
      const orderResponse = await aryaPayRepository.placeOrder(orderDetails);
      if (recordStatus) dispatch(orderStatusAction('SUCCESS'));
      dispatch(orderNumberAction(orderResponse.data));
      if (callback) callback();
      return orderResponse;
    } catch (error) {
      const errorData = mapAryaPayApiErrorCode(error);
      if (recordStatus) {
        dispatch(orderStatusAction(errorData.statusType));
        dispatch(setCreateOrderApiErrorMessage(errorData.errorMessage));
      }
      throw error;
    }
  };
}

export function resetAryaPayApiStatuses() {
  return dispatch => dispatch({ type: 'RESET_ARYAPAY_API_STATUS' });
}

export function setCreateOrderApiStatusPending() {
  return dispatch => dispatch({ type: 'SET_CREATE_ORDER_API_STATUS', payload: 'PENDING' });
}
export function getOrderDetailsPayload(items, paymentData, orderType) {
  let orderDetails = {};
  if (paymentData.paymentProfileId) {
    orderDetails = {
      Items: items,
      IsTaxCalcuationRequired: true,
      Payment: {
        ProfileId: paymentData.paymentProfileId,
        CardDetail: paymentData.cardDetails,
        BillingAddress: paymentData.address,
      },
      Type: orderType,
    };
  } else {
    orderDetails = {
      Items: items,
      IsTaxCalcuationRequired: false,
      Payment: {
        ProfileId: paymentData.paymentProfileId,
        CardDetail: paymentData.cardDetails,
        BillingAddress: paymentData.address,
      },
      Type: orderType,
    };
  }
  return orderDetails;
}
export function createOrder(items, paymentData, orderType, callback = null) {
  const orderDetails = getOrderDetailsPayload(items, paymentData, orderType);
  return async dispatch => {
    dispatch(resetAryaPayApiStatuses());
    if (paymentData.saveCardDetails && paymentData.cardDetails) {
      dispatch(savePaymentDetails(paymentData.cardDetails, paymentData.address));
    }
    try {
      const orderResponse = await dispatch(placeOrderAsync(orderDetails, true, callback));
      dispatch(setCurrentUserTryNowFlag(false));
      return orderResponse;
    } catch (err) {
      throw err;
    }
  };
}

export function placeOrder(orderDetails) {
  return async dispatch => {
    dispatch({ type: 'SET_PLACE_ORDER_API_STATUS', payload: 'PENDING' });
    try {
      const response = await aryaPayRepository.placeOrder(orderDetails);
      dispatch({ type: 'SET_PLACE_ORDER_API_STATUS', payload: 'COMPLETED' });
      return response;
    } catch (error) {
      dispatch({ type: 'SET_PLACE_ORDER_API_STATUS', payload: 'FAILED' });
      return error;
    }
  };
}

export function fetchPaymentProfile() {
  return dispatch => {
    dispatch({
      type: 'SET_PAYMENT_PROFILES_API_STATUS',
      payload: 'INPROGRESS',
    });
    aryaPayRepository
      .fetchPaymentProfile()
      .then(response => {
        dispatch({
          type: 'SET_PAYMENT_PROFILES',
          payload: response.data,
        });
        dispatch({
          type: 'SET_PAYMENT_PROFILES_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_PAYMENT_PROFILES_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

export function updateBillingInfo(paymentProfileId, Address, showNotification = false) {
  return async dispatch => {
    dispatch({
      type: 'SET_UPDATE_BILLING_INFO_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      await aryaPayRepository.updateBillingInfo(paymentProfileId, Address);
      if (showNotification) {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'savedSuccessfully',
          })
        );
      }
      dispatch({
        type: 'SET_UPDATE_BILLING_INFO_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'SET_UPDATE_BILLING_INFO_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
}

export function deletePaymentProfile(paymentProfileId, showNotification = false) {
  return dispatch => {
    dispatch({ type: 'SET_DELETE_PAYMENT_CARD_API_STATUS', payload: { status: 'INPROGRESS' } });
    aryaPayRepository
      .deletePaymentProfile(paymentProfileId)
      .then(() => {
        dispatch({ type: 'DELETE_PAYMENT_PROFILE', payload: { paymentProfileId } });
        dispatch({ type: 'SET_DELETE_PAYMENT_CARD_API_STATUS', payload: { status: 'COMPLETED' } });
        if (showNotification) {
          dispatch(
            setNotification('SUCCESS', {
              messageId: 'paymentMethodRemovedSuccessfully',
            })
          );
        }
      })
      .catch(err => {
        const status = { status: 'FAILED', error: _.get(err, 'response') };
        dispatch({ type: 'SET_DELETE_PAYMENT_CARD_API_STATUS', payload: status });
      });
  };
}

export function clearDeletePaymentCardApiStatus() {
  return dispatch => {
    dispatch({ type: 'SET_DELETE_PAYMENT_CARD_API_STATUS', payload: {} });
  };
}
