import { v4 as uuidv4 } from 'uuid';
import * as sourcingSupportRepository from '../Repository/SourcingSupportRepository';
import { setApiStatus } from './ApiStatusActions';
import {
  setChatIdForTheJob,
  setChatsForTheJob,
  setTopicIdForTheJob,
  setAllRepliesForTheThread,
  setLastFetchedThreadCountForChatId,
  setHasMoreRepliesForTheThread,
  setUnreadCountForChat,
} from './ActionCreators/SourcingSupportChatActionCreator';
import { setNotification } from './ActionCreators/ConnectActions';
import { getChatConnectionV2 } from '../Events/SocketConnectionManager';

export function createTopic({ jobGuid, jobTitle, jobId }) {
  return async dispatch => {
    const response = await sourcingSupportRepository.createTopic({ jobGuid, jobTitle, jobId });
    const { Id: topicId } = response.data;
    dispatch(setTopicIdForTheJob({ topicId, jobId }));
    return topicId;
  };
}

export function getAllChats({ topicId }) {
  return async dispatch => {
    const response = await sourcingSupportRepository.getAllChats({ topicId });
    const { ItemCount, Items } = response.data;
    return { ItemCount, Items };
  };
}

export function addMembersToTheChat({ chatId, users }) {
  return async _ => {
    await sourcingSupportRepository.addMembersToTheChat({ chatId, users });
  };
}

export function createChat({ jobGuid, jobTitle, jobId, usersInTheChat }) {
  return async _ => {
    const response = await sourcingSupportRepository.createSourcingSupportChat({
      jobGuid,
      jobTitle,
      jobId,
      usersInTheChat,
    });
    return response.data;
  };
}

export function markChatAsRead({ chatId }) {
  return async dispatch => {
    try {
      dispatch(setUnreadCountForChat({ chatId, unreadMessageCount: 0 }));
      await sourcingSupportRepository.markChatAsRead({ chatId });
    } catch (error) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'failedToMarkChatMessagesAsRead',
        })
      );
    }
  };
}

export function fetchAllThreads({ chatId, before = null }) {
  return async dispatch => {
    try {
      if (!before) dispatch(setApiStatus({ apiName: 'initialThreadsFetchApiStatus', status: 'IN_PROGRESS' }));
      const response = await sourcingSupportRepository.getAllMainThreadsForTheJob(chatId, true, before);
      const { Items: messageItems, ItemCount: threadCount } = response.data;
      const updatedMessageItems = messageItems
        .map(item => ({
          ...item,
          ThreadReplies: item.ThreadReplies
            ? {
                ...item.ThreadReplies,
                Messages: Array.isArray(item.ThreadReplies.Messages)
                  ? item.ThreadReplies.Messages.reverse()
                  : item.ThreadReplies.Messages,
              }
            : null,
        }))
        ?.reverse();
      if (!before) dispatch(setApiStatus({ apiName: 'initialThreadsFetchApiStatus', status: 'SUCCESS' }));
      dispatch(setChatsForTheJob(chatId, updatedMessageItems));
      dispatch(setLastFetchedThreadCountForChatId(chatId, threadCount));
    } catch (error) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'somethingWentWrongWhileFetchingExistingChatsForTheJob',
        })
      );
      if (!before) dispatch(setApiStatus({ apiName: 'initialChatsFetchApiStatus', status: 'FAILURE' }));
    }
  };
}

export function fetchSingleThread({ chatId, messageId }) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'fetchSingleThreadApiStatus', status: 'IN_PROGRESS' }));
      const response = await sourcingSupportRepository.fetchSingleThread({ chatId, messageId });
      const thread = response?.data;
      thread?.ThreadReplies?.Messages?.reverse();
      dispatch(setChatsForTheJob(chatId, [thread]));
      dispatch(setApiStatus({ apiName: 'fetchSingleThreadApiStatus', status: 'SUCCESS' }));
    } catch (err) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'failedToFetchTheCurrentThread',
        })
      );
      dispatch(setApiStatus({ apiName: 'fetchSingleThreadApiStatus', status: 'FAILED' }));
    }
  };
}

function isUserMember(chatObject, userGuid) {
  const members = chatObject.ChatMetadata.Members;
  return members.some(member => member.User.Id === userGuid);
}

export function initiateChat({ jobGuid, jobTitle, jobId, usersInTheChat, member }) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'initialChatsFetchApiStatus', status: 'IN_PROGRESS' }));
      const topicId = await dispatch(createTopic({ jobGuid, jobTitle, jobId }));
      const { ItemCount: chatCount, Items: chats } = await dispatch(getAllChats({ topicId }));
      let chatId;
      if (chatCount === 0) {
        ({ Id: chatId } = await dispatch(createChat({ jobGuid, jobTitle, jobId, usersInTheChat })));
      } else {
        chatId = chats[0]?.Id;
        if (!isUserMember(chats[0], member.Id)) await dispatch(addMembersToTheChat({ chatId, users: [member] }));
      }
      dispatch(setChatIdForTheJob(jobId, chatId));
      dispatch(setUnreadCountForChat({ chatId, unreadMessageCount: 0 }));
      dispatch(setApiStatus({ apiName: 'initialChatsFetchApiStatus', status: 'SUCCESS' }));
    } catch (error) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setApiStatus({ apiName: 'initialChatsFetchApiStatus', status: 'FAILURE' }));
    }
  };
}

export function fetchSourcingSupportChatUnreadCount({ jobGuid, jobTitle, jobId, usersInTheChat, member }) {
  return async dispatch => {
    try {
      dispatch(setApiStatus({ apiName: 'initialChatsFetchApiStatus', status: 'IN_PROGRESS' }));
      const topicId = await dispatch(createTopic({ jobGuid, jobTitle, jobId }));
      const { ItemCount: chatCount, Items: chats } = await dispatch(getAllChats({ topicId }));
      let chatId;
      let viewStatus = null;
      if (chatCount === 0) {
        ({ Id: chatId, ViewStatus: viewStatus } = await dispatch(
          createChat({ jobGuid, jobTitle, jobId, usersInTheChat })
        ));
      } else {
        chatId = chats[0]?.Id;
        viewStatus = chats[0]?.ViewStatus;
        if (!isUserMember(chats[0], member.Id)) await dispatch(addMembersToTheChat({ chatId, users: [member] }));
      }
      dispatch(setChatIdForTheJob(jobId, chatId));
      dispatch(setApiStatus({ apiName: 'initialChatsFetchApiStatus', status: 'SUCCESS' }));
      dispatch(setUnreadCountForChat({ chatId, unreadMessageCount: viewStatus?.UnreadCount || 0 }));
    } catch (error) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setApiStatus({ apiName: 'initialChatsFetchApiStatus', status: 'FAILURE' }));
    }
  };
}

export function fetchMoreRepliesForTheThread({ chatId, messageId, before, defaultLoad, limit }) {
  return async dispatch => {
    try {
      const response = await sourcingSupportRepository.getMoreRepliesForTheMessage({
        chatId,
        messageId,
        before,
        limit,
      });
      const { Items: replyMessages, ItemCount: replyMessageCount } = response.data;
      dispatch(setAllRepliesForTheThread({ chatId, messageId, replyMessages, defaultLoad }));
      if (replyMessageCount < 10) dispatch(setHasMoreRepliesForTheThread(chatId, messageId, false));
    } catch (error) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'somethingWentWrongWhileFetchingMoreReplies',
        })
      );
    }
  };
}

function createMessageObject({
  chatId,
  topicId,
  userGuid,
  messageContent,
  isThread,
  parentMessage = null,
  parentMessageId = null,
}) {
  const trimmedMessageContent = messageContent.trim();
  const msg = {
    Id: uuidv4(),
    ChatId: chatId,
    TopicId: topicId,
    MessageType: 'Chat',
    IsBroadcastMessage: false,
    From: {
      MemberId: userGuid,
      User: {
        Id: userGuid,
        UserType: 'User',
      },
    },
    Body: {
      ContentType: 'Text',
      Content: trimmedMessageContent,
    },
    ChatType: 'Group',
    IsThread: isThread,
  };

  if (!isThread) {
    msg.ReplyToMessage = {
      Id: parentMessageId,
      From: parentMessage.From,
      Body: parentMessage.Body,
      MessageType: parentMessage.MessageType,
      CreatedTime: parentMessage.CreatedTime,
    };
  }

  return msg;
}

async function sendMessage(dispatch, msg) {
  const msgString = JSON.stringify(msg);

  try {
    const chatConnectionV2 = await getChatConnectionV2(dispatch);
    chatConnectionV2.invoke('SendMessage', msgString);
  } catch (err) {
    console.error(err);
    dispatch(
      setNotification('ERROR', {
        messageId: 'failedToSendMessage',
      })
    );
  }
}

export function addThreadMessageToTheChat({ chatId, topicId, userGuid, messageContent }) {
  if (!messageContent || messageContent.trim().length === 0) {
    return dispatch => {
      dispatch(
        setNotification('ERROR', {
          messageId: 'MessageCannotBeEmpty',
        })
      );
    };
  }
  const msg = createMessageObject({ chatId, topicId, userGuid, messageContent, isThread: true });
  return async dispatch => {
    await sendMessage(dispatch, msg);
  };
}

export function addThreadReplyToTheChat({ chatId, topicId, userGuid, messageContent, parentMessage, parentMessageId }) {
  if (!messageContent || messageContent.trim().length === 0) {
    return dispatch => {
      dispatch(
        setNotification('ERROR', {
          messageId: 'MessageCannotBeEmpty',
        })
      );
    };
  }

  const msg = createMessageObject({
    chatId,
    topicId,
    userGuid,
    messageContent,
    isThread: false,
    parentMessage,
    parentMessageId,
  });
  return async dispatch => {
    await sendMessage(dispatch, msg);
  };
}
