import React from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import styles from './CandidateAdvanceFilters.module.scss';
import CandidateAdvanceFilters from './CandidateAdvanceFilters';
import { AdvanceFiltersJsonGenerator } from '../../../../Utils/AdvanceFiltersJsonGenerationUtils';

function CandidateAdvanceFilterGroup(props) {
  const { filterFields, setObject, component, isClearButtonClicked, updateApplyButtonState, shortlistedCount } = props;
  const [filterGroupList, setFilterGroupList] = React.useState([
    {
      FilterItems: [],
    },
  ]);
  const [showAddFilterGroupButton, setshowAddFilterGroupButton] = React.useState(false);
  const [showAddFilterGroupDeleteButton, setShowAddFilterGroupDeleteButton] = React.useState(1);

  const handleRemoveFilterGroupItem = index => {
    setShowAddFilterGroupDeleteButton(showAddFilterGroupDeleteButton - 1);
    const newList = [...filterGroupList];
    newList.splice(index, 1);
    setFilterGroupList(newList);
  };

  const handleAddGroupItem = () => {
    setShowAddFilterGroupDeleteButton(showAddFilterGroupDeleteButton + 1);
    setFilterGroupList([
      ...filterGroupList,
      {
        FilterItems: [],
      },
    ]);
  };

  const handleFilterGroupItemChange = (index, value) => {
    const clonedGroupFilters = _.cloneDeep(filterGroupList);
    if (clonedGroupFilters[index]) {
      clonedGroupFilters[index].FilterItems = value;
      setFilterGroupList(clonedGroupFilters);
    }
  };

  const handleFilterGroupObject = () => {
    const jsonArray = AdvanceFiltersJsonGenerator(filterGroupList, component);
    setObject(jsonArray);
  };

  const handleFilterGroupButtonDisplay = () => {
    if (filterGroupList?.length > 0) {
      if (
        filterGroupList[filterGroupList.length - 1]?.FilterItems[0] &&
        Object.keys(filterGroupList[filterGroupList.length - 1].FilterItems[0])?.length > 0
      ) {
        const filterListObject = filterGroupList[filterGroupList.length - 1]?.FilterItems[0];
        if (
          _.has(filterListObject, 'AttributeName') &&
          _.has(filterListObject, 'Operator') &&
          _.has(filterListObject, 'AttributeValue') &&
          filterListObject.AttributeValue?.length > 0
        ) {
          setshowAddFilterGroupButton(true);
        } else {
          setshowAddFilterGroupButton(false);
        }
      } else {
        setshowAddFilterGroupButton(false);
      }
    }
  };

  React.useEffect(() => {
    handleFilterGroupButtonDisplay();
    handleFilterGroupObject();
  }, [filterGroupList]);

  return (
    <div className={styles.filtergroupWrapper}>
      {filterGroupList.map((input, index) => (
        <div>
          <div className={styles.filterHeader}>
            <div key={index} className={styles.filterGroup}>
              {showAddFilterGroupDeleteButton > 1 ? (
                <div className={styles.closeIcon}>
                  <Icon onClick={() => handleRemoveFilterGroupItem(index)} type="close" />
                </div>
              ) : null}
              <CandidateAdvanceFilters
                FilterItems={filterGroupList[index]?.FilterItems}
                handleFilterGroupItemChange={handleFilterGroupItemChange}
                filterGroupId={index}
                filterGroupList={filterGroupList}
                filterFields={filterFields}
                isClearButtonClicked={isClearButtonClicked}
                setshowAddFilterGroupButton={setshowAddFilterGroupButton}
                setFilterGroupList={setFilterGroupList}
                setShowAddFilterGroupDeleteButton={setShowAddFilterGroupDeleteButton}
                component={component}
                updateApplyButtonState={updateApplyButtonState}
                shortlistedCount={shortlistedCount}
              />
            </div>
          </div>
          {filterGroupList?.length > 1 && index + 1 !== filterGroupList?.length ? (
            <Icon className={styles.filterGroupsAggregationSpliter} type="plus" />
          ) : null}
        </div>
      ))}
      {showAddFilterGroupButton ? (
        <div
          className={styles.addFilterButton}
          role="button"
          tabIndex={0}
          onKeyPress={handleAddGroupItem}
          onClick={handleAddGroupItem}
        >
          <Icon className={styles.addIcon} theme="filled" type="plus-circle" />
          Filter Group
        </div>
      ) : null}
    </div>
  );
}

export default CandidateAdvanceFilterGroup;
