import cleanSet from 'clean-set';
import {
  SET_SUBSEGMENT_LIST,
  SET_CREATE_SUBSEGMENT_MODAL_VISIBLE,
  SET_SUBSEGMENT_PAGINATION_DATA,
  SET_DELETE_SUBSEGMENT_MODAL_VISIBLE,
  SET_SELECTED_SUBSEGMENT,
  SET_ADD_SUBSEGMENT_MODAL_VISIBLE,
  SET_CREATED_SUBSEGMENT_ID
} from '../Actions/ActionCreators/SubsegmentActionCreator';

const initialState = {
  subSegmentList: {},
  subSegmentTabPaginationData: {
    pageNumber: 1,
    size: 10,
  },
  createModalVisible: false,
  deleteModalVisible: false,
  addCandidateModalVisible: false,
  selectedSubsegment: {},
  createdSubSegmentId: null
};

function SubsegmentReducer(state = initialState, action) {
  let newState;
  if (action.type === SET_SUBSEGMENT_LIST) {
    const { segmentId, data } = action.payload;
    const { Subsegments, Total } = data;
    newState = cleanSet(state, ['subSegmentList', segmentId], { subSegments: Subsegments, total: Total });
    return newState;
  }

  if (action.type === SET_CREATE_SUBSEGMENT_MODAL_VISIBLE) {
    const { visible } = action.payload;
    newState = cleanSet(state, ['createModalVisible'], visible);
    return newState;
  }
  if (action.type === SET_DELETE_SUBSEGMENT_MODAL_VISIBLE) {
    const { visible } = action.payload;
    newState = cleanSet(state, ['deleteModalVisible'], visible);
    return newState;
  }
  if (action.type === SET_ADD_SUBSEGMENT_MODAL_VISIBLE) {
    const { visible, additionalInfo } = action.payload;
    const { singleCandidateAdd, candidateId } = additionalInfo;
    newState = cleanSet(state, ['addCandidateModalVisible'], { visible, singleCandidateAdd, candidateId });
    return newState;
  }

  if (action.type === SET_SELECTED_SUBSEGMENT) {
    const data = action.payload;
    newState = cleanSet(state, ['selectedSubsegment'], data);
    return newState;
  }

  if (action.type === SET_SUBSEGMENT_PAGINATION_DATA) {
    const { pageNumber, size } = action.payload;
    newState = cleanSet(state, ['subSegmentTabPaginationData'], { pageNumber, size });
    return newState;
  }

  if(action.type === SET_CREATED_SUBSEGMENT_ID){
    const id = action.payload;
    newState = cleanSet(state, ['createdSubSegmentId'], id)
    return newState;
  }
  return state;
}

function getSubSegmentListofSegment(state, segmentId) {
  return state.SubsegmentReducer.subSegmentList[segmentId] || {};
}
function getAddModalVisbilityStatus(state) {
  return state.SubsegmentReducer.addCandidateModalVisible;
}
function getCreateModalVisbilityStatus(state) {
  return state.SubsegmentReducer.createModalVisible;
}
function getDeleteModalVisbilityStatus(state) {
  return state.SubsegmentReducer.deleteModalVisible;
}
function getSubsegmentPaginationData(state) {
  return state.SubsegmentReducer.subSegmentTabPaginationData;
}
function getselectedSubsegment(state) {
  return state.SubsegmentReducer.selectedSubsegment;
}
function getSelectedSubsegmentId(state) {
  return state.SubsegmentReducer.selectedSubsegment.Id || null;
}
function getLastCreatedSubSegmentId(state){
  return state.SubsegmentReducer.createdSubSegmentId || null;
}

export {
  SubsegmentReducer,
  getSubSegmentListofSegment,
  getCreateModalVisbilityStatus,
  getSubsegmentPaginationData,
  getDeleteModalVisbilityStatus,
  getselectedSubsegment,
  getAddModalVisbilityStatus,
  getLastCreatedSubSegmentId,
  getSelectedSubsegmentId
};
