import { setNotification } from './ConnectActions';

export const SET_WORKFLOW_TEMPLATES_API_FETCH_STATUS = 'SET_WORKFLOW_TEMPLATES_API_FETCH_STATUS';
export const SET_WORKFLOW_TEMPLATES = 'SET_WORKFLOW_TEMPLATES';
export const APPEND_WORKFLOW_TEMPLATES = 'APPEND_WORKFLOW_TEMPLATES';
export const SET_WORKFLOW_TEMPLATES_COUNT = 'SET_WORKFLOW_TEMPLATES_COUNT';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_WORKFLOW_TEMPLATE_DETAILS = 'SET_WORKFLOW_TEMPLATE_DETAILS';
export const SET_WORKFLOW_DRIP_TEMPLATE_SHARED_WITH = 'SET_WORKFLOW_DRIP_TEMPLATE_SHARED_WITH';
export const SET_ERROR = 'SET_ERROR';
export const SET_TEST_TEMPLATE_API_STATUS = 'SET_TEST_TEMPLATE_API_STATUS';

export const SET_WORKFLOW_NODE_CONNECTIONS = 'SET_WORKFLOW_NODE_CONNECTIONS';

export const SET_WORKFLOW_FETCH_API_STATUS = 'SET_WORKFLOW_FETCH_API_STATUS';

export function setWorkflowTemplatesFetchApiStatus(status) {
  const apiName = 'getWorkflowsFetchApiStatus';
  return {
    type: SET_WORKFLOW_TEMPLATES_API_FETCH_STATUS,
    payload: {
      apiName,
      status,
    },
  };
}

export function setWorkflowDripTemplates(data) {
  return {
    type: SET_WORKFLOW_TEMPLATES,
    payload: data,
  };
}

export function setWorkflowNodeConnections(data) {
  const { connections, reinitiate } = data;
  return {
    type: SET_WORKFLOW_NODE_CONNECTIONS,
    payload: {
      connections,
      reinitiate,
    },
  };
}

export function setWorkflowDripTemplateSharedWith(templateId, shareOptions) {
  return {
    type: SET_WORKFLOW_DRIP_TEMPLATE_SHARED_WITH,
    payload: shareOptions,
    templateId,
  };
}

export function setErrorForShareTemplatePostFailed() {
  return {
    type: SET_ERROR,
    payload: {
      code: 'SHARE_TEMPLATE_POST_FAILED',
      timeStamp: new Date(),
    },
  };
}

export function setTemplateSharingFailureNotification() {
  return dispatch => {
    dispatch(
      setNotification('ERROR', {
        messageId: 'templateSharingFailed',
      })
    );
  }
}
export function setLoadingNotificationMessage(data) {
  return dispatch =>{
    dispatch(
      setNotification('LOADING', {
        messageId: data,
      })
    );
  }
}

export function setSuccessNoificationMessage(data) {
  return dispatch =>{
    dispatch(
      setNotification('SUCCESS', {
        messageId: data,
      })
    );
  }
}

export function appendWorkflowDripTemplates(data) {
  return {
    type: APPEND_WORKFLOW_TEMPLATES,
    payload: {
      templates: data,
    },
  };
}

export function setWorkflowTemplatesCount(data) {
  return {
    type: SET_WORKFLOW_TEMPLATES_COUNT,
    payload: data,
  };
}

export function setWorkflowTemplateDetails(data) {
  return {
    type: SET_WORKFLOW_TEMPLATE_DETAILS,
    payload: {
      template: data,
    },
  };
}

export function setWorkflowTemplateFetchApiStatus(id, status) {
  return {
    type: SET_WORKFLOW_FETCH_API_STATUS,
    payload: {
      id,
      status,
    },
  };
}
