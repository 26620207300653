import { message } from 'antd';
import _ from 'lodash';
import * as chatGptRepo from '../Repository/ChatGptRepository';
import {
  setGenerativeAiConfiguration,
  setGenerativeAiFetchApiStatus,
  setResolvedMergeTagValues,
} from './ActionCreators/ChatGptActionCreators';
import { setNotification } from './ActionCreators/ConnectActions';

export function fetchGenerativeAiConfiguration(currentUser, jobId) {
  return async dispatch => {
    dispatch(setGenerativeAiFetchApiStatus('INPROGRESS'));
    try {
      const response = await chatGptRepo.fetchGenerativeAiConfiguration(currentUser, jobId);
      dispatch(setGenerativeAiConfiguration({ response }));
      dispatch(setGenerativeAiFetchApiStatus('COMPLETED'));
    } catch {
      dispatch(setGenerativeAiFetchApiStatus('FAILED'));
    }
  };
}

export function generateContent(payload) {
  return async dispatch => {
    try {
      return await chatGptRepo.generateContent(payload);
    } catch (error) {
      switch (_.get(error, ['response', 'status'], null)) {
        case 500:
          dispatch(
            setNotification('ERROR', {
              messageId: 'requestGotCancelled',
            })
          );
          break;
        case 429:
          dispatch(
            setNotification('ERROR', {
              messageId: 'chatGPTIsBusyRightNow',
            })
          );
          break;
        default:
          break;
      }
      return null;
    }
  };
}

export function cancelGenerateContent() {
  return () => {
    chatGptRepo.cancelGeneration();
  };
}

export function getGeneratedEmailsContentList(payload) {
  return async () => {
    const response = await chatGptRepo.getGeneratedEmailsContentList(payload);
    return response.data;
  };
}

export function saveGenerativeAiConfiguration(payload) {
  return async () => {
    await chatGptRepo.saveGenerativeAiConfiguration(payload);
  };
}

export function fetchResolvedMergeTags(jobId, candidateId) {
  return async dispatch => {
    const { data } = await chatGptRepo.fetchResolvedMergeTags(jobId, candidateId);
    dispatch(setResolvedMergeTagValues(data));
  };
}
