import React from 'react';
import { Select } from 'antd';
import {  FormattedMessage } from 'react-intl';
import messages from '../../../ManualSearchV2/ManualSearchMessages';

const { Option } = Select;

const CandidateAdvanceFilterOperators = props => {
  const { filterId, addFilterOperator, inputList, options } = props;

  const handleChange = value => {
    addFilterOperator(filterId, value);
  };

  return (
    <Select
      placeholder={<FormattedMessage {...messages.operatorLabel} />}
      style={{ width: '100%' }}
      onChange={handleChange}
      showSearch
      dropdownStyle={{ zIndex: 2200 }}
      value={inputList[filterId]?.Operator}
      showArrow={false}
      getPopupContainer={trigger => trigger.parentNode}
      data-testid="advance-filter-operators"
    >
      {options?.map(option => (
        <Option key={option.key} value={option.value}>
          {option.value}
        </Option>
      ))}
    </Select>
  );
};

export default CandidateAdvanceFilterOperators;
