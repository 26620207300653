import React from 'react';
import { connect } from 'react-redux';
import * as ReportPortalActions from '../../../Actions/ReportPortalActions';
import ReportStatsTabComponent from '../../../Components/ReportPortal/ReportStatsTab/ReportStatsTabComponent';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer';
import { getOrganizationAvailableReports } from '../../../Reducers/ReportPortalReducer';

const mapStateToProps = state => ({
  featureToggleList: getFeatureToggleList(state),
  availableReports: getOrganizationAvailableReports(state),
});

const mapDispatchToProps = {
  clearFetchReportStatsApiStatus: ReportPortalActions.clearFetchReportStatsApiStatus,
  clearRecruitersForDropdownLists: ReportPortalActions.clearRecruitersForDropdownLists,
  clearClientsForDropdownLists: ReportPortalActions.clearClientsForDropdownLists,
  clearReportStats: ReportPortalActions.clearReportStats,
  clearTheReportList: ReportPortalActions.clearTheReportList,
};

export function ReportStatsTabContainer(props) {
  const {
    clearFetchReportStatsApiStatus,
    clearRecruitersForDropdownLists,
    clearClientsForDropdownLists,
    clearReportStats,
    featureToggleList,
    availableReports,
    clearTheReportList,
  } = props;
  return (
    <ReportStatsTabComponent
      clearFetchReportStatsApiStatus={clearFetchReportStatsApiStatus}
      clearRecruitersForDropdownLists={clearRecruitersForDropdownLists}
      clearClientsForDropdownLists={clearClientsForDropdownLists}
      clearReportStats={clearReportStats}
      featureToggleList={featureToggleList}
      availableReports={availableReports}
      clearTheReportList={clearTheReportList}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportStatsTabContainer);
