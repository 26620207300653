import React from 'react';
import { Form, Input } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';

export function getPlaceholderText(jobBoard,intl) {
  return jobBoard.toLowerCase() === 'efinancial' ? intl.formatMessage({ ...messages.APIkeyLabel }) :  intl.formatMessage({ ...messages.usernameLabel });
}

 function UsernameInput({ form, account, credentialsValidator, isAuthenticated, jobBoard, intl }) {
  const usernameFieldName = `${jobBoard}userName`;
  const usernameError = form.isFieldTouched(usernameFieldName) && form.getFieldError(usernameFieldName);
  return (
    <div className="jobboard-input-container">
      <Form.Item validateStatus={usernameError ? 'error' : ''} help="">
        {form.getFieldDecorator(usernameFieldName, {
          initialValue: account.Username,
          rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
        })(
          <Input
            placeholder={getPlaceholderText(jobBoard,intl)}
            className="common-jobboard-username"
            readOnly={isAuthenticated}
          />
        )}
      </Form.Item>
    </div>
  );
}

export default (injectIntl(Form.create()(UsernameInput)));
export {UsernameInput as UsernameInputWithoutInjectIntl};
