export const SET_CANDIDATE_WORKFLOW_HISTORY = 'SET_CANDIDATE_WORKFLOW_HISTORY';
export const WORKFLOW_INITIATED = 'WORKFLOW_INITIATED';

export function setWorkflowWindowhistory(Id, data) {
  return {
    type: SET_CANDIDATE_WORKFLOW_HISTORY,
    payload: {
      Id,
      data,
    },
  };
}
