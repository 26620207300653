import React from 'react';
import { Input, Button, Form } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import './UserAndClientListFilters.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function UserAndCLientListFilters(props) {
  const { onAddNew, onSearch, onSearchChange, toggleAddClientDrawer, intl } = props;
  return (
    <div className="user-client-list-controls">
      <Input.Search
        placeholder={intl.formatMessage({ ...message.clientSearchLabel })}
        className="user-client-search"
        onSearch={value => onSearch(value)}
        onChange={e => onSearchChange(e)}
      />
      {/* <Tooltip title="Filters">
        <Button className="filter-users-clients">
          <img
            src={`${process.env.PUBLIC_URL}/static/Images/filter.svg`}
            alt="Filter"
            className="candidate-aggregation-image-filter"
          />
        </Button>
      </Tooltip>
      <div>
        <Icon type="ordered-list" className="sort-users-clients" />
      </div> */}
      <Button
        type="primary"
        shape="round"
        onClick={() => {
          onAddNew();
          toggleAddClientDrawer();
        }}
      >
        <FormattedMessage {...message.newClientLabel} />
      </Button>
    </div>
  );
}

export default (injectIntl(Form.create()(UserAndCLientListFilters)));
export {UserAndCLientListFilters as UserAndClientListFiltersWithoutInjectIntl};
