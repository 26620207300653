import * as React from 'react';

const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#FAAD14"
      d="M8 1a7 7 0 1 0 .001 14.001A7 7 0 0 0 8 1Zm-.5 3.625c0-.069.056-.125.125-.125h.75c.069 0 .125.056.125.125v4.25A.125.125 0 0 1 8.375 9h-.75a.125.125 0 0 1-.125-.125v-4.25ZM8 11.5A.75.75 0 0 1 8 10a.75.75 0 0 1 0 1.5Z"
    />
  </svg>
);
export default SvgComponent;
