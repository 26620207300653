import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from 'antd';
import { FormSelectComponent, FormDropdownInputComponent } from '../../Utils/FormComponentUtils';
import { getDisplayedLocations } from '../../Utils/LocationUtils';
import useZipcodeLocation from '../../Hooks/useZipcodeLocation';
import './ZipcodeLocation.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function ZipcodeLocation(props) {
  const { locations, zipcodes, isZipcodeValid, locationChange, callForZipCodes, filterLocations, onZipcodeChange } =
    useZipcodeLocation(props);
  const {
    form,
    zipcodeFieldDecorator,
    locationFieldDecorator,
    locationInitialValue,
    zipcodeInitialValue,
    isZipcodeDisabled,
    isLocationDisabled,
    locationLabel,
    zipcodeLabel,
    intl,
  } = props;
  const currentLocation = form.getFieldValue(locationFieldDecorator);
  const displayedLocations = getDisplayedLocations({ currentLocation, locationSuggestions: locations });

  return (
    <div className="zipcode-location">
      <FormSelectComponent
        label={locationLabel}
        placeholder={<FormattedMessage {...message.cityStateLabel} />}
        fieldDecoratorValue={locationFieldDecorator}
        notFoundContent={<FormattedMessage {...message.enterLocationLabel} />}
        className="location-select"
        showSearch
        showArrow={false}
        allowClear
        inputValues={displayedLocations}
        initialValue={locationInitialValue}
        onSearch={value => {
          if (value) filterLocations(value);
        }}
        onSelect={value => callForZipCodes(value)}
        onChange={value => locationChange(value)}
        form={form}
        isDisabled={isLocationDisabled}
      />
      <FormDropdownInputComponent
        label={zipcodeLabel}
        placeholder={intl.formatMessage({ ...message.postalCodeLabel })}
        fieldDecoratorValue={zipcodeFieldDecorator}
        errorMessage={<FormattedMessage {...message.invalidPostalCodeLabel} />}
        autoComplete="off"
        dropdownOverlayClassName="zipcode-dropdown"
        className="zipcode-input"
        showSearch
        showArrow={false}
        allowClear
        inputValues={zipcodes}
        initialValue={zipcodeInitialValue}
        validityStatus={isZipcodeValid}
        onChange={value => {
          onZipcodeChange(value);
        }}
        onClick={value => {
          form.setFieldsValue({ [zipcodeFieldDecorator]: value });
          onZipcodeChange(value);
        }}
        form={form}
        isDisabled={isZipcodeDisabled}
      />
    </div>
  );
}

export default injectIntl(Form.create()(ZipcodeLocation));
export {ZipcodeLocation as ZipcodeLocationWithoutInjectIntl}
