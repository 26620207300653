import * as React from 'react';

const SvgComponent = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={14} fill="none" {...props}>
    <path
      stroke="#9196A0"
      d="M3.286 12.377c.006-.047.014-.11.025-.174.19-1.107.379-2.214.574-3.321.023-.129.001-.216-.097-.31-.816-.786-1.626-1.579-2.437-2.37-.25-.243-.301-.504-.155-.772.103-.189.273-.272.48-.302 1.137-.162 2.272-.33 3.409-.49.115-.016.18-.06.231-.166C5.82 3.44 6.33 2.41 6.838 1.382c.138-.28.356-.409.623-.377.24.028.389.17.491.378.455.924.93 1.838 1.357 2.775.16.353.365.495.746.54 1.033.118 2.06.285 3.088.437.402.06.63.426.486.794a.899.899 0 0 1-.215.293c-.795.78-1.591 1.558-2.393 2.331-.108.103-.142.197-.116.346.199 1.116.39 2.233.58 3.35.067.394-.14.712-.508.74a.86.86 0 0 1-.434-.105A292.039 292.039 0 0 1 7.536 11.3c-.104-.055-.181-.054-.284 0-1.005.535-2.012 1.065-3.019 1.595-.297.157-.535.137-.753-.053-.137-.12-.184-.275-.194-.465Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgComponent;
