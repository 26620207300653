/* eslint-disable */
import { FormattedMessage } from 'react-intl';
import { getSourceName } from './SourceUtils';
import { getCurrentJobDetails } from '../Reducers/JobReducer';
import store from '../store';
import messages from '../Containers/ManualSearchV2/ManualSearchMessages';


function mapCandidateListApiErrorCodes(error) {
  const code = error?.response?.status;
  let errorData = {
    Code: code,
    Message: <FormattedMessage {...messages.noCandidatesFoundLabel} />,
    ErrorDescription: <FormattedMessage {...messages.pleaseTryChangingoptionsAndSearchAgainLabel} />,
  };
  const errorResponse = error?.response?.data?.Error || {};
  const { Code: appCode, Info: source = {}, Message: message } = errorResponse;
  const sourceName = getSourceName(source ?? {});
  const state = store.getState();
  const currentJobDetails = getCurrentJobDetails(state);
  const jobLocation = currentJobDetails?.Country;
  switch (error?.response?.status) {
    case 404:
      if (appCode === 'CREDENTIALS_NOT_FOUND')
        errorData = {
          code: 404,
          Message: <FormattedMessage {...messages.credentialsDoNotExistForSourceNameLabel} values={{sourceName}}/>,
          ErrorDescription: <FormattedMessage {...messages.pleaseAuthorizeToSeeAllTheCandidatesLabel} />,
        };
      break;
    case 401:
      errorData = {
        code: 401,
        Message: <FormattedMessage {...messages.credentialsAreInvalidExpiredForSourceName} values={{sourceName}}/>,
        ErrorDescription: <FormattedMessage {...messages.PleaseReauthorizeToSeeAllTheCandidates} />,
      };
      break;
    case 403:
      if (appCode === 'CREDENTIALS_NOT_SUPPORTED')
        errorData = {
          code: 403,
          Message: <FormattedMessage {...messages.credentialsNotSupportedForRegionJobLocation} values={{sourceName,jobLocation}}/>,
          ErrorDescription: message,
          appCode: 'CREDENTIALS_NOT_SUPPORTED',
        };
      else if (appCode === 'SOURCE_FORBIDDEN')
        errorData = {
          code: 403,
          Message: <FormattedMessage {...messages.youDontHaveAccessToSourceNameLabel} values={{sourceName}}/>,
          ErrorDescription: <FormattedMessage {...messages.contactYourAdminLabel} values={{sourceName}}/>,
          appCode: 'SOURCE_FORBIDDEN',
        };
      break;
    case 406:
      errorData = {
        code: 406,
        Message: <FormattedMessage {...messages.sourceNameIsNotAvailableInJobLocation} values={{sourceName,jobLocation}}/>,
        ErrorDescription: <FormattedMessage {...messages.pleaseContactYourAdminstratorForMoreInformation} />,
      };
      break;
    default:
      break;
  }
  return errorData;
}
function mapBulkPublishCandidatesApiErrorCode(error) {
  let errorMessage = 'Oops, something just went wrong. Please try again.';
  if (error?.response?.data?.Error?.Code === 'UNSUPPORTED_OPERATION') {
    errorMessage = error?.response?.data?.Error?.Message;
  }
  return errorMessage;
}
export { mapCandidateListApiErrorCodes, mapBulkPublishCandidatesApiErrorCode };
