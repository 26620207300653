import React from 'react';
import _ from 'lodash';
import { OutlookIcon } from '../Icons/AryaIcons';
import GoogleIcon from '../Icons/GoogleIcon';
import config from '../Config/Config';

function getProviderConfig(providers, name) {
  const provider = (providers || []).find(p => {
    return _.get(p, ['AuthScheme'], '').toLowerCase() === name.toLowerCase();
  });
  if (!provider) return null;
  return provider;
}

function getExistingProvider(providers, currentConfiguration) {
  return _.find(providers, provider => {
    return (
      _.get(provider, ['AuthScheme']) === _.get(currentConfiguration, ['AuthScheme']) &&
      _.get(provider, ['Configuration', 'Username']) === _.get(currentConfiguration, ['Configuration', 'Username']) &&
      _.get(provider, ['Configuration', 'Password']) === _.get(currentConfiguration, ['Configuration', 'Password']) &&
      _.get(provider, ['Configuration', 'Host']) === _.get(currentConfiguration, ['Configuration', 'Host']) &&
      _.get(provider, ['Configuration', 'Port'], 0).toString() ===
        _.get(currentConfiguration, ['Configuration', 'Port'])
    );
  });
}

function getExistingProviderWithGivenName(providers, currentName) {
  return _.find(
    providers,
    provider => _.get(provider, ['Name'], '').toLowerCase() === (currentName || '').toLowerCase()
  );
}

function checkIfEmailIsLinkedToOtherProvider(emailProviders, emailId, currentProviderId) {
  let existingProviderId = null;
  emailProviders.some(provider => {
    const emails = _.get(provider, ['Emails'], []);
    const existingEmailObject = _.find(emails, email => email.Email.EmailId === emailId);
    if (!existingEmailObject) return false;
    existingProviderId = _.get(provider, ['Id']);
    return existingProviderId;
  });
  return existingProviderId && existingProviderId !== currentProviderId;
}

function getSmtpRequestBody(values) {
  const formNameToApiName = {
    'email-smtp-name': 'Name',
    'email-smtp-type': 'AuthScheme',
    'email-smtp-username': 'Username',
    'email-smtp-password': 'Password',
    'email-smtp-outgoing-mail-server': 'Host',
    'email-smtp-port': 'Port',
    'email-smtp-ssl-checkbox': 'EnableSsl',
  };

  const stmpConfigValues = {};
  Object.keys(values).forEach(key => {
    const field = formNameToApiName[key];
    stmpConfigValues[field] = values[key];
  });

  const { Name, AuthScheme, Username, Password, Host, Port, EnableSsl } = stmpConfigValues;

  return {
    Configuration: {
      EnableSsl,
      Host: Host && Host.trim(),
      Port,
      Username: Username && Username.trim(),
      Password: Password && Password.trim(),
    },
    AuthScheme,
    Name,
  };
}

const getOAuthIcon = providerName => {
  switch (providerName) {
    case 'google':
    case 'googlemanual':
      return <GoogleIcon />;
    case 'microsoft':
      return <OutlookIcon />;
    default:
      return null;
  }
};

const getOAuthProviderDisplayName = {
  google: 'Google',
  googlemanual: 'Google',
  microsoft: 'Office365',
};

const PROVIDER_TYPE = {
  manual: 'Manual',
  google: 'Google',
  microsoft: 'Microsoft',
  googlemanual: 'Google',
};

const getAllowedSmtpOptions = props => {
  const { manualValue } = props;
  const allowedSmtpOptions = [];
  allowedSmtpOptions.push({
    value: manualValue,
    label: 'Manual',
  });
  return allowedSmtpOptions;
};

const generateUrl = props => {
  const { isAdmin, currentUser, provider } = props;
  const { baseUrl, redirectUri, clientId } = config.email.oauth[getOAuthProviderDisplayName[provider].toLowerCase()];
  const user = isAdmin ? 'admin' : 'user';
  let url;
  if (provider === 'google') {
    url = `${baseUrl}?client_id=${clientId}&response_type=code&scope=profile%20email%20https://www.googleapis.com/auth/gmail.send&redirect_uri=${redirectUri}&access_type=offline&approval_prompt=force&state=`;
    url += `${currentUser.email}|${provider}|${user}`;
  } else if (provider === 'microsoft') {
    url = `${baseUrl}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid+profile+email+offline_access+User.Read+Mail.Send+Mail.Send.Shared&state=`;
    url += `${currentUser.email}|${provider}|${user}`;
  }

  return url;
};

const providerNameToDisplayNameMapper = {
  manual: 'Organization Default',
  google: 'Google',
  microsoft: 'Office365',
};

const getProviderDisplayName = provider => {
  const providerName = provider?.ProviderName?.toLowerCase();
  const name = provider?.Name;
  let providerDisplayName = providerNameToDisplayNameMapper[providerName];
  if (providerDisplayName === 'Organization Default' && name !== 'Organization Default') providerDisplayName = name;
  return providerDisplayName;
};

export {
  getProviderConfig,
  getSmtpRequestBody,
  getExistingProvider,
  checkIfEmailIsLinkedToOtherProvider,
  getExistingProviderWithGivenName,
  getOAuthIcon,
  getProviderDisplayName,
  getOAuthProviderDisplayName,
  generateUrl,
  getAllowedSmtpOptions,
  PROVIDER_TYPE,
  providerNameToDisplayNameMapper,
};
