import { Divider, Icon } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styles from './ThreadRepliesContainer.module.scss';
import ThreadReplyItem from './ThreadReplyItem';
import ThreadReplyInputComponent from './ThreadReplyInputComponent';

function ThreadRepliesContainer(props) {
  const {
    threadReplies,
    currentMember,
    threadId,
    chatId,
    parentMessage,
    addThreadReplyToTheChat,
    topicId,
    fetchMoreRepliesForTheThread,
    hasMoreReplies,
    isChatDisabled,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const thirdMessageRef = useRef(null);
  const displayedReplies = isExpanded ? threadReplies : threadReplies.slice(0, 2);
  let currentPaginatedLastThreadReplyCreatedTime = null;
  const seeRepliesButtonName = isExpanded ? 'see less' : 'see more';
  const shouldShowSeeRepliesButton = (hasMoreReplies && threadReplies?.length >= 2) || threadReplies?.length > 2;
  if (threadReplies?.length) {
    const lastThreadReply = threadReplies[threadReplies.length - 1];
    currentPaginatedLastThreadReplyCreatedTime = new Date(lastThreadReply?.CreatedTime).getTime();
  }
  const expandedReplies = () => {
    const onLoadMore = () => {
      fetchMoreRepliesForTheThread({
        messageId: threadId,
        chatId,
        before: currentPaginatedLastThreadReplyCreatedTime,
        defaultLoad: false,
      });
    };
    return (
      <div className={styles.infiniteScrollingreplyItems}>
        <InfiniteScroll
          loadMore={onLoadMore}
          useWindow={false}
          initialLoad={false}
          hasMore={hasMoreReplies}
          loader={
            <div key="loader" style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
              <Icon style={{ fontSize: 20 }} type="loading" spin />
            </div>
          }
          threshold={50}
        >
          {(threadReplies || []).map((threadReply, index) => (
            <ThreadReplyItem
              key={threadReply.Id}
              threadReply={threadReply}
              ref={index === 2 ? thirdMessageRef : null}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };
  useEffect(() => {
    fetchMoreRepliesForTheThread({ messageId: threadId, chatId, before: null, defaultLoad: true, limit:100 });
  }, []);
  useEffect(() => {
    if (isExpanded && thirdMessageRef.current) {
      thirdMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [isExpanded]);
  const onSeeRepliesButtonClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      {!isChatDisabled && (
        <ThreadReplyInputComponent
          currentMember={currentMember}
          parentMessageId={threadId}
          parentMessage={parentMessage}
          addThreadReplyToTheChat={addThreadReplyToTheChat}
          chatId={chatId}
          topicId={topicId}
          isChatDisabled={isChatDisabled}
        />
      )}
      <Divider className={styles.horizontalLine} type="horizontal" />
      {!isExpanded ? (
        <div className="ReplyItems">
          {displayedReplies?.length > 0 ? (
            <div className={styles.replyItems}>
              {(displayedReplies || []).map(threadReply => (
                <ThreadReplyItem key={threadReply.Id} threadReply={threadReply} />
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        expandedReplies()
      )}
      {shouldShowSeeRepliesButton && (
        <div className={styles.seeRepliesButton} onClick={onSeeRepliesButtonClick}>
          {seeRepliesButtonName}
        </div>
      )}
    </div>
  );
}

export default ThreadRepliesContainer;
