import React from 'react';
import _ from 'lodash';
import { Form, Button, Icon, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getCountableNounForm } from '../../../Utils/TextUtils';
import './NewJobBoardLoginForm.scss';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';
import { getJobBoardErrorDetails } from '../../../Utils/JobBoardUtils';

class NewJobBoardLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getIsAuthenticated = this.getIsAuthenticated.bind(this);
    this.getSourceAuthenticationText = this.getSourceAuthenticationText.bind(this);
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    const { form } = this.props;
    form.validateFields();
  }

  handleSubmit(isAuthenticated) {
    const {
      form,
      jobBoard,
      disconnect,
      index,
      removeSelectedCountry,
      authenticate,
      clearAccount,
      addSelectedCountry,
      integrateSource,
    } = this.props;
    const countryCode = form.getFieldValue(`${jobBoard}countryCode`);
    if (jobBoard.toLowerCase() === 'resumelibrary') {
      if (isAuthenticated) {
        disconnect(jobBoard, countryCode, index, removeSelectedCountry);
      } else {
        authenticate(
          jobBoard,
          {
            IsSystemOwned: true,
            CountryCode: countryCode,
          },
          index,
          clearAccount,
          addSelectedCountry
        );
      }
    } else {
      const sourceDetails = {
        Source: {
          Group: jobBoard,
        },
        IsAuthorized: !isAuthenticated,
      };
      integrateSource(jobBoard, sourceDetails, index);
    }
  }

  getIsAuthenticated() {
    const { sourceConfig } = this.props;
    return _.get(sourceConfig, 'IsAuthorized', false);
  }

  getSourceAuthenticationText() {
    const { jobBoard, sourceConfig, AppName } = this.props;
    let credits = _.get(sourceConfig, 'Credits', 0);
    if (jobBoard.toLowerCase() === 'passive') {
      credits = 1;
    }
    const creditsText = getCountableNounForm('credit', 'credits', credits);
    const sourceName = jobBoard.toLowerCase();
    let actionName = '';
    switch (sourceName) {
      case 'resumelibrary':
        actionName = 'resume unlock';
        break;
      case 'active':
        actionName = 'profile view';
        break;
      case 'passive':
        actionName = 'contact pull';
        break;
      default:
        actionName = 'profile view';
    }
    if (sourceName === 'indeed') return <FormattedMessage {...messages.indeedNoLongerSupportsAPIintegrationsLabel} />;
    return (
      <FormattedMessage
        {...messages.appNameWillChargeCreditsCreditsTextForEveryActionNameLabel}
        values={{ AppName, credits, creditsText, actionName }}
      />
    );
  }

  render() {
    const {
      form,
      jobBoard,
      portalSettings,
      account: credentials,
      index,
      Countries,
      countriesMenu,
      portalText,
      showAuthenticateButton,
      userConfig,
    } = this.props;
    const isAuthenticated = this.getIsAuthenticated();
    const errorDetail = getJobBoardErrorDetails(credentials, jobBoard, userConfig);
    return (
      <div className="portal-login-form">
        <div className={isAuthenticated ? 'authentication-tick' : 'authentication-untick'}>
          {isAuthenticated ? <Icon type="check-circle" className="authentication-check-circle" theme="filled" /> : null}
        </div>
        <div className="credentials-container">
          <Form layout="inline">
            <div className="credentials-form">
              {['resumelibrary', 'indeed'].includes(jobBoard.toLowerCase()) ? (
                <Form.Item>
                  <div className="jobboard-country-code">
                    {form.getFieldDecorator(`${jobBoard}countryCode`, {
                      initialValue: credentials.CountryCode || (Countries && Countries[0]),
                    })(
                      <Select
                        className="jobboard-country-code-select"
                        disabled={isAuthenticated || countriesMenu.length < 2}
                      >
                        {countriesMenu}
                      </Select>
                    )}
                  </div>
                </Form.Item>
              ) : null}
              <Form.Item>
                <div className="authenticate-rl">
                  {jobBoard.toLowerCase() === 'indeed' ? (
                    <FormattedMessage {...messages.portalTextCandidatesAccessibleLabel} values={{ portalText }} />
                  ) : (
                    <FormattedMessage {...messages.authenticatePortalTextLabel} values={{ portalText }} />
                  )}
                </div>
                <div className="authentication">{this.getSourceAuthenticationText()}</div>
              </Form.Item>

              <Form.Item>
                <div className="btn-container">
                  {showAuthenticateButton ? (
                    <Button
                      className={isAuthenticated ? 'disconnect-button' : 'authenticate-button'}
                      loading={_.get(portalSettings, ['AuthenticationApiStatus', index], '') === 'INPROGRESS'}
                      htmlType="submit"
                      onClick={() => this.handleSubmit(isAuthenticated)}
                      shape="round"
                      block
                    >
                      {isAuthenticated ? <FormattedMessage {...messages.disconnectLabel}  /> : <FormattedMessage {...messages.authenticateLabel}  />}
                    </Button>
                  ) : null}
                </div>
              </Form.Item>
            </div>
          </Form>
          <div className="error-message">{errorDetail.Message}</div>
        </div>
      </div>
    );
  }
}

export default Form.create()(NewJobBoardLoginForm);
