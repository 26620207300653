import { Icon } from 'antd';
import * as React from 'react';

const CreatePostSvg = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M7.96436 17.9645H3.96435C2.85978 17.9645 1.96435 17.069 1.96436 15.9645L1.96443 3.96451C1.96444 2.85995 2.85987 1.96452 3.96443 1.96452H12.9647C14.0692 1.96452 14.9647 2.85995 14.9647 3.96452V7.96452M10.9645 15.207L15.2072 10.9644L18.0356 13.7928L13.7929 18.0355H10.9645V15.207Z"
        stroke="white"
      />
    </svg>
  );
};

const CreatePostIcon = props => <Icon component={() => CreatePostSvg(props)} {...props} />;
export default CreatePostIcon;
