import { Tooltip } from 'antd';
import React from 'react';
import { FilterIcon } from '../../../Icons/AryaIcons';
import styles from './FilterIconWithTooltip.module.scss';
import ManualSearchCollapsedFilterIconV2 from '../../../Icons/ManualSearchCollapsedFilterIconV2';

export default function FilterIconWithTooltip(props) {
  const { onClick, title, isFilterApplied, style, dotStyle, showV2FilterIcon } = props;
  return (
    <div role="presentation" className={styles.FilterIconToolTip} onClick={onClick}>
      <Tooltip title={title}>
        <div className={styles.icon}>
          {isFilterApplied ? <div className={styles.dot} style={dotStyle}></div> : null}
          {showV2FilterIcon ? <ManualSearchCollapsedFilterIconV2 style={style} /> : <FilterIcon style={style} />}
        </div>
      </Tooltip>
    </div>
  );
}
