import * as React from 'react';
import { Icon } from 'antd';

const CreateCampaignSvg = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C4.48893 0 0 4.48893 0 10C0 15.5112 4.48893 20 10 20C15.5112 20 20 15.5112 20 10C20 4.48893 15.5112 0 10 0ZM12.5444 14.1222L9.21111 10.7889C9 10.5777 8.88885 10.2999 8.88885 10V4.44447H11.1111V9.54442L14.1222 12.5556L12.5556 14.1222H12.5444Z"
      fill="#13C26B"
    />
  </svg>
);
const CreateCampaignIcon = props => (
  <Icon component={customProps => <CreateCampaignSvg {...props} {...customProps} />} {...props} />
);
export default CreateCampaignIcon;
