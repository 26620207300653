import React from 'react';
import uuid from 'uuid';
import _ from 'lodash';
import MustHavesContext from './MustHavesContext';
import { changeCursorPosition } from '../../Utils/ManualSearchUtils';
import { filterUniqueSkills } from '../../Utils/MissingSkillsUtils';

export const getFormattedMustHaves = (value, mustHaveInputValue, isDoubleQuotesAllowed, setIsDoubleQuotesAllowed) => {
  const hasWhitespace = /\s/.test(value);
  const surroundedByQuotes = /^".*"$/;
  const singleDoubleQuoteRegex = /^([^"]*"){1}[^"]*$/;
  const prevValueHasDoubleQuotes = surroundedByQuotes.test(mustHaveInputValue);
  if (value?.trim() && hasWhitespace && !/"/.test(mustHaveInputValue) && isDoubleQuotesAllowed) {
    return `"${value}"`;
  }
  if (prevValueHasDoubleQuotes && singleDoubleQuoteRegex.test(value)) {
    if (mustHaveInputValue?.length > 2) setIsDoubleQuotesAllowed(false);
    return value.replace(/"/g, '');
  }
  if (!value.length || value === '"') setIsDoubleQuotesAllowed(true);
  return value;
};

export default function MustHavesWrapper(props) {
  const { children, mustHaves = {}, onChange } = props;
  const [inputVisibilityKey, setInputVisibilityKey] = React.useState('');
  const [inputValue, setInputValue] = React.useState(''); // Input in Boolean Display component
  const [mustHaveInputValue, setMustHaveInputValue] = React.useState(''); // Input in Must Have component
  const [isDoubleQuotesAllowed, setIsDoubleQuotesAllowed] = React.useState(true);
  const mustHaveInputRef = React.useRef(null);

  const addMustHave = (mustHave, currentIndex) => {
    const clonedMustHaves = _.cloneDeep(mustHaves);
    if (currentIndex) {
      const currentIndexMustHaves = clonedMustHaves[currentIndex];
      if (
        !currentIndexMustHaves.selectedSkills.find(
          selectedSkill => selectedSkill.toLowerCase() === mustHave.toLowerCase()
        )
      ) {
        const updatedCurrentIndexMustHaves = currentIndexMustHaves.selectedSkills.concat(mustHave);
        _.setWith(clonedMustHaves, [currentIndex, 'selectedSkills'], updatedCurrentIndexMustHaves, Object);
      }
    } else {
      mustHave.split(',').forEach(skill => {
        const currentSkillKey = uuid.v4();
        _.setWith(clonedMustHaves, [currentSkillKey, 'selectedSkills'], [skill], Object);
      });
      setMustHaveInputValue('');
    }
    setIsDoubleQuotesAllowed(true);
    onChange(filterUniqueSkills(clonedMustHaves));
  };

  const deSelectMustHave = (mustHave, currentIndex) => {
    const clonedMustHaves = _.cloneDeep(mustHaves);
    const currentIndexMustHaves = clonedMustHaves[currentIndex];
    const updatedCurrentIndexMustHaves = currentIndexMustHaves.selectedSkills.filter(
      selectedSkill => selectedSkill !== mustHave
    );
    if (!updatedCurrentIndexMustHaves?.length) {
      delete clonedMustHaves[currentIndex];
    } else {
      _.setWith(clonedMustHaves, [currentIndex, 'selectedSkills'], updatedCurrentIndexMustHaves, Object);
    }
    onChange(clonedMustHaves);
  };

  const onMustHavesChange = value => {
    const formattedMustHave = getFormattedMustHaves(
      value,
      mustHaveInputValue,
      isDoubleQuotesAllowed,
      setIsDoubleQuotesAllowed
    );
    setMustHaveInputValue(formattedMustHave);
  };

  React.useEffect(() => {
    const mustHaveInput = mustHaveInputRef.current?.input?.input;
    changeCursorPosition(mustHaveInput, mustHaveInputValue, isDoubleQuotesAllowed);
  }, [mustHaveInputValue, isDoubleQuotesAllowed]);

  return (
    <MustHavesContext.Provider
      value={{
        mustHaves,
        addMustHave,
        deSelectMustHave,
        inputVisibilityKey,
        setInputVisibilityKey,
        inputValue,
        setInputValue,
        mustHaveInputValue,
        setMustHaveInputValue,
        onMustHavesChange,
        mustHaveInputRef,
      }}
    >
      {children}
    </MustHavesContext.Provider>
  );
}
