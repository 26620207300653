import React from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import config from '../../Config/Config';
import { highlightHtml } from '../../Utils/KeywordHighlightUtil';

class HtmlViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.createCleanMarkup = this.createCleanMarkup.bind(this);
  }

  componentDidMount() {
    const { highlights, mustHavesKeywords } = this.props;
    setTimeout(() => {
      const htmlResumeNodes = document.querySelectorAll('.html-resume-viewer');
      highlightHtml(htmlResumeNodes, highlights, 'mark', mustHavesKeywords);
    }, 0);
  }

  createCleanMarkup() {
    const { htmlContent, htmlPurificationOptions, forbidTags, includeAttributes } = this.props;
    const whitelistURIs = ['http', 'https'];
    const whitelistDomains = config.trustedResumeResourceDomains;
    const URIRegex = new RegExp(`^(${whitelistURIs.join('|')}):`, 'gim');
    const domainRegex = new RegExp(`${whitelistDomains.join('|')}`, 'gim');
    DOMPurify.addHook('beforeSanitizeAttributes', node => {
      if (node.tagName === 'LINK') {
        if (node.hasAttribute('href')) {
          const protocol = node.getAttribute('href').split('/')[0];
          const domain = node.getAttribute('href').split('/')[2];
          if (
            (protocol && !protocol.match(URIRegex)) ||
            (domain && !domain.match(domainRegex)) ||
            !domain ||
            !protocol
          ) {
            node.removeAttribute('href');
          }
        }
      }
    });
    let cleanHtmlContent = DOMPurify.sanitize(htmlContent, {
      ADD_TAGS: ['link'],
      ADD_ATTR: includeAttributes,
      FORBID_TAGS: forbidTags ?? [],
      WHOLE_DOCUMENT: true,
    });

    htmlPurificationOptions.forEach(option => {
      cleanHtmlContent = DOMPurify.sanitize(cleanHtmlContent, option);
    });
    cleanHtmlContent = cleanHtmlContent?.replace(/<a href/g, `<a target="_blank" href`); // to open hyperlinks in new tab
    // DOMPurify.removeAllHooks();
    return { __html: cleanHtmlContent };
  }

  render() {
    const { className, textPreviewRef = {} } = this.props;

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={this.createCleanMarkup()}
        className={`${className || ''} html-resume-viewer`}
        ref={textPreviewRef}
      />
    );
  }
}

HtmlViewer.propTypes = {
  htmlPurificationOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

HtmlViewer.defaultProps = {
  htmlPurificationOptions: [],
};

export default HtmlViewer;
