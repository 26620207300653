import React from 'react';
import styles from './ThreadItem.module.scss';
import ThreadMessage from './ThreadMessage';
import ThreadRepliesContainer from './ThreadRepliesContainer';

function ThreadItem(props) {
  const {
    thread,
    currentMember,
    chatId,
    topicId,
    addThreadReplyToTheChat,
    fetchMoreRepliesForTheThread,
    isChatDisabled,
    isSingleThreadWindow
  } = props;
  const {
    ThreadReplies,
    Body: threadMessage,
    CreatedTime: threadCreatedTime,
    From: from,
    hasMoreReplies = true,
  } = thread;
  const threadDetails = {
    userName: from?.User?.DisplayName,
    content: threadMessage?.Content,
    postedAt: threadCreatedTime,
    isAryaRecruiter: from?.Role?.Name === 'SupportAdmin' || false,
  };
  const replyToMessage = ThreadReplies?.Messages?.[0] || thread;

  return (
    <div className={styles.threadItem}>
      <ThreadMessage threadDetails={threadDetails} />
      <ThreadRepliesContainer
        threadReplies={ThreadReplies?.Messages || []}
        currentMember={currentMember}
        threadId={thread.Id}
        parentMessage={replyToMessage}
        chatId={chatId}
        addThreadReplyToTheChat={addThreadReplyToTheChat}
        topicId={topicId}
        fetchMoreRepliesForTheThread={fetchMoreRepliesForTheThread}
        hasMoreReplies={hasMoreReplies}
        isChatDisabled={isChatDisabled}
        isSingleThreadWindow={isSingleThreadWindow}
      />
    </div>
  );
}

export default ThreadItem;
