import React from 'react';
import {  FormattedMessage } from 'react-intl';
import { EmptyCandidateIntelIcon } from '../../Icons/AryaIcons';
import './CandidateIntel.scss';
import messages from '../../Containers/ManualSearchV2/ManualSearchMessages';

export default function NoCandidateIntel() {
  return (
    <div className="candidate-intel-not-found">
      <div className="empty-candidate-intel-icon">
        <EmptyCandidateIntelIcon />
      </div>
      <span className="empty-candidate-intel-text"> <FormattedMessage {...messages.noScoreDetailsFoundLabel}  /></span>
    </div>
  );
}
