import React from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';
import styles from './CustomCheckableTags.module.scss';
import CheckboxGreenTick from '../../../Icons/CheckboxGreenTick';
import CheckboxWithoutTick from '../../../Icons/CheckboxWithoutTick';

/**
 *
 * @callback tagsClassName
 * @param {boolean} isTagSelected Indicated whether the tag is selected or not
 * @returns {string} classname applied to the tag
 */

/**
 *
 * @callback getSkEventName
 * @param {boolean} isTagSelected Indicated whether the tag is selected or not
 * @returns {string} sk-event-name applied to the tag
 */

/**
 *
 * @param {Object} props
 * @param {Object[]} props.tags Array of tags
 * @param {string|number} props.tags.id Unique id of each tag
 * @param {Boolean} props.tags.isSelected Indicated whether the tag is selcted or not
 * @param {string} props.tags.name Tag name
 * @param {tagsClassName} props.tagsClassName - Function to apply classname based on tag selection status
 * @param {getSkEventName} props.getSkEventName - Function to apply sk-event-name based on tag selection status
 * @returns
 */

const CustomCheckableTags = props => {
  const { tags, onChange, className, tagsClassName, getSkEventName, featureToggleList } = props;
  const isAdvancedSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;
  const handleTagClick = (id, isSelected) => {
    const index = tags.findIndex(x => x.id === id);
    const clonedValue = _.cloneDeep(tags);
    clonedValue[index].isSelected = isSelected;
    onChange(clonedValue);
  };

  const renderCheckbox = isSelected => {
    return isSelected ? <CheckboxGreenTick /> : <CheckboxWithoutTick />;
  };

  return (
    <div className={className}>
      {tags.map(tag => (
        <div
          key={tag.id}
          role="button"
          tabIndex={0}
          className={tagsClassName ? tagsClassName(tag.isSelected) : null}
          onClick={() => handleTagClick(tag.id, !tag.isSelected)}
          onKeyPress={() => handleTagClick(tag.id, !tag.isSelected)}
          style={isAdvancedSearchV2Enabled ? { display: 'flex', alignItems: 'center', gap: '8px' } : {}}
          sk-event-name={getSkEventName ? getSkEventName(tag.isSelected) : null}
        >
          {isAdvancedSearchV2Enabled ? renderCheckbox(tag.isSelected) : null}
          <Tooltip title={tag.name} placement="topLeft">
            {isAdvancedSearchV2Enabled ? <span className={styles.jobTitleTag}>{tag.name}</span> : tag.name}
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default CustomCheckableTags;
