import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Empty, Row, Tooltip, Skeleton, Form } from 'antd';
import DonutChart from '../../D3Charts/DonutChart';
import { prepareDonutChart } from '../../../Utils/DashBoardUtils';
import InsightList from './InsightList';
import styles from './Insight.module.scss';
import { EmptyEducationIcon } from '../../../Icons/AryaIcons';
import { menuItemsList } from '../../../Utils/GlobalInsightSupplyUtil';
import eventTypes from '../../../Analytics/EventTypes';
import jobMessage from '../../JobForm/JobMessages';

function EducationInsightView(props) {
  const { listOfEducationStats, loading, readonlyStats, emptyInsightDescription, intl } = props;
  const [educationsListView, setEducationsListView] = React.useState(false);
  const chartConfig = {
    width: 250,
    height: 250,
    innerRadius: 50,
    textfontSize: 12,
    innerRadiusInfo: {
      position: 10,
      fontSize: 16,
      topText: intl.formatMessage({ ...jobMessage.educationHeader }),
      bottomText: intl.formatMessage({ ...jobMessage.distributionLabel }),
    },
  };
  React.useEffect(() => {
    if (educationsListView) {
      setEducationsListView(false);
    }
  }, [loading]);
  const educationInsightTitlte = (
    <div className={styles.headerTitle}>
      <FormattedMessage {...jobMessage.educationRelevanceLabel} />
    </div>
  );
  const educationInsightDescription = (
    <div className={styles.headerDescription}>
      <FormattedMessage {...jobMessage.educationInsightLabel} />
    </div>
  );

  return (
    <div className={styles.supplyContent} id={menuItemsList.education.id}>
      {educationsListView ? (
        <>
          <div className={styles.headerSupplyInfo} id="EducationsHeader">
            <div className={styles.headerInfo}>
              {educationInsightTitlte}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  onClick={() => setEducationsListView(false)}
                  sk-event-name={eventTypes.job.marketIntel.hideEducationList}
                >
                  {' '}
                  X{' '}
                </Button>
              </div>
            </div>
            {educationInsightDescription}
          </div>
          <div id="EducationsList">
            <InsightList insightList={listOfEducationStats} loading={loading} itemSpan={11} />
          </div>
        </>
      ) : (
        <>
          <div className={styles.headerSupplyInfo} id="EducationsListChat">
            <div className={styles.headerInfo}>
              {educationInsightTitlte}
              <div className={styles.headerButton}>
                <Button
                  size="small"
                  disabled={listOfEducationStats.length < 1}
                  onClick={() => setEducationsListView(true)}
                  sk-event-name={eventTypes.job.marketIntel.displayEducationList}
                >
                  <FormattedMessage {...jobMessage.seeListLabel} />
                </Button>
              </div>
            </div>
            {educationInsightDescription}
          </div>
          <div>
            <Skeleton active paragraph={{ rows: 5 }} loading={loading}>
              <Row>
                {listOfEducationStats.length > 0 ? (
                  <div className={styles.educationChatView}>
                    <div>
                      <DonutChart ChartConfig={chartConfig} DonutChartList={prepareDonutChart(listOfEducationStats)} />
                    </div>
                    <div className={styles.educationRelevanceChartLabels}>
                      {prepareDonutChart(listOfEducationStats).map(item => (
                        <div className={styles.educationChatView} key={item.degree}>
                          <div className={styles.educationDegreeLabel}>
                            <div
                              className={styles.educationDegreeLabelColor}
                              style={{ backgroundColor: item.fillColor }}
                            ></div>
                            <Tooltip title={item.degree}>{item.degree}</Tooltip>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={styles.jobLabels}>
                      <div className={styles.educationJobRequirementLabelContainer}>
                        <div className={styles.jobRequirementColor}></div>
                        <div>
                          <div className={styles.jobRequirementHeading}>
                            <FormattedMessage {...jobMessage.jobRequirementLabel} />
                          </div>

                          <div className={styles.jobRequirementName}>
                            <Tooltip title={listOfEducationStats.length > 0 ? listOfEducationStats[0].Name : null}>
                              {listOfEducationStats.length > 0 ? listOfEducationStats[0].Name : null}
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Empty
                      image={<EmptyEducationIcon className={styles.emptyIcon} />}
                      description={
                        <div>
                          <h3>
                            <FormattedMessage {...jobMessage.noEducationDataFoundLabel} />
                          </h3>
                          {!readonlyStats ? <div>{emptyInsightDescription}</div> : null}
                        </div>
                      }
                    />
                  </div>
                )}
              </Row>
            </Skeleton>
          </div>
        </>
      )}
    </div>
  );
}

export default injectIntl(Form.create()(EducationInsightView));

export {EducationInsightView as EducationInsightViewWithoutInjectIntl};
