export const SET_SCOUTING_AGENT_MODAL_VISIBILITY = 'SET_SCOUTING_AGENT_MODAL_VISIBILITY';
export const SET_SCOUTING_AGENT_WARNING_MODAL_VISIBILITY = 'SET_SCOUTING_AGENT_WARNING_MODAL_VISIBILITY';
export const CREATE_SCOUTING_AGENT = 'CREATE_SCOUTING_AGENT';
export const SET_JOB_SCOUTING_AGENTS = 'SET_JOB_SCOUTING_AGENTS';
export const SET_SELECTED_SCOUTING_AGENT = 'SET_SELECTED_SCOUTING_AGENT';
export const SET_SCOUTING_AGENT_ENABLE_JOB_IDS = 'SET_SCOUTING_AGENT_ENABLE_JOB_IDS';
export const SET_SCOUTING_AGENT_DRAWER = 'SET_SCOUTING_AGENT_DRAWER';
export const SET_SCOUTING_AGENT = 'SET_SCOUTING_AGENT';
export const SET_SCOUTING_AGENT_CONFIG_DATA = 'SET_SCOUTING_AGENT_CONFIG_DATA';
export const SET_SCOUTING_AGENT_ADMIN_VIEW_STATUS = 'SET_SCOUTING_AGENT_ADMIN_VIEW_STATUS';
export const SET_LAST_SCOUTING_AGENT_DELETED_ID = 'SET_LAST_SCOUTING_AGENT_DELETED_ID';
export const UPDATE_ACTIVE_AGENT_COUNTS = 'UPDATE_ACTIVE_AGENT_COUNTS';
export const SET_SCOUTING_AGENT_SEARCH_CRITERIA_MODAL = 'SET_SCOUTING_AGENT_SEARCH_CRITERIA_MODAL';
export const UPDATE_SCOUTING_AGENT_STATS = 'UPDATE_SCOUTING_AGENT_STATS';

export function setScoutingAgentModalVisibilityAction(visible, mode) {
  return {
    type: SET_SCOUTING_AGENT_MODAL_VISIBILITY,
    payload: {
      visible,
      mode,
    },
  };
}
export function setScoutingAgentWarningModalVisibilityAction(visible) {
  return {
    type: SET_SCOUTING_AGENT_WARNING_MODAL_VISIBILITY,
    payload: {
      visible,
    },
  };
}
export function updateActiveAgentCount(data) {
  return {
    type: UPDATE_ACTIVE_AGENT_COUNTS,
    payload: data,
  };
}
export function setScoutingAgentDrawerAction(visible) {
  return {
    type: SET_SCOUTING_AGENT_DRAWER,
    payload: {
      visible,
    },
  };
}

export function setScoutingAgentSearchCriteriaModalAction(visible) {
  return {
    type: SET_SCOUTING_AGENT_SEARCH_CRITERIA_MODAL,
    payload: {
      visible,
    },
  };
}
export function setLastScoutingAgentDeletedId(Id) {
  return {
    type: SET_LAST_SCOUTING_AGENT_DELETED_ID,
    payload: Id,
  };
}

export function setJobScoutingAgents(jobId, data) {
  return {
    type: SET_JOB_SCOUTING_AGENTS,
    payload: {
      jobId,
      data,
    },
  };
}
export function setScoutingAgentConfig(data) {
  return {
    type: SET_SCOUTING_AGENT_CONFIG_DATA,
    payload: data,
  };
}

export function setSelectedScoutingAgentId(agentId) {
  return {
    type: SET_SELECTED_SCOUTING_AGENT,
    payload: agentId,
  };
}

export function setScoutingAgentIdforJobs(data) {
  return {
    type: SET_SCOUTING_AGENT_ENABLE_JOB_IDS,
    payload: data,
  };
}

export function setScoutingAgentData(data) {
  return {
    type: SET_SCOUTING_AGENT,
    payload: data,
  };
}

export function setScoutingAgentAdminViewStatus(data) {
  return {
    type: SET_SCOUTING_AGENT_ADMIN_VIEW_STATUS,
    payload: data,
  };
}

export function updateScoutingAgentStats(agentId, jobId){
  return {
    type: UPDATE_SCOUTING_AGENT_STATS,
    payload: {
      agentId,
      jobId
    }
  };
}
